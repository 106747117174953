import * as yup from 'yup';

import { spaceValidation } from 'features/asset/update/lib/yup-validation';

export const createHairSchema = yup.object({
	frontLink: spaceValidation
		.url()
		.required('Front hair is required field')
		.nullable(),
	backLink: spaceValidation
		.url()
		.required('Back hair is required field')
		.nullable(),
	filename: spaceValidation.min(5).max(255),
	dropboxLink: spaceValidation,
	keywords: yup.array().of(yup.string().trim().required()).required(),
	newKeywords: yup
		.object()
		.shape({
			sex: yup.array().nullable(),
			app: yup.array().nullable(),
			art_style: yup.array().nullable(),
			age: yup.array().nullable(),
			body_type: yup.array().nullable(),
			color: yup.array().nullable(),
			structure: yup.array().nullable(),
			length: yup.array().nullable(),
			style: yup.array().nullable(),
			hairstyle: yup.array().nullable(),
			extra: yup.array().nullable(),
		})
		.nullable(),
	newHair: yup.boolean().required(),
});

export type CreateHairValues = yup.InferType<typeof createHairSchema>;
