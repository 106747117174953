import React from 'react';
import { createIcon } from '@chakra-ui/react';

export const EmojiIcon = createIcon({
	viewBox: '0 0 24 24',
	path: (
		<>
			<g clipPath="url(#clip0_6317_24985)" fill="none">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M21.4418 11.9768C21.4418 7.01687 17.421 2.99609 12.4611 2.99609C7.50125 2.99609 3.48047 7.01687 3.48047 11.9768C3.48047 16.9366 7.50125 20.9574 12.4611 20.9574L21.4418 11.9768Z"
					stroke="currentColor"
					strokeWidth="1.4"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M9.46855 10.9771C10.0197 10.9771 10.4664 10.5304 10.4664 9.9793C10.4664 9.4282 10.0197 8.98145 9.46855 8.98145C8.91746 8.98145 8.4707 9.4282 8.4707 9.9793C8.4707 10.5304 8.91746 10.9771 9.46855 10.9771Z"
					fill="currentColor"
					stroke="currentColor"
					strokeWidth="0.498925"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M15.4549 10.9771C16.006 10.9771 16.4527 10.5304 16.4527 9.9793C16.4527 9.4282 16.006 8.98145 15.4549 8.98145C14.9038 8.98145 14.457 9.4282 14.457 9.9793C14.457 10.5304 14.9038 10.9771 15.4549 10.9771Z"
					fill="currentColor"
					stroke="currentColor"
					strokeWidth="0.498925"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M9.46777 13.9736C10.0658 14.8907 11.182 15.5076 12.4604 15.5076C12.6054 15.5076 12.7483 15.4996 12.8887 15.4842"
					stroke="currentColor"
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M12.4619 20.9553L21.4426 11.9746"
					stroke="currentColor"
					strokeWidth="1.49678"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M12.4615 20.9558C12.2952 17.7959 12.9604 15.4676 14.4572 13.9708C15.9539 12.4741 18.2823 11.8088 21.4421 11.9751"
					stroke="currentColor"
					strokeWidth="1.4"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</g>
			<defs>
				<clipPath id="clip0_6317_24985">
					<rect
						width="21.7713"
						height="21.7713"
						fill="white"
						transform="translate(1 1)"
					/>
				</clipPath>
			</defs>
		</>
	),
});
