import * as Types from '../../../models.gen';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BackgroundsByStoryQueryVariables = Types.Exact<{
  page?: Types.InputMaybe<Types.Scalars['Int']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
  filter?: Types.InputMaybe<Types.BackgroundsFilterArgDto>;
  sort?: Types.InputMaybe<Types.BackgroundsSortArgDto>;
}>;


export type BackgroundsByStoryQuery = { backgrounds: { backgrounds: Array<{ id: string, type: string, filename?: string | null, link?: string | null, atName?: string | null, updatedAt: any, name?: string | null, chapters: Array<{ id: string, name?: string | null, chapterOrder?: number | null, book?: { id: string, name?: string | null } | null }> }> } };

export type DeleteBackgroundFromStoryMutationVariables = Types.Exact<{
  chapter: Types.AddBackgroundsToChapterDto;
}>;


export type DeleteBackgroundFromStoryMutation = { removeBackgroundsFromChapter: boolean };

export type UpdateBackgroundChaptersMutationVariables = Types.Exact<{
  chapter: Types.AddBackgroundsToChapterDto;
}>;


export type UpdateBackgroundChaptersMutation = { addBackgroundsToChapter: boolean };


export const BackgroundsByStoryDocument = gql`
    query BackgroundsByStory($page: Int, $limit: Int, $filter: BackgroundsFilterArgDto, $sort: BackgroundsSortArgDto) {
  backgrounds(page: $page, limit: $limit, filter: $filter, sort: $sort) {
    backgrounds {
      id
      type
      filename
      link
      atName
      updatedAt
      name
      chapters {
        id
        name
        chapterOrder
        book {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __useBackgroundsByStoryQuery__
 *
 * To run a query within a React component, call `useBackgroundsByStoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useBackgroundsByStoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBackgroundsByStoryQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useBackgroundsByStoryQuery(baseOptions?: Apollo.QueryHookOptions<BackgroundsByStoryQuery, BackgroundsByStoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BackgroundsByStoryQuery, BackgroundsByStoryQueryVariables>(BackgroundsByStoryDocument, options);
      }
export function useBackgroundsByStoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BackgroundsByStoryQuery, BackgroundsByStoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BackgroundsByStoryQuery, BackgroundsByStoryQueryVariables>(BackgroundsByStoryDocument, options);
        }
export type BackgroundsByStoryQueryHookResult = ReturnType<typeof useBackgroundsByStoryQuery>;
export type BackgroundsByStoryLazyQueryHookResult = ReturnType<typeof useBackgroundsByStoryLazyQuery>;
export type BackgroundsByStoryQueryResult = Apollo.QueryResult<BackgroundsByStoryQuery, BackgroundsByStoryQueryVariables>;
export const DeleteBackgroundFromStoryDocument = gql`
    mutation DeleteBackgroundFromStory($chapter: AddBackgroundsToChapterDto!) {
  removeBackgroundsFromChapter(chapter: $chapter)
}
    `;
export type DeleteBackgroundFromStoryMutationFn = Apollo.MutationFunction<DeleteBackgroundFromStoryMutation, DeleteBackgroundFromStoryMutationVariables>;

/**
 * __useDeleteBackgroundFromStoryMutation__
 *
 * To run a mutation, you first call `useDeleteBackgroundFromStoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBackgroundFromStoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBackgroundFromStoryMutation, { data, loading, error }] = useDeleteBackgroundFromStoryMutation({
 *   variables: {
 *      chapter: // value for 'chapter'
 *   },
 * });
 */
export function useDeleteBackgroundFromStoryMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBackgroundFromStoryMutation, DeleteBackgroundFromStoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteBackgroundFromStoryMutation, DeleteBackgroundFromStoryMutationVariables>(DeleteBackgroundFromStoryDocument, options);
      }
export type DeleteBackgroundFromStoryMutationHookResult = ReturnType<typeof useDeleteBackgroundFromStoryMutation>;
export type DeleteBackgroundFromStoryMutationResult = Apollo.MutationResult<DeleteBackgroundFromStoryMutation>;
export type DeleteBackgroundFromStoryMutationOptions = Apollo.BaseMutationOptions<DeleteBackgroundFromStoryMutation, DeleteBackgroundFromStoryMutationVariables>;
export const UpdateBackgroundChaptersDocument = gql`
    mutation UpdateBackgroundChapters($chapter: AddBackgroundsToChapterDto!) {
  addBackgroundsToChapter(chapter: $chapter)
}
    `;
export type UpdateBackgroundChaptersMutationFn = Apollo.MutationFunction<UpdateBackgroundChaptersMutation, UpdateBackgroundChaptersMutationVariables>;

/**
 * __useUpdateBackgroundChaptersMutation__
 *
 * To run a mutation, you first call `useUpdateBackgroundChaptersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBackgroundChaptersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBackgroundChaptersMutation, { data, loading, error }] = useUpdateBackgroundChaptersMutation({
 *   variables: {
 *      chapter: // value for 'chapter'
 *   },
 * });
 */
export function useUpdateBackgroundChaptersMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBackgroundChaptersMutation, UpdateBackgroundChaptersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBackgroundChaptersMutation, UpdateBackgroundChaptersMutationVariables>(UpdateBackgroundChaptersDocument, options);
      }
export type UpdateBackgroundChaptersMutationHookResult = ReturnType<typeof useUpdateBackgroundChaptersMutation>;
export type UpdateBackgroundChaptersMutationResult = Apollo.MutationResult<UpdateBackgroundChaptersMutation>;
export type UpdateBackgroundChaptersMutationOptions = Apollo.BaseMutationOptions<UpdateBackgroundChaptersMutation, UpdateBackgroundChaptersMutationVariables>;