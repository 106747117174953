import * as yup from 'yup';

import { spaceValidation } from 'features/asset/update/lib/yup-validation';

export const createItemSchema = yup.object({
	filename: spaceValidation.min(5).max(255),
	link: spaceValidation.url().required('Item is a required field').nullable(),
	price: yup.number().integer(),
	dropboxLink: spaceValidation,
	keywords: yup.array().of(yup.string().trim().required()).required(),
	newKeywords: yup
		.object()
		.shape({
			app: yup.array().nullable(),
			art_style: yup.array().nullable(),
			item: yup.array().nullable(),
		})
		.nullable(),
});

export type CreateItemValues = yup.InferType<typeof createItemSchema>;
