import React from 'react';

import { GetBookQuery } from 'features/book/queries.gen';
import { ArrayElement } from 'shared/model';
import { formatDate } from 'shared/utils';

import styles from './styles.module.scss';

interface ChapterPropertiesProps {
	chapter: ArrayElement<GetBookQuery['book']['chapters']>;
}

export const ChapterProperties: React.FC<ChapterPropertiesProps> = ({
	chapter,
}) => {
	return (
		<div className={styles.root}>
			<div className={styles.detail}>
				<p className={styles.text}>Analytics Name</p>
				<p className={styles.text}>{chapter?.chapterOrder || '---'}</p>
			</div>
			<div className={styles.detail}>
				<p className={styles.text}>Row ID</p>
				<p className={styles.text}>{chapter?.id || '---'}</p>
			</div>
			<div className={styles.detail}>
				<p className={styles.text}>Last Edited</p>
				<p className={styles.text}>{formatDate(chapter?.updatedAt) || '---'}</p>
			</div>
			{/* <div className={styles.detail}>
				<p className={styles.text}>Author Name</p>
				<p className={styles.text}>{chapter?.author || '---'}</p>
			</div> */}
		</div>
	);
};
