import React from 'react';
import { Flex, IconButton } from '@chakra-ui/react';

import { CrossSmallIcon } from 'shared/icons';
import { SearchInput } from 'shared/ui';

interface HeaderProps {
	searchHero: string;
	setSearchHero: (v: string) => void;
	onCloseModal: () => void;
}

const Header: React.FC<HeaderProps> = ({
	searchHero,
	setSearchHero,
	onCloseModal,
}) => {
	return (
		<Flex
			alignItems="center"
			justifyContent="space-between"
			gap={5}
			px={6}
			py={4}
			height="78px"
			borderBottom="1px solid #eeeef2"
		>
			<SearchInput
				search={searchHero}
				setSearch={setSearchHero}
				placeholder="Search by name or id"
			/>
			<IconButton
				aria-label="Close Modal"
				variant="ghost"
				size="square"
				icon={<CrossSmallIcon w={5} h={5} />}
				onClick={onCloseModal}
			/>
		</Flex>
	);
};

export default Header;
