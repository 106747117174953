import React, { useState } from 'react';
import {
	Box,
	Button,
	Flex,
	Grid,
	GridItem,
	Menu,
	MenuButton,
} from '@chakra-ui/react';
import { useDraggable } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';

import { EventTypes, sendAmplitudeData } from 'shared/analytics';
import { ArrowDownIcon, CrossIcon } from 'shared/icons';

import { AiModalContent } from './modal-content';
import { PromptsDropdown } from './prompts-dropdown';
import { AmplitudeInfo, PromptProps } from './types';

interface AiModalProps {
	isOpenModal: boolean;
	selectedText: string;
	selectedPrompt: PromptProps;
	onClose: () => void;
	handleAiModalOpen: (v: string) => void;
	amplitudeInfo: AmplitudeInfo;
	coordinates: number;
}

export const AiModal: React.FC<AiModalProps> = ({
	isOpenModal,
	onClose,
	selectedPrompt,
	handleAiModalOpen,
	selectedText,
	amplitudeInfo,
	coordinates,
}) => {
	const [customValue, setCustomValue] = useState<string>('');
	const { attributes, listeners, setNodeRef, transform } = useDraggable({
		id: 'ai-modal-id',
	});

	const handleClose = () => {
		onClose();
		setCustomValue('');
		sendAmplitudeData(EventTypes.ASKAI_CLOSE_CLICK, amplitudeInfo);
	};

	return (
		<Box
			ref={setNodeRef}
			opacity={isOpenModal ? '1' : '0'}
			pointerEvents={isOpenModal ? 'auto' : 'none'}
			background="#FFF"
			w="534px"
			zIndex={1200}
			top="70px"
			right={coordinates}
			transform={CSS.Transform.toString(transform)}
			boxShadow="md"
			position="absolute"
		>
			<Grid templateColumns="auto 1fr" h={700}>
				<GridItem
					display="flex"
					pos="absolute"
					right="0"
					bottom="0"
					left="0"
					top="0"
					flexDir="column"
					pb="50px"
				>
					<Flex
						w="100%"
						align="center"
						justifyContent="space-between"
						p=" 5px 5px 5px 23px"
						borderBottom="1px solid #eeeef2"
					>
						<Menu>
							{({ isOpen }) => (
								<>
									<MenuButton
										fontSize="14px"
										color="#484964"
										fontWeight={500}
										as={Button}
										variant="ghost"
										size="sm"
										ml="-10px"
										px="10px !important"
										rightIcon={
											<ArrowDownIcon
												w={5}
												h={5}
												ml={2}
												transform={isOpen ? 'rotate(180deg)' : 'none'}
												transition="ease"
												transitionDuration="200ms"
											/>
										}
									>
										<Box overflow="hidden" textOverflow="ellipsis" maxW="200px">
											{selectedPrompt.value}
											{selectedPrompt.option
												? `: ${selectedPrompt.option}`
												: ''}
										</Box>
									</MenuButton>
									<Box
										w="100%"
										h="100%"
										{...listeners}
										{...attributes}
										cursor="move"
									/>
									<Button
										variant="secondary"
										onClick={handleClose}
										margin="10px 5px"
										size="sm"
										borderRadius={8}
									>
										<CrossIcon w={3} h={3} />
									</Button>
									<PromptsDropdown handleAiModalOpen={handleAiModalOpen} />
								</>
							)}
						</Menu>
					</Flex>
					<AiModalContent
						selectedText={selectedText}
						selectedPrompt={selectedPrompt}
						amplitudeInfo={amplitudeInfo}
						customValue={customValue}
						setCustomValue={setCustomValue}
					/>
				</GridItem>
			</Grid>
		</Box>
	);
};
