import * as Types from '../../../models.gen';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateBodyMutationVariables = Types.Exact<{
  body: Types.CreateBodyRequestDto;
}>;


export type CreateBodyMutation = { createBody: { id: string, filename?: string | null, link?: string | null, newKeywords?: { sex?: Array<string> | null, app?: Array<string> | null, art_style?: Array<string> | null, body_type?: Array<string> | null, skintone?: Array<string> | null, age?: Array<string> | null, extra?: Array<string> | null, not_human?: Array<string> | null } | null } };

export type CreateHairMutationVariables = Types.Exact<{
  hair: Types.CreateHairsRequestDto;
}>;


export type CreateHairMutation = { createHair: { id: string, filename?: string | null, frontLink?: string | null, newKeywords?: { sex?: Array<string> | null, app?: Array<string> | null, art_style?: Array<string> | null, body_type?: Array<string> | null, color?: Array<string> | null, structure?: Array<string> | null, length?: Array<string> | null, style?: Array<string> | null, hairstyle?: Array<string> | null, extra?: Array<string> | null } | null } };

export type CreateClothesMutationVariables = Types.Exact<{
  clothes: Types.CreateClothesRequestDto;
}>;


export type CreateClothesMutation = { createClothes: { id: string, filename?: string | null, link?: string | null, newKeywords?: { sex?: Array<string> | null, app?: Array<string> | null, art_style?: Array<string> | null, body_type?: Array<string> | null, age?: Array<string> | null, style?: Array<string> | null, color_range?: Array<string> | null, material?: Array<string> | null, nudity?: Array<string> | null, top?: Array<string> | null, bottom?: Array<string> | null, adjective?: Array<string> | null, extra?: Array<string> | null } | null } };

export type CreateLayerMutationVariables = Types.Exact<{
  layer: Types.CreateLayerRequestDto;
}>;


export type CreateLayerMutation = { createLayer: { id: string, name?: string | null, link?: string | null, newKeywords?: { sex?: Array<string> | null, app?: Array<string> | null, art_style?: Array<string> | null, body_type?: Array<string> | null, owner?: Array<string> | null, defects?: Array<string> | null, accessories?: Array<string> | null, items?: Array<string> | null, for_body?: Array<string> | null } | null } };

export type CreateBackgroundMutationVariables = Types.Exact<{
  background: Types.CreateBackgroundRequestDto;
}>;


export type CreateBackgroundMutation = { createBackground: { id: string, filter?: string | null, filename?: string | null, link?: string | null, newKeywords?: { app?: Array<string> | null, art_style?: Array<string> | null, outside?: Array<string> | null, inside?: Array<string> | null, time?: Array<string> | null, rooms?: Array<string> | null, style?: Array<string> | null, mood?: Array<string> | null, extra?: Array<string> | null } | null } };

export type CreateChatPhotoMutationVariables = Types.Exact<{
  chatPhoto: Types.CreateChatPhotoRequestDto;
}>;


export type CreateChatPhotoMutation = { createChatPhoto: { id: string } };

export type CreateItemMutationVariables = Types.Exact<{
  item: Types.CreateItemRequestDto;
}>;


export type CreateItemMutation = { createItem: { id: string, filename?: string | null, link?: string | null, newKeywords?: { app?: Array<string> | null, sex?: Array<string> | null, item?: Array<string> | null } | null } };

export type CreateAudioMutationVariables = Types.Exact<{
  audio: Types.CreateAudioRequestDto;
}>;


export type CreateAudioMutation = { createAudio: { id: string } };


export const CreateBodyDocument = gql`
    mutation CreateBody($body: CreateBodyRequestDto!) {
  createBody(body: $body) {
    id
    filename
    link
    newKeywords {
      sex
      app
      art_style
      body_type
      skintone
      age
      extra
      not_human
    }
  }
}
    `;
export type CreateBodyMutationFn = Apollo.MutationFunction<CreateBodyMutation, CreateBodyMutationVariables>;

/**
 * __useCreateBodyMutation__
 *
 * To run a mutation, you first call `useCreateBodyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBodyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBodyMutation, { data, loading, error }] = useCreateBodyMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useCreateBodyMutation(baseOptions?: Apollo.MutationHookOptions<CreateBodyMutation, CreateBodyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBodyMutation, CreateBodyMutationVariables>(CreateBodyDocument, options);
      }
export type CreateBodyMutationHookResult = ReturnType<typeof useCreateBodyMutation>;
export type CreateBodyMutationResult = Apollo.MutationResult<CreateBodyMutation>;
export type CreateBodyMutationOptions = Apollo.BaseMutationOptions<CreateBodyMutation, CreateBodyMutationVariables>;
export const CreateHairDocument = gql`
    mutation CreateHair($hair: CreateHairsRequestDto!) {
  createHair(hair: $hair) {
    id
    filename
    frontLink
    newKeywords {
      sex
      app
      art_style
      body_type
      color
      structure
      length
      style
      hairstyle
      extra
    }
  }
}
    `;
export type CreateHairMutationFn = Apollo.MutationFunction<CreateHairMutation, CreateHairMutationVariables>;

/**
 * __useCreateHairMutation__
 *
 * To run a mutation, you first call `useCreateHairMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateHairMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createHairMutation, { data, loading, error }] = useCreateHairMutation({
 *   variables: {
 *      hair: // value for 'hair'
 *   },
 * });
 */
export function useCreateHairMutation(baseOptions?: Apollo.MutationHookOptions<CreateHairMutation, CreateHairMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateHairMutation, CreateHairMutationVariables>(CreateHairDocument, options);
      }
export type CreateHairMutationHookResult = ReturnType<typeof useCreateHairMutation>;
export type CreateHairMutationResult = Apollo.MutationResult<CreateHairMutation>;
export type CreateHairMutationOptions = Apollo.BaseMutationOptions<CreateHairMutation, CreateHairMutationVariables>;
export const CreateClothesDocument = gql`
    mutation CreateClothes($clothes: CreateClothesRequestDto!) {
  createClothes(clothes: $clothes) {
    id
    filename
    link
    newKeywords {
      sex
      app
      art_style
      body_type
      age
      style
      color_range
      material
      nudity
      top
      bottom
      adjective
      extra
    }
  }
}
    `;
export type CreateClothesMutationFn = Apollo.MutationFunction<CreateClothesMutation, CreateClothesMutationVariables>;

/**
 * __useCreateClothesMutation__
 *
 * To run a mutation, you first call `useCreateClothesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClothesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClothesMutation, { data, loading, error }] = useCreateClothesMutation({
 *   variables: {
 *      clothes: // value for 'clothes'
 *   },
 * });
 */
export function useCreateClothesMutation(baseOptions?: Apollo.MutationHookOptions<CreateClothesMutation, CreateClothesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateClothesMutation, CreateClothesMutationVariables>(CreateClothesDocument, options);
      }
export type CreateClothesMutationHookResult = ReturnType<typeof useCreateClothesMutation>;
export type CreateClothesMutationResult = Apollo.MutationResult<CreateClothesMutation>;
export type CreateClothesMutationOptions = Apollo.BaseMutationOptions<CreateClothesMutation, CreateClothesMutationVariables>;
export const CreateLayerDocument = gql`
    mutation CreateLayer($layer: CreateLayerRequestDto!) {
  createLayer(layer: $layer) {
    id
    name
    link
    newKeywords {
      sex
      app
      art_style
      body_type
      owner
      defects
      accessories
      items
      for_body
    }
  }
}
    `;
export type CreateLayerMutationFn = Apollo.MutationFunction<CreateLayerMutation, CreateLayerMutationVariables>;

/**
 * __useCreateLayerMutation__
 *
 * To run a mutation, you first call `useCreateLayerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLayerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLayerMutation, { data, loading, error }] = useCreateLayerMutation({
 *   variables: {
 *      layer: // value for 'layer'
 *   },
 * });
 */
export function useCreateLayerMutation(baseOptions?: Apollo.MutationHookOptions<CreateLayerMutation, CreateLayerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateLayerMutation, CreateLayerMutationVariables>(CreateLayerDocument, options);
      }
export type CreateLayerMutationHookResult = ReturnType<typeof useCreateLayerMutation>;
export type CreateLayerMutationResult = Apollo.MutationResult<CreateLayerMutation>;
export type CreateLayerMutationOptions = Apollo.BaseMutationOptions<CreateLayerMutation, CreateLayerMutationVariables>;
export const CreateBackgroundDocument = gql`
    mutation CreateBackground($background: CreateBackgroundRequestDto!) {
  createBackground(background: $background) {
    id
    filter
    filename
    link
    newKeywords {
      app
      art_style
      outside
      inside
      time
      rooms
      style
      mood
      extra
    }
  }
}
    `;
export type CreateBackgroundMutationFn = Apollo.MutationFunction<CreateBackgroundMutation, CreateBackgroundMutationVariables>;

/**
 * __useCreateBackgroundMutation__
 *
 * To run a mutation, you first call `useCreateBackgroundMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBackgroundMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBackgroundMutation, { data, loading, error }] = useCreateBackgroundMutation({
 *   variables: {
 *      background: // value for 'background'
 *   },
 * });
 */
export function useCreateBackgroundMutation(baseOptions?: Apollo.MutationHookOptions<CreateBackgroundMutation, CreateBackgroundMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBackgroundMutation, CreateBackgroundMutationVariables>(CreateBackgroundDocument, options);
      }
export type CreateBackgroundMutationHookResult = ReturnType<typeof useCreateBackgroundMutation>;
export type CreateBackgroundMutationResult = Apollo.MutationResult<CreateBackgroundMutation>;
export type CreateBackgroundMutationOptions = Apollo.BaseMutationOptions<CreateBackgroundMutation, CreateBackgroundMutationVariables>;
export const CreateChatPhotoDocument = gql`
    mutation CreateChatPhoto($chatPhoto: CreateChatPhotoRequestDto!) {
  createChatPhoto(chatPhoto: $chatPhoto) {
    id
  }
}
    `;
export type CreateChatPhotoMutationFn = Apollo.MutationFunction<CreateChatPhotoMutation, CreateChatPhotoMutationVariables>;

/**
 * __useCreateChatPhotoMutation__
 *
 * To run a mutation, you first call `useCreateChatPhotoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChatPhotoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChatPhotoMutation, { data, loading, error }] = useCreateChatPhotoMutation({
 *   variables: {
 *      chatPhoto: // value for 'chatPhoto'
 *   },
 * });
 */
export function useCreateChatPhotoMutation(baseOptions?: Apollo.MutationHookOptions<CreateChatPhotoMutation, CreateChatPhotoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateChatPhotoMutation, CreateChatPhotoMutationVariables>(CreateChatPhotoDocument, options);
      }
export type CreateChatPhotoMutationHookResult = ReturnType<typeof useCreateChatPhotoMutation>;
export type CreateChatPhotoMutationResult = Apollo.MutationResult<CreateChatPhotoMutation>;
export type CreateChatPhotoMutationOptions = Apollo.BaseMutationOptions<CreateChatPhotoMutation, CreateChatPhotoMutationVariables>;
export const CreateItemDocument = gql`
    mutation CreateItem($item: CreateItemRequestDto!) {
  createItem(item: $item) {
    id
    filename
    link
    newKeywords {
      app
      sex
      item
    }
  }
}
    `;
export type CreateItemMutationFn = Apollo.MutationFunction<CreateItemMutation, CreateItemMutationVariables>;

/**
 * __useCreateItemMutation__
 *
 * To run a mutation, you first call `useCreateItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createItemMutation, { data, loading, error }] = useCreateItemMutation({
 *   variables: {
 *      item: // value for 'item'
 *   },
 * });
 */
export function useCreateItemMutation(baseOptions?: Apollo.MutationHookOptions<CreateItemMutation, CreateItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateItemMutation, CreateItemMutationVariables>(CreateItemDocument, options);
      }
export type CreateItemMutationHookResult = ReturnType<typeof useCreateItemMutation>;
export type CreateItemMutationResult = Apollo.MutationResult<CreateItemMutation>;
export type CreateItemMutationOptions = Apollo.BaseMutationOptions<CreateItemMutation, CreateItemMutationVariables>;
export const CreateAudioDocument = gql`
    mutation CreateAudio($audio: CreateAudioRequestDto!) {
  createAudio(audio: $audio) {
    id
  }
}
    `;
export type CreateAudioMutationFn = Apollo.MutationFunction<CreateAudioMutation, CreateAudioMutationVariables>;

/**
 * __useCreateAudioMutation__
 *
 * To run a mutation, you first call `useCreateAudioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAudioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAudioMutation, { data, loading, error }] = useCreateAudioMutation({
 *   variables: {
 *      audio: // value for 'audio'
 *   },
 * });
 */
export function useCreateAudioMutation(baseOptions?: Apollo.MutationHookOptions<CreateAudioMutation, CreateAudioMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAudioMutation, CreateAudioMutationVariables>(CreateAudioDocument, options);
      }
export type CreateAudioMutationHookResult = ReturnType<typeof useCreateAudioMutation>;
export type CreateAudioMutationResult = Apollo.MutationResult<CreateAudioMutation>;
export type CreateAudioMutationOptions = Apollo.BaseMutationOptions<CreateAudioMutation, CreateAudioMutationVariables>;