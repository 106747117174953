import * as Types from '../../../models.gen';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteClothesMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type DeleteClothesMutation = { deleteClothes: { filename?: string | null, link?: string | null } };


export const DeleteClothesDocument = gql`
    mutation DeleteClothes($id: String!) {
  deleteClothes(id: $id) {
    filename
    link
  }
}
    `;
export type DeleteClothesMutationFn = Apollo.MutationFunction<DeleteClothesMutation, DeleteClothesMutationVariables>;

/**
 * __useDeleteClothesMutation__
 *
 * To run a mutation, you first call `useDeleteClothesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteClothesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteClothesMutation, { data, loading, error }] = useDeleteClothesMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteClothesMutation(baseOptions?: Apollo.MutationHookOptions<DeleteClothesMutation, DeleteClothesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteClothesMutation, DeleteClothesMutationVariables>(DeleteClothesDocument, options);
      }
export type DeleteClothesMutationHookResult = ReturnType<typeof useDeleteClothesMutation>;
export type DeleteClothesMutationResult = Apollo.MutationResult<DeleteClothesMutation>;
export type DeleteClothesMutationOptions = Apollo.BaseMutationOptions<DeleteClothesMutation, DeleteClothesMutationVariables>;