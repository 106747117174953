import React, { useCallback } from 'react';
import {
	Box,
	Input,
	InputGroup,
	InputLeftElement,
	InputRightElement,
	Text,
} from '@chakra-ui/react';

import { CrossIcon, SearchIcon } from 'shared/icons';

interface Props {
	search: string;
	setSearch: (newState: string) => void;
	placeholder?: string;
	currentSearchResultIndex?: number;
	matchedLinesLength?: number;
	inputPadding?: string;
	handleCrossClick?: () => void;
	isDisabled?: boolean;
}

export const SearchInput = React.forwardRef<HTMLInputElement, Props>(
	(
		{
			search,
			setSearch,
			placeholder = 'Search',
			currentSearchResultIndex,
			matchedLinesLength,
			inputPadding,
			handleCrossClick,
			isDisabled = false,
		},
		ref,
	) => {
		const onSearchInputChange = useCallback(
			(e: React.ChangeEvent<HTMLInputElement>) => {
				const { value } = e.currentTarget;
				setSearch(value);
			},
			[setSearch],
		);
		const onCrossClick = useCallback(() => {
			setSearch('');
			return handleCrossClick ? handleCrossClick() : null;
		}, [handleCrossClick, setSearch]);
		return (
			<InputGroup>
				<InputLeftElement
					height="44px"
					pointerEvents="none"
					color="#BCBCC6"
					fontSize="1.2em"
				>
					<SearchIcon />
				</InputLeftElement>
				<Input
					fontSize="13px"
					fontWeight={500}
					lineHeight="16px"
					minW="200px"
					height="44px"
					pl="40px"
					pr={inputPadding || 9}
					placeholder={placeholder}
					value={search}
					onChange={onSearchInputChange}
					ref={ref}
					disabled={isDisabled}
				/>
				{search && (
					<InputRightElement
						display="flex"
						alignItems="center"
						height="44px"
						w="none"
						color="#BCBCC6"
						mx={2.5}
						fontSize="14px"
					>
						{matchedLinesLength ? (
							<Text lineHeight="16px" mr={1.5} color="#BCBCC6">
								{currentSearchResultIndex} of {matchedLinesLength}
							</Text>
						) : null}
						<Box cursor="pointer" pr="8px" onClick={onCrossClick}>
							<CrossIcon fontSize="11px" />
						</Box>
					</InputRightElement>
				)}
			</InputGroup>
		);
	},
);
