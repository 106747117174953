import * as Types from '../../../models.gen';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HeroQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type HeroQuery = { hero: { id: string, customId?: number | null, names: Array<string>, keywords: Array<string>, isFamiliar: boolean, updatedAt: any, parent?: { id: string } | null, clothes: Array<{ id: string, link?: string | null }>, hairs: Array<{ id: string, name?: string | null, frontLink?: string | null, backLink?: string | null }>, bodies?: Array<{ id: string, link?: string | null, emotions: Array<string> }> | null, layers: Array<{ id: string }>, chapters: Array<{ id: string, name?: string | null, chapterOrder?: number | null, book?: { id: string, name?: string | null, fantasy: boolean, customId?: string | null } | null }>, defaultClothes?: { id: string } | null } };

export type HeroKeywordsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type HeroKeywordsQuery = { heroesKeywords: Array<string> };

export type UpdateHeroBooksMutationVariables = Types.Exact<{
  hero: Types.UpdateHeroRequestDto;
}>;


export type UpdateHeroBooksMutation = { updateHero: { id: string } };

export type DeleteHeroMutationVariables = Types.Exact<{
  heroId: Types.Scalars['String'];
}>;


export type DeleteHeroMutation = { deleteHero: string };


export const HeroDocument = gql`
    query Hero($id: String!) {
  hero(id: $id) {
    id
    customId
    names
    keywords
    isFamiliar
    updatedAt
    parent {
      id
    }
    clothes {
      id
      link
    }
    hairs {
      id
      name
      frontLink
      backLink
    }
    bodies {
      id
      link
      emotions
    }
    layers {
      id
    }
    chapters {
      id
      name
      chapterOrder
      book {
        id
        name
        fantasy
        customId
      }
    }
    defaultClothes {
      id
    }
  }
}
    `;

/**
 * __useHeroQuery__
 *
 * To run a query within a React component, call `useHeroQuery` and pass it any options that fit your needs.
 * When your component renders, `useHeroQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHeroQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useHeroQuery(baseOptions: Apollo.QueryHookOptions<HeroQuery, HeroQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HeroQuery, HeroQueryVariables>(HeroDocument, options);
      }
export function useHeroLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HeroQuery, HeroQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HeroQuery, HeroQueryVariables>(HeroDocument, options);
        }
export type HeroQueryHookResult = ReturnType<typeof useHeroQuery>;
export type HeroLazyQueryHookResult = ReturnType<typeof useHeroLazyQuery>;
export type HeroQueryResult = Apollo.QueryResult<HeroQuery, HeroQueryVariables>;
export const HeroKeywordsDocument = gql`
    query HeroKeywords {
  heroesKeywords
}
    `;

/**
 * __useHeroKeywordsQuery__
 *
 * To run a query within a React component, call `useHeroKeywordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHeroKeywordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHeroKeywordsQuery({
 *   variables: {
 *   },
 * });
 */
export function useHeroKeywordsQuery(baseOptions?: Apollo.QueryHookOptions<HeroKeywordsQuery, HeroKeywordsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HeroKeywordsQuery, HeroKeywordsQueryVariables>(HeroKeywordsDocument, options);
      }
export function useHeroKeywordsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HeroKeywordsQuery, HeroKeywordsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HeroKeywordsQuery, HeroKeywordsQueryVariables>(HeroKeywordsDocument, options);
        }
export type HeroKeywordsQueryHookResult = ReturnType<typeof useHeroKeywordsQuery>;
export type HeroKeywordsLazyQueryHookResult = ReturnType<typeof useHeroKeywordsLazyQuery>;
export type HeroKeywordsQueryResult = Apollo.QueryResult<HeroKeywordsQuery, HeroKeywordsQueryVariables>;
export const UpdateHeroBooksDocument = gql`
    mutation UpdateHeroBooks($hero: UpdateHeroRequestDto!) {
  updateHero(hero: $hero) {
    id
  }
}
    `;
export type UpdateHeroBooksMutationFn = Apollo.MutationFunction<UpdateHeroBooksMutation, UpdateHeroBooksMutationVariables>;

/**
 * __useUpdateHeroBooksMutation__
 *
 * To run a mutation, you first call `useUpdateHeroBooksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateHeroBooksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateHeroBooksMutation, { data, loading, error }] = useUpdateHeroBooksMutation({
 *   variables: {
 *      hero: // value for 'hero'
 *   },
 * });
 */
export function useUpdateHeroBooksMutation(baseOptions?: Apollo.MutationHookOptions<UpdateHeroBooksMutation, UpdateHeroBooksMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateHeroBooksMutation, UpdateHeroBooksMutationVariables>(UpdateHeroBooksDocument, options);
      }
export type UpdateHeroBooksMutationHookResult = ReturnType<typeof useUpdateHeroBooksMutation>;
export type UpdateHeroBooksMutationResult = Apollo.MutationResult<UpdateHeroBooksMutation>;
export type UpdateHeroBooksMutationOptions = Apollo.BaseMutationOptions<UpdateHeroBooksMutation, UpdateHeroBooksMutationVariables>;
export const DeleteHeroDocument = gql`
    mutation DeleteHero($heroId: String!) {
  deleteHero(heroId: $heroId)
}
    `;
export type DeleteHeroMutationFn = Apollo.MutationFunction<DeleteHeroMutation, DeleteHeroMutationVariables>;

/**
 * __useDeleteHeroMutation__
 *
 * To run a mutation, you first call `useDeleteHeroMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteHeroMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteHeroMutation, { data, loading, error }] = useDeleteHeroMutation({
 *   variables: {
 *      heroId: // value for 'heroId'
 *   },
 * });
 */
export function useDeleteHeroMutation(baseOptions?: Apollo.MutationHookOptions<DeleteHeroMutation, DeleteHeroMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteHeroMutation, DeleteHeroMutationVariables>(DeleteHeroDocument, options);
      }
export type DeleteHeroMutationHookResult = ReturnType<typeof useDeleteHeroMutation>;
export type DeleteHeroMutationResult = Apollo.MutationResult<DeleteHeroMutation>;
export type DeleteHeroMutationOptions = Apollo.BaseMutationOptions<DeleteHeroMutation, DeleteHeroMutationVariables>;