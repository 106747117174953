import React from 'react';
import {
	Button,
	Flex,
	Grid,
	Modal,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Spinner,
} from '@chakra-ui/react';

import { ChaptersByStoryQuery } from 'features/asset/chapters-asset-modal/graphql/queries.gen';
import EmptyStories from 'pages/stories/ui/empty-stories';
import { ArrayElement } from 'shared/model';
import { Chapter } from 'widgets/existing-heroes-modal/graphql/hooks';
import { CardQuery } from 'widgets/update-card-modal/graphql/queries.gen';

import { ChapterCard } from './chapter-card';

interface SelectChapterModalProps {
	isOpen: boolean;
	onClose: () => void;
	bookChapters: Chapter[];
	loadingChapters: boolean;
	selectedChapter:
		| ArrayElement<ChaptersByStoryQuery['book']['chapters']>
		| CardQuery['card']['chapter']
		| null;
	setSelectedChapter: (v: null) => void;
	handleSubmit: () => void;
}

export const SelectChapterModal: React.FC<SelectChapterModalProps> = ({
	isOpen,
	onClose,
	bookChapters,
	loadingChapters,
	selectedChapter,
	setSelectedChapter,
	handleSubmit,
}) => {
	const handleSelectChapter = (chapter: Chapter) => {
		if (selectedChapter?.id === chapter.id) {
			setSelectedChapter(null);
		} else {
			setSelectedChapter(chapter as any);
		}
	};

	const onApply = () => {
		handleSubmit();
		onClose();
	};

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent
				minW={820}
				m={0}
				overflow="hidden"
				maxH="calc(100vh - 30px)"
			>
				<ModalHeader borderBottom="1px solid #eeeef2" marginBottom="20px">
					<Flex
						alignItems="center"
						gap={2}
						fontSize="18px"
						fontWeight="700"
						lineHeight="24px"
					>
						Choose Chapter
						<ModalCloseButton />
					</Flex>
				</ModalHeader>
				<Grid
					templateRows="auto 1fr auto"
					maxH={700}
					minH={500}
					h="calc(100vh - 200px)"
				>
					<Grid gap={3} px={5} pb={4} overflow="auto" alignContent="start">
						{loadingChapters ? (
							<Spinner size="xl" m="auto" />
						) : (
							bookChapters.map((chapter) => (
								<ChapterCard
									handleSelectChapter={handleSelectChapter}
									selectedChapter={selectedChapter}
									chapter={chapter}
									key={chapter.id}
								/>
							))
						)}
						{!loadingChapters && !bookChapters.length && <EmptyStories />}
					</Grid>
				</Grid>
				<Flex
					justifyContent="flex-end"
					alignItems="center"
					gap={5}
					px={6}
					py={4}
					borderTop="1px solid #eeeef2"
				>
					<Button type="button" size="lg" variant="secondary" onClick={onClose}>
						Cancel
					</Button>
					<Button
						type="button"
						size="lg"
						variant="primary"
						onClick={onApply}
						// isDisabled={isDisabled}
					>
						Apply
					</Button>
				</Flex>
			</ModalContent>
		</Modal>
	);
};

export default SelectChapterModal;
