import React, { useMemo } from 'react';
import { Box, Flex, Grid, Spinner, Text } from '@chakra-ui/react';

import { ChapterCard } from 'features/chapter/chapter-card';
import EmptyStories from 'pages/stories/ui/empty-stories';
import { CheckSmallIcon } from 'shared/icons';
import { useSortedByChapterOrderChapters } from 'widgets/existing-heroes-modal/graphql/hooks';

interface ChooseChaptersTabProps {
	selectChapters: string[];
	setSelectChapters: React.Dispatch<React.SetStateAction<string[]>>;
	bookId: string;
}

const ChooseChaptersTab: React.FC<ChooseChaptersTabProps> = ({
	selectChapters,
	setSelectChapters,
	bookId,
}) => {
	const { chapters, loading } = useSortedByChapterOrderChapters(bookId);

	const currentChapterIds = chapters.map((chapter) => chapter.id);
	const isAllChecked: boolean = useMemo(
		() =>
			currentChapterIds.length
				? currentChapterIds.every((id) => selectChapters.includes(id))
				: false,
		[currentChapterIds, selectChapters],
	);

	const handleSelectAllChapter = () => {
		if (isAllChecked) {
			setSelectChapters([
				...selectChapters.filter((id) => !currentChapterIds.includes(id)),
			]);
		} else {
			setSelectChapters([...selectChapters, ...currentChapterIds]);
		}
	};

	const handleSelectChapter = (chapterId: string) => {
		if (selectChapters.includes(chapterId)) {
			setSelectChapters((prevState) => {
				return prevState.filter(
					(selectChapter: string | string[]) => selectChapter !== chapterId,
				);
			});
		} else {
			setSelectChapters((prevState) => {
				return [...prevState, chapterId];
			});
		}
	};

	return (
		<>
			<Flex
				gap={2}
				p={2}
				my={4}
				mx={8}
				width="max-content"
				onClick={handleSelectAllChapter}
				cursor="pointer"
			>
				<Box
					position="relative"
					h="16px"
					w="16px"
					background={`${isAllChecked ? '#644DED' : 'none'}`}
					border={`${isAllChecked ? 'none' : '1.5px solid #BCBCC6'}`}
					borderRadius="3px"
					transition="300ms"
				>
					{isAllChecked && (
						<CheckSmallIcon
							position="absolute"
							top={-1}
							left={-1}
							h={6}
							w={6}
							color="white"
						/>
					)}
				</Box>
				<Text fontSize="13px" lineHeight="16px">
					Select All Chapters
				</Text>
			</Flex>
			<Grid gap={3} px={5} pb={4} alignContent="start" overflow="auto">
				{loading ? (
					<Spinner size="xl" m="auto" />
				) : (
					chapters.map((chapter) => (
						<ChapterCard
							{...chapter}
							key={chapter.id}
							selectChapters={selectChapters}
							handleSelectChapter={handleSelectChapter}
						/>
					))
				)}
				{!loading && !chapters.length && <EmptyStories />}
			</Grid>
		</>
	);
};

export default ChooseChaptersTab;
