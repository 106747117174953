import React from 'react';
import {
	Button,
	Popover,
	PopoverBody,
	PopoverContent,
	PopoverTrigger,
	useDisclosure,
} from '@chakra-ui/react';

import { SortIcon } from 'shared/icons';
import { SingleChoiceList } from 'shared/ui';
import { sortOptions } from 'widgets/existing-heroes-modal/constant/sortOptions';

interface SortHeroesProps {
	sortHeroes: string;
	setSortHeroes: (v: string) => void;
}

const SortHeroes: React.FC<SortHeroesProps> = ({
	sortHeroes,
	setSortHeroes,
}) => {
	const { onOpen, onClose, isOpen } = useDisclosure();

	const setSelectedSort = (order: string) => {
		setSortHeroes(order);
		onClose();
	};

	const selectedOption: string =
		sortOptions.find((option) => option.value === sortHeroes)?.title || '';

	return (
		<Popover
			isOpen={isOpen}
			onOpen={onOpen}
			onClose={onClose}
			variant="singleList"
			placement="bottom-end"
		>
			<PopoverTrigger>
				<Button size="md" variant="secondary">
					<SortIcon marginRight="8px" />
					Sort by: {selectedOption}
				</Button>
			</PopoverTrigger>
			<PopoverContent maxW={185}>
				<PopoverBody maxW={185}>
					<SingleChoiceList
						options={sortOptions}
						selectedOption={selectedOption}
						onChange={setSelectedSort}
					/>
				</PopoverBody>
			</PopoverContent>
		</Popover>
	);
};

export default SortHeroes;
