import React, { useCallback, useState } from 'react';
import { Box, Button, IconButton, Tooltip } from '@chakra-ui/react';
import cn from 'classnames';

import { HighlighterIcon, NoColorIcon } from 'shared/icons';
import { Paper } from 'shared/ui';

import { backgroundsStyleMap } from '../constants/backgrounds';
import styles from './styles.module.scss';

interface BackgroundColorPickerProps {
	onSelect: (backgroundColor?: string) => void;
}

export const BackgroundColorPicker: React.FC<BackgroundColorPickerProps> = ({
	onSelect,
}) => {
	const [isPickerOpen, setIsPickerOpen] = useState(false);

	const togglePicker = useCallback(() => setIsPickerOpen((prev) => !prev), []);

	// useOutsideClick({
	// 	ref: modalRef,
	// 	handler: () => {
	// 		setIsPickerOpen(false);
	// 	},
	// });

	const handleBackgroundColorSelect = useCallback(
		(backgroundColor?: string) => {
			onSelect(backgroundColor);
			setIsPickerOpen(false);
		},
		[onSelect],
	);

	return (
		<div>
			<Box _hover={{ color: '#644ded' }}>
				<Tooltip label="Highlight Color">
					<IconButton
						variant="ghost"
						size="square"
						aria-label="Google Doc"
						onMouseDown={(e) => e.preventDefault()}
						onClick={togglePicker}
					>
						<HighlighterIcon w={5} h={5} />
					</IconButton>
				</Tooltip>
			</Box>
			{isPickerOpen ? (
				<Paper
					position="absolute"
					left="0"
					bottom="0"
					transform="translateY(100%)"
					display="flex"
					flexDirection="column"
					alignItems="center"
					zIndex={99999}
				>
					<div
						className={styles.colorsContainer}
						onMouseDown={(e) => e.preventDefault()}
					>
						<span className={styles.title}>Color</span>
						<div className={styles.optionsContainer}>
							{Object.keys(backgroundsStyleMap).map((color) => (
								<button
									key={color}
									className={styles.option}
									onMouseDown={(e) => e.preventDefault()}
									onClick={() => {
										handleBackgroundColorSelect(color);
									}}
									type="button"
								>
									<div
										className={cn(styles.color, {
											[styles.colorBorder]: color === '#FFFFFF',
										})}
										style={{ backgroundColor: color }}
									/>
								</button>
							))}
						</div>
					</div>
					<div className={styles.divider} />
					<Button
						size="md"
						variant="secondary"
						className={styles.colorPicker}
						onMouseDown={(e) => e.preventDefault()}
						onClick={() => {
							handleBackgroundColorSelect();
						}}
					>
						<NoColorIcon w="6" h="6" />
						None
					</Button>
				</Paper>
			) : null}
		</div>
	);
};
