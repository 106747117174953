import * as Types from '../../models.gen';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateDocVersionMutationVariables = Types.Exact<{
  file: Types.Scalars['Upload'];
  chapterId: Types.Scalars['String'];
  type: Types.Scalars['String'];
}>;


export type UpdateDocVersionMutation = { updateDocVersion: string };


export const UpdateDocVersionDocument = gql`
    mutation UpdateDocVersion($file: Upload!, $chapterId: String!, $type: String!) {
  updateDocVersion(file: $file, chapterId: $chapterId, type: $type)
}
    `;
export type UpdateDocVersionMutationFn = Apollo.MutationFunction<UpdateDocVersionMutation, UpdateDocVersionMutationVariables>;

/**
 * __useUpdateDocVersionMutation__
 *
 * To run a mutation, you first call `useUpdateDocVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDocVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDocVersionMutation, { data, loading, error }] = useUpdateDocVersionMutation({
 *   variables: {
 *      file: // value for 'file'
 *      chapterId: // value for 'chapterId'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useUpdateDocVersionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDocVersionMutation, UpdateDocVersionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDocVersionMutation, UpdateDocVersionMutationVariables>(UpdateDocVersionDocument, options);
      }
export type UpdateDocVersionMutationHookResult = ReturnType<typeof useUpdateDocVersionMutation>;
export type UpdateDocVersionMutationResult = Apollo.MutationResult<UpdateDocVersionMutation>;
export type UpdateDocVersionMutationOptions = Apollo.BaseMutationOptions<UpdateDocVersionMutation, UpdateDocVersionMutationVariables>;