export const hexToRgb = (color: string) => {
	const numStr = color.slice(1);
	const splitStr = numStr.match(/.{1,2}/g);

	if (!splitStr) throw new Error('incorrect color');

	return {
		red: parseInt(splitStr[0], 16) / 255,
		green: parseInt(splitStr[1], 16) / 255,
		blue: parseInt(splitStr[2], 16) / 255,
	};
};
