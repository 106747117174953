/* eslint-disable sonarjs/cognitive-complexity */
import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import {
	Box,
	Flex,
	Image,
	Input,
	InputGroup,
	Text,
	useDisclosure,
} from '@chakra-ui/react';
import * as Yup from 'yup';

import {
	GetBookDocument,
	GetBookQuery,
	GetBooksDocument,
} from 'features/book/queries.gen';
import {
	BookProperty,
	ButtonType,
	trackPropertyChanged,
} from 'shared/analytics';
import { useAppToast } from 'shared/hooks/toast';
import { CrossSmallIcon } from 'shared/icons';
import { FormWithChildren } from 'shared/ui';

import {
	useGetAllUsersLazyQuery,
	useUpdateBookMutation,
} from '../../../queries.gen';

export const updateAuthorSchema = Yup.object({
	formValue: Yup.string().trim(),
});

type Author = {
	id: string;
	fullName?: string;
	picture?: string;
};
interface Props {
	book: GetBookQuery['book'];
	users?: any;
	setUsers?: any;
}

export const PropertiesAuthor: React.FC<Props> = ({
	book,
	users,
	setUsers,
}) => {
	const authors = useMemo(() => {
		if (!book.author) return [];
		try {
			return JSON.parse(book.author || '');
		} catch (e) {
			return [{ fullName: book.author, id: book.author }];
		}
	}, [book.author]);
	const [filterValue, setFilterValue] = useState('');
	const [authorValue, setAuthorValue] = useState<Author[]>(authors);
	const toast = useAppToast();
	const inputGroupRef = useRef<HTMLDivElement>(null);
	const crossRef = useRef<SVGSVGElement>(null);
	const usersPopoverRef = useRef<HTMLDivElement>(null);

	const {
		onOpen: onStartRedacting,
		onClose: onStopRedacting,
		isOpen: isRedacting,
	} = useDisclosure();
	const {
		onOpen: onOpenUsersPopover,
		onClose: onCloseUsersPopover,
		isOpen: isOpenUsersPopover,
	} = useDisclosure();

	const [getUsers] = useGetAllUsersLazyQuery();

	const [updateBook] = useUpdateBookMutation({
		refetchQueries: [GetBookDocument, GetBooksDocument],
	});

	const filteredUsers = useMemo(
		() =>
			users.filter(
				({ fullName }: { fullName: string }) =>
					(fullName?.toLowerCase() || '').includes(filterValue.toLowerCase()) &&
					!authorValue
						.map((author: Author) => author.fullName)
						.includes(fullName),
			),
		[authorValue, filterValue, users],
	);
	const onSearchInputChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			const { value } = e.currentTarget;
			setFilterValue(value);
		},
		[setFilterValue],
	);

	const handleCrossIcon = (id: string) => {
		setAuthorValue(authorValue.filter((author: Author) => author.id !== id));
		onCloseUsersPopover();
	};

	const handleSubmit = useCallback(
		async (author: string) => {
			try {
				await updateBook({
					variables: { book: { id: book.id, author } },
				});
				trackPropertyChanged({
					entity: 'book',
					property: BookProperty.AUTHOR,
					value: author,
					id: book.id,
				});
				onStopRedacting();

				toast({
					title: 'Book author updated',
					status: 'success',
				});
			} catch {
				toast({
					title: 'Book author was not updated',
					status: 'error',
				});
			}
		},
		[book.id, onStopRedacting, toast, updateBook],
	);

	const isDisabledSubmit = useMemo(() => {
		const authorIds = authors.map((author: Author) => author.id);
		const newAuthors = authorValue.filter(
			(author: Author) => !authorIds.includes(author.id),
		);
		return (
			!newAuthors ||
			(authorValue.length === authors.length && !newAuthors.length)
		);
	}, [authorValue, authors]);

	const handleClickOutside = useCallback(
		(event: MouseEvent) => {
			if (
				usersPopoverRef.current &&
				!usersPopoverRef.current.contains(event.target as Node) &&
				inputGroupRef.current &&
				!inputGroupRef.current.contains(event.target as Node)
			) {
				onCloseUsersPopover();
			}
		},
		[onCloseUsersPopover],
	);
	const onInputClick = useCallback(
		(e) => {
			if (crossRef.current && crossRef.current.contains(e.target as Node))
				return;

			if (isOpenUsersPopover) {
				onCloseUsersPopover();
			} else {
				onOpenUsersPopover();
			}
		},
		[isOpenUsersPopover, onCloseUsersPopover, onOpenUsersPopover],
	);

	useEffect(() => {
		if (isRedacting) {
			getUsers().then((allUsers) => setUsers(allUsers.data?.allUsers || []));
		}
	}, [getUsers, isRedacting, setUsers]);

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [handleClickOutside]);

	return (
		<Box borderTop="1px solid #eeeef2" position="relative">
			<Text fontSize="13px" fontWeight={500} color="#808192" mt="10px">
				Author
			</Text>
			<FormWithChildren
				submitButtonName={ButtonType.EDIT_BOOK_AUTHOR}
				initialValue={book.author}
				isRedacting={isRedacting}
				startRedacting={onStartRedacting}
				stopRedacting={() => {
					onStopRedacting();
					setFilterValue('');
				}}
				validationSchema={updateAuthorSchema}
				isDisabledSubmit={isDisabledSubmit}
				onSubmit={() => handleSubmit(JSON.stringify(authorValue))}
			>
				<InputGroup
					ref={inputGroupRef}
					backgroundColor="#F3F3F8"
					p="0 12px"
					display="-webkit-box"
					flexDirection="row"
					overflow="auto hidden"
					alignItems="center"
					gap="4px"
					h={isRedacting ? 'auto' : '48px'}
					border={isOpenUsersPopover ? '1px solid #644ded' : 'none'}
					outline={
						isOpenUsersPopover ? '4px solid rgba(100, 77, 237, 0.08)' : 'none'
					}
					borderRadius="8px"
					onClick={onInputClick}
				>
					{authorValue.map((author: Author) => (
						<Flex
							alignItems="center"
							justifyContent="center"
							gap="5px"
							p="3px 5px"
							backgroundColor="#DFE0EB"
							borderRadius="6px"
							width="fit-content"
							h="24px"
						>
							<Image
								src={author.picture || '/images/common/none-image.png'}
								width="20px"
								height="20px"
								borderRadius="50%"
							/>
							<Text color="current" fontSize="14px">
								{author.fullName?.split(' ')?.[0] || ''}
							</Text>
							<CrossSmallIcon
								ref={crossRef}
								width="14px"
								height="14px"
								background="#808192"
								borderRadius="50%"
								color="white"
								onClick={(e) => {
									e.stopPropagation();
									handleCrossIcon(author.id);
									onStartRedacting();
								}}
								_hover={{ backgroundColor: '#644ded', cursor: 'pointer' }}
							/>
						</Flex>
					))}
					{isRedacting && (
						<Input
							variant="filter"
							placeholder={authorValue.length ? '' : 'Author name'}
							value={filterValue}
							onChange={onSearchInputChange}
							onFocus={onStartRedacting}
							minW="10px"
							w={authorValue.length > 1 ? '30px' : '100px'}
							p={authorValue.length ? '0' : '12px 9px 12px 5px'}
							_placeholder={{ color: '#808192', fontWeight: 400 }}
						/>
					)}
				</InputGroup>
				{filteredUsers.length && isOpenUsersPopover ? (
					<Flex
						ref={usersPopoverRef}
						mt="8px"
						w="200px"
						maxH="200px"
						h={filteredUsers?.length ? 'auto' : '200px'}
						flexDirection="column"
						gap="5px"
						position="absolute"
						zIndex="1000"
						backgroundColor="#FDFDFD"
						p="8px 2px 2px 6px"
						borderRadius="8px"
						boxShadow="0px 4px 4px rgba(0, 0, 0, 0.08), 0px 0px 1px rgba(67, 90, 111, 0.47)"
					>
						<Box overflow="scroll" pr="3px">
							{filteredUsers.map((user: any) => (
								<Flex
									minW="full"
									w="fit-content"
									fontSize="13px"
									fontWeight="500"
									lineHeight="16px"
									color="#242533"
									cursor="pointer"
									p="9px 8px"
									_hover={{
										color: '#644ded',
										backgroundColor: 'rgba(93, 66, 255, 0.08)',
									}}
									borderRadius="6px"
									onClick={() => {
										onStartRedacting();
										setAuthorValue((prev: Author[]) => [
											...prev,
											{
												id: user.id,
												fullName: user.fullName,
												picture: user.picture,
											},
										]);
										setFilterValue('');
									}}
									alignItems="center"
									gap="5px"
								>
									<Image
										src={user.picture || '/images/common/none-image.png'}
										width="31px"
										height="31px"
										borderRadius="50%"
									/>
									<Text color="current">{user.fullName || ''}</Text>
								</Flex>
							))}
						</Box>
					</Flex>
				) : null}
			</FormWithChildren>
		</Box>
	);
};
