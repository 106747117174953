import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
	Button,
	Checkbox,
	Flex,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Popover,
	PopoverBody,
	PopoverCloseButton,
	PopoverContent,
	Switch,
	Text,
} from '@chakra-ui/react';
import { EditorState } from 'draft-js';
import { useStore } from 'effector-react';

import {
	DraftCommentEntityWithRowsAndKeys,
	scriptModel,
} from 'entities/script';
import { uiModel } from 'entities/ui';
import { ListComment } from 'features/editor/show-list-comment';
import { CommentsIcon, TriangleDownIcon } from 'shared/icons';
import { getEntitiesDataByEntityType } from 'shared/utils';

interface CommentsListProps {
	editorState: EditorState;
	setEditorState: Dispatch<SetStateAction<EditorState>>;
	isCommentsListOpened: boolean;
	handleCloseCommentsList: () => void;
}

export const CommentsList: React.FC<CommentsListProps> = ({
	editorState,
	setEditorState,
	isCommentsListOpened,
	handleCloseCommentsList,
}) => {
	const isShownCommentsMarkdowns = useStore(
		scriptModel.$isShownCommentsMarkdowns,
	);
	const [isShownResolvedComments, setIsShownResolvedComments] = useState(true);

	const [commentEntities, setCommentEntities] = useState<
		DraftCommentEntityWithRowsAndKeys[]
	>([]);

	useEffect(() => {
		const contentState = editorState.getCurrentContent();
		const allCommentEntities =
			getEntitiesDataByEntityType<DraftCommentEntityWithRowsAndKeys>(
				contentState,
				'COMMENT',
			);

		const commentEntitiesWithKeysAndRows = allCommentEntities.reduce<
			DraftCommentEntityWithRowsAndKeys[]
		>((acc, comment) => {
			const existingEntity = acc.find(
				(entity) => entity.entityKey === comment.entityKey,
			);

			if (!existingEntity) {
				acc.push({
					...comment,
					commentedBlocksKeys: [comment.blockKey],
					commentedBlocksRows: [comment.row],
				});
				return acc;
			}

			const newAcc = acc.filter(
				(item) => item.entityKey !== existingEntity.entityKey,
			);

			return [
				...newAcc,
				{
					...existingEntity,
					commentedBlocksKeys: [
						...existingEntity.commentedBlocksKeys,
						comment.blockKey,
					],
					commentedBlocksRows: [
						...existingEntity.commentedBlocksRows,
						comment.row,
					],
				},
			];
		}, []);

		setCommentEntities(
			commentEntitiesWithKeysAndRows.filter((entity) => {
				if (isShownResolvedComments) return true;
				return !entity.entityData?.resolved;
			}),
		);
	}, [editorState, isShownResolvedComments]);

	useEffect(() => {
		uiModel.setIsAllCommentsResolved(
			!!commentEntities.find((entity) => !entity.entityData?.resolved),
		);
	}, [commentEntities]);

	return (
		<Popover
			isOpen={isCommentsListOpened}
			onClose={handleCloseCommentsList}
			closeOnBlur={false}
			closeOnEsc={false}
			placement="right"
		>
			<PopoverContent w="344px">
				<PopoverBody h="90vh" w="344px" p="0">
					<Flex
						align="center"
						justify="space-between"
						height="63px"
						padding="16px"
						borderBottom="1.5px solid #EEEEF2"
					>
						<Text fontSize="18px" fontWeight="700">
							Comments
						</Text>
						<Menu>
							{({ isOpen }) => (
								<>
									<MenuButton
										size="sm"
										mr="40px"
										variant="ghost"
										as={Button}
										isActive={isOpen}
										rightIcon={
											<TriangleDownIcon
												transform={isOpen ? 'rotate(180deg)' : 'none'}
												transition="ease"
												transitionDuration="200ms"
											/>
										}
									>
										All
									</MenuButton>
									<MenuList minW="max-content">
										<MenuItem
											closeOnSelect={false}
											onClick={() =>
												setTimeout(
													() =>
														setIsShownResolvedComments(
															!isShownResolvedComments,
														),
													0,
												)
											}
										>
											Resolved{' '}
											<Checkbox isChecked={isShownResolvedComments} ml="10px" />
										</MenuItem>
									</MenuList>
								</>
							)}
						</Menu>
						<PopoverCloseButton right="16px" top="20px" />
					</Flex>
					<Flex direction="column" padding="16px" overflow="auto" height="100%">
						{commentEntities.length ? (
							commentEntities.map((entity, index) => (
								<ListComment
									commentEntity={entity}
									editorState={editorState}
									setEditorState={setEditorState}
									// eslint-disable-next-line react/no-array-index-key
									key={`${entity.entityKey}${entity.blockKey}${index}`}
								/>
							))
						) : (
							<Flex
								direction="column"
								align="center"
								justify="center"
								height="100%"
							>
								<CommentsIcon w="130px" h="122px" mb="38px" />
								<Text fontSize="18px" mb="15px">
									No Comments
								</Text>
								<Text fontSize="13px" color="#808192">
									There haven&apos;t been any comments yet
								</Text>
							</Flex>
						)}
					</Flex>
					<Flex
						align="center"
						height="63px"
						padding="16px"
						borderTop="1.5px solid #EEEEF2"
						marginTop="auto"
					>
						<Text color="#484964" fontSize="14px" mr="21px">
							Show comment markers
						</Text>
						<Switch
							onChange={() =>
								scriptModel.setIsShownCommentsMarkdowns(
									!isShownCommentsMarkdowns,
								)
							}
							isChecked={isShownCommentsMarkdowns}
							h="20px"
							size="md"
							colorScheme="purple"
						/>
					</Flex>
				</PopoverBody>
			</PopoverContent>
		</Popover>
	);
};
