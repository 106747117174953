import React, { Dispatch, SetStateAction, useEffect, useMemo } from 'react';
import { Box } from '@chakra-ui/react';

import { GetBookQuery } from 'features/book/queries.gen';
import { Select } from 'shared/ui';
import {
	ChapterPropTabs,
	ChapterTabsConfig,
} from 'widgets/update-chapter-modal/ui/lib/chapter-prop-tabs';

import styles from './styles.module.scss';

interface SideNavbarProps {
	chapterId: string | undefined;
	setCurrentChapterId: Dispatch<SetStateAction<string>>;
	currentChapterName: string;
	activeTab: string;
	handleTabClick: (title: ChapterPropTabs) => void;
	onChapterChange: (id: string) => void;
	chapters: GetBookQuery['book']['chapters'];
}

const SideNavbar: React.FC<SideNavbarProps> = ({
	chapterId,
	setCurrentChapterId,
	currentChapterName,
	onChapterChange,
	activeTab,
	handleTabClick,
	chapters = [],
}) => {
	const chaptersOptions = useMemo(
		() =>
			chapters
				?.sort((a, b) => (a?.chapterOrder || 0) - (b?.chapterOrder || 0))
				.map(
					(chapter) =>
						`${chapter?.chapterOrder || '-'}. ${chapter?.name || ''}`,
				) || [],
		[chapters],
	);

	const firstChapterId = useMemo(() => chapters?.[0]?.id, [chapters]);

	useEffect(() => {
		const newChapterId = chapterId || firstChapterId || '';
		setCurrentChapterId(newChapterId);
	}, [chapterId, firstChapterId, setCurrentChapterId]);

	return (
		<div className={styles.root}>
			<div className={styles.header}>
				<Select
					options={chaptersOptions}
					selectedOptions={[currentChapterName]}
					btnClassName={styles.btn}
					dropdownClassName={styles.dropdown}
					onChange={(selected) =>
						chapters?.forEach((chapter) => {
							if (
								selected?.includes(
									`${chapter?.chapterOrder || '-'}. ${chapter?.name || ''}`,
								)
							) {
								onChapterChange(chapter.id);
							}
						})
					}
				/>
			</div>
			{ChapterTabsConfig.map((tab) => (
				<Box
					key={tab}
					className={`${tab === activeTab ? styles.activeNavItem : ''} ${
						styles.navItem
					}`}
					onClick={() => handleTabClick(tab)}
				>
					{tab}
				</Box>
			))}
		</div>
	);
};

export default SideNavbar;
