import React from 'react';
import { createIcon } from '@chakra-ui/react';

export const ArrowFillDownIcon = createIcon({
	viewBox: '0 0 7 4',
	path: (
		<path
			xmlns="http://www.w3.org/2000/svg"
			d="M7 0.4C7 0.176 6.8075 0 6.5625 0L0.4375 0C0.1925 0 0 0.176 0 0.4C0 0.504 0.04375 0.592 0.11375 0.664L3.17625 3.864C3.255 3.944 3.36875 4 3.5 4C3.63125 4 3.745 3.944 3.82375 3.864L6.88625 0.664C6.95625 0.592 7 0.504 7 0.4Z"
			fill="#484964"
		/>
	),
});
