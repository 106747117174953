/* eslint-disable sonarjs/cognitive-complexity */
import React, { useCallback } from 'react';
import {
	Button,
	Flex,
	Grid,
	GridItem,
	IconButton,
	Menu,
	Text,
	Tooltip,
	useDisclosure,
} from '@chakra-ui/react';

import { GetBookDocument } from 'features/book/queries.gen';
import { useRemoveAdditionalDocMutation } from 'features/chapter/update/queries.gen';
import { ChapterByIdDocument } from 'features/story-editor/header/queries.gen';
import { ButtonType } from 'shared/analytics';
import { useAppToast } from 'shared/hooks/toast';
import { PlusIcon, TrashBinIcon } from 'shared/icons';
import { EntityDeletionAlert } from 'shared/ui/entity-deletion-alert';
import { CreateDocNotesModal } from 'widgets/doc-description-modal';
import { UpdateDocNotesModal } from 'widgets/update-doc-description-modal';

interface Props {
	label: string;
	documentId: string;
	docNotes?: { id: string; name?: string | null } | null;
	handleRedirect: (v: string) => void;
}

export const UpdateDocForm: React.FC<Props> = ({
	label,
	documentId,
	docNotes,
	handleRedirect,
}) => {
	const toast = useAppToast();

	const { isOpen, onClose, onOpen } = useDisclosure();
	const {
		isOpen: isDeleteModal,
		onClose: onCloseDeleteModal,
		onOpen: onOpenDeleteModal,
	} = useDisclosure();
	const {
		isOpen: isOpenUpdateModal,
		onClose: onCloseUpdateModal,
		onOpen: onOpenUpdateModal,
	} = useDisclosure();

	const [removeDoc, { loading: isLoading }] = useRemoveAdditionalDocMutation({
		refetchQueries: [ChapterByIdDocument, GetBookDocument],
	});

	const deleteAdditionalDoc = useCallback(async () => {
		try {
			await removeDoc({
				variables: {
					documentId,
				},
			});

			toast({
				title: 'Doc deleted successfully',
				status: 'success',
			});
			if (handleRedirect) {
				handleRedirect(documentId || '');
			}
		} catch {
			toast({
				title: 'Doc was not deleted',
				status: 'error',
			});
		}
	}, [documentId, handleRedirect, removeDoc, toast]);

	return (
		<Grid
			borderBottom="1px solid #eeeef2"
			height="50px"
			gridTemplateColumns="1fr 83px"
			alignItems="center"
			_last={{ borderBottom: 'none' }}
		>
			<GridItem>
				<Flex align="center" fontWeight={500} fontSize="14px">
					{label}
					{docNotes ? (
						<Tooltip label="Open Additional Doc Notes">
							<Flex>
								<Text mx={1}>-</Text>
								<Text
									as="span"
									_hover={{ textDecoration: 'underline', cursor: 'pointer' }}
									onClick={onOpenUpdateModal}
									noOfLines={1}
									maxW="350px"
								>
									{docNotes?.name || ''}
								</Text>
							</Flex>
						</Tooltip>
					) : (
						<Tooltip label="Add Additional Doc notes">
							<Button variant="ghost" mx={2} onClick={onOpen}>
								<PlusIcon />
							</Button>
						</Tooltip>
					)}
				</Flex>
			</GridItem>
			<GridItem>
				<Flex justify="flex-end">
					<Menu placement="bottom-end">
						<Tooltip label="Remove Docs">
							<IconButton
								borderRadius={999}
								size="xs"
								variant="ghost"
								aria-label="discard changes"
								disabled={isLoading}
								onClick={onOpenDeleteModal}
							>
								<TrashBinIcon w={6} h={6} />
							</IconButton>
						</Tooltip>
					</Menu>
				</Flex>
			</GridItem>
			<CreateDocNotesModal
				documentId={documentId}
				isOpen={isOpen}
				onClose={onClose}
				docType="Additional Doc"
				label={label}
			/>
			<UpdateDocNotesModal
				isOpen={isOpenUpdateModal}
				onClose={onCloseUpdateModal}
				docType="Additional Doc"
				label={label}
				docNotesData={docNotes}
			/>
			{isDeleteModal && (
				<EntityDeletionAlert
					isOpen={isDeleteModal}
					onClose={onCloseDeleteModal}
					onSubmit={deleteAdditionalDoc}
					buttonName={ButtonType.DELETE_ADDITIONAL_DOC}
					title={`Delete ${label} document?`}
					description="If you delete the document, it can't be restored. Are you sure you want to continue?"
				/>
			)}
		</Grid>
	);
};
