import { useCallback } from 'react';

import { EXISTED_FILENAME_ERROR_MESSAGE } from 'features/asset/create/ui/forms/constants';

import { useAppToast } from '../toast';

export const useGetAssetError = () => {
	const toast = useAppToast();

	return useCallback(
		(err: string, type: string) => {
			if (err === EXISTED_FILENAME_ERROR_MESSAGE) {
				return toast({
					title: `${err}`,
					status: 'error',
				});
			}
			return toast({
				title: `${type} was not created. ${err}`,
				status: 'error',
			});
		},
		[toast],
	);
};
