import React from 'react';
import { createIcon } from '@chakra-ui/react';

export const CommentIcon = createIcon({
	viewBox: '0 0 24 24',
	path: (
		<g fill="none">
			<path
				d="M5.1265 16.4848C4.39214 15.228 4 13.7967 4 12.3074C4 7.71932 7.7241 4 12.318 4C16.912 4 20.6361 7.71932 20.6361 12.3074C20.6361 16.8954 16.912 20.6147 12.318 20.6147H8.09366L4 20.6361L5.1265 16.4848Z"
				stroke="currentColor"
				strokeWidth="1.4"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</g>
	),
});
