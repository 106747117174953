import React, { useEffect, useState } from 'react';
import { Button, Input, Tooltip } from '@chakra-ui/react';

import { useAppToast } from 'shared/hooks/toast';
import {
	CheckmarkIcon,
	PencilIcon,
	PlusIcon,
	TrashBinIcon,
} from 'shared/icons';
import { isURL } from 'shared/utils/validation/url';

import styles from './styles.module.scss';

interface Props {
	label?: string;
	name: string;
	value?: string;
	onApply: (v: string | number) => void;
	error?: string;
	type?: 'text' | 'number';
	defaultValue?: string;
	tooltipValue?: string;
	isPlusBtn?: boolean;
	placeholder?: string;
	isDisabledFilename?: boolean;
	border?: boolean;
	infoPopover?: () => void;
}

export const InputFieldWithLink: React.FC<Props> = ({
	label,
	name,
	value,
	onApply,
	error,
	type = 'text',
	defaultValue = '',
	tooltipValue,
	isPlusBtn = true,
	placeholder,
	isDisabledFilename,
	border = true,
	infoPopover,
	// eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
	const toast = useAppToast();

	const [innerValue, setInnerValue] = useState(value);
	const [isRedacting, setIsRedacting] = useState(false);
	const [isInvalidInput, setIsInvalidInput] = useState(false);

	const stopRedacting = () => setIsRedacting(false);

	const hasValue = typeof value === 'number' ? true : !!value;

	useEffect(() => {
		setInnerValue(value);
	}, [value]);

	const handleApply = () => {
		if (isURL(innerValue as string)) {
			onApply(innerValue as string);
			stopRedacting();
			setIsInvalidInput(false);
		} else {
			toast({
				title: 'Link was not added',
				description: 'Please enter correct link',
				status: 'error',
			});
			setIsInvalidInput(true);
		}
	};
	return (
		<div
			className={`${label && styles.root} ${!border ? styles.borderNone : ''}`}
		>
			{label && (
				<div className={styles.left}>
					<p className={styles.text}>
						{label} {infoPopover && infoPopover()}
					</p>
				</div>
			)}
			<div className={styles.right}>
				{isRedacting ? (
					<>
						<div className={styles.input}>
							<Input
								width={placeholder ? '300px' : '165px'}
								value={innerValue}
								name={name}
								isInvalid={isInvalidInput}
								autoComplete="off"
								size="sm"
								placeholder={placeholder || ''}
								type={type}
								mr="20px"
								onChange={(e) => {
									setInnerValue(e.target.value);
								}}
								onKeyPress={(e) => {
									if (e.code === 'Enter') {
										e.preventDefault();
									}
								}}
							/>
						</div>
						<Tooltip label="Apply">
							<Button size="circle" variant="secondary" onClick={handleApply}>
								<CheckmarkIcon w={4} h={4} />
							</Button>
						</Tooltip>
						<Tooltip label="Delete">
							<Button
								size="circle"
								variant="secondary"
								onClick={() => {
									if (!isPlusBtn) {
										onApply(value || '');
										setInnerValue(value);
									} else {
										onApply(defaultValue);
										setInnerValue(defaultValue);
									}
									stopRedacting();
									setIsInvalidInput(false);
								}}
							>
								<TrashBinIcon w={6} h={6} />
							</Button>
						</Tooltip>
					</>
				) : (
					<>
						{hasValue && !error && (
							<a
								href={value as string}
								target="_blank"
								rel="noreferrer"
								style={{
									color: '#644DED',
									fontSize: '13px',
									fontWeight: 500,
									textDecorationLine: 'underline',
									marginRight: '9px',
								}}
							>
								link
							</a>
						)}
						{!isDisabledFilename ? (
							<Button
								size="circle"
								variant="secondary"
								onClick={() => setIsRedacting(true)}
							>
								{hasValue ? (
									<Tooltip label={tooltipValue || ''}>
										<PencilIcon w={6} h={6} />
									</Tooltip>
								) : (
									<PlusIcon />
								)}
							</Button>
						) : null}
					</>
				)}
			</div>
		</div>
	);
};
