import * as yup from 'yup';

import { spaceValidation } from '../yup-validation';

export const updateLayerSchema = yup.object({
	id: yup.string().trim().required(),
	link: spaceValidation.url().nullable(),
	price: yup.number().integer(),
	dropboxLink: spaceValidation,
	position: yup.number().integer(),
	// description: yup.string().trim().min(5).max(255), // TODO: add field
	keywords: yup.array().of(yup.string().trim().required()),
	newKeywords: yup
		.object()
		.shape({
			sex: yup.array().nullable(),
			app: yup.array().nullable(),
			art_style: yup.array().nullable(),
			body_type: yup.array().nullable(),
			owner: yup.array().nullable(),
			defects: yup.array().nullable(),
			accessories: yup.array().nullable(),
			items: yup.array().nullable(),
			for_body: yup.array().nullable(),
		})
		.nullable(),
});

export type UpdateLayerValues = yup.InferType<typeof updateLayerSchema>;
