import React from 'react';
import { Box, Button, Tooltip } from '@chakra-ui/react';

import { CloudBackupIcon, CloudOffIcon, RefreshIcon } from 'shared/icons';

interface StateIconProps {
	state:
		| 'default'
		| 'additionalDoc'
		| 'saving'
		| 'error'
		| 'driveError'
		| 'noInternetConnection';
	variant?: 'editor' | 'artDoc';
}

const SAVING_STATE_EDITOR_MAP: Record<string, JSX.Element> = {
	default: (
		<Tooltip label="All changes saved to Drive">
			<CloudBackupIcon w={4} _hover={{ color: '#644DED' }} />
		</Tooltip>
	),
	additionalDoc: (
		<Tooltip label="All changes saved">
			<CloudBackupIcon w={4} _hover={{ color: '#644DED' }} />
		</Tooltip>
	),
	saving: (
		<Box d="flex" alignItems="center" color="#808192">
			<RefreshIcon w={4} mr={1} /> Saving...
		</Box>
	),
	error: (
		<Box d="flex" alignItems="center" color="#808192">
			<CloudOffIcon w={4} mr={1} /> Changes not saved...
		</Box>
	),
	driveError: (
		<Box d="flex" alignItems="center" color="#ED0000">
			<CloudOffIcon w={4} mr={1} /> Not synced with drive...
		</Box>
	),
};

const SAVING_STATE_ART_DOC_MAP: Record<string, JSX.Element> = {
	default: (
		<Tooltip label="All changes saved">
			<Button variant="secondary">
				<CloudBackupIcon w={4} _hover={{ color: '#644DED' }} />
			</Button>
		</Tooltip>
	),
	saving: (
		<Box
			d="flex"
			alignItems="center"
			color="#808192"
			fontSize="13px"
			fontWeight="400"
		>
			<RefreshIcon w={4} mr={1} /> Saving...
		</Box>
	),
	error: (
		<Box
			d="flex"
			alignItems="center"
			color="#808192"
			fontSize="13px"
			fontWeight="400"
		>
			<CloudOffIcon w={4} mr={1} /> Changes not saved...
		</Box>
	),
	noInternetConnection: (
		<Box
			d="flex"
			alignItems="center"
			color="#808192"
			fontSize="13px"
			fontWeight="400"
		>
			<CloudOffIcon w={4} mr={1} /> Trying to connection...
		</Box>
	),
};

const VARIANTS_MAP = {
	editor: SAVING_STATE_EDITOR_MAP,
	artDoc: SAVING_STATE_ART_DOC_MAP,
};

export const StateIcon: React.FC<StateIconProps> = ({
	state,
	variant = 'editor',
}) => {
	return VARIANTS_MAP[variant][state];
};
