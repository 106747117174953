import * as yup from 'yup';

import { spaceValidation } from '../yup-validation';

export const updateClothesSchema = yup.object({
	id: yup.string().trim().required(),
	link: spaceValidation.url().nullable(),
	filename: spaceValidation.min(5).max(255).nullable(),
	dropboxLink: spaceValidation,
	price: yup.number().integer(),
	keywords: yup.array().of(yup.string().trim().required()),
	newKeywords: yup
		.object()
		.shape({
			sex: yup.array().nullable(),
			app: yup.array().nullable(),
			art_style: yup.array().nullable(),
			body_type: yup.array().nullable(),
			age: yup.array().nullable(),
			style: yup.array().nullable(),
			color_range: yup.array().nullable(),
			material: yup.array().nullable(),
			nudity: yup.array().nullable(),
			top: yup.array().nullable(),
			bottom: yup.array().nullable(),
			adjective: yup.array().nullable(),
			extra: yup.array().nullable(),
		})
		.nullable(),
});

export type UpdateClothesValues = yup.InferType<typeof updateClothesSchema>;
