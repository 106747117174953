import React, { useCallback, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
	Box,
	Button,
	Heading,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Tooltip,
	useDisclosure,
} from '@chakra-ui/react';

import { scriptModel } from 'entities/script';
import { useGetBookQuery } from 'features/book/queries.gen';
import { ArrowDownIcon } from 'shared/icons';

import { useChapterByIdQuery } from '../header/queries.gen';

export const ChapterPicker: React.FC = () => {
	const { bookId, chapterId } = useParams();
	const { data: chapterData } = useChapterByIdQuery({
		variables: { id: chapterId as string },
	});

	const { onOpen, isOpen, onClose } = useDisclosure();

	const selectedItemRef = useRef<HTMLButtonElement | null>(null);

	const { data, loading } = useGetBookQuery({
		variables: { id: bookId as string },
	});

	const navigate = useNavigate();

	const handleChapterOpen = useCallback(
		({ googleDoc, googleDocWriter, googleDocNarrative, id }: any) =>
			() => {
				navigate(
					`/stories/${bookId}/${id}/${
						googleDoc?.id ||
						googleDocWriter?.id ||
						googleDocNarrative?.id ||
						'no-doc'
					}`,
				);
				scriptModel.setIsDisabledControlButtons(true);
			},
		[bookId, navigate],
	);

	useEffect(() => {
		if (selectedItemRef.current) {
			setTimeout(() => {
				selectedItemRef.current?.scrollIntoView({
					behavior: 'smooth',
					block: 'center',
				});
			}, 50);
		}
	}, [isOpen]);

	if (loading || !chapterData?.chapter) return null;

	return (
		<Menu isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
			<>
				<Tooltip label="Open another chapter" placement="bottom">
					<MenuButton
						fontSize="16px"
						color="#242533"
						fontWeight={600}
						as={Button}
						variant="ghost"
						size="sm"
						aria-label="Google Doc"
						ml="-10px"
						px="10px !important"
						rightIcon={
							<ArrowDownIcon
								w={5}
								h={5}
								ml={2}
								transform={isOpen ? 'rotate(180deg)' : 'none'}
								transition="ease"
								transitionDuration="200ms"
								_hover={{ padding: '100px' }}
							/>
						}
					>
						{chapterData.chapter.chapterOrder}. {chapterData.chapter.name}
					</MenuButton>
				</Tooltip>

				<MenuList p="0 !important">
					<Box
						position="sticky"
						top="0"
						zIndex={100}
						borderBottom="1px solid rgba(0, 0, 0, 0.08)"
					>
						<Heading
							px={6}
							py={3}
							fontSize="15px"
							bg="#fdfdfd"
							fontWeight={600}
							userSelect="none"
						>
							{data?.book.name}
						</Heading>
					</Box>
					<Box px={2} py={1}>
						{data?.book.chapters
							?.sort((a, b) => (a?.chapterOrder || 0) - (b?.chapterOrder || 0))
							.map(
								({
									id,
									googleDoc,
									googleDocNarrative,
									googleDocWriter,
									name,
									chapterOrder,
								}) => (
									<MenuItem
										key={id}
										color={
											chapterData.chapter.name === name ? '#644ded' : 'inherit'
										}
										bg={
											chapterData.chapter.name === name
												? 'rgba(93,66,255,0.08)'
												: 'inherit'
										}
										onClick={handleChapterOpen({
											googleDoc,
											googleDocNarrative,
											id,
											googleDocWriter,
										})}
										ref={
											chapterData.chapter.name === name ? selectedItemRef : null
										}
									>
										{`${chapterOrder}. ${name}`}
									</MenuItem>
								),
							)}
					</Box>
				</MenuList>
			</>
		</Menu>
	);
};
