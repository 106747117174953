import React from 'react';
import { NavLink } from 'react-router-dom';
import cn from 'classnames';

import styles from './styles.module.scss';

interface Props {
	icon: JSX.Element;
	text?: string;
	isNavigationOpened: boolean;
	onClick?: () => void;
	href?: string;
}

const NavigationLink: React.FC<Props> = ({
	icon,
	text,
	onClick,
	isNavigationOpened,
	href,
}) => {
	return href ? (
		<NavLink
			className={({ isActive }) =>
				cn(styles.wrapper, { [styles.activeLink]: isActive })
			}
			onClick={onClick}
			to={href}
		>
			{icon}
			{text && (
				<p
					className={cn(styles.text, {
						[styles.open]: isNavigationOpened,
					})}
				>
					{text}
				</p>
			)}
		</NavLink>
	) : (
		<span className={styles.wrapper} onClick={onClick}>
			{icon}
			{text && (
				<p
					className={cn(styles.text, {
						[styles.open]: isNavigationOpened,
					})}
				>
					{text}
				</p>
			)}
		</span>
	);
};

export default NavigationLink;
