import * as Types from '../../../../../models.gen';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateBodyMutationVariables = Types.Exact<{
  body: Types.UpdateBodyRequestDto;
}>;


export type UpdateBodyMutation = { updateBody: { id: string, filename?: string | null, link?: string | null, keywords: Array<string>, dropboxLink?: string | null, newKeywords?: { sex?: Array<string> | null, app?: Array<string> | null, art_style?: Array<string> | null, body_type?: Array<string> | null, skintone?: Array<string> | null, age?: Array<string> | null, extra?: Array<string> | null, not_human?: Array<string> | null } | null } };

export type UnlinkAssetMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
  parentId?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type UnlinkAssetMutation = { unlinkAsset: string };

export type UpdateHairMutationVariables = Types.Exact<{
  hair: Types.UpdateHairsRequestDto;
}>;


export type UpdateHairMutation = { updateHair: { id: string, filename?: string | null, frontLink?: string | null, backLink?: string | null, keywords?: Array<string> | null, newHair: boolean, dropboxLink?: string | null, newKeywords?: { sex?: Array<string> | null, app?: Array<string> | null, art_style?: Array<string> | null, body_type?: Array<string> | null, color?: Array<string> | null, structure?: Array<string> | null, length?: Array<string> | null, style?: Array<string> | null, hairstyle?: Array<string> | null, extra?: Array<string> | null } | null } };

export type UpdateClothesMutationVariables = Types.Exact<{
  clothes: Types.UpdateClothesRequestDto;
}>;


export type UpdateClothesMutation = { updateClothes: { id: string, filename?: string | null, link?: string | null, keywords?: Array<string> | null, price?: number | null, dropboxLink?: string | null, alternativeClothes: Array<{ id: string }>, newKeywords?: { sex?: Array<string> | null, app?: Array<string> | null, art_style?: Array<string> | null, body_type?: Array<string> | null, age?: Array<string> | null, style?: Array<string> | null, color_range?: Array<string> | null, material?: Array<string> | null, nudity?: Array<string> | null, top?: Array<string> | null, bottom?: Array<string> | null, adjective?: Array<string> | null, extra?: Array<string> | null } | null } };

export type UpdateItemMutationVariables = Types.Exact<{
  item: Types.UpdateItemRequestDto;
}>;


export type UpdateItemMutation = { updateItem: { id: string, filename?: string | null, link?: string | null, keywords: Array<string>, price?: number | null, dropboxLink?: string | null, newKeywords?: { app?: Array<string> | null, sex?: Array<string> | null, item?: Array<string> | null } | null } };

export type UpdateLayerMutationVariables = Types.Exact<{
  layer: Types.UpdateLayerRequestDto;
}>;


export type UpdateLayerMutation = { updateLayer: { id: string, link?: string | null, keywords: Array<string>, position?: number | null, price?: number | null, dropboxLink?: string | null, newKeywords?: { sex?: Array<string> | null, app?: Array<string> | null, art_style?: Array<string> | null, body_type?: Array<string> | null, owner?: Array<string> | null, defects?: Array<string> | null, accessories?: Array<string> | null, items?: Array<string> | null, for_body?: Array<string> | null } | null } };

export type UpdateBackgroundMutationVariables = Types.Exact<{
  background: Types.UpdateBackgroundRequestDto;
}>;


export type UpdateBackgroundMutation = { updateBackground: { id: string, filename?: string | null, link?: string | null, dropboxLink?: string | null, keywords: Array<string>, visionLabels?: string | null, alternativeBackgrounds: Array<{ id: string }>, newKeywords?: { app?: Array<string> | null, art_style?: Array<string> | null, outside?: Array<string> | null, inside?: Array<string> | null, time?: Array<string> | null, rooms?: Array<string> | null, style?: Array<string> | null, mood?: Array<string> | null, extra?: Array<string> | null } | null } };

export type UpdateChatPhotoMutationVariables = Types.Exact<{
  chatPhoto: Types.UpdateChatPhotoRequestDto;
}>;


export type UpdateChatPhotoMutation = { updateChatPhoto: { id: string, name?: string | null, link?: string | null } };

export type UpdateAudioMutationVariables = Types.Exact<{
  audio: Types.UpdateAudioRequestDto;
}>;


export type UpdateAudioMutation = { updateAudio: { id: string, name?: string | null, link?: string | null, keywords: Array<string>, newKeywords?: { app?: Array<string> | null, art_style?: Array<string> | null, outside?: Array<string> | null, inside?: Array<string> | null, time?: Array<string> | null, rooms?: Array<string> | null, style?: Array<string> | null, mood?: Array<string> | null, extra?: Array<string> | null } | null } };

export type UpdateEmotionsMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
  emotions: Types.UpdateEmotionsRequestDto;
}>;


export type UpdateEmotionsMutation = { updateEmotions: { newEmotions: Array<string>, deletedEmotions: Array<string>, updatedEmotions: Array<string> } };


export const UpdateBodyDocument = gql`
    mutation UpdateBody($body: UpdateBodyRequestDto!) {
  updateBody(body: $body) {
    id
    filename
    link
    keywords
    dropboxLink
    newKeywords {
      sex
      app
      art_style
      body_type
      skintone
      age
      extra
      not_human
    }
  }
}
    `;
export type UpdateBodyMutationFn = Apollo.MutationFunction<UpdateBodyMutation, UpdateBodyMutationVariables>;

/**
 * __useUpdateBodyMutation__
 *
 * To run a mutation, you first call `useUpdateBodyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBodyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBodyMutation, { data, loading, error }] = useUpdateBodyMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useUpdateBodyMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBodyMutation, UpdateBodyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBodyMutation, UpdateBodyMutationVariables>(UpdateBodyDocument, options);
      }
export type UpdateBodyMutationHookResult = ReturnType<typeof useUpdateBodyMutation>;
export type UpdateBodyMutationResult = Apollo.MutationResult<UpdateBodyMutation>;
export type UpdateBodyMutationOptions = Apollo.BaseMutationOptions<UpdateBodyMutation, UpdateBodyMutationVariables>;
export const UnlinkAssetDocument = gql`
    mutation UnlinkAsset($id: String!, $parentId: String) {
  unlinkAsset(id: $id, parentId: $parentId)
}
    `;
export type UnlinkAssetMutationFn = Apollo.MutationFunction<UnlinkAssetMutation, UnlinkAssetMutationVariables>;

/**
 * __useUnlinkAssetMutation__
 *
 * To run a mutation, you first call `useUnlinkAssetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnlinkAssetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unlinkAssetMutation, { data, loading, error }] = useUnlinkAssetMutation({
 *   variables: {
 *      id: // value for 'id'
 *      parentId: // value for 'parentId'
 *   },
 * });
 */
export function useUnlinkAssetMutation(baseOptions?: Apollo.MutationHookOptions<UnlinkAssetMutation, UnlinkAssetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnlinkAssetMutation, UnlinkAssetMutationVariables>(UnlinkAssetDocument, options);
      }
export type UnlinkAssetMutationHookResult = ReturnType<typeof useUnlinkAssetMutation>;
export type UnlinkAssetMutationResult = Apollo.MutationResult<UnlinkAssetMutation>;
export type UnlinkAssetMutationOptions = Apollo.BaseMutationOptions<UnlinkAssetMutation, UnlinkAssetMutationVariables>;
export const UpdateHairDocument = gql`
    mutation UpdateHair($hair: UpdateHairsRequestDto!) {
  updateHair(hair: $hair) {
    id
    filename
    frontLink
    backLink
    keywords
    newKeywords {
      sex
      app
      art_style
      body_type
      color
      structure
      length
      style
      hairstyle
      extra
    }
    newHair
    dropboxLink
  }
}
    `;
export type UpdateHairMutationFn = Apollo.MutationFunction<UpdateHairMutation, UpdateHairMutationVariables>;

/**
 * __useUpdateHairMutation__
 *
 * To run a mutation, you first call `useUpdateHairMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateHairMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateHairMutation, { data, loading, error }] = useUpdateHairMutation({
 *   variables: {
 *      hair: // value for 'hair'
 *   },
 * });
 */
export function useUpdateHairMutation(baseOptions?: Apollo.MutationHookOptions<UpdateHairMutation, UpdateHairMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateHairMutation, UpdateHairMutationVariables>(UpdateHairDocument, options);
      }
export type UpdateHairMutationHookResult = ReturnType<typeof useUpdateHairMutation>;
export type UpdateHairMutationResult = Apollo.MutationResult<UpdateHairMutation>;
export type UpdateHairMutationOptions = Apollo.BaseMutationOptions<UpdateHairMutation, UpdateHairMutationVariables>;
export const UpdateClothesDocument = gql`
    mutation UpdateClothes($clothes: UpdateClothesRequestDto!) {
  updateClothes(clothes: $clothes) {
    id
    filename
    link
    alternativeClothes {
      id
    }
    keywords
    newKeywords {
      sex
      app
      art_style
      body_type
      age
      style
      color_range
      material
      nudity
      top
      bottom
      adjective
      extra
    }
    price
    dropboxLink
  }
}
    `;
export type UpdateClothesMutationFn = Apollo.MutationFunction<UpdateClothesMutation, UpdateClothesMutationVariables>;

/**
 * __useUpdateClothesMutation__
 *
 * To run a mutation, you first call `useUpdateClothesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClothesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClothesMutation, { data, loading, error }] = useUpdateClothesMutation({
 *   variables: {
 *      clothes: // value for 'clothes'
 *   },
 * });
 */
export function useUpdateClothesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateClothesMutation, UpdateClothesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateClothesMutation, UpdateClothesMutationVariables>(UpdateClothesDocument, options);
      }
export type UpdateClothesMutationHookResult = ReturnType<typeof useUpdateClothesMutation>;
export type UpdateClothesMutationResult = Apollo.MutationResult<UpdateClothesMutation>;
export type UpdateClothesMutationOptions = Apollo.BaseMutationOptions<UpdateClothesMutation, UpdateClothesMutationVariables>;
export const UpdateItemDocument = gql`
    mutation UpdateItem($item: UpdateItemRequestDto!) {
  updateItem(item: $item) {
    id
    filename
    link
    keywords
    newKeywords {
      app
      sex
      item
    }
    price
    dropboxLink
  }
}
    `;
export type UpdateItemMutationFn = Apollo.MutationFunction<UpdateItemMutation, UpdateItemMutationVariables>;

/**
 * __useUpdateItemMutation__
 *
 * To run a mutation, you first call `useUpdateItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateItemMutation, { data, loading, error }] = useUpdateItemMutation({
 *   variables: {
 *      item: // value for 'item'
 *   },
 * });
 */
export function useUpdateItemMutation(baseOptions?: Apollo.MutationHookOptions<UpdateItemMutation, UpdateItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateItemMutation, UpdateItemMutationVariables>(UpdateItemDocument, options);
      }
export type UpdateItemMutationHookResult = ReturnType<typeof useUpdateItemMutation>;
export type UpdateItemMutationResult = Apollo.MutationResult<UpdateItemMutation>;
export type UpdateItemMutationOptions = Apollo.BaseMutationOptions<UpdateItemMutation, UpdateItemMutationVariables>;
export const UpdateLayerDocument = gql`
    mutation UpdateLayer($layer: UpdateLayerRequestDto!) {
  updateLayer(layer: $layer) {
    id
    link
    keywords
    newKeywords {
      sex
      app
      art_style
      body_type
      owner
      defects
      accessories
      items
      for_body
    }
    position
    price
    dropboxLink
  }
}
    `;
export type UpdateLayerMutationFn = Apollo.MutationFunction<UpdateLayerMutation, UpdateLayerMutationVariables>;

/**
 * __useUpdateLayerMutation__
 *
 * To run a mutation, you first call `useUpdateLayerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLayerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLayerMutation, { data, loading, error }] = useUpdateLayerMutation({
 *   variables: {
 *      layer: // value for 'layer'
 *   },
 * });
 */
export function useUpdateLayerMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLayerMutation, UpdateLayerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLayerMutation, UpdateLayerMutationVariables>(UpdateLayerDocument, options);
      }
export type UpdateLayerMutationHookResult = ReturnType<typeof useUpdateLayerMutation>;
export type UpdateLayerMutationResult = Apollo.MutationResult<UpdateLayerMutation>;
export type UpdateLayerMutationOptions = Apollo.BaseMutationOptions<UpdateLayerMutation, UpdateLayerMutationVariables>;
export const UpdateBackgroundDocument = gql`
    mutation UpdateBackground($background: UpdateBackgroundRequestDto!) {
  updateBackground(background: $background) {
    id
    filename
    link
    dropboxLink
    alternativeBackgrounds {
      id
    }
    keywords
    newKeywords {
      app
      art_style
      outside
      inside
      time
      rooms
      style
      mood
      extra
    }
    visionLabels
  }
}
    `;
export type UpdateBackgroundMutationFn = Apollo.MutationFunction<UpdateBackgroundMutation, UpdateBackgroundMutationVariables>;

/**
 * __useUpdateBackgroundMutation__
 *
 * To run a mutation, you first call `useUpdateBackgroundMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBackgroundMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBackgroundMutation, { data, loading, error }] = useUpdateBackgroundMutation({
 *   variables: {
 *      background: // value for 'background'
 *   },
 * });
 */
export function useUpdateBackgroundMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBackgroundMutation, UpdateBackgroundMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBackgroundMutation, UpdateBackgroundMutationVariables>(UpdateBackgroundDocument, options);
      }
export type UpdateBackgroundMutationHookResult = ReturnType<typeof useUpdateBackgroundMutation>;
export type UpdateBackgroundMutationResult = Apollo.MutationResult<UpdateBackgroundMutation>;
export type UpdateBackgroundMutationOptions = Apollo.BaseMutationOptions<UpdateBackgroundMutation, UpdateBackgroundMutationVariables>;
export const UpdateChatPhotoDocument = gql`
    mutation UpdateChatPhoto($chatPhoto: UpdateChatPhotoRequestDto!) {
  updateChatPhoto(chatPhoto: $chatPhoto) {
    id
    name
    link
  }
}
    `;
export type UpdateChatPhotoMutationFn = Apollo.MutationFunction<UpdateChatPhotoMutation, UpdateChatPhotoMutationVariables>;

/**
 * __useUpdateChatPhotoMutation__
 *
 * To run a mutation, you first call `useUpdateChatPhotoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChatPhotoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChatPhotoMutation, { data, loading, error }] = useUpdateChatPhotoMutation({
 *   variables: {
 *      chatPhoto: // value for 'chatPhoto'
 *   },
 * });
 */
export function useUpdateChatPhotoMutation(baseOptions?: Apollo.MutationHookOptions<UpdateChatPhotoMutation, UpdateChatPhotoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateChatPhotoMutation, UpdateChatPhotoMutationVariables>(UpdateChatPhotoDocument, options);
      }
export type UpdateChatPhotoMutationHookResult = ReturnType<typeof useUpdateChatPhotoMutation>;
export type UpdateChatPhotoMutationResult = Apollo.MutationResult<UpdateChatPhotoMutation>;
export type UpdateChatPhotoMutationOptions = Apollo.BaseMutationOptions<UpdateChatPhotoMutation, UpdateChatPhotoMutationVariables>;
export const UpdateAudioDocument = gql`
    mutation UpdateAudio($audio: UpdateAudioRequestDto!) {
  updateAudio(audio: $audio) {
    id
    name
    link
    keywords
    newKeywords {
      app
      art_style
      outside
      inside
      time
      rooms
      style
      mood
      extra
    }
  }
}
    `;
export type UpdateAudioMutationFn = Apollo.MutationFunction<UpdateAudioMutation, UpdateAudioMutationVariables>;

/**
 * __useUpdateAudioMutation__
 *
 * To run a mutation, you first call `useUpdateAudioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAudioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAudioMutation, { data, loading, error }] = useUpdateAudioMutation({
 *   variables: {
 *      audio: // value for 'audio'
 *   },
 * });
 */
export function useUpdateAudioMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAudioMutation, UpdateAudioMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAudioMutation, UpdateAudioMutationVariables>(UpdateAudioDocument, options);
      }
export type UpdateAudioMutationHookResult = ReturnType<typeof useUpdateAudioMutation>;
export type UpdateAudioMutationResult = Apollo.MutationResult<UpdateAudioMutation>;
export type UpdateAudioMutationOptions = Apollo.BaseMutationOptions<UpdateAudioMutation, UpdateAudioMutationVariables>;
export const UpdateEmotionsDocument = gql`
    mutation UpdateEmotions($id: String!, $emotions: UpdateEmotionsRequestDto!) {
  updateEmotions(id: $id, emotions: $emotions) {
    newEmotions
    deletedEmotions
    updatedEmotions
  }
}
    `;
export type UpdateEmotionsMutationFn = Apollo.MutationFunction<UpdateEmotionsMutation, UpdateEmotionsMutationVariables>;

/**
 * __useUpdateEmotionsMutation__
 *
 * To run a mutation, you first call `useUpdateEmotionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmotionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmotionsMutation, { data, loading, error }] = useUpdateEmotionsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      emotions: // value for 'emotions'
 *   },
 * });
 */
export function useUpdateEmotionsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEmotionsMutation, UpdateEmotionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEmotionsMutation, UpdateEmotionsMutationVariables>(UpdateEmotionsDocument, options);
      }
export type UpdateEmotionsMutationHookResult = ReturnType<typeof useUpdateEmotionsMutation>;
export type UpdateEmotionsMutationResult = Apollo.MutationResult<UpdateEmotionsMutation>;
export type UpdateEmotionsMutationOptions = Apollo.BaseMutationOptions<UpdateEmotionsMutation, UpdateEmotionsMutationVariables>;