import React from 'react';
import {
	Flex,
	FormControl,
	FormLabel,
	Switch,
	Tooltip,
	useDisclosure,
} from '@chakra-ui/react';

interface BooleanInputProps {
	label: string;
	isChecked: boolean;
	onChange: () => void;
	htmlFor?: string;
	size?: string;
	tooltip?: string;
	isBorderBottom?: boolean;
}

export const BooleanInput: React.FC<BooleanInputProps> = ({
	label,
	isChecked,
	onChange,
	htmlFor = 'boolean-input',
	size = 'lg',
	tooltip,
	isBorderBottom,
}) => {
	const { isOpen, onClose, onOpen } = useDisclosure();

	return (
		<Flex
			as={FormControl}
			p="19px 0 16px 0"
			align="center"
			justify="space-between"
			borderBottom={isBorderBottom ? '1px solid #eeeef2' : ''}
		>
			<FormLabel fontSize="14px" htmlFor={htmlFor} mb="0">
				{label}
			</FormLabel>
			<Tooltip isOpen={isOpen} w="96px" offset={[60, 35]} label={tooltip}>
				<Switch
					onMouseMove={onOpen}
					onMouseLeave={onClose}
					onChange={onChange}
					id={htmlFor}
					isChecked={isChecked}
					size={size}
				/>
			</Tooltip>
		</Flex>
	);
};
