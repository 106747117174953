import { SelectedAssetsInfo } from 'widgets/create-card-modal/interfaces';

interface Combination {
	selectedBodies?: string;
	selectedClothes?: string;
	selectedHairs?: string;
	selectedLayers?: string;
}

enum SELECTED_ASSET_MAP {
	selectedBodies = 'bodyId',
	selectedClothes = 'clothesId',
	selectedHairs = 'hairId',
	selectedLayers = 'layerId',
}

export type Card = {
	bodyId: string;
	clothesId: string;
	hairId: string;
	layerId?: string;
};

export function getAllCombinations(obj: SelectedAssetsInfo): Card[] {
	const keys = Object.keys(obj);
	const result: Card[] = [];

	const helper = (currentIndex: number, currentCombination: Combination) => {
		if (currentIndex === keys.length) {
			result.push(currentCombination as Card);
			return;
		}

		const currentKey = keys[currentIndex];
		const currentArray = obj[currentKey as keyof SelectedAssetsInfo];

		if (currentArray.length === 0) {
			helper(currentIndex + 1, currentCombination);
			return;
		}

		currentArray.forEach((element) => {
			const updatedCombination = {
				...currentCombination,
				[SELECTED_ASSET_MAP[currentKey as keyof SelectedAssetsInfo]]: element,
			};
			helper(currentIndex + 1, updatedCombination);
		});
	};

	helper(0, {});

	return result;
}
