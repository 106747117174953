import React, { useCallback, useRef } from 'react';
import {
	Badge,
	Box,
	Button,
	Flex,
	Popover,
	PopoverBody,
	PopoverContent,
	PopoverTrigger,
	Portal,
	Text,
	useDisclosure,
} from '@chakra-ui/react';
import { useFormik } from 'formik';

import { GetBookDocument, GetBookQuery } from 'features/book/queries.gen';
import { useUpdateChapterMutation } from 'features/chapter/update/queries.gen';
import { ChapterByIdDocument } from 'features/story-editor/header/queries.gen';
import {
	BookProperty,
	ButtonType,
	trackPropertyChanged,
} from 'shared/analytics';
import { useAppToast } from 'shared/hooks/toast';
import { PencilIcon } from 'shared/icons';
import { ArrayElement } from 'shared/model';
import { UpdateTagsForm } from 'shared/ui';

export interface Props {
	chapter: ArrayElement<GetBookQuery['book']['chapters']>;
}

export const UpdateHasTwoPovs: React.FC<Props> = ({ chapter }) => {
	const toast = useAppToast();
	const { onOpen, onClose, isOpen } = useDisclosure();
	const containerRef = useRef<HTMLDivElement | null>(null);

	const [updateChapter] = useUpdateChapterMutation({
		onCompleted: () => {
			toast({
				title: 'HasTwoPovs updated successfully',
				status: 'success',
			});
		},
		onError: () =>
			toast({
				title: 'HasTwoPovs was not updated',
				status: 'error',
			}),
		refetchQueries: [ChapterByIdDocument, GetBookDocument],
	});

	const onSubmitHasTwoPovsModal = useCallback(
		(formValue: any) => {
			updateChapter({
				variables: {
					chapter: {
						id: chapter?.id || '',
						hasTwoPovs: formValue[0] === 'Yes',
					},
				},
			});
			trackPropertyChanged({
				entity: 'chapter',
				property: BookProperty.HAS_TWO_POWS,
				value: formValue[0],
				id: chapter?.id,
			});
		},
		[chapter?.id, updateChapter],
	);

	const updateHasTowPovsForm = useFormik({
		initialValues: {
			hasTwoPovs: chapter?.hasTwoPovs ? ['Yes'] : ['No'],
		},
		validateOnChange: true,
		enableReinitialize: true,
		onSubmit: (values) => onSubmitHasTwoPovsModal({ ...values }),
	});

	const onCloseHasTwoPovsModal = useCallback(() => {
		onClose();
		updateHasTowPovsForm.resetForm();
	}, [onClose, updateHasTowPovsForm]);

	if (!chapter) return null;

	return (
		<Box
			borderTop="1px solid #eeeef2"
			borderBottom="1px solid #eeeef2"
			ref={containerRef}
			paddingBottom="15px"
		>
			<Flex
				position="relative"
				p="13px 0"
				justifyContent="space-between"
				alignItems="center"
			>
				<Text fontWeight={500} fontSize="14px" lineHeight="114%">
					Has Two Povs
				</Text>
				<Flex>
					{chapter?.hasTwoPovs && (
						<Text fontSize="14px" mr="10px">
							{chapter?.hasTwoPovs}
						</Text>
					)}
					<Popover
						isOpen={isOpen}
						onOpen={onOpen}
						onClose={onCloseHasTwoPovsModal}
						placement="bottom-end"
						offset={[10, 10]}
					>
						<PopoverTrigger>
							<Button size="circle" variant="secondary">
								<PencilIcon w={6} h={6} />
							</Button>
						</PopoverTrigger>
						<Portal containerRef={containerRef}>
							<PopoverContent>
								<PopoverBody>
									<UpdateTagsForm
										submitButtonName={ButtonType.EDIT_CHAPTER_HAS_TWO_POVS}
										isMulti={false}
										formTitle="Has Two Povs"
										onClose={onCloseHasTwoPovsModal}
										options={['Yes', 'No']}
										selectedOptions={updateHasTowPovsForm.values.hasTwoPovs}
										onChange={(selected) =>
											updateHasTowPovsForm.setFieldValue(
												'hasTwoPovs',
												selected,
												true,
											)
										}
										onSubmit={() => {
											onClose();
											onSubmitHasTwoPovsModal(
												updateHasTowPovsForm.values.hasTwoPovs,
											);
										}}
									/>
								</PopoverBody>
							</PopoverContent>
						</Portal>
					</Popover>
				</Flex>
			</Flex>
			<Box flexWrap="wrap" gap="10px">
				<Badge>{chapter.hasTwoPovs ? 'Yes' : 'No'}</Badge>
			</Box>
		</Box>
	);
};
