import React, { useCallback, useState } from 'react';
import {
	Button,
	Flex,
	IconButton,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Spinner,
	Text,
	Tooltip,
} from '@chakra-ui/react';
import { Form, Formik } from 'formik';

import { GetBookDocument, GetBooksDocument } from 'features/book/queries.gen';
import { useGenerateStoryProcessDocMutation } from 'features/book/update/queries.gen';
import { ButtonType } from 'shared/analytics';
import { useAppToast } from 'shared/hooks/toast';
import {
	CheckmarkIcon,
	FileIcon,
	PencilIcon,
	PlusIcon,
	TrashBinIcon,
} from 'shared/icons';

import { DocNotesData } from '../edit-doc-description';
import { FormInputField } from '../form-input-field';
import styles from './styles.module.scss';

export type FormWithLinkValue = {
	formValue: string;
};

interface Props {
	label: string;
	inputValue: string;
	isRedacting: boolean;
	startRedacting: () => void;
	validationSchema: any;
	onSubmit: ({ formValue }: FormWithLinkValue) => void;
	onDelete: () => void;
	iconLink?: boolean;
	submitButtonName: ButtonType;
	onOpen?: () => void;
	onOpenUpdateModal?: () => void;
	isDescription?: boolean;
	docNotesData?: DocNotesData | null;
	isDocUpdate?: boolean;
	processDocType?: string;
	bookId?: string;
}

export const FormWithLink: React.FC<Props> = ({
	label,
	inputValue,
	isRedacting,
	startRedacting,
	validationSchema,
	onSubmit,
	onDelete,
	iconLink,
	submitButtonName,
	onOpen,
	onOpenUpdateModal,
	isDescription,
	docNotesData,
	isDocUpdate,
	processDocType,
	bookId = '',
}) => {
	const toast = useAppToast();
	const [isDocGenerating, setIsDocGenerating] = useState(false);

	const [generateStoryProcessDoc] = useGenerateStoryProcessDocMutation({
		refetchQueries: [GetBookDocument, GetBooksDocument],
	});

	const generateDocLink = useCallback(async () => {
		setIsDocGenerating(true);
		try {
			await generateStoryProcessDoc({
				variables: {
					bookId,
					type: processDocType as string,
				},
			});

			setIsDocGenerating(false);
			toast({
				title: 'Link generated successfully',
				status: 'success',
			});
		} catch {
			setIsDocGenerating(false);
			toast({
				title: 'Link was not updated',
				status: 'error',
			});
		}
	}, [bookId, generateStoryProcessDoc, processDocType, toast]);

	const onHandleEditDoc = useCallback(
		(resetForm: (arg0: { values: { formValue: any } }) => void) => {
			resetForm({
				values: {
					formValue: inputValue,
				},
			});
			startRedacting();
		},
		[inputValue, startRedacting],
	);
	return (
		<Formik
			initialValues={
				{
					formValue: inputValue || '',
				} as FormWithLinkValue
			}
			validationSchema={validationSchema}
			enableReinitialize
			onSubmit={onSubmit}
		>
			{({ resetForm }) => (
				<Form className={styles.root}>
					<div className={styles.left}>
						<Flex className={styles.text} align="center">
							<Text mr="10px">{label}</Text>
							{isDescription && !docNotesData ? (
								<Tooltip label="Add A/B notes">
									<Button variant="ghost" mx={2} onClick={onOpen}>
										<PlusIcon />
									</Button>
								</Tooltip>
							) : null}
							{isDescription && docNotesData ? (
								<Tooltip label="Open A/B notes">
									<Flex>
										<Text mx={1}>-</Text>
										<Text
											as="span"
											_hover={{
												textDecoration: 'underline',
												cursor: 'pointer',
											}}
											onClick={onOpenUpdateModal}
											noOfLines={1}
											maxW="350px"
										>
											{docNotesData?.name || ''}
										</Text>
									</Flex>
								</Tooltip>
							) : null}
						</Flex>
						{isRedacting && (
							<div className={styles.input}>
								<FormInputField
									name="formValue"
									placeholder="http//..."
									inputSize="sm"
									autocomplete="off"
								/>
							</div>
						)}
					</div>
					<div className={styles.right}>
						{isRedacting ? (
							<div>
								{isDocUpdate ? (
									<Spinner size="sm" mr="7px" />
								) : (
									<>
										<Tooltip label="Apply">
											<Button
												name={submitButtonName}
												size="circle"
												variant="secondary"
												type="submit"
											>
												<CheckmarkIcon w={4} h={4} />
											</Button>
										</Tooltip>
										<Tooltip label="Remove">
											<Button
												size="circle"
												variant="secondary"
												onClick={onDelete}
											>
												<TrashBinIcon w={6} h={6} />
											</Button>
										</Tooltip>
									</>
								)}
							</div>
						) : (
							<>
								{inputValue && (
									<>
										<a
											href={inputValue}
											className={styles.link}
											target="_blank"
											rel="noreferrer"
										>
											{iconLink ? <FileIcon w={5} h={5} /> : 'link'}
										</a>
										<Menu placement="bottom-end">
											<MenuButton
												as={IconButton}
												variant="ghost"
												size="sm"
												padding="10px 0"
												icon={<PencilIcon w={6} h={6} />}
											/>
											<MenuList minW="180px">
												<MenuItem onClick={() => onHandleEditDoc(resetForm)}>
													Edit
												</MenuItem>
											</MenuList>
										</Menu>
									</>
								)}

								{!inputValue && (
									<Flex justify="flex-end">
										{isDocGenerating ? (
											<Spinner size="sm" mr="7px" />
										) : (
											<Menu placement="bottom-end">
												<Tooltip label="Add Doc">
													<MenuButton
														as={IconButton}
														variant="ghost"
														size="sm"
														padding="10px 0"
														icon={<PlusIcon />}
													/>
												</Tooltip>
												<MenuList minW="180px">
													<MenuItem onClick={generateDocLink}>
														Generate a Doc file
													</MenuItem>
													<MenuItem onClick={startRedacting}>
														Add Doc file manually
													</MenuItem>
												</MenuList>
											</Menu>
										)}
									</Flex>
								)}
							</>
						)}
					</div>
				</Form>
			)}
		</Formik>
	);
};
