import React from 'react';
import { createIcon } from '@chakra-ui/react';

export const FeaturesIcon = createIcon({
	viewBox: '0 0 24 24',
	path: (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M10.525 4H4.225C4.10074 4 4 4.10074 4 4.225V8.275C4 8.39926 4.10074 8.5 4.225 8.5H10.525C10.6493 8.5 10.75 8.39926 10.75 8.275V4.225C10.75 4.10074 10.6493 4 10.525 4Z"
				fill="#644DED"
			/>
			<path
				d="M18.775 14.5005H12.475C12.3507 14.5005 12.25 14.6012 12.25 14.7255V18.7755C12.25 18.8998 12.3507 19.0005 12.475 19.0005H18.775C18.8993 19.0005 19 18.8998 19 18.7755V14.7255C19 14.6012 18.8993 14.5005 18.775 14.5005Z"
				fill="#644DED"
			/>
			<path
				d="M10.525 9.25H4.225C4.10074 9.25 4 9.35913 4 9.49375V18.7562C4 18.8909 4.10074 19 4.225 19H10.525C10.6493 19 10.75 18.8909 10.75 18.7562V9.49375C10.75 9.35913 10.6493 9.25 10.525 9.25Z"
				fill="#AD9FFF"
			/>
			<path
				d="M18.775 4H12.475C12.3507 4 12.25 4.10074 12.25 4.225V12.775C12.25 12.8993 12.3507 13 12.475 13H18.775C18.8993 13 19 12.8993 19 12.775V4.225C19 4.10074 18.8993 4 18.775 4Z"
				fill="#AD9FFF"
			/>
		</svg>
	),
});
