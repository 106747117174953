import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { NetworkStatus } from '@apollo/client';
import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	Box,
	Button,
	HStack,
	Spinner,
	Table,
	TableContainer,
	Tbody,
	Text,
	Th,
	Thead,
	Tr,
	useDisclosure,
} from '@chakra-ui/react';

import { GetBookInfoDocument } from 'features/book/queries.gen';
import { useAppToast } from 'shared/hooks/toast';
import { ArrayElement } from 'shared/model';
import {
	CardsByStoryDocument,
	CardsByStoryQuery,
	useCardsByStoryQuery,
	useDeleteCardMutation,
} from 'widgets/cards-list/graphql/queries.gen';
import { CardListRow } from 'widgets/cards-list/ui/list-row';

type CardListRouteParams = {
	readonly bookId: string;
};

export const CardsList: React.FC = () => {
	const { bookId } = useParams<CardListRouteParams>();
	if (!bookId) throw Error();

	const toast = useAppToast();
	const cancelRef = useRef(null);
	const [deletedCard, setDeletedCard] = useState<ArrayElement<
		CardsByStoryQuery['cards']['cards']
	> | null>(null);

	const {
		isOpen: isOpenDeleteModal,
		onClose: onCloseDeleteModal,
		onOpen: onOpeDeleteModal,
	} = useDisclosure();

	const { data, loading, error, networkStatus, refetch } = useCardsByStoryQuery(
		{
			variables: { filter: { bookId }, limit: 100 },
			fetchPolicy: 'network-only',
			nextFetchPolicy: 'network-only',
			notifyOnNetworkStatusChange: true,
		},
	);

	const [deleteCard, { loading: deleteCardLoading }] = useDeleteCardMutation({
		refetchQueries: [
			{
				variables: { filter: { bookId }, limit: 100 },
				query: CardsByStoryDocument,
			},
			GetBookInfoDocument,
		],
		onCompleted: () => {
			toast({
				title: `${deletedCard?.filename} deleted successfully`,
				status: 'success',
			});
		},
		onError: () => {
			toast({
				title: `Can't remove card`,
				status: 'error',
			});
		},
	});

	const handleCardDeleteAttempt = useCallback(
		(card) => async () => {
			setDeletedCard(card);
			onOpeDeleteModal();
		},
		[onOpeDeleteModal],
	);

	const handleCardDeleteSubmit = useCallback(async () => {
		await deleteCard({
			variables: {
				id: deletedCard?.id || '',
			},
		});
		onCloseDeleteModal();
	}, [deleteCard, deletedCard?.id, onCloseDeleteModal]);

	useEffect(() => {
		if (error?.message)
			toast({
				title: error?.message,
				status: 'error',
			});
	}, [error, toast]);

	if (networkStatus === NetworkStatus.loading) {
		return <Spinner size="xl" m="auto" />;
	}

	return (
		<>
			<Box
				d="flex"
				flex-direction="column"
				flex="1 1"
				overflow="hidden"
				h="calc(100vh - 195px)"
			>
				<Box d="flex" flex="1 1" overflow="auto">
					<TableContainer width="100%" overflowY="auto">
						<Table>
							<Thead position="sticky" left={0} top={0} zIndex={101}>
								<Tr>
									<Th position="sticky" left={0} zIndex={100}>
										<HStack>
											<Text
												textAlign="center"
												minW={7}
												mr={5}
												fontFamily="sans-serif"
											>
												№
											</Text>
											<Text minW="75px" mr={1}>
												Pic
											</Text>
											<Text minW="150px" noOfLines={1}>
												Cards Set Name
											</Text>
										</HStack>
									</Th>
									<Th>Card Type</Th>
									<Th>Chapters</Th>
									<Th>Hero Name</Th>
									<Th>Last Edited</Th>
									<Th />
								</Tr>
							</Thead>
							<Tbody>
								{data?.cards.cards.map((card, idx) => (
									<CardListRow
										key={card.id}
										index={idx + 1}
										card={card}
										currentBookId={bookId}
										handleCardDelete={handleCardDeleteAttempt}
										refetch={refetch}
										loading={loading}
									/>
								))}
							</Tbody>
						</Table>
					</TableContainer>
				</Box>
			</Box>
			<AlertDialog
				isOpen={isOpenDeleteModal}
				leastDestructiveRef={cancelRef}
				onClose={onCloseDeleteModal}
			>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize="lg" fontWeight="bold">
							Cards
						</AlertDialogHeader>

						<AlertDialogBody>
							The card preset and the generated cards will be deleted and
							can&apos;t be restored. Are you sure you want to continue?
						</AlertDialogBody>

						<AlertDialogFooter>
							{!deleteCardLoading && (
								<Button
									ref={cancelRef}
									variant="ghost"
									onClick={onCloseDeleteModal}
								>
									Cancel
								</Button>
							)}
							<Button
								onClick={handleCardDeleteSubmit}
								ml={3}
								isLoading={deleteCardLoading}
							>
								Delete
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
		</>
	);
};
