import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Flex } from '@chakra-ui/react';

import { LangSelector } from 'features/story-editor/lang-selector/index';

interface LangSelectorWrapperProps {
	localizedGoogleDocLinks: {
		EN?: string;
		ES?: string;
		FR?: string;
		DE?: string;
		NL?: string;
		PT?: string;
		IT?: string;
		PL?: string;
		SV?: string;
		DA?: string;
		NO?: string;
		FI?: string;
		ID?: string;
		CS?: string;
		RO?: string;
		HU?: string;
		TR?: string;
		JA?: string;
		KO?: string;
		'PT-BR'?: string;
	};
	prodPageName?: string;
}

export const LangSelectorWrapper: React.FC<LangSelectorWrapperProps> = ({
	prodPageName,
	localizedGoogleDocLinks,
}) => {
	const { googleDocId } = useParams();

	const localizedDocsLength = useMemo(
		() => Object.values(localizedGoogleDocLinks).filter((link) => link).length,
		[localizedGoogleDocLinks],
	);

	return (
		<Flex alignItems="center" gap="20px" color="#484964">
			{prodPageName && localizedDocsLength > 1 ? (
				<>
					<LangSelector
						googleDocId={googleDocId || 'no-doc'}
						localizedGoogleDocLinks={localizedGoogleDocLinks}
					/>
					<Box
						margin="0 5px"
						width="1px"
						height="23px"
						backgroundColor="#eeeef2"
					/>
				</>
			) : null}
		</Flex>
	);
};
