import React from 'react';
import { createIcon } from '@chakra-ui/react';

export const NoColorIcon = createIcon({
	viewBox: '0 0 24 24',
	path: (
		<>
			<path
				d="M16.1896 9.0488 12.74 5.38641c-.1974-.20956-.5306-.20956-.7279 0L8.56242 9.0488c-2.08323 2.2177-2.08323 5.8128 0 8.0136.48702.5259 1.07673.9462 1.73278 1.2352.6559.2889 1.3642.4404 2.0809.4447.7167-.0044 1.425-.1558 2.0808-.4447.6561-.289 1.2457-.7094 1.7328-1.2353 2.0831-2.2176 2.0831-5.8127-.0001-8.0135Zm-.4871 7.56c-.858.9277-2.0629 1.4571-3.3265 1.4616-1.2637-.0045-2.46846-.5339-3.32643-1.4616-.89262-.9681-1.38819-2.2365-1.38819-3.5532s.4956-2.5852 1.38819-3.5532L12.376 5.9912l3.3264 3.5112c.8926.9681 1.3882 2.2365 1.3882 3.5532s-.4955 2.5852-1.3881 3.5532Z"
				fill="currentColor"
				stroke="currentColor"
				strokeWidth=".7"
			/>
			<path
				d="M6.90771 7.08591 18.0923 18.9141"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</>
	),
});
