import React from 'react';
import {
	Button,
	Flex,
	Image,
	Modal,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
} from '@chakra-ui/react';

import { ButtonType } from 'shared/analytics';

interface ImagePreviewProps {
	isOpen: boolean;
	onClose: () => void;
	filename: string;
	src: string;
	onConfirm?: () => void;
	isPending?: boolean;
}

export const ImagePreview: React.FC<ImagePreviewProps> = ({
	isOpen,
	onClose,
	filename,
	src,
	onConfirm,
	isPending,
}) => {
	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			variant="fullscreen"
			closeOnEsc={!!isPending}
			closeOnOverlayClick={!!isPending}
		>
			<ModalOverlay />
			<ModalContent overflow="hidden">
				<ModalHeader>
					<Flex>
						<Text>{filename}</Text>
					</Flex>
				</ModalHeader>
				<ModalCloseButton disabled={isPending} />

				{/* <Flex alignItems="center" justifyContent="center" flex="1"> */}
				<Image objectFit="contain" src={src} maxH="calc(100% - 37px)" />
				{/* </Flex> */}

				{onConfirm ? (
					<ModalFooter>
						<Flex>
							<Button disabled={isPending} variant="ghost" onClick={onClose}>
								Cancel
							</Button>
							<Button
								name={ButtonType.ACCEPT_IMAGE_PREVIEW}
								disabled={isPending}
								onClick={onConfirm}
							>
								Apply
							</Button>
						</Flex>
					</ModalFooter>
				) : null}
			</ModalContent>
		</Modal>
	);
};
