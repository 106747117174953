import { EditorState, Modifier } from 'draft-js';

export const insertDecorationTag = (
	editorState: EditorState,
	tag: 'b' | 'i',
) => {
	const currentContent = editorState.getCurrentContent();
	const currentSelection = editorState.getSelection();

	const start = currentSelection.getStartOffset();
	const end = currentSelection.getEndOffset();

	const selectionBefore = editorState.getSelection().merge({
		focusOffset: start,
		anchorOffset: start,
	});

	const selectionAfter = editorState.getSelection().merge({
		focusOffset: end + 2 + tag.length,
		anchorOffset: end + 2 + tag.length,
	});

	let newContent = Modifier.insertText(
		currentContent,
		selectionBefore,
		`<${tag}>`,
	);

	newContent = Modifier.insertText(newContent, selectionAfter, `</${tag}>`);

	const newEditorState = EditorState.push(
		editorState,
		newContent,
		'insert-characters',
	);

	return EditorState.forceSelection(
		newEditorState,
		newContent.getSelectionAfter(),
	);
};
