import React from 'react';
import { Spinner } from '@chakra-ui/react';

import { Asset } from 'entities/assets/interfaces/asset';
import { AssetViewTabs } from 'widgets/update-asset-modal/lib/asset-view-tabs';

import { useGetLayerQuery } from '../modal/queries.gen';
import { ViewAsset } from '../view';

interface LoadLayerProps {
	assetId: string;
	activeTab: AssetViewTabs;
}

export const LoadLayer: React.FC<LoadLayerProps> = ({ assetId, activeTab }) => {
	const { data, loading } = useGetLayerQuery({
		variables: {
			id: assetId,
		},
	});

	if (loading) return <Spinner />;

	return (
		<ViewAsset asset={data?.layer as Asset} chats={[]} activeTab={activeTab} />
	);
};
