import * as Types from '../../../models.gen';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteLayerMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type DeleteLayerMutation = { deleteLayer: { name?: string | null, link?: string | null } };


export const DeleteLayerDocument = gql`
    mutation DeleteLayer($id: String!) {
  deleteLayer(id: $id) {
    name
    link
  }
}
    `;
export type DeleteLayerMutationFn = Apollo.MutationFunction<DeleteLayerMutation, DeleteLayerMutationVariables>;

/**
 * __useDeleteLayerMutation__
 *
 * To run a mutation, you first call `useDeleteLayerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLayerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLayerMutation, { data, loading, error }] = useDeleteLayerMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteLayerMutation(baseOptions?: Apollo.MutationHookOptions<DeleteLayerMutation, DeleteLayerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteLayerMutation, DeleteLayerMutationVariables>(DeleteLayerDocument, options);
      }
export type DeleteLayerMutationHookResult = ReturnType<typeof useDeleteLayerMutation>;
export type DeleteLayerMutationResult = Apollo.MutationResult<DeleteLayerMutation>;
export type DeleteLayerMutationOptions = Apollo.BaseMutationOptions<DeleteLayerMutation, DeleteLayerMutationVariables>;