import React from 'react';
import { createIcon } from '@chakra-ui/react';

export const StarsIcon = createIcon({
	viewBox: '0 0 27 27',
	path: (
		<g fill="none">
			<path
				d="M14.3744 12.2065C14.7799 12.0662 14.7799 11.8167 14.3744 11.6608L12.1759 10.8188C11.7861 10.6785 11.3339 10.2263 11.178 9.82088L10.336 7.62234C10.1957 7.21693 9.94619 7.21693 9.79026 7.62234L8.94827 9.82088C8.80794 10.2107 8.35575 10.6629 7.95035 10.8188L5.75181 11.6608C5.3464 11.8011 5.3464 12.0506 5.75181 12.2065L7.95035 13.0485C8.34016 13.1889 8.79234 13.641 8.94827 14.0464L9.79026 16.245C9.9306 16.6504 10.1801 16.6504 10.336 16.245L11.178 14.0464C11.3183 13.6566 11.7705 13.2045 12.1759 13.0485L14.3744 12.2065Z"
				stroke="currentColor"
				strokeWidth="1.45"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M25.9809 19.1068C27.1909 18.8043 27.1909 18.3052 25.9809 18.0027L23.3038 17.3372C22.0939 17.0347 20.8536 15.7945 20.5511 14.5845L19.8857 11.9074C19.5832 10.6974 19.084 10.6974 18.7815 11.9074L18.1161 14.5845C17.8136 15.7945 16.5733 17.0347 15.3634 17.3372L12.6863 18.0027C11.4763 18.3052 11.4763 18.8043 12.6863 19.1068L15.3634 19.7723C16.5733 20.0747 17.8136 21.315 18.1161 22.525L18.7815 25.202C19.084 26.412 19.5832 26.412 19.8857 25.202L20.5511 22.525C20.8536 21.315 22.0939 20.0747 23.3038 19.7723L25.9809 19.1068Z"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</g>
	),
});
