import { RawDraftContentState } from 'draft-js';

import { hexToRgb } from 'shared/utils';

export const deserialize = (
	contentState: RawDraftContentState,
): { text: string; styles: any } => {
	let offset = 0;

	const { blocks } = contentState;
	const textToSave = blocks
		.map((block) => `${block.text}\n`)
		.join('')
		.slice(0, -1);

	const stylesToSave = blocks.map((block) => {
		offset += block.text.length + 1;

		return block.inlineStyleRanges.map((styleRange) => {
			return {
				range: {
					startIndex: offset - block.text.length + styleRange.offset,
					endIndex:
						offset - block.text.length + styleRange.offset + styleRange.length,
				},
				textStyle: {
					...(styleRange.style?.includes('#')
						? {
								backgroundColor: {
									color: {
										rgbColor: hexToRgb(styleRange.style),
									},
								},
						  }
						: {}),
					...(styleRange.style === 'BOLD' ? { bold: true } : {}),
					...(styleRange.style === 'ITALIC' ? { italic: true } : {}),
				},
				...(styleRange.style?.includes('#')
					? { fields: 'backgroundColor' }
					: {}),
				...(styleRange.style === 'BOLD' ? { fields: 'bold' } : {}),
				...(styleRange.style === 'ITALIC' ? { fields: 'italic' } : {}), // ! maybe should be only one empty {}
			};
		});
	});

	return {
		text: textToSave,
		styles: [
			{
				range: {
					startIndex: 1,
					endIndex: textToSave.length + 1,
				},
				fields: 'backgroundColor',
			},
			...stylesToSave,
		]
			.flat()
			.filter(({ range }) => range.startIndex !== range.endIndex)
			.filter(({ fields }) => !!fields),
	};
};
