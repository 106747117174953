import React, {
	Dispatch,
	SetStateAction,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { Box, useDisclosure } from '@chakra-ui/react';
import { DndContext } from '@dnd-kit/core';
import {
	restrictToHorizontalAxis,
	restrictToWindowEdges,
} from '@dnd-kit/modifiers';
import { EditorState } from 'draft-js';

import { scriptModel } from 'entities/script';
import { FEATURES_TYPE } from 'pages/script-editor';
import { EventTypes, sendAmplitudeData } from 'shared/analytics';
import {
	AmplitudeInfo,
	CHANGE_TONE_EVENTS,
	DOC_TYPES_MAP,
	PROMPTS_INFO_EVENTS,
} from 'widgets/ask-ai-modal/ui/types';

import { useGetParamsInfo } from '../ask-ai-modal/hooks/getParamsInfo';
import { AiModal } from '../ask-ai-modal/ui';
import { CommentsList } from './ui/comments';
import { DraftEditor } from './ui/editor';
import { Errors } from './ui/errors';
import { Toolbar } from './ui/toolbar';

interface TextEditorProps {
	editorState: EditorState;
	setEditorState: Dispatch<SetStateAction<EditorState>>;
	handleOpenGuideline: () => void;
	withComments: boolean;
	features: FEATURES_TYPE | null;
}

export const TextEditor: React.FC<TextEditorProps> = ({
	editorState,
	setEditorState,
	handleOpenGuideline,
	withComments,
	features,
}) => {
	const counterRef = useRef<HTMLDivElement>(null);
	const [coordinates, setCoordinates] = useState(3);

	const [isErrorBarOpened, setIsErrorBarOpened] = useState(false);
	const [selectedText, setSelectedText] = useState<string>('');
	const [selectedPrompt, setSelectedPrompt] = useState<{
		value: string;
		option?: string;
	}>({ value: '', option: '' });

	const { chapterId, bookId, googleDocId } = useParams<{
		chapterId: string;
		bookId: string;
		googleDocId: string;
	}>();

	const { bookCustomId, chapterOrder, doc } = useGetParamsInfo(
		chapterId || '',
		bookId || '',
		googleDocId || '',
	);

	const amplitudeInfo = useMemo<AmplitudeInfo>(
		() => ({
			bookId: bookCustomId,
			chapterNumber: chapterOrder,
			doc: DOC_TYPES_MAP[doc] || 'additional',
		}),
		[bookCustomId, chapterOrder, doc],
	);

	const {
		onOpen: handleOpenCommentsList,
		onClose: handleCloseCommentsList,
		isOpen: isCommentsListOpened,
	} = useDisclosure();

	const {
		onOpen: handleOpenAiModal,
		onClose: handleCloseAiModal,
		isOpen: isOpenModal,
	} = useDisclosure();

	const handleAiModalOpen = (prompt: string, option?: string) => {
		sendAmplitudeData(EventTypes.ASKAI_OPTION_CLICK, {
			...amplitudeInfo,
			option: option ? CHANGE_TONE_EVENTS[option] : PROMPTS_INFO_EVENTS[prompt],
		});

		setSelectedPrompt({ value: prompt, option: option || '' });
		handleOpenAiModal();
	};

	useEffect(() => {
		return () => {
			scriptModel.resetValidationErrors();
		};
	}, []);

	useEffect(() => {
		if (!isOpenModal) {
			setSelectedText('');
		}
	}, [isOpenModal]);

	return (
		<Box w="full" h="full" pt="64px">
			<Toolbar
				isOpenAiModal={isOpenModal}
				counterRef={counterRef}
				editorState={editorState}
				setEditorState={setEditorState}
				isErrorBarOpened={isErrorBarOpened}
				toggleErrorBar={() => setIsErrorBarOpened((prev) => !prev)}
				handleOpenGuideline={handleOpenGuideline}
				handleAiModalOpen={handleAiModalOpen}
				handleOpenCommentsList={handleOpenCommentsList}
				setSelectedText={setSelectedText}
				withComments={withComments}
			/>
			<DndContext
				modifiers={[restrictToHorizontalAxis, restrictToWindowEdges]}
				onDragEnd={({ delta }) => {
					setCoordinates(coordinates - delta.x);
				}}
			>
				<Box d="flex">
					<DraftEditor
						editorState={editorState}
						setEditorState={setEditorState}
						isErrorBarOpened={isErrorBarOpened}
						withComments={withComments}
						counterRef={counterRef}
						features={features}
					/>
					{isErrorBarOpened && (
						<Errors
							isErrorBarOpened={isErrorBarOpened}
							editorState={editorState}
							setEditorState={setEditorState}
						/>
					)}
					{isCommentsListOpened && withComments && (
						<Box position="absolute" right="354px" top="64px">
							<CommentsList
								editorState={editorState}
								setEditorState={setEditorState}
								isCommentsListOpened={isCommentsListOpened}
								handleCloseCommentsList={handleCloseCommentsList}
							/>
						</Box>
					)}
				</Box>
				{isOpenModal && (
					<AiModal
						isOpenModal={isOpenModal}
						onClose={handleCloseAiModal}
						selectedPrompt={selectedPrompt}
						handleAiModalOpen={handleAiModalOpen}
						selectedText={selectedText}
						amplitudeInfo={amplitudeInfo}
						coordinates={coordinates}
					/>
				)}
			</DndContext>
		</Box>
	);
};
