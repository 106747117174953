export const imageResolutionValidate = async (
	file: File | null | undefined,
	{ width, height }: { width: number | string; height: number | string },
): Promise<boolean> => {
	if (!file || !['image/png', 'image/jpeg', 'image/jpg'].includes(file.type)) {
		return false;
	}
	const reader = new window.FileReader();
	reader.readAsDataURL(file);
	type returnType = { width: number; height: number };

	const { width: imageWidth, height: imageHeight }: returnType =
		await new Promise((resolve) => {
			reader.onload = async function _(_file) {
				const image = new window.Image();
				if (_file.target?.result) {
					image.src = _file.target.result.toString();
				}
				const { width: w, height: h }: returnType = await new Promise((res) => {
					image.onload = () => {
						const { width: ww, height: hh } = image;
						res({ width: ww, height: hh });
					};
				});
				resolve({ width: w, height: h });
			};
		});

	return imageWidth === width && imageHeight === height;
};
