export enum Modal {
	CREATE_BOOK = 'New Story',
	DELETE_BOOK = 'Book Deletion',
	CREATE_CHAPTER = 'New Chapter',
	DELETE_CHAPTER = 'Chapter Deletion',
	ADD_STORY = 'Add Story',
	CREATE_HERO = 'New Hero',
	DELETE_HERO = 'Hero Deletion',
	CREATE_HAIR = 'New Hair',
	DELETE_HAIR = 'Hair Deletion',
	CREATE_CLOTHES = 'New Clothes',
	DELETE_CLOTHES = 'Clothes Deletion',
	CREATE_BACKGROUND = 'New Background',
	DELETE_BACKGROUND = 'Background Deletion',
	CREATE_ITEM = 'New Item',
	DELETE_ITEM = 'Item Deletion',
	CREATE_BODY = 'New Body',
	DELETE_BODY = 'Body Deletion',
	CREATE_EMOTION = 'New Emotion',
	DELETE_EMOTION = 'Emotion Deletion',
	CREATE_LAYER = 'New Layer',
	DELETE_LAYER = 'Layer Deletion',

	ART_DOC = 'Art Doc',
	CHAPTER_PROPERTIES = 'Chapter Properties',
	AN_EXISTING_HERO = 'An Existing Hero',
	ADD_BACKGROUND = 'Add Background',
	ADD_ITEM = 'Add Item',
	HERO_DETAILS = 'Hero Details',
	BACKGROUND_DETAILS = 'Background Details',
	ITEM_DETAILS = 'Item Details',
	BODY_DETAILS = 'Body Details',
	LAYER_DETAILS = 'Layer Details',
	HAIR_DETAILS = 'Hair Details',
	CLOTH_DETAILS = 'Cloth Details',
	ASSET_DETAILS = 'Asset Details',
	REMOVE_HERO = 'Hero Removing',
	REMOVE_BACKGROUND = 'Background Removing',
	REMOVE_ITEM = 'Item Removing',
	BODY_FILTER = 'Body Filter',
	CLOTH_FILTER = 'Cloth Filter',
	HAIR_FILTER = 'Hair Filter',
	ITEM_FILTER = 'Item Filter',
	LAYER_FILTER = 'Layer Filter',
	BACKGROUND_FILTER = 'Background Filter',
	BODY_KEYWORDS = 'Body Keywords',
	CLOTH_KEYWORDS = 'Cloth Keywords',
	HAIR_KEYWORDS = 'Hair Keywords',
	ITEM_KEYWORDS = 'Item Keywords',
	LAYER_KEYWORDS = 'Layer Keywords',
	BACKGROUND_KEYWORDS = 'Background Keywords',
	BUILDER_ADD_STORY = 'Builder Add Story',
	BUILDER_TRANSFER = 'Builder Transfer',
	CLOTHING_BEFORE_TEXT_SAVING = 'Clothing Before Text Saving',
	CLOTHING_WITHOUT_LUA_SAVING = 'Clothing Without Lua Saving',
	FIND_AND_REPLACE = 'Find And Replace',
	FIND_BY_TEXT = 'Find By Text',
	FIND_BY_DIALOG = 'Find By Dialog',

	EDIT_ASSET = 'edit asset',

	EDIT_CHAPTER = 'edit chapter',

	ADD_BOOKS_TO_CHARACTER = 'add books to character',

	ASSET_IMAGE_PREVIEW = 'asset image preview',
	ASSET_IMAGE_VIEW = 'asset image view',

	SELECT_CHAPTERS = 'select chapters',

	ADD_EXISTING_CHARACTER = 'add existing character to book',
	ADD_HERO_TO_CARD = 'add hero to card',

	CREATE_CHARACTER = 'create character',
	CREATE_CARD = 'create card',
	UPDATE_CARD = 'update card',
	EDIT_CHARACTER = 'edit character',

	EDIT_DOC_DESCRIPTION = 'edit doc description',

	UPLOAD_IMAGE = 'upload image',

	LEGACY_WRITER_CHAPTER_PICKER = 'legacy writer chapter picker',

	TRANSFER_BETWEEN_BUCKETS = 'transfer between buckets',

	CREATE_BUILD_TEMPLATE = 'create build template',
	EDIT_BUILD_TEMPLATE = 'edit build template',
}

export const MODAL_TYPE_FILTER = {
	body: Modal.BODY_FILTER,
	item: Modal.ITEM_FILTER,
	layer: Modal.LAYER_FILTER,
	hair: Modal.HAIR_FILTER,
	clothes: Modal.CLOTH_FILTER,
	background: Modal.BACKGROUND_FILTER,
};
export const MODAL_TYPE_KEYWORDS = {
	body: Modal.BODY_KEYWORDS,
	item: Modal.ITEM_KEYWORDS,
	layer: Modal.LAYER_KEYWORDS,
	hair: Modal.HAIR_KEYWORDS,
	clothes: Modal.CLOTH_KEYWORDS,
	background: Modal.BACKGROUND_KEYWORDS,
};

export const MODAL_ASSET_DETAILS = {
	body: Modal.BODY_DETAILS,
	item: Modal.ITEM_DETAILS,
	layer: Modal.LAYER_DETAILS,
	hair: Modal.HAIR_DETAILS,
	clothes: Modal.CLOTH_DETAILS,
	background: Modal.BACKGROUND_DETAILS,
};
