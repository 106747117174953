import React from 'react';
import { createIcon } from '@chakra-ui/react';

export const SizeUpIcon = createIcon({
	viewBox: '0 0 24 24',
	path: (
		<path
			d="M14.3333 5h4.6666v4.6666M9.6666 18.9987H5V14.332M18.9999 5l-4.6666 4.6666M5 18.9987l4.6666-4.6667"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	),
});
