/* eslint-disable sonarjs/no-identical-functions */
import React, { Dispatch, SetStateAction, useState } from 'react';
import {
	Box,
	Button,
	Flex,
	Menu,
	MenuButton,
	MenuItemOption,
	MenuList,
	MenuOptionGroup,
	Text,
	Tooltip,
} from '@chakra-ui/react';
import { EditorState } from 'draft-js';
import { useStore } from 'effector-react';
import _ from 'lodash';

import { scriptModel } from 'entities/script';
import { ArrowDownIcon, WarningIcon } from 'shared/icons';
import { MotionBox } from 'shared/ui';

interface ErrorsProps {
	isErrorBarOpened: boolean;
	editorState: EditorState;
	setEditorState: Dispatch<SetStateAction<EditorState>>;
}

export const Errors: React.FC<ErrorsProps> = ({
	isErrorBarOpened,
	editorState,
	setEditorState,
}) => {
	const [errorsFilter, setErrorsFilter] = useState('all');
	const validationErrors = useStore(scriptModel.$validationErrors);
	const validationWarnings = useStore(scriptModel.$validationWarnings);

	return (
		<MotionBox
			animate={isErrorBarOpened ? 'open' : 'closed'}
			variants={{
				open: { width: '256px' },
				closed: { width: '0px' },
			}}
			transition={{ duration: 0.2, ease: 'easeOut' }}
			w="0"
			h="full"
		>
			<Box
				w="16rem"
				h="calc(100vh - 115px)"
				borderLeftWidth="1px"
				borderColor="#EEEEF2"
				overflowY="scroll"
				background="white"
			>
				<Flex
					alignItems="center"
					py={2}
					px={5}
					borderBottom="1px solid #F3F3F8"
				>
					<Text fontSize="12px" mr={2}>
						Show me:
					</Text>
					<Menu>
						<MenuButton
							background="rgba(100, 77, 237, 0.08)"
							borderRadius="8px"
						>
							<Button
								size="xs"
								variant="secondary"
								aria-label="toggle filter"
								rightIcon={<ArrowDownIcon />}
							>
								{_.capitalize(errorsFilter)}
							</Button>
						</MenuButton>
						<MenuList>
							<MenuOptionGroup
								onChange={(v) => setErrorsFilter(v as string)}
								defaultValue="all"
								type="radio"
							>
								<MenuItemOption value="all">All</MenuItemOption>
								<MenuItemOption value="errors">Errors</MenuItemOption>
								<MenuItemOption value="warnings">Warnings</MenuItemOption>
							</MenuOptionGroup>
						</MenuList>
					</Menu>
				</Flex>
				{errorsFilter === 'all' || errorsFilter === 'errors'
					? validationErrors.map((error) => (
							<Box
								w="full"
								d="flex"
								borderBottomWidth="1px"
								borderColor="#EEEEF2"
								py="4"
								pr="4"
								pl="4"
								key={error.id}
								_hover={{
									background: 'rgba(100, 77, 237, 0.05)',
									cursor: 'pointer',
								}}
								onMouseDown={(e) => e.preventDefault()}
								onClick={() => {
									const contentBlocks = editorState
										.getCurrentContent()
										.getBlocksAsArray();

									if (contentBlocks.length >= error.line) {
										const blockToFocus = contentBlocks[error.line];
										const blockToFocusLength = blockToFocus.getText().length;
										const selection = editorState.getSelection().merge({
											anchorKey: blockToFocus.getKey(),
											focusKey: blockToFocus.getKey(),
											focusOffset:
												blockToFocusLength <= 100 ? blockToFocusLength : 100,
											anchorOffset:
												blockToFocusLength <= 100 ? blockToFocusLength : 100,
										});
										setEditorState(
											EditorState.forceSelection(editorState, selection),
										);

										setTimeout(() => {
											window
												.getSelection()
												?.focusNode?.parentElement?.scrollIntoView({
													inline: 'center',
													block: 'center',
												});
										}, 50);
									}
								}}
							>
								<Flex
									alignItems="center"
									justifyContent="space-between"
									w="full"
								>
									<Flex pr={2}>
										<Text fontSize="xs" mr="4">
											{error.line + 1}
										</Text>
										<Text fontSize="xs" wordBreak="break-word">
											{error.message}
										</Text>
									</Flex>
									<Tooltip label="Error">
										<WarningIcon w={6} h={6} color="#ED0000" />
									</Tooltip>
								</Flex>
							</Box>
					  ))
					: null}
				{errorsFilter === 'all' || errorsFilter === 'warnings'
					? validationWarnings.map((warning) => (
							<Box
								w="full"
								d="flex"
								borderBottomWidth="1px"
								borderColor="#EEEEF2"
								py="4"
								pr="4"
								pl="4"
								key={warning.id}
								_hover={{
									background: 'rgba(100, 77, 237, 0.05)',
									cursor: 'pointer',
								}}
								onMouseDown={(e) => e.preventDefault()}
								onClick={() => {
									const contentBlocks = editorState
										.getCurrentContent()
										.getBlocksAsArray();

									if (contentBlocks.length >= warning.line) {
										const blockToFocus = contentBlocks[warning.line];
										const selection = editorState.getSelection().merge({
											anchorKey: blockToFocus.getKey(),
											focusKey: blockToFocus.getKey(),
											focusOffset: blockToFocus.getText().length,
											anchorOffset: blockToFocus.getText().length,
										});
										setEditorState(
											EditorState.forceSelection(editorState, selection),
										);

										setTimeout(() => {
											window
												.getSelection()
												?.focusNode?.parentElement?.scrollIntoView({
													inline: 'center',
													block: 'center',
												});
										}, 50);
									}
								}}
							>
								<Flex
									alignItems="center"
									justifyContent="space-between"
									w="full"
								>
									<Flex pr={2}>
										<Text fontSize="xs" mr="4">
											{warning.line + 1}
										</Text>
										<Text fontSize="xs" wordBreak="break-word">
											{warning.message}
										</Text>
									</Flex>
									<Tooltip label="Warning">
										<WarningIcon w={6} h={6} color="#F49304" />
									</Tooltip>
								</Flex>
							</Box>
					  ))
					: null}
			</Box>
		</MotionBox>
	);
};
