import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';

import { Asset } from 'entities/assets/interfaces/asset';
import { AssetType } from 'entities/assets/model';
import { ImageReview } from 'entities/assets/ui/image-review';
import {
	GetAssetByIdDocument,
	GetAssetsDocument,
} from 'pages/art-catalog/ui/ArtCatalogTable/queries.gen';
import { useGetAssetError } from 'shared/hooks/asset-error';
import { useAppToast } from 'shared/hooks/toast';
import { InputField, InputFieldWithLink } from 'shared/ui';
import { GetItemDocument } from 'widgets/update-asset-modal/ui/modal/queries.gen';

import { KeywordsPicker } from '../../keywords-picker';
import { updateItemSchema, UpdateItemValues } from '../lib/schemas/item';
import { useUpdateItemMutation } from './graphql/queries.gen';

export interface Props {
	asset: Asset;
}

export const UpdateItemForm: React.FC<Props> = ({ asset }) => {
	const toast = useAppToast();
	const handleError = useGetAssetError();
	const [isDisabledFilename, setIsDisabledFilename] = useState(
		!!asset.filename,
	);
	const [isUploadError, setIsUploadError] = useState(false);

	useEffect(() => {
		setIsDisabledFilename(!!asset.filename);
	}, [asset.filename]);

	const [updateItem] = useUpdateItemMutation({
		onCompleted: () => {
			toast({
				title: 'Item updated successfully',
				status: 'success',
			});
			setIsUploadError(false);
		},
		onError: (mutationError) => {
			handleError(mutationError.message, 'Item');
			setIsUploadError(!asset?.link);
		},
		refetchQueries: [GetAssetsDocument, GetItemDocument, GetAssetByIdDocument],
	});

	return (
		<Formik<UpdateItemValues>
			initialValues={{
				id: asset.id,
				filename: asset.filename,
				link: asset.link,
				keywords: asset.keywords,
				newKeywords: asset.newKeywords,
				price: asset.price || 0,
				dropboxLink: asset.dropboxLink || '',
			}}
			validationSchema={updateItemSchema}
			onSubmit={(value) =>
				updateItem({
					variables: {
						item: { ...value, price: parseInt(value.price as any, 10) },
					},
				})
			}
			enableReinitialize
		>
			{({ values, submitForm, setFieldValue, errors, validateForm }) => (
				<Form>
					<InputField
						label="Filename"
						name="filename"
						value={values.filename?.trim() || ''}
						onApply={async (v) => {
							setFieldValue('filename', v, true);
							await validateForm();
							if (!errors.filename) submitForm();
						}}
						error={errors.filename}
						placeholder="items_object_name"
						isDisabledFilename={isDisabledFilename}
					/>
					<ImageReview
						label="Item"
						type={asset.type}
						atName={isUploadError ? '' : asset?.atName?.trim() || ''}
						imageSrc={isUploadError ? '' : values.link || ''}
						onImageChange={async (link) => {
							setFieldValue('link', link, true);
							await validateForm();
							return submitForm();
						}}
						isUploadError={isUploadError}
						error={errors.link}
					/>
					<KeywordsPicker
						type={AssetType.ITEM}
						selectedKeywords={values.newKeywords}
						onApply={async (v) => {
							setFieldValue('newKeywords', v, true);
							await validateForm();
							submitForm();
						}}
					/>
					<InputFieldWithLink
						label="Link"
						name="dropboxLink"
						value={values.dropboxLink?.trim() || ''}
						onApply={async (v: string | number) => {
							setFieldValue('dropboxLink', v, true);
							await validateForm();
							if (!errors.dropboxLink) submitForm();
						}}
						error={errors.dropboxLink}
						placeholder="https://..."
					/>
				</Form>
			)}
		</Formik>
	);
};
