import * as Types from '../../../models.gen';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HeroesNamesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type HeroesNamesQuery = { heroes: Array<{ names: Array<string> }> };


export const HeroesNamesDocument = gql`
    query HeroesNames {
  heroes(limit: 10000) {
    names
  }
}
    `;

/**
 * __useHeroesNamesQuery__
 *
 * To run a query within a React component, call `useHeroesNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useHeroesNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHeroesNamesQuery({
 *   variables: {
 *   },
 * });
 */
export function useHeroesNamesQuery(baseOptions?: Apollo.QueryHookOptions<HeroesNamesQuery, HeroesNamesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HeroesNamesQuery, HeroesNamesQueryVariables>(HeroesNamesDocument, options);
      }
export function useHeroesNamesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HeroesNamesQuery, HeroesNamesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HeroesNamesQuery, HeroesNamesQueryVariables>(HeroesNamesDocument, options);
        }
export type HeroesNamesQueryHookResult = ReturnType<typeof useHeroesNamesQuery>;
export type HeroesNamesLazyQueryHookResult = ReturnType<typeof useHeroesNamesLazyQuery>;
export type HeroesNamesQueryResult = Apollo.QueryResult<HeroesNamesQuery, HeroesNamesQueryVariables>;