import { drawImage } from 'shared/utils/drawImage';
import { runInSequence } from 'shared/utils/run-in-sequence';

import { DRAW_CANVAS_CONFIG } from './configs';

// todo for cards - [background, hairBack, body, clothes, emotions, hairFront, layer]
// eslint-disable-next-line sonarjs/cognitive-complexity
export async function drawCanvas(
	links: string[],

	config: DRAW_CANVAS_CONFIG,
	canvas?: React.RefObject<HTMLCanvasElement | null>,
) {
	const width = config.type.w;
	const height = config.type.h;
	const newCanvas = canvas?.current || document.createElement('canvas');
	const ctx = newCanvas?.getContext('2d');
	newCanvas.width = width;
	newCanvas.height = height;

	if (ctx) {
		ctx.clearRect(0, 0, width, height);

		await runInSequence(
			links.map((link, i) => async () => {
				if (!link) return;
				if (config.configs[i].isCanvas) {
					const canvas2 = document.createElement('canvas');
					canvas2.width = width;
					canvas2.height = height;
					const ctx2 = canvas2?.getContext('2d');

					if (ctx2) {
						ctx2.filter = 'blur(4px)';
						await drawImage(ctx2, link, {
							dx: config.configs[i].dx,
							dy: config.configs[i].dy,
							w: config.configs[i].w,
							h: config.configs[i].h,
						});
					}
					ctx.drawImage(canvas2 as HTMLCanvasElement, 0, 0);
				} else {
					await drawImage(ctx, link, {
						dx: config.configs[i].dx,
						dy: config.configs[i].dy,
						w: config.configs[i].w,
						h: config.configs[i].h,
					});
				}
			}),
		);
	}
	return newCanvas;
}
