import React from 'react';
import {
	Box,
	Image,
	Modal,
	ModalCloseButton,
	ModalContent,
	ModalOverlay,
	Spinner,
	Text,
} from '@chakra-ui/react';

interface Props {
	name: string;
	link: string;
	isViewOpened: boolean;
	setIsViewOpened: (v: boolean) => void;
}

export const ImageViewModal: React.FC<Props> = ({
	name,
	link,
	isViewOpened,
	setIsViewOpened,
}) => {
	return (
		<Modal
			isOpen={isViewOpened}
			onClose={() => setIsViewOpened(false)}
			variant="fullscreen"
		>
			<ModalOverlay />
			<ModalContent
				minW="100%"
				h="100%"
				m={0}
				borderRadius="none"
				overflow="hidden"
			>
				<ModalCloseButton margin="13px 5px" borderRadius={8} m={0} />
				<Text
					marginTop="13px"
					fontStyle="normal"
					fontWeight={600}
					fontSize="16px"
					lineHeight="24px"
					textAlign="center"
					color="#ffffff"
				>
					{name}
				</Text>
				{link ? (
					<Image
						maxH="calc(100% - 37px)"
						objectFit="scale-down"
						src={link}
						alt={name}
					/>
				) : (
					<Box d="flex" w="full">
						<Spinner m="auto" size="xl" />
					</Box>
				)}
			</ModalContent>
		</Modal>
	);
};
