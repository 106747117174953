import React from 'react';
import {
	Badge,
	Button,
	Flex,
	Text,
	Tooltip,
	useDisclosure,
} from '@chakra-ui/react';

import { AssetType } from 'entities/assets/model';
import { trackAssetKeywordsModalOpen } from 'shared/analytics/modals/open';
import { MODAL_TYPE_KEYWORDS } from 'shared/analytics/modals/types';
import { PencilIcon, PlusIcon } from 'shared/icons';
import { KeywordsFilter } from 'widgets/keywords-filter-modal';
import {
	FilterKeywords,
	KEYWORDS_KEY_MAP,
	KEYWORDS_MAP,
} from 'widgets/keywords-filter-modal/constant';

interface KeywordsPickerProps {
	type: AssetType;
	selectedKeywords: FilterKeywords;
	onApply: (v: FilterKeywords | null) => void;
}

export const KeywordsPicker: React.FC<KeywordsPickerProps> = ({
	type,
	selectedKeywords,
	onApply,
}) => {
	const { isOpen, onClose, onOpen } = useDisclosure();

	// filtered deleted keywords

	const keywordsKeysByType = Object.keys(KEYWORDS_MAP[type]);

	const keywordsToShow: Partial<FilterKeywords> = Object.entries(
		selectedKeywords || {},
	).reduce((acc, [key, value]) => {
		if (keywordsKeysByType.includes(key as keyof FilterKeywords)) {
			const filteredValue = value?.filter((word) => {
				const keywordsGroup: string[] =
					KEYWORDS_MAP[type][key as keyof FilterKeywords];
				return keywordsGroup?.includes(word);
			});
			return { ...acc, [key]: filteredValue };
		}
		return acc;
	}, {});

	return (
		<Flex direction="column" p="20px 0 17px" borderBottom="1px solid #eeeef2">
			<Flex alignItems="center" justifyContent="space-between">
				<Text fontSize="14px" lineHeight="16px" color="#242533" mb="15px">
					Keywords
				</Text>
				<KeywordsFilter
					type={type}
					filterKeywords={keywordsToShow as FilterKeywords}
					setFilterKeywords={onApply}
					isOpen={isOpen}
					onClose={onClose}
					onOpen={onOpen}
				>
					<Button
						type="button"
						size="circle"
						variant="secondary"
						onClick={() => {
							onOpen();
							trackAssetKeywordsModalOpen(
								MODAL_TYPE_KEYWORDS[type as keyof typeof MODAL_TYPE_KEYWORDS],
							);
						}}
					>
						{keywordsToShow && Object.values(keywordsToShow).flat().length ? (
							<PencilIcon w={6} h={6} />
						) : (
							<PlusIcon />
						)}
					</Button>
				</KeywordsFilter>
			</Flex>
			{keywordsToShow ? (
				<Flex gap="7px" w="100%" flexWrap="wrap">
					{Object.entries(keywordsToShow).map(([key, value]) =>
						value?.map((word) => (
							<Tooltip label={KEYWORDS_KEY_MAP[key as keyof FilterKeywords]}>
								<Badge key={word}>{word}</Badge>
							</Tooltip>
						)),
					)}
				</Flex>
			) : null}
		</Flex>
	);
};
