import React from 'react';
import { createIcon } from '@chakra-ui/react';

export const HelpIcon = createIcon({
	viewBox: '0 0 24 24',
	path: (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M12 20.5C16.6944 20.5 20.5 16.6944 20.5 12C20.5 7.30558 16.6944 3.5 12 3.5C7.30558 3.5 3.5 7.30558 3.5 12C3.5 16.6944 7.30558 20.5 12 20.5Z"
				stroke="currentColor"
				strokeWidth="1.4"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M12.0665 13.5792C12.0665 13.3939 12.0936 13.2285 12.148 13.083C12.2024 12.9375 12.2733 12.8019 12.3609 12.6762C12.4485 12.5505 12.5466 12.4298 12.6553 12.314C12.764 12.1982 12.8758 12.0808 12.9905 11.9617C13.1959 11.75 13.368 11.5086 13.5069 11.2374C13.6458 10.9661 13.7153 10.6354 13.7153 10.2451C13.7153 9.98707 13.6775 9.74562 13.602 9.5207C13.5265 9.29578 13.4133 9.10063 13.2623 8.93525C13.1113 8.76987 12.9241 8.63922 12.7006 8.5433C12.4772 8.44737 12.2175 8.39941 11.9215 8.39941C11.5954 8.39941 11.3176 8.44903 11.088 8.54826C10.8585 8.64749 10.6653 8.77814 10.5082 8.94021C10.3512 9.10228 10.2274 9.28586 10.1368 9.49093C10.0462 9.696 9.97978 9.90438 9.9375 10.1161"
				stroke="currentColor"
				strokeWidth="1.4"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12.3077 14.8794L12.5748 15.1466C12.7223 15.2941 12.7223 15.5333 12.5748 15.6808L12.3077 15.948C12.1601 16.0955 11.9209 16.0955 11.7734 15.948L11.5063 15.6808C11.3587 15.5333 11.3587 15.2941 11.5063 15.1466L11.7734 14.8794C11.9209 14.7319 12.1601 14.7319 12.3077 14.8794Z"
				fill="currentColor"
			/>
		</svg>
	),
});
