export const DOC_TYPES: Record<string, string> = {
	PROD: 'Production',
	PROD2POV: 'Production 2Pov',
	AUTHOR: 'Author',
	NARRATIVE: 'Narrative Designer',
	PROD_VERSION_B: 'A/B Production [B]',
	PROD_VERSION_C: 'A/B Production [C]',
	PROD_VERSION_D: 'A/B Production [D]',
};

export const PROD_DOC_TYPES: Record<string, string> = {
	[DOC_TYPES.PROD]: 'Production',
	[DOC_TYPES.PROD2POV]: 'Production 2Pov',
};
