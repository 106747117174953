import React, { useRef } from 'react';
import {
	Button,
	Flex,
	HTMLChakraProps,
	Text,
	useOutsideClick,
} from '@chakra-ui/react';

interface DeletionAlertProps extends HTMLChakraProps<'div'> {
	message: string;
	onSubmit: () => void;
	onClose: () => void;
}

export const DeletionAlert: React.FC<DeletionAlertProps> = ({
	message,
	onSubmit,
	onClose,
	...rest
}) => {
	const ref = useRef<HTMLDivElement>(null);

	useOutsideClick({
		ref,
		handler: onClose,
	});

	return (
		<Flex
			position="absolute"
			zIndex="100"
			direction="column"
			align="center"
			borderRadius="0 0 8px 8px"
			ref={ref}
			{...rest}
		>
			<Text fontWeight="600" fontSize="16px" color="#ffffff" mb="17px">
				{message}
			</Text>
			<Flex>
				<Button size="md" variant="light" mr="8px" onClick={onClose}>
					Cancel
				</Button>
				<Button size="md" variant="primary" onClick={onSubmit}>
					Delete
				</Button>
			</Flex>
		</Flex>
	);
};
