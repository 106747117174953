export const ASSETS_PER_PAGE = 20;

export enum AssetType {
	CLOTHES = 'clothes',
	BODY = 'body',
	HAIR = 'hair',
	BACKGROUND = 'background',
	LAYER = 'layer',
	ITEM = 'item',
	// CHAT_PHOTO = 'chatPhoto',
	// AUDIO = 'audio',
}
