import { accordionStyles } from './accordion';
import { badgeStyles } from './badge';
import { buttonStyles } from './button';
import { checkboxStyles } from './checkbox';
import { inputStyles } from './input';
import { menuStyles } from './menu';
import { modalStyles } from './modal';
import { popoverStyles } from './popover';
import { progressStyles } from './progress';
import { switchStyles } from './switch';
import { tableStyles } from './table';
import { tabsStyles } from './tabs';
import { textStyles } from './text';
import { toastStyles } from './toast';
import { tooltipStyles } from './tooltip';

export const components = {
	Accordion: accordionStyles,
	Badge: badgeStyles,
	Button: buttonStyles,
	Checkbox: checkboxStyles,
	Input: inputStyles,
	Menu: menuStyles,
	Modal: modalStyles,
	Popover: popoverStyles,
	Table: tableStyles,
	Alert: toastStyles,
	Tooltip: tooltipStyles,
	Progress: progressStyles,
	Switch: switchStyles,
	Tabs: tabsStyles,
	Text: textStyles,
};
