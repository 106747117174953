import React from 'react';
import { Box, BoxProps } from '@chakra-ui/react';

export const Paper: React.FC<BoxProps> = ({ children, ...rest }) => {
	return (
		<Box
			{...rest}
			background="#FDFDFD"
			boxShadow="0px 4px 4px rgba(0, 0, 0, 0.08), 0px 0px 1px rgba(67, 90, 111, 0.47)"
			borderRadius="8px"
		>
			{children}
		</Box>
	);
};
