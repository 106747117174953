import React, { useMemo } from 'react';
import { Badge, Box, Flex, Text } from '@chakra-ui/react';

import { Asset } from 'entities/assets/interfaces/asset';
import { AssetType } from 'entities/assets/model';
import { UpdateBackgroundForm } from 'features/asset/update/forms/background';
import { UpdateBodyForm } from 'features/asset/update/forms/body';
import { UpdateClothesForm } from 'features/asset/update/forms/clothes';
import { UpdateHairForm } from 'features/asset/update/forms/hair';
import { UpdateItemForm } from 'features/asset/update/forms/item';
import { UpdateLayerForm } from 'features/asset/update/forms/layer';
import { formatDate } from 'shared/utils';

interface DetailedViewAssetProps {
	asset: Asset;
}

interface UpdateAssetFormProps {
	asset: Asset;
}

const ASSET_UPDATE_COMPONENTS_MAP: Record<
	AssetType,
	React.FC<UpdateAssetFormProps>
> = {
	[AssetType.BODY]: UpdateBodyForm,
	[AssetType.HAIR]: UpdateHairForm,
	[AssetType.CLOTHES]: UpdateClothesForm,
	[AssetType.ITEM]: UpdateItemForm,
	[AssetType.LAYER]: UpdateLayerForm,
	[AssetType.BACKGROUND]: UpdateBackgroundForm,
	// [AssetType.CHAT_PHOTO]: UpdateChatPhotoForm,
	// [AssetType.AUDIO]: UpdateAudioForm,
};

export const DetailedViewAsset: React.FC<DetailedViewAssetProps> = ({
	asset,
}) => {
	const DisplayUpdateAssetComponent = useMemo(
		() => ASSET_UPDATE_COMPONENTS_MAP[asset?.type as AssetType],
		[asset],
	);

	return (
		<Box mb="20px">
			<Box pr="23px" pl="25px">
				<Flex
					justifyContent="space-between"
					alignItems="center"
					p="20px 0 17px 0"
					borderBottom="1px solid #eeeef2"
				>
					<Text fontSize="14px" lineHeight="16px">
						Type
					</Text>
					<Badge>{asset.type}</Badge>
				</Flex>
			</Box>
			<Box p="0 23px 0 25px">
				{DisplayUpdateAssetComponent ? (
					<DisplayUpdateAssetComponent asset={asset} />
				) : null}
			</Box>
			<Box p="0 23px 0 25px">
				<Flex
					justifyContent="space-between"
					alignItems="center"
					p="20px 0 17px 0"
					borderBottom="1px solid #eeeef2"
				>
					<Text fontSize="14px" lineHeight="16px">
						ID
					</Text>
					<Text fontSize="13px" lineHeight="16px">
						{asset.id}
					</Text>
				</Flex>
			</Box>
			<Box p="0 23px 0 25px">
				<Flex
					justifyContent="space-between"
					alignItems="center"
					p="20px 0 17px 0"
					borderBottom="1px solid #eeeef2"
				>
					<Text fontSize="14px" lineHeight="16px">
						Date Uploaded
					</Text>
					<Text fontSize="13px" lineHeight="16px">
						{formatDate(asset.createdAt)}
					</Text>
				</Flex>
			</Box>
		</Box>
	);
};
