import React from 'react';
import { createIcon } from '@chakra-ui/react';

export const PauseIcon = createIcon({
	viewBox: '0 0 10 12',
	path: (
		<>
			<rect width="4" height="12" rx="1" fill="currentColor" />
			<rect x="6" width="4" height="12" rx="1" fill="currentColor" />
		</>
	),
});
