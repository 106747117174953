import React from 'react';
import { Center, Spinner } from '@chakra-ui/react';

import { HeroImage } from 'features/hero/hero-image';
import { HeroManagementListQuery } from 'pages/heroes/graphql/queries.gen';
import { ArrayElement } from 'shared/model';

interface HeroPreviewCellProps {
	hero?: ArrayElement<HeroManagementListQuery['heroes']>;
}

export const HeroPreviewCell: React.FC<HeroPreviewCellProps> = ({ hero }) => {
	if (!hero)
		return (
			<Center w="full" h="full" pt={1}>
				<Spinner size="sm" />
			</Center>
		);

	return (
		<HeroImage
			emotionsData={{
				link:
					hero?.bodies?.[0]?.emotions?.find((emotion: string | string[]) =>
						emotion.includes('default'),
					) ||
					hero?.bodies?.[0]?.emotions?.[0] ||
					'',
				position: 3,
			}}
			bodyData={{ link: hero.bodies?.[0]?.link || '', position: 1 }}
			hairFrontData={{
				link: hero.hairs?.[0]?.frontLink || '',
				position: 4,
			}}
			hairBackData={{
				link: hero.hairs?.[0]?.backLink || '',
				position: 0,
			}}
			clothesData={{
				link: hero?.clothes?.[0]?.link || '',
				position: 2,
			}}
		/>
	);
};
