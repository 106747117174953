import * as Types from '../../../../../models.gen';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AssetCardLayerByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type AssetCardLayerByIdQuery = { layer: { link?: string | null, name?: string | null } };

export type LayersByIdsQueryVariables = Types.Exact<{
  layersIds: Array<Types.Scalars['String']> | Types.Scalars['String'];
}>;


export type LayersByIdsQuery = { layersByIds: Array<{ link?: string | null, position?: number | null }> };


export const AssetCardLayerByIdDocument = gql`
    query AssetCardLayerById($id: String!) {
  layer(id: $id) {
    link
    name
  }
}
    `;

/**
 * __useAssetCardLayerByIdQuery__
 *
 * To run a query within a React component, call `useAssetCardLayerByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssetCardLayerByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssetCardLayerByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAssetCardLayerByIdQuery(baseOptions: Apollo.QueryHookOptions<AssetCardLayerByIdQuery, AssetCardLayerByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AssetCardLayerByIdQuery, AssetCardLayerByIdQueryVariables>(AssetCardLayerByIdDocument, options);
      }
export function useAssetCardLayerByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AssetCardLayerByIdQuery, AssetCardLayerByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AssetCardLayerByIdQuery, AssetCardLayerByIdQueryVariables>(AssetCardLayerByIdDocument, options);
        }
export type AssetCardLayerByIdQueryHookResult = ReturnType<typeof useAssetCardLayerByIdQuery>;
export type AssetCardLayerByIdLazyQueryHookResult = ReturnType<typeof useAssetCardLayerByIdLazyQuery>;
export type AssetCardLayerByIdQueryResult = Apollo.QueryResult<AssetCardLayerByIdQuery, AssetCardLayerByIdQueryVariables>;
export const LayersByIdsDocument = gql`
    query LayersByIds($layersIds: [String!]!) {
  layersByIds(layersIds: $layersIds) {
    link
    position
  }
}
    `;

/**
 * __useLayersByIdsQuery__
 *
 * To run a query within a React component, call `useLayersByIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLayersByIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLayersByIdsQuery({
 *   variables: {
 *      layersIds: // value for 'layersIds'
 *   },
 * });
 */
export function useLayersByIdsQuery(baseOptions: Apollo.QueryHookOptions<LayersByIdsQuery, LayersByIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LayersByIdsQuery, LayersByIdsQueryVariables>(LayersByIdsDocument, options);
      }
export function useLayersByIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LayersByIdsQuery, LayersByIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LayersByIdsQuery, LayersByIdsQueryVariables>(LayersByIdsDocument, options);
        }
export type LayersByIdsQueryHookResult = ReturnType<typeof useLayersByIdsQuery>;
export type LayersByIdsLazyQueryHookResult = ReturnType<typeof useLayersByIdsLazyQuery>;
export type LayersByIdsQueryResult = Apollo.QueryResult<LayersByIdsQuery, LayersByIdsQueryVariables>;