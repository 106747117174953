import * as Types from '../../../models.gen';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type StoryNamesListQueryVariables = Types.Exact<{
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
  page?: Types.InputMaybe<Types.Scalars['Int']>;
  filter?: Types.InputMaybe<Types.BooksFilterArgDto>;
}>;


export type StoryNamesListQuery = { books: Array<{ id: string, name?: string | null, customId?: string | null, fantasy: boolean }> };

export type ChapterNamesByStoryIdQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type ChapterNamesByStoryIdQuery = { book: { chapters?: Array<{ id: string, name?: string | null, chapterOrder?: number | null }> | null } };


export const StoryNamesListDocument = gql`
    query StoryNamesList($limit: Int, $page: Int, $filter: BooksFilterArgDto) {
  books(limit: $limit, page: $page, filter: $filter) {
    id
    name
    customId
    fantasy
  }
}
    `;

/**
 * __useStoryNamesListQuery__
 *
 * To run a query within a React component, call `useStoryNamesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoryNamesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoryNamesListQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useStoryNamesListQuery(baseOptions?: Apollo.QueryHookOptions<StoryNamesListQuery, StoryNamesListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StoryNamesListQuery, StoryNamesListQueryVariables>(StoryNamesListDocument, options);
      }
export function useStoryNamesListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StoryNamesListQuery, StoryNamesListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StoryNamesListQuery, StoryNamesListQueryVariables>(StoryNamesListDocument, options);
        }
export type StoryNamesListQueryHookResult = ReturnType<typeof useStoryNamesListQuery>;
export type StoryNamesListLazyQueryHookResult = ReturnType<typeof useStoryNamesListLazyQuery>;
export type StoryNamesListQueryResult = Apollo.QueryResult<StoryNamesListQuery, StoryNamesListQueryVariables>;
export const ChapterNamesByStoryIdDocument = gql`
    query ChapterNamesByStoryId($id: String!) {
  book(id: $id) {
    chapters {
      id
      name
      chapterOrder
    }
  }
}
    `;

/**
 * __useChapterNamesByStoryIdQuery__
 *
 * To run a query within a React component, call `useChapterNamesByStoryIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useChapterNamesByStoryIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChapterNamesByStoryIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useChapterNamesByStoryIdQuery(baseOptions: Apollo.QueryHookOptions<ChapterNamesByStoryIdQuery, ChapterNamesByStoryIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChapterNamesByStoryIdQuery, ChapterNamesByStoryIdQueryVariables>(ChapterNamesByStoryIdDocument, options);
      }
export function useChapterNamesByStoryIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChapterNamesByStoryIdQuery, ChapterNamesByStoryIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChapterNamesByStoryIdQuery, ChapterNamesByStoryIdQueryVariables>(ChapterNamesByStoryIdDocument, options);
        }
export type ChapterNamesByStoryIdQueryHookResult = ReturnType<typeof useChapterNamesByStoryIdQuery>;
export type ChapterNamesByStoryIdLazyQueryHookResult = ReturnType<typeof useChapterNamesByStoryIdLazyQuery>;
export type ChapterNamesByStoryIdQueryResult = Apollo.QueryResult<ChapterNamesByStoryIdQuery, ChapterNamesByStoryIdQueryVariables>;