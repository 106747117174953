export interface ImageConfig {
	dx: number;
	dy: number;
	w: number;
	h: number;
	isCanvas?: boolean;
}
export const drawImage = async (
	ctx: CanvasRenderingContext2D,
	src: string,
	config: ImageConfig,
): Promise<void> => {
	return new Promise<void>((resolve) => {
		const image = new Image();
		image.crossOrigin = 'Anonymous';
		image.src = src;
		image.onload = () => {
			ctx.drawImage(image, config.dx, config.dy, config.w, config.h);
			resolve();
		};
	});
};
