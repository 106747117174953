import React, { useCallback, useMemo, useState } from 'react';
import Dropzone from 'react-dropzone';
import { Flex, Text } from '@chakra-ui/react';

import { useAppToast } from 'shared/hooks/toast';
import { FileInput, ImagePreview } from 'shared/ui';
import { findSpaces } from 'shared/utils/find-space';
import {
	fileSizeValidate,
	fileTypeValidate,
	imageResolutionValidate,
} from 'shared/utils/validation';

// import {
// 	fileSizeValidate,
// 	fileTypeValidate,
// 	imageResolutionValidate,
// } from 'shared/utils/validation';
import {
	useUploadImageMutation,
	useUploadImagesMutation,
} from '../queries.gen';

interface ImageUploadBlockProps {
	setValue: (v: string) => void;
	accept: string;
	imageConfig: {
		w: number | string;
		h: number | string;
	};
	multiple?: boolean;
	onMultipleAdd?: (v: string[]) => void;
}

const validateImage = async (file: File) => {
	const filetypeValidationResult = fileTypeValidate(file, ['image/png']);

	const resolutionValidationResult = await imageResolutionValidate(file, {
		width: 731,
		height: 1661,
	});
	const fileSizeValidationResult = fileSizeValidate(file);

	return (
		filetypeValidationResult &&
		resolutionValidationResult &&
		fileSizeValidationResult
	);
};

export const ImageUploadBlock: React.FC<ImageUploadBlockProps> = ({
	setValue,
	accept,
	imageConfig,
	multiple,
	onMultipleAdd,
}) => {
	const toast = useAppToast();
	const [file, setFile] = useState<File | undefined>();
	const [isPreviewOpen, setPreviewOpen] = useState(false);
	const [uploadImage, { loading }] = useUploadImageMutation();
	const [uploadImages] = useUploadImagesMutation();

	const handleImageConfirm = useCallback(async () => {
		if (file) {
			const res = await uploadImage({
				variables: { file, filename: file.name.trim() },
			});
			setValue(res.data?.uploadFileToFolder || '');
			setPreviewOpen(false);
		}
	}, [file, setValue, uploadImage]);

	const validateImages = useCallback(
		async (filesArr) => {
			const validatedFiles = await Promise.all(
				filesArr.map(async (el: File) => {
					const isValid = fileTypeValidate(el, ['image/png']); // await validateImage(el);
					if (!isValid) {
						toast({
							title: `Image ${el.name} was not added`,
							description: 'Image size, extension or resolution is wrong',
							status: 'error',
						});
						return false;
					}

					const isSpaces = findSpaces(el.name);

					if (isSpaces) {
						toast({
							title: 'Image was not  added',
							description: "The filename shouldn't contain spaces",
							status: 'error',
						});

						return false;
					}
					return el;
				}),
			);

			const files = validatedFiles.filter((el) => el);

			if (files.length) {
				const result = await uploadImages({
					variables: { files },
				});

				if (onMultipleAdd)
					onMultipleAdd(result?.data?.uploadFilesToFolder || []);
			}
		},
		[onMultipleAdd, toast, uploadImages],
	);

	const handleUploadClick = useCallback(
		async (acceptedFiles: File[]) => {
			if (acceptedFiles?.length === 1) {
				const isValid = await validateImage(acceptedFiles[0]);

				if (!isValid) {
					toast({
						title: 'Image was not added',
						description: 'Image size, extension or resolution is wrong',
						status: 'error',
					});

					return;
				}

				const isSpaces = findSpaces(acceptedFiles[0].name);

				if (isSpaces) {
					toast({
						title: 'Image was not added',
						description: "The filename shouldn't contain spaces",
						status: 'error',
					});

					return;
				}
				setFile(acceptedFiles[0]);
				setPreviewOpen(true);
			} else if (acceptedFiles?.length || 0 > 1) {
				const filesArr = Array.from(acceptedFiles || []);
				validateImages(filesArr);
			}
		},
		[toast, validateImages],
	);

	const previewSrc = useMemo(
		() => (file ? URL.createObjectURL(file) : null),
		[file],
	);

	return (
		<Dropzone
			onDrop={(acceptedFiles: File[]) => handleUploadClick(acceptedFiles)}
		>
			{({ getRootProps, getInputProps, isDragActive }) => (
				<Flex
					justifyContent="center"
					alignItems="center"
					border={isDragActive ? '1px solid #644DED' : '1px solid #eeeef2'}
					borderRadius="10px"
					flexDir="column"
					w="240px"
					h="182px"
					{...getRootProps()}
				>
					<FileInput
						accept={accept}
						multiple={multiple}
						inputProps={getInputProps()}
					>
						Upload asset
					</FileInput>

					{imageConfig.w !== 'any' && imageConfig.h !== 'any' && (
						<Text mt="20px" fontSize="12px" color="#ABACBE">
							Resolution of &quot;{imageConfig.w}x{imageConfig.h}&quot;
						</Text>
					)}

					<ImagePreview
						isOpen={isPreviewOpen && Boolean(file && previewSrc)}
						onClose={() => setPreviewOpen(false)}
						src={previewSrc || ''}
						filename={file?.name || ''}
						onConfirm={handleImageConfirm}
						isPending={loading}
					/>
				</Flex>
			)}
		</Dropzone>
	);
};
