import React, { useCallback, useEffect, useState } from 'react';
import { Flex, IconButton, Input, Text, useDisclosure } from '@chakra-ui/react';

import { ButtonType } from 'shared/analytics';
import { AnalyticsIconButton } from 'shared/analytics/ui/icon-button';
import { CheckmarkIcon, PencilIcon } from 'shared/icons';
import { findSpaces } from 'shared/utils/find-space';

import { useAppToast } from '../../hooks/toast';
import { FILENAME_REGEX } from '../../utils/regex/filename';

interface EditableFieldProps {
	initialValue: string;
	onApply: (v: string) => Promise<void>;
	submitButtonName: ButtonType;
}

export const EditableField: React.FC<EditableFieldProps> = ({
	initialValue,
	onApply,
	submitButtonName,
}) => {
	const [value, setValue] = useState(initialValue);
	const [isPending, setPending] = useState(false);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const toast = useAppToast();

	// this effect ensures input value changes when component rerendered with different initial value
	useEffect(() => {
		setValue(initialValue);
	}, [initialValue]);

	// const handleDiscardClick = useCallback(async () => {
	// 	setPending(true);
	// 	await onApply('');
	// 	setPending(false);
	// 	onClose();
	// }, [onApply, onClose]);

	const handleApplyClick = useCallback(async () => {
		const trimmedValue = value.trim();
		const isSpaces = findSpaces(trimmedValue);

		if (trimmedValue === '') {
			toast({
				title: `File name can't be empty`,
				status: 'error',
				description: `${trimmedValue === '' ? 'Missing extension' : ''}`,
			});
		} else if (!FILENAME_REGEX.test(trimmedValue)) {
			toast({
				title: `Invalid file name`,
				description: `Incorrect extension`,
				status: 'error',
			});
		} else if (isSpaces) {
			toast({
				title: `Invalid file name`,
				description: `The filename shouldn't contain spaces`,
				status: 'error',
			});
			onClose();
			setValue(initialValue);
		} else {
			setPending(true);
			await onApply(trimmedValue);
			setPending(false);
			onClose();
		}
	}, [value, toast, onClose, initialValue, onApply]);

	return (
		<Flex justifyContent="space-between" alignItems="center" w="full">
			{isOpen ? (
				<>
					<Input
						fontSize="12px"
						mr={2}
						size="xs"
						value={value}
						onChange={(e) => setValue(e.target.value)}
						disabled={isPending}
						onKeyPress={(e) => {
							if (e.code === 'Enter') {
								e.preventDefault();
							}
						}}
					/>

					{/* <IconButton
						borderRadius={999}
						size="xs"
						variant="ghost"
						aria-label="discard changes"
						onClick={handleDiscardClick}
						disabled={isPending}
					>
						<TrashBinIcon w={6} h={6} />
					</IconButton> */}
					<AnalyticsIconButton
						name={submitButtonName}
						borderRadius={999}
						size="xs"
						variant="ghost"
						aria-label="apply changes"
						onClick={handleApplyClick}
						disabled={isPending}
					>
						<CheckmarkIcon w={4} h={4} />
					</AnalyticsIconButton>
				</>
			) : (
				<>
					<Text fontWeight={600} fontSize="12px" mr={4} wordBreak="break-all">
						{initialValue}
					</Text>
					<IconButton
						borderRadius={999}
						size="xs"
						variant="ghost"
						onClick={onOpen}
						aria-label="edit field"
						disabled={isPending}
					>
						<PencilIcon w={6} h={6} />
					</IconButton>
				</>
			)}
		</Flex>
	);
};
