export const switchStyles = {
	baseStyle: {
		track: {
			bg: '#BCBCC6',
			_checked: {
				bg: '#644DED',
			},
			_focus: {
				outline: 'none',
				boxShadow: 'none',
			},
		},
	},
};
