export const EXTRA_OPTIONS = [
	'With Music',
	'Different Endings',
	'Several Love Interests',
	'Story From 2 POVs',
	'Sensitive Content ',
	'With Cards',
	'Voiceover',
	'Story with video content',
];

enum BookStage {
	WAITING_FOR_ASSIGNMENT = 'Waiting for assignment',
	IN_PROGRESS = 'In progress',
	FINISHED = 'Finished',
	RELEASED = 'Released',
	FREEZED = 'Freezed',
	CANCELLED = 'Cancelled',
}

export const STAGE_OPTIONS = Object.values(BookStage);
