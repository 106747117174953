import { EditorState, RichUtils, SelectionState } from 'draft-js';

import { movePointerToEndOfSelection } from 'shared/utils';

export const applyEntityToTheInsertedCharacter = (
	editorState: EditorState,
	selection: SelectionState,
	blockKey: string,
	offset: number,
	entityKey: string,
): EditorState => {
	const newSelection = selection.merge({
		focusKey: blockKey,
		anchorKey: blockKey,
		focusOffset: offset,
		anchorOffset: offset - 1,
	});

	const editorStateWithComment = RichUtils.toggleLink(
		editorState,
		newSelection,
		entityKey,
	);

	return EditorState.forceSelection(
		editorStateWithComment,
		movePointerToEndOfSelection(newSelection),
	);
};
