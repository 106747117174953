import React, { useMemo } from 'react';
import {
	Badge,
	Button,
	Popover,
	PopoverBody,
	PopoverContent,
	PopoverTrigger,
	Text,
	useDisclosure,
} from '@chakra-ui/react';
import cn from 'classnames';

import { PencilIcon, PlusIcon } from 'shared/icons';
import { SingleChoiceList } from 'shared/ui';

import styles from './styles.module.scss';

interface Props {
	label: string;
	selectedOption: string;
	options: { value: string; title: string }[];
	onClick: (v: string) => void;
	touched?: boolean;
	error?: string;
	type?: 'text' | 'number';
	showErrorIfNotTouched?: boolean;
}

export const SingleSelectForm: React.FC<Props> = ({
	label,
	selectedOption,
	options,
	onClick,
	touched,
	error,
	type = 'text',
	showErrorIfNotTouched,
}) => {
	const { onOpen, onClose, isOpen } = useDisclosure();
	const onSelect = (answer: string) => {
		onClick(answer);
		onClose();
	};

	const isTypeText = useMemo(() => type === 'text', [type]);

	const selectedOptionValue = useMemo(
		() =>
			options.find(({ value }) => value === selectedOption)?.title || undefined,
		[selectedOption, options],
	);

	return (
		<div className={styles.wrapper}>
			<div
				className={cn(styles.container, {
					[styles.bottom]: isTypeText,
					[styles.top]: !isTypeText,
				})}
			>
				<p className={styles.title}>{label}</p>
				<div
					className={cn({
						[styles.action]: !isTypeText,
					})}
				>
					{!isTypeText && selectedOptionValue && (
						<Text
							fontSize="13px"
							mr="10px"
							fontStyle="normal"
							lineHeight="16px"
						>
							{selectedOptionValue}
						</Text>
					)}
					<Popover
						isOpen={isOpen}
						onOpen={onOpen}
						onClose={onClose}
						variant="singleList"
					>
						<PopoverTrigger>
							<Button size="circle" variant="secondary">
								{selectedOption ? <PencilIcon w={6} h={6} /> : <PlusIcon />}
							</Button>
						</PopoverTrigger>
						<PopoverContent>
							<PopoverBody maxW={270}>
								<SingleChoiceList
									label={label}
									options={options}
									selectedOption={selectedOption}
									onChange={onSelect}
								/>
							</PopoverBody>
						</PopoverContent>
					</Popover>
				</div>
			</div>
			{isTypeText && selectedOption && <Badge>{selectedOption}</Badge>}
			{isTypeText && !selectedOption && <div className={styles.place} />}
			{(touched || showErrorIfNotTouched) && error && (
				<p className={styles.error}>{error}</p>
			)}
		</div>
	);
};
