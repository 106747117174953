import * as Types from '../../../../models.gen';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ChaptersByStoryQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type ChaptersByStoryQuery = { book: { id: string, name?: string | null, chapters?: Array<{ id: string, name?: string | null, chapterOrder?: number | null, backgrounds: Array<{ id: string }>, items: Array<{ id: string }> }> | null } };

export type BackgroundChaptersByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type BackgroundChaptersByIdQuery = { background: { chapters: Array<{ id: string }> } };

export type ItemChaptersByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type ItemChaptersByIdQuery = { item: { chapters: Array<{ id: string }> } };


export const ChaptersByStoryDocument = gql`
    query ChaptersByStory($id: String!) {
  book(id: $id) {
    id
    name
    chapters {
      id
      name
      chapterOrder
      backgrounds {
        id
      }
      items {
        id
      }
    }
  }
}
    `;

/**
 * __useChaptersByStoryQuery__
 *
 * To run a query within a React component, call `useChaptersByStoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useChaptersByStoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChaptersByStoryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useChaptersByStoryQuery(baseOptions: Apollo.QueryHookOptions<ChaptersByStoryQuery, ChaptersByStoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChaptersByStoryQuery, ChaptersByStoryQueryVariables>(ChaptersByStoryDocument, options);
      }
export function useChaptersByStoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChaptersByStoryQuery, ChaptersByStoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChaptersByStoryQuery, ChaptersByStoryQueryVariables>(ChaptersByStoryDocument, options);
        }
export type ChaptersByStoryQueryHookResult = ReturnType<typeof useChaptersByStoryQuery>;
export type ChaptersByStoryLazyQueryHookResult = ReturnType<typeof useChaptersByStoryLazyQuery>;
export type ChaptersByStoryQueryResult = Apollo.QueryResult<ChaptersByStoryQuery, ChaptersByStoryQueryVariables>;
export const BackgroundChaptersByIdDocument = gql`
    query BackgroundChaptersById($id: String!) {
  background(id: $id) {
    chapters {
      id
    }
  }
}
    `;

/**
 * __useBackgroundChaptersByIdQuery__
 *
 * To run a query within a React component, call `useBackgroundChaptersByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useBackgroundChaptersByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBackgroundChaptersByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBackgroundChaptersByIdQuery(baseOptions: Apollo.QueryHookOptions<BackgroundChaptersByIdQuery, BackgroundChaptersByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BackgroundChaptersByIdQuery, BackgroundChaptersByIdQueryVariables>(BackgroundChaptersByIdDocument, options);
      }
export function useBackgroundChaptersByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BackgroundChaptersByIdQuery, BackgroundChaptersByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BackgroundChaptersByIdQuery, BackgroundChaptersByIdQueryVariables>(BackgroundChaptersByIdDocument, options);
        }
export type BackgroundChaptersByIdQueryHookResult = ReturnType<typeof useBackgroundChaptersByIdQuery>;
export type BackgroundChaptersByIdLazyQueryHookResult = ReturnType<typeof useBackgroundChaptersByIdLazyQuery>;
export type BackgroundChaptersByIdQueryResult = Apollo.QueryResult<BackgroundChaptersByIdQuery, BackgroundChaptersByIdQueryVariables>;
export const ItemChaptersByIdDocument = gql`
    query ItemChaptersById($id: String!) {
  item(id: $id) {
    chapters {
      id
    }
  }
}
    `;

/**
 * __useItemChaptersByIdQuery__
 *
 * To run a query within a React component, call `useItemChaptersByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useItemChaptersByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useItemChaptersByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useItemChaptersByIdQuery(baseOptions: Apollo.QueryHookOptions<ItemChaptersByIdQuery, ItemChaptersByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ItemChaptersByIdQuery, ItemChaptersByIdQueryVariables>(ItemChaptersByIdDocument, options);
      }
export function useItemChaptersByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ItemChaptersByIdQuery, ItemChaptersByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ItemChaptersByIdQuery, ItemChaptersByIdQueryVariables>(ItemChaptersByIdDocument, options);
        }
export type ItemChaptersByIdQueryHookResult = ReturnType<typeof useItemChaptersByIdQuery>;
export type ItemChaptersByIdLazyQueryHookResult = ReturnType<typeof useItemChaptersByIdLazyQuery>;
export type ItemChaptersByIdQueryResult = Apollo.QueryResult<ItemChaptersByIdQuery, ItemChaptersByIdQueryVariables>;