import * as Types from '../../../models.gen';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateAiRequestQueryVariables = Types.Exact<{
  prompt: Types.Scalars['String'];
}>;


export type CreateAiRequestQuery = { createRequest: { totalTokens: number, result: string } };


export const CreateAiRequestDocument = gql`
    query CreateAiRequest($prompt: String!) {
  createRequest(prompt: $prompt) {
    totalTokens
    result
  }
}
    `;

/**
 * __useCreateAiRequestQuery__
 *
 * To run a query within a React component, call `useCreateAiRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreateAiRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreateAiRequestQuery({
 *   variables: {
 *      prompt: // value for 'prompt'
 *   },
 * });
 */
export function useCreateAiRequestQuery(baseOptions: Apollo.QueryHookOptions<CreateAiRequestQuery, CreateAiRequestQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CreateAiRequestQuery, CreateAiRequestQueryVariables>(CreateAiRequestDocument, options);
      }
export function useCreateAiRequestLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CreateAiRequestQuery, CreateAiRequestQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CreateAiRequestQuery, CreateAiRequestQueryVariables>(CreateAiRequestDocument, options);
        }
export type CreateAiRequestQueryHookResult = ReturnType<typeof useCreateAiRequestQuery>;
export type CreateAiRequestLazyQueryHookResult = ReturnType<typeof useCreateAiRequestLazyQuery>;
export type CreateAiRequestQueryResult = Apollo.QueryResult<CreateAiRequestQuery, CreateAiRequestQueryVariables>;