/* eslint-disable sonarjs/cognitive-complexity */
import React, { useCallback, useEffect, useMemo } from 'react';
import { Button } from '@chakra-ui/react';
import { ContentState } from 'draft-js';
import { useStore } from 'effector-react';

import { scriptModel } from 'entities/script';
import { ArrowFillDownIcon } from 'shared/icons';

interface Props {
	blockKey: string;
	contentState: ContentState;
	isOpen: boolean;
	setIsOpen?: (v: boolean) => void;
}
export type InfoKeyProps = {
	blockKeyFoldStart: string;
	blockKeyFoldEnd: string;
	blockKeysIn: string[];
	isOpen: boolean;
};

const FoldButton: React.FC<Props> = ({
	contentState,
	blockKey,
	isOpen,
	setIsOpen,
}) => {
	const hideBlockKeys = useStore(scriptModel.$hideBlockKeys);

	const getHideBlock = useMemo(() => {
		const infoKey: InfoKeyProps = {
			blockKeyFoldStart: '',
			blockKeyFoldEnd: '',
			blockKeysIn: [],
			isOpen,
		};
		let startIndex = 0;
		let counter = 0;

		contentState.getBlocksAsArray().forEach((b, idx) => {
			const isStartKey = b.getKey() === blockKey;
			const isArrowOpenLine = b.getText().includes('{');
			const isArrowCloseLine = b.getText().includes('}');
			if (isStartKey && isArrowOpenLine) {
				infoKey.blockKeyFoldStart = b.getKey();
			}
			if ((isStartKey || startIndex) && isArrowOpenLine) {
				counter += 1;
			}
			if (startIndex && isArrowCloseLine) {
				counter -= 1;
				if (!counter) {
					startIndex = 0;
					infoKey.blockKeyFoldEnd = b.getKey();
				}
			}
			if (startIndex) {
				infoKey.blockKeysIn.push(b.getKey());
			}
			if (isStartKey) {
				startIndex = idx;
			}
		});
		return infoKey;
	}, [blockKey, contentState, isOpen]);

	const isArrow = useMemo(() => {
		return !!(getHideBlock.blockKeyFoldStart && getHideBlock.blockKeyFoldEnd);
	}, [getHideBlock]);

	const handleArrowClick = useCallback(
		() => setIsOpen && setIsOpen(!isOpen),
		[isOpen, setIsOpen],
	);

	useEffect(() => {
		hideBlockKeys.forEach((foldBlock) => {
			if (
				foldBlock.blockKeyFoldStart === blockKey &&
				foldBlock.isOpen &&
				getHideBlock.isOpen &&
				setIsOpen
			) {
				setIsOpen(true);
			}
		});
	}, [blockKey, getHideBlock, hideBlockKeys, setIsOpen]);

	useEffect(() => {
		if (isArrow) {
			scriptModel.setHideBlockKeys(getHideBlock);
		}
	}, [getHideBlock, isArrow]);

	return isArrow ? (
		<Button
			position="absolute"
			left="-15px"
			top="5px"
			variant="secondary"
			size="circleSmall"
			transform={`rotate(${!isOpen && '-90deg'})`}
			onClick={handleArrowClick}
			id="fold"
		>
			<ArrowFillDownIcon w="7px" h="4px" />
		</Button>
	) : null;
};

export default FoldButton;
