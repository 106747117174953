import {
	AssetPropertyType,
	BookChangedPropertyType,
	ButtonType,
	ChapterPropertyType,
	EventTypes,
	HeroPropertyType,
	LuaSavedPropertyType,
	ScriptValidatePropertyType,
	sendAmplitudeData,
	setUserProperties,
	StoryPropertyType,
} from './base';

export const setEmail = (email: string) => {
	setUserProperties({ email });
};

export const setVersion = (version: string) => {
	setUserProperties({ version });
};

export const trackPageView = (location: string) => {
	sendAmplitudeData(EventTypes.PAGEVIEW, { location });
};

export const trackValidateChapter = (chapterId: string) => {
	sendAmplitudeData(EventTypes.VALIDATE_CHAPTER, { chapterId });
};

export const trackSaveChapter = (chapterId: string) => {
	sendAmplitudeData(EventTypes.SAVE_CHAPTER, { chapterId });
};

export const trackButtonClick = (button: ButtonType) => {
	sendAmplitudeData(EventTypes.BUTTON_CLICK, { button });
};
export const trackStoryCreated = (property: StoryPropertyType) => {
	sendAmplitudeData(EventTypes.CREATED_BOOK, property);
};
export const trackStoryDeleted = (property: StoryPropertyType) => {
	sendAmplitudeData(EventTypes.DELETED_BOOK, property);
};
export const trackChapterCreated = (property: ChapterPropertyType) => {
	sendAmplitudeData(EventTypes.CREATED_CHAPTER, property);
};
export const trackChapterDeleted = (property: Partial<ChapterPropertyType>) => {
	sendAmplitudeData(EventTypes.DELETED_CHAPTER, property);
};
export const trackHeroCreated = (property: HeroPropertyType) => {
	sendAmplitudeData(EventTypes.CREATED_HERO, property);
};
export const trackHeroDeleted = (property: HeroPropertyType) => {
	sendAmplitudeData(EventTypes.DELETED_HERO, property);
};
export const trackAssetCreated = (property: AssetPropertyType) => {
	sendAmplitudeData(EventTypes.CREATED_ASSET, property);
};
export const trackAssetDeleted = (property: AssetPropertyType) => {
	sendAmplitudeData(EventTypes.DELETED_ASSET, property);
};
export const trackScriptValidated = (property: ScriptValidatePropertyType) => {
	sendAmplitudeData(EventTypes.VALIDATED_SCRIPT, property);
};
export const trackLuaSaved = (property: LuaSavedPropertyType) => {
	sendAmplitudeData(EventTypes.SAVED_LUA, property);
};
export const trackErrorShown = (errorText: string) => {
	sendAmplitudeData(EventTypes.SHOWN_ERROR, { errorText });
};
export const trackPropertyChanged = (property: BookChangedPropertyType) => {
	sendAmplitudeData(EventTypes.CHANGED_PROPERTY, property);
};
