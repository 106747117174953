import React, { useCallback, useState } from 'react';
import { Button, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';

import { AssetType } from 'entities/assets/model';
import { trackCreateAssetModalClose } from 'shared/analytics/modals/close';
import { trackCreateAssetModalOpen } from 'shared/analytics/modals/open';
import { Modal } from 'shared/analytics/modals/types';

import { CreateAssetModal } from '../modal';

export const CreateAssetButton = () => {
	const [isModalOpen, setModalOpen] = useState(false);
	const [selectedAssetType, setSelectedAssetType] = useState<
		undefined | AssetType
	>();

	const createMenuItemClickHandler = useCallback(
		(assetType: AssetType) => () => {
			setModalOpen(true);
			trackCreateAssetModalOpen(`New ${assetType}` as Modal);
			setSelectedAssetType(assetType);
		},
		[],
	);

	const handleModalClose = useCallback(() => {
		setModalOpen(false);
		trackCreateAssetModalClose(`New ${selectedAssetType}` as Modal);
		setSelectedAssetType(undefined);
	}, [selectedAssetType]);

	return (
		<>
			<Menu>
				<MenuButton as={Button} size="lg" minW="fit-content" height="44px">
					New Asset
				</MenuButton>
				<MenuList maxH="none">
					<MenuItem onClick={createMenuItemClickHandler(AssetType.BODY)}>
						Body
					</MenuItem>
					<MenuItem onClick={createMenuItemClickHandler(AssetType.HAIR)}>
						Hairs
					</MenuItem>
					<MenuItem onClick={createMenuItemClickHandler(AssetType.CLOTHES)}>
						Clothes
					</MenuItem>
					<MenuItem onClick={createMenuItemClickHandler(AssetType.ITEM)}>
						Item
					</MenuItem>
					<MenuItem onClick={createMenuItemClickHandler(AssetType.LAYER)}>
						Layer
					</MenuItem>
					<MenuItem onClick={createMenuItemClickHandler(AssetType.BACKGROUND)}>
						Background
					</MenuItem>
					{/* <MenuItem onClick={createMenuItemClickHandler(AssetType.CHAT_PHOTO)}> */}
					{/*	Chat Photo */}
					{/* </MenuItem> */}
					{/* <MenuItem onClick={createMenuItemClickHandler(AssetType.AUDIO)}>
						Audio
					</MenuItem> */}
				</MenuList>
			</Menu>
			{selectedAssetType ? (
				<CreateAssetModal
					isOpen={isModalOpen}
					onClose={handleModalClose}
					assetType={selectedAssetType}
					onAssetTypeChange={setSelectedAssetType}
				/>
			) : null}
		</>
	);
};
