import React from 'react';
import { createIcon } from '@chakra-ui/react';

export const SearchIcon = createIcon({
	viewBox: '0 0 18 18',
	path: (
		<path
			d="M8.1335 15.0658c3.8292 0 6.9333-3.1041 6.9333-6.9333 0-3.8291-3.1041-6.9333-6.9333-6.9333S1.2002 4.3034 1.2002 8.1325c0 3.8292 3.1041 6.9333 6.9333 6.9333ZM16.8003 16.7979l-2.9033-2.9034"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
	),
});
