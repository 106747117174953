import { useCallback } from 'react';

import { downloadCanvas } from 'shared/utils/downloadCanvas';
import { DRAW_TYPES, DRAW1_5xCONFIG } from 'shared/utils/drawCanvas/configs';
import { drawCanvas } from 'shared/utils/drawCanvas/drawCanvas';
import { runInSequence } from 'shared/utils/run-in-sequence';
import { useGetCardLinks } from 'widgets/create-card-modal/graphql/hooks';
import { SelectedAssetsInfo } from 'widgets/create-card-modal/interfaces';
import { getAllCombinations } from 'widgets/create-card-modal/utils/getAllCombinations';
import { getRace } from 'widgets/create-card-modal/utils/getRace';

export interface UseDownloadCards {
	selectedAssetsInfo: SelectedAssetsInfo;
	bookId: string;
	chapterId: number;
	heroId: number;
	backgroundLink: string;
	isGolden: boolean;
	isSecondary: boolean;
}

export const useDownloadCards = () => {
	const getCardLinks = useGetCardLinks();

	return useCallback(
		async ({
			selectedAssetsInfo,
			bookId,
			chapterId,
			heroId,
			backgroundLink,
			isGolden,
			isSecondary,
		}: UseDownloadCards) => {
			const combinations = getAllCombinations(selectedAssetsInfo);

			runInSequence(
				combinations.map((card) => async () => {
					const cardLinks = await getCardLinks(card);
					const splitHairName = cardLinks.hairFilename?.split('_');
					const hairId = splitHairName?.[splitHairName.length - 1].replace(
						'.png',
						'',
					);

					const canvas = await drawCanvas(
						[
							backgroundLink || '',
							cardLinks.hairBack || '',
							cardLinks.body || '',
							cardLinks.clothes || '',
							cardLinks.emotion || '',
							cardLinks.hairFront || '',
							cardLinks.layer || '',
						],
						{ configs: DRAW1_5xCONFIG, type: DRAW_TYPES.DRAW_1_5X },
					);

					const race = getRace(cardLinks.body || '');
					const cardType = isGolden ? 'golden' : 'regular';
					// if card is secondary - we don't need info about race and hairId
					const filenameEnding = isSecondary ? '' : `_${race}_${hairId}`;

					const filename = `${cardType}_${bookId}_${chapterId}_${heroId}${filenameEnding}.jpg`;
					downloadCanvas(canvas, filename, 'jpeg');
				}),
			);
		},
		[getCardLinks],
	);
};
