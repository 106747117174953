import React, { useCallback, useEffect, useMemo } from 'react';
import { NetworkStatus } from '@apollo/client';
import {
	Box,
	Button,
	ButtonGroup,
	Checkbox,
	Flex,
	IconButton,
	Popover,
	PopoverBody,
	PopoverContent,
	PopoverHeader,
	PopoverTrigger,
	Spinner,
	Text,
	Tooltip,
	useDisclosure,
	VStack,
} from '@chakra-ui/react';
import { useFormik } from 'formik';

import { TriangleDownIcon } from 'shared/icons';
import { ArrayElement } from 'shared/model';

import {
	BookChaptersForAssetSelectQuery,
	useBookChaptersForAssetSelectQuery,
} from './queries.gen';

type AssetChapters = {
	readonly id: string;
	readonly name?: string | null | undefined;
	readonly chapterOrder?: number | null | undefined;
	readonly book?:
		| {
				readonly id: string;
				readonly name?: string | null | undefined;
		  }
		| null
		| undefined;
}[];

interface ChaptersAssetSelectProps {
	assetId?: string;
	bookId: string;
	selectedChapters: AssetChapters;
	onSubmit: (
		chapters: BookChaptersForAssetSelectQuery['book']['chapters'],
	) => void;
	isLoading: boolean;
	updatedStory?: string;
	updatedAsset?: string;
}

interface ChaptersAssetSelectFormikParams {
	chapters: BookChaptersForAssetSelectQuery['book']['chapters'];
}

export const ChaptersAssetSelect: React.FC<ChaptersAssetSelectProps> = ({
	assetId,
	bookId,
	selectedChapters,
	onSubmit,
	isLoading,
	updatedStory,
	updatedAsset,
}) => {
	const { data, loading, networkStatus, refetch } =
		useBookChaptersForAssetSelectQuery({
			variables: { id: bookId },
			notifyOnNetworkStatusChange: true,
		});

	const { isOpen, onOpen, onClose } = useDisclosure();

	const formik = useFormik<ChaptersAssetSelectFormikParams>({
		initialValues: {
			chapters: selectedChapters || [],
		},
		enableReinitialize: true,
		onSubmit: async (values) => {
			onSubmit(values.chapters);
			onClose();
		},
	});

	const isCheckedAllChapters = useMemo(
		() => data?.book.chapters?.length === formik.values.chapters?.length,
		[data?.book.chapters?.length, formik.values.chapters?.length],
	);

	const chaptersDisplay = useMemo(() => {
		if (data?.book.chapters?.length === selectedChapters.length) return 'All';

		return [...selectedChapters]
			.sort((a, b) =>
				(a.chapterOrder as number) > (b.chapterOrder as number) ? 1 : -1,
			)
			.reduce((acc, chapter) => {
				if (chapter?.book && chapter?.book?.id !== bookId) return acc;
				if (!acc) return `${chapter.chapterOrder}`;
				return `${acc}, ${chapter.chapterOrder}`;
			}, '');
	}, [bookId, data?.book.chapters?.length, selectedChapters]);

	const isSelectedChapter = useCallback(
		(chapterId: string) =>
			formik.values.chapters?.some((chapter) => chapter.id === chapterId),
		[formik.values.chapters],
	);

	const handleSelectAllChaptersToggle = useCallback(() => {
		if (isCheckedAllChapters) {
			formik.setFieldValue('chapters', []);
			return;
		}
		formik.setFieldValue('chapters', data?.book.chapters);
	}, [data?.book.chapters, formik, isCheckedAllChapters]);

	const handleChapterSelectToggle = useCallback(
		(
				chapter: ArrayElement<
					BookChaptersForAssetSelectQuery['book']['chapters']
				>,
			) =>
			async () => {
				if (isSelectedChapter(chapter.id)) {
					await formik.setFieldValue(
						'chapters',
						formik.values.chapters?.filter((c) => c.id !== chapter.id),
					);
					return;
				}
				await formik.setFieldValue('chapters', [
					...(formik.values.chapters || []),
					chapter,
				]);
			},
		[formik, isSelectedChapter],
	);

	useEffect(() => {
		if (
			(bookId && isLoading && bookId === updatedStory) ||
			(assetId && isLoading && assetId === updatedAsset)
		) {
			setTimeout(() => refetch(), 500);
		}
	}, [assetId, bookId, isLoading, refetch, updatedAsset, updatedStory]);

	return (
		<Flex
			w="100%"
			align="center"
			justify="space-between"
			css={{
				a: { color: 'red' },
				'a:visited': { color: '#551A8B;' },
			}}
			gap="10px"
		>
			{loading || networkStatus === NetworkStatus.refetch ? (
				<Spinner size="xs" />
			) : (
				<Text
					flex="1"
					overflow="hidden"
					whiteSpace="nowrap"
					textOverflow="ellipsis"
				>
					{chaptersDisplay}
				</Text>
			)}
			<Popover
				isLazy
				isOpen={isOpen}
				onOpen={onOpen}
				onClose={onClose}
				placement="bottom-end"
			>
				<PopoverTrigger>
					<Box>
						<Tooltip label="Choose chapters">
							<IconButton
								aria-label="triangle"
								variant="ghost"
								icon={
									<TriangleDownIcon
										transform={isOpen ? 'rotate(180deg)' : undefined}
										transition="all .35s"
									/>
								}
							/>
						</Tooltip>
					</Box>
				</PopoverTrigger>
				<PopoverContent maxW={290} maxH={337}>
					<PopoverHeader>
						<Text>{data?.book.name}</Text>
					</PopoverHeader>
					<PopoverBody>
						<Checkbox
							isChecked={isCheckedAllChapters}
							onChange={handleSelectAllChaptersToggle}
							mb="23px"
						>
							<Text fontWeight={500} fontSize="13px" lineHeight="16px">
								Select All
							</Text>
						</Checkbox>
						<VStack
							alignItems="flex-start"
							justifyItems="flex-start"
							gap="18px"
							maxH={171}
							overflow="auto"
							mb={2.5}
						>
							{[...(data?.book.chapters || [])]
								.sort((a, b) => (a?.chapterOrder || 0) - (b?.chapterOrder || 0))
								.map((chapter) => (
									<Checkbox
										m={0}
										key={chapter.id}
										isChecked={isSelectedChapter(chapter.id)}
										onChange={handleChapterSelectToggle(chapter)}
									>
										<Text
											fontWeight={500}
											fontSize="13px"
											lineHeight="16px"
											overflow="hidden"
											whiteSpace="nowrap"
											textOverflow="ellipsis"
										>
											{chapter.chapterOrder}. {chapter.name}
										</Text>
									</Checkbox>
								))}
						</VStack>
						<ButtonGroup
							pb="7px"
							pt="5px"
							spacing="8px"
							justifyContent="flex-end"
						>
							<Button variant="ghost" onClick={onClose}>
								Cancel
							</Button>
							<Button variant="ghost" onClick={formik.submitForm}>
								Apply
							</Button>
						</ButtonGroup>
					</PopoverBody>
				</PopoverContent>
			</Popover>
		</Flex>
	);
};
