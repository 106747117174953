import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { NetworkStatus } from '@apollo/client';
import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	Box,
	Button,
	Flex,
	Spinner,
	Table,
	TableContainer,
	Tbody,
	Text,
	Th,
	Thead,
	Tr,
	useDisclosure,
} from '@chakra-ui/react';

import { uiModel } from 'entities/ui';
import { GetBookInfoDocument } from 'features/book/queries.gen';
import { BookChaptersQuery } from 'features/hero/chapters-select/queries.gen';
import { ButtonType } from 'shared/analytics';
import {
	trackHeroDetailsModalClose,
	trackHeroRemoveModalClose,
} from 'shared/analytics/modals/close';
import {
	trackHeroDetailsModalOpen,
	trackHeroRemoveModalOpen,
} from 'shared/analytics/modals/open';
import { useAppToast } from 'shared/hooks/toast';
import { ArrayElement } from 'shared/model';
import { HeroUpdateModal } from 'widgets/hero-update-modal';

import {
	ChapterHeroesDocument,
	ChapterHeroesQuery,
	useChapterHeroesQuery,
	useDeleteHeroFromBookMutation,
	useUpdateHeroChaptersMutation,
} from '../graphql/queries.gen';
import { HeroListRow } from './list-row';

type HeroesListRouteParams = {
	readonly bookId: string;
};

export const HeroesList: React.FC = () => {
	const { bookId } = useParams<HeroesListRouteParams>();
	if (!bookId) throw Error();

	const toast = useAppToast();
	const cancelRef = useRef(null);

	const {
		isOpen: isOpenDeleteModal,
		onClose: onCloseDeleteModal,
		onOpen: onOpeDeleteModal,
	} = useDisclosure();

	const {
		isOpen: isOpenHeroUpdateModal,
		onClose: onCloseHeroUpdateModal,
		onOpen: onOpenHeroUpdateModal,
	} = useDisclosure();

	const [updatedHeroId, setUpdatedHeroId] = useState('');
	const [deletedHero, setDeletedHero] = useState<ArrayElement<
		ChapterHeroesQuery['heroes']
	> | null>(null);

	const { data, error, networkStatus } = useChapterHeroesQuery({
		variables: { filter: { bookId }, limit: 100 },
		fetchPolicy: 'network-only',
		nextFetchPolicy: 'network-only',
		notifyOnNetworkStatusChange: true,
	});
	const [updateHeroChapters] = useUpdateHeroChaptersMutation({
		refetchQueries: [
			{
				variables: { filter: { bookId }, limit: 100 },
				query: ChapterHeroesDocument,
			},
			GetBookInfoDocument,
		],
	});
	const [deleteHeroFromBook, { loading: deleteHeroLoading }] =
		useDeleteHeroFromBookMutation({
			refetchQueries: [
				{
					variables: { filter: { bookId }, limit: 100 },
					query: ChapterHeroesDocument,
				},
				GetBookInfoDocument,
			],
		});

	const usedAssetsIds = useMemo(() => {
		return data?.heroes.reduce((acc, hero) => {
			const hairsIds = hero.hairs.map((hair) => hair.id);
			const clothesIds = hero.clothes.map((cloth) => cloth.id);
			const bodiesIds = hero.bodies?.map((body) => body.id) || [];
			const layersIds = hero.layers.map((layer) => layer.id);
			acc.push({
				heroId: hero.id,
				heroName: hero.names[0],
				ids: [...hairsIds, ...clothesIds, ...bodiesIds, ...layersIds],
			});
			return acc;
		}, [] as { heroId: string; heroName: string; ids: string[] }[]);
	}, [data?.heroes]);

	const handleDeleteHeroModalClose = useCallback(() => {
		onCloseDeleteModal();
		setDeletedHero(null);
		trackHeroRemoveModalClose();
	}, [onCloseDeleteModal]);

	const handleHeroDeleteAttempt = useCallback(
		(hero: ArrayElement<ChapterHeroesQuery['heroes']>) => async () => {
			setDeletedHero(hero);
			onOpeDeleteModal();
			trackHeroRemoveModalOpen();
		},
		[onOpeDeleteModal],
	);

	const handleHeroDeleteSubmit = useCallback(async () => {
		try {
			await deleteHeroFromBook({
				variables: {
					hero: {
						id: deletedHero?.id,
						chapters: deletedHero?.chapters.reduce((acc, chapter) => {
							if (chapter.book?.id !== bookId) return [...acc, chapter.id];
							return acc;
						}, [] as string[]),
					},
				},
			});
			toast({
				title: `${deletedHero?.names.join(', ')} was removed`,
				status: 'success',
			});
		} catch (errors) {
			toast({
				title: `Can't remove hero`,
				status: 'error',
			});
		} finally {
			handleDeleteHeroModalClose();
		}
	}, [
		bookId,
		deleteHeroFromBook,
		deletedHero?.chapters,
		deletedHero?.id,
		deletedHero?.names,
		handleDeleteHeroModalClose,
		toast,
	]);

	const handleHeroUpdateModalOpen = useCallback(
		(heroId: string) => () => {
			onOpenHeroUpdateModal();
			trackHeroDetailsModalOpen();
			setUpdatedHeroId(heroId);
		},
		[onOpenHeroUpdateModal],
	);

	const handleUpdateHeroChapters = useCallback(
		(hero: ArrayElement<ChapterHeroesQuery['heroes']>) =>
			async (chapters: BookChaptersQuery['book']['chapters']) => {
				try {
					await updateHeroChapters({
						variables: {
							hero: {
								id: hero.id,
								chapters: [
									...hero.chapters
										.filter((chapter) => chapter.book?.id !== bookId)
										.map((chapter) => chapter.id),
									...(chapters || []).map((chapter) => chapter.id),
								],
							},
						},
					});
					if (chapters?.length === 0) {
						toast({
							title: `${hero.names.join(', ')} was removed`,
							status: 'success',
						});
					}
				} catch (errors) {
					toast({
						title: `Error: Can't update chapters`,
						status: 'error',
					});
				}
			},
		[bookId, toast, updateHeroChapters],
	);

	useEffect(() => {
		if (error?.message)
			toast({
				title: error?.message,
				status: 'error',
				variant: 'error',
			});
	}, [error, toast]);

	useEffect(() => {
		uiModel.setUsedAssets(usedAssetsIds || []);
	}, [usedAssetsIds]);

	if (networkStatus === NetworkStatus.loading) {
		return <Spinner size="xl" m="auto" />;
	}

	return (
		<>
			<Box
				d="flex"
				flex-direction="column"
				flex="1 1"
				overflow="hidden"
				h="calc(100vh - 195px)"
			>
				<Box d="flex" flex="1 1" overflow="auto">
					<TableContainer width="100%" overflowY="auto">
						<Table>
							<Thead position="sticky" left={0} top={0} zIndex={101}>
								<Tr>
									<Th position="sticky" left={0} zIndex={100}>
										<Flex>
											<Text w="26px" mr="20px" fontFamily="sans-serif">
												№
											</Text>
											<Text w="90px" mr="20px">
												Pic
											</Text>
											<Text>Hero Name</Text>
										</Flex>
									</Th>
									<Th>id</Th>
									<Th>Chapters</Th>
									<Th>Last Edited</Th>
									<Th />
								</Tr>
							</Thead>
							<Tbody>
								{data?.heroes.map((hero, index) => (
									<HeroListRow
										key={hero.id}
										currentBookId={bookId}
										index={index}
										hero={hero}
										handleHeroDelete={handleHeroDeleteAttempt}
										handleHeroUpdateModalOpen={handleHeroUpdateModalOpen(
											hero.id,
										)}
										handleUpdateHeroChapters={handleUpdateHeroChapters}
									/>
								))}
							</Tbody>
						</Table>
					</TableContainer>
				</Box>
			</Box>
			{isOpenHeroUpdateModal && (
				<HeroUpdateModal
					isOpen={isOpenHeroUpdateModal}
					onClose={() => {
						onCloseHeroUpdateModal();
						trackHeroDetailsModalClose();
					}}
					heroId={updatedHeroId}
					setHeroId={setUpdatedHeroId}
				/>
			)}
			{isOpenDeleteModal && (
				<AlertDialog
					isOpen={isOpenDeleteModal}
					leastDestructiveRef={cancelRef}
					onClose={handleDeleteHeroModalClose}
				>
					<AlertDialogOverlay>
						<AlertDialogContent>
							<AlertDialogHeader fontSize="lg" fontWeight="bold">
								Hero
							</AlertDialogHeader>

							<AlertDialogBody>
								If you delete hero from the book, it can always be returned. Are
								you sure you want to continue?
							</AlertDialogBody>

							<AlertDialogFooter>
								{!deleteHeroLoading && (
									<Button
										ref={cancelRef}
										variant="ghost"
										onClick={handleDeleteHeroModalClose}
									>
										Cancel
									</Button>
								)}
								<Button
									name={ButtonType.DELETE_CHARACTER}
									onClick={handleHeroDeleteSubmit}
									ml={3}
									isLoading={deleteHeroLoading}
								>
									Delete
								</Button>
							</AlertDialogFooter>
						</AlertDialogContent>
					</AlertDialogOverlay>
				</AlertDialog>
			)}
		</>
	);
};
