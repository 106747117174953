import React, { useCallback, useRef } from 'react';
import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	Avatar,
	Button,
	CloseButton,
	Flex,
	HStack,
	Text,
	VStack,
} from '@chakra-ui/react';
import { useStore } from 'effector-react';

import { docsModel } from 'entities/docs';
import { scriptModel } from 'entities/script';
import { uiModel } from 'entities/ui';
import { ButtonType } from 'shared/analytics';
import { formatDate } from 'shared/utils';

interface SyncAlertProps {
	documentId: string;
}

export const SyncAlert: React.FC<SyncAlertProps> = ({ documentId }) => {
	const cancelRef = useRef<HTMLButtonElement>(null);

	const isDraftSyncAlertShow = useStore(uiModel.$isDraftSyncAlertShow);
	const isDraftDocContentUpdated = useStore(
		scriptModel.$isDraftDocContentUpdated,
	);
	const lastChangeBy = useStore(scriptModel.$docLastChangeBy);
	const handleAlertClose = useCallback(() => {
		scriptModel.setIsDisabledSyncToDoc(true);
		uiModel.setIsDraftSyncAlertShow(false);
	}, []);

	const handleCancelAlert = useCallback(() => {
		docsModel.syncDraftDocContentIntoDBFx({ documentId });
		scriptModel.setIsDisabledSyncToDoc(false);
		uiModel.setIsDraftSyncAlertShow(false);
	}, [documentId]);

	const handleSubmitAlert = useCallback(() => {
		scriptModel.setIsDisabledSyncToDoc(false);
		scriptModel.setIsScriptEdited(true);
		uiModel.setIsDraftSyncAlertShow(false);
	}, []);

	return (
		<AlertDialog
			isOpen={isDraftSyncAlertShow && isDraftDocContentUpdated}
			leastDestructiveRef={cancelRef}
			onClose={handleCancelAlert}
			closeOnOverlayClick={false}
			closeOnEsc={false}
		>
			<AlertDialogOverlay>
				<AlertDialogContent maxW="502px">
					<AlertDialogHeader fontSize="lg" fontWeight="bold" padding="24px">
						<Flex align="center" justify="space-between">
							Warning
							<CloseButton onClick={handleAlertClose} />
						</Flex>
					</AlertDialogHeader>
					{/* TODO add modified by */}
					<AlertDialogBody padding="0 24px 0">
						<Text fontWeight="bold">
							The document was modified in a GoogleDoc file
						</Text>
						{lastChangeBy?.modifiedBy?.photo ||
						lastChangeBy?.modifiedBy?.name ? (
							<HStack
								py={2}
								px={2}
								my={2}
								border="2px solid #644DED"
								w="fit-content"
								borderRadius="16px"
								alignItems="center"
								mx="auto"
							>
								<Avatar
									size="md"
									src={lastChangeBy?.modifiedBy?.photo}
									name={lastChangeBy?.modifiedBy?.name}
									mr={2}
								/>
								<VStack alignItems="flex-start">
									<Text fontSize="15px">
										by {lastChangeBy?.modifiedBy?.name}
									</Text>
									<Text fontSize="13px">
										at {formatDate(lastChangeBy?.modifiedTime)}
									</Text>
								</VStack>
							</HStack>
						) : null}

						<Text>
							After uploading updates from Google Docs to Editor all comments
							there will be removed. Or you can save comments and update the
							Google Doc file to the Editor version.
						</Text>
					</AlertDialogBody>

					<AlertDialogFooter padding="24px" justifyContent="space-between">
						<Button
							name={ButtonType.UPDATE_TO_GOOGLE_DOC_VERSION}
							onClick={handleCancelAlert}
							variant="ghost"
							size="lg"
							ref={cancelRef}
						>
							Update to GoogleDoc version
						</Button>
						<Button
							name={ButtonType.UPDATE_TO_EDITOR_VERSION}
							onClick={handleSubmitAlert}
							ml={3}
							size="lg"
						>
							Update to Editor version
						</Button>
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialogOverlay>
		</AlertDialog>
	);
};
