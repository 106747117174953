import React from 'react';
import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Box,
	Text,
} from '@chakra-ui/react';

import { GetBookQuery } from 'features/book/queries.gen';
import { LOCALIZED_LANG_MAP } from 'features/book/update/update-properties/constants';
import { UpdateChapterDocLink } from 'features/chapter/update/update-doc-link';
import { UpdateLocalizationDescription } from 'features/chapter/update/update-localization-description';
import { UpdateLocalizationName } from 'features/chapter/update/update-localization-name';
import { ArrayElement } from 'shared/model';

interface LocalizationTabProps {
	book: GetBookQuery['book'];
	currentChapter: ArrayElement<GetBookQuery['book']['chapters']>;
}

export const LocalizationTab: React.FC<LocalizationTabProps> = ({
	book,
	currentChapter,
}) => {
	return (
		<Accordion allowToggle>
			{Object.values(LOCALIZED_LANG_MAP).map(
				({
					title,
					chapterName,
					chapterDescription,
					docName,
					doc2PovName,
					lang,
				}) => (
					<AccordionItem>
						<AccordionButton>
							<Box
								flex="1"
								textAlign="left"
								fontWeight={600}
								color="#808192"
								fontSize="14px"
								lineHeight="20px"
							>
								{title}
							</Box>
							<AccordionIcon />
						</AccordionButton>
						<AccordionPanel>
							<UpdateLocalizationName
								id={currentChapter.id}
								chapterName={chapterName}
								name={currentChapter?.[chapterName]}
							/>
							<UpdateLocalizationDescription
								id={currentChapter.id}
								chapterDescription={chapterDescription}
								description={currentChapter?.[chapterDescription]}
							/>

							<Text mb="-13px" fontWeight="500" fontSize="14px" color="#999aa3">
								Docs
							</Text>

							<UpdateChapterDocLink
								language={lang}
								docType="prod"
								chapterId={currentChapter?.id}
								label={`Production - ${lang.toUpperCase()} Google Docs`}
								initialDocLink={currentChapter?.[docName]?.link || ''}
								docLinkToUpdate={docName}
								bookId={book?.id}
							/>
							{currentChapter.hasTwoPovs ? (
								<UpdateChapterDocLink
									language={lang}
									docType="prod-2pov"
									chapterId={currentChapter?.id}
									label={`Production 2Pov - ${lang.toUpperCase()} Google Docs`}
									initialDocLink={currentChapter?.[doc2PovName]?.link || ''}
									docLinkToUpdate={doc2PovName}
									bookId={book?.id}
								/>
							) : null}
						</AccordionPanel>
					</AccordionItem>
				),
			)}
		</Accordion>
	);
};
