import React from 'react';

import { SuggestionsList } from './list';
import { Suggestion } from './suggestion';

interface Props {
	containerRef: any;
	suggestions: string[];
	index: number;
	onSnippetSelect: (v: number, t?: number, variants?: string[]) => void;
}

export const SnippetsSuggestions: React.FC<Props> = ({
	index,
	containerRef,
	suggestions,
	onSnippetSelect,
}) => (
	<SuggestionsList containerRef={containerRef}>
		{suggestions.map((suggestion, i) => (
			<Suggestion
				key={suggestion}
				mapIndex={i}
				suggestion={suggestion}
				index={index}
				onClick={onSnippetSelect}
				containerRef={containerRef}
			/>
		))}
	</SuggestionsList>
);
