import React, { SetStateAction } from 'react';
import { Flex } from '@chakra-ui/react';

import { AssetInfo } from '../../../widgets/hero-update-modal';
import { AssetsHero } from './components/assets-hero';

interface AssetsTabProps {
	bodies: string[];
	clothes: string[];
	hairs: { id: string; name: string }[];
	layers: string[];
	defaultClothesId?: string;
	openAddModal: () => void;
	setAssetType: (v: string) => void;
	currentAssetInfo: AssetInfo;
	setCurrentAssetInfo: React.Dispatch<SetStateAction<AssetInfo>>;
	// openDetailsModal: () => void;
	handleSetFieldValue: (
		field: string,
		value: any,
		shouldValidate?: any,
	) => void;
}

export const AssetsTab: React.FC<AssetsTabProps> = ({
	bodies,
	clothes,
	hairs,
	layers,
	defaultClothesId,
	openAddModal,
	setAssetType,
	currentAssetInfo,
	setCurrentAssetInfo,
	// openDetailsModal,
	handleSetFieldValue,
}) => {
	return (
		<Flex h="calc(100vh - 220px)" gap={6} flexDirection="column">
			<AssetsHero
				title="Bodies"
				type="body"
				assets={bodies}
				hairs={[]}
				openAddModal={openAddModal}
				setAssetType={setAssetType}
				currentAssetInfo={currentAssetInfo}
				setCurrentAssetInfo={setCurrentAssetInfo}
				// openDetailsModal={openDetailsModal}
				handleSetFieldValue={handleSetFieldValue}
			/>
			<AssetsHero
				title="Hairs"
				type="hair"
				assets={[]}
				hairs={hairs}
				currentAssetInfo={currentAssetInfo}
				setCurrentAssetInfo={setCurrentAssetInfo}
				openAddModal={openAddModal}
				setAssetType={setAssetType}
				// openDetailsModal={openDetailsModal}
				handleSetFieldValue={handleSetFieldValue}
			/>
			<AssetsHero
				defaultId={defaultClothesId}
				title="Clothes"
				type="clothes"
				assets={clothes}
				hairs={[]}
				currentAssetInfo={currentAssetInfo}
				setCurrentAssetInfo={setCurrentAssetInfo}
				openAddModal={openAddModal}
				setAssetType={setAssetType}
				// openDetailsModal={openDetailsModal}
				handleSetFieldValue={handleSetFieldValue}
			/>
			<AssetsHero
				title="Layers"
				type="layer"
				assets={layers}
				hairs={[]}
				openAddModal={openAddModal}
				setAssetType={setAssetType}
				currentAssetInfo={currentAssetInfo}
				setCurrentAssetInfo={setCurrentAssetInfo}
				// openDetailsModal={openDetailsModal}
				handleSetFieldValue={handleSetFieldValue}
			/>
		</Flex>
	);
};
