import React from 'react';
import { createIcon } from '@chakra-ui/react';

export const KebabMenuIcon = createIcon({
	viewBox: '0 0 24 24',
	path: (
		<path
			d="M12 13.75c.9665 0 1.75-.7835 1.75-1.75s-.7835-1.75-1.75-1.75-1.75.7835-1.75 1.75.7835 1.75 1.75 1.75ZM19 13.75c.9665 0 1.75-.7835 1.75-1.75s-.7835-1.75-1.75-1.75-1.75.7835-1.75 1.75.7835 1.75 1.75 1.75ZM5 13.75c.9665 0 1.75-.7835 1.75-1.75S5.9665 10.25 5 10.25s-1.75.7835-1.75 1.75.7835 1.75 1.75 1.75Z"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
	),
});
