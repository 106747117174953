import * as Types from '../../../models.gen';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateCardMutationVariables = Types.Exact<{
  card: Types.UpdateCardRequestDto;
}>;


export type UpdateCardMutation = { updateCard: { id: string } };

export type CardQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type CardQuery = { card: { id: string, description?: string | null, cardsLink: Array<string>, filename: string, golden: boolean, secondary?: boolean | null, heroName: string, bookId: string, selectedAssets: { selectedBodies?: Array<string> | null, selectedClothes?: Array<string> | null, selectedHairs?: Array<string> | null, selectedLayers?: Array<string> | null }, chapter: { id: string, name?: string | null, chapterOrder?: number | null }, hero: { id: string, customId?: number | null, names: Array<string>, updatedAt: any, parent?: { id: string } | null, clothes: Array<{ id: string }>, hairs: Array<{ id: string, name?: string | null }>, bodies?: Array<{ id: string }> | null, layers: Array<{ id: string }> }, background: { id: string, filename?: string | null, atName?: string | null, link?: string | null } } };


export const UpdateCardDocument = gql`
    mutation UpdateCard($card: UpdateCardRequestDto!) {
  updateCard(card: $card) {
    id
  }
}
    `;
export type UpdateCardMutationFn = Apollo.MutationFunction<UpdateCardMutation, UpdateCardMutationVariables>;

/**
 * __useUpdateCardMutation__
 *
 * To run a mutation, you first call `useUpdateCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCardMutation, { data, loading, error }] = useUpdateCardMutation({
 *   variables: {
 *      card: // value for 'card'
 *   },
 * });
 */
export function useUpdateCardMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCardMutation, UpdateCardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCardMutation, UpdateCardMutationVariables>(UpdateCardDocument, options);
      }
export type UpdateCardMutationHookResult = ReturnType<typeof useUpdateCardMutation>;
export type UpdateCardMutationResult = Apollo.MutationResult<UpdateCardMutation>;
export type UpdateCardMutationOptions = Apollo.BaseMutationOptions<UpdateCardMutation, UpdateCardMutationVariables>;
export const CardDocument = gql`
    query Card($id: String!) {
  card(id: $id) {
    id
    description
    cardsLink
    filename
    golden
    secondary
    heroName
    bookId
    selectedAssets {
      selectedBodies
      selectedClothes
      selectedHairs
      selectedLayers
    }
    chapter {
      id
      name
      chapterOrder
    }
    hero {
      id
      customId
      names
      updatedAt
      parent {
        id
      }
      clothes {
        id
      }
      hairs {
        id
        name
      }
      bodies {
        id
      }
      layers {
        id
      }
    }
    background {
      id
      filename
      atName
      link
    }
  }
}
    `;

/**
 * __useCardQuery__
 *
 * To run a query within a React component, call `useCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCardQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCardQuery(baseOptions: Apollo.QueryHookOptions<CardQuery, CardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CardQuery, CardQueryVariables>(CardDocument, options);
      }
export function useCardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CardQuery, CardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CardQuery, CardQueryVariables>(CardDocument, options);
        }
export type CardQueryHookResult = ReturnType<typeof useCardQuery>;
export type CardLazyQueryHookResult = ReturnType<typeof useCardLazyQuery>;
export type CardQueryResult = Apollo.QueryResult<CardQuery, CardQueryVariables>;