import React from 'react';
import { Spinner } from '@chakra-ui/react';

import { Asset } from 'entities/assets/interfaces/asset';
import { AssetViewTabs } from 'widgets/update-asset-modal/lib/asset-view-tabs';

import { useGetBodyQuery } from '../modal/queries.gen';
import { ViewAsset } from '../view';

interface LoadBodyProps {
	assetId: string;
	activeTab: AssetViewTabs;
}

export const LoadBody: React.FC<LoadBodyProps> = ({ assetId, activeTab }) => {
	const { data, loading } = useGetBodyQuery({
		variables: {
			id: assetId,
		},
	});

	if (loading) return <Spinner />;

	return (
		<ViewAsset asset={data?.body as Asset} chats={[]} activeTab={activeTab} />
	);
};
