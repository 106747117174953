import React, { useEffect } from 'react';
import {
	Button,
	Flex,
	FormControl,
	IconButton,
	Popover,
	PopoverBody,
	PopoverContent,
	PopoverHeader,
	PopoverTrigger,
	Switch,
	Text,
	Tooltip,
	useDisclosure,
} from '@chakra-ui/react';
import { useStore } from 'effector-react';

import { userModel } from 'entities/user';
import { CrossIcon, FeaturesIcon } from 'shared/icons';

export const CustomizePicker = () => {
	const { onOpen, onClose, isOpen } = useDisclosure();
	const features = useStore(userModel.$features);

	useEffect(() => {
		if (features) {
			userModel.setFeatures(features);
		}
	}, [features]);

	return (
		<Popover
			isOpen={isOpen}
			onOpen={onOpen}
			onClose={onClose}
			variant="singleList"
			placement="right-start"
		>
			<PopoverTrigger>
				<Button
					aria-label="Open features"
					variant="ghost"
					size="square"
					mr="15px"
				>
					<Tooltip label="Customize">
						<FeaturesIcon w={5} h={5} />
					</Tooltip>
				</Button>
			</PopoverTrigger>
			<PopoverContent w="288px" maxH="370px">
				<PopoverHeader padding="19px 16px">
					<Flex justifyContent="space-between" alignItems="center">
						<Text fontSize="18px" fontWeight="700">
							Customize
						</Text>
						<IconButton
							// disabled={loading}
							onClick={onClose}
							aria-label="close"
							variant="ghost"
							w="25px"
							h="25px"
						>
							<CrossIcon w={3} h={3} />
						</IconButton>
					</Flex>
				</PopoverHeader>
				<PopoverBody p="10px 10px 50px">
					<FormControl>
						<Flex justifyContent="space-between" alignItems="center" p="8px">
							<Text fontSize="13px" fontWeight="500">
								Auto highlight
							</Text>
							<Switch
								size="lg"
								isChecked={features?.isAutoHighlight}
								onChange={() => {
									if (features) {
										userModel.setFeatures({
											...features,
											isAutoHighlight: !features.isAutoHighlight,
										});
									}
								}}
							/>
						</Flex>
						<Flex justifyContent="space-between" alignItems="center" p="8px">
							<Text fontSize="13px" fontWeight="500">
								Colors
							</Text>
							<Switch
								size="lg"
								isChecked={features?.isDecorator}
								onChange={() => {
									if (features) {
										userModel.setFeatures({
											...features,
											isDecorator: !features.isDecorator,
										});
									}
								}}
							/>
						</Flex>
						<Flex justifyContent="space-between" alignItems="center" p="8px">
							<Text fontSize="13px" fontWeight="500" maxW="70%">
								{'Automatically insert closing braces ", {, (, [, $'}
							</Text>
							<Switch
								size="lg"
								isChecked={features?.isAutoBraces}
								onChange={() => {
									if (features) {
										userModel.setFeatures({
											...features,
											isAutoBraces: !features.isAutoBraces,
										});
									}
								}}
							/>
						</Flex>
						<Flex justifyContent="space-between" alignItems="center" p="8px">
							<Text fontSize="13px" fontWeight="500">
								Snippets
							</Text>
							<Switch
								size="lg"
								isChecked={features?.isSnippets}
								onChange={() => {
									if (features) {
										userModel.setFeatures({
											...features,
											isSnippets: !features.isSnippets,
										});
									}
								}}
							/>
						</Flex>
						<Flex justifyContent="space-between" alignItems="center" p="8px">
							<Text fontSize="13px" fontWeight="500">
								Code folding
							</Text>
							<Switch
								size="lg"
								isChecked={features?.isCodeFolding}
								onChange={() => {
									if (features) {
										userModel.setFeatures({
											...features,
											isCodeFolding: !features.isCodeFolding,
										});
									}
								}}
							/>
						</Flex>
						<Flex justifyContent="space-between" alignItems="center" p="8px">
							<Text fontSize="13px" fontWeight="500">
								Dialogues
							</Text>
							<Switch
								size="lg"
								isChecked={features?.isDialogues}
								onChange={() => {
									if (features) {
										userModel.setFeatures({
											...features,
											isDialogues: !features.isDialogues,
										});
									}
								}}
							/>
						</Flex>
					</FormControl>
				</PopoverBody>
			</PopoverContent>
		</Popover>
	);
};
