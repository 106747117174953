import React from 'react';
import { Box, Checkbox, HStack, Text } from '@chakra-ui/react';
import cn from 'classnames';

import { FrIcon, StarIcon, WaterIcon } from 'shared/icons';

import styles from './styles.module.scss';

interface Props {
	label: string;
	isMultipleChoice: boolean;
	hasCheckbox?: boolean;
	isSelected?: boolean;
	onSelect: () => void;
	isIcon?: boolean;
}

export const ListItem: React.FC<Props> = ({
	label,
	isMultipleChoice,
	hasCheckbox = true,
	onSelect,
	isSelected,
	isIcon = false,
}) => {
	return (
		<div
			className={cn(styles.item, {
				[styles.singleChoiceItem]: !isMultipleChoice,
				[styles.multiChoiceItem]: isMultipleChoice,
				[styles.selectedSingle]: isSelected && !isMultipleChoice,
				[styles.selectedItem]: isSelected,
			})}
		>
			<Box
				position="absolute"
				top={0}
				left={0}
				w="full"
				h="full"
				transform="translateX(0)"
				zIndex="999999"
				onClick={(e) => {
					onSelect();
					e.stopPropagation();
				}}
			/>
			{hasCheckbox && <Checkbox mr={2} isChecked={isSelected} />}
			<HStack alignItems="center">
				{isIcon && label === 'trending' && (
					<StarIcon w={3.5} h={3.5} className={styles.labelIcon} />
				)}
				{isIcon && label === 'fresh' && (
					<WaterIcon w={3.5} h={3.5} className={styles.labelIcon} />
				)}
				{isIcon && label === 'redesigned' && (
					<FrIcon
						w={3.5}
						h={3.5}
						className={styles.labelIcon}
						color={isSelected ? '#644ded' : undefined}
					/>
				)}
				{isIcon && label === 'new customization' && (
					<StarIcon
						w={3.5}
						h={3.5}
						className={styles.labelIcon}
						color={isSelected ? '#644ded' : undefined}
					/>
				)}
				<Text color={isSelected ? '#644ded' : undefined} fontSize="13px">
					{label}
				</Text>
			</HStack>
		</div>
	);
};
