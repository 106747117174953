import React, { useCallback, useRef } from 'react';
import { DropzoneInputProps } from 'react-dropzone';
import { Button } from '@chakra-ui/react';

interface FileInputProps {
	accept: string;
	children?: string;
	multiple?: boolean;
	inputProps: DropzoneInputProps;
}

export const FileInput: React.FC<FileInputProps> = ({
	accept,
	children = 'Upload file',
	multiple = false,
	inputProps,
}) => {
	const inputRef = useRef<HTMLInputElement>(null);

	const handleButtonClick = useCallback(() => {
		if (inputRef.current) {
			inputRef.current.click();
		}
	}, [inputRef]);

	const onInputClick = () => {
		if (inputRef.current) {
			inputRef.current.value = '';
		}
	};

	return (
		<>
			<Button onClick={handleButtonClick}>{children}</Button>
			<input
				ref={inputRef}
				type="file"
				accept={accept}
				// onChange={onChange}
				onClick={onInputClick}
				style={{ display: 'none' }}
				multiple={multiple}
				{...inputProps}
			/>
		</>
	);
};
