import React from 'react';
import { Navigate } from 'react-router-dom';
import { useStore } from 'effector-react';

import { userModel, useUserByEmailQuery } from 'entities/user';

export interface RequireAuthProps {
	children: React.ReactElement;
}

export const RequireAuth: React.FC<RequireAuthProps> = ({ children }) => {
	const { data: userData } = useUserByEmailQuery();

	if (userData?.user.isDeleted) {
		userModel.setAccessToken(null);
		userModel.setRefreshToken(null);
	}

	const accessToken = useStore(userModel.$accessToken);

	if (!accessToken) return <Navigate to="/auth" />;

	return children;
};
