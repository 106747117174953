import React, { useCallback } from 'react';
import {
	Box,
	Button,
	Checkbox,
	Flex,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	Text,
} from '@chakra-ui/react';
import { EditorState, Modifier, SelectionState } from 'draft-js';
import { useStore } from 'effector-react';
import { Form, Formik } from 'formik';

import { escape, generateDecorator, scriptModel } from 'entities/script';
import { uiModel } from 'entities/ui';
import { trackFindAndReplaceModalClose } from 'shared/analytics/modals/close';
import { FormInputField } from 'shared/ui';

import { checkIsCaseSensitive } from '../../../utils';

interface ReplaceFormPros {
	editorState: EditorState;
	setEditorState: (editorState: EditorState) => void;
	setIsCaseSensitive: (caseSensitive: boolean) => void;
	setInputValue: (inputValue: string) => void;
	isCaseSensitive: boolean;
	matchedLinesLength: number;
	inputFindRef: React.RefObject<HTMLInputElement> | null;
}

export const ReplaceForm: React.FC<ReplaceFormPros> = ({
	editorState,
	setEditorState,
	setIsCaseSensitive,
	isCaseSensitive,
	matchedLinesLength,
	setInputValue,
	inputFindRef,
}) => {
	const isEditorReplaceModalOpened = useStore(
		uiModel.$isEditorReplaceModalOpened,
	);
	const editorSearchValue = useStore(scriptModel.$editorSearchValue);
	const editorReplaceValue = useStore(scriptModel.$editorReplaceValue);

	const resetFormValues = useCallback(
		() =>
			setTimeout(() => {
				scriptModel.setEditorSearchValue('');
				scriptModel.setEditorReplaceValue('');
				setInputValue('');
			}, 500),
		[setInputValue],
	);

	// eslint-disable-next-line sonarjs/cognitive-complexity
	const onReplace = useCallback(() => {
		const regex = new RegExp(
			escape(editorSearchValue),
			checkIsCaseSensitive(isCaseSensitive),
		);

		const blockMap = editorState.getCurrentContent().getBlockMap();

		let contentState = editorState.getCurrentContent();
		blockMap.forEach((contentBlock) => {
			if (contentBlock) {
				const replacedBlock: { start: number; end: number }[] = [];
				const text = contentBlock.getText();
				let offset = 0;
				let matchArr;
				let start;
				// eslint-disable-next-line no-cond-assign
				while ((matchArr = regex.exec(text)) !== null) {
					start = matchArr.index;
					const end = matchArr.index + editorSearchValue.length;

					if (replacedBlock.length >= 1) {
						offset -= editorSearchValue.length - editorReplaceValue.length;
					}

					replacedBlock.push({
						start: replacedBlock.length >= 1 ? start + offset : start,
						end: replacedBlock.length >= 1 ? end + offset : end,
					});
				}
				const blockKey = contentBlock.getKey();

				replacedBlock.forEach(
					({ start: selectionStart, end: selectionEnd }: any) => {
						const blockSelection = SelectionState.createEmpty(blockKey).merge({
							anchorOffset: selectionStart,
							focusOffset: selectionEnd,
						});
						contentState = Modifier.replaceText(
							contentState,
							blockSelection,
							editorReplaceValue,
						);
					},
				);
			}
		});

		const newEditorState = EditorState.push(
			editorState,
			contentState,
			'insert-characters',
		);

		setEditorState(
			EditorState.set(newEditorState, {
				decorator: generateDecorator('', { isCaseSensitive }),
			}),
		);
		resetFormValues();
		uiModel.setIsEditorReplaceModalOpened(false);
		scriptModel.setIsScriptEdited(true);
		setIsCaseSensitive(false);
	}, [
		editorSearchValue,
		isCaseSensitive,
		editorState,
		setEditorState,
		resetFormValues,
		setIsCaseSensitive,
		editorReplaceValue,
	]);

	return (
		<Modal
			isOpen={isEditorReplaceModalOpened}
			onClose={() => {
				uiModel.setIsEditorReplaceModalOpened(false);
				setIsCaseSensitive(false);
				resetFormValues();
				trackFindAndReplaceModalClose();
			}}
		>
			<ModalContent maxW="368px">
				<ModalHeader p="19px 16px">
					Find and replace <ModalCloseButton top="15px" />
				</ModalHeader>
				<ModalBody p="10px 16px">
					<Formik
						initialValues={{
							findValue: editorSearchValue,
							replaceValue: editorReplaceValue,
						}}
						onReset={() => {
							uiModel.setIsEditorReplaceModalOpened(false);
							resetFormValues();
						}}
						onSubmit={onReplace}
						validate={({ findValue, replaceValue }) => {
							setInputValue(findValue);
							scriptModel.setEditorReplaceValue(replaceValue);
						}}
					>
						{({ values }) => (
							<Form>
								<Box position="relative" mb="16px">
									<FormInputField
										label="Find"
										name="findValue"
										inputSize="lg"
										inputPadding="75px"
										autocomplete="off"
										inputRef={inputFindRef}
									/>
									{matchedLinesLength ? (
										<Text
											position="absolute"
											right={2}
											top="45px"
											lineHeight="16px"
											mr={1.5}
											color="#BCBCC6"
										>
											Total: {matchedLinesLength}
										</Text>
									) : null}
								</Box>
								<Box>
									<FormInputField
										label="Replace with"
										name="replaceValue"
										inputSize="lg"
										autocomplete="off"
									/>
								</Box>
								<Checkbox
									m="10px"
									isChecked={isCaseSensitive}
									onChange={() => setIsCaseSensitive(!isCaseSensitive)}
								>
									Match case
								</Checkbox>
								<Flex justify="flex-end" mt="10px">
									<Button
										type="reset"
										size="md"
										variant="secondary"
										onClick={trackFindAndReplaceModalClose}
									>
										Cancel
									</Button>
									<Button
										disabled={
											!(values.findValue || '').trim().length ||
											!(values.replaceValue || '').trim().length
										}
										type="submit"
										size="md"
										variant="secondary"
										onClick={trackFindAndReplaceModalClose}
									>
										Apply
									</Button>
								</Flex>
							</Form>
						)}
					</Formik>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};
