/* eslint-disable consistent-return */
/* eslint-disable sonarjs/cognitive-complexity */
import React, { useCallback, useEffect, useState } from 'react';
import { Form, Formik, useFormik } from 'formik';

import { Asset } from 'entities/assets/interfaces/asset';
import { AssetType } from 'entities/assets/model';
import { EmotionsReview } from 'entities/assets/ui/emotions-review';
import { ImageReview } from 'entities/assets/ui/image-review';
import { KeywordsPicker } from 'features/asset/create';
import {
	GetAssetByIdDocument,
	GetAssetsDocument,
} from 'pages/art-catalog/ui/ArtCatalogTable/queries.gen';
import { useGetAssetError } from 'shared/hooks/asset-error';
import { useAppToast } from 'shared/hooks/toast';
import { InputField, InputFieldWithLink } from 'shared/ui';
import { getFilenameFromURL } from 'shared/utils/get-filename-from-url';
import { GetBodyDocument } from 'widgets/update-asset-modal/ui/modal/queries.gen';

import { updateBodySchema, UpdateBodyValues } from '../lib/schemas/body';
import {
	useUpdateBodyMutation,
	useUpdateEmotionsMutation,
} from './graphql/queries.gen';

export interface Props {
	asset: Asset;
}

export const UpdateBodyForm: React.FC<Props> = ({ asset }) => {
	const toast = useAppToast();
	const handleError = useGetAssetError();
	const [isDisabledFilename, setIsDisabledFilename] = useState(
		!!asset.filename,
	);
	const [isUploadError, setIsUploadError] = useState(false);

	useEffect(() => {
		setIsDisabledFilename(!!asset.filename);
	}, [asset.filename]);

	const [updateBody] = useUpdateBodyMutation({
		onCompleted: async () => {
			toast({
				title: 'Body updated successfully',
				status: 'success',
			});
			setIsUploadError(false);
		},
		onError: (mutationError) => {
			handleError(mutationError.message, 'Body');
			setIsUploadError(!asset?.link);
		},
		refetchQueries: [GetAssetsDocument, GetBodyDocument, GetAssetByIdDocument],
	});

	const formik = useFormik({
		initialValues: {
			id: asset.id,
			filename: asset.filename || '',
			link: asset.link || '',
			keywords: asset.keywords || [],
			newKeywords: asset.newKeywords || {},
			emotions: asset.emotions || [],
			dropboxLink: asset.dropboxLink || '',
		},
		validationSchema: updateBodySchema,
		onSubmit: async (values) =>
			updateBody({
				variables: {
					body: {
						id: values.id,
						filename: values.filename?.trim(),
						link: values.link,
						keywords: values.keywords,
						newKeywords: values.newKeywords,
						dropboxLink: values.dropboxLink,
					},
				},
			}),
	});

	const [updateEmotions] = useUpdateEmotionsMutation({
		onCompleted: (data) => {
			const { updatedEmotions, newEmotions, deletedEmotions } =
				data.updateEmotions;

			if (newEmotions.length)
				newEmotions.forEach((em) =>
					toast({
						title: `Image ${getFilenameFromURL(em)} was successfully added`,
						status: 'success',
					}),
				);

			if (updatedEmotions.length)
				updatedEmotions.forEach((em) =>
					toast({
						title: `Image ${getFilenameFromURL(em)} was successfully updated`,
						status: 'success',
					}),
				);

			if (deletedEmotions.length)
				deletedEmotions.forEach((em) =>
					toast({
						title: `Image ${getFilenameFromURL(em)} was successfully deleted`,
						status: 'success',
					}),
				);
		},
		onError: (error) => {
			toast({
				title: `Emotions were not updated`,
				description: error.message,
				status: 'error',
			});
		},
		refetchQueries: [GetAssetsDocument, GetBodyDocument],
	});
	const submitEmotions = useCallback(
		async (emotions: string[]) => {
			return updateEmotions({
				variables: {
					id: asset.id,
					emotions: {
						emotions,
					},
				},
			});
		},
		[asset.id, updateEmotions],
	);

	const onChangeEmotions = useCallback(
		async (emotions: string[]) => {
			const error = await formik.validateForm();
			if (!error.emotions) {
				const { errors } = await submitEmotions(emotions);
				if (errors) {
					return errors;
				}
			}
			formik.setFieldValue('emotions', emotions, true);
		},
		[formik, submitEmotions],
	);
	return (
		<Formik<UpdateBodyValues>
			initialValues={{
				id: asset.id,
				filename: asset.filename || '',
				link: asset.link || '',
				keywords: asset.keywords || [],
				newKeywords: asset.newKeywords || {},
				emotions: asset.emotions || [],
				dropboxLink: asset.dropboxLink || '',
			}}
			validationSchema={updateBodySchema}
			onSubmit={(values) =>
				updateBody({
					variables: {
						body: {
							id: values.id,
							filename: values.filename?.trim(),
							link: values.link,
							keywords: values.keywords,
							newKeywords: values.newKeywords,
							dropboxLink: values.dropboxLink,
						},
					},
				})
			}
			enableReinitialize
		>
			{({ values, submitForm, setFieldValue, errors, validateForm }) => (
				<Form>
					<InputField
						label="Filename"
						name="filename"
						value={values.filename?.trim() || ''}
						onApply={async (v: string | number) => {
							setFieldValue('filename', v, true);
							await validateForm();
							if (!errors.filename) submitForm();
						}}
						error={errors.filename}
						placeholder="face_f_0_body_f_regular_white_0.png"
						isDisabledFilename={isDisabledFilename}
					/>
					<ImageReview
						label="Body"
						type={asset.type}
						atName={isUploadError ? '' : asset?.atName?.trim() || ''}
						imageSrc={isUploadError ? '' : values.link || ''}
						onImageChange={async (link) => {
							setFieldValue('link', link, true);
							await validateForm();
							return submitForm();
						}}
						error={errors.link}
						isUploadError={isUploadError}
					/>
					<EmotionsReview
						label="Emotions"
						emotions={values.emotions || []}
						onChange={onChangeEmotions}
						multiple
						error={errors.emotions}
					/>
					<KeywordsPicker
						type={AssetType.BODY}
						selectedKeywords={values.newKeywords}
						onApply={async (v) => {
							setFieldValue('newKeywords', v, true);
							await validateForm();
							submitForm();
						}}
					/>
					<InputFieldWithLink
						label="Link"
						name="dropboxLink"
						value={values.dropboxLink?.trim() || ''}
						onApply={async (v: string | number) => {
							setFieldValue('dropboxLink', v, true);
							await validateForm();
							if (!errors.dropboxLink) submitForm();
						}}
						error={errors.dropboxLink}
						placeholder="https://..."
					/>
				</Form>
			)}
		</Formik>
	);
};
