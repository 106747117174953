import React from 'react';
import { Badge, Button, Flex, Text } from '@chakra-ui/react';

import { filterKeywordsTypes } from 'pages/art-catalog';
import { FILTER_TYPES } from 'pages/art-catalog/ui/ArtCatalogTable';
import { trackAssetFilterModalOpen } from 'shared/analytics/modals/open';
import { MODAL_TYPE_FILTER } from 'shared/analytics/modals/types';
import { FilterIcon } from 'shared/icons';

import { FilterKeywords } from './constant';
import KeywordsModal from './keywords-modal';

interface KeywordFilterProps {
	type: string;
	filterKeywords: FilterKeywords | null;
	filterKeywordsType?: filterKeywordsTypes;
	setFilterKeywords: (v: FilterKeywords | null) => void;
	setFilterKeywordsType?: (v: filterKeywordsTypes) => void;
	isOpen: boolean;
	onClose: () => void;
	onOpen: () => void;
	isFilter?: boolean;
}

export const KeywordsFilter: React.FC<KeywordFilterProps> = ({
	type,
	filterKeywords,
	filterKeywordsType,
	setFilterKeywords,
	setFilterKeywordsType,
	children,
	isOpen,
	onClose,
	onOpen,
	isFilter,
}) => {
	const countKeywords = (keywords: FilterKeywords | null) => {
		if (keywords)
			return Object.values(keywords)
				.flat()
				.filter((v) => v).length;

		return null;
	};

	return (
		<>
			{children || (
				<Button
					aria-label="Open filter"
					p="6px 12px 6px 8px"
					h="40px"
					variant={isOpen ? 'secondaryActive' : 'secondary'}
					onClick={() => {
						onOpen();
						if (isFilter) {
							trackAssetFilterModalOpen(
								MODAL_TYPE_FILTER[type as keyof typeof MODAL_TYPE_FILTER],
							);
						}
					}}
				>
					<Flex alignItems="center">
						{countKeywords(filterKeywords) ? (
							<Badge
								w="18px"
								h="18px"
								backgroundColor="#644DED"
								color="#ffffff"
								borderRadius="50%"
								display="flex"
								alignItems="center"
								justifyContent="center"
								mr="7px"
							>
								{countKeywords(filterKeywords)}
							</Badge>
						) : (
							<FilterIcon w="24px" h="24px" mr="4px" />
						)}

						<Text fontSize="13px" lineHeight="16px" color="currentcolor">
							Filter
						</Text>
					</Flex>
				</Button>
			)}
			{isOpen && (
				<KeywordsModal
					type={type}
					filterTypes={FILTER_TYPES}
					filterKeywords={filterKeywords}
					setFilterKeywords={setFilterKeywords}
					setFilterKeywordsType={setFilterKeywordsType}
					filterKeywordsType={filterKeywordsType}
					isOpen={isOpen}
					onClose={onClose}
					isFilter={isFilter}
				/>
			)}
		</>
	);
};
