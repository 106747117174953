import React, { useCallback } from 'react';
import {
	Box,
	Button,
	Image,
	Modal,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalOverlay,
	Spinner,
	Text,
} from '@chakra-ui/react';

import { ButtonType } from 'shared/analytics';

interface Props {
	name: string;
	link: string;
	onApply: (resultLink: string, fileName: string) => void;
	onCancel: () => void;
	isFormOpened: boolean;
	onClose: () => void;
}

export const ImageUploadModal: React.FC<Props> = ({
	name,
	link,
	onApply,
	onCancel,
	isFormOpened,
	onClose,
}) => {
	const handleClose = useCallback(() => {
		onClose();
		onCancel();
	}, [onCancel, onClose]);

	return (
		<Modal isOpen={isFormOpened} onClose={handleClose} variant="fullscreen">
			<ModalOverlay />
			<ModalContent
				minW="100%"
				h="100%"
				m={0}
				borderRadius="none"
				overflow="hidden"
			>
				<ModalCloseButton margin="13px 5px" borderRadius={8} m={0} />
				{link ? (
					<>
						<Text
							mt="13px"
							fontWeight={600}
							fontSize="16px"
							lineHeight="24px"
							textAlign="center"
							color="#ffffff"
						>
							{name}
						</Text>
						<Image h="calc(100% - 37px)" src={link} alt={name} />
					</>
				) : (
					<Box d="flex" w="full" h="full">
						<Spinner
							m="auto"
							size="xl"
							emptyColor="rgba(255, 255, 255, 0.6)"
							color="#644DED"
							thickness="4px"
						/>
					</Box>
				)}
				<ModalFooter>
					<Button variant="dark" onClick={handleClose}>
						Cancel
					</Button>
					<Button
						disabled={!link}
						name={ButtonType.ACCEPT_IMAGE_PREVIEW}
						onClick={() => onApply(link, name)}
					>
						Apply
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};
