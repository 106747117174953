import { ChapterByIdQuery } from 'features/story-editor/header/queries.gen';

export const getDocTypeForRems = (
	docId: string,
	chapter?: ChapterByIdQuery['chapter'],
) => {
	switch (docId) {
		case chapter?.googleDocABTest1?.id: {
			return 'abDoc1';
		}
		case chapter?.googleDocABTest2?.id: {
			return 'abDoc2';
		}
		case chapter?.googleDocABTest3?.id: {
			return 'abDoc3';
		}
		case chapter?.googleDocNarrative?.id: {
			return 'narrative';
		}
		case chapter?.googleDocWriter?.id: {
			return 'writer';
		}
		case chapter?.googleDoc2Pov?.id ||
			chapter?.googleDoc2PovES?.id ||
			chapter?.googleDoc2PovFR?.id ||
			chapter?.googleDoc2PovDE?.id ||
			chapter?.googleDoc2PovNL?.id ||
			chapter?.googleDoc2PovPT?.id ||
			chapter?.googleDoc2PovIT?.id ||
			chapter?.googleDoc2PovPL?.id ||
			chapter?.googleDoc2PovSV?.id ||
			chapter?.googleDoc2PovDA?.id ||
			chapter?.googleDoc2PovNO?.id ||
			chapter?.googleDoc2PovFI?.id ||
			chapter?.googleDoc2PovID?.id ||
			chapter?.googleDoc2PovRO?.id ||
			chapter?.googleDoc2PovHU?.id ||
			chapter?.googleDoc2PovTR?.id ||
			chapter?.googleDoc2PovJA?.id ||
			chapter?.googleDoc2PovKO?.id ||
			chapter?.googleDoc2PovPT_BR?.id: {
			return 'secondPov';
		}

		default:
			return 'prod';
	}
};

export const getAnalyticsDocType = (
	docId: string,
	chapter?: ChapterByIdQuery['chapter'],
) => {
	switch (docId) {
		case chapter?.googleDocABTest1?.id: {
			return 'prodB';
		}
		case chapter?.googleDocABTest2?.id: {
			return 'prodC';
		}
		case chapter?.googleDocABTest3?.id: {
			return 'prodD';
		}
		case chapter?.googleDocNarrative?.id: {
			return 'narrator';
		}
		case chapter?.googleDocWriter?.id: {
			return 'author';
		}
		case chapter?.additionalDocs?.find((doc) => doc.id === docId)?.id: {
			const docIndex =
				chapter?.additionalDocs?.findIndex((doc) => doc.id === docId) || 0;
			return `additional${docIndex + 1}`;
		}
		case chapter?.googleDoc2Pov?.id: {
			return 'prod2POV';
		}
		case chapter?.googleDoc2PovES?.id: {
			return 'prod2POVES';
		}
		case chapter?.googleDoc2PovFR?.id: {
			return 'prod2POVFR';
		}
		case chapter?.googleDoc2PovDE?.id: {
			return 'prod2POVDE';
		}
		case chapter?.googleDoc2PovPT?.id: {
			return 'prod2POVPT';
		}
		case chapter?.googleDoc2PovNL?.id: {
			return 'prod2POVNL';
		}
		case chapter?.googleDoc2PovIT?.id: {
			return 'prod2POVIT';
		}
		case chapter?.googleDoc2PovPL?.id: {
			return 'prod2POVPL';
		}
		case chapter?.googleDoc2PovSV?.id: {
			return 'prod2POVSV';
		}
		case chapter?.googleDoc2PovDA?.id: {
			return 'prod2POVDA';
		}
		case chapter?.googleDoc2PovNO?.id: {
			return 'prod2POVNO';
		}
		case chapter?.googleDoc2PovFI?.id: {
			return 'prod2POVFI';
		}
		case chapter?.googleDoc2PovID?.id: {
			return 'prod2POVID';
		}
		case chapter?.googleDoc2PovRO?.id: {
			return 'prod2POVRO';
		}
		case chapter?.googleDoc2PovHU?.id: {
			return 'prod2POVHU';
		}
		case chapter?.googleDoc2PovTR?.id: {
			return 'prod2POVTR';
		}
		case chapter?.googleDoc2PovJA?.id: {
			return 'prod2POVJP';
		}
		case chapter?.googleDoc2PovKO?.id: {
			return 'prod2POVKR';
		}
		case chapter?.googleDoc2PovPT_BR?.id: {
			return 'prod2POVPT_BR';
		}

		default:
			return 'prod';
	}
};
