import React from 'react';
import { createIcon } from '@chakra-ui/react';

export const LinkIcon = createIcon({
	viewBox: '0 0 24 24',
	path: (
		<g fill="none">
			<path
				d="M 10.037 13.012 C 9.662 13.087 9.273 13.068 8.907 12.956 C 8.541 12.844 8.208 12.644 7.938 12.372 L 5.52 9.874 C 5.198 9.557 4.942 9.179 4.767 8.762 C 4.593 8.345 4.503 7.897 4.503 7.445 C 4.503 6.993 4.593 6.546 4.767 6.129 C 4.942 5.712 5.198 5.334 5.52 5.017 L 5.52 5.017 C 5.837 4.695 6.215 4.439 6.632 4.264 C 7.049 4.09 7.496 4 7.948 4 C 8.4 4 8.848 4.09 9.265 4.264 C 9.682 4.439 10.06 4.695 10.377 5.017 L 12.835 7.475 C 13.103 7.732 13.305 8.05 13.423 8.401 C 13.541 8.753 13.573 9.128 13.515 9.494 M 9.517 9.014 L 15.514 15.01 L 9.517 9.014 Z M 14.914 11.013 C 15.293 10.95 15.681 10.979 16.046 11.097 C 16.411 11.216 16.743 11.42 17.013 11.693 L 19.511 14.211 C 20.143 14.849 20.498 15.711 20.498 16.61 C 20.498 17.508 20.143 18.37 19.511 19.008 C 18.872 19.643 18.008 20 17.108 20 C 16.207 20 15.343 19.643 14.704 19.008 L 12.216 16.51 C 11.942 16.246 11.736 15.92 11.614 15.56 C 11.493 15.2 11.459 14.817 11.516 14.441 L 14.914 11.013 Z"
				stroke="currentColor"
				strokeWidth="1.4"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</g>
	),
});
