import React, { forwardRef, useState } from 'react';
import { Box, Text } from '@chakra-ui/react';
import cn from 'classnames';

import styles from './styles.module.scss';

interface Props
	extends React.DetailedHTMLProps<
		React.TextareaHTMLAttributes<HTMLTextAreaElement>,
		HTMLTextAreaElement
	> {
	isError?: boolean;
	isFullWidth?: boolean;
	rows?: number;
	isDisabled?: boolean;
	withLink?: boolean;
}

export const TextArea = forwardRef<HTMLTextAreaElement, Props>(
	// eslint-disable-next-line sonarjs/cognitive-complexity
	({ isError, isFullWidth, isDisabled, withLink, rows, ...props }, ref) => {
		const [isRedacting, setIsRedacting] = useState(false);

		let textSegments: string | string[][] = [];
		if (typeof props.value === 'string') {
			textSegments = props.value
				?.split('\n')
				.map((row) => row.split(/(https?:\/\/[^\s]+)/g));
		}

		return withLink && !isRedacting ? (
			<Box
				overflow="hidden"
				className={styles.textWrapper}
				onClick={() => {
					if (isDisabled) return;
					setIsRedacting(true);
				}}
			>
				<Box className={styles.text} h="130px">
					{!!textSegments.flat()[0] || textSegments.flat().length > 1
						? textSegments.map((text: string | string[]) => {
								if (text.includes('http')) {
									return (
										<a
											href={text as string}
											className={styles.link}
											onClick={(e) => e.stopPropagation()}
											target="_blank"
											rel="noreferrer"
										>
											{text}
										</a>
									);
								}
								return (
									<Text
										minH="16px"
										fontWeight={400}
										color={isDisabled ? '#808192' : '#242533'}
									>
										{Array.isArray(text)
											? text.map((word) => {
													if (word.includes('http')) {
														return (
															<a
																href={word as string}
																className={styles.link}
																onClick={(e) => e.stopPropagation()}
																target="_blank"
																rel="noreferrer"
															>
																{word}
															</a>
														);
													}
													return <span>{word}</span>;
											  })
											: text}
									</Text>
								);
						  })
						: props.placeholder?.split('\n').map((phrase) => (
								<Text variant="selectPlaceholder" fontWeight={400}>
									{phrase || <br />}
								</Text>
						  ))}
				</Box>
			</Box>
		) : (
			<textarea
				rows={rows || 5}
				onBlur={() => setIsRedacting(false)}
				disabled={isDisabled}
				// eslint-disable-next-line jsx-a11y/no-autofocus
				autoFocus={!!withLink}
				className={cn(styles.textarea, {
					[styles.error]: isError,
				})}
				style={{
					width: isFullWidth ? '100%' : '',
					color: isDisabled ? '#808192' : '#242533',
				}}
				{...props}
				ref={ref}
			/>
		);
	},
);
