import React from 'react';
import { createIcon } from '@chakra-ui/react';

export const ArtDocIcon = createIcon({
	viewBox: '0 0 24 24',
	path: (
		<g fill="none">
			<path
				d="M8.98357 9.27744L14.8633 14.8638M4.38887 17.7333C3.87038 18.2519 3.87038 19.0926 4.38887 19.6111C4.90745 20.1296 5.74814 20.1296 6.26663 19.6111L8.40406 17.4737C9.13009 16.7477 10.1469 16.391 11.1674 16.5044L12.9705 16.7047C13.4807 16.7614 13.9891 16.5831 14.3521 16.2201L19.7525 10.8197C20.0825 10.4897 20.0825 9.95471 19.7525 9.62465L18.2398 8.11195C17.9784 7.85052 17.5941 7.75466 17.2405 7.86271L14.6307 8.66018C14.5511 8.68451 14.4784 8.60659 14.5081 8.52884L15.4239 6.13344C15.5648 5.76499 15.4759 5.34814 15.197 5.06921L14.3753 4.24748C14.0453 3.91751 13.5102 3.91751 13.1803 4.24748L7.77995 9.6478C7.41685 10.0109 7.23855 10.5193 7.29525 11.0295L7.4956 12.8326C7.609 13.8531 7.25233 14.8698 6.5263 15.5959L4.38887 17.7333Z"
				stroke="currentColor"
				strokeWidth="1.5"
			/>
		</g>
	),
});
