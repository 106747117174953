import React, { useCallback } from 'react';
import { IconButton, IconButtonProps } from '@chakra-ui/react';

import { ButtonType } from '../base';

interface AnalyticsButtonProps extends IconButtonProps {
	name: ButtonType;
	href?: string;
	isExternal?: boolean;
}
export const AnalyticsIconButton = React.forwardRef<
	HTMLButtonElement,
	AnalyticsButtonProps
>(({ name, children, onClick, ...rest }, ref) => {
	const handleClick = useCallback(
		(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
			if (onClick) {
				onClick(e);
			}
		},
		[onClick],
	);

	return (
		<IconButton onClick={handleClick} {...rest} ref={ref}>
			{children}
		</IconButton>
	);
});
