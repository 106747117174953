/* eslint-disable jsx-a11y/media-has-caption */
import React, { useRef } from 'react';
import { Box, Button, Flex } from '@chakra-ui/react';

import { ButtonType } from 'shared/analytics';
import { useAudioPlayer } from 'shared/hooks/audio';
import { PauseIcon, PlayIcon } from 'shared/icons';

import { Bar } from '../progress-bar';

interface Props {
	link?: string;
}

export const Audio: React.FC<Props> = ({ link }) => {
	const audioRef = useRef<HTMLAudioElement>(null);
	const { currentTime, duration, isPlaying, setIsPlaying, setClickedTime } =
		useAudioPlayer(audioRef);

	const handleClickAudioButton = (
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
	) => {
		e.stopPropagation();
		setIsPlaying((prev) => !prev);
	};

	return (
		<Box
			w="full"
			border="1px solid #EEEEF2"
			borderRadius="10px"
			p="6px 18px 6px 6px"
		>
			<audio ref={audioRef} id="audio" src={link} />
			<Flex alignItems="center">
				<Button
					name={ButtonType.TOGGLE_AUDIO_PLAYBACK}
					variant="secondary"
					onClick={(e) => handleClickAudioButton(e)}
					mr="6px"
				>
					{isPlaying ? <PauseIcon /> : <PlayIcon />}
				</Button>
				<Bar
					duration={duration}
					curTime={currentTime}
					onTimeUpdate={(time) => setClickedTime(time)}
				/>
			</Flex>
		</Box>
	);
};
