import React from 'react';
import { createIcon } from '@chakra-ui/react';

export const SortIcon = createIcon({
	viewBox: '0 0 18 13',
	path: (
		<>
			<path
				d="M2.12124 3.8291L4.94978 1.00056L7.77832 3.8291"
				fill="transparent"
				stroke="currentColor"
				strokeWidth="1.2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M4.94996 1.00025L4.94996 10.9001"
				fill="transparent"
				stroke="currentColor"
				strokeWidth="1.2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M15.6787 9.07129L12.8502 11.8998L10.0216 9.07129"
				fill="transparent"
				stroke="currentColor"
				strokeWidth="1.2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M12.8499 2.00025L12.8499 11.9001"
				fill="transparent"
				stroke="currentColor"
				strokeWidth="1.2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</>
	),
});
