import React, { useCallback } from 'react';
import {
	Box,
	Button,
	Flex,
	Grid,
	GridItem,
	Spinner,
	Switch,
	useDisclosure,
} from '@chakra-ui/react';
import { Form, Formik } from 'formik';

import { GetBookDocument } from 'features/book/queries.gen';
import { ChapterByIdDocument } from 'features/story-editor/header/queries.gen';
import { ButtonType } from 'shared/analytics';
import { useAppToast } from 'shared/hooks/toast';
import { TrashBinIcon } from 'shared/icons';
import { EntityDeletionAlert } from 'shared/ui/entity-deletion-alert';
import { FormInputField, FormTextArea } from 'shared/ui/index';

import {
	GetDocsNotesByIdDocument,
	useDeleteDocsNotesMutation,
} from './queries.gen';
import { docNotesSchema } from './schema';
import styles from './styles.module.scss';

type DocsNotes = {
	name: string;
	link: string;
	description: string;
	isActive: boolean;
};

export type DocNotesData = {
	id: string;
	name?: string | null;
	link?: string | null;
	description?: string | null;
	isActive?: boolean | null;
};

export type EditDocNotes = {
	id?: string;
	description?: string;
	isActive?: boolean;
	link?: string;
	name?: string;
};

export interface Props {
	onClose: () => void;
	onSubmit: (docsNotes: EditDocNotes) => Promise<void>;
	docType: 'A/B' | 'Additional Doc';
	isSubmittingForm: boolean;
	docNotesData?: DocNotesData | null;
}

export const EditDocDescription: React.FC<Props> = ({
	onClose,
	docType,
	onSubmit,
	isSubmittingForm,
	docNotesData,
}) => {
	const toast = useAppToast();

	const {
		isOpen: isOpenDeleteModal,
		onOpen: onOpenDeleteModal,
		onClose: onCloseDeleteModal,
	} = useDisclosure();

	const [removeDocNotes, { loading: isLoading }] = useDeleteDocsNotesMutation({
		refetchQueries: [
			ChapterByIdDocument,
			GetBookDocument,
			GetDocsNotesByIdDocument,
		],
	});

	const deleteDocNotes = useCallback(async () => {
		if (docNotesData) {
			try {
				await removeDocNotes({
					variables: {
						id: docNotesData.id || '',
					},
				});

				toast({
					title: ` ${docType} Notes deleted successfully`,
					status: 'success',
				});
			} catch {
				toast({
					title: `${docType} Notes were not deleted`,
					status: 'error',
				});
			}
			onClose();
		}
	}, [docNotesData, docType, onClose, removeDocNotes, toast]);

	return (
		<Formik
			initialValues={
				{
					name: docNotesData?.name || '',
					link: docNotesData?.link || '',
					description: docNotesData?.description || '',
					isActive: docNotesData?.isActive || false,
				} as DocsNotes
			}
			validationSchema={docNotesSchema}
			onSubmit={(values) => onSubmit(values)}
		>
			{({ setFieldValue, values, dirty, touched }) => (
				<Form className={styles.container}>
					<div className={styles.form}>
						<FormInputField
							label={`${docType} Name`}
							name="name"
							placeholder={`${docType} Name`}
							required
						/>
						<FormInputField label="Asana link" name="link" placeholder="Link" />
						<FormTextArea
							rows={8}
							label={`${docType} Description`}
							name="description"
							placeholder={`${docType} Description`}
						/>
						<Grid
							height="50px"
							gridTemplateColumns="1fr 83px"
							alignItems="center"
						>
							<GridItem>Is active</GridItem>
							<GridItem>
								<Flex justify="flex-end">
									<Switch
										size="lg"
										isChecked={values.isActive}
										onChange={() => {
											setFieldValue('isActive', !values.isActive);
										}}
										mx="2"
									/>
								</Flex>
							</GridItem>
						</Grid>
						{docNotesData && (
							<Button
								mt="50px"
								mb="10px"
								alignSelf="flex-end"
								disabled={isSubmittingForm || isLoading}
								onClick={onOpenDeleteModal}
								leftIcon={<TrashBinIcon w={6} h={6} />}
							>
								Delete Notes
							</Button>
						)}
					</div>
					<Box
						className={styles.bottomContainer}
						visibility={
							dirty || Object.keys(touched).length !== 0 || !docNotesData
								? 'visible'
								: 'hidden'
						}
					>
						<Button
							className={styles.defaultButton}
							type="button"
							size="lg"
							variant="secondary"
							onClick={onClose}
						>
							Cancel
						</Button>
						<Button
							name={ButtonType.CREATE_CHAPTER}
							className={styles.defaultButton}
							type="submit"
							size="lg"
							variant="primary"
							disabled={isSubmittingForm || !dirty}
							width="75px"
						>
							{isSubmittingForm ? <Spinner size="sm" /> : 'Apply'}
						</Button>
					</Box>
					{isOpenDeleteModal && (
						<EntityDeletionAlert
							isOpen={isOpenDeleteModal}
							onClose={onCloseDeleteModal}
							onSubmit={deleteDocNotes}
							buttonName={ButtonType.DELETE_DOC_NOTES}
							title={`${docType} Notes Deletion`}
							description={`If you delete this ${docType} notes, it can't be restored. Are you sure you want to continue?`}
						/>
					)}
				</Form>
			)}
		</Formik>
	);
};
