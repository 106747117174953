import React from 'react';
import { Flex } from '@chakra-ui/react';

import { SearchInput } from 'shared/ui';

interface HeaderProps {
	searchAsset: string;
	setSearchAsset: (v: string) => void;
}

const Header: React.FC<HeaderProps> = ({ searchAsset, setSearchAsset }) => {
	return (
		<Flex
			alignItems="center"
			justifyContent="space-between"
			gap={5}
			px={6}
			py={4}
			height="78px"
			borderBottom="1px solid #eeeef2"
		>
			<SearchInput
				search={searchAsset}
				setSearch={setSearchAsset}
				placeholder="Search by background"
			/>
		</Flex>
	);
};

export default Header;
