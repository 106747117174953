import React from 'react';
import { Box, Flex, Tooltip } from '@chakra-ui/react';

import { InfoIcon } from 'shared/icons';

interface Props {
	docNotesName?: string | null;
	label: string;
}

const DocNotesTooltip: React.FC<Props> = ({ docNotesName, label }) => {
	return (
		<Tooltip
			placement="right"
			offset={[0, 35]}
			fontSize="14px"
			lineHeight="114%"
			p="10px"
			maxW="200px"
			label={docNotesName}
		>
			<Flex w="100%" justify="space-between" alignItems="center">
				<Box>{label}</Box>
				{docNotesName && (
					<InfoIcon
						w={6}
						h={6}
						cursor="pointer"
						color="#808192"
						_groupHover={{ color: '#8960FF' }}
					/>
				)}
			</Flex>
		</Tooltip>
	);
};

export default DocNotesTooltip;
