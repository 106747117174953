import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, useDisclosure } from '@chakra-ui/react';

import {
	GetBookDocument,
	GetBookInfoDocument,
	GetBooksDocument,
} from 'features/book/queries.gen';
import { ButtonType, trackChapterDeleted } from 'shared/analytics';
import { useAppToast } from 'shared/hooks/toast';
import { TrashBinIcon } from 'shared/icons';
import { EntityDeletionAlert } from 'shared/ui/entity-deletion-alert';
import { ChaptersDocument } from 'widgets/existing-heroes-modal/graphql/queries.gen';

import { useDeleteChapterMutation } from './queries.gen';

interface DeleteChapterButtonProps {
	id: string;
	chapterName: string;
	customBookId: string;
	chapterNumber: number;
}

export const DeleteChapterButton: React.FC<DeleteChapterButtonProps> = ({
	id,
	chapterName,
	customBookId,
	chapterNumber,
}) => {
	const { isOpen, onClose, onOpen } = useDisclosure();
	const [isDeleteGoogleDrive, setIsDeleteGoogleDrive] =
		useState<boolean>(false);
	const toast = useAppToast();
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const [deleteChapter, { loading }] = useDeleteChapterMutation({
		variables: { id, isDeleteGoogleDrive },
		onCompleted: () => {
			trackChapterDeleted({ customBookId, chapterName, chapterNumber });
			toast({ title: 'Chapter deleted successfully', status: 'success' });
			if (pathname.split('/').length === 5) {
				navigate(pathname.split('/').splice(3, 2).join('/'));
			}
		},
		onError: () => toast({ title: 'Chapter was not deleted', status: 'error' }),
		refetchQueries: [
			ChaptersDocument,
			GetBookDocument,
			GetBooksDocument,
			GetBookInfoDocument,
		],
	});

	return (
		<>
			<Button
				disabled={loading}
				onClick={onOpen}
				leftIcon={<TrashBinIcon w={6} h={6} />}
			>
				Delete Chapter
			</Button>
			{isOpen && (
				<EntityDeletionAlert
					isOpen={isOpen}
					onClose={onClose}
					onSubmit={deleteChapter}
					buttonName={ButtonType.DELETE_CHAPTER}
					isDeleteGoogleDrive={isDeleteGoogleDrive}
					checked
					onChecked={() => setIsDeleteGoogleDrive((prev) => !prev)}
					title="Chapter Deletion"
					description="If you delete this chapter, it can't be restored. Are you sure you want to continue?"
				/>
			)}
		</>
	);
};
