import { trackModalOpen } from './base';
import { Modal } from './types';

export const trackCreateBookModalOpen = () => trackModalOpen(Modal.CREATE_BOOK);
export const trackDeleteBookModalOpen = () => trackModalOpen(Modal.DELETE_BOOK);

export const trackCreateChapterModalOpen = () =>
	trackModalOpen(Modal.CREATE_CHAPTER);
export const trackDeleteChapterModalOpen = () =>
	trackModalOpen(Modal.DELETE_CHAPTER);
export const trackCreateHeroModalOpen = () => trackModalOpen(Modal.CREATE_HERO);
export const trackDeleteHeroModalOpen = () => trackModalOpen(Modal.DELETE_HERO);

export const trackCreateAssetModalOpen = (modal: Modal) =>
	trackModalOpen(modal);

export const trackDeleteAssetBodyModalOpen = () =>
	trackModalOpen(Modal.DELETE_BODY);
export const trackDeleteAssetEmotionModalOpen = () =>
	trackModalOpen(Modal.DELETE_EMOTION);
export const trackDeleteAssetHairModalOpen = () =>
	trackModalOpen(Modal.DELETE_HAIR);
export const trackDeleteAssetClothesModalOpen = () =>
	trackModalOpen(Modal.DELETE_CLOTHES);
export const trackDeleteAssetLayerModalOpen = () =>
	trackModalOpen(Modal.DELETE_LAYER);
export const trackDeleteAssetItemModalOpen = () =>
	trackModalOpen(Modal.DELETE_ITEM);
export const trackDeleteAssetBackgroundModalOpen = () =>
	trackModalOpen(Modal.DELETE_BACKGROUND);
export const trackArtDocModalOpen = () => trackModalOpen(Modal.ART_DOC);
export const trackChapterPropertiesModalOpen = () =>
	trackModalOpen(Modal.CHAPTER_PROPERTIES);
export const trackAddStoryModalOpen = () => trackModalOpen(Modal.ADD_STORY);
export const trackAnExistingHeroModalOpen = () =>
	trackModalOpen(Modal.AN_EXISTING_HERO);
export const trackAddBackgroundModalOpen = () =>
	trackModalOpen(Modal.ADD_BACKGROUND);
export const trackAddItemModalOpen = () => trackModalOpen(Modal.ADD_ITEM);
export const trackHeroDetailsModalOpen = () =>
	trackModalOpen(Modal.HERO_DETAILS);
export const trackAssetDetailsModalOpen = (modal: Modal) =>
	trackModalOpen(modal);
export const trackHeroRemoveModalOpen = () => trackModalOpen(Modal.REMOVE_HERO);
export const trackBackgroundRemoveModalOpen = () =>
	trackModalOpen(Modal.REMOVE_BACKGROUND);
export const trackItemRemoveModalOpen = () => trackModalOpen(Modal.REMOVE_ITEM);
export const trackAssetFilterModalOpen = (modal: Modal) =>
	trackModalOpen(modal);

export const trackAssetKeywordsModalOpen = (modal: Modal) =>
	trackModalOpen(modal);

export const trackBuilderAddStoryModalOpen = () =>
	trackModalOpen(Modal.BUILDER_ADD_STORY);
export const trackBuilderTransferModalOpen = () =>
	trackModalOpen(Modal.BUILDER_TRANSFER);
export const trackClothingBeforeTextSavingModalOpen = () =>
	trackModalOpen(Modal.CLOTHING_BEFORE_TEXT_SAVING);
export const trackClothingWithoutLuaSavingModalOpen = () =>
	trackModalOpen(Modal.CLOTHING_WITHOUT_LUA_SAVING);
export const trackFindAndReplaceModalOpen = () =>
	trackModalOpen(Modal.FIND_AND_REPLACE);
export const trackFindByTextModalOpen = () =>
	trackModalOpen(Modal.FIND_BY_TEXT);
export const trackFindByDialogModalOpen = () =>
	trackModalOpen(Modal.FIND_BY_DIALOG);

// export const editAsset = () => trackModalOpen(Modal.EDIT_ASSET);

// export const createChapter = () => trackModalOpen(Modal.CREATE_CHAPTER);
// export const editChapter = () => trackModalOpen(Modal.EDIT_CHAPTER);

// export const openDocDescription = () =>
// 	trackModalOpen(Modal.EDIT_DOC_DESCRIPTION);

// export const addBooksToCharacter = () =>
// 	trackModalOpen(Modal.ADD_BOOKS_TO_CHARACTER);
// export const assetImagePreview = () =>
// 	trackModalOpen(Modal.ASSET_IMAGE_PREVIEW);
// export const assetImageView = () => trackModalOpen(Modal.ASSET_IMAGE_VIEW);
// export const selectChapters = () => trackModalOpen(Modal.SELECT_CHAPTERS);

// export const addExistingCharacter = () =>
// 	trackModalOpen(Modal.ADD_EXISTING_CHARACTER);

// export const addHeroToCard = () => trackModalOpen(Modal.ADD_HERO_TO_CARD);

// export const createCharacter = () => trackModalOpen(Modal.CREATE_CHARACTER);
// export const createCard = () => trackModalOpen(Modal.CREATE_CARD);
// export const updateCard = () => trackModalOpen(Modal.UPDATE_CARD);
// export const editCharacter = () => trackModalOpen(Modal.EDIT_CHARACTER);
// export const uploadImage = () => trackModalOpen(Modal.UPLOAD_IMAGE);
// export const legacyWriter = () =>
// 	trackModalOpen(Modal.LEGACY_WRITER_CHAPTER_PICKER);

// export const transferBuckets = () =>
// 	trackModalOpen(Modal.TRANSFER_BETWEEN_BUCKETS);

// export const createTemplate = () => trackModalOpen(Modal.CREATE_BUILD_TEMPLATE);
// export const editTemplate = () => trackModalOpen(Modal.EDIT_BUILD_TEMPLATE);
