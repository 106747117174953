import React, { useCallback } from 'react';
import {
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
} from '@chakra-ui/react';

import { GetBookDocument } from 'features/book/queries.gen';
import { ChapterByIdDocument } from 'features/story-editor/header/queries.gen';
import { useAppToast } from 'shared/hooks/toast';
import {
	EditDocDescription,
	EditDocNotes,
} from 'shared/ui/edit-doc-description';
import {
	GetDocsNotesByIdDocument,
	useCreateDocsNotesMutation,
} from 'shared/ui/edit-doc-description/queries.gen';

interface CreateDocNotesModalProps {
	isOpen: boolean;
	onClose: () => void;
	documentId: string;
	docType: 'A/B' | 'Additional Doc';
	label?: string;
}

export const CreateDocNotesModal: React.FC<CreateDocNotesModalProps> = ({
	documentId,
	isOpen,
	onClose,
	docType,
	label,
}) => {
	const toast = useAppToast();

	const [createDocsNotes, { loading: isLoading }] = useCreateDocsNotesMutation({
		refetchQueries: [
			ChapterByIdDocument,
			GetBookDocument,
			GetDocsNotesByIdDocument,
		],
	});
	const handleDocsNotesCreate = useCallback(
		async (docsNotes: EditDocNotes) => {
			try {
				await createDocsNotes({
					variables: {
						docsNotes: {
							...docsNotes,
						},
						docId: documentId,
					},
				});
				onClose();

				toast({
					title: `${docType} Notes created successfully`,
					status: 'success',
				});
			} catch (error) {
				toast({
					title: `${docType} Notes were not created`,
					status: 'error',
				});
			}
		},
		[createDocsNotes, documentId, docType, onClose, toast],
	);

	return (
		<Modal isOpen={isOpen} onClose={onClose} autoFocus={false}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Add {label} Notes</ModalHeader>
				<ModalCloseButton margin="10px" />
				<ModalBody p={0}>
					<EditDocDescription
						onClose={onClose}
						docType={docType}
						onSubmit={handleDocsNotesCreate}
						isSubmittingForm={isLoading}
					/>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};
