import React from 'react';
import { createIcon } from '@chakra-ui/react';

export const CrossSmallIcon = createIcon({
	viewBox: '0 0 24 24',
	path: (
		<g fill="none">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M16.7231 8.26034C17.005 7.95681 16.9874 7.48226 16.6839 7.20041C16.3803 6.91856 15.9058 6.93613 15.6239 7.23966L12.4618 10.6451L9.2996 7.23966C9.01775 6.93613 8.5432 6.91856 8.23966 7.20041C7.93613 7.48226 7.91856 7.95681 8.20041 8.26034L11.4383 11.7473L8.20041 15.2342C7.91856 15.5378 7.93613 16.0123 8.23966 16.2942C8.5432 16.576 9.01775 16.5584 9.2996 16.2549L12.4618 12.8495L15.6239 16.2549C15.9058 16.5584 16.3803 16.576 16.6839 16.2942C16.9874 16.0123 17.005 15.5378 16.7231 15.2342L13.4853 11.7473L16.7231 8.26034Z"
				fill="currentColor"
			/>
		</g>
	),
});
