import React from 'react';
import { Button, Center, Flex, Grid, Spacer, Spinner } from '@chakra-ui/react';

import { HeroCard } from 'features/hero';
import { ButtonType } from 'shared/analytics';
import { ArrayElement } from 'shared/model';
import NotFound from 'shared/ui/not-found';
import {
	HeroesQuery,
	StoriesQuery,
} from 'widgets/existing-heroes-modal/graphql/queries.gen';

import SearchStory from '../search-story';
import SortHeroes from '../sort-heroes';

interface ChooseHeroesTabProps {
	selectedHero: ArrayElement<HeroesQuery['heroes']> | null;
	setSelectedHero: (hero: ArrayElement<HeroesQuery['heroes']> | null) => void;
	sortHeroes: string;
	setSortHeroes: (v: string) => void;
	heroes: HeroesQuery['heroes'];
	loadingHeroes: boolean;
	stories: StoriesQuery['books'];
	loadingStories: boolean;
	setSelectStory: (v: string) => void;
	searchStory: string;
	setSearchStory: (v: string) => void;
	handleLoadMoreHeroes: () => void;
	isLoading: boolean;
	loadMore: boolean;
}

const ChooseHeroesTab: React.FC<ChooseHeroesTabProps> = ({
	selectedHero,
	setSelectedHero,
	sortHeroes,
	setSortHeroes,
	heroes,
	loadingHeroes,
	stories,
	loadingStories,
	setSelectStory,
	searchStory,
	setSearchStory,
	handleLoadMoreHeroes,
	isLoading,
	loadMore,
}) => {
	return (
		<>
			<Flex px={6} my={3}>
				<SearchStory
					stories={stories}
					loadingStories={loadingStories}
					setSelectStory={setSelectStory}
					searchStory={searchStory}
					setSearchStory={setSearchStory}
				/>
				<Spacer />
				<SortHeroes sortHeroes={sortHeroes} setSortHeroes={setSortHeroes} />
			</Flex>
			{loadingHeroes && !loadMore && <Spinner size="xl" m="auto" />}
			{heroes.length ? (
				<Grid gap={3} px={5} pb={4} alignContent="start" overflow="auto">
					{heroes.map((hero) => (
						<HeroCard
							hero={hero}
							heroId={hero.id}
							key={hero.id}
							selectedHero={selectedHero}
							setSelectedHero={setSelectedHero}
						/>
					))}
					{loadMore ? (
						<Center>
							<Button
								name={ButtonType.LOAD_MORE_CHARACTERS}
								size="lg"
								variant="ghost"
								isLoading={isLoading}
								onClick={handleLoadMoreHeroes}
							>
								Load More
							</Button>
						</Center>
					) : null}
				</Grid>
			) : null}
			{!loadingHeroes && !heroes.length && <NotFound />}
		</>
	);
};

export default ChooseHeroesTab;
