import React, { useMemo } from 'react';
import { Image } from '@chakra-ui/react';

interface ImageData {
	link?: string | null;
	position?: number | null;
}

interface HeroImageProps {
	hairBackData?: ImageData;
	bodyData?: ImageData;
	emotionsData?: ImageData;
	hairFrontData?: ImageData;
	layersData?: ImageData[];
	clothesData?: ImageData;
}

export const HeroImage: React.FC<HeroImageProps> = ({
	hairBackData,
	bodyData,
	emotionsData,
	hairFrontData,
	layersData = [],
	clothesData,
}) => {
	const sortedImages = useMemo(() => {
		return [
			hairBackData,
			bodyData,
			emotionsData,
			hairFrontData,
			clothesData,
			...layersData,
		]
			.filter((el) => !!el?.link)
			.sort((a, b) => (a?.position || 0) - (b?.position || 0));
	}, [
		bodyData,
		clothesData,
		emotionsData,
		hairBackData,
		hairFrontData,
		layersData,
	]);

	return (
		<>
			{sortedImages.map((image) => (
				<Image
					position="absolute"
					margin="auto"
					htmlHeight="100%"
					htmlWidth="100%"
					alt="hero-image"
					bottom="0"
					maxH="100%"
					src={image?.link || ''}
				/>
			))}
		</>
	);
};
