import { createEvent, createStore } from 'effector';
import { useStore } from 'effector-react';
import { persist } from 'effector-storage/local';

import { FEATURES_TYPE, initialFeatures } from 'pages/script-editor';
import { Permission } from 'models.gen';

export const setEmail = createEvent<string>('set email');
export const setPicture = createEvent<string | null>('set picture');
export const setAccessToken = createEvent<string | null>('set access token');
export const setRefreshToken = createEvent<string | null>('set refresh token');
export const setPermissions = createEvent<Permission[]>('set user permissions');
export const setFeatures = createEvent<FEATURES_TYPE>('set customize editor');
export const $email = createStore<string | null>(null).on(
	setEmail,
	(_, email) => email,
);
export const $picture = createStore<string | null>(null).on(
	setPicture,
	(_, picture) => picture,
);
export const $accessToken = createStore<string | null>(null).on(
	setAccessToken,
	(_, accessToken) => accessToken,
);
export const $refreshToken = createStore<string | null>(null).on(
	setRefreshToken,
	(_, refreshToken) => refreshToken,
);
export const $permissions = createStore<Permission[]>([]).on(
	setPermissions,
	(_, permissions) => permissions,
);
export const $features = createStore<FEATURES_TYPE>(initialFeatures).on(
	setFeatures,
	(_, features) => features,
);

persist({
	store: $accessToken,
	key: 'accessToken',
	serialize: (v) => v,
	deserialize: (v) => v,
});
persist({
	store: $refreshToken,
	key: 'refreshToken',
	serialize: (v) => v,
	deserialize: (v) => v,
});
persist({
	store: $picture,
	key: 'picture',
	serialize: (v) => v,
	deserialize: (v) => v,
});
persist({
	store: $features,
	key: 'customizeEditor',
	serialize: (v) => JSON.stringify(v),
	deserialize: (v) => JSON.parse(v),
});

export const useIsUserAuthorized = () => {
	const userEmail = useStore($email);
	const userAccessToken = useStore($accessToken);

	return Boolean(userEmail && userAccessToken);
};
