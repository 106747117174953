import React, { useCallback } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';

import { PermissionGuard } from 'shared/utils';
import { Permission } from 'models.gen';

import { ListItem } from '../list-item';
import { SearchInput } from '../search-input';

interface Option {
	title: string;
	value: string;
}

interface Props {
	label?: string;
	options: Option[];
	selectedOption?: string | null;
	onChange: (selected: string) => void;
	onClose?: (() => void) | null;
	isIcon?: boolean;
	hasSearch?: boolean;
	search?: string;
	setSearch?: (v: string) => void;
	isNeedPermissionToSearch?: boolean;
	optionText?: string;
	onApply?: (v: string) => void;
}

export const SingleChoiceList: React.FC<Props> = ({
	label = '',
	options,
	selectedOption,
	onChange,
	onClose,
	isIcon = false,
	hasSearch = false,
	search = '',
	setSearch,
	isNeedPermissionToSearch,
	optionText,
	onApply,
}) => {
	const handleSelect = useCallback(
		(value: string, title: string) => {
			const newValue = value === selectedOption ? '' : value;
			onChange(newValue);

			if (setSearch && newValue && !onApply) setSearch(title);
			if (onClose) onClose();
		},
		[onChange, onClose, selectedOption, setSearch, onApply],
	);

	return (
		<>
			{hasSearch && setSearch && (
				<PermissionGuard
					permissions={
						isNeedPermissionToSearch ? [Permission.BundlerAdmin] : []
					}
				>
					<Box m="5px 8px 10px 8px">
						<SearchInput
							search={search}
							setSearch={setSearch}
							handleCrossClick={() => handleSelect('', '')}
						/>
					</Box>
				</PermissionGuard>
			)}
			<Flex
				mx="2px"
				w="full"
				h="full"
				overflow="auto"
				flexDirection="column"
				gap="5px"
			>
				{label && (
					<Box
						p="10px 10px 2px"
						fontWeight={500}
						fontSize="14px"
						lineHeight="20px"
						color="#242533"
					>
						{label}
					</Box>
				)}
				{options.length
					? options.map(({ title, value }) => (
							<ListItem
								key={value}
								label={title}
								isMultipleChoice={false}
								hasCheckbox={false}
								isSelected={value === selectedOption}
								onSelect={() => handleSelect(value, title)}
								isIcon={isIcon}
							/>
					  ))
					: optionText && (
							<Text fontSize="14px" align="center">
								{optionText}
							</Text>
					  )}
			</Flex>
		</>
	);
};
