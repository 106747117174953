import React from 'react';
import { useStore } from 'effector-react';

import { docsModel } from 'entities/docs';
import { scriptModel } from 'entities/script';
import { formatDate } from 'shared/utils';

import styles from '../header/styles.module.scss';

interface LastUpdatedProps {
	pageName: string;
}
export const LastUpdatedDraftDoc = () => {
	const updatedAt = useStore(scriptModel.$lastUpdateDraftDoc);

	const lastUpdateAdditionalDocDate = useStore(
		docsModel.$lastUpdateAdditionalDocDate,
	);
	return (
		<>
			last edited:{' '}
			{formatDate((lastUpdateAdditionalDocDate || updatedAt) as string) || '-'}
		</>
	);
};

export const LastUpdatedGoogleDoc = () => {
	const updatedAt = useStore(docsModel.$lastUpdateDocDate)?.modifiedTime;

	return <>last edited: {formatDate(updatedAt as string) || '-'}</>;
};

export const LastUpdated: React.FC<LastUpdatedProps> = ({ pageName }) => (
	<span className={styles.storySync} style={{ marginRight: '10px' }}>
		{pageName.includes('Additional Doc') ? (
			<LastUpdatedDraftDoc />
		) : (
			<LastUpdatedGoogleDoc />
		)}
	</span>
);
