import React from 'react';
import { createIcon } from '@chakra-ui/react';

export const CopyIcon = createIcon({
	viewBox: '0 0 24 24',
	path: (
		<>
			<path
				d="M17.4808 9.75h-5.7116c-.701 0-1.2692.5682-1.2692 1.2692v5.7116c0 .7009.5682 1.2692 1.2692 1.2692h5.7116c.7009 0 1.2692-.5683 1.2692-1.2692v-5.7116c0-.701-.5683-1.2692-1.2692-1.2692Z"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
				fill="none"
			/>
			<path
				d="M7.90385 13.5h-.63462c-.33662 0-.65945-.1337-.89748-.3717C6.13372 12.8902 6 12.5674 6 12.2308V6.51923c0-.33662.13372-.65945.37175-.89748.23803-.23803.56086-.37175.89748-.37175h5.71157c.3366 0 .6594.13372.8975.37175.238.23803.3717.56086.3717.89748v.63462"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
				fill="none"
			/>
		</>
	),
});
