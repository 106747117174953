import { ImageConfig } from '../drawImage';

export const DRAW_TYPES = {
	PREVIEW: { w: 338, h: 562 },
	DRAW_1X: { w: 496, h: 826 }, // x 1,47  preview change to draw 1x
	DRAW_1_5X: { w: 744, h: 1239 },
	DRAW_HERO: { w: 882, h: 1984 },
};

export interface DRAW_CANVAS_CONFIG {
	type: { w: number; h: number };
	configs: ImageConfig[];
}

// 338 x 562
export const PREVIEW_DRAW_CONFIG = {
	BACKGROUND: { dx: -220, dy: 0, w: 776, h: 640, isCanvas: true },
	BACK_LINK: { dx: -35, dy: -80, w: 400, h: 900 },
	FRONT_LINK: { dx: -35, dy: -80, w: 400, h: 900 },
	BODY: { dx: -35, dy: -80, w: 400, h: 900 },
	CLOTHES: { dx: -35, dy: -80, w: 400, h: 900 },
	EMOTION: { dx: -35, dy: -80, w: 400, h: 900 },
	LAYER: { dx: -35, dy: -80, w: 400, h: 900 },
};

// 496 x 826
// export const Draw1xConfig = {
// 	BACKGROUND: { dx: -323, dy: 0, w: 1141, h: 940, isCanvas: true },
// 	BACK_LINK: { dx: -52, dy: -116, w: 588, h: 1323 },
// 	FRONT_LINK: { dx: -52, dy: -116, w: 588, h: 1323 },
// 	BODY: { dx: -52, dy: -116, w: 588, h: 1323 },
// 	CLOTHES: { dx: -52, dy: -116, w: 588, h: 1323 },
// 	EMOTION: { dx: -52, dy: -116, w: 588, h: 1323 },
// 	LAYER: { dx: -52, dy: -116, w: 588, h: 1323 },
// };

// 744 x 1239
export const DRAW1_5x_CONFIG = {
	BACKGROUND: { dx: -484, dy: 0, w: 1711, h: 1410, isCanvas: true },
	BACK_LINK: { dx: -77, dy: -173, w: 882, h: 1984 },
	FRONT_LINK: { dx: -77, dy: -173, w: 882, h: 1984 },
	BODY: { dx: -77, dy: -173, w: 882, h: 1984 },
	CLOTHES: { dx: -77, dy: -173, w: 882, h: 1984 },
	EMOTION: { dx: -77, dy: -173, w: 882, h: 1984 },
	LAYER: { dx: -77, dy: -173, w: 882, h: 1984 },
};

export const PREVIEW_CONFIG = [
	PREVIEW_DRAW_CONFIG.BACKGROUND,
	PREVIEW_DRAW_CONFIG.BACK_LINK,
	PREVIEW_DRAW_CONFIG.BODY,
	PREVIEW_DRAW_CONFIG.CLOTHES,
	PREVIEW_DRAW_CONFIG.EMOTION,
	PREVIEW_DRAW_CONFIG.FRONT_LINK,
	PREVIEW_DRAW_CONFIG.LAYER,
];

// export const draw1xConfig = [
// 	Draw1xConfig.BACKGROUND,
// 	Draw1xConfig.BACK_LINK,
// 	Draw1xConfig.BODY,
// 	Draw1xConfig.CLOTHES,
// 	Draw1xConfig.EMOTION,
// 	Draw1xConfig.FRONT_LINK,
// 	Draw1xConfig.LAYER,
// ];
export const DRAW1_5xCONFIG = [
	DRAW1_5x_CONFIG.BACKGROUND,
	DRAW1_5x_CONFIG.BACK_LINK,
	DRAW1_5x_CONFIG.BODY,
	DRAW1_5x_CONFIG.CLOTHES,
	DRAW1_5x_CONFIG.EMOTION,
	DRAW1_5x_CONFIG.FRONT_LINK,
	DRAW1_5x_CONFIG.LAYER,
];

export const DRAW_HERO_CONFIG = {
	BACKGROUND: { dx: -724, dy: 0, w: 2330, h: 1984 },
	BACK_LINK: { dx: 0, dy: 0, w: 882, h: 1984 },
	FRONT_LINK: { dx: 0, dy: 0, w: 882, h: 1984 },
	BODY: { dx: 0, dy: 0, w: 882, h: 1984 },
	CLOTHES: { dx: 0, dy: 0, w: 882, h: 1984 },
	EMOTION: { dx: 0, dy: 0, w: 882, h: 1984 },
	LAYER: { dx: 0, dy: 0, w: 882, h: 1984 },
};
export const DRAW_HERO_1X_CONFIG = [
	DRAW_HERO_CONFIG.BACKGROUND,
	DRAW_HERO_CONFIG.BACK_LINK,
	DRAW_HERO_CONFIG.BODY,
	DRAW_HERO_CONFIG.CLOTHES,
	DRAW_HERO_CONFIG.EMOTION,
	DRAW_HERO_CONFIG.FRONT_LINK,
	DRAW_HERO_CONFIG.LAYER,
	DRAW_HERO_CONFIG.LAYER,
	DRAW_HERO_CONFIG.LAYER,
	DRAW_HERO_CONFIG.LAYER,
	DRAW_HERO_CONFIG.LAYER,
];
