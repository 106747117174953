import * as Types from '../../../models.gen';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateChapterDocMutationVariables = Types.Exact<{
  chapter: Types.UpdateChapterRequestDto;
}>;


export type UpdateChapterDocMutation = { updateChapterDoc: { id: string } };

export type UpdateChapterMutationVariables = Types.Exact<{
  chapter: Types.UpdateChapterRequestDto;
}>;


export type UpdateChapterMutation = { updateChapter: { id: string } };

export type GenerateChapterDocMutationVariables = Types.Exact<{
  bookId: Types.Scalars['String'];
  chapterId: Types.Scalars['String'];
  config: Types.CreateChapterDocByConfigRequestDto;
}>;


export type GenerateChapterDocMutation = { createChapterDocByConfig: { id: string } };

export type RemoveAdditionalDocMutationVariables = Types.Exact<{
  documentId: Types.Scalars['String'];
}>;


export type RemoveAdditionalDocMutation = { removeAdditionalDoc: string };

export type GetChapterByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type GetChapterByIdQuery = { chapter: { chapterOrder?: number | null } };

export type GetChapterDocsQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type GetChapterDocsQuery = { chapter: { googleDoc?: { id: string, link?: string | null } | null, googleDoc2Pov?: { id: string, link?: string | null } | null, googleDocABTest1?: { id: string, link?: string | null } | null, googleDocABTest2?: { id: string, link?: string | null } | null, googleDocABTest3?: { id: string, link?: string | null } | null, googleDocWriter?: { id: string, link?: string | null } | null, googleDocNarrative?: { id: string, link?: string | null } | null, googleDocES?: { id: string, link?: string | null } | null, googleDocFR?: { id: string, link?: string | null } | null, googleDocDE?: { id: string, link?: string | null } | null } };


export const UpdateChapterDocDocument = gql`
    mutation UpdateChapterDoc($chapter: UpdateChapterRequestDto!) {
  updateChapterDoc(chapter: $chapter) {
    id
  }
}
    `;
export type UpdateChapterDocMutationFn = Apollo.MutationFunction<UpdateChapterDocMutation, UpdateChapterDocMutationVariables>;

/**
 * __useUpdateChapterDocMutation__
 *
 * To run a mutation, you first call `useUpdateChapterDocMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChapterDocMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChapterDocMutation, { data, loading, error }] = useUpdateChapterDocMutation({
 *   variables: {
 *      chapter: // value for 'chapter'
 *   },
 * });
 */
export function useUpdateChapterDocMutation(baseOptions?: Apollo.MutationHookOptions<UpdateChapterDocMutation, UpdateChapterDocMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateChapterDocMutation, UpdateChapterDocMutationVariables>(UpdateChapterDocDocument, options);
      }
export type UpdateChapterDocMutationHookResult = ReturnType<typeof useUpdateChapterDocMutation>;
export type UpdateChapterDocMutationResult = Apollo.MutationResult<UpdateChapterDocMutation>;
export type UpdateChapterDocMutationOptions = Apollo.BaseMutationOptions<UpdateChapterDocMutation, UpdateChapterDocMutationVariables>;
export const UpdateChapterDocument = gql`
    mutation UpdateChapter($chapter: UpdateChapterRequestDto!) {
  updateChapter(chapter: $chapter) {
    id
  }
}
    `;
export type UpdateChapterMutationFn = Apollo.MutationFunction<UpdateChapterMutation, UpdateChapterMutationVariables>;

/**
 * __useUpdateChapterMutation__
 *
 * To run a mutation, you first call `useUpdateChapterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChapterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChapterMutation, { data, loading, error }] = useUpdateChapterMutation({
 *   variables: {
 *      chapter: // value for 'chapter'
 *   },
 * });
 */
export function useUpdateChapterMutation(baseOptions?: Apollo.MutationHookOptions<UpdateChapterMutation, UpdateChapterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateChapterMutation, UpdateChapterMutationVariables>(UpdateChapterDocument, options);
      }
export type UpdateChapterMutationHookResult = ReturnType<typeof useUpdateChapterMutation>;
export type UpdateChapterMutationResult = Apollo.MutationResult<UpdateChapterMutation>;
export type UpdateChapterMutationOptions = Apollo.BaseMutationOptions<UpdateChapterMutation, UpdateChapterMutationVariables>;
export const GenerateChapterDocDocument = gql`
    mutation GenerateChapterDoc($bookId: String!, $chapterId: String!, $config: CreateChapterDocByConfigRequestDto!) {
  createChapterDocByConfig(
    bookId: $bookId
    chapterId: $chapterId
    chapterDocConfig: $config
  ) {
    id
  }
}
    `;
export type GenerateChapterDocMutationFn = Apollo.MutationFunction<GenerateChapterDocMutation, GenerateChapterDocMutationVariables>;

/**
 * __useGenerateChapterDocMutation__
 *
 * To run a mutation, you first call `useGenerateChapterDocMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateChapterDocMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateChapterDocMutation, { data, loading, error }] = useGenerateChapterDocMutation({
 *   variables: {
 *      bookId: // value for 'bookId'
 *      chapterId: // value for 'chapterId'
 *      config: // value for 'config'
 *   },
 * });
 */
export function useGenerateChapterDocMutation(baseOptions?: Apollo.MutationHookOptions<GenerateChapterDocMutation, GenerateChapterDocMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateChapterDocMutation, GenerateChapterDocMutationVariables>(GenerateChapterDocDocument, options);
      }
export type GenerateChapterDocMutationHookResult = ReturnType<typeof useGenerateChapterDocMutation>;
export type GenerateChapterDocMutationResult = Apollo.MutationResult<GenerateChapterDocMutation>;
export type GenerateChapterDocMutationOptions = Apollo.BaseMutationOptions<GenerateChapterDocMutation, GenerateChapterDocMutationVariables>;
export const RemoveAdditionalDocDocument = gql`
    mutation RemoveAdditionalDoc($documentId: String!) {
  removeAdditionalDoc(documentId: $documentId)
}
    `;
export type RemoveAdditionalDocMutationFn = Apollo.MutationFunction<RemoveAdditionalDocMutation, RemoveAdditionalDocMutationVariables>;

/**
 * __useRemoveAdditionalDocMutation__
 *
 * To run a mutation, you first call `useRemoveAdditionalDocMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAdditionalDocMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAdditionalDocMutation, { data, loading, error }] = useRemoveAdditionalDocMutation({
 *   variables: {
 *      documentId: // value for 'documentId'
 *   },
 * });
 */
export function useRemoveAdditionalDocMutation(baseOptions?: Apollo.MutationHookOptions<RemoveAdditionalDocMutation, RemoveAdditionalDocMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveAdditionalDocMutation, RemoveAdditionalDocMutationVariables>(RemoveAdditionalDocDocument, options);
      }
export type RemoveAdditionalDocMutationHookResult = ReturnType<typeof useRemoveAdditionalDocMutation>;
export type RemoveAdditionalDocMutationResult = Apollo.MutationResult<RemoveAdditionalDocMutation>;
export type RemoveAdditionalDocMutationOptions = Apollo.BaseMutationOptions<RemoveAdditionalDocMutation, RemoveAdditionalDocMutationVariables>;
export const GetChapterByIdDocument = gql`
    query GetChapterById($id: String!) {
  chapter(id: $id) {
    chapterOrder
  }
}
    `;

/**
 * __useGetChapterByIdQuery__
 *
 * To run a query within a React component, call `useGetChapterByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChapterByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChapterByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetChapterByIdQuery(baseOptions: Apollo.QueryHookOptions<GetChapterByIdQuery, GetChapterByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetChapterByIdQuery, GetChapterByIdQueryVariables>(GetChapterByIdDocument, options);
      }
export function useGetChapterByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetChapterByIdQuery, GetChapterByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetChapterByIdQuery, GetChapterByIdQueryVariables>(GetChapterByIdDocument, options);
        }
export type GetChapterByIdQueryHookResult = ReturnType<typeof useGetChapterByIdQuery>;
export type GetChapterByIdLazyQueryHookResult = ReturnType<typeof useGetChapterByIdLazyQuery>;
export type GetChapterByIdQueryResult = Apollo.QueryResult<GetChapterByIdQuery, GetChapterByIdQueryVariables>;
export const GetChapterDocsDocument = gql`
    query GetChapterDocs($id: String!) {
  chapter(id: $id) {
    googleDoc {
      id
      link
    }
    googleDoc2Pov {
      id
      link
    }
    googleDocABTest1 {
      id
      link
    }
    googleDocABTest2 {
      id
      link
    }
    googleDocABTest3 {
      id
      link
    }
    googleDocWriter {
      id
      link
    }
    googleDocNarrative {
      id
      link
    }
    googleDocES {
      id
      link
    }
    googleDocFR {
      id
      link
    }
    googleDocDE {
      id
      link
    }
  }
}
    `;

/**
 * __useGetChapterDocsQuery__
 *
 * To run a query within a React component, call `useGetChapterDocsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChapterDocsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChapterDocsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetChapterDocsQuery(baseOptions: Apollo.QueryHookOptions<GetChapterDocsQuery, GetChapterDocsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetChapterDocsQuery, GetChapterDocsQueryVariables>(GetChapterDocsDocument, options);
      }
export function useGetChapterDocsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetChapterDocsQuery, GetChapterDocsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetChapterDocsQuery, GetChapterDocsQueryVariables>(GetChapterDocsDocument, options);
        }
export type GetChapterDocsQueryHookResult = ReturnType<typeof useGetChapterDocsQuery>;
export type GetChapterDocsLazyQueryHookResult = ReturnType<typeof useGetChapterDocsLazyQuery>;
export type GetChapterDocsQueryResult = Apollo.QueryResult<GetChapterDocsQuery, GetChapterDocsQueryVariables>;