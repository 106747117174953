import * as yup from 'yup';

import { spaceValidation } from '../yup-validation';

export const updateItemSchema = yup.object({
	id: yup.string().required(),
	link: spaceValidation.url().nullable(),
	filename: spaceValidation.min(5).max(255).nullable(),
	dropboxLink: spaceValidation,
	price: yup.number().integer(),
	keywords: yup.array().of(yup.string().trim().required()),
	newKeywords: yup
		.object()
		.shape({
			app: yup.array().nullable(),
			art_style: yup.array().nullable(),
			item: yup.array().nullable(),
		})
		.nullable(),
});

export type UpdateItemValues = yup.InferType<typeof updateItemSchema>;
