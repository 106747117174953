import React from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Flex, Link, Text } from '@chakra-ui/react';

export type Breadcrumb = {
	name: string | null;
	to: string;
};

interface Props {
	breadcrumbs: Breadcrumb[];
}

// TODO 3 breadcrumbs === proval
// TODO add check for current breadcrumb

export const Breadcrumbs: React.FC<Props> = ({ breadcrumbs }) => {
	return (
		<Flex alignItems="center" pb="3px">
			{breadcrumbs.map(({ name, to }, index) => (
				<React.Fragment key={to}>
					{index ? (
						<Text
							as="span"
							fontSize="11px"
							lineHeight="140%"
							fontWeight="500"
							px="8px"
							color="#808192"
						>
							/
						</Text>
					) : (
						''
					)}
					<Button
						textDecoration="none"
						size="sm"
						variant="secondary"
						color={index === breadcrumbs.length - 1 ? '#484964' : '#bcbcc6'}
					>
						<Link
							as={NavLink}
							_focus={{ outline: 'none' }}
							_hover={{ textDecoration: 'none' }}
							to={to}
						>
							{name || 'Loading'}
						</Link>
					</Button>
				</React.Fragment>
			))}
		</Flex>
	);
};
