import * as Types from '../../../models.gen';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RevokeTokenQueryVariables = Types.Exact<{
  access: Types.Scalars['String'];
  refresh: Types.Scalars['String'];
}>;


export type RevokeTokenQuery = { revokeToken: boolean };


export const RevokeTokenDocument = gql`
    query RevokeToken($access: String!, $refresh: String!) {
  revokeToken(access: $access, refresh: $refresh)
}
    `;

/**
 * __useRevokeTokenQuery__
 *
 * To run a query within a React component, call `useRevokeTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useRevokeTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRevokeTokenQuery({
 *   variables: {
 *      access: // value for 'access'
 *      refresh: // value for 'refresh'
 *   },
 * });
 */
export function useRevokeTokenQuery(baseOptions: Apollo.QueryHookOptions<RevokeTokenQuery, RevokeTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RevokeTokenQuery, RevokeTokenQueryVariables>(RevokeTokenDocument, options);
      }
export function useRevokeTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RevokeTokenQuery, RevokeTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RevokeTokenQuery, RevokeTokenQueryVariables>(RevokeTokenDocument, options);
        }
export type RevokeTokenQueryHookResult = ReturnType<typeof useRevokeTokenQuery>;
export type RevokeTokenLazyQueryHookResult = ReturnType<typeof useRevokeTokenLazyQuery>;
export type RevokeTokenQueryResult = Apollo.QueryResult<RevokeTokenQuery, RevokeTokenQueryVariables>;