import React from 'react';
import { Flex, Text } from '@chakra-ui/react';

import { WarningIcon } from '../../icons';

interface ErrorTextProps {
	message: string;
}

export const ErrorText: React.FC<ErrorTextProps> = ({ message }) => {
	return (
		<Flex
			my={2}
			border="1px solid #ED0000"
			borderRadius="10px"
			p="7px 10px"
			align="center"
			background="#ED00001A"
		>
			<WarningIcon w={6} h={6} color=" #ED0000" />
			<Text ml="5px">{message}</Text>
		</Flex>
	);
};
