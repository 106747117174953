import React, { useCallback, useState } from 'react';

import { GetBookDocument, GetBooksDocument } from 'features/book/queries.gen';
import { useUpdateChapterMutation } from 'features/chapter/update/queries.gen';
import { updateLocalizationNameSchema } from 'features/chapter/update/update-localization-name/schema';
import { ButtonType, trackPropertyChanged } from 'shared/analytics';
import { useAppToast } from 'shared/hooks/toast';
import { FormInputField, FormWithChildren } from 'shared/ui';

export interface Props {
	id: string;
	name?: string | null;
	chapterName: string;
}

export const UpdateLocalizationNameForm: React.FC<Props> = ({
	id,
	name,
	chapterName,
}) => {
	const toast = useAppToast();

	const [isRedacting, setIsRedacting] = useState(false);

	const onStartRedacting = useCallback(() => setIsRedacting(true), []);
	const onStopRedacting = useCallback(() => setIsRedacting(false), []);

	const [updateChapter] = useUpdateChapterMutation({
		onCompleted: () => {
			onStopRedacting();
			toast({
				title: `Chapter name updated`,
				status: 'success',
			});
		},
		onError: () =>
			toast({
				title: 'Chapter name was not updated',
				status: 'error',
			}),
		refetchQueries: [GetBookDocument, GetBooksDocument],
	});

	const updateLocalizationName = useCallback(
		async (formValue) => {
			updateChapter({
				variables: {
					chapter: {
						id,
						[chapterName]: formValue,
					},
				},
			});
			trackPropertyChanged({
				entity: 'chapter',
				property: chapterName as string,
				value: formValue,
				id,
			});
		},
		[chapterName, id, updateChapter],
	);

	return (
		<FormWithChildren
			submitButtonName={ButtonType.EDIT_LOCALIZED_CHAPTER_NAME}
			initialValue={name}
			isRedacting={isRedacting}
			startRedacting={onStartRedacting}
			stopRedacting={onStopRedacting}
			validationSchema={updateLocalizationNameSchema}
			onSubmit={({ formValue }) => updateLocalizationName(formValue)}
		>
			<FormInputField
				label="Name"
				name="formValue"
				placeholder="Name"
				autocomplete="off"
			/>
		</FormWithChildren>
	);
};
