import React from 'react';
import { createIcon } from '@chakra-ui/react';

export const ArrowLeftIcon = createIcon({
	viewBox: '0 0 14 14',
	path: (
		<>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M13.5 6.74958C13.5 7.23583 13.1058 7.63001 12.6196 7.63001L0.880435 7.63001C0.394184 7.63001 -5.97397e-08 7.23583 -3.8485e-08 6.74957C-1.72303e-08 6.26332 0.394184 5.86914 0.880435 5.86914L12.6196 5.86914C13.1058 5.86914 13.5 6.26332 13.5 6.74958Z"
				fill="currentColor"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M7.37299 0.257873C7.71682 0.601705 7.71682 1.15917 7.37299 1.503L2.12598 6.75L7.37299 11.997C7.71682 12.3408 7.71682 12.8983 7.37299 13.2421C7.02915 13.586 6.47169 13.586 6.12786 13.2421L0.258297 7.37256C-0.0855344 7.02873 -0.0855344 6.47127 0.258297 6.12744L6.12786 0.257873C6.47169 -0.0859578 7.02915 -0.0859578 7.37299 0.257873Z"
				fill="currentColor"
			/>
		</>
	),
});
