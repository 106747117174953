/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useRef } from 'react';
import { Box, Flex, Portal, Text, useDisclosure } from '@chakra-ui/react';

import { ArrowRightSmallIcon } from 'shared/icons';
import { SNIPPETS_FULL } from 'widgets/editor/constants/snippets';

import { AdditionalSuggestions } from '../additionalSuggestions';

interface Props {
	suggestion: string;
	index: number;
	mapIndex: number;
	containerRef: any;
	onClick: (v: number, t?: number, variants?: string[]) => void;
}

export const Suggestion: React.FC<Props> = ({
	suggestion,
	index,
	mapIndex,
	containerRef,
	onClick,
}) => {
	const { isOpen, onOpen, onClose } = useDisclosure();

	const snippetRef = useRef<HTMLDivElement | null>(null);
	const additionalSnippetRef = useRef<HTMLDivElement | null>(null);

	const isAdditionalSnippet = typeof SNIPPETS_FULL[suggestion] === 'object';
	const isAdditionalSnippetRef = suggestion === '#ref';

	const additionalSnippetHeight = useMemo(
		() => additionalSnippetRef.current?.getBoundingClientRect().height || 0,
		[additionalSnippetRef.current],
	);
	const topPosition =
		window.innerHeight -
			(snippetRef.current?.parentElement?.getBoundingClientRect().top || 0) >
		210
			? snippetRef.current?.parentElement?.style.top
			: `${window.innerHeight - 180}px`;

	const topRefPosition =
		window.innerHeight -
			(snippetRef.current?.parentElement?.getBoundingClientRect().top || 0) >
		(additionalSnippetHeight || 180)
			? snippetRef.current?.parentElement?.style.top
			: `none`;

	const bottomPosition = `${
		window.innerHeight -
		(snippetRef.current?.parentElement?.getBoundingClientRect().top || 0) -
		(snippetRef.current?.parentElement?.getBoundingClientRect().height || 0)
	}px`;

	const refIndex = Object.keys(SNIPPETS_FULL).indexOf('#ref');

	useEffect(() => {
		if (snippetRef.current && index === mapIndex) {
			snippetRef.current.scrollIntoView();
		}
	}, [snippetRef, index, mapIndex]);

	return (
		<Flex
			ref={snippetRef}
			cursor="pointer"
			p="4.5px 8px"
			position="relative"
			borderRadius="8px"
			alignItems="center"
			justifyContent="space-between"
			onMouseDown={(e) => e.preventDefault()}
			onKeyDown={(e) => e.preventDefault()}
			onClick={() =>
				!isAdditionalSnippet && !isAdditionalSnippetRef && onClick(mapIndex)
			}
			onMouseEnter={onOpen}
			onMouseLeave={onClose}
			_hover={{ background: '#f4f4f8' }}
		>
			<Text fontWeight={500} fontSize="13px">
				{suggestion.replace('=', '')}
			</Text>
			{(isAdditionalSnippet || isAdditionalSnippetRef) && (
				<ArrowRightSmallIcon w={6} h={6} />
			)}
			{isAdditionalSnippet && isOpen && (
				<Portal>
					<Box
						ref={additionalSnippetRef}
						onMouseEnter={onOpen}
						position="absolute"
						top={topPosition}
						left={
							(snippetRef.current?.parentElement?.getBoundingClientRect()
								.left || 0) + 180
						}
						zIndex={999999999}
						p="7px 3px 9px 6px"
						background="white"
						borderRadius="8px"
						boxShadow="0 1px 5px rgba(0, 0, 0, 0.2)"
						w="full"
						maxW="190px"
						maxHeight="218px"
						overflowY="auto"
					>
						{[...(SNIPPETS_FULL[suggestion] as string[])].map(
							(subSuggestion, i) => (
								<Suggestion
									key={suggestion}
									mapIndex={i}
									suggestion={
										subSuggestion.split('-')[1] || subSuggestion.split('=')[1]
									}
									index={index}
									onClick={() => onClick(mapIndex, i)}
									containerRef={containerRef}
								/>
							),
						)}
					</Box>
				</Portal>
			)}
			{isAdditionalSnippetRef && isOpen && (
				<AdditionalSuggestions
					topPosition={topRefPosition as string}
					bottomPosition={bottomPosition}
					additionalSnippetRef={additionalSnippetRef}
					snippetRef={snippetRef}
					onClick={(variants: string[] | undefined) =>
						onClick(mapIndex, refIndex, variants)
					}
				/>
			)}
		</Flex>
	);
};
