import { StoriesQuery, useChaptersQuery, useStoriesQuery } from './queries.gen';

export type Chapter = {
	id: string;
	name: string;
	chapterOrder: number;
	bookName?: string;
};

export const useStories = (name: string) => {
	const {
		data,
		loading: loadingStories,
		error,
	} = useStoriesQuery({
		variables: {
			page: 0,
			limit: 300,
			filter: { name: name || undefined },
		},
	});
	const stories: StoriesQuery['books'] = data?.books || [];

	return { stories, loadingStories, error };
};

export const useSortedByChapterOrderChapters = (bookId: string) => {
	const { data, loading, error } = useChaptersQuery({
		variables: {
			id: bookId,
		},
	});

	const chapters: Chapter[] =
		[...(data?.book?.chapters || [])]
			?.sort((a, b): number => {
				return (a?.chapterOrder || 0) - (b?.chapterOrder || 0);
			})
			.map((chapter) => {
				return {
					id: chapter?.id || '',
					name: chapter?.name || '',
					chapterOrder: chapter?.chapterOrder || 0,
					bookName: data?.book?.name || '',
				};
			}) || [];

	return { chapters, loading, error };
};
