export type BodyKeywords = {
	sex: string[];
	// app?: string[];
	art_style: string[];
	// body_type?: string[];
	skintone: string[];
	age: string[];
	extra: string[];
	not_human: string[];
};

export type HairsKeywords = {
	sex: string[];
	// app?: string[];
	art_style: string[];
	// body_type?: string[];
	color: string[];
	structure: string[];
	length: string[];
	age: string[];
	style: string[];
	hairstyle: string[];
	extra: string[];
};

export type ClothesKeywords = {
	sex: string[];
	// app?: string[];
	art_style: string[];
	// body_type?: string[];
	age: string[];
	style: string[];
	color_range: string[];
	material: string[];
	nudity: string[];
	top: string[];
	bottom: string[];
	adjective: string[];
	extra: string[];
};

export type LayersKeywords = {
	sex: string[];
	// app?: string[];
	art_style: string[];
	// body_type?: string[];
	owner: string[];
	defects: string[];
	accessories: string[];
	items: string[];
	for_body: string[];
};

export type BackgroundsKeywords = {
	// app?: string[];
	// art_style?: string[];
	outside?: string[];
	inside?: string[];
	time?: string[];
	rooms?: string[];
	style?: string[];
	mood?: string[];
	extra?: string[];
};

export type ItemsKeywords = {
	// app: string[];
	// art_style: string[];
	item: string[];
};

export type FilterKeywords =
	| BodyKeywords
	| ItemsKeywords
	| BackgroundsKeywords
	| LayersKeywords
	| ClothesKeywords
	| HairsKeywords;

export type KeywordsType = {
	body: BodyKeywords;
	hair: HairsKeywords;
	clothes: ClothesKeywords;
	layer: LayersKeywords;
	background: BackgroundsKeywords;
	item: ItemsKeywords;
};

export const KEYWORDS_MAP: KeywordsType = {
	body: {
		sex: ['male', 'female', 'nonbinary'],
		// app: ['My Fantasy', 'Blushed'],
		art_style: ['old', 'new', 'stable'],
		// body_type: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
		skintone: ['white', 'asian', 'latino', 'black'],
		age: ['baby', 'kid', 'teen', 'adult', 'old'],
		extra: ['tattoo', 'mustache', 'beard', 'vitiligo', 'glasses'],
		not_human: ['creature', 'wild animal', 'pet', 'domestic animal'],
	},
	hair: {
		sex: ['male', 'female', 'nonbinary'],
		// app: ['My Fantasy', 'Blushed'],
		art_style: ['old', 'new', 'stable'],
		// body_type: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
		color: ['blond', 'black', 'brown', 'ginger', 'colored', 'gray', 'ombre'],
		structure: ['straight', 'wavy', 'curly', 'afro'],
		length: ['bold', 'short', 'medium', 'long'],
		age: ['old', 'adult', 'teen', 'kid'],
		style: ['casual', 'free', 'fancy', 'paid'],
		hairstyle: ['ponytails', 'braid', 'bun', 'dreadlocks', 'hollywood'],
		extra: ['crown', 'hat', 'helmet', 'fantasy', 'glasses'],
	},
	clothes: {
		sex: ['male', 'female', 'nonbinary'],
		// app: ['My Fantasy', 'Blushed'],
		art_style: ['old', 'new', 'stable'],
		// body_type: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
		age: ['kid', 'teen', 'adult', 'old'],
		style: [
			'casual',
			'business',
			'eveningwear',
			'pyjama',
			'bdsm',
			'sport',
			'swimsuit',
			'historical',
			'underwear',
			'roleplay',
			'wedding',
			'punk',
			'boho',
			'uniform',
			'bathrobe',
			'towel',
			'fantasy',
		],
		color_range: [
			'colorfull',
			'red',
			'black',
			'white',
			'gray',
			'brown',
			'green',
			'blue',
			'pink',
			'yellow',
			'violet',
			'orange',
			'gold',
			'beige',
			'silver',
		],
		material: ['denim', 'leather', 'lace', 'latex', 'silk'],
		nudity: ['naked', 'without top', 'without bottom'],
		top: [
			'corset',
			'jacket',
			't-shirt',
			'shirt',
			'sleeveless',
			'sweater',
			'hoodie',
			'blouse',
			'short dress',
			'long dress',
			'coat',
			'bra',
			'robe',
			'longsleeve',
			'crop top',
			'jumper',
			'suite',
			'tuxedo',
			'vest',
			'top',
		],
		bottom: [
			'short skirt',
			'long skirt',
			'shorts',
			'jeans',
			'pants',
			'stockings',
			'panties',
			'boxers',
		],
		adjective: ['regular', 'sexy', 'fancy', 'free', 'paid'],
		extra: [
			'wings',
			'shibari',
			'purse',
			'chains',
			'stains',
			'torn',
			'wound',
			'pregnant',
			'scarf',
			'wet',
			'belly',
			'tie',
			'bow tie',
			'accessories',
			'apron',
			'animal',
			'shoes',
			'belt',
		],
	},
	layer: {
		sex: ['male', 'female', 'nonbinary'],
		// app: ['My Fantasy', 'Blushed'],
		art_style: ['old', 'new', 'stable'],
		// body_type: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
		owner: ['human', 'animal'],
		defects: ['stain', 'dust', 'mud', 'wound'],
		accessories: [
			'clothes',
			'jewelry',
			'device',
			'body-modification',
			'glasses',
		],
		items: [
			'bdsm item',
			'guitar',
			'weapon',
			'bouquet',
			'motorcycle',
			'tools',
			'cigarette',
		],
		for_body: ['censore', 'fantasy', 'bandage', 'shibari'],
	},
	background: {
		// app: ['My Fantasy', 'Blushed'],
		// art_style: ['old', 'new', 'stable'],
		outside: [
			'porch',
			'yard',
			'city',
			'park',
			'forest',
			'beach',
			'street',
			'road',
			'mountain',
			'lake',
			'ocean',
			'amusement park',
			'entrance',
			'pool',
			'jacuzzi',
			'fantasy',
			'sport',
			'wedding',
			'parking',
		],
		inside: [
			'house',
			'shop',
			'cafe',
			'restaurant',
			'yacht',
			'car',
			'office',
			'airplane',
			'bar',
			'club',
			'pool',
			'warehouse',
			'dungeon',
			'hotel',
			'jacuzzi',
			'gym',
			'airport',
			'art facility',
			'balcony',
			// 'terrace',
			'barn',
			'hospital',
			'studio',
			'university',
			'banquet',
			'wedding',
			'palace',
			'reception',
		],
		time: ['day', 'night', 'winter', 'summer', 'autumn', 'sunset'],
		rooms: [
			'bedroom',
			'bathroom',
			'kitchen',
			'living room',
			'public restroom',
			'hallway',
			'stairs',
			'elevator',
			'dressing room',
			'private office',
			'classroom',
			'reception',
			'changing room',
		],
		style: [
			'modern',
			'historical',
			'scandinavian',
			'loft',
			'rustic',
			'traditional',
			'art-deco',
		],
		mood: ['poor', 'luxury', 'romantic', 'scary', 'dreamy'],
		extra: ['split', 'rain', 'smoke', 'snow', 'bdsm'],
	},
	item: {
		// app: ['My Fantasy', 'Blushed'],
		// art_style: ['old', 'new', 'stable'],
		item: [
			'food',
			'drink',
			'bdsm',
			'fantasy',
			'sex toys',
			'jewelry',
			'clothes',
			'toy',
			'furniture',
			'animal',
			'device',
			'crockery',
			'cosmetics',
			'plants',
			'medicine',
			'paper',
			'picture',
			'vehicle',
			'weapons',
			'gift',
			'ring',
			'tools',
		],
	},
};

export const KEYWORDS_KEY_MAP = {
	sex: 'Sex',
	// app: 'App',
	art_style: 'Art Style',
	// body_type: 'Body Type',
	skintone: 'Skintone',
	age: 'Age',
	extra: 'Extra',
	not_human: 'Not Human',
	color: 'Color',
	structure: 'Structure',
	length: 'Length',
	hairstyle: 'Hairstyle',
	style: 'Style',
	color_range: 'Color Range',
	material: 'Material',
	nudity: 'Nudity',
	top: 'Top',
	bottom: 'Bottom',
	adjective: 'Adjective',
	owner: 'Owner',
	defects: 'Defects',
	accessories: 'Accessories',
	items: 'Items',
	for_body: 'For body',
	outside: 'Outside',
	inside: 'Inside',
	time: 'Time',
	rooms: 'Rooms',
	mood: 'Mood',
	item: 'Item',
};
