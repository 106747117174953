import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { NetworkStatus } from '@apollo/client';
import { Box, Flex, Spinner, useDisclosure } from '@chakra-ui/react';

import { ASSETS_PER_PAGE } from 'entities/assets/model';
import { AssetCard } from 'entities/assets/ui/asset-card';
import { filterKeywordsTypes } from 'pages/art-catalog';
import { trackAssetDetailsModalClose } from 'shared/analytics/modals/close';
import { trackAssetDetailsModalOpen } from 'shared/analytics/modals/open';
import { Modal, MODAL_ASSET_DETAILS } from 'shared/analytics/modals/types';
import useInfiniteScroll from 'shared/hooks/infinite-scroll';
import NotFound from 'shared/ui/not-found';
import { KeywordsFilter } from 'widgets/keywords-filter-modal';
import { FilterKeywords } from 'widgets/keywords-filter-modal/constant';
import { UpdateAssetModal } from 'widgets/update-asset-modal';
import { AssetsSortArgDto } from 'models.gen';

import ArtCatalogSort from '../ArtCatalogSort';
import ArtCatalogBookFilter from '../ArtCatalogStoriesFilter';
import { useGetAssetsQuery } from './queries.gen';
import styles from './styles.module.scss';

interface Props {
	selectedCategory?: string;
	search: string;
	page: number;
	setPage: (v: number) => void;
	filterKeywords: FilterKeywords | null;
	setFilterKeywords: (v: FilterKeywords | null) => void;
	selectedBook?: string;
	setSelectedBook: (v: string) => void;
	type?: string;
	setFilterKeywordsType?: (v: filterKeywordsTypes) => void;
	filterKeywordsType?: filterKeywordsTypes;
}

const SORT_MAP: Record<string, AssetsSortArgDto> = {
	'updatedAt-desc': {
		field: 'updatedAt',
		order: 'desc',
	},
	'name-asc': {
		field: 'name',
		order: 'asc',
	},
	'name-desc': {
		field: 'name',
		order: 'desc',
	},
	'used-asc': {
		field: 'used',
		order: 'asc',
	},
	'used-desc': {
		field: 'used',
		order: 'desc',
	},
};

export const FILTER_TYPES: filterKeywordsTypes[] = [
	'has all of',
	'has any of',
	'has none of',
];
export const ArtCatalogTable: React.FC<Props> = ({
	selectedCategory,
	search,
	page,
	setPage,
	filterKeywords,
	setFilterKeywords,
	selectedBook,
	setSelectedBook,
	type,
	setFilterKeywordsType,
	filterKeywordsType,
}) => {
	const [sort, setSort] = useState('updatedAt-desc');
	const [selectedAsset, setSelectedAsset] = useState<any>(null);
	const tableRef = useRef<HTMLDivElement>(null);
	const observeRef = useRef(null);
	const abortControllerRef = useRef<AbortController | null>(null);

	const { isOpen, onClose, onOpen } = useDisclosure();

	const location = useLocation();
	const navigate = useNavigate();

	// for 'Open in Art Catalog' feature (Additional Clothes)
	const assetFromLocation = useMemo(() => {
		const params = new URLSearchParams(location.search);
		return {
			id: params.get('id'),
			name: params.get('name'),
			link: params.get('link'),
			type: params.get('type'),
		};
	}, [location.search]);

	useEffect(() => {
		if (assetFromLocation?.id) {
			setSelectedAsset(assetFromLocation);
			navigate(location.pathname, {});
		}
	}, [assetFromLocation, location.pathname, navigate]);

	const {
		data: assetsData,
		loading,
		refetch,
		fetchMore,
		networkStatus,
	} = useGetAssetsQuery({
		variables: {
			page: 0,
			limit: ASSETS_PER_PAGE,
			filter: {
				type,
				name: search || undefined,
				bookId: selectedBook,
				filterKeywordsType,
				keywords: filterKeywords,
			},
			sort: SORT_MAP[sort],
		},
		fetchPolicy: 'network-only',
		nextFetchPolicy: 'network-only',
		notifyOnNetworkStatusChange: true,
	});
	const trackModalAssetOpen = useCallback(
		(assetType = '') => {
			const modalType = MODAL_ASSET_DETAILS[
				(assetType || type) as keyof typeof MODAL_ASSET_DETAILS
			] as Modal;
			if (modalType) {
				trackAssetDetailsModalOpen(modalType);
			}
		},
		[type],
	);
	const trackModalAssetClose = useCallback(
		(assetType = '') => {
			const modalType = MODAL_ASSET_DETAILS[
				(assetType || type) as keyof typeof MODAL_ASSET_DETAILS
			] as Modal;
			if (modalType) {
				trackAssetDetailsModalClose(modalType);
			}
		},
		[type],
	);
	const handleLoadMore = async (entries: { isIntersecting: boolean }[]) => {
		const target = entries[0];

		if (
			target.isIntersecting &&
			!loading &&
			!!assetsData?.assets.assets.length
		) {
			if (abortControllerRef.current) {
				abortControllerRef.current.abort();
			}
			abortControllerRef.current = new AbortController();

			await fetchMore({
				variables: {
					page: page + 1,
				},
				context: {
					fetchOptions: {
						signal: abortControllerRef.current.signal,
					},
				},
				updateQuery: (previousQueryResult, { fetchMoreResult }) => {
					if (!fetchMoreResult || fetchMoreResult.assets.assets.length === 0) {
						return previousQueryResult;
					}
					return {
						...previousQueryResult,
						assets: {
							...previousQueryResult?.assets,
							assets: [
								...(previousQueryResult?.assets?.assets || []),
								...fetchMoreResult.assets.assets,
							],
						},
					};
				},
			});

			setPage(page + 1);
		}
	};

	useInfiniteScroll(observeRef, handleLoadMore);

	useEffect(() => {
		if (networkStatus === NetworkStatus.refetch) {
			setPage(0);
			tableRef.current?.scrollTo(0, 0);
		}
	});
	useEffect(() => {
		if (abortControllerRef.current) {
			abortControllerRef.current.abort();
		}
		setSort('updatedAt-desc');
	}, [type]);

	useEffect(() => {
		setPage(0);
	}, [setPage, sort]);

	return (
		<div className={styles.table}>
			<div className={styles.tooltip}>
				<div className={styles.filters}>
					<ArtCatalogBookFilter
						selectedBook={selectedBook}
						setFilterBook={setSelectedBook}
					/>
				</div>
				<Flex alignItems="center" gap="10px">
					{selectedCategory && (
						<KeywordsFilter
							type={selectedCategory}
							filterKeywords={filterKeywords}
							setFilterKeywords={setFilterKeywords}
							setFilterKeywordsType={setFilterKeywordsType}
							filterKeywordsType={filterKeywordsType}
							isOpen={isOpen}
							onClose={onClose}
							onOpen={onOpen}
							isFilter
						/>
					)}
					<ArtCatalogSort sort={sort} setSort={setSort} />
				</Flex>
			</div>
			{loading && !assetsData?.assets.assets.length && (
				<Spinner size="xl" m="auto" />
			)}
			{!assetsData?.assets.assets.length && !loading && <NotFound />}
			{!!assetsData?.assets.assets.length && (
				<>
					<div className={styles.assets} ref={tableRef}>
						{assetsData?.assets.assets.map((asset) => (
							<AssetCard
								key={asset.id}
								asset={asset}
								onClick={(v) => {
									setSelectedAsset(v);
									trackModalAssetOpen(asset.type);
								}}
								selectedAsset={selectedAsset}
								setSelectedAsset={setSelectedAsset}
							/>
						))}
						<Box minH={4} ref={observeRef} />
					</div>
					{loading && (
						<Box display="flex" justifyContent="center" minH={5} mt="10px">
							<Spinner size="md" />
						</Box>
					)}
				</>
			)}
			{selectedAsset ? (
				<UpdateAssetModal
					isOpen={!!selectedAsset}
					onClose={() => {
						setSelectedAsset(null);
						trackModalAssetClose(selectedAsset.type);
					}}
					asset={selectedAsset}
					afterDelete={refetch}
				/>
			) : null}
		</div>
	);
};

export default ArtCatalogTable;
