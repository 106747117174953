import React, { useCallback, useState } from 'react';

import { GetBookDocument, GetBookQuery } from 'features/book/queries.gen';
import { updateAuthorSchema } from 'features/book/update/update-properties/ui/author';
import { ChapterByIdDocument } from 'features/story-editor/header/queries.gen';
import { ButtonType } from 'shared/analytics';
import { useAppToast } from 'shared/hooks/toast';
import { ArrayElement } from 'shared/model';
import { FormInputField, FormWithChildren } from 'shared/ui';

import { useUpdateChapterMutation } from './queries.gen';

export interface Props {
	chapter: ArrayElement<GetBookQuery['book']['chapters']>;
}

export const UpdateChapterAuthorForm: React.FC<Props> = ({ chapter }) => {
	const toast = useAppToast();
	const [isRedacting, setIsRedacting] = useState(false);

	const onStartRedacting = useCallback(() => setIsRedacting(true), []);
	const onStopRedacting = useCallback(() => setIsRedacting(false), []);

	const [updateChapter] = useUpdateChapterMutation({
		onCompleted: () => {
			onStopRedacting();

			toast({
				title: 'Chapter author updated successfully',
				status: 'success',
			});
		},
		onError: () =>
			toast({
				title: 'Chapter author was not updated',
				status: 'error',
			}),
		refetchQueries: [ChapterByIdDocument, GetBookDocument],
	});

	const updateChapterAuthor = useCallback(
		async (formValue) => {
			await updateChapter({
				variables: {
					chapter: {
						id: chapter?.id,
						author: formValue,
					},
				},
			});
		},
		[chapter?.id, updateChapter],
	);

	return (
		<FormWithChildren
			submitButtonName={ButtonType.EDIT_CHAPTER_AUTHOR}
			initialValue={chapter?.author}
			isRedacting={isRedacting}
			startRedacting={onStartRedacting}
			stopRedacting={onStopRedacting}
			validationSchema={updateAuthorSchema}
			onSubmit={({ formValue }) => updateChapterAuthor(formValue)}
		>
			<FormInputField
				label="Chapter author"
				name="formValue"
				autocomplete="off"
				placeholder="Chapter author"
			/>
		</FormWithChildren>
	);
};
