import * as Types from '../../../models.gen';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BookChaptersQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type BookChaptersQuery = { book: { name?: string | null, chapters?: Array<{ id: string, name?: string | null, chapterOrder?: number | null }> | null } };


export const BookChaptersDocument = gql`
    query BookChapters($id: String!) {
  book(id: $id) {
    name
    chapters {
      id
      name
      chapterOrder
    }
  }
}
    `;

/**
 * __useBookChaptersQuery__
 *
 * To run a query within a React component, call `useBookChaptersQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookChaptersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookChaptersQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBookChaptersQuery(baseOptions: Apollo.QueryHookOptions<BookChaptersQuery, BookChaptersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BookChaptersQuery, BookChaptersQueryVariables>(BookChaptersDocument, options);
      }
export function useBookChaptersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BookChaptersQuery, BookChaptersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BookChaptersQuery, BookChaptersQueryVariables>(BookChaptersDocument, options);
        }
export type BookChaptersQueryHookResult = ReturnType<typeof useBookChaptersQuery>;
export type BookChaptersLazyQueryHookResult = ReturnType<typeof useBookChaptersLazyQuery>;
export type BookChaptersQueryResult = Apollo.QueryResult<BookChaptersQuery, BookChaptersQueryVariables>;