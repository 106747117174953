import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import {
	Box,
	Button,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Spinner,
	VStack,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import { debounce } from 'lodash';

import { AssetBook } from 'entities/assets/interfaces/asset';
import useInfiniteScroll from 'shared/hooks/infinite-scroll';
import NotFound from 'shared/ui/not-found';
import { SearchInput } from 'shared/ui/search-input';

import { BookPopover } from './book-popover';
import { useStoryNamesListQuery } from './queries.gen';

interface Chapter {
	id: string;
	name?: string | null | undefined;
	chapterOrder?: number | null | undefined;
}

interface AddStoriesModalFormikParams {
	books: Record<string, AssetBook>;
}

interface AddStoriesModalProps {
	isOpen: boolean;
	onClose: () => void;
	onSubmit: (books: AssetBook[]) => Promise<void>;
	books: Record<string, AssetBook>;
}

export const AddStoriesModal: React.FC<AddStoriesModalProps> = ({
	isOpen,
	onClose,
	onSubmit,
	books,
}) => {
	const [page, setPage] = useState(0);
	const [search, setSearch] = useState('');
	const [searchValue, setSearchValue] = useState('');

	const observeRef = useRef(null);
	const scrollRef = useRef<HTMLDivElement>(null);

	const debouncedSetSearch = useMemo(
		() => debounce((v) => setSearch(v), 1000),
		[setSearch],
	);

	useEffect(() => {
		debouncedSetSearch(searchValue);

		return () => debouncedSetSearch.cancel();
	}, [searchValue, debouncedSetSearch]);

	const { data, loading, fetchMore } = useStoryNamesListQuery({
		variables: {
			limit: 30,
			page: 0,
			filter: {
				name: search || undefined,
			},
		},
		fetchPolicy: 'network-only',
		nextFetchPolicy: 'network-only',
		notifyOnNetworkStatusChange: true,
	});

	const handleModalClose = useCallback(() => {
		onClose();
		setSearch('');
	}, [onClose]);

	const formik = useFormik({
		initialValues: {
			books,
		} as AddStoriesModalFormikParams,
		validateOnMount: true,
		enableReinitialize: true,
		onSubmit: (values) => {
			onSubmit(Object.values(values.books));
			handleModalClose();
		},
	});

	const handleBookAddSubmit = useCallback(
		(book: AssetBook) => async (chapters: Chapter[]) => {
			if (!chapters.length) {
				const copyFormikValues = { ...formik.values.books };
				delete copyFormikValues[book.id];
				await formik.setFieldValue('books', copyFormikValues);
				return;
			}

			await formik.setFieldValue('books', {
				...formik.values.books,
				[book.id]: { ...book, chapters },
			});
		},
		[formik],
	);

	const handleLoadMore = (entries: { isIntersecting: boolean }[]) => {
		if (!data) return;
		const target = entries[0];

		if (data?.books.length < 30) return;
		if (target.isIntersecting && !loading) {
			fetchMore({
				variables: {
					page: page + 1,
				},
				updateQuery: (previousQueryResult, { fetchMoreResult }) => {
					if (!fetchMoreResult || fetchMoreResult.books.length === 0) {
						return previousQueryResult;
					}
					return {
						...previousQueryResult,
						books: [
							...(previousQueryResult?.books || []),
							...fetchMoreResult.books,
						],
					};
				},
			});
			setPage((prev) => prev + 1);
		}
	};
	useInfiniteScroll(observeRef, handleLoadMore);

	return (
		<Modal isOpen={isOpen} onClose={handleModalClose}>
			<ModalOverlay />
			<ModalContent minW="480px" minH="616px" m={0}>
				<ModalHeader mr="30px">
					<ModalCloseButton mt="20px" />
					<SearchInput
						placeholder="Search by name or id"
						inputPadding="30px"
						search={searchValue}
						setSearch={setSearchValue}
					/>
				</ModalHeader>
				<ModalBody h="475px">
					{loading && !data?.books.length ? (
						<Box h="475px">
							<Box w="full" h="full" display="flex">
								<Spinner size="md" m="auto" />
							</Box>
						</Box>
					) : (
						<VStack
							gap="3px"
							h="475px"
							overflow="auto"
							align="stretch"
							ref={scrollRef}
						>
							<>
								{data?.books.length ? (
									data?.books?.map((book) => (
										<BookPopover
											key={book.id}
											bookId={book.id}
											selectedChapters={
												formik.values.books?.[book.id]?.chapters || []
											}
											name={book.name as string}
											handleSubmit={handleBookAddSubmit(book as AssetBook)}
										/>
									))
								) : (
									<NotFound />
								)}
								{!!data?.books.length && <Box minH={4} ref={observeRef} />}
								{loading && (
									<Box display="flex" justifyContent="center" minH={5}>
										<Spinner size="sm" />
									</Box>
								)}
							</>
						</VStack>
					)}
				</ModalBody>
				<ModalFooter>
					<Button
						color="#808192"
						variant="ghost"
						mr={3}
						onClick={handleModalClose}
					>
						Cancel
					</Button>
					<Button
						variant="ghost"
						disabled={!Object.keys(formik.values.books).length}
						onClick={formik.submitForm}
					>
						Apply
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};
