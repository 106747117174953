import React, { useState } from 'react';
import { Formik } from 'formik';

import {
	GetBookInfoDocument,
	useGetBookCustomIdQuery,
} from 'features/book/queries.gen';
import { useAppToast } from 'shared/hooks/toast';
import { CardsByStoryDocument } from 'widgets/cards-list/graphql/queries.gen';
import { SelectedAssetsInfo } from 'widgets/create-card-modal/interfaces';
import {
	useCardQuery,
	useUpdateCardMutation,
} from 'widgets/update-card-modal/graphql/queries.gen';
import { UpdateCardFormikParams } from 'widgets/update-card-modal/interfaces';
import { updateCardSchema } from 'widgets/update-card-modal/schema/card';
import { UpdateCardModal } from 'widgets/update-card-modal/ui';

interface UpdateCardModalContainerProps {
	cardId: string;
	bookId: string;
	isOpen: boolean;
	onClose: () => void;
	setCardId: (v: string) => void;
	setIsCardGenerating: (v: boolean) => void;
	refetch?: () => void;
}

export const UpdateCardModalContainer: React.FC<
	UpdateCardModalContainerProps
> = ({
	cardId,
	bookId,
	setCardId,
	isOpen,
	onClose,
	refetch,
	setIsCardGenerating,
}) => {
	const toast = useAppToast();

	const [selectedAssetsInfo, setSelectedAssetsInfo] =
		useState<SelectedAssetsInfo>({
			selectedBodies: [],
			selectedClothes: [],
			selectedHairs: [],
			selectedLayers: [],
		});

	const {
		data,
		loading: isCardLoading,
		refetch: refetchCard,
	} = useCardQuery({
		variables: { id: cardId },
	});

	const { data: bookData } = useGetBookCustomIdQuery({
		variables: { id: bookId },
	});

	const [updateCard, { loading }] = useUpdateCardMutation({
		refetchQueries: [
			{
				variables: { filter: { bookId }, limit: 100 },
				query: CardsByStoryDocument,
			},
			GetBookInfoDocument,
		],
	});

	return (
		<Formik
			initialValues={
				{
					id: data?.card?.id || '',
					cardsLink: data?.card.cardsLink || [],
					selectedAssets: data?.card.selectedAssets || null,
					filename: data?.card?.filename || '',
					golden: !!data?.card?.golden,
					secondary: !!data?.card?.secondary || false,
					description: data?.card?.description || '',
					chapter: data?.card?.chapter || null,
					background: data?.card?.background || null,
					hero: data?.card?.hero || null,
					heroName: data?.card?.heroName || '',
				} as UpdateCardFormikParams
			}
			onSubmit={async (
				{
					id,
					background,
					hero,
					chapter,
					description,
					golden,
					secondary,
					heroName,
					cardsLink,
				},
				{ resetForm },
			) => {
				try {
					if (background?.id && hero?.id && chapter?.id) {
						const bookCustomId = bookData?.book.customId;
						await updateCard({
							variables: {
								card: {
									id,
									bookId,
									cardsLink,
									description,
									golden,
									secondary,
									heroName,
									selectedAssets: selectedAssetsInfo,
									filename: `${bookCustomId}_${
										chapter.chapterOrder
									}_${heroName}_${golden ? 'golden' : 'regular'}`,
									backgroundId: background.id,
									heroId: hero.id,
									chapterId: chapter.id,
								},
							},
						});
					}

					resetForm();
					onClose();
					toast({
						title: `Cards preset was updated`,
						status: 'success',
					});
					refetchCard();
					setSelectedAssetsInfo({
						selectedBodies: [],
						selectedClothes: [],
						selectedHairs: [],
						selectedLayers: [],
					});
				} catch (errors) {
					toast({
						title: 'Warning',
						description: 'Cards preset was not updated"',
						status: 'error',
					});
				} finally {
					setIsCardGenerating(false);
					if (refetch) setTimeout(() => refetch(), 500);
				}
			}}
			isInitialValid
			validateOnMount
			validationSchema={updateCardSchema}
			enableReinitialize
		>
			<UpdateCardModal
				isOpen={isOpen}
				isLoading={loading}
				isCardLoading={isCardLoading}
				selectedAssetsInfo={selectedAssetsInfo}
				setSelectedAssetsInfo={setSelectedAssetsInfo}
				bookId={bookId}
				bookCustomId={bookData?.book.customId}
				onClose={onClose}
				setCardId={setCardId}
				setIsCardGenerating={setIsCardGenerating}
			/>
		</Formik>
	);
};
