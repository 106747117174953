import * as Types from '../../../models.gen';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ChapterHeroesQueryVariables = Types.Exact<{
  page?: Types.InputMaybe<Types.Scalars['Int']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
  filter?: Types.InputMaybe<Types.HeroesFilterArgDto>;
}>;


export type ChapterHeroesQuery = { heroes: Array<{ id: string, names: Array<string>, customId?: number | null, updatedAt: any, clothes: Array<{ id: string, link?: string | null }>, hairs: Array<{ id: string, frontLink?: string | null, backLink?: string | null }>, bodies?: Array<{ id: string, link?: string | null, emotions: Array<string> }> | null, layers: Array<{ id: string, link?: string | null }>, chapters: Array<{ id: string, name?: string | null, chapterOrder?: number | null, book?: { id: string, name?: string | null } | null }> }> };

export type DeleteHeroFromBookMutationVariables = Types.Exact<{
  hero: Types.UpdateHeroRequestDto;
}>;


export type DeleteHeroFromBookMutation = { updateHero: { id: string } };

export type UpdateHeroChaptersMutationVariables = Types.Exact<{
  hero: Types.UpdateHeroRequestDto;
}>;


export type UpdateHeroChaptersMutation = { updateHero: { id: string } };


export const ChapterHeroesDocument = gql`
    query ChapterHeroes($page: Int, $limit: Int, $filter: HeroesFilterArgDto) {
  heroes(page: $page, limit: $limit, filter: $filter) {
    id
    names
    customId
    updatedAt
    clothes {
      id
      link
    }
    hairs {
      id
      frontLink
      backLink
    }
    bodies {
      id
      link
      emotions
    }
    layers {
      id
      link
    }
    chapters {
      id
      name
      chapterOrder
      book {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useChapterHeroesQuery__
 *
 * To run a query within a React component, call `useChapterHeroesQuery` and pass it any options that fit your needs.
 * When your component renders, `useChapterHeroesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChapterHeroesQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useChapterHeroesQuery(baseOptions?: Apollo.QueryHookOptions<ChapterHeroesQuery, ChapterHeroesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChapterHeroesQuery, ChapterHeroesQueryVariables>(ChapterHeroesDocument, options);
      }
export function useChapterHeroesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChapterHeroesQuery, ChapterHeroesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChapterHeroesQuery, ChapterHeroesQueryVariables>(ChapterHeroesDocument, options);
        }
export type ChapterHeroesQueryHookResult = ReturnType<typeof useChapterHeroesQuery>;
export type ChapterHeroesLazyQueryHookResult = ReturnType<typeof useChapterHeroesLazyQuery>;
export type ChapterHeroesQueryResult = Apollo.QueryResult<ChapterHeroesQuery, ChapterHeroesQueryVariables>;
export const DeleteHeroFromBookDocument = gql`
    mutation DeleteHeroFromBook($hero: UpdateHeroRequestDto!) {
  updateHero(hero: $hero) {
    id
  }
}
    `;
export type DeleteHeroFromBookMutationFn = Apollo.MutationFunction<DeleteHeroFromBookMutation, DeleteHeroFromBookMutationVariables>;

/**
 * __useDeleteHeroFromBookMutation__
 *
 * To run a mutation, you first call `useDeleteHeroFromBookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteHeroFromBookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteHeroFromBookMutation, { data, loading, error }] = useDeleteHeroFromBookMutation({
 *   variables: {
 *      hero: // value for 'hero'
 *   },
 * });
 */
export function useDeleteHeroFromBookMutation(baseOptions?: Apollo.MutationHookOptions<DeleteHeroFromBookMutation, DeleteHeroFromBookMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteHeroFromBookMutation, DeleteHeroFromBookMutationVariables>(DeleteHeroFromBookDocument, options);
      }
export type DeleteHeroFromBookMutationHookResult = ReturnType<typeof useDeleteHeroFromBookMutation>;
export type DeleteHeroFromBookMutationResult = Apollo.MutationResult<DeleteHeroFromBookMutation>;
export type DeleteHeroFromBookMutationOptions = Apollo.BaseMutationOptions<DeleteHeroFromBookMutation, DeleteHeroFromBookMutationVariables>;
export const UpdateHeroChaptersDocument = gql`
    mutation UpdateHeroChapters($hero: UpdateHeroRequestDto!) {
  updateHero(hero: $hero) {
    id
  }
}
    `;
export type UpdateHeroChaptersMutationFn = Apollo.MutationFunction<UpdateHeroChaptersMutation, UpdateHeroChaptersMutationVariables>;

/**
 * __useUpdateHeroChaptersMutation__
 *
 * To run a mutation, you first call `useUpdateHeroChaptersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateHeroChaptersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateHeroChaptersMutation, { data, loading, error }] = useUpdateHeroChaptersMutation({
 *   variables: {
 *      hero: // value for 'hero'
 *   },
 * });
 */
export function useUpdateHeroChaptersMutation(baseOptions?: Apollo.MutationHookOptions<UpdateHeroChaptersMutation, UpdateHeroChaptersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateHeroChaptersMutation, UpdateHeroChaptersMutationVariables>(UpdateHeroChaptersDocument, options);
      }
export type UpdateHeroChaptersMutationHookResult = ReturnType<typeof useUpdateHeroChaptersMutation>;
export type UpdateHeroChaptersMutationResult = Apollo.MutationResult<UpdateHeroChaptersMutation>;
export type UpdateHeroChaptersMutationOptions = Apollo.BaseMutationOptions<UpdateHeroChaptersMutation, UpdateHeroChaptersMutationVariables>;