import React from 'react';
import { Flex, Text } from '@chakra-ui/react';

interface HeaderProps {
	title?: string;
}

const Header: React.FC<HeaderProps> = ({ title = 'General' }) => {
	return (
		<Flex h="76px" p="10px 24px" alignItems="center" justify="space-between">
			<Text
				as="h3"
				fontWeight={800}
				fontSize="16px"
				lineHeight="135%"
				color="#242533"
			>
				{title}
			</Text>
		</Flex>
	);
};

export default Header;
