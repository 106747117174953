import React, { ChangeEvent, useCallback, useState } from 'react';
import {
	Badge,
	Box,
	Flex,
	HStack,
	IconButton,
	Input,
	InputGroup,
	InputLeftElement,
	Popover,
	PopoverBody,
	PopoverContent,
	PopoverTrigger,
	Spinner,
	Text,
	useDisclosure,
	VStack,
} from '@chakra-ui/react';

import { DeleteIcon, PlusIcon, SearchIcon } from 'shared/icons';

import { useHeroesNames } from './hooks';

interface MultipleNamesInputProps {
	label: string;
	onChange: (value: string[]) => void;
	values: string[];
}

export const MultipleNamesInput: React.FC<MultipleNamesInputProps> = ({
	label,
	values,
	onChange,
}) => {
	const [search, setSearch] = useState('');

	const { isOpen, onClose, onOpen } = useDisclosure();

	const { heroNames, loading } = useHeroesNames({
		isOpen,
		search,
		usedNames: values,
	});
	const handleSearchChange = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value),
		[],
	);

	const handleInputChange = useCallback(
		(name: string) => () => {
			onChange([...values, name]);
			onClose();
			setSearch('');
		},
		[onChange, onClose, values],
	);

	const handleNameDelete = useCallback(
		(name: string) => () => {
			onChange(values.filter((n) => n !== name));
			onClose();
			setSearch('');
		},
		[onChange, onClose, values],
	);

	return (
		<Flex
			p="13px 0 12px 0"
			align="center"
			justify="space-between"
			borderBottom="1px solid #eeeef2"
		>
			<Text fontSize="14px" mr="5px">
				{label}
			</Text>
			<Flex gap="10px" align="center">
				<Flex gap="7px" align="center" wrap="wrap" maxW="45vw">
					{values?.map((name) => (
						<Badge key={name} variant="logline">
							<Text>{name}</Text>
							<IconButton
								minW="auto"
								h="14px"
								variant="ghost"
								aria-label="delete-hero-name"
								onClick={handleNameDelete(name)}
							>
								<DeleteIcon />
							</IconButton>
						</Badge>
					))}
				</Flex>
				<Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
					<PopoverTrigger>
						<IconButton
							aria-label="add-hero-names"
							size="circle"
							variant="secondary"
						>
							<PlusIcon />
						</IconButton>
					</PopoverTrigger>
					<PopoverContent maxW={270} maxH={256}>
						<PopoverBody px={0} maxH={256}>
							<InputGroup mx="14px" my="10px" maxW={242}>
								<InputLeftElement pointerEvents="none">
									<SearchIcon />
								</InputLeftElement>
								<Input
									pl="40px"
									type="search"
									placeholder="Find a hero name"
									onChange={handleSearchChange}
								/>
							</InputGroup>
							<VStack
								mx="10px"
								mb="10px"
								spacing="1px"
								align="stretch"
								overflowY="auto"
							>
								{loading && !heroNames.length && <Spinner size="sm" />}
								{heroNames.map((name) => (
									<Box
										px="14px"
										py="8px"
										fontWeight={500}
										fontSize={13}
										key={name}
										_hover={{
											background: 'rgba(100, 77, 237, 0.08)',
											borderRadius: 8,
											color: '#644DED',
											cursor: 'pointer',
										}}
										onClick={handleInputChange(name)}
									>
										{name}
									</Box>
								))}
								{search && (
									<HStack
										align="center"
										p="9px"
										fontWeight={500}
										fontSize={13}
										_hover={{
											background: 'rgba(100, 77, 237, 0.08)',
											borderRadius: 8,
											color: '#644DED',
											cursor: 'pointer',
										}}
										onClick={handleInputChange(search)}
									>
										<PlusIcon />
										<Text>Create a new option name</Text>
									</HStack>
								)}
							</VStack>
						</PopoverBody>
					</PopoverContent>
				</Popover>
			</Flex>
		</Flex>
	);
};
