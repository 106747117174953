import React from 'react';
import {
	Divider,
	Flex,
	Popover,
	PopoverBody,
	PopoverContent,
	PopoverTrigger,
	Text,
} from '@chakra-ui/react';

import { InfoIcon } from 'shared/icons';

interface InfoPopoverProps {
	isOpen: boolean;
	onOpen: () => void;
	onClose: () => void;
}

const InfoPopover: React.FC<InfoPopoverProps> = ({
	isOpen,
	onOpen,
	onClose,
}) => {
	return (
		<Popover isOpen={isOpen} placement="right" offset={[55, 15]}>
			<PopoverTrigger>
				<InfoIcon
					onMouseMove={onOpen}
					onMouseLeave={onClose}
					w={6}
					h={6}
					cursor="pointer"
					color="#808192"
					_hover={{
						color: '#8960FF',
					}}
				/>
			</PopoverTrigger>
			<PopoverContent w="220px">
				<PopoverBody>
					<Text textAlign="left" lineHeight="21px" fontWeight={600} pb="13px">
						Info
					</Text>
					<Divider />
					<Flex
						p="17px 17px 17px 0"
						flexDir="column"
						textAlign="left"
						align="flex-start"
						fontSize="14px"
					>
						The description shouldn&apos;t contain more than 65 characters.
					</Flex>
				</PopoverBody>
			</PopoverContent>
		</Popover>
	);
};

export default InfoPopover;
