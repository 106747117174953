import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { Box } from '@chakra-ui/react';
import { CompositeDecorator, ContentBlock, ContentState } from 'draft-js';
import { useStore } from 'effector-react';

import { scriptModel } from 'entities/script';
import { userModel } from 'entities/user';
import { checkIsCaseSensitive } from 'features/editor/utils';
import { useGetDialogNumber } from 'shared/hooks/dialogue-number';
import { useIsArrow } from 'shared/hooks/isArrow';
import { CommentButton } from 'shared/ui';
import FoldButton from 'shared/ui/fold-button';

export type ErrorContent = {
	start_index: number;
	end_index: number;
	msgs: string[];
};

interface ComponentItemProps {
	offsetKey: string;
	blockKey: string;
	contentState: ContentState;
	children: ReactNode;
}
interface ComponentMethodProps {
	offsetKey: string;
	children: ReactNode;
}

type CallbackFunction = (start: number, end: number) => void;

const COMMAND_REGEX =
	/backgroundNameNoTransition|backgroundName|choiceTime-\d{1,}|choice|#[a-zA-Z_\d\s-]*/gi;
const REACTION_REGEX = /\s?\(.*\)/g;
const METHOD_REGEX = /\s?(type=|side=|effect=).*/g;
const NAME_REGEX = /^(NARRATOR)|([A-Z]+[A-Za-z ]*(?=\s\({1}))/g;
const CHOICE_REGEX = /("|“|”).*?("|“|”)/g;

export const findWithComment = (
	contentBlock: ContentBlock,
	callback: CallbackFunction,
	contentState: ContentState,
) => {
	contentBlock.findEntityRanges((character) => {
		const entityKey = character.getEntity();

		return (
			entityKey !== null &&
			contentState.getEntity(entityKey).getType() === 'COMMENT' &&
			!contentState.getEntity(entityKey).getData().resolved
		);
	}, callback);
};

export const findWithRegex = (
	regex: RegExp,
	contentBlock: ContentBlock,
	callback: CallbackFunction,
) => {
	const text = contentBlock.getText();
	let matchArr;
	let start;
	// eslint-disable-next-line no-cond-assign
	while ((matchArr = regex.exec(text)) !== null) {
		start = matchArr.index;
		callback(start, start + matchArr[0].length);
	}
};

export const findCurrentHighlighted = (
	regex: RegExp,
	contentBlock: ContentBlock,
	callback: CallbackFunction,
	position?: {
		from: number;
		to: number;
	},
) => {
	const text = contentBlock.getText();
	let matchArr;
	let start;
	// eslint-disable-next-line no-cond-assign
	while ((matchArr = regex.exec(text)) !== null) {
		start = matchArr.index;

		if (position?.from === start && position?.to === start + matchArr[0].length)
			callback(start, start + matchArr[0].length);
	}
};

const commandStrategy = (
	contentBlock: ContentBlock,
	callback: CallbackFunction,
) => {
	findWithRegex(COMMAND_REGEX, contentBlock, callback);
};
const reactionStrategy = (
	contentBlock: ContentBlock,
	callback: CallbackFunction,
) => {
	findWithRegex(REACTION_REGEX, contentBlock, callback);
};
const methodStrategy = (
	contentBlock: ContentBlock,
	callback: CallbackFunction,
) => {
	findWithRegex(METHOD_REGEX, contentBlock, callback);
};
const choiceStrategy = (
	contentBlock: ContentBlock,
	callback: CallbackFunction,
) => {
	findWithRegex(CHOICE_REGEX, contentBlock, callback);
};
const nameStrategy = (
	contentBlock: ContentBlock,
	callback: CallbackFunction,
) => {
	findWithRegex(NAME_REGEX, contentBlock, callback);
};
const commentStrategy = (
	contentBlock: ContentBlock,
	callback: CallbackFunction,
	contentState: ContentState,
) => {
	findWithComment(contentBlock, callback, contentState);
};

const CommandSpan: React.FC<ComponentItemProps> = ({
	offsetKey,
	blockKey,
	contentState,
	children,
}) => {
	const [isOpen, setIsOpen] = useState<boolean>(true);

	const { isCodeFolding } = useStore(userModel.$features);

	const isArrow = useIsArrow(contentState, blockKey);

	return (
		<>
			{isArrow && isCodeFolding ? (
				<FoldButton
					contentState={contentState}
					blockKey={blockKey}
					isOpen={isOpen}
					setIsOpen={setIsOpen}
				/>
			) : null}
			<span data-offset-key={offsetKey} className="editor-choice-token">
				{children}
			</span>
		</>
	);
};
const ReactionSpan: React.FC<ComponentMethodProps> = ({
	offsetKey,
	children,
}) => {
	return (
		<span data-offset-key={offsetKey} className="editor-reaction-token">
			{children}
		</span>
	);
};
const MethodSpan: React.FC<ComponentItemProps> = ({
	offsetKey,
	blockKey,
	contentState,
	children,
}) => {
	const [isOpen, setIsOpen] = useState<boolean>(true);

	const { isCodeFolding } = useStore(userModel.$features);

	const isArrow = useIsArrow(contentState, blockKey);

	return (
		<>
			{isArrow && isCodeFolding ? (
				<FoldButton
					contentState={contentState}
					blockKey={blockKey}
					isOpen={isOpen}
					setIsOpen={setIsOpen}
				/>
			) : null}
			<span data-offset-key={offsetKey} className="editor-method-token">
				{children}
			</span>
		</>
	);
};
const ChoiceSpan: React.FC<ComponentItemProps> = ({
	offsetKey,
	blockKey,
	contentState,
	children,
}) => {
	const [isOpen, setIsOpen] = useState<boolean>(true);

	const { isCodeFolding } = useStore(userModel.$features);

	const isArrow = useIsArrow(contentState, blockKey);

	return (
		<>
			{isArrow && isCodeFolding ? (
				<FoldButton
					contentState={contentState}
					blockKey={blockKey}
					isOpen={isOpen}
					setIsOpen={setIsOpen}
				/>
			) : null}
			<span data-offset-key={offsetKey} className="editor-command-token">
				{children}
			</span>
		</>
	);
};

const NameSpan: React.FC<ComponentItemProps> = ({
	offsetKey,
	blockKey,
	contentState,
	children,
}) => {
	const [isOpen, setIsOpen] = useState<boolean>(true);

	const { isCodeFolding } = useStore(userModel.$features);

	const isArrow = useIsArrow(contentState, blockKey);
	const dialog = contentState?.getBlockAfter(blockKey)?.getText().trim() || '';

	useGetDialogNumber(dialog, offsetKey);

	return (
		<>
			{isArrow && isCodeFolding ? (
				<FoldButton
					contentState={contentState}
					blockKey={blockKey}
					isOpen={isOpen}
					setIsOpen={setIsOpen}
				/>
			) : null}
			<span data-offset-key={offsetKey} className="editor-name-token">
				{children}
			</span>
		</>
	);
};
const SearchHighlightSpan: React.FC<ComponentItemProps> = ({
	offsetKey,
	blockKey,
	contentState,
	children,
}) => {
	const [isOpen, setIsOpen] = useState<boolean>(true);

	const { isCodeFolding } = useStore(userModel.$features);

	const isArrow = useIsArrow(contentState, blockKey);

	return (
		<>
			{isArrow && isCodeFolding ? (
				<FoldButton
					contentState={contentState}
					blockKey={blockKey}
					isOpen={isOpen}
					setIsOpen={setIsOpen}
				/>
			) : null}
			<span data-offset-key={offsetKey} className="editor-search-token">
				{children}
			</span>
		</>
	);
};

const HighlightCurrent: React.FC<ComponentItemProps> = ({
	offsetKey,
	blockKey,
	contentState,
	children,
}) => {
	const [isOpen, setIsOpen] = useState<boolean>(true);

	const hideBlockKeys = useStore(scriptModel.$hideBlockKeys);
	const { isCodeFolding } = useStore(userModel.$features);

	const isArrow = useIsArrow(contentState, blockKey);

	useEffect(() => {
		hideBlockKeys.forEach((foldBlock) => {
			if (foldBlock.blockKeysIn.includes(blockKey) && !foldBlock.isOpen) {
				const newArr = foldBlock;
				newArr.isOpen = true;
				scriptModel.setHideBlockKeys(newArr);
			}
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<>
			{isArrow && isCodeFolding ? (
				<FoldButton
					contentState={contentState}
					blockKey={blockKey}
					isOpen={isOpen}
					setIsOpen={setIsOpen}
				/>
			) : null}
			<span data-offset-key={offsetKey} className="editor-current-token">
				{children}
			</span>
		</>
	);
};

const CommentElement: React.FC<{
	offsetKey: string;
	blockKey: string;
	entityKey: string;
	contentState: ContentState;
	children: ReactNode;
}> = ({ offsetKey, blockKey, entityKey, contentState, children }) => {
	const data = contentState.getEntity(entityKey).getData();

	const commentRef = useRef<HTMLDivElement>(null);

	const { isCodeFolding } = useStore(userModel.$features);
	const isShownCommentsMarkdowns = useStore(
		scriptModel.$isShownCommentsMarkdowns,
	);
	const currentCommentsMarkdowns = useStore(
		scriptModel.$currentCommentsMarkdowns,
	);

	const showBtn = () => {
		scriptModel.setCurrentCommentsMarkdowns([offsetKey]);
	};

	const [isOpen, setIsOpen] = useState<boolean>(true);

	const isArrow = useIsArrow(contentState, blockKey);

	if (data?.resolved)
		return <span data-offset-key={offsetKey}>{children}</span>;

	return (
		<>
			{isArrow && isCodeFolding ? (
				<FoldButton
					contentState={contentState}
					blockKey={blockKey}
					isOpen={isOpen}
					setIsOpen={setIsOpen}
				/>
			) : null}
			<Box
				as="span"
				data-offset-key={offsetKey}
				bg="rgba(254,233,174,0.9)"
				onClick={showBtn}
			>
				{children}
			</Box>

			<CommentButton
				isFirstLine={contentState.getFirstBlock().getKey() === blockKey}
				commentsCount={1 + (data.replies?.length || 0)}
				commentRef={commentRef}
				onClick={() => {
					scriptModel.setCurrentCommentData({
						entity: {
							blockKey,
							entityKey,
							entityData: data,
						},
						position: commentRef.current?.getBoundingClientRect(),
					});
				}}
				isVisible={
					isShownCommentsMarkdowns &&
					currentCommentsMarkdowns.includes(offsetKey)
				}
			/>
		</>
	);
};

export const escape = (str: string) => {
	// eslint-disable-next-line no-control-regex
	return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
};

export const generateDecorator = (
	textForSearch: string,
	config?: {
		isCaseSensitive?: boolean;
		position?: {
			from: number;
			to: number;
		};
	},
	blockKey = '',
) => {
	const regex = new RegExp(
		escape(textForSearch),
		checkIsCaseSensitive(config?.isCaseSensitive),
	);
	return new CompositeDecorator([
		{
			strategy: (contentBlock: ContentBlock, callback: CallbackFunction) => {
				if (textForSearch && blockKey === contentBlock.getKey()) {
					findCurrentHighlighted(
						regex,
						contentBlock,
						callback,
						config?.position,
					);
				}
			},
			component: HighlightCurrent,
		},
		{
			strategy: commentStrategy,
			component: CommentElement,
		},
		{
			strategy: (contentBlock: ContentBlock, callback: CallbackFunction) => {
				if (textForSearch !== '') {
					findWithRegex(regex, contentBlock, callback);
				}
			},
			component: SearchHighlightSpan,
		},
		{
			strategy: choiceStrategy,
			component: ChoiceSpan,
		},
		{
			strategy: commandStrategy,
			component: CommandSpan,
		},
		{
			strategy: reactionStrategy,
			component: ReactionSpan,
		},
		{
			strategy: nameStrategy,
			component: NameSpan,
		},
		{
			strategy: methodStrategy,
			component: MethodSpan,
		},
	]);
};
