import React, { useState } from 'react';
import { Form, Formik } from 'formik';

import { Asset } from 'entities/assets/interfaces/asset';
import { AssetType } from 'entities/assets/model';
import { ImageReview } from 'entities/assets/ui/image-review';
import {
	GetAssetByIdDocument,
	GetAssetsDocument,
} from 'pages/art-catalog/ui/ArtCatalogTable/queries.gen';
import { useGetAssetError } from 'shared/hooks/asset-error';
import { useAppToast } from 'shared/hooks/toast';
import { InputFieldWithLink, SingleSelectForm } from 'shared/ui';
import { GetLayerDocument } from 'widgets/update-asset-modal/ui/modal/queries.gen';

import { KeywordsPicker } from '../../keywords-picker';
import { updateLayerSchema, UpdateLayerValues } from '../lib/schemas/layer';
import { useUpdateLayerMutation } from './graphql/queries.gen';

export interface Props {
	asset: Asset;
}

export const ARRAY_OF_LAYERS_POSITION = [
	{ title: '00', value: '-1' },
	{ title: '0', value: '0' },
	{ title: '1', value: '1' },
	{ title: '2', value: '2' },
	{ title: '3', value: '3' },
	{ title: '4', value: '4' },
	{ title: '5', value: '5' },
];
export const UpdateLayerForm: React.FC<Props> = ({ asset }) => {
	const toast = useAppToast();
	const handleError = useGetAssetError();
	const [isUploadError, setIsUploadError] = useState(false);

	const [updateLayer] = useUpdateLayerMutation({
		onCompleted: () => {
			toast({
				title: 'Layer updated successfully',
				status: 'success',
			});
			setIsUploadError(false);
		},
		onError: (mutationError) => {
			handleError(mutationError.message, 'Layer');
			setIsUploadError(!asset?.link);
		},
		refetchQueries: [GetAssetsDocument, GetLayerDocument, GetAssetByIdDocument],
	});

	return (
		<Formik<UpdateLayerValues>
			initialValues={{
				id: asset.id,
				link: asset.link,
				keywords: asset.keywords,
				newKeywords: asset.newKeywords,
				position: asset.position || 0,
				price: asset.price || 0,
				dropboxLink: asset.dropboxLink || '',
				// TODO: add description field
			}}
			validationSchema={updateLayerSchema}
			onSubmit={(value) =>
				updateLayer({
					variables: {
						layer: {
							...value,
							price: parseInt(value.price as any, 10),
							position: parseInt(value.position as any, 10),
						},
					},
				})
			}
			enableReinitialize
		>
			{({
				values,
				submitForm,
				setFieldValue,
				errors,
				touched,
				validateForm,
			}) => (
				<Form>
					<ImageReview
						label="Layer"
						type={asset.type}
						atName={isUploadError ? '' : asset?.atName?.trim() || ''}
						imageSrc={isUploadError ? '' : values.link || ''}
						onImageChange={async (link) => {
							setFieldValue('link', link, true);
							await validateForm();
							return submitForm();
						}}
						isUploadError={isUploadError}
						error={errors.link}
					/>
					<KeywordsPicker
						type={AssetType.LAYER}
						selectedKeywords={values.newKeywords}
						onApply={async (v) => {
							setFieldValue('newKeywords', v, true);
							await validateForm();
							submitForm();
						}}
					/>
					<InputFieldWithLink
						label="Link"
						name="dropboxLink"
						value={values.dropboxLink?.trim() || ''}
						onApply={async (v: string | number) => {
							setFieldValue('dropboxLink', v, true);
							await validateForm();
							submitForm();
						}}
						error={errors.dropboxLink}
						placeholder="https://..."
					/>
					<SingleSelectForm
						label="Position"
						selectedOption={String(values.position)}
						options={ARRAY_OF_LAYERS_POSITION}
						onClick={async (v) => {
							setFieldValue('position', parseInt(v, 10), true);
							await validateForm();
							submitForm();
						}}
						error={errors.position}
						touched={touched.position}
						type="number"
					/>
				</Form>
			)}
		</Formik>
	);
};
