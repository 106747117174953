import React, { Dispatch, SetStateAction } from 'react';
import { Box, Checkbox, Flex, Image, Spinner, Text } from '@chakra-ui/react';

import { useAssetCardHairByIdQuery } from 'features/hero/assets-tab/components/asset-card-hair/queries.gen';
import { SelectedAssetsInfo } from 'widgets/create-card-modal/interfaces';

interface AssetCardHairProps {
	assetId: string;
	setSelectedAssetsInfo?: Dispatch<SetStateAction<SelectedAssetsInfo>>;
	isActive?: boolean;
}

export const AssetCardHair: React.FC<AssetCardHairProps> = ({
	assetId,
	setSelectedAssetsInfo,
	isActive,
}) => {
	const { data, loading } = useAssetCardHairByIdQuery({
		variables: {
			id: assetId,
		},
	});

	const handleChange = () =>
		setSelectedAssetsInfo
			? setSelectedAssetsInfo((prev: SelectedAssetsInfo) => ({
					...prev,
					selectedHairs: isActive
						? prev.selectedHairs.filter((el) => el !== assetId)
						: [...prev.selectedHairs, assetId],
			  }))
			: null;

	return (
		<Flex flexDirection="column" gap={2} minW="240px" maxW="260px">
			<Box
				h="min-content"
				userSelect="none"
				border="4px solid transparent"
				borderRadius="10px"
				position="relative"
				// cursor="pointer"
				// borderColor={`${isSelected(assetId) ? 'rgba(100, 77, 237, 0.08)' : ''}`}
				// onClick={() => handleSelectAsset(assetId)}
			>
				<Flex
					height="180px"
					alignItems="center"
					justifyContent="center"
					border="1px solid #EEEEF2"
					borderRadius="10px"
					overflow="hidden"
					borderColor={isActive ? '#644DED' : ''}
					_hover={{
						borderColor: '#644DED',
						cursor: 'pointer',
					}}
				>
					{loading ? (
						<Spinner size="md" />
					) : (
						<Box
							position="relative"
							top="50%"
							transform="scale(0.9)"
							onClick={handleChange}
						>
							<Image
								alt={data?.hair.name || ''}
								src={data?.hair.frontLink || ''}
							/>
						</Box>
					)}
				</Flex>

				{!loading && (
					<Box position="absolute" top={1} right={1}>
						<Checkbox m="10px" isChecked={isActive} onChange={handleChange} />
					</Box>
				)}
			</Box>
			<Text p={1} fontSize="12px" fontWeight={600} lineHeight="16px">
				{data?.hair.name || ''}
			</Text>
		</Flex>
	);
};
