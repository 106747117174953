import React from 'react';
import { Portal } from '@chakra-ui/react';
import { Formik } from 'formik';

import { AssetType } from 'entities/assets/model';
import { ImageReview } from 'entities/assets/ui/image-review';
import {
	createBackgroundSchema,
	CreateBackgroundValues,
} from 'features/asset/create/lib/schemas/background';
import { useCreateBackgroundMutation } from 'features/asset/create/queries.gen';
import { ActionButtons } from 'features/asset/create/ui/action-buttons';
import { CreateAnyAssetFormProps } from 'features/asset/create/ui/types';
import { FilterPicker } from 'features/asset/filter-picker/filter-picker';
import { KeywordsPicker } from 'features/asset/keywords-picker';
import { GetAssetsDocument } from 'pages/art-catalog/ui/ArtCatalogTable/queries.gen';
import { trackAssetCreated } from 'shared/analytics';
import { useGetAssetError } from 'shared/hooks/asset-error';
import { useAppToast } from 'shared/hooks/toast';
import { InputField, InputFieldWithLink } from 'shared/ui';
import { getFilenameFromURL } from 'shared/utils/get-filename-from-url';
import { FilterKeywords } from 'widgets/keywords-filter-modal/constant';
import { AssetViewTabs } from 'widgets/update-asset-modal/lib/asset-view-tabs';

export const CreateBackgroundForm: React.FC<CreateAnyAssetFormProps> = ({
	onSubmit,
	onCancel,
	buttonsContainerRef,
	specialType,
	altBackgroundsIds,
}) => {
	const toast = useAppToast();
	const handleError = useGetAssetError();

	const [createBackground] = useCreateBackgroundMutation({
		onCompleted: (data) => {
			const keywords = Object.values(
				data.createBackground?.newKeywords as FilterKeywords,
			)
				.filter((v) => v)
				.flat();

			if (!keywords.length) {
				toast({
					title: `Background was created without keywords`,
					status: 'warning',
				});
			}
			if (!data.createBackground.filter)
				toast({
					title: `Background created without filter`,
					status: 'warning',
				});
			else {
				toast({ title: 'Background created successfully', status: 'success' });
			}
			onSubmit();
			trackAssetCreated({
				type: AssetType.BACKGROUND,
				filename: data.createBackground?.filename as string,
				link: data.createBackground?.link as string,
			});
		},
		onError: (mutationError) =>
			handleError(mutationError.message, 'Background'),
		refetchQueries: [GetAssetsDocument],
	});

	return (
		<Formik<CreateBackgroundValues>
			initialValues={{
				link: '',
				filename: '',
				dropboxLink: '',
				visionLabels: '',
				keywords: [],
				filter: '',
				newKeywords: {},
			}}
			validationSchema={createBackgroundSchema}
			onSubmit={(data) =>
				createBackground({
					variables: {
						background: {
							...data,
							alternativeBackgroundsIds: altBackgroundsIds,
						},
					},
				})
			}
		>
			{({ values, submitForm, setFieldValue, errors, isSubmitting }) => (
				<>
					{specialType !== AssetViewTabs.ALTERNATIVE_BACKGROUNDS ? (
						<>
							<InputField
								label="Filename"
								name="filename"
								value={
									getFilenameFromURL(values.link) ||
									values.filename?.trim() ||
									''
								}
								onApply={(v: string | number) =>
									setFieldValue('filename', v, true)
								}
								error={errors.filename}
								placeholder="ext-background-name-day.jpg"
								isDisabledFilename={!!values.link}
							/>
							<ImageReview
								label="Background"
								type={AssetType.BACKGROUND}
								imageSrc={values.link?.trim()}
								onImageChange={(link) => setFieldValue('link', link, true)}
								error={errors.link}
							/>
							<KeywordsPicker
								type={AssetType.BACKGROUND}
								selectedKeywords={values.newKeywords}
								onApply={(v) => setFieldValue('newKeywords', v, true)}
							/>
							<InputFieldWithLink
								label="Link"
								name="dropboxLink"
								value={values.dropboxLink?.trim() || ''}
								onApply={(v: string | number) =>
									setFieldValue('dropboxLink', v, true)
								}
								error={errors.dropboxLink}
								placeholder="https://..."
							/>
							<FilterPicker
								label="Filter"
								selectedValue={values.filter || ''}
								onApply={(v) => setFieldValue('filter', v, true)}
								isCreateForm
							/>
						</>
					) : null}
					<Portal containerRef={buttonsContainerRef}>
						<ActionButtons
							disabled={isSubmitting}
							onApply={submitForm}
							onCancel={onCancel}
						/>
					</Portal>
				</>
			)}
		</Formik>
	);
};
