import React, { memo } from 'react';
import {
	HStack,
	IconButton,
	Image,
	Td,
	Text,
	Tooltip,
	Tr,
} from '@chakra-ui/react';

import { ChaptersAssetSelect } from 'features/asset/chapters-asset-select';
import { BookChaptersForAssetSelectQuery } from 'features/asset/chapters-asset-select/queries.gen';
import { TrashBinIcon } from 'shared/icons';
import { ArrayElement } from 'shared/model';
import { formatDate } from 'shared/utils';
import { BackgroundsByStoryQuery } from 'widgets/backgrounds-list/graphql/queries.gen';

interface BackgroundListRowProps {
	background: ArrayElement<
		BackgroundsByStoryQuery['backgrounds']['backgrounds']
	>;
	index: number;
	currentBookId: string;
	handleUpdateBackgroundChapters: (
		background: ArrayElement<
			BackgroundsByStoryQuery['backgrounds']['backgrounds']
		>,
	) => (
		chapters: BookChaptersForAssetSelectQuery['book']['chapters'],
	) => Promise<void>;
	handleBackgroundDelete: (
		background: ArrayElement<
			BackgroundsByStoryQuery['backgrounds']['backgrounds']
		>,
	) => () => Promise<void>;
	isLoading: boolean;
	updatedBackground: string;
	handleUpdateModalOpen: () => void;
}

export const BackgroundListRow: React.FC<BackgroundListRowProps> = memo(
	({
		background,
		index,
		currentBookId,
		handleUpdateBackgroundChapters,
		handleBackgroundDelete,
		isLoading,
		updatedBackground,
		handleUpdateModalOpen,
	}) => {
		return (
			<Tr h="90px" cursor="pointer">
				<Tooltip label="Open Asset" openDelay={500}>
					<Td
						py={0}
						position="sticky"
						left={0}
						zIndex={100}
						onClick={handleUpdateModalOpen}
					>
						<HStack alignItems="center" w="full">
							<Text textAlign="center" minW="26px" mr="20px">
								{index + 1}
							</Text>
							<Image
								boxSize="75px"
								borderRadius="10px"
								objectFit="cover"
								src={background.link || ''}
								alt={background.name || ''}
							/>
							<Text
								fontWeight={400}
								minW="150px"
								overflow="hidden"
								whiteSpace="nowrap"
								textOverflow="ellipsis"
							>
								{background.name}
							</Text>
						</HStack>
					</Td>
				</Tooltip>

				<Td>
					<ChaptersAssetSelect
						assetId={background.id}
						bookId={currentBookId}
						selectedChapters={background.chapters.filter(
							(chapter) => chapter.book?.id === currentBookId,
						)}
						onSubmit={handleUpdateBackgroundChapters(background)}
						isLoading={isLoading}
						updatedAsset={updatedBackground}
					/>
				</Td>
				<Td>{formatDate(background.updatedAt)}</Td>
				<Td>
					<Tooltip label="Remove from Story">
						<IconButton
							aria-label="remove-background"
							variant="ghost"
							size="square"
							onClick={handleBackgroundDelete(background)}
						>
							<TrashBinIcon w={6} h={6} />
						</IconButton>
					</Tooltip>
				</Td>
			</Tr>
		);
	},
);
