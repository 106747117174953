import React from 'react';
import {
	Button,
	Flex,
	Popover,
	PopoverBody,
	PopoverContent,
	PopoverTrigger,
} from '@chakra-ui/react';

import { SingleChoiceList } from '../single-choice-list';

interface Props {
	options: { title: string; value: string }[];
	onChange: (v: string) => void;
	selectedValue?: string | null;
	searchValue: string;
	setSearchValue: (searchValue: string) => void;
	isOpen: boolean;
	onOpen: () => void;
	onClose?: () => void;
	isNeedPermissionToSearch?: boolean;
	onApply?: (v: string | null) => void;
	initialValue?: string;
	optionText?: string;
	customStyle?: { width: string; maxHeight: string };
}

export const SinglePicker: React.FC<Props> = ({
	options,
	onChange,
	children,
	selectedValue,
	searchValue,
	setSearchValue,
	isOpen,
	onOpen,
	onClose,
	isNeedPermissionToSearch = false,
	onApply,
	initialValue,
	optionText,
	customStyle,
}) => {
	return (
		<Popover
			isOpen={isOpen}
			onOpen={onOpen}
			onClose={onClose}
			variant="singleList"
			placement="bottom-start"
		>
			<PopoverTrigger>{children}</PopoverTrigger>
			<PopoverContent
				ml={2}
				w={isNeedPermissionToSearch ? 300 : 270}
				maxH={250}
				style={customStyle}
			>
				<PopoverBody
					w={isNeedPermissionToSearch ? 300 : 270}
					maxH={250}
					{...customStyle}
				>
					<SingleChoiceList
						options={options}
						selectedOption={selectedValue}
						onChange={onChange}
						onClose={!onApply ? onClose : null}
						hasSearch
						search={searchValue}
						setSearch={setSearchValue}
						isNeedPermissionToSearch={isNeedPermissionToSearch}
						optionText={optionText}
						onApply={onApply}
					/>
					{onApply && onClose ? (
						<Flex justifyContent="flex-end">
							<Button
								type="button"
								size="md"
								variant="secondary"
								onClick={() => {
									setSearchValue('');
									onChange(initialValue || '');
									onApply(null);
									onClose();
								}}
							>
								Reset
							</Button>
							<Button
								type="button"
								size="md"
								variant="secondary"
								onClick={() => {
									setSearchValue('');
									onChange(initialValue || '');
									onClose();
								}}
							>
								Cancel
							</Button>
							<Button
								type="button"
								size="md"
								variant="secondary"
								onClick={() => {
									setSearchValue('');
									onApply(selectedValue || '');
									onClose();
								}}
							>
								Apply
							</Button>
						</Flex>
					) : null}
				</PopoverBody>
			</PopoverContent>
		</Popover>
	);
};
