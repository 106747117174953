import { EditorState, Modifier } from 'draft-js';

export const insertCharacter = (
	editorState: EditorState,
	characterToInsert: string,
) => {
	const currentContent = editorState.getCurrentContent();
	const currentSelection = editorState.getSelection();

	const newContent = Modifier.insertText(
		currentContent,
		currentSelection,
		characterToInsert,
	);

	const newEditorState = EditorState.push(
		editorState,
		newContent,
		'insert-characters',
	);

	return EditorState.forceSelection(
		newEditorState,
		newContent.getSelectionAfter(),
	);
};
