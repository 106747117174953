import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import {
	Button,
	Checkbox,
	Flex,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	MenuOptionGroup,
	Text,
	useDisclosure,
} from '@chakra-ui/react';

import { ArrowDownIcon, FrIcon, StoryIcon } from 'shared/icons';
import { truncateString } from 'shared/utils/truncate-string';

interface Props {
	options: string[];
	selectedOptions: string[];
	onChange: (options: string[]) => void;
	isMulti?: boolean;
	isSelectWithInfo?: boolean;
	placeholder?: string;
	btnClassName?: string;
	value?: string;
	dropdownClassName?: string;
}

export const Select: React.FC<Props> = ({
	options,
	onChange,
	selectedOptions,
	placeholder,
	isMulti = false,
	btnClassName,
	dropdownClassName,
	isSelectWithInfo,
	value = '',
}) => {
	const { onOpen, isOpen, onClose } = useDisclosure();

	const selectedItemRef = useRef<HTMLButtonElement | null>(null);

	const onSelect = useCallback(
		(item: string) => {
			if (!selectedOptions) {
				onChange([item]);
				return;
			}

			let selected = [];

			if (isMulti && selectedOptions) {
				if (selectedOptions.includes(item)) {
					selected = selectedOptions.filter((v) => v !== item);
				} else {
					selected = [...selectedOptions, item];
				}
			} else {
				selected = [item];
			}

			onChange(selected);
		},
		[isMulti, onChange, selectedOptions],
	);

	const valueTitle = useMemo(() => {
		return Array.isArray(selectedOptions) && selectedOptions.length > 0
			? truncateString(selectedOptions.map((option) => option).join(', '), 27)
			: placeholder;
	}, [selectedOptions, placeholder]);

	const placeholderVariant = useMemo(() => {
		if (isSelectWithInfo) {
			return value ? 'selectValue' : 'selectPlaceholder';
		}
		return '';
	}, [isSelectWithInfo, value]);

	useEffect(() => {
		if (selectedItemRef.current) {
			selectedItemRef.current.scrollIntoView({
				behavior: 'smooth',
				block: 'center',
			});
		}
	}, [isOpen]);

	return (
		<Menu
			variant={!isMulti ? 'single' : 'multi'}
			closeOnSelect={!isMulti}
			autoSelect={false}
			preventOverflow
			isOpen={isOpen}
			onOpen={onOpen}
			onClose={onClose}
		>
			<MenuButton
				as={Button}
				rightIcon={<ArrowDownIcon w={5} h={5} />}
				variant="select"
				className={btnClassName}
			>
				{isMulti ? (
					<Text variant={placeholderVariant}>{valueTitle}</Text>
				) : (
					<Flex>
						{valueTitle === 'FR' ? <FrIcon /> : null}
						{valueTitle === 'Story' ? <StoryIcon /> : null}
						<Text
							ml="5px"
							maxW="100%"
							overflow="hidden"
							textOverflow="ellipsis"
							fontWeight={600}
						>
							{valueTitle}
						</Text>
					</Flex>
				)}
			</MenuButton>
			<MenuList className={dropdownClassName}>
				<MenuOptionGroup type="radio">
					{options &&
						options.map((item) => (
							<Flex alignItems="center" key={item}>
								{isMulti && (
									<Checkbox
										isChecked={
											selectedOptions &&
											selectedOptions.some((option) => option === item)
										}
										onChange={() => onSelect(item)}
										marginLeft="5px"
									/>
								)}
								<MenuItem
									value={item}
									onClick={() => onSelect(item)}
									display="flex"
									style={
										selectedOptions &&
										selectedOptions.some((option) => option === item)
											? {
													backgroundColor: isMulti ? '' : 'rgba(93,66,255,.08)',
													color: '#644ded',
											  }
											: {}
									}
									_hover={
										isMulti
											? {}
											: { color: '#644ded', bg: 'rgba(93, 66, 255, 0.08)' }
									}
									ref={valueTitle === item ? selectedItemRef : null}
									_active={isMulti ? { bg: '#FFFFF' } : {}}
									_focus={isMulti ? { bg: '#FFFFF' } : {}}
								>
									{isMulti ? (
										item
									) : (
										<>
											{item === 'FR' ? <FrIcon mr="5px" /> : null}
											{item === 'Story' ? <StoryIcon mr="5px" /> : null}

											{item}
										</>
									)}
								</MenuItem>
							</Flex>
						))}
				</MenuOptionGroup>
			</MenuList>
		</Menu>
	);
};
