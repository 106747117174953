import { ApolloQueryResult, FetchResult } from '@apollo/client';
import gql from 'graphql-tag';

import { apiInstance } from './base';
import {
	getDraftDocContent as getDraftDocContentRes,
	getDraftDocContentVariables,
} from './model/getDraftDocContent';
import { getGoogleDocContent } from './model/getGoogleDocContent';
import { getIsDraftDocContentUpdatedVariables } from './model/getIsDraftDocContentUpdated';
import {
	syncDraftDocContentIntoDB as syncDraftDocContentIntoDBRes,
	syncDraftDocContentIntoDBVariables,
} from './model/syncDraftDocContentIntoDB';

type GetPageContentParams = {
	documentId: string;
};
type ValidationAccessParams = {
	fileId: string;
	isValidateContentAccount?: boolean;
};
interface IndexedEntity {
	startIndex: number;
	endIndex: number;
}

interface ParagraphElement extends IndexedEntity {
	textRun: {
		content: string;
		textStyle: {
			backgroundColor: {
				color: {
					rgbColor: {
						red?: number;
						green?: number;
						blue?: number;
					};
				};
			};
			bold?: boolean | null;
			italic?: boolean | null;
		};
	};
}

interface Paragraph extends IndexedEntity {
	elements: ParagraphElement[];
}

export interface ContentElement {
	paragraph?: Paragraph;
}

type PatchFileParams = {
	documentId: string;
	text: string;
	styles: any[];
};

export type UpdateDraftDocContentParams = {
	documentId: string;
	lang: string;
	updateGoogleDoc: boolean;
	blocks: any[];
	entityMap: any[];
};

const GET_GOOGLE_DOC_CONTENT_QUERY = gql`
	query getGoogleDocContent($documentId: String!) {
		getGoogleDocContent(documentId: $documentId) {
			text {
				paragraph {
					elements {
						textRun {
							content
							textStyle {
								backgroundColor {
									color {
										rgbColor {
											red
											green
											blue
										}
									}
								}
								italic
								bold
							}
						}
					}
				}
			}
		}
	}
`;

const GET_DRAFT_DOC_CONTENT_QUERY = gql`
	query getDraftDocContent($documentId: String!) {
		getDraftDocContent(documentId: $documentId) {
			blocks {
				key
				type
				text
				depth
				inlineStyleRanges {
					style
					offset
					length
				}
				entityRanges {
					key
					offset
					length
				}
			}
			entityMap {
				key
				entity {
					type
					mutability
					data {
						comment
						picture
						createdAt
						author
						resolved
						replies {
							id
							reply
							picture
							author
							createdAt
							resolved
						}
					}
				}
			}
		}
	}
`;
const GET_DRAFT_DOC_CONTENT_UPDATE_DATE_QUERY = gql`
	query getDraftDocContentUpdatedAt($documentId: String!) {
		getDraftDocContent(documentId: $documentId) {
			updatedAt
		}
	}
`;

const GET_IS_DRAFT_DOC_CONTENT_UPDATED_QUERY = gql`
	query getIsDraftDocContentUpdated($documentId: String!) {
		getIsDraftDocContentUpdated(documentId: $documentId) {
			isUpdated
			modifiedTime
			modifiedBy {
				name
				photo
			}
		}
	}
`;

const SYNC_DRAFT_DOC_CONTENT_INTO_DB = gql`
	mutation syncDraftDocContentIntoDB($documentId: String!) {
		syncDraftDocContentIntoDB(documentId: $documentId) {
			blocks {
				key
				type
				text
				depth
				inlineStyleRanges {
					style
					offset
					length
				}
				entityRanges {
					key
					offset
					length
				}
			}
			entityMap {
				key
				entity {
					type
					mutability
					data {
						comment
						picture
						createdAt
						author
						resolved
						replies {
							id
							reply
							picture
							author
							createdAt
							resolved
						}
					}
				}
			}
		}
	}
`;

const UPDATE_DOCUMENT_CONTENT = gql`
	mutation updateGoogleDocContent(
		$documentId: String!
		$googleDoc: InsertGoogleDocDataDto!
	) {
		updateGoogleDocContent(documentId: $documentId, googleDoc: $googleDoc)
	}
`;

const UPDATE_DRAFT_DOC_CONTENT = gql`
	mutation updateDraftDocContent(
		$documentId: String!
		$updateGoogleDoc: Boolean!
		$googleDoc: InsertDraftDocDataDto!
		$lang: String!
	) {
		updateDraftDocContent(
			documentId: $documentId
			updateGoogleDoc: $updateGoogleDoc
			googleDoc: $googleDoc
			lang: $lang
		)
	}
`;

const VALIDATION_ACCESS_FILE = gql`
	query GetValidationAccess(
		$fileId: String!
		$isValidateContentAccount: Boolean
	) {
		getValidationAccess(
			fileId: $fileId
			isValidateContentAccount: $isValidateContentAccount
		)
	}
`;

export const getValidationAccessFile = async ({
	fileId,
	isValidateContentAccount,
}: ValidationAccessParams): Promise<ApolloQueryResult<boolean | undefined>> =>
	apiInstance.query({
		query: VALIDATION_ACCESS_FILE,
		variables: { fileId, isValidateContentAccount },
	});

export const getPageContent = async ({
	documentId,
}: GetPageContentParams): Promise<ApolloQueryResult<getGoogleDocContent>> =>
	apiInstance.query({
		query: GET_GOOGLE_DOC_CONTENT_QUERY,
		variables: { documentId },
	});

export const getDraftDocContent = async ({
	documentId,
}: getDraftDocContentVariables): Promise<
	ApolloQueryResult<getDraftDocContentRes>
> =>
	apiInstance.query({
		query: GET_DRAFT_DOC_CONTENT_QUERY,
		variables: { documentId },
	});

export const getDraftDocContentUpdateDate = async ({
	documentId,
}: getDraftDocContentVariables): Promise<ApolloQueryResult<any>> =>
	apiInstance.query({
		query: GET_DRAFT_DOC_CONTENT_UPDATE_DATE_QUERY,
		variables: { documentId },
	});

export const getIsDraftDocContentUpdated = async ({
	documentId,
}: getIsDraftDocContentUpdatedVariables): Promise<ApolloQueryResult<any>> =>
	apiInstance.query({
		query: GET_IS_DRAFT_DOC_CONTENT_UPDATED_QUERY,
		variables: { documentId },
	});

export const syncDraftDocContentIntoDB = async ({
	documentId,
}: syncDraftDocContentIntoDBVariables): Promise<
	FetchResult<syncDraftDocContentIntoDBRes>
> => {
	// TODO add type
	return apiInstance.mutate({
		mutation: SYNC_DRAFT_DOC_CONTENT_INTO_DB,
		variables: { documentId },
	});
};

export const patchFile = async ({
	documentId,
	text,
	styles,
}: PatchFileParams): Promise<FetchResult<any>> => {
	return apiInstance.mutate({
		mutation: UPDATE_DOCUMENT_CONTENT,
		variables: { googleDoc: { text, documentId, styles }, documentId },
	});
};

export const updateDraftDocContent = async ({
	documentId,
	updateGoogleDoc,
	blocks,
	entityMap,
	lang,
}: UpdateDraftDocContentParams): Promise<FetchResult<any>> => {
	return apiInstance.mutate({
		mutation: UPDATE_DRAFT_DOC_CONTENT,
		variables: {
			googleDoc: { blocks, entityMap },
			documentId,
			updateGoogleDoc,
			lang,
		},
	});
};
