import React, { useMemo, useState } from 'react';
import { Badge, Button } from '@chakra-ui/react';
import cn from 'classnames';

import { CancelIcon } from 'shared/icons/cancel';

import { FORMAT_STATUS_MAP } from '../../../features/chapter/update/update-management-status/constants';
import { ListItem } from '../list-item';
import { SearchInput } from '../search-input';
import styles from './styles.module.scss';

interface Props {
	options: readonly string[];
	selectedOptions: string[];
	onChange: (selected: string[]) => void;
	isIcon?: boolean;
	disableSearch?: boolean;
	disableTags?: boolean;
	isKeywordsChoice?: boolean;
	isManagementStatus?: boolean;
}

export const MultipleChoiceList: React.FC<Props> = ({
	options,
	selectedOptions,
	onChange,
	isIcon = false,
	disableSearch,
	disableTags,
	isKeywordsChoice,
	isManagementStatus,
}) => {
	const [search, setSearch] = useState('');

	const choices = useMemo(
		() => [...options.filter((option) => option.includes(search))],
		[options, search],
	);

	const onSelectAnswer = (answer: string) => {
		const isAlreadySelected = (selectedOptions || []).some(
			(option) => option === answer,
		);

		if (isAlreadySelected) {
			onChange(selectedOptions.filter((option) => option !== answer));
			return;
		}
		onChange([...(selectedOptions || []), answer]);
	};

	return (
		<>
			{!disableSearch ? (
				<SearchInput
					search={search}
					setSearch={setSearch}
					placeholder="Find Keywords"
				/>
			) : null}
			{selectedOptions?.length > 0 && !disableTags ? (
				<div className={styles.selected}>
					{selectedOptions.map((option) => (
						<Badge key={option} gap="none">
							{isManagementStatus ? FORMAT_STATUS_MAP[option] : option}
							<Button
								flexShrink={0}
								variant="secondary"
								size="circle"
								onClick={() => onSelectAnswer(option)}
							>
								<CancelIcon />
							</Button>
						</Badge>
					))}
				</div>
			) : null}
			<div
				className={cn(
					styles.multipleList,
					isKeywordsChoice && styles.keywordsList,
				)}
			>
				{choices?.length > 0 &&
					choices.map((choice) => (
						<ListItem
							key={choice}
							label={isManagementStatus ? FORMAT_STATUS_MAP[choice] : choice}
							isMultipleChoice
							isSelected={(selectedOptions || []).includes(choice)}
							onSelect={() => onSelectAnswer(choice)}
							isIcon={isIcon}
						/>
					))}
			</div>
		</>
	);
};
