import * as yup from 'yup';

import { spaceValidation } from 'features/asset/update/lib/yup-validation';

export const createBodySchema = yup.object({
	link: spaceValidation.url().required('Body is a required field').nullable(),
	filename: spaceValidation.min(5).max(255),
	dropboxLink: spaceValidation,
	emotions: yup
		.array()
		.of(spaceValidation.required())
		.required()
		.min(1, 'Emotions is a required field'),
	keywords: yup.array().of(yup.string().trim().required()).required(),
	newKeywords: yup
		.object()
		.shape({
			sex: yup.array().nullable(),
			app: yup.array().nullable(),
			art_style: yup.array().nullable(),
			body_type: yup.array().nullable(),
			skintone: yup.array().nullable(),
			age: yup.array().nullable(),
			extra: yup.array().nullable(),
			not_human: yup.array().nullable(),
		})
		.nullable(),
});

export type CreateBodyValues = yup.InferType<typeof createBodySchema>;
