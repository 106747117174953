import React, { useCallback, useRef } from 'react';
import {
	Badge,
	Button,
	Popover,
	PopoverBody,
	PopoverContent,
	PopoverTrigger,
	Portal,
	Text,
	useDisclosure,
} from '@chakra-ui/react';
import { useFormik } from 'formik';

import { GetBookDocument, GetBookQuery } from 'features/book/queries.gen';
import { ChapterByIdDocument } from 'features/story-editor/header/queries.gen';
import {
	BookProperty,
	ButtonType,
	trackPropertyChanged,
} from 'shared/analytics';
import { useAppToast } from 'shared/hooks/toast';
import { PencilIcon } from 'shared/icons';
import { ArrayElement } from 'shared/model';
import { UpdateTagsForm } from 'shared/ui/update-tags-form';

import { useUpdateChapterMutation } from '../../queries.gen';
import { FORMAT_STATUS_MAP, statusesOptions } from '../constants';
import styles from './styles.module.scss';

export interface Props {
	chapter: ArrayElement<GetBookQuery['book']['chapters']>;
}

export const UpdateStatusForm: React.FC<Props> = ({ chapter }) => {
	const toast = useAppToast();
	const { onOpen, onClose, isOpen } = useDisclosure();
	const containerRef = useRef<HTMLDivElement | null>(null);

	const [updateChapter] = useUpdateChapterMutation({
		onCompleted: () => {
			onClose();
			toast({
				title: 'Chapter management status updated successfully',
				status: 'success',
			});
		},
		onError: () =>
			toast({
				title: 'Chapter management status was not updated',
				status: 'error',
			}),
		refetchQueries: [ChapterByIdDocument, GetBookDocument],
	});

	const onSubmitStatusModal = useCallback(
		(formValue: any) => {
			updateChapter({
				variables: {
					chapter: {
						id: chapter?.id || '',
						managementStatus: formValue,
					},
				},
			});
			trackPropertyChanged({
				entity: 'chapter',
				property: BookProperty.MANAGEMENT_STATUS,
				value: formValue,
				id: chapter?.id,
			});
		},
		[chapter?.id, updateChapter],
	);

	const updateStatusForm = useFormik({
		initialValues: {
			managementStatus: chapter?.managementStatus || [],
		},
		validateOnChange: true,
		enableReinitialize: true,
		onSubmit: (values) => onSubmitStatusModal({ ...values }),
	});

	const onCloseStatusModal = useCallback(() => {
		onClose();
		updateStatusForm.resetForm();
	}, [onClose, updateStatusForm]);

	if (!chapter) return null;

	return (
		<div className={styles.extra} ref={containerRef}>
			<div className={styles.wrapper}>
				<Text fontWeight={500} fontSize="14px" lineHeight="114%">
					Management Status
				</Text>
				<Popover
					isOpen={isOpen}
					onOpen={onOpen}
					onClose={onCloseStatusModal}
					placement="bottom-end"
					offset={[10, 10]}
				>
					<PopoverTrigger>
						<Button size="circle" variant="secondary">
							<PencilIcon w={6} h={6} />
						</Button>
					</PopoverTrigger>
					<Portal containerRef={containerRef}>
						<PopoverContent>
							<PopoverBody>
								<UpdateTagsForm
									submitButtonName={ButtonType.EDIT_CHAPTER_STATUS}
									formTitle="Management Status"
									onClose={onCloseStatusModal}
									options={statusesOptions}
									selectedOptions={updateStatusForm.values.managementStatus}
									onChange={(selected) =>
										updateStatusForm.setFieldValue(
											'managementStatus',
											selected,
											true,
										)
									}
									disableTags
									onSubmit={() =>
										onSubmitStatusModal(
											updateStatusForm.values.managementStatus,
										)
									}
									isManagementStatus
								/>
							</PopoverBody>
						</PopoverContent>
					</Portal>
				</Popover>
			</div>
			<div className={styles.tags}>
				{chapter.managementStatus?.map((elem: string) => (
					<Badge key={elem} variant={elem.toLowerCase()}>
						{FORMAT_STATUS_MAP[elem]}
					</Badge>
				)) || '---'}
			</div>
		</div>
	);
};
