/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
	Badge,
	Button,
	Flex,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Tab,
	TabList,
	Tabs,
	Text,
} from '@chakra-ui/react';

import { filterKeywordsTypes, KEYWORDS_FILTER_TYPES } from 'pages/art-catalog';
import {
	trackAssetFilterModalClose,
	trackAssetKeywordsModalClose,
} from 'shared/analytics/modals/close';
import {
	MODAL_TYPE_FILTER,
	MODAL_TYPE_KEYWORDS,
} from 'shared/analytics/modals/types';

import { FilterKeywords, KEYWORDS_MAP, KeywordsType } from '../constant';
import { KeywordsList } from '../keywords';

const TITLE_FILTER_MAP: Record<string, string> = {
	body: 'Bodies',
	clothes: 'Clothes',
	layer: 'Layers',
	hair: 'Hairs',
	background: 'Backgrounds',
	item: 'Items',
};

interface KeywordFilterProps {
	type: string;
	filterKeywords: FilterKeywords | null;
	filterKeywordsType?: filterKeywordsTypes;
	filterTypes?: filterKeywordsTypes[];
	setFilterKeywords: (v: FilterKeywords | null) => void;
	setFilterKeywordsType?: (v: filterKeywordsTypes) => void;
	isOpen: boolean;
	onClose: () => void;
	isFilter?: boolean;
}

const KeywordsModal: React.FC<KeywordFilterProps> = ({
	type,
	filterKeywords,
	filterKeywordsType,
	setFilterKeywords,
	setFilterKeywordsType,
	filterTypes,
	isOpen,
	onClose,
	isFilter,
}) => {
	const [selectedValues, setSelectedValues] = useState<FilterKeywords | null>(
		null,
	);
	const [selectedFilterType, setSelectedFilterType] =
		useState(filterKeywordsType);

	const keywordsKeys: string[] = Object.keys(
		KEYWORDS_MAP[type as keyof KeywordsType],
	);

	const countKeywords = (keywords: FilterKeywords | null) => {
		if (keywords)
			return Object.values(keywords)
				.flat()
				.filter((v) => v).length;

		return null;
	};

	const handleTabClick = (filterType: filterKeywordsTypes) =>
		setSelectedFilterType(filterType);

	const handleApplyClick = () => {
		if (selectedFilterType && setFilterKeywordsType)
			setFilterKeywordsType(selectedFilterType);

		setFilterKeywords(selectedValues);
		onClose();
		if (isFilter) {
			trackAssetFilterModalClose(
				MODAL_TYPE_FILTER[type as keyof typeof MODAL_TYPE_FILTER],
			);
		} else {
			trackAssetKeywordsModalClose(
				MODAL_TYPE_KEYWORDS[type as keyof typeof MODAL_TYPE_KEYWORDS],
			);
		}
	};

	const handleResetClick = () => {
		if (isFilter) {
			setFilterKeywords(null);
			onClose();
			trackAssetFilterModalClose(
				MODAL_TYPE_FILTER[type as keyof typeof MODAL_TYPE_FILTER],
			);
		}
		setSelectedValues(null);
	};

	useEffect(() => {
		setSelectedValues(filterKeywords);
	}, [filterKeywords]);

	useEffect(() => {
		setSelectedFilterType(filterKeywordsType);
	}, [filterKeywordsType, setFilterKeywordsType]);

	return (
		<Modal
			isOpen={isOpen}
			onClose={() => {
				onClose();
				if (isFilter) {
					trackAssetFilterModalClose(
						MODAL_TYPE_FILTER[type as keyof typeof MODAL_TYPE_FILTER],
					);
				} else {
					trackAssetKeywordsModalClose(
						MODAL_TYPE_KEYWORDS[type as keyof typeof MODAL_TYPE_KEYWORDS],
					);
				}
			}}
		>
			<ModalOverlay />
			<ModalContent minW="500px" h="calc(100vh - 70px)" overflowY="auto">
				<ModalHeader p="22px 24px" borderBottom="1px solid #EEEEF2">
					<Flex alignItems="center" justifyContent="space-between">
						<Flex alignItems="center">
							<Text mr="8px">
								{TITLE_FILTER_MAP[type]} {isFilter ? 'Filter' : 'Keywords'}
							</Text>
							{countKeywords(selectedValues) ? (
								<Badge
									w="18px"
									h="18px"
									backgroundColor="#644DED"
									color="#ffffff"
									borderRadius="50%"
									display="flex"
									alignItems="center"
									justifyContent="center"
								>
									{countKeywords(selectedValues)}
								</Badge>
							) : null}
						</Flex>
						<ModalCloseButton pos="relative" top="0" right="0" />
					</Flex>
				</ModalHeader>
				<ModalBody overflowY="auto">
					{filterTypes?.length && isFilter ? (
						<Flex
							p="16px 0"
							flexDirection="column"
							borderBottom="1px solid #EEEEF2"
						>
							<Text pb="16px">Matching Criteria</Text>
							<Tabs
								variant="rounded"
								defaultIndex={filterTypes.indexOf(
									(filterKeywordsType as filterKeywordsTypes) ||
										KEYWORDS_FILTER_TYPES.HAS_ALL_OF,
								)}
							>
								<TabList gap="5px">
									{filterTypes?.map((filterType) => (
										<Tab onClick={() => handleTabClick(filterType)}>
											{filterType}
										</Tab>
									))}
								</TabList>
							</Tabs>
						</Flex>
					) : null}
					<Flex flexDirection="column">
						{keywordsKeys.map((keywordsKey: string) => (
							<KeywordsList
								keywordsKey={keywordsKey}
								type={type}
								filterKeywords={selectedValues}
								setFilterKeywords={setSelectedValues}
							/>
						))}
					</Flex>
				</ModalBody>
				<ModalFooter>
					<Flex justifyContent="flex-end" gap="8px">
						<Button
							type="button"
							size="md"
							variant="secondary"
							color={countKeywords(selectedValues) ? '#484964' : '#808192'}
							onClick={handleResetClick}
						>
							Reset All
						</Button>
						<Button
							type="button"
							size="md"
							variant="secondary"
							color="#808192"
							onClick={() => {
								onClose();
								if (isFilter) {
									trackAssetFilterModalClose(
										MODAL_TYPE_FILTER[type as keyof typeof MODAL_TYPE_FILTER],
									);
								} else {
									trackAssetKeywordsModalClose(
										MODAL_TYPE_KEYWORDS[
											type as keyof typeof MODAL_TYPE_KEYWORDS
										],
									);
								}
							}}
						>
							Cancel
						</Button>
						<Button
							type="button"
							size="md"
							variant="secondary"
							onClick={handleApplyClick}
						>
							Apply
						</Button>
					</Flex>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

export default KeywordsModal;
