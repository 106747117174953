import React from 'react';
import { createIcon } from '@chakra-ui/react';

export const DeleteIcon = createIcon({
	viewBox: '0 0 14 14',
	path: (
		<>
			<circle cx="7" cy="7" r="7" fill="#808192" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M9.48575 5.27742C9.6732 5.08997 9.6732 4.78605 9.48575 4.5986C9.2983 4.41114 8.99438 4.41114 8.80692 4.5986L7.08435 6.32117L5.36177 4.5986C5.17432 4.41114 4.8704 4.41114 4.68295 4.5986C4.4955 4.78605 4.4955 5.08997 4.68295 5.27742L6.40552 7L4.68295 8.72257C4.4955 8.91003 4.4955 9.21395 4.68295 9.4014C4.8704 9.58885 5.17432 9.58885 5.36177 9.4014L7.08435 7.67882L8.80692 9.4014C8.99438 9.58885 9.2983 9.58885 9.48575 9.4014C9.6732 9.21395 9.6732 8.91003 9.48575 8.72257L7.76317 7L9.48575 5.27742Z"
				fill="#808192"
				stroke="white"
			/>
		</>
	),
});
