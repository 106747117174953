import * as Types from '../../../models.gen';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ChangeBackgroundAssignsMutationVariables = Types.Exact<{
  dto: Types.ChangeBackgroundsAssignsDto;
}>;


export type ChangeBackgroundAssignsMutation = { changeBackgroundAssigns: boolean };

export type ChangeItemAssignsMutationVariables = Types.Exact<{
  dto: Types.ChangeItemAssignsDto;
}>;


export type ChangeItemAssignsMutation = { changeItemAssigns: boolean };


export const ChangeBackgroundAssignsDocument = gql`
    mutation ChangeBackgroundAssigns($dto: ChangeBackgroundsAssignsDto!) {
  changeBackgroundAssigns(chapter: $dto)
}
    `;
export type ChangeBackgroundAssignsMutationFn = Apollo.MutationFunction<ChangeBackgroundAssignsMutation, ChangeBackgroundAssignsMutationVariables>;

/**
 * __useChangeBackgroundAssignsMutation__
 *
 * To run a mutation, you first call `useChangeBackgroundAssignsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeBackgroundAssignsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeBackgroundAssignsMutation, { data, loading, error }] = useChangeBackgroundAssignsMutation({
 *   variables: {
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useChangeBackgroundAssignsMutation(baseOptions?: Apollo.MutationHookOptions<ChangeBackgroundAssignsMutation, ChangeBackgroundAssignsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeBackgroundAssignsMutation, ChangeBackgroundAssignsMutationVariables>(ChangeBackgroundAssignsDocument, options);
      }
export type ChangeBackgroundAssignsMutationHookResult = ReturnType<typeof useChangeBackgroundAssignsMutation>;
export type ChangeBackgroundAssignsMutationResult = Apollo.MutationResult<ChangeBackgroundAssignsMutation>;
export type ChangeBackgroundAssignsMutationOptions = Apollo.BaseMutationOptions<ChangeBackgroundAssignsMutation, ChangeBackgroundAssignsMutationVariables>;
export const ChangeItemAssignsDocument = gql`
    mutation ChangeItemAssigns($dto: ChangeItemAssignsDto!) {
  changeItemAssigns(chapter: $dto)
}
    `;
export type ChangeItemAssignsMutationFn = Apollo.MutationFunction<ChangeItemAssignsMutation, ChangeItemAssignsMutationVariables>;

/**
 * __useChangeItemAssignsMutation__
 *
 * To run a mutation, you first call `useChangeItemAssignsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeItemAssignsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeItemAssignsMutation, { data, loading, error }] = useChangeItemAssignsMutation({
 *   variables: {
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useChangeItemAssignsMutation(baseOptions?: Apollo.MutationHookOptions<ChangeItemAssignsMutation, ChangeItemAssignsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeItemAssignsMutation, ChangeItemAssignsMutationVariables>(ChangeItemAssignsDocument, options);
      }
export type ChangeItemAssignsMutationHookResult = ReturnType<typeof useChangeItemAssignsMutation>;
export type ChangeItemAssignsMutationResult = Apollo.MutationResult<ChangeItemAssignsMutation>;
export type ChangeItemAssignsMutationOptions = Apollo.BaseMutationOptions<ChangeItemAssignsMutation, ChangeItemAssignsMutationVariables>;