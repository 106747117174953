import React from 'react';
import { createIcon } from '@chakra-ui/react';

export const BoldTextIcon = createIcon({
	viewBox: '0 0 24 24',
	path: (
		<path
			d="M 15.304 11.795 C 16.23 11.993 16.976 12.455 17.538 13.185 C 18.1 13.9 18.382 14.72 18.382 15.647 C 18.382 16.983 17.91 18.048 16.968 18.837 C 16.041 19.612 14.742 20 13.071 20 L 5.618 20 L 5.618 4 L 12.82 4 C 14.446 4 15.715 4.372 16.627 5.117 C 17.553 5.86 18.017 6.871 18.017 8.148 C 18.017 9.091 17.766 9.872 17.265 10.496 C 16.779 11.119 16.126 11.552 15.304 11.795 Z M 9.516 10.473 L 12.068 10.473 C 12.706 10.473 13.192 10.336 13.526 10.063 C 13.877 9.774 14.051 9.356 14.051 8.81 C 14.051 8.261 13.877 7.844 13.526 7.556 C 13.192 7.267 12.706 7.123 12.068 7.123 L 9.516 7.123 L 9.516 10.473 Z M 12.387 16.854 C 13.04 16.854 13.542 16.709 13.892 16.421 C 14.256 16.117 14.438 15.684 14.438 15.123 C 14.438 14.561 14.248 14.119 13.869 13.801 C 13.504 13.482 12.995 13.323 12.342 13.323 L 9.516 13.323 L 9.516 16.854 L 12.387 16.854 Z"
			fill="currentColor"
		/>
	),
});
