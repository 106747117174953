import React, { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { userModel } from 'entities/user';

export const AuthSuccess = () => {
	const location = useLocation();

	useEffect(() => {
		const params = new URLSearchParams(location.search);

		userModel.setAccessToken(params.get('accessToken'));
		userModel.setRefreshToken(params.get('refreshToken'));
	}, [location]);

	return <Navigate to="/stories" />;
};
