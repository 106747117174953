import { useEffect, useState } from 'react';

export const useAudioPlayer = (audioRef: React.RefObject<HTMLAudioElement>) => {
	const [duration, setDuration] = useState(0);
	const [currentTime, setCurrentTime] = useState(0);
	const [isPlaying, setIsPlaying] = useState(false);
	const [clickedTime, setClickedTime] = useState(0);

	useEffect(() => {
		const audio = audioRef.current;

		const setAudioData = () => {
			if (audio) {
				setDuration(audio.duration);
				setCurrentTime(audio.currentTime);
			}
		};

		const setAudioTime = () => {
			if (audio) {
				setCurrentTime(audioRef.current.currentTime);
			}
		};

		if (audio) {
			audio.addEventListener('loadeddata', setAudioData);
			audio.addEventListener('timeupdate', setAudioTime);

			if (isPlaying) {
				audio.play();
			} else {
				audio.pause();
			}

			if (clickedTime && clickedTime !== currentTime) {
				audio.currentTime = clickedTime;
				setClickedTime(0);
			}
		}

		return () => {
			if (audio) {
				audio.removeEventListener('loadeddata', setAudioData);
				audio.removeEventListener('timeupdate', setAudioTime);
			}
		};
	}, [clickedTime, currentTime, isPlaying, audioRef, duration]);

	return {
		currentTime,
		duration,
		isPlaying,
		setIsPlaying,
		setClickedTime,
	};
};
