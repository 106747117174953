import React from 'react';
import { createIcon } from '@chakra-ui/react';

export const ArrowRightSmallIcon = createIcon({
	viewBox: '0 0 24 24',
	path: (
		<path
			d="M10 16L14 12L10 8"
			stroke="#484964"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
	),
});
