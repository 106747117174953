import { useMemo } from 'react';

import { useGetBookCustomIdQuery } from 'features/book/queries.gen';
import {
	useGetChapterByIdQuery,
	useGetChapterDocsQuery,
} from 'features/chapter/update/queries.gen';

export const useGetParamsInfo = (
	chapterId: string,
	bookId: string,
	googleDocId: string,
) => {
	const { data: bookData } = useGetBookCustomIdQuery({
		variables: { id: bookId },
	});
	const { data: chapterData } = useGetChapterByIdQuery({
		variables: { id: chapterId },
	});
	const { data: docsData } = useGetChapterDocsQuery({
		variables: { id: chapterId },
	});

	const docType: string[] = Object.entries(docsData?.chapter || {}).reduce<
		string[]
	>((acc, [key, value]) => {
		if (value?.id === googleDocId) return [...acc, key];
		return acc;
	}, []);

	return useMemo(() => {
		return {
			bookCustomId: bookData?.book.customId,
			chapterOrder: chapterData?.chapter.chapterOrder,
			doc: docType[0],
		};
	}, [bookData?.book.customId, chapterData?.chapter.chapterOrder, docType]);
};
