import { EditorState, RichUtils } from 'draft-js';

import { DraftCommentData } from 'entities/script';
import {
	extractEntityKeyFromSelection,
	movePointerToEndOfSelection,
} from 'shared/utils';

export const addOrEditComment = (
	editorState: EditorState,
	commentText: string,
	picture: string,
	author?: string,
) => {
	const contentState = editorState.getCurrentContent();
	const selection = editorState.getSelection();

	if (selection.isCollapsed()) {
		return editorState;
	}

	const commentKey = extractEntityKeyFromSelection(contentState, selection);

	if (commentKey) {
		// * edit existing comment
		editorState.getCurrentContent().mergeEntityData(commentKey, {
			comment: commentText,
		});

		const newSelection = movePointerToEndOfSelection(selection);

		return EditorState.forceSelection(editorState, newSelection);
	}

	// * add  comment
	const contentStateWithEntity = contentState.createEntity(
		'COMMENT',
		'MUTABLE',
		{
			comment: commentText,
			picture,
			author: author || 'Unknown author',
			resolved: false,
			createdAt: Date.now(),
			replies: [],
		} as DraftCommentData,
	);

	const newCommentKey = contentStateWithEntity.getLastCreatedEntityKey();
	const newEditorState = EditorState.set(editorState, {
		currentContent: contentStateWithEntity,
	});

	const editorStateWithComment = RichUtils.toggleLink(
		newEditorState,
		newEditorState.getSelection(),
		newCommentKey,
	);

	const newSelection = movePointerToEndOfSelection(selection);

	return EditorState.forceSelection(editorStateWithComment, newSelection);
};
