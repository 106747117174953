import React from 'react';
import { Button, Flex } from '@chakra-ui/react';

import { ButtonType } from 'shared/analytics';

interface ActionButtonsProps {
	onApply: () => void;
	onCancel: () => void;
	disabled?: boolean;
}

export const ActionButtons: React.FC<ActionButtonsProps> = ({
	onApply,
	onCancel,
	disabled,
}) => (
	<Flex
		w="full"
		alignItems="center"
		justifyContent="flex-end"
		px="30px"
		pt="10px"
	>
		<Button variant="ghost" disabled={disabled} onClick={onCancel}>
			Cancel
		</Button>
		<Button
			name={ButtonType.CREATE_ASSET}
			ml="10px"
			disabled={disabled}
			onClick={onApply}
		>
			Save
		</Button>
	</Flex>
);
