import compose from 'compose-function';

import { withAnalytics } from './with-analytics';
import { withApollo } from './with-apollo';
import { withChakra } from './with-chakra';
import { withRouter } from './with-router';

export const withProviders = compose(
	withAnalytics,
	withChakra,
	withApollo,
	withRouter,
);
