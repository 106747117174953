import { ChapterByIdQuery } from 'features/story-editor/header/queries.gen';

export const findProdDoc = (
	docId: string | undefined,
	chapterData: ChapterByIdQuery | undefined,
) =>
	docId === chapterData?.chapter?.googleDoc?.id ||
	docId === chapterData?.chapter?.googleDocDE?.id ||
	docId === chapterData?.chapter?.googleDocES?.id ||
	docId === chapterData?.chapter?.googleDocFR?.id ||
	docId === chapterData?.chapter?.googleDocPT?.id ||
	docId === chapterData?.chapter?.googleDocNL?.id ||
	docId === chapterData?.chapter?.googleDocIT?.id ||
	docId === chapterData?.chapter?.googleDocPL?.id ||
	docId === chapterData?.chapter?.googleDocSV?.id ||
	docId === chapterData?.chapter?.googleDocDA?.id ||
	docId === chapterData?.chapter?.googleDocNO?.id ||
	docId === chapterData?.chapter?.googleDocFI?.id ||
	docId === chapterData?.chapter?.googleDocID?.id ||
	docId === chapterData?.chapter?.googleDocCS?.id ||
	docId === chapterData?.chapter?.googleDocTR?.id ||
	docId === chapterData?.chapter?.googleDocRO?.id ||
	docId === chapterData?.chapter?.googleDocHU?.id ||
	docId === chapterData?.chapter?.googleDocJA?.id ||
	docId === chapterData?.chapter?.googleDocKO?.id ||
	docId === chapterData?.chapter?.googleDocPT_BR?.id ||
	docId === chapterData?.chapter?.googleDoc2Pov?.id ||
	docId === chapterData?.chapter?.googleDoc2PovDE?.id ||
	docId === chapterData?.chapter?.googleDoc2PovES?.id ||
	docId === chapterData?.chapter?.googleDoc2PovFR?.id ||
	docId === chapterData?.chapter?.googleDoc2PovNL?.id ||
	docId === chapterData?.chapter?.googleDoc2PovPT?.id ||
	docId === chapterData?.chapter?.googleDoc2PovIT?.id ||
	docId === chapterData?.chapter?.googleDoc2PovPL?.id ||
	docId === chapterData?.chapter?.googleDoc2PovSV?.id ||
	docId === chapterData?.chapter?.googleDoc2PovDA?.id ||
	docId === chapterData?.chapter?.googleDoc2PovNO?.id ||
	docId === chapterData?.chapter?.googleDoc2PovFI?.id ||
	docId === chapterData?.chapter?.googleDoc2PovID?.id ||
	docId === chapterData?.chapter?.googleDoc2PovCS?.id ||
	docId === chapterData?.chapter?.googleDoc2PovRO?.id ||
	docId === chapterData?.chapter?.googleDoc2PovHU?.id ||
	docId === chapterData?.chapter?.googleDoc2PovTR?.id ||
	docId === chapterData?.chapter?.googleDoc2PovJA?.id ||
	docId === chapterData?.chapter?.googleDoc2PovKO?.id ||
	docId === chapterData?.chapter?.googleDoc2PovPT_BR?.id ||
	docId === chapterData?.chapter?.googleDocABTest1?.id ||
	docId === chapterData?.chapter?.googleDocABTest2?.id ||
	docId === chapterData?.chapter?.googleDocABTest3?.id ||
	docId === chapterData?.chapter?.googleDoc2Pov?.id;
