import React from 'react';
import { Flex, IconButton } from '@chakra-ui/react';

import { ArrowLeftIcon, CrossSmallIcon } from 'shared/icons';
import { SearchInput } from 'shared/ui';
import { ActivePage } from 'widgets/existing-heroes-modal/constant/activePage';

interface HeaderProps {
	activePage: string;
	setActivePage: (v: string) => void;
	searchHero: string;
	setSearchHero: (v: string) => void;
	onCloseModal: () => void;
}

const Header: React.FC<HeaderProps> = ({
	activePage,
	setActivePage,
	searchHero,
	setSearchHero,
	onCloseModal,
}) => {
	return (
		<Flex
			alignItems="center"
			justifyContent="space-between"
			gap={5}
			px={6}
			py={4}
			height="78px"
			borderBottom="1px solid #eeeef2"
		>
			{activePage === ActivePage.CHOOSE_HEROES ||
			activePage === ActivePage.KTO_OTEC ? (
				<SearchInput
					search={searchHero}
					setSearch={setSearchHero}
					placeholder="Search by name or id"
				/>
			) : (
				<Flex
					alignItems="center"
					gap={2}
					fontSize="18px"
					fontWeight="700"
					lineHeight="24px"
				>
					<IconButton
						aria-label="Close Modal"
						variant="ghost"
						size="square"
						icon={<ArrowLeftIcon w={5} h={5} />}
						onClick={() => setActivePage('Choose Heroes')}
					/>
					Choose Chapters
				</Flex>
			)}
			<IconButton
				aria-label="Close Modal"
				variant="ghost"
				size="square"
				icon={<CrossSmallIcon w={5} h={5} />}
				onClick={onCloseModal}
			/>
		</Flex>
	);
};

export default Header;
