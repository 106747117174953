import * as yup from 'yup';

import { spaceValidation } from '../yup-validation';

export const updateBodySchema = yup.object({
	id: yup.string().trim().required(),
	link: spaceValidation.url().nullable(),
	filename: spaceValidation.min(5).max(255).nullable(),
	dropboxLink: spaceValidation,
	emotions: yup.array().of(spaceValidation.required()),
	keywords: yup.array().of(yup.string().trim().required()),
	newKeywords: yup
		.object()
		.shape({
			sex: yup.array().nullable(),
			app: yup.array().nullable(),
			art_style: yup.array().nullable(),
			body_type: yup.array().nullable(),
			skintone: yup.array().nullable(),
			age: yup.array().nullable(),
			extra: yup.array().nullable(),
			not_human: yup.array().nullable(),
		})
		.nullable(),
});

export type UpdateBodyValues = yup.InferType<typeof updateBodySchema>;
