import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { NetworkStatus } from '@apollo/client';
import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	Box,
	Button,
	HStack,
	Spinner,
	Table,
	TableContainer,
	Tbody,
	Text,
	Th,
	Thead,
	Tr,
	useDisclosure,
} from '@chakra-ui/react';

import { BookChaptersForAssetSelectQuery } from 'features/asset/chapters-asset-select/queries.gen';
import { useChangeItemAssignsMutation } from 'features/asset/stories-asset-tab/queries.gen';
import { GetBookInfoDocument } from 'features/book/queries.gen';
import {
	trackAssetDetailsModalClose,
	trackItemRemoveModalClose,
} from 'shared/analytics/modals/close';
import {
	trackAssetDetailsModalOpen,
	trackItemRemoveModalOpen,
} from 'shared/analytics/modals/open';
import { Modal } from 'shared/analytics/modals/types';
import { useAppToast } from 'shared/hooks/toast';
import { ArrayElement } from 'shared/model';
import { UpdateAssetModal } from 'widgets/update-asset-modal';

import {
	ItemsByStoryDocument,
	ItemsByStoryQuery,
	useDeleteItemFromStoryMutation,
	useItemsByStoryQuery,
} from '../graphql/queries.gen';
import { ItemListRow } from './list-row';

type ItemsListRouteParams = {
	readonly bookId: string;
};

export const ItemsList: React.FC = () => {
	const { bookId } = useParams<ItemsListRouteParams>();
	const [updatedItem, setUpdatedItem] = useState<ArrayElement<
		ItemsByStoryQuery['items']['items']
	> | null>(null);
	if (!bookId) throw Error();

	const toast = useAppToast();
	const cancelRef = useRef(null);

	const {
		isOpen: isOpenDeleteModal,
		onClose: onCloseDeleteModal,
		onOpen: onOpeDeleteModal,
	} = useDisclosure();

	const {
		isOpen: isOpenUpdateModal,
		onClose: onCloseUpdateModal,
		onOpen: onOpeUpdateModal,
	} = useDisclosure();

	const [deletedItem, setDeletedItem] = useState<ArrayElement<
		ItemsByStoryQuery['items']['items']
	> | null>(null);

	const { data, error, networkStatus, refetch } = useItemsByStoryQuery({
		variables: { filter: { bookId }, limit: 100 },
		fetchPolicy: 'network-only',
		nextFetchPolicy: 'network-only',
		notifyOnNetworkStatusChange: true,
	});

	const handleUpdateModalClose = useCallback(() => {
		onCloseUpdateModal();
		refetch();
	}, [onCloseUpdateModal, refetch]);

	const handleUpdateModalOpen = useCallback(
		(background) => {
			setUpdatedItem(background);
			onOpeUpdateModal();
			trackAssetDetailsModalOpen(Modal.ITEM_DETAILS);
		},
		[onOpeUpdateModal],
	);

	const [updateItemChapters, { loading: updateItemChaptersLoading }] =
		useChangeItemAssignsMutation({
			refetchQueries: [ItemsByStoryDocument, GetBookInfoDocument],
		});

	const [deleteItemFromStory, { loading: deleteItemLoading }] =
		useDeleteItemFromStoryMutation({
			refetchQueries: [
				{
					variables: { filter: { bookId }, limit: 100 },
					query: ItemsByStoryDocument,
				},
				GetBookInfoDocument,
			],
		});

	const handleUpdateItemChapters = useCallback(
		(item: ArrayElement<ItemsByStoryQuery['items']['items']>) =>
			async (chapters: BookChaptersForAssetSelectQuery['book']['chapters']) => {
				try {
					await updateItemChapters({
						variables: {
							dto: {
								itemId: item.id,
								chaptersIds: [
									...item.chapters
										.filter((chapter) => chapter.book?.id !== bookId)
										.map((chapter) => chapter.id),
									...(chapters || []).map((chapter) => chapter.id),
								],
								bookId,
							},
						},
					});
					if (chapters?.length === 0) {
						toast({
							title: `${item?.filename} was removed`,
							status: 'success',
						});
					}
				} catch (errors) {
					toast({
						title: `Error: Can't update chapters`,
						status: 'error',
					});
				}
			},
		[bookId, toast, updateItemChapters],
	);

	const handleDeleteItemModalClose = useCallback(() => {
		onCloseDeleteModal();
		setDeletedItem(null);
		trackItemRemoveModalClose();
	}, [onCloseDeleteModal]);

	const handleItemDeleteAttempt = useCallback(
		(item: ArrayElement<ItemsByStoryQuery['items']['items']>) => async () => {
			setDeletedItem(item);
			onOpeDeleteModal();
			trackItemRemoveModalOpen();
		},
		[onOpeDeleteModal],
	);

	const handleItemDeleteSubmit = useCallback(async () => {
		try {
			await deleteItemFromStory({
				variables: {
					chapter: {
						items: [deletedItem?.id || ''],
						chapterIds:
							deletedItem?.chapters.reduce((acc, chapter) => {
								if (chapter.book?.id === bookId) return [...acc, chapter.id];
								return acc;
							}, [] as string[]) || [],
					},
				},
			});
			handleDeleteItemModalClose();
			toast({
				title: `${deletedItem?.filename} was removed`,
				status: 'success',
			});
		} catch (errors) {
			toast({
				title: `Can't remove item`,
				status: 'error',
			});
		}
	}, [
		bookId,
		deleteItemFromStory,
		deletedItem?.chapters,
		deletedItem?.filename,
		deletedItem?.id,
		handleDeleteItemModalClose,
		toast,
	]);

	useEffect(() => {
		if (error?.message)
			toast({
				title: error?.message,
				status: 'error',
			});
	}, [error, toast]);

	useEffect(() => {
		if (updateItemChaptersLoading) {
			setTimeout(() => refetch(), 0);
		}
	}, [updateItemChaptersLoading, refetch]);

	if (networkStatus === NetworkStatus.loading) {
		return <Spinner size="xl" m="auto" />;
	}

	return (
		<>
			<Box
				d="flex"
				flex-direction="column"
				flex="1 1"
				overflow="hidden"
				h="calc(100vh - 195px)"
			>
				<Box d="flex" flex="1 1" overflow="auto">
					<TableContainer width="100%" overflowY="auto">
						<Table>
							<Thead position="sticky" left={0} top={0} zIndex={101}>
								<Tr>
									<Th position="sticky" left={0} zIndex={100}>
										<HStack>
											<Text
												textAlign="center"
												minW={7}
												mr={5}
												fontFamily="sans-serif"
											>
												№
											</Text>
											<Text minW="75px" mr={1}>
												Pic
											</Text>
											<Text minW="150px" noOfLines={1}>
												Item Name
											</Text>
										</HStack>
									</Th>
									<Th>Chapters</Th>
									<Th>Last Edited</Th>
									<Th />
								</Tr>
							</Thead>
							<Tbody>
								{data?.items.items.map((item, index) => (
									<ItemListRow
										key={item.id}
										index={index}
										item={item}
										currentBookId={bookId}
										handleUpdateItemChapters={handleUpdateItemChapters}
										handleItemDelete={handleItemDeleteAttempt}
										isLoading={updateItemChaptersLoading}
										updatedItem={updatedItem?.id as string}
										refetch={refetch}
										handleUpdateModalOpen={() => handleUpdateModalOpen(item)}
									/>
								))}
							</Tbody>
						</Table>
					</TableContainer>
				</Box>
			</Box>
			{isOpenDeleteModal && (
				<AlertDialog
					isOpen={isOpenDeleteModal}
					leastDestructiveRef={cancelRef}
					onClose={handleDeleteItemModalClose}
				>
					<AlertDialogOverlay>
						<AlertDialogContent>
							<AlertDialogHeader fontSize="lg" fontWeight="bold">
								Item
							</AlertDialogHeader>

							<AlertDialogBody>
								If you delete item from the story, it can always be returned.
								Are you sure you want to continue?
							</AlertDialogBody>

							<AlertDialogFooter>
								{!deleteItemLoading && (
									<Button
										ref={cancelRef}
										variant="ghost"
										onClick={handleDeleteItemModalClose}
									>
										Cancel
									</Button>
								)}
								<Button
									onClick={handleItemDeleteSubmit}
									ml={3}
									isLoading={deleteItemLoading}
								>
									Delete
								</Button>
							</AlertDialogFooter>
						</AlertDialogContent>
					</AlertDialogOverlay>
				</AlertDialog>
			)}
			{isOpenUpdateModal && updatedItem && (
				<UpdateAssetModal
					isOpen={isOpenUpdateModal}
					onClose={() => {
						handleUpdateModalClose();
						trackAssetDetailsModalClose(Modal.ITEM_DETAILS);
					}}
					asset={updatedItem}
					afterDelete={refetch}
				/>
			)}
		</>
	);
};
