import React from 'react';
import { createIcon } from '@chakra-ui/react';

export const FrIcon = createIcon({
	viewBox: '0 0 13 15',
	path: (
		<path
			d="M6.69161 14.058C9.9364 14.058 12.0766 12.5252 12.0766 9.51221C12.0766 5.42554 7.68078 4.96732 7.67078 1.3425C7.67078 1.33614 7.66532 1.33159 7.65896 1.33341C6.19157 1.7989 4.24779 4.5182 5.71336 6.78473C6.20248 7.69389 5.22241 8.60305 4.24416 8.60305C1.93489 8.60305 2.28583 5.87557 1.7967 6.78473C-0.0125285 10.1432 2.21491 14.058 6.69161 14.058Z"
			stroke="currentColor"
			fillOpacity="0"
			strokeWidth="1.4"
		/>
	),
});
