import { useChaptersByStoryQuery } from './queries.gen';

export type Chapter = {
	id: string;
	name: string;
	chapterOrder: number;
	bookName?: string;
};

export const useChapters = (bookId: string) => {
	const {
		data,
		loading: loadingChapters,
		error,
	} = useChaptersByStoryQuery({
		variables: {
			id: bookId,
		},
	});

	const chapters: Chapter[] =
		[...(data?.book?.chapters || [])]
			?.sort((a, b): number => {
				return (a?.chapterOrder || 0) - (b?.chapterOrder || 0);
			})
			.map((chapter) => {
				return {
					id: chapter?.id || '',
					name: chapter?.name || '',
					chapterOrder: chapter?.chapterOrder || 0,
					bookName: data?.book?.name || '',
				};
			}) || [];

	return { chapters, loadingChapters, error };
};
