import React, {
	RefObject,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import {
	Box,
	Button,
	ButtonGroup,
	Flex,
	Grid,
	GridItem,
	Heading,
	IconButton,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Modal,
	ModalContent,
	ModalFooter,
	ModalOverlay,
	Spinner,
	Tab,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
	Text,
	Tooltip,
	useDisclosure,
	VStack,
} from '@chakra-ui/react';
import { useFormik } from 'formik';

import { AssetBook } from 'entities/assets/interfaces/asset';
import { scriptModel } from 'entities/script';
import { uiModel } from 'entities/ui';
import { AddHeroAssetsModal } from 'features/asset/add-assets-modal';
import CloseAlert from 'features/editor/close-alert';
import { DeleteHeroAlert, StoriesHeroTab } from 'features/hero';
import { AssetsTab } from 'features/hero/assets-tab';
import { RenderHero } from 'features/hero/assets-tab/components/render-hero';
import { ButtonType, trackHeroDeleted } from 'shared/analytics';
import { trackDeleteHeroModalClose } from 'shared/analytics/modals/close';
import { trackDeleteHeroModalOpen } from 'shared/analytics/modals/open';
import { usePrompt } from 'shared/hooks/prompt';
import { useAppToast } from 'shared/hooks/toast';
import { CrossIcon, MoreIcon, WarningIcon } from 'shared/icons';
import { ArrayElement } from 'shared/model';
import { PermissionGuard } from 'shared/utils';
import { HeroesQuery } from 'widgets/existing-heroes-modal/graphql/queries.gen';
import { Permission } from 'models.gen';

import { ChapterHeroesDocument } from '../../hero-list/graphql/queries.gen';
import {
	useDeleteHeroMutation,
	useHeroQuery,
	useUpdateHeroBooksMutation,
} from '../graphql/queries.gen';
import { updateHeroSchema } from '../schema';
import { GeneralTab } from './general-tab';

export interface AssetInfo {
	bodyId: string;
	clothesId: string;
	hairsId: string;
	layers: string[];
}

interface HeroUpdateModalProps {
	heroId: string;
	setHeroId: any;
	isOpen: boolean;
	onClose: () => void;
	refetch?: () => void;
}

interface UpdateHeroFormikParams {
	names: string[];
	keywords: any[];
	isFamiliar: boolean;
	books: AssetBook[];
	bodies: string[];
	clothes: string[];
	hairs: { id: string; name: string }[];
	layers: string[];
	parent: ArrayElement<HeroesQuery['heroes']> | null;
	defaultClothesId?: string;
}

export const HeroUpdateModal: React.FC<HeroUpdateModalProps> = ({
	heroId,
	setHeroId,
	isOpen,
	onClose,
	refetch,
}) => {
	const [isRedacting, setIsRedacting] = useState(false);
	const elGeneralTab = useRef<HTMLDivElement>(null);
	const elAssetsTab = useRef<HTMLDivElement>(null);
	const elStoriesTab = useRef<HTMLDivElement>(null);

	const {
		isOpen: isOpenAssetModal,
		onOpen: onOpenAssetModal,
		onClose: onCloseAssetModal,
	} = useDisclosure();

	const toast = useAppToast();

	const [assetType, setAssetType] = useState('');
	const [bookId, setBookId] = useState('');
	const [currentAssetInfo, setCurrentAssetInfo] = useState<AssetInfo>({
		bodyId: '',
		clothesId: '',
		hairsId: '',
		layers: [],
	});

	const {
		isOpen: isOpenDeleteModal,
		onClose: onCloseDeleteModal,
		onOpen: onOpenDeleteModal,
	} = useDisclosure();

	const [updateHero, { loading: loadingUpdate }] = useUpdateHeroBooksMutation({
		onCompleted: () =>
			toast({
				title: 'Hero updated successfully',
				status: 'success',
			}),
		onError: () =>
			toast({
				title: 'Hero was not updated',
				status: 'error',
			}),
		refetchQueries: [ChapterHeroesDocument],
	});
	const { data, loading } = useHeroQuery({ variables: { id: heroId } });

	const [deleteHero] = useDeleteHeroMutation({
		variables: {
			heroId,
		},
	});

	const formikInitialValues = useMemo(() => {
		const {
			names,
			keywords,
			isFamiliar = false,
			parent,
			chapters,
			bodies,
			clothes,
			hairs,
			layers,
			defaultClothes,
		} = {
			...(data?.hero || {}),
		};

		const books = Object.values(
			chapters?.reduce((acc, chapter) => {
				if (!chapter.book?.id) return acc;
				if (!acc[chapter.book.id])
					return {
						...acc,
						[chapter.book.id]: {
							...chapter.book,
							chapters: [
								{
									id: chapter.id,
									name: chapter.name,
									chapterOrder: chapter.chapterOrder,
								},
							],
						} as AssetBook,
					};

				return {
					...acc,
					[chapter.book.id]: {
						...acc[chapter.book.id],
						chapters: [...acc[chapter.book.id].chapters, chapter],
					} as AssetBook,
				};
			}, {} as Record<string, AssetBook>) || {},
		);

		return {
			names: names as string[],
			keywords: keywords as string[],
			isFamiliar,
			parent: parent || null, // null has to be sent to BE so parent is removed
			books,
			bodies: bodies?.map((body) => body.id as string) || [],
			clothes: clothes?.map((item) => item.id as string) || [],
			hairs:
				hairs?.map(
					(hair) =>
						({ id: hair.id, name: hair.name } as { id: string; name: string }),
				) || [],
			layers: layers?.map((layer) => layer.id as string) || [],
			defaultClothesId: defaultClothes?.id,
		} as UpdateHeroFormikParams;
	}, [data?.hero]);

	const formik = useFormik({
		initialValues: formikInitialValues,
		enableReinitialize: true,
		onSubmit: async ({
			names,
			keywords,
			isFamiliar,
			books,
			parent,
			bodies,
			clothes,
			hairs,
			layers,
			defaultClothesId,
		}) => {
			const chapters = books.reduce(
				(acc, book) => [...acc, ...book.chapters.map((chapter) => chapter.id)],
				[] as string[],
			);
			updateHero({
				variables: {
					hero: {
						id: heroId,
						names,
						keywords,
						isFamiliar,
						chapters,
						parentId: parent === null ? null : parent?.id,
						bodies,
						clothes,
						hairs: hairs.map((hair) => hair.id),
						layers,
						defaultClothesId,
					},
				},
			});
		},
		validateOnMount: true,
		validationSchema: updateHeroSchema,
	});

	const handleModalClose = useCallback(() => {
		onClose();
		setHeroId('');
		formik.resetForm();
	}, [formik, onClose, setHeroId]);

	const handleAlertModal = useCallback(() => {
		if (isRedacting) {
			uiModel.setIsCloseAlertShow(true);
			scriptModel.setRedirectAction(onClose);
		} else {
			handleModalClose();
		}
	}, [handleModalClose, isRedacting, onClose]);
	const handleSetFieldValue = useCallback(
		(field: string, value: any) => {
			setIsRedacting(true);
			formik.setFieldValue(field, value, true);
		},
		[formik],
	);

	const handleAddHeroBooksSubmit = useCallback(
		async (books: AssetBook[]) => {
			await formik.setFieldValue('books', books);
			setIsRedacting(true);
		},
		[formik],
	);

	const handleBookRemove = useCallback(
		async (id: string) => {
			await formik.setFieldValue(
				'books',
				formik.values.books.filter((book) => book.id !== id),
			);
		},
		[formik],
	);

	const handleHeroDeleteSubmit = useCallback(async () => {
		try {
			await deleteHero();
			trackHeroDeleted({
				customId: data?.hero.customId as number,
				names: data?.hero.names as string[],
				stories:
					(data?.hero.chapters?.map(
						(chapter) => chapter.book?.name,
					) as string[]) || [],
			});
			toast({
				title: `${data?.hero.names.join(', ')} was deleted`,
				status: 'success',
			});
			handleModalClose();
			if (refetch) refetch();
		} catch (error) {
			toast({
				title: "Error: Can't delete hero",
				status: 'error',
			});
		}
	}, [data?.hero, deleteHero, handleModalClose, refetch, toast]);

	const handleHeroUpdate = useCallback(async () => {
		try {
			await formik.submitForm();
		} finally {
			formik.resetForm();
			handleModalClose();
			if (refetch) setTimeout(() => refetch(), 500);
		}
	}, [formik, handleModalClose, refetch]);

	const handleScrollTop = useCallback((el: RefObject<HTMLDivElement>) => {
		if (el.current) el.current.scrollIntoView({ behavior: 'smooth' });
	}, []);

	useEffect(() => {
		const chapter = data?.hero.chapters[data.hero.chapters.length - 1];
		const book = chapter?.book;
		if (book) {
			setBookId(book.id || '');
		}
	}, [data?.hero.chapters]);

	useEffect(() => {
		setCurrentAssetInfo({
			bodyId: currentAssetInfo.bodyId || formik.values.bodies[0],
			clothesId: currentAssetInfo.clothesId || formik.values.clothes[0],
			hairsId: currentAssetInfo.hairsId || formik.values.hairs[0]?.id,
			layers: currentAssetInfo.layers,
		});
	}, [
		currentAssetInfo.bodyId,
		currentAssetInfo.clothesId,
		currentAssetInfo.hairsId,
		currentAssetInfo.layers,
		formik.values,
	]);

	useEffect(() => {
		setCurrentAssetInfo({
			bodyId: formik.initialValues.bodies[0],
			clothesId: formik.initialValues.clothes[0],
			hairsId: formik.initialValues.hairs[0]?.id,
			layers: [],
		});
	}, [formik.initialValues]);

	// useEffect(() => {
	// 	const handleBeforeUnload = (event: {
	// 		returnValue: string;
	// 		preventDefault: () => void;
	// 		// eslint-disable-next-line consistent-return
	// 	}) => {
	// 		if (isRedacting) {
	// 			event.preventDefault();
	// 			// eslint-disable-next-line no-param-reassign
	// 			event.returnValue = '';
	// 			return '';
	// 		}
	// 	};

	// 	window.addEventListener('beforeunload', handleBeforeUnload);

	// 	return () => {
	// 		window.removeEventListener('beforeunload', handleBeforeUnload);
	// 	};
	// }, [isRedacting]);

	usePrompt((tx) => {
		uiModel.setIsCloseAlertShow(true);
		scriptModel.setRedirectAction(() => {
			handleModalClose();
			tx.retry();
		});
	}, isRedacting);

	return (
		<>
			<Modal
				closeOnOverlayClick={false}
				isOpen={isOpen}
				onClose={handleAlertModal}
			>
				<ModalOverlay />
				<ModalContent
					h="100%"
					w="100%"
					maxW="calc(100vw - 138px)"
					maxH="calc(100vh - 30px)"
					m={0}
					overflow="hidden"
				>
					<Grid
						flex="1"
						gridTemplateColumns="1fr 2fr"
						gridTemplateRows="76px 1fr"
						templateAreas={`'heading form' 'hero form'`}
						borderBottom="1px solid #eeeef2"
						overflow="auto"
					>
						<GridItem area="heading" borderBottom="1px solid #eeeef2">
							<Text as={Heading} fontSize="18px !important" p="25px 24px 26px">
								Hero details
							</Text>
						</GridItem>
						<GridItem area="form" borderLeft="1px solid #eeeef2">
							<Tabs h="100%" d="flex" flexDir="column">
								<Flex>
									<TabList px="38px" h="76px" gap="40px">
										<Tab onClick={() => handleScrollTop(elGeneralTab)}>
											General
										</Tab>
										<Tab onClick={() => handleScrollTop(elAssetsTab)}>
											Assets
										</Tab>
										<Flex
											as={Tab}
											fontWeight="600"
											fontSize="16px"
											lineHeight="24px"
											color="#808192"
											_selected={{
												color: '#242533',
												outline: 'none',
												borderBottom: 'none',
												boxShadow: 'inset 0px -2px 0px #242533',
											}}
											align="center"
											gap="4px"
											onClick={() => handleScrollTop(elStoriesTab)}
										>
											Stories
											{!formik.values.books.length && (
												<WarningIcon color="#ED0000" />
											)}
										</Flex>
									</TabList>
									<ButtonGroup
										paddingRight={38}
										spacing={15}
										alignItems="center"
										justifyContent="flex-end"
										flex="1"
										borderBottom="2px solid #E2E8F0"
										color="#ABACBE"
									>
										<PermissionGuard permissions={[Permission.DeleteHeroes]}>
											<Menu>
												<Tooltip label="More">
													<MenuButton
														as={IconButton}
														variant="ghost"
														borderRadius="full"
														aria-label="more"
														icon={<MoreIcon w={6} h={6} />}
													/>
												</Tooltip>

												<MenuList>
													<MenuItem
														onClick={() => {
															onOpenDeleteModal();
															trackDeleteHeroModalOpen();
														}}
													>
														Delete hero
													</MenuItem>
												</MenuList>
											</Menu>
										</PermissionGuard>
										<Box background="#E2E8F0" w="1px" h="24px" />
										<IconButton
											borderRadius="full"
											variant="ghost"
											size="md"
											aria-label="close-modal"
											onClick={handleAlertModal}
										>
											<CrossIcon />
										</IconButton>
									</ButtonGroup>
								</Flex>
								{loading ? (
									<Spinner size="md" />
								) : (
									<VStack
										align="stretch"
										as={TabPanels}
										flex="1"
										overflow="auto"
										overflowX="hidden"
									>
										<TabPanel p="16px 40px 16px 38px" ref={elGeneralTab}>
											<GeneralTab
												bookId={bookId}
												customId={data?.hero.customId || ''}
												heroId={heroId}
												updatedAt={data?.hero.updatedAt || ''}
												handleSetFieldValue={handleSetFieldValue}
												{...formik.values}
											/>
										</TabPanel>
										<TabPanel p="16px 38px" ref={elAssetsTab}>
											<AssetsTab
												defaultClothesId={formik.values.defaultClothesId}
												setAssetType={setAssetType}
												setCurrentAssetInfo={setCurrentAssetInfo}
												currentAssetInfo={currentAssetInfo}
												bodies={formik.values.bodies}
												clothes={formik.values.clothes}
												hairs={formik.values.hairs}
												layers={formik.values.layers}
												openAddModal={onOpenAssetModal}
												handleSetFieldValue={handleSetFieldValue}
											/>
										</TabPanel>
										<TabPanel flex="1" ref={elStoriesTab}>
											<StoriesHeroTab
												books={formik.values.books}
												handleBookRemove={handleBookRemove}
												handleAddHeroBooksSubmit={handleAddHeroBooksSubmit}
												handleSetFieldValue={handleSetFieldValue}
											/>
										</TabPanel>
									</VStack>
								)}
							</Tabs>
						</GridItem>
						<GridItem area="hero" overflow="hidden" position="relative">
							<RenderHero
								body={currentAssetInfo.bodyId || ''}
								clothes={currentAssetInfo.clothesId || ''}
								layers={currentAssetInfo.layers || ''}
								hairs={currentAssetInfo.hairsId || ''}
							/>
						</GridItem>
					</Grid>
					<ModalFooter>
						<Button variant="ghost" mr={3} onClick={handleAlertModal}>
							Cancel
						</Button>
						<Button
							name={ButtonType.EDIT_CHARACTER}
							disabled={!formik.isValid || loadingUpdate}
							onClick={handleHeroUpdate}
						>
							Save
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
			{isOpenDeleteModal && (
				<DeleteHeroAlert
					isOpen={isOpenDeleteModal}
					onClose={() => {
						onCloseDeleteModal();
						trackDeleteHeroModalClose();
					}}
					onSubmit={handleHeroDeleteSubmit}
				/>
			)}
			{isOpenAssetModal && (
				<AddHeroAssetsModal
					heroId={heroId}
					isOpen={isOpenAssetModal}
					onClose={onCloseAssetModal}
					type={assetType}
					handleSetFieldValue={handleSetFieldValue}
					{...formik.values}
				/>
			)}
			<CloseAlert />
		</>
	);
};
