import shortid from 'shortid';

export enum Snippets {
	IMPOTENT = '#imp',
	SELECTION = '#se',
	CHANGE_OUTFIT = '#cha',
	CHANGE_HAIR = '#nh',
	BACKGROUND_NAME = '#backg',
	ADD_LAYER = '#ad',
	DELETE_LAYER = '#de',
	FREE_CHOICE = '#free',
	PAID_CHOICE = '#paid',
	UPGRADE = '#upgrade',
	LUXURY = '#lux',
	LUXURY2 = '#lux2',
	ITEM = '#item',
	ITEM_BRANCH = '#itembranch',
	REF = '#ref',
	TIME = '#time',
	ROLL_BODY = '#rollb',
	ROLL_CLOTH = '#rollc',
	ROLL_HAIR = '#rollh',
	REM = '#rem',
	OUTFIT = '#outfit',
	PLACEHOLDER_CLOTHES = '#po',
	HAIR = '#hair',
	PLACEHOLDER_HAIR = '#ph',
	LAYER = '#lay',
	PAID_REM = '#paidrem',
	TAGS = '#tags',
	INCOMING_MESSAGE = '#incomingMessage',
	OUTGOING_MESSAGE = '#outgoingMessage',
	GROUP_CHAT = '#groupChat',
	KEEP_GROUP_CHAT = '#keepGroupChat',
	LINK = '#link',
	FIRST_PAID = '#firstpaid',
	FIRST_TIME = '#firsttime',
	FREE_REM = '#freerem',
	FIRST_FREE_REM = '#firstfreerem',
	FIRST_CUSTOMIZATION = '#firstCustomization',
	SHOW = '#show',
	ADD = '#addeff',
	DEL = '#deleff',
	BACKGROUND_NAME_NO_TRANSITION = '#backgno',
	EFFECT = 'effect=',

	// INCOMING_CALL = '#incomingCall',
	// OUTGOING_CALL = '#outgoingCall',
	// RETURN_TO_CALL = '#returnToCall',
	// INCOMING_VIDEO_CALL = '#incomingVideocall',
	// OUTGOING_VIDEO_CALL = '#outgoingVideocall',
	// RETURN_TO_VIDEO_CALL = '#returnToVideoCall',
	// INCOMING_SEXY_VIDEO_CALL = '#incomingSexyVideocall',
	// OUTGOING_SEXY_VIDEO_CALL = '#outgoingSexyVideocall',
	// RETURN_TO_SEXY_VIDEO_CALL = '#returnToSexyVideoCall',
}

const SNIPPETS_EXPANDED = {
	[Snippets.IMPOTENT]: '#important-',
	[Snippets.SELECTION]: '#selection-hero-outfit',
	[Snippets.CHANGE_OUTFIT]: '#change-hero-outfit',
	[Snippets.CHANGE_HAIR]: '#changehair-hero-hairID',
	[Snippets.BACKGROUND_NAME]: 'backgroundName',
	[Snippets.ADD_LAYER]: '#addlayer-hero-0-layer_1',
	[Snippets.DELETE_LAYER]: '#deletelayer-hero-0-layer_1',
	[Snippets.FREE_CHOICE]: `MAIN (default)
...
choice
"... #give-r" {
}
"... #give-h" {
}
"... #give-n" {
}`,
	[Snippets.PAID_CHOICE]: `#info-

MAIN (default)
...
choice
"... #give-w #paid- #rem-" {
}
"... #rem-" {
}
"... #rem-" {
}`,
	[Snippets.UPGRADE]: `#info-...

MAIN (default)
...
choice
"New room. #paid-25 #rem-remember_luxury_life" {
}
"Old room. #paid-0 #rem-remember_poor_life" {
}`,
	[Snippets.LUXURY]: '#luxurypoor_background-1_background-2',
	[Snippets.ITEM]: `MAIN (default)
itemPlace
choice
"items_1" {
}`,
	[Snippets.ITEM_BRANCH]: `MAIN (default)
itemPlaceBranch
choice
"items_1 #paid-12 #desc-..." {
}
"items_2 #paid-10 #desc-..." {
}
"items_3 #paid-0 #desc-..." {
}
`,
	[Snippets.REF]: (variants: string[]) => `MAIN (default)
refPlace
choice
${variants
	.map(
		(v: string) => `"${v} #cons-"{
}`,
	)
	.join('\n')}
`,
	[Snippets.TIME]: `MAIN (default)
...
choiceTime-5
"... #give-r #rnd" {
}
"... #give-h" {
}
"... #give-n" {
}`,
	[Snippets.ROLL_BODY]: '#rollbody',
	[Snippets.ROLL_CLOTH]: '#rollcloth',
	[Snippets.ROLL_HAIR]: '#rollhair',
	[Snippets.REM]: '#rem-',
	[Snippets.OUTFIT]: `MAIN (default)
heroSelectionBranch-MAINHERO
choice
"cloth_1 #paid-17 #desc-... #rem-..." {
}
"cloth_2 #paid-12 #desc-... #rem-..." {
}
"cloth_3 #paid-0 #desc-... #rem-..." {
}`,
	[Snippets.PLACEHOLDER_CLOTHES]: `MAIN (default) 
heroSelectionBranch-MAINHERO 
choice 
"cloth_1 #paid-17 #desc-..." {
 
} 
"cloth_2 #paid-12 #desc-..." { 

} 
"placeholder_clothes #paid-0 #desc-Your Old Clothes" { 
}`,
	[Snippets.HAIR]: `MAIN (default)
hairPlace-MAINHERO
choice
"hair_1 #paid-10 #desc-..." {
}
"hair_2 #paid-7 #desc-..." {
}
"hair_3 #paid-0 #desc-..." {
}
`,
	[Snippets.PLACEHOLDER_HAIR]: `MAIN (default) 
hairPlace-MAINHERO
choice 
"hair_1 #paid-10 #desc-..." { 
} 
"hair_2 #paid-7 #desc-..." { 
} 
"placeholder_hair #paid-0 #desc-Your Old Hairdo" { 
}`,
	[Snippets.LAYER]: `MAIN (default)
layerPlace-MAINHERO-2 
choice 
"layer_1 #paid-10 #layer-2 #desc-Layer2" { 
} 
"layer_2 #paid-7 #layer-2 #desc-Layer2" { 
} 
"layer_3 #paid-0 #layer-2 #desc-Layer2" {
 }`,
	[Snippets.PAID_REM]: `#info-

MAIN (default)
...
choice
" #give-w #paid- #rem- " {
}
"" {
}
"" {
}`,
	[Snippets.LUXURY2]: `MAIN (default)
refPlace
choice
"yourLuxuryRem #noCons" {

backgroundName your-luxury-background
}
"yourPoorRem #noCons" {

backgroundName your-poor-background
}
	`,
	[Snippets.TAGS]: () =>
		`#tags-objective: ...; li: ...; type: ...; id: ${shortid.generate()}`,
	[Snippets.INCOMING_MESSAGE]: () => `#chat-MAINHERO-Hero Name

Hero Name (default) type=chat
text`,
	[Snippets.OUTGOING_MESSAGE]: () => `#keepchat-MAINHERO-Hero Name

MAIN (default) type=chat
text`,
	[Snippets.GROUP_CHAT]: () => `#groupChat-MAINHERO-Chat Name

Hero Name (default) type=chat
text`,
	[Snippets.KEEP_GROUP_CHAT]: () => `#keepGroupChat-MAINHERO-Chat Name
	
MAIN (default) type=chat
text`,
	[Snippets.LINK]: () => `<link> </link>`,
	[Snippets.FIRST_PAID]: `#important-Premium choices open bonus scenes and can influence relationships between characters.

#info-

MAIN (default)
...
choice
"... #give-w #paid- #rem-" {
}
"... #rem-" {
}
"... #rem-" {
}`,
	[Snippets.FIRST_TIME]: `#important-Some choices may have a time limit. Think fast!

MAIN (default)
...
choiceTime-5
"... #give-r #rnd" {
}
"... #give-h" {
}
"... #give-n" {
}`,
	[Snippets.FREE_REM]: `MAIN (default)
...
choice
"... #give-r #rem-" {
}
"... #give-h #rem-" {
}
"... #give-n #rem-" {
}`,
	[Snippets.FIRST_FREE_REM]: `#important-Some choices will have consequences later in the story. Pick your answers carefully!

MAIN (default)
...
choice
"... #give-r #rem-" {
}
"... #give-h #rem-" {
}
"... #give-n #rem-" {
}`,
	[Snippets.FIRST_CUSTOMIZATION]: `backgroundName your-background-name-for-first-customization

#changehair-MAINHERO-hair_name

#change-MAINHERO-outfit_name

Hero Name (default)
customization
"body-selection" {

"body1",

"body2",

"body3"
}
"hair-selection" {

"hair1 #rem-remid #paid-10 #desc-some hair",

"hair2 #rem-remid #paid-7 #desc-some hair", 

"hair3 #rem-remid #paid-0 #desc-some hair",
}
"outfit-selection" {

"outfit1 #rem-remid #paid-10 #desc-some outfit",

"outfit2 #rem-remid #paid-7 #desc-some outfit", 

"outfit3 #rem-remid #paid-0 #desc-some outfit"
}
"layer-selection" {

"layerName1 #layer-1 #rem-remid #paid-10 #desc-some layer", 

"layerName2 #layer-5 #rem-remid #paid-0 #desc-some layer"
}

#nameCustomization-MAINHERO`,
	[Snippets.SHOW]: [
		'#showeffect-blinking',
		'#showeffect-shaking',
		'#showeffect-moving',
		'#showeffect-flash',
	],
	[Snippets.EFFECT]: [
		'effect=sit',
		'effect=zoom',
		'effect=eyeshine',
		'effect=shake',
	],
	[Snippets.ADD]: [
		'#addeffect-lightoff',
		'#addeffect-blur',
		'#addeffect-dream',
		'#addeffect-matebonding',
		'#addeffect-hearts',
		'#addeffect-shyness',
		'#addeffect-dominantaura',
	],
	[Snippets.DEL]: [
		'#deleteeffect-lightoff',
		'#deleteeffect-blur',
		'#deleteeffect-dream',
		'#deleteeffect-matebonding',
		'#deleteeffect-hearts',
		'#deleteeffect-shyness',
		'#deleteeffect-dominantaura',
	],
	[Snippets.BACKGROUND_NAME_NO_TRANSITION]: 'backgroundNameNoTransition',

	// 	[Snippets.INCOMING_CALL]: `#call-MAINHERO-Hero Name

	// Hero Name (default) type=call
	// Some text

	// MAIN (default) type=call
	// Some text`,
	// 	[Snippets.OUTGOING_CALL]: `#call-Hero Name-MAINHERO

	// Hero Name (default) type=call
	// Some text

	// MAIN (default) type=call
	// Some text`,
	// 	[Snippets.RETURN_TO_CALL]: `#keepCall-Hero Name-Hero Name

	// Hero Name (default) type=call
	// Some text

	// Hero Name (default) type=call
	// Some text`,
	// 	[Snippets.INCOMING_VIDEO_CALL]: `#videocall-MAINHERO-Hero Name

	// backgroundName some-background

	// Hero Name (default) type=videocall
	// Some text

	// MAIN (default) type=videocall
	// Some text`,
	// 	[Snippets.OUTGOING_VIDEO_CALL]: `#videocall-Hero Name-MAINHERO

	// backgroundName some-background

	// Hero Name (default) type=videocall
	// Some text

	// MAIN (default) type=videocall
	// Some text`,
	// 	[Snippets.RETURN_TO_VIDEO_CALL]: `#keepVideocall-Hero Name-Hero Name

	// backgroundName some-background

	// Hero Name (default) type=videocall
	// Some text

	// Hero Name (default) type=videocall
	// Some text`,
	// 	[Snippets.INCOMING_SEXY_VIDEO_CALL]: `#sexyVideocall-MAINHERO-Hero Name

	// backgroundName some-split-background

	// Hero Name (default) type=sexyVideocall
	// Some text

	// MAIN (default) type=sexyVideocall
	// Some text`,
	// 	[Snippets.OUTGOING_SEXY_VIDEO_CALL]: `#sexyVideocall-Hero Name-MAINHERO

	// backgroundName some-split-background

	// Hero Name (default) type=sexyVideocall
	// Some text

	// MAIN (default) type=sexyVideocall
	// Some text`,
	// 	[Snippets.RETURN_TO_SEXY_VIDEO_CALL]: `#keepSexyVideocall-Hero Name-Hero Name

	// backgroundName some-split-background

	// Hero Name (default) type=sexyVideocall
	// Some text

	// Hero Name (default) type=sexyVideocall
	// Some text`,
};

export enum Emotions {
	DEFAULT = '#def',
	SURPRISED = '#sur',
	FLIRTY = '#fli',
	JOY = '#jo',
	SHY = '#sh',
	SAD = '#sa',
	ANGRY = '#an',
	ANNOYED = '#ann',
	PLEASURE = '#pl',
}

const EMOTIONS_EXPANDED = {
	[Emotions.DEFAULT]: '(default)',
	[Emotions.SURPRISED]: '(surprised)',
	[Emotions.FLIRTY]: '(flirty)',
	[Emotions.JOY]: '(joy)',
	[Emotions.SHY]: '(shy)',
	[Emotions.SAD]: '(sad)',
	[Emotions.ANGRY]: '(angry)',
	[Emotions.ANNOYED]: '(annoyed)',
	[Emotions.PLEASURE]: '(pleasure)',
};

export type SnippetsNames = typeof Snippets & typeof Emotions;

export const SNIPPETS_FULL: Record<
	string,
	string | string[] | ((variants: string[]) => string)
> = {
	...SNIPPETS_EXPANDED,
	...EMOTIONS_EXPANDED,
};

export const SNIPPETS_OPTIONS = [
	...Object.values(Snippets),
	...Object.values(Emotions),
];
