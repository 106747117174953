import { useCallback } from 'react';
import { useToast, UseToastOptions } from '@chakra-ui/react';
import _ from 'lodash';

import { trackErrorShown } from 'shared/analytics';

export const useAppToast = () => {
	const innerToast = useToast({
		position: 'top',
		duration: 5000,
	});

	return useCallback(
		(options: UseToastOptions) => {
			if (options.status === 'error') {
				trackErrorShown(options.title as string);
			}
			return innerToast({
				...options,
				duration: _.isNil(options.isClosable) ? 5000 : null,
				isClosable: _.isNil(options.isClosable) ? true : options.isClosable,
				variant: options.status,
			});
		},
		[innerToast],
	);
};
