import React, { useCallback, useRef, useState } from 'react';
import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	Badge,
	Box,
	Button,
	Flex,
	Grid,
	GridItem,
	IconButton,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Table,
	TableContainer,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tr,
	useDisclosure,
	VStack,
} from '@chakra-ui/react';

import { AssetBook } from 'entities/assets/interfaces/asset';
import { ButtonType } from 'shared/analytics';
import { trackAddStoryModalClose } from 'shared/analytics/modals/close';
import { trackAddStoryModalOpen } from 'shared/analytics/modals/open';
import { BookIcon, FireIcon, MoreIcon, PlusIcon } from 'shared/icons';
import { AddStoriesModal } from 'shared/ui/add-stories-modal';

import { ChaptersHeroSelect } from '../chapters-select';
import { BookChaptersQuery } from '../chapters-select/queries.gen';

interface StoriesHeroTabProps {
	books: AssetBook[];
	handleBookRemove: (id: string) => Promise<void>;
	handleAddHeroBooksSubmit: (books: AssetBook[]) => Promise<void>;
	handleSetFieldValue: (
		field: string,
		value: any,
		shouldValidate?: any,
	) => void;
}

export const StoriesHeroTab: React.FC<StoriesHeroTabProps> = ({
	books,
	handleBookRemove,
	handleAddHeroBooksSubmit,
	handleSetFieldValue,
}) => {
	const cancelRef = useRef(null);
	const {
		isOpen: isOpenAddModal,
		onOpen: onOpenAddModal,
		onClose: onCloseAddModal,
	} = useDisclosure();
	const {
		isOpen: isOpenDeleteStoryModal,
		onOpen: onOpenDeleteStoryModal,
		onClose: onCloseDeleteStoryModal,
	} = useDisclosure();

	const [deletedStoryId, setDeletedStoryId] = useState('');

	const handleDeleteBookAttempt = useCallback(
		(bookId: string) => () => {
			onOpenDeleteStoryModal();
			setDeletedStoryId(bookId);
		},
		[onOpenDeleteStoryModal],
	);

	const handleDeleteModalClose = useCallback(() => {
		onCloseDeleteStoryModal();
		setDeletedStoryId('');
	}, [onCloseDeleteStoryModal]);

	const handleDeleteBookSubmit = useCallback(() => {
		handleBookRemove(deletedStoryId);
		handleDeleteModalClose();
	}, [deletedStoryId, handleBookRemove, handleDeleteModalClose]);

	const handleBookOpenInNewTab = useCallback(
		(bookId: string) => () =>
			window.open(
				`${window.location.origin}/stories/${bookId}/chapters`,
				'_blank',
			),
		[],
	);

	const handleBookUpdateChapters = useCallback(
		(bookId: string) => (chapters: BookChaptersQuery['book']['chapters']) => {
			handleSetFieldValue(
				'books',
				books.map((book) => {
					if (book.id === bookId) {
						return { ...book, chapters };
					}
					return book;
				}),
			);
		},
		[books, handleSetFieldValue],
	);

	return (
		<>
			<Grid h="100%" templateRows="40px 1fr" gap="15px">
				<GridItem as={Flex} align="center" justify="space-between">
					<Text fontWeight={600}>All Stories</Text>
					<Button
						leftIcon={<PlusIcon />}
						onClick={() => {
							onOpenAddModal();
							trackAddStoryModalOpen();
						}}
					>
						Add
					</Button>
				</GridItem>
				{books.length ? (
					<Box maxH={547} overflow="auto" h="full">
						<TableContainer>
							<Table>
								<Thead>
									<Tr>
										<Th>Story Name</Th>
										<Th>Id</Th>
										<Th>Chapters</Th>
										<Th>Type</Th>
										<Th />
									</Tr>
								</Thead>
								<Tbody>
									{books?.map((book) =>
										book.chapters.length ? (
											<Tr key={book.id}>
												<Td>
													<Text
														fontWeight={600}
														overflow="hidden"
														whiteSpace="nowrap"
														textOverflow="ellipsis"
													>
														{book.name}
													</Text>
												</Td>
												<Td
													w="12%"
													overflow="hidden"
													whiteSpace="nowrap"
													textOverflow="ellipsis"
												>
													{book.customId}
												</Td>
												<Td w="26%">
													<ChaptersHeroSelect
														bookId={book.id}
														selectedChapters={book.chapters}
														onSubmit={handleBookUpdateChapters(book.id)}
													/>
												</Td>
												<Td w="26%">
													<Badge
														as={Flex}
														align="center"
														gap="6px"
														p="5px 6px"
														bg="#EEECFC"
														color="#644DED"
													>
														{book.fantasy ? (
															<>
																<FireIcon />
																<Text>FR</Text>
															</>
														) : (
															<>
																<BookIcon />
																<Text>Story</Text>
															</>
														)}
													</Badge>
												</Td>
												<Td w="65px">
													<Menu>
														<MenuButton
															as={IconButton}
															variant="ghost"
															borderRadius="full"
															aria-label="more"
															icon={<MoreIcon w={6} h={6} marginRight="5px" />}
														/>

														<MenuList>
															<MenuItem
																onClick={handleBookOpenInNewTab(book.id)}
															>
																Open in New Tab
															</MenuItem>

															<MenuItem
																onClick={handleDeleteBookAttempt(book.id)}
															>
																Remove from Stories
															</MenuItem>
														</MenuList>
													</Menu>
												</Td>
											</Tr>
										) : null,
									)}
								</Tbody>
							</Table>
						</TableContainer>
					</Box>
				) : (
					<GridItem as={VStack} justify="center" gap="14px">
						<Text fontSize={18} lineHeight="27px" fontWeight={600}>
							There is no hero in any story
						</Text>
						<Text maxW={301} color="#ABACBE" align="center">
							to save a character, you need to add him to at least one chapter
						</Text>
					</GridItem>
				)}
			</Grid>
			{isOpenAddModal && (
				<AddStoriesModal
					books={books.reduce((acc, book) => ({ ...acc, [book.id]: book }), {})}
					isOpen={isOpenAddModal}
					onClose={() => {
						onCloseAddModal();
						trackAddStoryModalClose();
					}}
					onSubmit={handleAddHeroBooksSubmit}
				/>
			)}
			{isOpenDeleteStoryModal && (
				<AlertDialog
					isOpen={isOpenDeleteStoryModal}
					leastDestructiveRef={cancelRef}
					onClose={handleDeleteModalClose}
				>
					<AlertDialogOverlay>
						<AlertDialogContent>
							<AlertDialogHeader fontSize="lg" fontWeight="bold">
								Hero
							</AlertDialogHeader>

							<AlertDialogBody>
								If you delete hero from the book, he can always be returned. Are
								you sure you want to continue?
							</AlertDialogBody>

							<AlertDialogFooter>
								<Button
									ref={cancelRef}
									variant="ghost"
									onClick={handleDeleteModalClose}
								>
									Cancel
								</Button>
								<Button
									name={ButtonType.DELETE_BOOK}
									onClick={handleDeleteBookSubmit}
									ml={3}
								>
									Delete
								</Button>
							</AlertDialogFooter>
						</AlertDialogContent>
					</AlertDialogOverlay>
				</AlertDialog>
			)}
		</>
	);
};
