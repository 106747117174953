import React from 'react';
import { createIcon } from '@chakra-ui/react';

export const HighlighterIcon = createIcon({
	viewBox: '0 0 24 24',
	path: (
		<g fill="none">
			<path
				d="M 20.4 6.632 L 17.328 3.58 C 16.519 2.807 15.245 2.807 14.436 3.58 L 6.93 11.071 C 6.865 11.141 6.818 11.225 6.792 11.317 L 5.724 14.32 L 4.8 15.275 C 4.568 15.51 4.568 15.888 4.8 16.122 L 4.884 16.213 L 3.174 17.924 C 3.061 18.038 2.999 18.192 3 18.351 L 3 20.399 C 3 20.559 3.063 20.711 3.176 20.824 C 3.288 20.937 3.441 21 3.6 21 L 5.646 21 C 5.806 21.001 5.959 20.938 6.072 20.825 L 7.801 19.114 L 7.89 19.198 C 8.002 19.309 8.153 19.371 8.31 19.372 C 8.47 19.373 8.623 19.31 8.736 19.198 L 9.66 18.273 L 12.69 17.204 L 12.69 17.203 C 12.778 17.176 12.857 17.128 12.924 17.065 L 20.4 9.533 C 20.785 9.149 21 8.628 21 8.085 C 20.999 7.541 20.784 7.018 20.4 6.632 L 20.4 6.632 Z M 5.4 19.798 L 4.2 19.798 L 4.2 18.597 L 5.737 17.059 L 6.936 18.261 L 5.4 19.798 Z M 9.121 17.185 C 9.036 17.216 8.958 17.263 8.892 17.323 L 8.292 17.924 L 6.072 15.702 L 6.672 15.101 C 6.733 15.034 6.78 14.957 6.81 14.873 L 7.614 12.59 L 11.4 16.381 L 9.121 17.185 Z M 12.474 15.768 L 8.208 11.496 L 13.752 5.94 L 18 10.217 L 12.474 15.768 Z M 19.554 8.686 L 18.87 9.364 L 14.598 5.094 L 15.282 4.409 C 15.619 4.091 16.145 4.091 16.482 4.409 L 19.554 7.485 C 19.711 7.646 19.8 7.861 19.8 8.085 C 19.799 8.31 19.71 8.525 19.554 8.686 Z"
				fill="currentColor"
				stroke="currentColor"
				strokeWidth="0.11"
			/>
		</g>
	),
});
