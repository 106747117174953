import React, { useCallback, useEffect } from 'react';
import { Modal, ModalOverlay } from '@chakra-ui/react';
import { FormikProps, useFormikContext } from 'formik';

import { useDownloadCards } from 'widgets/create-card-modal/hooks/useDownloadCards';
import { useGenerateCards } from 'widgets/create-card-modal/hooks/useGenerateCards';
import {
	UpdateCardFormikParams,
	UpdateCardModalProps,
} from 'widgets/update-card-modal/interfaces';

import { CardModalContent } from './modal-content';

export const UpdateCardModal: React.FC<UpdateCardModalProps> = ({
	bookId,
	bookCustomId,
	setCardId,
	isOpen,
	onClose,
	selectedAssetsInfo,
	setSelectedAssetsInfo,
	isLoading,
	isCardLoading,
	setIsCardGenerating,
}) => {
	const formik: FormikProps<UpdateCardFormikParams> = useFormikContext();
	const generateCards = useGenerateCards();
	const downloadCards = useDownloadCards();

	const handleModalClose = useCallback(() => {
		onClose();
		setSelectedAssetsInfo({
			selectedBodies: [],
			selectedClothes: [],
			selectedHairs: [],
			selectedLayers: [],
		});
		formik.resetForm();
		setCardId('');
	}, [formik, onClose, setCardId, setSelectedAssetsInfo]);

	const handleCardsDownload = async () => {
		const backgroundLink = formik.values.background?.link || '';
		const chapterId = formik.values.chapter?.chapterOrder || 0;
		const heroId = formik.values.hero?.customId || 0;

		await downloadCards({
			selectedAssetsInfo,
			bookId: bookCustomId || '',
			chapterId,
			heroId,
			backgroundLink,
			isGolden: formik.values.golden,
			isSecondary: formik.values.secondary,
		});
	};

	const handleSubmitForm = async () => {
		setIsCardGenerating(true);
		const backgroundLink = formik.values.background?.link || '';
		const chapterId = formik.values.chapter?.chapterOrder || 0;
		const heroId = formik.values.hero?.customId || 0;

		const linksArr = await generateCards({
			selectedAssetsInfo,
			bookId: bookCustomId || '',
			chapterId,
			heroId,
			backgroundLink,
			onClose,
			isGolden: formik.values.golden,
			isSecondary: formik.values.secondary,
		});

		formik.setFieldValue('cardsLink', linksArr, true);
		await formik.submitForm();
	};

	useEffect(() => {
		const body = formik.values.hero?.bodies?.[0]?.id as string;
		const clothes = formik.values.hero?.clothes?.[0]?.id as string;
		const hairs = formik.values.hero?.hairs?.[0]?.id as string;

		setSelectedAssetsInfo({
			selectedBodies: body ? [body] : [],
			selectedClothes: clothes ? [clothes] : [],
			selectedHairs: hairs ? [hairs] : [],
			selectedLayers: [],
		});
	}, [formik.values.hero, setSelectedAssetsInfo]);

	useEffect(() => {
		setSelectedAssetsInfo({
			selectedBodies: formik.initialValues.selectedAssets?.selectedBodies || [],
			selectedClothes:
				formik.initialValues.selectedAssets?.selectedClothes || [],
			selectedHairs: formik.initialValues.selectedAssets?.selectedHairs || [],
			selectedLayers: formik.initialValues.selectedAssets?.selectedLayers || [],
		});
	}, [
		formik.initialValues.selectedAssets?.selectedBodies,
		formik.initialValues.selectedAssets?.selectedClothes,
		formik.initialValues.selectedAssets?.selectedHairs,
		formik.initialValues.selectedAssets?.selectedLayers,
		setSelectedAssetsInfo,
	]);

	return (
		<Modal
			closeOnOverlayClick={false}
			isOpen={isOpen}
			onClose={handleModalClose}
		>
			<ModalOverlay />
			<CardModalContent
				bookId={bookId}
				isLoading={isLoading}
				isCardLoading={isCardLoading}
				onClose={onClose}
				selectedAssetsInfo={selectedAssetsInfo}
				setSelectedAssetsInfo={setSelectedAssetsInfo}
				handleModalClose={handleModalClose}
				setIsCardGenerating={setIsCardGenerating}
				handleCardsDownload={handleCardsDownload}
				handleSubmitForm={handleSubmitForm}
			/>
		</Modal>
	);
};
