import React, { useCallback } from 'react';
import {
	Box,
	ButtonGroup,
	Flex,
	IconButton,
	Text,
	Tooltip,
	useDisclosure,
} from '@chakra-ui/react';

import { HeroCard } from 'features/hero';
import { ButtonType } from 'shared/analytics';
import { AnalyticsIconButton } from 'shared/analytics/ui/icon-button';
import { PencilIcon, PlusIcon, TrashBinIcon } from 'shared/icons';
import { ArrayElement } from 'shared/model';
import { HeroesQuery } from 'widgets/existing-heroes-modal/graphql/queries.gen';
import { SelectHeroesModal } from 'widgets/select-heroes-modal';
import { CardQuery } from 'widgets/update-card-modal/graphql/queries.gen';

interface ParentHeroInputProps {
	handleSetFieldValue: (
		field: string,
		value: any,
		shouldValidate?: any,
	) => void;
	hero: ArrayElement<HeroesQuery['heroes']> | CardQuery['card']['hero'] | null;
	chapterId: string;
}

export const DisplayHeroCard: React.FC<ParentHeroInputProps> = ({
	hero,
	chapterId,
	handleSetFieldValue,
}) => {
	const {
		isOpen: isOpenExistingHeroModal,
		onOpen: onOpenExistingHeroModal,
		onClose: onCloseExistingHeroModal,
	} = useDisclosure();

	const handleHeroRemove = useCallback(() => {
		handleSetFieldValue('hero', null);
		handleSetFieldValue('heroName', '');
	}, [handleSetFieldValue]);

	return (
		<Flex flexDir="column" marginBottom="20px">
			<Flex p="13px 0 12px 0" align="center" justify="space-between">
				<Text fontSize="14px">Hero</Text>

				{!hero ? (
					<IconButton
						aria-label="add-hero-names"
						size="circle"
						variant="secondary"
						onClick={onOpenExistingHeroModal}
					>
						<PlusIcon />
					</IconButton>
				) : (
					<ButtonGroup spacing="4px" alignItems="center">
						<IconButton
							aria-label="edit-parent"
							size="circle"
							variant="secondary"
							onClick={onOpenExistingHeroModal}
						>
							<PencilIcon w={6} h={6} />
						</IconButton>
						<Tooltip label="Remove">
							<AnalyticsIconButton
								name={ButtonType.REMOVE_CHARACTER_PARENT}
								aria-label="remove-parent"
								size="circle"
								variant="secondary"
								onClick={handleHeroRemove}
							>
								<TrashBinIcon w={6} h={6} />
							</AnalyticsIconButton>
						</Tooltip>
					</ButtonGroup>
				)}
			</Flex>
			<Box maxW={600}>{hero && <HeroCard heroId={hero.id} hero={hero} />}</Box>
			<Box pos="relative">
				{hero?.parent && (
					<Text
						pos="absolute"
						color="red"
						right="40px"
						top="12px"
						fontWeight={500}
						fontSize="12px"
						background="#fff"
						p="0 5px"
					>
						This hero has blood related. Please, select another hero!
					</Text>
				)}
			</Box>
			<SelectHeroesModal
				handleSetFieldValue={handleSetFieldValue}
				chapterId={chapterId || ''}
				isOpen={isOpenExistingHeroModal}
				onClose={onCloseExistingHeroModal}
			/>
		</Flex>
	);
};
