import React from 'react';
import { createIcon } from '@chakra-ui/react';

export const StoryIcon = createIcon({
	viewBox: '0 0 12 14',
	path: (
		<>
			<path
				d="M9.50098 0.5H2.50098C1.39798 0.5 0.500977 1.33293 0.500977 2.35714V11.6429C0.500977 12.6671 1.39798 13.5 2.50098 13.5H9.50098C10.604 13.5 11.501 12.6671 11.501 11.6429V2.35714C11.501 1.33293 10.604 0.5 9.50098 0.5ZM10.501 11.6429C10.501 12.1554 10.053 12.5714 9.50098 12.5714H2.50098C1.94898 12.5714 1.50098 12.1554 1.50098 11.6429V2.35714C1.50098 1.84457 1.94898 1.42857 2.50098 1.42857H9.50098C10.053 1.42857 10.501 1.84457 10.501 2.35714V11.6429Z"
				fill="currentColor"
				stroke="currentColor"
				strokeWidth="0.2"
			/>
			<path
				d="M9.00098 6.02101H3.00098C2.70092 6.02101 2.45098 6.24797 2.45098 6.5353C2.45098 6.82262 2.70092 7.04958 3.00098 7.04958H9.00098C9.30104 7.04958 9.55098 6.82262 9.55098 6.5353C9.55098 6.24797 9.30104 6.02101 9.00098 6.02101ZM9.00098 4.16387H3.00098C2.70092 4.16387 2.45098 4.39083 2.45098 4.67815C2.45098 4.96548 2.70092 5.19244 3.00098 5.19244H9.00098C9.30104 5.19244 9.55098 4.96548 9.55098 4.67815C9.55098 4.39083 9.30104 4.16387 9.00098 4.16387Z"
				fill="currentColor"
				stroke="currentColor"
				strokeWidth="0.1"
			/>
		</>
	),
});
