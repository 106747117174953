export const progressStyles = {
	baseStyle: {
		track: {
			bg: '#644DED',
			borderRadius: '4px',
		},
		filledTrack: {
			borderRadius: '4px',
			bgImage: `linear-gradient(
                to right,
                transparent 0%,
                #EEEEF2 50%,
                transparent 100%
              )`,
		},
	},
	variants: {
		disabled: {
			track: {
				bg: '#EEEEF2',
			},
		},
		finished: {
			filledTrack: {
				bgImage: 'none',
				bg: '644DED',
			},
		},
	},
};
