import React from 'react';
import { Box, Flex, Tooltip } from '@chakra-ui/react';

import {
	CatalogIcon,
	CommunityIcon,
	OpenBookIcon,
	SignIcon,
	StackIcon,
	UploadIcon,
} from 'shared/icons';

import NavigationLink from '../link';
import ToggleNavigationBtn from '../toggle-btn';

interface Props {
	toggleBtnClickHandler: () => void;
	isNavigationOpened: boolean;
}

const NavigationBody: React.FC<Props> = ({
	toggleBtnClickHandler,
	isNavigationOpened,
}) => {
	return (
		<>
			<Flex
				h="calc(100% - 78px)"
				flexDirection="column"
				justify="space-between"
			>
				<div>
					<Tooltip label="Story Management" isDisabled={isNavigationOpened}>
						<Box>
							<NavigationLink
								icon={<StackIcon w={6} h={6} />}
								text="Story Management"
								isNavigationOpened={isNavigationOpened}
								href="/stories"
							/>
						</Box>
					</Tooltip>

					<Tooltip label="Hero Management" isDisabled={isNavigationOpened}>
						<Box>
							<NavigationLink
								icon={<CommunityIcon w="6" h="6" />}
								text="Hero Management"
								isNavigationOpened={isNavigationOpened}
								href="/hero-management"
							/>
						</Box>
					</Tooltip>

					<Tooltip label="Art Catalog" isDisabled={isNavigationOpened}>
						<Box>
							<NavigationLink
								icon={<CatalogIcon w="6" h="6" />}
								text="Art Catalog"
								isNavigationOpened={isNavigationOpened}
								href="/art-catalog"
							/>
						</Box>
					</Tooltip>

					{/* <Tooltip label="Editor" isDisabled={isNavigationOpened}>
						<Box>
							<NavigationLink
								icon={<PencilIcon w="6" h="6" />}
								text="Editor"
								isNavigationOpened={isNavigationOpened}
								onClick={() => {
									scriptModel.resetDocumentValue();
									scriptModel.setIsScriptEdited(false); // !remove after next release with alert
									setIsDocSelectModalOpen(true);
								}}
							/>
						</Box>
					</Tooltip> */}

					<Tooltip label="Content Builder" isDisabled={isNavigationOpened}>
						<Box>
							<NavigationLink
								icon={<UploadIcon w="6" h="6" />}
								text="Content Builder"
								isNavigationOpened={isNavigationOpened}
								href="/content-builder"
							/>
						</Box>
					</Tooltip>
					{/* <Tooltip
					text="Community"
					placement="bottom-end"
					permitted={!isNavigationOpened}
				>
					<NavigationLink
						iconSrc="/images/story/community-ico.svg"
						text="Community"
						isNavigationOpened={isNavigationOpened}
					/>
				</Tooltip> */}
				</div>
				<Box>
					<Tooltip label="Ongoing Books" isDisabled={isNavigationOpened}>
						<Box>
							<NavigationLink
								icon={<OpenBookIcon w={6} h={6} />}
								text="Ongoing Books"
								isNavigationOpened={isNavigationOpened}
								href="/ongoing-books"
							/>
						</Box>
					</Tooltip>
					<Tooltip label="Resources" isDisabled={isNavigationOpened}>
						<Box mb={12}>
							<NavigationLink
								icon={<SignIcon w={6} h={6} />}
								text="Resources"
								isNavigationOpened={isNavigationOpened}
								href="/resources"
							/>
						</Box>
					</Tooltip>
					{/* <Tooltip
					text="Settings"
					placement="top"
					permitted={!isNavigationOpened}
				>
					<NavigationLink
						icon={<GearIcon />}
						text="Settings"
						isNavigationOpened={isNavigationOpened}
					/>
				</Tooltip> */}
					<Tooltip label="Show Menu" isDisabled={isNavigationOpened}>
						<Box>
							<ToggleNavigationBtn
								isNavigationOpened={isNavigationOpened}
								onClick={toggleBtnClickHandler}
							/>
						</Box>
					</Tooltip>
				</Box>
			</Flex>
			{/* <LegacyWriterPicker
				isOpen={isDocSelectModalOpen}
				onClose={() => setIsDocSelectModalOpen(false)}
			/> */}
		</>
	);
};

export default NavigationBody;
