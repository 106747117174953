import React from 'react';
import { createIcon } from '@chakra-ui/react';

export const ExpandIcon = createIcon({
	viewBox: '0 0 24 24',
	path: (
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M17.0499 16.7743c0 .3544.2873.6417.6417.6417.3544 0 .6417-.2873.6417-.6417V4.68654c0-.35438-.2873-.64167-.6417-.64167-.3544 0-.6417.28729-.6417.64167V16.7743Zm-1.894-5.5577c.2685-.2685.2685-.7038 0-.9723l-3.4604-3.46043c-.2685-.26849-.7038-.26849-.9722 0-.2686.26849-.2686.70382 0 .97231l2.2882 2.28822-9.08197.0191c-.37968.0008-.68686.3093-.68603.689.00073.3797.30919.6869.68887.6861l9.07623-.0192-2.2991 2.2991c-.2685.2685-.2685.7038 0 .9722.2685.2685.7038.2685.9723 0l3.4741-3.4741Z"
			fill="currentColor"
		/>
	),
});
