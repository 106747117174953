import React from 'react';
import { createIcon } from '@chakra-ui/react';

export const CheckSmallIcon = createIcon({
	viewBox: '0 0 24 24',
	path: (
		<g fill="none">
			<path
				id="Vector 43"
				d="M 8 12.036 L 10.256 14 L 16 9"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</g>
	),
});
