import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
	IconButton,
	Image,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
} from '@chakra-ui/react';
import { useStore } from 'effector-react';

import { userModel, useUserByEmailQuery } from 'entities/user';
import { ArrowDownIcon, ProfileIcon } from 'shared/icons';

import { useRevokeTokenLazyQuery } from './queries.gen';

export const SessionManagement = () => {
	const navigate = useNavigate();
	const [revokeToken] = useRevokeTokenLazyQuery();
	const accessToken = useStore(userModel.$accessToken);
	const refreshToken = useStore(userModel.$refreshToken);
	const handleLogoutClick = async () => {
		await revokeToken({
			variables: {
				access: accessToken as string,
				refresh: refreshToken as string,
			},
		});
		userModel.setAccessToken(null);
		userModel.setRefreshToken(null);
		userModel.setPicture(null);
		navigate('/auth');
	};

	const { data: userData } = useUserByEmailQuery();
	useEffect(() => {
		userModel.setPicture(`${userData?.user.picture}`);
	}, [userData]);

	const userPicture = useStore(userModel.$picture);
	return (
		<Menu>
			{({ isOpen }) => (
				<>
					<MenuButton>
						<IconButton
							minW="36px"
							minH="36px"
							w="36px"
							h="36px"
							variant="outline"
							borderRadius="9999px"
							aria-label="toggle user management"
							as="div"
						>
							{userPicture ? (
								<Image
									src={`${userPicture}`}
									alt="Author"
									borderRadius="full"
									width="31px"
									height="31px"
									objectFit="contain"
								/>
							) : (
								<ProfileIcon w="20px" h="20px" />
							)}
						</IconButton>

						<ArrowDownIcon
							w={5}
							h={5}
							ml={2}
							transform={isOpen ? 'rotate(180deg)' : 'none'}
							transition="ease"
							transitionDuration="200ms"
							_hover={{ padding: '100px' }}
						/>
					</MenuButton>
					<MenuList>
						<MenuItem onClick={handleLogoutClick}>Sign out</MenuItem>
					</MenuList>
				</>
			)}
		</Menu>
	);
};
