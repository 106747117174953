import React from 'react';
import { Box, Text } from '@chakra-ui/react';

interface ViewAssetTabsProps {
	title: string;
	isActive: boolean;
	onClick: (v: string) => void;
}

export const ViewAssetTabs: React.FC<ViewAssetTabsProps> = ({
	title,
	isActive,
	onClick,
}) => {
	return (
		<Box
			mr="43px"
			p="19px 21px"
			cursor="pointer"
			borderRightRadius="16px"
			bg={isActive ? 'rgba(100, 77, 237, 0.08)' : ''}
			onClick={() => onClick(title)}
		>
			<Text
				fontWeight={600}
				fontSize="15px"
				lineHeight="22px"
				textAlign="left"
				color={isActive ? '#644ded' : ''}
			>
				{title}
			</Text>
		</Box>
	);
};
