import React from 'react';

import styles from './styles.module.scss';

interface Props {
	duration: number;
	curTime: number;
	onTimeUpdate: (v: number) => void;
}

export const Bar: React.FC<Props> = ({ duration, curTime, onTimeUpdate }) => {
	const curPercentage = (curTime / duration) * 100;

	function calcClickedTime(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
		const eventTarget = e.target as HTMLDivElement;
		const clickPosition = e.pageX - eventTarget.offsetLeft;
		const bar = document.getElementById('progress-bar');
		if (bar) {
			const barWidth = bar.offsetWidth;
			return (clickPosition * duration) / barWidth;
		}
		return 0;
	}

	const handleSelectTime = (
		e: React.MouseEvent<HTMLDivElement, MouseEvent>,
	) => {
		e.stopPropagation();
		onTimeUpdate(calcClickedTime(e));
	};

	return (
		<div className={styles.bar}>
			<div
				id="progress-bar"
				className={styles.progress}
				style={{
					background: `linear-gradient(to right, #484964 ${curPercentage}%, #EEEEF2 0)`,
				}}
				onClick={(e) => handleSelectTime(e)}
			>
				<span style={{ left: `${curPercentage - 2}%` }} />
			</div>
		</div>
	);
};
