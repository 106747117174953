import React, { useCallback, useRef } from 'react';
import {
	Badge,
	Box,
	Button,
	Flex,
	Popover,
	PopoverBody,
	PopoverContent,
	PopoverTrigger,
	Portal,
	Text,
	useDisclosure,
} from '@chakra-ui/react';
import { useFormik } from 'formik';

import { GetBookDocument, GetBookQuery } from 'features/book/queries.gen';
import { ChapterByIdDocument } from 'features/story-editor/header/queries.gen';
import {
	BookProperty,
	ButtonType,
	trackPropertyChanged,
} from 'shared/analytics';
import { useAppToast } from 'shared/hooks/toast';
import { PencilIcon } from 'shared/icons';
import { ArrayElement } from 'shared/model';
import { UpdateTagsForm } from 'shared/ui/update-tags-form';

import { useUpdateChapterMutation } from '../../queries.gen';

export interface Props {
	chapter: ArrayElement<GetBookQuery['book']['chapters']>;
}

export const UpdateStatusForm: React.FC<Props> = ({ chapter }) => {
	const toast = useAppToast();
	const { onOpen, onClose, isOpen } = useDisclosure();
	const containerRef = useRef<HTMLDivElement | null>(null);

	const [updateChapter] = useUpdateChapterMutation({
		onCompleted: () => {
			onClose();
			toast({
				title: 'Chapter status updated successfully',
				status: 'success',
			});
		},
		onError: () =>
			toast({
				title: 'Chapter status was not updated',
				status: 'error',
			}),
		refetchQueries: [ChapterByIdDocument, GetBookDocument],
	});

	const onSubmitStatusModal = useCallback(
		async (formValue: any) => {
			await updateChapter({
				variables: {
					chapter: {
						id: chapter?.id || '',
						status: formValue[0],
					},
				},
			});
			trackPropertyChanged({
				entity: 'chapter',
				property: BookProperty.STATUS,
				value: formValue[0],
				id: chapter?.id,
			});
		},
		[chapter?.id, updateChapter],
	);

	const updateStatusForm = useFormik({
		initialValues: {
			status: chapter?.status ? [chapter?.status] : [],
		},
		validateOnChange: true,
		enableReinitialize: true,
		onSubmit: (values) => onSubmitStatusModal({ ...values }),
	});

	const onCloseStatusModal = useCallback(() => {
		onClose();
		updateStatusForm.resetForm();
	}, [onClose, updateStatusForm]);

	if (!chapter) return null;

	return (
		<Box
			pb="15px"
			borderTop="1px solid #eeeef2"
			borderBottom="1px solid #eeeef2"
			ref={containerRef}
		>
			<Flex
				position="relative"
				p="13px 0"
				justifyContent="space-between"
				alignItems="center"
			>
				<Text fontWeight={500} fontSize="14px" lineHeight="114%">
					Status
				</Text>
				<Popover
					isOpen={isOpen}
					onOpen={onOpen}
					onClose={onCloseStatusModal}
					placement="bottom-end"
					offset={[10, 10]}
				>
					<PopoverTrigger>
						<Button size="circle" variant="secondary">
							<PencilIcon w={6} h={6} />
						</Button>
					</PopoverTrigger>
					<Portal containerRef={containerRef}>
						<PopoverContent>
							<PopoverBody>
								<UpdateTagsForm
									submitButtonName={ButtonType.EDIT_CHAPTER_STATUS}
									isMulti={false}
									formTitle="Status"
									onClose={onCloseStatusModal}
									options={['rdy', 'not rdy']}
									selectedOptions={updateStatusForm.values.status}
									onChange={(selected) =>
										updateStatusForm.setFieldValue('status', selected, true)
									}
									onSubmit={() =>
										onSubmitStatusModal(updateStatusForm.values.status)
									}
								/>
							</PopoverBody>
						</PopoverContent>
					</Portal>
				</Popover>
			</Flex>
			<Flex>
				<Badge>{chapter.status || 'not rdy'}</Badge>
			</Flex>
		</Box>
	);
};
