import React from 'react';
import { Box, Flex, Grid, Text } from '@chakra-ui/react';

import { CheckSmallIcon } from 'shared/icons';
import { Chapter } from 'widgets/existing-heroes-modal/graphql/hooks';

interface ChapterCardProps extends Chapter {
	selectChapters: string[];
	handleSelectChapter: (v: string) => void;
}

export const ChapterCard: React.FC<ChapterCardProps> = ({
	id,
	name,
	chapterOrder,
	bookName,
	selectChapters,
	handleSelectChapter,
}) => {
	const isChecked = selectChapters.includes(id);

	return (
		<Box
			h="min-content"
			userSelect="none"
			border="4px solid transparent"
			borderRadius="10px"
			cursor="pointer"
			borderColor={`${isChecked ? 'rgba(100, 77, 237, 0.08)' : ''}`}
			onClick={() => handleSelectChapter(id)}
		>
			<Grid
				p={3}
				height="85px"
				templateColumns="auto 1fr auto"
				border="1px solid #eeeef2"
				borderRadius="10px"
				overflow="hidden"
				borderColor={`${isChecked ? '#644DED' : ''}`}
				_hover={{
					borderColor: '#644DED',
				}}
			>
				<Box
					position="relative"
					mx={1}
					my={2}
					h="16px"
					w="16px"
					background={`${isChecked ? '#644DED' : 'none'}`}
					border={`${isChecked ? 'none' : '1.5px solid #BCBCC6'}`}
					borderRadius="3px"
					transition="300ms"
				>
					{isChecked && (
						<CheckSmallIcon
							position="absolute"
							top={-1}
							left={-1}
							h={6}
							w={6}
							color="white"
						/>
					)}
				</Box>
				<Flex py={1} pl={1} flexDirection="column" gap={1}>
					<Text fontSize="15px" fontWeight={600} lineHeight="24px">
						{`${chapterOrder}. ${name}`}
					</Text>
					<Text fontSize="13px" fontWeight={400} lineHeight="16px">
						{bookName}
					</Text>
				</Flex>
			</Grid>
		</Box>
	);
};
