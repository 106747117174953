import { BaseError } from '@holywater-tech/lua-interpreter';
import { RawDraftContentState } from 'draft-js';
import { combine, createEffect, createEvent, createStore } from 'effector';

import { docsModel } from 'entities/docs';
import { api } from 'shared/api';
import { InfoKeyProps } from 'shared/ui/fold-button';
import { deserialize } from 'shared/utils/deserialize';

import { handleErrors, handleWarnings, mapMessages } from './lib';
import { CurrentCommentData, ErrorWithId } from './types';

// ! chats and airtable editor not working, need to implement separate mutation on backend

export const setInitialDocumentValue = createEvent<any[]>(
	'set initial script value',
);
export const resetDocumentValue = createEvent('reset initial script value');
export const setIsDisabledControlButtons = createEvent<boolean>(
	'set script control buttons disabled',
);
export const setIsShownCommentsMarkdowns = createEvent<boolean>(
	'set comments markdowns visibility',
);
export const setCurrentCommentsMarkdowns = createEvent<string[]>(
	'set current comments markdowns',
);
export const setIsDisabledSyncToDoc = createEvent<boolean>(
	'set sync to google doc disabled',
);

export const setIsScriptEdited = createEvent<boolean>('set script edited ');
export const setRedirectAction = createEvent<(() => void) | null>(
	'set redirected action',
);
export const setCurrentCommentData = createEvent<CurrentCommentData | null>(
	'set Edit Comment Modal Data action',
);
export const setIsDraftDocContentUpdated = createEvent<boolean>(
	'set is draft doc updated action',
);
export const setBlockToFindFocus = createEvent<{ from: number; to: number }>(
	'set block to find focus',
);
export const setEditorSearchValue = createEvent<string>(
	'set editor search value',
);
export const setEditorSearchDialogueValue = createEvent<number>(
	'set editor search dialogue value',
);
export const setEditorReplaceValue = createEvent<string>(
	'set editor replace value',
);
export const setSearchType = createEvent<string>('set editor search type');
export const setErrors = createEvent<BaseError[]>('set editor errors');
export const setWarnings = createEvent<BaseError[]>('set editor warnings');

export const resetValidationErrors = createEvent('reset validation errors');
export const setSyntaxErrors = createEvent<boolean>('set syntax errors');

export const setIsLuaEdited = createEvent<boolean>('set lua edited ');

export const setHideBlockKeys = createEvent<InfoKeyProps>(
	'set hide block keys',
);
export const setCurrentScriptInfo = createEvent<any>('set current script info');

export const loadDocumentValueFx = createEffect(async (documentId: string) =>
	api.docs.getPageContent({ documentId }),
);

export const loadDraftDocContentFx = createEffect(async (documentId: string) =>
	api.docs.getDraftDocContent({ documentId }),
);
export const loadDraftDocContentUpdateDateFx = createEffect(
	async (documentId: string) =>
		api.docs.getDraftDocContentUpdateDate({ documentId }),
);

export const loadIsDraftDocContentUpdatedFX = createEffect(
	async (documentId: string) =>
		api.docs.getIsDraftDocContentUpdated({ documentId }),
);

// TODO: use sample from effector
export const validateScriptFx = createEffect(
	async ({
		chapterId,
		script,
		docType,
		lang,
	}: {
		chapterId: string;
		script: RawDraftContentState;
		docType: string;
		lang: string;
	}) => {
		const res = await api.script.convert(
			deserialize(script).text?.replaceAll('“', '"')?.replaceAll('”', '"'),
			chapterId,
			docType,
			lang,
		);

		const errorsPositions = [
			...new Set([...(res.data?.convertScript.errors || [])]),
		];
		const warningsPositions = [
			...new Set([...(res.data?.convertScript.warnings || [])]),
		];
		return {
			data: res.data?.convertScript,
			errorsPositions,
			warningsPositions,
		};
	},
);

export const validateAirTableChatScriptFx = createEffect(
	async ({
		bookId,
		script,
	}: {
		bookId: string;
		script: RawDraftContentState;
	}) => {
		const res = await api.script.convert(
			deserialize(script).text?.replaceAll('“', '"')?.replaceAll('”', '"'),
			bookId, // TODO: add correct handling
		);

		const errorsPositions = [
			...new Set([...(res.data?.convertScript.errors || [])]),
		];
		const warningsPositions = [
			...new Set([...(res.data?.convertScript.warnings || [])]),
		];
		return {
			data: res.data?.convertScript,
			errorsPositions,
			warningsPositions,
		};
	},
);

export const $hideBlockKeys = createStore<InfoKeyProps[]>([]).on(
	setHideBlockKeys,
	(prev, value) => [
		...prev.filter(
			(block) => block.blockKeyFoldStart !== value.blockKeyFoldStart,
		),
		value,
	],
);

export const $initialGoogleDocValue = createStore<null | any[]>([])
	.on(
		loadDocumentValueFx.doneData,
		(_, response) => response.data?.getGoogleDocContent?.text || [],
	)
	.on(loadDocumentValueFx.fail, () => [])
	.on(loadDocumentValueFx.failData, () => [])
	.on(resetDocumentValue, () => []);

export const $initialDraftDocValueRequested = createStore(false)
	.on(loadDraftDocContentFx, () => true)
	.on(resetDocumentValue, () => false);

const emptyDraftDocState = {
	blocks: [],
	entityMap: {},
};

export const $lastUpdateDraftDoc = createStore<string | null>(null).on(
	loadDraftDocContentUpdateDateFx.doneData,
	(_, response) => response.data.getDraftDocContent.updatedAt,
);

export const $initialDraftDocValue = createStore<RawDraftContentState>(
	emptyDraftDocState,
)
	.on(loadDraftDocContentFx.doneData, (_, response) => {
		const { blocks, entityMap } = response.data.getDraftDocContent;

		return {
			blocks,
			entityMap: entityMap.reduce(
				(acc, value) => ({ ...acc, [value.key]: { ...value.entity } }),
				{},
			),
		} as RawDraftContentState;
	})
	.on(docsModel.syncDraftDocContentIntoDBFx.doneData, (_, response) => {
		if (!response.data?.syncDraftDocContentIntoDB) return emptyDraftDocState;
		const { blocks, entityMap } = response.data.syncDraftDocContentIntoDB;
		return {
			blocks,
			entityMap: entityMap.reduce(
				(acc, value) => ({ ...acc, [value.key]: { ...value.entity } }),
				{},
			),
		} as RawDraftContentState;
	})
	.on(loadDraftDocContentFx.fail, () => emptyDraftDocState)
	.on(loadDraftDocContentFx.failData, () => emptyDraftDocState)
	.on(docsModel.syncDraftDocContentIntoDBFx.fail, () => emptyDraftDocState)
	.on(docsModel.syncDraftDocContentIntoDBFx.failData, () => emptyDraftDocState)
	.on(resetDocumentValue, () => emptyDraftDocState);

export const $docLastChangeBy = createStore<any>({})
	.on(
		loadIsDraftDocContentUpdatedFX.doneData,
		(_, response) => response.data.getIsDraftDocContentUpdated,
	)
	.on(loadIsDraftDocContentUpdatedFX.fail, () => ({}))
	.on(loadIsDraftDocContentUpdatedFX.failData, () => ({}))
	.on(setIsDraftDocContentUpdated, () => ({}))
	.on(resetDocumentValue, () => ({}));

export const $isDraftDocContentUpdated = createStore<boolean>(false)
	.on(
		loadIsDraftDocContentUpdatedFX.doneData,
		(_, response) => response.data.getIsDraftDocContentUpdated.isUpdated,
	)
	.on(loadIsDraftDocContentUpdatedFX.fail, () => false)
	.on(loadIsDraftDocContentUpdatedFX.failData, () => false)
	.on(setIsDraftDocContentUpdated, (_, data) => data)
	.on(resetDocumentValue, () => false);

export const $luaValue = createStore('')
	.on(validateScriptFx.doneData, (_, { data }) => data?.text || '')
	.on(validateAirTableChatScriptFx.doneData, (_, { data }) => data?.text || '')
	.on(resetDocumentValue, () => '');

export const $syntaxErrors = createStore(false).on(
	setSyntaxErrors,
	(_, value) => value,
);
export const $validationErrors = createStore<ErrorWithId[]>([])
	.on(validateScriptFx.doneData, handleErrors)
	.on(validateAirTableChatScriptFx.doneData, handleErrors)
	.on(setErrors, (_, errors) => {
		const errorsPositions = [...new Set([...(errors || [])])];

		return handleErrors(_, { errorsPositions });
	})
	.on(resetValidationErrors, () => []);

interface WarningWithId {
	id: string;
	message: string;
	line: number;
	position: number;
}
export const $validationWarnings = createStore<WarningWithId[]>([])
	.on(validateScriptFx.doneData, handleWarnings)
	.on(validateAirTableChatScriptFx.doneData, handleWarnings)
	.on(setWarnings, (_, warnings) => {
		const warningsPositions = [...new Set([...(warnings || [])])];

		return handleWarnings(_, { warningsPositions });
	})
	.on(resetValidationErrors, () => []);
export const $linesWithWarnings = combine($validationWarnings, (warnings) =>
	warnings
		.map(({ line }) => line)
		.flat()
		.filter((el) => typeof el === 'number'),
);

export const $validationErrorMessage = combine($validationErrors, (errors) => {
	return errors.map(mapMessages);
});

export const $linesWithErrors = combine($validationErrors, (errors) =>
	errors
		.map(({ line }) => line)
		.flat()
		.filter((el) => typeof el === 'number'),
);

export const $isDisabledControlButtons = createStore(true)
	.on(setIsDisabledControlButtons, (_, value) => value)
	.on(validateScriptFx.doneData, () => false)
	.on(validateAirTableChatScriptFx.doneData, () => false);

export const $isScriptEdited = createStore(false).on(
	setIsScriptEdited,
	(_, value) => value,
);

export const $isLuaEdited = createStore(false).on(
	setIsLuaEdited,
	(_, value) => value,
);

export const $isShownCommentsMarkdowns = createStore(true).on(
	setIsShownCommentsMarkdowns,
	(_, value) => value,
);
export const $currentCommentsMarkdowns = createStore<string[]>([]).on(
	setCurrentCommentsMarkdowns,
	(_, value) => value,
);
export const $isDisabledSyncToDoc = createStore(false)
	.on(setIsDisabledSyncToDoc, (_, value) => value)
	.on(loadIsDraftDocContentUpdatedFX.pending, () => false);

export const $isDeniedAccess = createStore(false)
	.on(loadIsDraftDocContentUpdatedFX.failData, () => true)
	.on(loadIsDraftDocContentUpdatedFX.doneData, () => false);

export const $redirectAction = createStore<(() => void) | null>(null).on(
	setRedirectAction,
	(_, action) => action,
);
export const $currentCommentData = createStore<CurrentCommentData | null>(
	null,
).on(setCurrentCommentData, (_, data) => data);

export const $editorSearchValue = createStore('').on(
	setEditorSearchValue,
	(_, value) => value,
);
export const $editorSearchDialogueValue = createStore(0).on(
	setEditorSearchDialogueValue,
	(_, value) => value,
);
export const $editorReplaceValue = createStore('').on(
	setEditorReplaceValue,
	(_, value) => value,
);
export const $searchType = createStore('text').on(
	setSearchType,
	(_, value) => value,
);
export const $blockToFindFocus = createStore({ from: 0, to: 0 }).on(
	setBlockToFindFocus,
	(_, value) => value,
);

export const $currentScriptInfo = createStore({
	bookId: '',
	chapterOrder: 0,
	docType: '',
	lang: '',
}).on(setCurrentScriptInfo, (_, value) => value);
