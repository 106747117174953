import React from 'react';
import { createIcon } from '@chakra-ui/react';

export const CatalogIcon = createIcon({
	viewBox: '0 0 22 22',
	path: (
		<>
			<path
				clipRule="evenodd"
				d="M5.4923 19.3967c1.9606 0 3.55-1.5893 3.55-3.5499 0-1.9606-1.5894-3.5499-3.55-3.5499s-3.55 1.5893-3.55 3.5499c0 1.9606 1.5894 3.5499 3.55 3.5499ZM11.9773 5.9492l4.417-4.417 4.4171 4.417-4.4171 4.4171-4.417-4.417Z"
				stroke="currentColor"
				strokeWidth="1.6"
				strokeLinecap="round"
				strokeLinejoin="round"
				fill="transparent"
			/>
			<path
				d="M3.001 9.495V3.1427h6.3525M18.8823 12.6709v6.3525h-6.3525"
				stroke="currentColor"
				strokeWidth="1.6"
				strokeLinecap="round"
				strokeLinejoin="round"
				fill="transparent"
			/>
		</>
	),
});
