import React, { useEffect, useRef } from 'react';
import { Box, Flex, Image as Img, VStack } from '@chakra-ui/react';

import { useAssetCardBodyByIdQuery } from 'features/hero/assets-tab/components/asset-card-body/queries.gen';
import { useAssetCardClothesByIdQuery } from 'features/hero/assets-tab/components/asset-card-clothes/queries.gen';
import { useAssetCardHairByIdQuery } from 'features/hero/assets-tab/components/asset-card-hair/queries.gen';
import { useLayersByIdsQuery } from 'features/hero/assets-tab/components/asset-card-layer/queries.gen';
import { DRAW_TYPES, PREVIEW_CONFIG } from 'shared/utils/drawCanvas/configs';
import { drawCanvas } from 'shared/utils/drawCanvas/drawCanvas';
import { SelectedAssetsInfo } from 'widgets/create-card-modal/interfaces';
import { CardQuery } from 'widgets/update-card-modal/graphql/queries.gen';
import { Background } from 'models.gen';

interface CardPreviewProps {
	golden: boolean;
	description: string;
	background: Background | CardQuery['card']['background'] | null;
	heroName: string;
	selectedAssetsInfo: SelectedAssetsInfo;
}

export const CardPreview: React.FC<CardPreviewProps> = ({
	golden,
	description,
	background,
	heroName,
	selectedAssetsInfo,
}) => {
	const canvasRef = useRef<HTMLCanvasElement | null>(null);
	const isUnScalePreview: boolean = window.innerHeight < 790;

	const { data: bodyData } = useAssetCardBodyByIdQuery({
		variables: {
			id: selectedAssetsInfo.selectedBodies[0],
		},
	});

	const { data: clothesData } = useAssetCardClothesByIdQuery({
		variables: {
			id: selectedAssetsInfo.selectedClothes[0],
		},
	});

	const { data: hairData } = useAssetCardHairByIdQuery({
		variables: {
			id: selectedAssetsInfo.selectedHairs[0],
		},
	});

	const { data: layersData } = useLayersByIdsQuery({
		variables: {
			layersIds: selectedAssetsInfo.selectedLayers[0],
		},
	});

	useEffect(() => {
		const emotions = bodyData?.body.emotions;
		drawCanvas(
			[
				background?.link || '',
				hairData?.hair.backLink || '',
				bodyData?.body.link || '',
				clothesData?.findOneClothes.link || '',
				emotions?.filter((em) => em.includes('_default'))[0] ||
					emotions?.[0] ||
					'',
				hairData?.hair.frontLink || '',
				layersData?.layersByIds[0].link || '',
			],
			{
				configs: PREVIEW_CONFIG,
				type: DRAW_TYPES.PREVIEW,
			},
			canvasRef,
		);
	}, [
		background?.link,
		bodyData?.body.emotions,
		bodyData?.body.link,
		clothesData?.findOneClothes.link,
		hairData?.hair.backLink,
		hairData?.hair.frontLink,
		layersData?.layersByIds,
	]);

	return (
		<Flex position="relative">
			<canvas
				width="338px"
				height="563px"
				style={{ borderRadius: '50px', scale: isUnScalePreview ? '0.8' : '1' }}
				ref={canvasRef}
			/>
			<Img
				position="absolute"
				left={-5}
				bottom="-25px"
				minWidth="111.5%"
				transform={isUnScalePreview ? 'scale(0.8)' : 'scale(1)'}
				height="615px"
				src={`/images/collectibles/${golden ? 'golden' : 'default'}.png`}
			/>
			<VStack
				width="95%"
				color="#fff"
				position="absolute"
				bottom={isUnScalePreview ? '95px' : '67px'}
				justifyContent="center"
			>
				<Box
					fontFamily="Nunito"
					fontWeight="700"
					mb={14}
					fontSize={isUnScalePreview ? '20px' : '24px'}
					lineHeight="24px"
				>
					{heroName || 'Hero name'}
				</Box>
				<Box
					top="40px"
					position="absolute"
					fontFamily="Nunito"
					fontSize={isUnScalePreview ? '12px' : '16px'}
					maxWidth={isUnScalePreview ? '230px' : '300px'}
					lineHeight={isUnScalePreview ? '16px' : '27px'}
					noOfLines={3}
					padding="0 20px"
					textAlign="center"
				>
					{description || '"Description"'}
				</Box>
			</VStack>
		</Flex>
	);
};
