import React from 'react';
import { Spinner } from '@chakra-ui/react';

import { AssetViewTabs } from 'widgets/update-asset-modal/lib/asset-view-tabs';

import { useGetHairQuery } from '../modal/queries.gen';
import { ViewAsset } from '../view';

interface LoadHairProps {
	assetId: string;
	activeTab: AssetViewTabs;
}

export const LoadHair: React.FC<LoadHairProps> = ({ assetId, activeTab }) => {
	const { data, loading } = useGetHairQuery({
		variables: {
			id: assetId,
		},
	});

	if (loading) return <Spinner />;

	return (
		<ViewAsset asset={data?.hair as any} chats={[]} activeTab={activeTab} />
	);
};
