import React from 'react';
import { Button, useDisclosure } from '@chakra-ui/react';

import { AssetType } from 'entities/assets/model';
import { AssetsDocument } from 'features/asset/add-assets-modal/graphql/queries.gen';
import { GetAssetsDocument } from 'pages/art-catalog/ui/ArtCatalogTable/queries.gen';
import { ButtonType, trackAssetDeleted } from 'shared/analytics';
import { useAppToast } from 'shared/hooks/toast';
import { TrashBinIcon } from 'shared/icons';
import { EntityDeletionAlert } from 'shared/ui/entity-deletion-alert';

import { useDeleteClothesMutation } from './queries.gen';

interface DeleteClothesButtonProps {
	id: string;
	onCompleted: () => void;
}

export const DeleteClothesButton: React.FC<DeleteClothesButtonProps> = ({
	id,
	onCompleted,
}) => {
	const { isOpen, onClose, onOpen } = useDisclosure();
	const toast = useAppToast();
	const [deleteClothes, { loading }] = useDeleteClothesMutation({
		variables: { id },
		onCompleted: (data) => {
			toast({ title: 'Clothes deleted successfully', status: 'success' });
			onCompleted();
			trackAssetDeleted({
				type: AssetType.CLOTHES,
				filename: data.deleteClothes?.filename as string,
				link: data.deleteClothes?.link as string,
			});
		},
		onError: () => toast({ title: 'Clothes was not deleted', status: 'error' }),
		refetchQueries: [AssetsDocument, GetAssetsDocument],
	});

	return (
		<>
			<Button
				disabled={loading}
				onClick={onOpen}
				leftIcon={<TrashBinIcon w={6} h={6} />}
			>
				Delete Clothes
			</Button>
			{isOpen && (
				<EntityDeletionAlert
					isOpen={isOpen}
					onClose={onClose}
					onSubmit={deleteClothes}
					buttonName={ButtonType.DELETE_CLOTHES}
					title="Clothes Deletion"
					description="If you delete this clothes, it can't be restored. Are you sure you want to continue?"
				/>
			)}
		</>
	);
};
