import React, { useCallback, useMemo, useRef, useState } from 'react';
import {
	Box,
	Flex,
	Grid,
	GridItem,
	IconButton,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Modal,
	ModalCloseButton,
	ModalContent,
	ModalOverlay,
	Text,
	useDisclosure,
} from '@chakra-ui/react';

import { AssetType } from 'entities/assets/model';
import { CreateAssetForm } from 'features/asset/create/ui/form';
import { AssetsHero } from 'features/hero/assets-tab/components/assets-hero';
import { PencilIcon } from 'shared/icons';

import { AddHeroAssetsModal } from '../../../../features/asset/add-assets-modal';
import { AssetViewTabs } from '../../../update-asset-modal/lib/asset-view-tabs';
import { ViewAssetTabs } from '../../../update-asset-modal/ui/tab';

interface CreateAssetModalProps {
	isOpen: boolean;
	onClose: () => void;
	assetType: AssetType;
	onAssetTypeChange: (assetType: AssetType) => void;
}

const ASSET_TYPE_TITLE_MAP: Record<AssetType, string> = {
	[AssetType.BODY]: 'Body',
	[AssetType.HAIR]: 'Hair',
	[AssetType.CLOTHES]: 'Clothes',
	[AssetType.ITEM]: 'Item',
	[AssetType.LAYER]: 'Layer',
	[AssetType.BACKGROUND]: 'Background',
	// [AssetType.CHAT_PHOTO]: 'Chat Photo',
	// [AssetType.AUDIO]: 'Audio',
};

const ASSET_TYPE_OPTIONS: { title: string; value: AssetType }[] = [
	{ value: AssetType.BODY, title: ASSET_TYPE_TITLE_MAP.body },
	{ value: AssetType.HAIR, title: ASSET_TYPE_TITLE_MAP.hair },
	{ value: AssetType.CLOTHES, title: ASSET_TYPE_TITLE_MAP.clothes },
	{ value: AssetType.ITEM, title: ASSET_TYPE_TITLE_MAP.item },
	{ value: AssetType.LAYER, title: ASSET_TYPE_TITLE_MAP.layer },
	{ value: AssetType.BACKGROUND, title: ASSET_TYPE_TITLE_MAP.background },
	// { value: AssetType.CHAT_PHOTO, title: ASSET_TYPE_TITLE_MAP.chatPhoto },
	// { value: AssetType.AUDIO, title: ASSET_TYPE_TITLE_MAP.audio },
];

const TABS_CONFIG: Record<AssetType, AssetViewTabs[]> = {
	[AssetType.BODY]: [AssetViewTabs.ASSET_DETAILS],
	[AssetType.HAIR]: [AssetViewTabs.ASSET_DETAILS],
	[AssetType.CLOTHES]: [
		AssetViewTabs.ASSET_DETAILS,
		AssetViewTabs.ALTERNATIVE_CLOTHES,
	],
	[AssetType.ITEM]: [AssetViewTabs.ASSET_DETAILS],
	[AssetType.LAYER]: [AssetViewTabs.ASSET_DETAILS],
	[AssetType.BACKGROUND]: [
		AssetViewTabs.ASSET_DETAILS,
		AssetViewTabs.ALTERNATIVE_BACKGROUNDS,
	],
	// [AssetType.CHAT_PHOTO]: [AssetViewTabs.ASSET_DETAILS],
	// [AssetType.AUDIO]: [AssetViewTabs.ASSET_DETAILS],
};

export const CreateAssetModal: React.FC<CreateAssetModalProps> = ({
	isOpen,
	onClose,
	assetType,
	onAssetTypeChange,
}) => {
	const [activeTab, setActiveTab] = useState(AssetViewTabs.ASSET_DETAILS);
	const [altClothesIds, setAltClothesIds] = useState<Array<string>>([]);
	const [altBackgroundsIds, setAltBackgroundsIds] = useState<Array<string>>([]);
	const buttonsContainerRef = useRef<HTMLDivElement>(null);
	const {
		isOpen: isOpenAssetModal,
		onOpen: onOpenAssetModal,
		onClose: onCloseAssetModal,
	} = useDisclosure();

	const handleAssetTabClick = useCallback((title: string) => {
		setActiveTab(title as AssetViewTabs);
	}, []);

	const handleSetFieldValue = useCallback(
		(field: string, value: any) => {
			if (field === 'altClothes') {
				setAltClothesIds([...value]);
				setAltBackgroundsIds([...value]);
			} else {
				setAltClothesIds([...altClothesIds, ...value]);
				setAltBackgroundsIds([...altBackgroundsIds, ...value]);
			}
		},
		[altBackgroundsIds, altClothesIds],
	);

	const tabs = useMemo(() => TABS_CONFIG[assetType as AssetType], [assetType]);

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent minW={910} m={0} overflow="hidden">
				<ModalCloseButton margin="13px 5px" borderRadius={8} />
				<Grid templateColumns="auto 1fr" h={700}>
					<GridItem>
						<Box
							borderStyle="solid"
							borderColor="#eeeef2"
							borderRightWidth="1px"
							borderBottomWidth="1px"
							w="260px"
							bg="#fbfbfc"
							h="full"
						>
							<Box p="26px 20px">
								<Text fontWeight={700} fontSize="18px" lineHeight="135%">
									New Asset
								</Text>
							</Box>
							{tabs.map((tab) => (
								<ViewAssetTabs
									key={tab}
									title={tab}
									isActive={tab === activeTab}
									onClick={handleAssetTabClick}
								/>
							))}
						</Box>
					</GridItem>
					<GridItem display="flex" flexDir="column" pb="16px">
						<Flex p="25px 24px 29px 32px" borderBottom="1px solid #eeeef2">
							<Text
								variant="h3"
								fontWeight={700}
								fontSize="18px"
								lineHeight="135%"
							>
								{activeTab}
							</Text>
						</Flex>
						<Flex
							position="relative"
							flex="1"
							flexDir="column"
							pb="30px"
							overflow="auto"
							overflowX="hidden"
						>
							<Flex
								pos="absolute"
								left="0"
								top="0"
								right="0"
								bottom="0"
								flex="1"
								flexDir="column"
							>
								<Flex py="17px" flexDir="column" flex="1">
									<Flex
										mx="24px"
										justifyContent="space-between"
										borderBottom="1px solid #EEEEF2"
									>
										{activeTab === AssetViewTabs.ASSET_DETAILS ? (
											<>
												<Box pb="10px">
													<Text mb="15px" fontSize="14px" lineHeight="16px">
														Type
													</Text>
													<Text
														p="2px 5px"
														bg="#F3F3F8"
														borderRadius="6px"
														fontSize="13px"
													>
														{ASSET_TYPE_TITLE_MAP[assetType]}
													</Text>
												</Box>
												<Menu>
													<MenuButton
														as={IconButton}
														h="32px"
														minH="32px"
														size="sm"
														aria-label="change asset type"
														variant="ghost"
														icon={<PencilIcon w="24px" h="24px" />}
													/>

													<MenuList maxH="100%">
														{ASSET_TYPE_OPTIONS.map(({ title, value }) => (
															<MenuItem
																key={value}
																onClick={() => onAssetTypeChange(value)}
															>
																{title}
															</MenuItem>
														))}
													</MenuList>
												</Menu>
											</>
										) : null}
										{activeTab === AssetViewTabs.ALTERNATIVE_CLOTHES ? (
											<AssetsHero
												defaultId=""
												title=""
												type="clothes"
												assets={altClothesIds}
												hairs={[]}
												openAddModal={onOpenAssetModal}
												setAssetType={() => {}}
												handleSetFieldValue={handleSetFieldValue}
												isAltAsset
											/>
										) : null}
										{activeTab === AssetViewTabs.ALTERNATIVE_BACKGROUNDS ? (
											<AssetsHero
												defaultId=""
												title=""
												type="background"
												assets={altBackgroundsIds}
												hairs={[]}
												openAddModal={onOpenAssetModal}
												setAssetType={() => {}}
												handleSetFieldValue={handleSetFieldValue}
												isAltAsset
											/>
										) : null}
									</Flex>
									<Flex px="24px" flexDir="column" pb="30px">
										<CreateAssetForm
											altClothesIds={altClothesIds}
											altBackgroundsIds={altBackgroundsIds}
											type={assetType}
											specialType={activeTab}
											buttonsContainerRef={buttonsContainerRef}
											onSubmit={onClose}
											onCancel={onClose}
										/>
									</Flex>
								</Flex>
							</Flex>
						</Flex>

						<Flex
							pb="3px"
							borderTop="1px solid #EEEEF2"
							ref={buttonsContainerRef}
							justifyContent="flex-end"
							id="create-asset-action-buttons-container"
						/>
					</GridItem>
				</Grid>
			</ModalContent>
			{isOpenAssetModal && (
				<AddHeroAssetsModal
					isOpen={isOpenAssetModal}
					onClose={onCloseAssetModal}
					type={assetType}
					handleSetFieldValue={handleSetFieldValue}
					altClothesIds={altClothesIds}
					// altBackgroundsIds={altBackgroundsIds}
					isAltAsset
				/>
			)}
		</Modal>
	);
};
