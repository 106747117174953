import React, { useCallback, useEffect, useRef } from 'react';
import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogCloseButton,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	Button,
} from '@chakra-ui/react';
import { useStore } from 'effector-react';

import { scriptModel } from 'entities/script';
import { uiModel } from 'entities/ui';
import { ButtonType } from 'shared/analytics';
import {
	trackClothingBeforeTextSavingModalClose,
	trackClothingWithoutLuaSavingModalClose,
} from 'shared/analytics/modals/close';
import {
	trackClothingBeforeTextSavingModalOpen,
	trackClothingWithoutLuaSavingModalOpen,
} from 'shared/analytics/modals/open';

interface CloseAlertProps {
	title?: string;
}

const CloseAlert: React.FC<CloseAlertProps> = ({ title }) => {
	const cancelRef = useRef<HTMLButtonElement>(null);

	const redirectAction = useStore(scriptModel.$redirectAction);
	const isCloseAlertShow = useStore(uiModel.$isCloseAlertShow);

	const handleAlertClose = useCallback(() => {
		uiModel.setIsCloseAlertShow(false);
		if (title) {
			trackClothingWithoutLuaSavingModalClose();
		} else {
			trackClothingBeforeTextSavingModalClose();
		}
	}, [title]);

	const handleSubmitAlert = useCallback(() => {
		if (redirectAction) redirectAction();
		uiModel.setIsCloseAlertShow(false);
		scriptModel.setIsScriptEdited(false);
		scriptModel.setIsLuaEdited(false);
	}, [redirectAction]);

	useEffect(() => {
		if (!isCloseAlertShow) return;
		if (title) {
			trackClothingWithoutLuaSavingModalOpen();
		} else {
			trackClothingBeforeTextSavingModalOpen();
		}
	}, [title, isCloseAlertShow]);
	return (
		<AlertDialog
			isOpen={isCloseAlertShow}
			leastDestructiveRef={cancelRef}
			onClose={handleAlertClose}
		>
			<AlertDialogOverlay>
				<AlertDialogContent>
					<AlertDialogHeader fontSize="lg" fontWeight="bold">
						{title
							? 'Changes were not saved to lua file'
							: 'Closing before saving'}
						<AlertDialogCloseButton
							right="24px"
							top="22px"
							w="24px"
							h="24px"
							fontSize="10px"
						/>
					</AlertDialogHeader>

					<AlertDialogBody>
						Are you sure you want to leave without saving?
					</AlertDialogBody>

					<AlertDialogFooter>
						<Button
							name={ButtonType.DISMISS_CLOSE_ALERT}
							variant="ghost"
							onClick={handleSubmitAlert}
						>
							Submit
						</Button>
						<Button
							name={ButtonType.ACCEPT_CLOSE_ALERT}
							ref={cancelRef}
							onClick={handleAlertClose}
							ml={3}
						>
							Cancel
						</Button>
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialogOverlay>
		</AlertDialog>
	);
};

export default CloseAlert;
