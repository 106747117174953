import { api } from 'shared/api';

export const statusesOptions = Object.values(api.model.CHAPTER_STATUS);

export const FORMAT_STATUS_MAP: Record<string, string> = {
	DRAFT: 'Draft',
	SCRIPT: 'Script',
	ART_DOC: 'Art Doc',
	ILLUSTRATION: 'Illustration',
	MUSIC: 'Music',
	LUA: 'Lua',
	RELEASED: 'Released',
	ART_DOC_READY: 'Art doc ready',
	DOC_READY: 'Doc ready',
	EDITS_FOR_ART: 'Edits for art',
	ILLUSTRATION_DONE: 'Illustration done',
	ILLUSTRATION_READY: 'Illustration ready',
	IN_PRODUCTION: 'In production',
	LUA_DONE: 'Lua done',
	RELEASE_DONE: 'Release done',
	SCRIPT_DONE: 'Script done',
};

export enum FORMAT_STATUS {
	// ART_DOC_READY = "ART_DOC_READY",
	// DOC_READY = "DOC_READY",
	// DRAFT = "DRAFT",
	// EDITS_FOR_ART = "EDITS_FOR_ART",
	// ILLUSTRATION_DONE = "ILLUSTRATION_DONE",
	// ILLUSTRATION_READY = "ILLUSTRATION_READY",
	// IN_PRODUCTION = "IN_PRODUCTION",
	// LUA_DONE = "LUA_DONE",
	// RELEASE_DONE = "RELEASE_DONE",
	// SCRIPT_DONE = "SCRIPT_DONE",
	DRAFT = 'DRAFT',
	SCRIPT = 'SCRIPT',
	ART_DOC = 'ART_DOC',
	ILLUSTRATION = 'ILLUSTRATION',
	MUSIC = 'MUSIC',
	LUA = 'LUA',
	RELEASED = 'RELEASED',
}
