import { useMemo } from 'react';
import { ContentState } from 'draft-js';

type UseIsArrowFunc = (contentState: ContentState, blockKey: string) => boolean;

export const useIsArrow: UseIsArrowFunc = (contentState, blockKey) => {
	return useMemo(() => {
		const start = contentState.getBlockForKey(blockKey).getText();

		return start.includes('{');
	}, [blockKey, contentState]);
};
