import amplitude from 'amplitude-js';

import { EventTypes } from '../base';
import { Modal } from './types';

export const trackModalOpen = (modal: Modal) =>
	amplitude.getInstance().logEvent(EventTypes.OPEN_MODAL, { modal });

export const trackModalClose = (modal: Modal) =>
	amplitude.getInstance().logEvent(EventTypes.CLOSE_MODAL, { modal });
