import * as Types from '../../../../../models.gen';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AssetCardHairByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type AssetCardHairByIdQuery = { hair: { frontLink?: string | null, backLink?: string | null, name?: string | null } };


export const AssetCardHairByIdDocument = gql`
    query AssetCardHairById($id: String!) {
  hair(id: $id) {
    frontLink
    backLink
    name
  }
}
    `;

/**
 * __useAssetCardHairByIdQuery__
 *
 * To run a query within a React component, call `useAssetCardHairByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssetCardHairByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssetCardHairByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAssetCardHairByIdQuery(baseOptions: Apollo.QueryHookOptions<AssetCardHairByIdQuery, AssetCardHairByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AssetCardHairByIdQuery, AssetCardHairByIdQueryVariables>(AssetCardHairByIdDocument, options);
      }
export function useAssetCardHairByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AssetCardHairByIdQuery, AssetCardHairByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AssetCardHairByIdQuery, AssetCardHairByIdQueryVariables>(AssetCardHairByIdDocument, options);
        }
export type AssetCardHairByIdQueryHookResult = ReturnType<typeof useAssetCardHairByIdQuery>;
export type AssetCardHairByIdLazyQueryHookResult = ReturnType<typeof useAssetCardHairByIdLazyQuery>;
export type AssetCardHairByIdQueryResult = Apollo.QueryResult<AssetCardHairByIdQuery, AssetCardHairByIdQueryVariables>;