import { ComponentStyleConfig } from '@chakra-ui/react';

const ACTIVE_BG = 'rgba(93,66,255,0.08)';

export const textStyles: ComponentStyleConfig = {
	baseStyle: {
		color: '#242533',
		fontWeight: 500,
	},
	variants: {
		artDocAccordion: {
			fontSize: '15px',
			fontStyle: 'normal',
			fontWeight: 600,
			lineHeight: '16px',
			w: '100%',
			p: '14px 8px 14px 16px',
			borderRadius: '8px',
			cursor: 'pointer',
			color: '#484964',
		},
		headerModal: {
			fontSize: '18px',
			fontWeight: 700,
			lineHeight: '135px',
			color: '#242533',
		},
		inputLabel: {
			fontSize: '13px',
			fontStyle: 'normal',
			fontWeight: 500,
			lineHeight: '16px',
			color: '#808192',
		},
		selectPlaceholder: {
			fontSize: '13px',
			fontStyle: 'normal',
			fontWeight: 400,
			lineHeight: '16px',
			color: '#808192',
		},
		selectValue: {
			fontSize: '13px',
			fontStyle: 'normal',
			fontWeight: 400,
			lineHeight: '16px',
			color: '#242533',
		},
		labelText: {
			fontSize: '16px',
			fontStyle: 'normal',
			fontWeight: 600,
			lineHeight: 'normal',
			color: '#808192',
		},
		tooltipText: {
			fontSize: '14px',
			fontStyle: 'normal',
			fontWeight: 400,
			lineHeight: 'normal',
			color: '#242533',
		},
		errors: {
			fontSize: '13px',
			fontStyle: 'normal',
			fontWeight: 500,
			lineHeight: '16px',
			color: '#FF4D4F',
		},
		artDocPopoverText: {
			fontSize: '13px',
			fontStyle: 'normal',
			fontWeight: 500,
			lineHeight: '16px',
			padding: '8px',
			borderRadius: '6px',
			cursor: 'pointer',
			_hover: {
				color: '#644DED',
				bg: ACTIVE_BG,
			},
		},
	},
};
