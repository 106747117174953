import * as yup from 'yup';

import { spaceValidation } from '../yup-validation';

export const updateBackgroundSchema = yup.object({
	id: yup.string().trim().required(),
	link: spaceValidation.url().nullable(),
	filename: spaceValidation.min(5).max(255).nullable(),
	dropboxLink: spaceValidation,
	visionLabels: yup.string().trim().min(5).max(255),
	keywords: yup.array().of(yup.string().trim().required()),
	newKeywords: yup
		.object()
		.shape({
			app: yup.array().nullable(),
			art_style: yup.array().nullable(),
			outside: yup.array().nullable(),
			inside: yup.array().nullable(),
			time: yup.array().nullable(),
			rooms: yup.array().nullable(),
			style: yup.array().nullable(),
			mood: yup.array().nullable(),
			extra: yup.array().nullable(),
		})
		.nullable(),
	filter: yup.string().trim().nullable(),
});

export type UpdateBackgroundValues = yup.InferType<
	typeof updateBackgroundSchema
>;
