import amplitude from 'amplitude-js';

const AMPLITUDE_KEY = process.env.REACT_APP_AMPLITUDE_KEY || '';

interface UserProperties {
	email?: string;
	version?: string;
}

export enum EventTypes {
	PAGEVIEW = 'pageview',
	OPEN_MODAL = 'open modal',
	CLOSE_MODAL = 'close modal',
	VALIDATE_CHAPTER = 'validate chapter',
	SAVE_CHAPTER = 'save chapter',
	BUTTON_CLICK = 'button click',
	CREATED_BOOK = 'story_created',
	DELETED_BOOK = 'story_deleted',
	CREATED_CHAPTER = 'chapter_created',
	DELETED_CHAPTER = 'chapter_deleted',
	CREATED_HERO = 'hero_created',
	DELETED_HERO = 'hero_deleted',
	CREATED_ASSET = 'asset_created',
	DELETED_ASSET = 'asset_deleted',
	VALIDATED_SCRIPT = 'script_validated',
	SAVED_LUA = 'lua_saved',
	SHOWN_ERROR = 'error_shown',
	CHANGED_PROPERTY = 'property_changed',

	ASKAI_CLICK = 'askai_click',
	ASKAI_OPTION_CLICK = 'askai_option_click',
	ASKAI_GENERATE_CLICK = 'askai_generate_click',
	ASKAI_REGENERATE_CLICK = 'askai_regenerate_click',
	ASKAI_COPY_CLICK = 'askai_copy_click',
	ASKAI_CLOSE_CLICK = 'askai_close_click',
	ASKAI_RESULT_SHOWN = 'askai_result_shown',
	ASKAI_ERROR_SHOWN = 'askai_error_shown',
}

export enum ButtonType {
	CREATE_BOOK = 'create book',
	CREATE_HERO = 'create hero',
	DELETE_HERO = 'delete hero',

	UPLOAD_FILE = 'upload file',
	CREATE_CHARACTER = 'create character',
	CREATE_CARD = 'create card',
	SET_CHARACTER_KEYWORDS = 'set character keywords',
	CREATE_ASSET = 'create asset',
	DELETE_COVER = 'delete cover',
	DOWNLOAD_COVER = 'download cover',
	UPLOAD_COVER = 'upload cover',
	EDIT_BOOK_BADGE = 'edit book badge',
	EDIT_STORY_TYPE = 'edit story type',
	CREATE_CHAPTER = 'create chapter',
	DISMISS_DOC_ADD_PUSH = 'dismiss doc add push',
	ACCEPT_DOC_ADD_PUSH = 'accept doc add push',
	EDITOR_BACKGROUND_COLOR = 'editor text background color',
	EDITOR_BACKGROUND_COLOR_RESET = 'editor text background color reset',
	ACCEPT_CLOSE_ALERT = 'accept close alert',
	DISMISS_CLOSE_ALERT = 'dismiss close alert',
	ADD_COMMENT = 'add comment',
	OPEN_LEGACY_EDITOR = 'open legacy editor',
	RESOLVE_COMMENT = 'resolve comment',
	EDIT_COMMENT = 'edit comment',
	UPDATE_TO_EDITOR_VERSION = 'update script to editor version',
	UPDATE_TO_GOOGLE_DOC_VERSION = 'update script to google doc version',
	EDIT_CHAPTER_HEROES = 'edit chapter heroes',
	DELETE_CHARACTER = 'delete character',
	DELETE_BOOK = 'delete book',
	DELETE_DOC_NOTES = 'delete doc notes',
	VALIDATE_SCRIPT = 'validate script',
	DOWNLOAD_LUA = 'download lua',
	ART_CATALOG_FILTER_RESET_KEYWORDS = 'reset art catalog keywords filter',
	ART_CATALOG_FILTER_APPLY_KEYWORDS = 'apply art catalog keywords filter',
	SAVE_TO_GOOGLE_DOC = 'save to google doc',
	CLEAR_CHARACTER_KEYWORDS = 'clear character keywords',
	LOAD_MORE_CHARACTERS = 'load more characters',
	OPEN_RESOURCE = 'open resource',
	EXIT_CHAPTERS_TABLE_FULLSCREEN = 'exit chapters table fullscreen',
	ENTER_CHAPTERS_TABLE_FULLSCREEN = 'enter chapters table fullscreen',
	TOGGLE_BOOK_DETAILS_SHOWN = 'toggle book details shown',
	ADD_CHARACTERS_TO_CHAPTER = 'add characters to chapter',
	ADD_HERO_TO_CARD = 'add hero to card',
	TOGGLE_AUDIO_PLAYBACK = 'toggle audio playback',
	UPLOAD_ASSET = 'upload asset',
	UPLOAD_AUDIO = 'upload audio',
	DELETE_AUDIO = 'delete audio',
	ACCEPT_IMAGE_PREVIEW = 'accept image preview',
	DELETE_ASSET_FILE = 'delete asset file',
	DOWNLOAD_ASSET_FILE = 'download asset file',
	PAGINATION = 'change pagination',
	ADD_BOOK_TO_BUILD = 'add book to build',
	CLOSE_BUILD_ALERT = 'close build error alert',
	START_BUILD = 'start build',
	APPLY_CHAPTERS = 'apply chapters',
	APPLY_CHAPTERS_SELECTION = 'apply chapters selection',
	APPLY_CHAPTERS_FOR_BUILD = 'apply chapters to build',
	CREATE_BUILD_TEMPLATE = 'create build template',
	COPY_URL_TO_CLIPBOARD = 'copy url to clipboard',
	EDIT_CHARACTER = 'edit character',
	TRANSFER_TO_BUCKET = 'transfer to bucker',
	OPEN_ASSET_IN_AIRTABLE = 'open asset in airtable',
	EDIT_TEMPLATE = 'edit template',
	EDIT_BOOK_DESCRIPTION = 'edit book description',
	EDIT_LOCALIZED_CHAPTER_DESCRIPTION = 'edit localized chapter description',
	EDIT_BOOK_NOTES = 'edit book notes',
	EDIT_BOOK_ID = 'edit book id',
	OPEN_CHARACTER_IN_AIRTABLE = 'open character in airtable',
	EDIT_LOCALIZED_BOOK_NAME = 'edit localized book name',
	EDIT_BOOK_NAME = 'edit book name',
	EDIT_LOCALIZED_CHAPTER_NAME = 'edit localized chapter name',
	EDIT_BOOK_PROCESS = 'edit book process',
	EDIT_CHAPTER_DESCRIPTION = 'edit chapter description',
	EDIT_CHAPTER_DOC_LINK = 'edit chapter doc link',
	EDIT_CHAPTER_NAME = 'edit chapter name',
	EDIT_BOOK_EXTRA = 'edit book extra',
	SELECT_HERO_NAME = 'select hero name',
	EDIT_BOOK_LOCALIZED = 'edit book localized',
	EDIT_BOOK_SERIES = 'edit book series',
	EDIT_BOOK_STATUS = 'edit book status',
	EDIT_BOOK_APP = 'edit book app',
	DOWNLOAD_AUDIO_FILE = 'download audio file',
	DELETE_AUDIO_FILE = 'delete audio file',
	OPEN_CHAPTER_IN_AIRTABLE = 'open chapter in airtable',
	TOGGLE_EMOJI_SELECT = 'toggle emoji picker',
	REMOVE_CHARACTER_PARENT = 'remove character parent',
	OPEN_GOOGLE_DOC = 'open google doc',
	OPEN_CHARACTER_BODY_IN_AIRTABLE = 'open character body oin airtable',
	OPEN_BUILD_ERROR = 'open build error',
	EDITOR_UNDO = 'editor undo',
	EDITOR_MARK_BOLD = 'editor mark bold',
	EDITOR_REDO = 'editor redo',
	EDITOR_MARK_ITALIC = 'editor mark italic',
	EDITOR_OPEN_DOCS = 'editor open docs',
	EDITOR_TOGGLE_SHOW_ERRORS = 'editor toggle show errors',
	EDIT_AUDIO_FILE_NAME = 'edit audio file name',
	EDIT_ASSET_FILE_NAME = 'edit asset file name',
	APP_OPEN_DOCS = 'app open docs',
	OPEN_ITEM_IN_AIRTABLE = 'open item in airtable',
	DELETE_ITEM_ASSET = 'delete item asset',
	DELETE_CARD = 'delete card',
	ROLLBACK_TRANSFER = 'rollback transfer',
	EDIT_BOOK_CATEGORY = 'edit book category',
	EDIT_CHAPTER_STATUS = 'edit chapter status',
	EDIT_CHAPTER_VIDEO = 'edit chapter video',
	EDIT_BOOK_STAGE = 'edit book stage',
	EDIT_BOOK_AUTHOR = 'edit book author',
	EDIT_BOOK_NARRATIVE_LEAD = 'edit book narrative lead',
	EDIT_CHAPTER_AUTHOR = 'edit chapter author',
	DELETE_CHAPTER = 'delete chapter',
	DELETE_ADDITIONAL_DOC = 'delete additional doc',
	DELETE_BACKGROUND = 'delete background',
	DELETE_ITEM = 'delete item asset',
	DELETE_CHAT_PHOTO = 'delete chat photo',
	DELETE_BODY = 'delete body',
	DELETE_EMOTION = 'delete emotion',
	DELETE_HAIR = 'delete hair',
	DELETE_CLOTHES = 'delete clothes',
	DELETE_LAYER = 'delete layer',
	DELETE_PHOTO = 'delete photo',
	EDIT_BOOK_FINISHED = 'edit book finished',
	COPY_RELEASE_NOTES = 'copy release notes',
	REPLY_COMMENT = 'reply comment',
	EDIT_CHAPTER_HAS_TWO_POVS = 'edit chapter hasTwoPovs',
}

export enum BookProperty {
	NAME = 'name',
	DESCRIPTION = 'description',
	STATUS = 'status',
	CUSTOM_ID = 'customId',
	FINISHED = 'finished',
	LOCALIZED = 'localized',
	AUTHOR = 'author',
	CATEGORY = 'category',
	NARRATIVE = 'narrative',
	BADGE = 'badge',
	TYPE = 'type',
	STAGE = 'stage',
	EXTRA = 'extra',
	APP = 'app',
	IS_VIDEO = 'isVideo',
	HAS_TWO_POWS = 'hasTwoPovs',
	SECOND_HERO_NAME = 'secondHeroName',
	MANAGEMENT_STATUS = 'managementStatus',
}

export interface StoryPropertyType {
	bookName: string;
	customBookId: string;
}

export interface ChapterPropertyType {
	customBookId: string;
	chapterName: string;
	chapterDescription?: string;
	chapterNumber: number;
}
export interface ScriptValidatePropertyType
	extends Partial<ChapterPropertyType> {
	doc: string;
	validation: 'error' | 'success';
}
export interface LuaSavedPropertyType extends Partial<ChapterPropertyType> {
	doc: string;
	link: string;
}

export interface HeroPropertyType {
	customId: number;
	names: string[];
	stories: string[];
}
export interface AssetPropertyType {
	filename: string;
	type: 'body' | 'hair' | 'clothes' | 'layer' | 'background' | 'item';
	link: string;
}
export interface BookChangedPropertyType {
	entity: 'book' | 'chapter';
	property: BookProperty | string;
	value: string | string[];
	id: string;
}
export const init = () => {
	amplitude.getInstance().init(AMPLITUDE_KEY);
};

export const setUserId = (userId: string) => {
	amplitude.getInstance().setUserId(userId);
};

export const setUserProperties = (properties: UserProperties) => {
	amplitude.getInstance().setUserProperties(properties);
};

export const sendAmplitudeData = (
	eventType: EventTypes,
	eventProperties: Record<string, any>,
) => {
	amplitude.getInstance().logEvent(eventType, eventProperties);
};

export const trackPageview = (path: string) =>
	amplitude.getInstance().logEvent(EventTypes.PAGEVIEW, { path });
