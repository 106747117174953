import React, { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
	Button,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Tooltip,
} from '@chakra-ui/react';

import { scriptModel } from 'entities/script';
import { ArrowDownIcon } from 'shared/icons';

import { ChapterByIdQuery, useChapterByIdQuery } from '../header/queries.gen';

export const useChapterLocalization = (
	docId: string,
	chapter?: ChapterByIdQuery['chapter'],
) => {
	if (!chapter) return 'EN';

	switch (docId) {
		case chapter?.googleDoc2PovDE?.id:
		case chapter?.googleDocDE?.id: {
			return 'DE';
		}
		case chapter?.googleDoc2PovES?.id:
		case chapter?.googleDocES?.id: {
			return 'ES';
		}
		case chapter?.googleDoc2PovFR?.id:
		case chapter?.googleDocFR?.id: {
			return 'FR';
		}
		case chapter?.googleDoc2PovNL?.id:
		case chapter?.googleDocNL?.id: {
			return 'NL';
		}
		case chapter?.googleDoc2PovPT?.id:
		case chapter?.googleDocPT?.id: {
			return 'PT';
		}
		case chapter?.googleDoc2PovIT?.id:
		case chapter?.googleDocIT?.id: {
			return 'IT';
		}
		case chapter?.googleDoc2PovPL?.id:
		case chapter?.googleDocPL?.id: {
			return 'PL';
		}
		case chapter?.googleDoc2PovSV?.id:
		case chapter?.googleDocSV?.id: {
			return 'SV';
		}
		case chapter?.googleDoc2PovDA?.id:
		case chapter?.googleDocDA?.id: {
			return 'DA';
		}
		case chapter?.googleDoc2PovNO?.id:
		case chapter?.googleDocNO?.id: {
			return 'NO';
		}
		case chapter?.googleDoc2PovFI?.id:
		case chapter?.googleDocFI?.id: {
			return 'FI';
		}
		case chapter?.googleDoc2PovID?.id:
		case chapter?.googleDocID?.id: {
			return 'ID';
		}
		case chapter?.googleDoc2PovCS?.id:
		case chapter?.googleDocCS?.id: {
			return 'CS';
		}
		case chapter?.googleDoc2PovRO?.id:
		case chapter?.googleDocRO?.id: {
			return 'RO';
		}
		case chapter?.googleDoc2PovHU?.id:
		case chapter?.googleDocHU?.id: {
			return 'HU';
		}
		case chapter?.googleDoc2PovTR?.id:
		case chapter?.googleDocTR?.id: {
			return 'TR';
		}
		case chapter?.googleDocJA?.id:
		case chapter?.googleDoc2PovJA?.id: {
			return 'JA';
		}
		case chapter?.googleDocKO?.id:
		case chapter?.googleDoc2PovKO?.id: {
			return 'KO';
		}
		case chapter?.googleDocPT_BR?.id:
		case chapter?.googleDoc2PovPT_BR?.id: {
			return 'PT-BR';
		}

		case chapter?.googleDoc?.id:
		case chapter?.googleDoc2Pov?.id:
		default: {
			return 'EN';
		}
	}
};

export interface LangSelectorProps {
	googleDocId: string;
	localizedGoogleDocLinks: any;
}

export const LangSelector: React.FC<LangSelectorProps> = ({
	googleDocId,
	localizedGoogleDocLinks,
}) => {
	const { bookId, chapterId } = useParams();
	const navigate = useNavigate();

	const { data } = useChapterByIdQuery({
		variables: { id: chapterId as string },
	});

	const localization = useChapterLocalization(googleDocId, data?.chapter);

	const availableLocalizedGoogleDocLinks = useMemo(
		() =>
			Object.fromEntries(
				Object.entries(localizedGoogleDocLinks).filter(([, link]) => link),
			),
		[localizedGoogleDocLinks],
	);

	return (
		<Menu>
			{({ isOpen }) => (
				<>
					<Tooltip label="Localization">
						<MenuButton
							fontSize="13px"
							color="#484964"
							fontWeight={500}
							as={Button}
							variant="ghost"
							size="sm"
							rightIcon={
								<ArrowDownIcon
									w={5}
									h={5}
									ml={1}
									transform={isOpen ? 'rotate(180deg)' : 'none'}
									transition="ease"
									transitionDuration="200ms"
								/>
							}
						>
							{localization}
						</MenuButton>
					</Tooltip>
					<MenuList minW="136px">
						{Object.keys(availableLocalizedGoogleDocLinks).map((lang) => (
							<MenuItem
								key={lang}
								onClick={() => {
									navigate(
										`/stories/${bookId}/${chapterId}/${
											availableLocalizedGoogleDocLinks[lang] || ''
										}`,
									);
									scriptModel.setIsDisabledControlButtons(true);
								}}
							>
								{lang}
							</MenuItem>
						))}
					</MenuList>
				</>
			)}
		</Menu>
	);
};
