import React, { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Flex } from '@chakra-ui/react';
import { debounce } from 'lodash';

import { LayoutForBlockWithSidebar } from 'shared/ui';
import { HeaderWithBreadcrumbs } from 'widgets/header-with-breadcrumbs';
import { FilterKeywords } from 'widgets/keywords-filter-modal/constant';
import { NavigationSidebar } from 'widgets/navigation-sidebar';

import ArtCatalogBody from './ui/ArtCatalogBody';
import ArtCatalogHeader from './ui/ArtCatalogHeader';

export type filterKeywordsTypes = 'has any of' | 'has all of' | 'has none of';

export const KEYWORDS_FILTER_TYPES: Record<string, filterKeywordsTypes> = {
	HAS_ALL_OF: 'has all of',
	HAS_ANY_OF: 'has any of',
	HAS_NONE_OF: 'has none of',
};

const ArtCatalogPage: React.FC = () => {
	const [selectedCategory, setSelectedCategory] = useState<
		string | undefined
	>();
	const [currentSearch, setCurrentSearch] = useState('');
	const [search, setSearch] = useState('');
	const [filterKeywords, setFilterKeywords] = useState<FilterKeywords | null>(
		null,
	);
	const [filterKeywordsType, setFilterKeywordsType] =
		useState<filterKeywordsTypes>(KEYWORDS_FILTER_TYPES.HAS_ALL_OF);
	const [selectedBook, setSelectedBook] = useState<undefined | string>(
		undefined,
	);

	const [page, setPage] = useState(0);

	// clear keywords on type change
	useEffect(() => {
		setFilterKeywords(null);
		setFilterKeywordsType(KEYWORDS_FILTER_TYPES.HAS_ALL_OF);
	}, [selectedCategory]);

	const debouncedSetSearch = useMemo(
		() =>
			debounce((v) => {
				setSearch(v);
				setFilterKeywords(null);
			}, 2000),
		[setSearch],
	);

	useEffect(() => {
		debouncedSetSearch(currentSearch);

		return () => debouncedSetSearch.cancel();
	}, [debouncedSetSearch, currentSearch]);

	useEffect(
		() => setPage(0),
		[selectedCategory, search, filterKeywords, selectedBook],
	);

	return (
		<LayoutForBlockWithSidebar>
			<Helmet>
				<title>My Fantasy - Art Catalog</title>
			</Helmet>
			<NavigationSidebar />
			<Flex w="full" h="full" flexDirection="column" bg="#FBFBFC">
				<HeaderWithBreadcrumbs
					breadcrumbs={[{ name: 'Art Catalog', to: '/art-catalog' }]}
					linkDocs="https://coda.io/d/_d3cwMKQBqfE/Art-Catalog_suG-A"
				/>
				<ArtCatalogHeader search={currentSearch} setSearch={setCurrentSearch} />
				<ArtCatalogBody
					selectedCategory={selectedCategory}
					setSelectedCategory={setSelectedCategory}
					search={search}
					page={page}
					setPage={setPage}
					filterKeywords={filterKeywords}
					filterKeywordsType={filterKeywordsType}
					setFilterKeywords={setFilterKeywords}
					setFilterKeywordsType={setFilterKeywordsType}
					selectedBook={selectedBook}
					setSelectedBook={setSelectedBook}
					type={selectedCategory}
				/>
			</Flex>
		</LayoutForBlockWithSidebar>
	);
};

export default ArtCatalogPage;
