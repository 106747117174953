import React, { useMemo } from 'react';
import { Flex, Grid, Image, Text } from '@chakra-ui/react';

import { formatDate } from 'shared/utils';

interface UserCommentCardProps {
	author: string;
	picture: string | null | undefined;
	createdAt: string | number;
}

export const UserCommentCard: React.FC<UserCommentCardProps> = ({
	author,
	picture,
	createdAt,
}) => {
	const authorName = useMemo(() => author.split('@')[0], [author]);

	return (
		<Grid templateColumns="31px 1fr" gap="10px" alignItems="center">
			{picture ? (
				<Image
					src={picture}
					alt="Author"
					borderRadius="full"
					width="31px"
					height="31px"
				/>
			) : (
				<Flex
					bg="purple"
					borderRadius="full"
					width="31px"
					height="31px"
					align="center"
					justify="center"
					color="#ffffff"
				>
					{authorName?.slice(0, 1)?.toUpperCase() || '---'}
				</Flex>
			)}
			<Flex direction="column">
				<Text fontWeight="600" fontSize="15px" overflow="hidden">
					{authorName.length < 20
						? authorName
						: `${authorName.slice(0, 20)}...`}
				</Text>
				<Text color="#808192" fontSize="12px">
					{formatDate(createdAt)}
				</Text>
			</Flex>
		</Grid>
	);
};
