import React from 'react';
import { createIcon } from '@chakra-ui/react';

export const CommunityIcon = createIcon({
	viewBox: '0 0 24 24',
	path: (
		<g fill="none">
			<path
				id="Vector"
				d="M9.37901 3.41083C8.85413 3.14808 8.26037 3 7.63158 3C5.49511 3 3.76316 4.70946 3.76316 6.81818C3.76316 8.92691 5.49511 10.6364 7.63158 10.6364C8.28813 10.6364 8.90648 10.4749 9.4481 10.1901M1 21C1 17.385 3.96906 14.4545 7.63158 14.4545M15.3684 10.6364C17.5049 10.6364 19.2368 8.92691 19.2368 6.81818C19.2368 4.70946 17.5049 3 15.3684 3C13.232 3 11.5 4.70946 11.5 6.81818C11.5 8.92691 13.232 10.6364 15.3684 10.6364ZM8.73684 21C8.73684 17.385 11.7059 14.4545 15.3684 14.4545C19.0309 14.4545 22 17.385 22 21H8.73684Z"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</g>
	),
});
