import React from 'react';
import {
	Button,
	Divider,
	Menu,
	MenuButton,
	MenuItemOption,
	MenuList,
	MenuOptionGroup,
	useDisclosure,
} from '@chakra-ui/react';

import { SortIcon } from 'shared/icons';

import { sortOptions } from '../../constants/sortOptions';

interface Props {
	sort: string;
	setSort: (v: string) => void;
}

const ArtCatalogSort: React.FC<Props> = ({ sort, setSort }) => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	return (
		<Menu isOpen={isOpen} onOpen={onOpen} onClose={onClose} variant="sort">
			<MenuButton
				as={Button}
				variant="secondary"
				bg="rgba(100, 77, 237, 0.08)"
				color="#644ded"
				leftIcon={<SortIcon marginRight="8px" />}
			>
				Sort by: {sortOptions.find(({ value }) => value === sort)?.title}
			</MenuButton>
			<MenuList>
				<MenuOptionGroup
					value={sort}
					defaultValue="asc"
					title="Order"
					type="radio"
				>
					{sortOptions.map(({ title, value }) => (
						<>
							<MenuItemOption
								key={value}
								value={value}
								onClick={() => setSort(value)}
								icon={null}
								background={
									sort === value ? 'rgba(93,66,255,0.08)' : 'transparent'
								}
								color={sort === value ? '#644ded' : '#242533'}
								_focus={{
									background:
										sort === value ? 'rgba(93,66,255,0.08)' : 'transparent',
								}}
							>
								{title}
							</MenuItemOption>
							{value === 'used-asc' && <Divider mb="15px" h="3px" />}
						</>
					))}
				</MenuOptionGroup>
			</MenuList>
		</Menu>
	);
};

export default ArtCatalogSort;
