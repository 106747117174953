import React from 'react';
import { Helmet } from 'react-helmet';

import { Routing } from 'pages';
import { analytics } from 'shared';

import { withProviders } from './providers';

import './styles/index.scss';

// ! START FIX FOR MOBILE IOS
const setCustomVH = () => {
	const vh = window.innerHeight * 0.01;
	// Then we set the value in the --vh custom property to the root of the document
	document.documentElement.style.setProperty('--vh', `${vh}px`);
};
setCustomVH();
window.addEventListener('resize', setCustomVH);
// ! END FIX FOR MOBILE IOS

analytics.init();

const App = () => (
	<>
		<Helmet>
			<title>My Fantasy - Stories HUB</title>
		</Helmet>
		<Routing />
	</>
);

export default withProviders(App);
