export const backgroundsStyleMap = {
	'#000000': {
		backgroundColor: '#000000',
	},
	'#434343': {
		backgroundColor: '#434343',
	},
	'#666666': {
		backgroundColor: '#666666',
	},
	'#999999': {
		backgroundColor: '#999999',
	},
	'#B7B7B7': {
		backgroundColor: '#B7B7B7',
	},
	'#CCCCCC': {
		backgroundColor: '#CCCCCC',
	},
	'#D9D9D9': {
		backgroundColor: '#D9D9D9',
	},
	'#EFEFEF': {
		backgroundColor: '#EFEFEF',
	},
	'#F3F3F3': {
		backgroundColor: '#F3F3F3',
	},
	'#FFFFFF': {
		backgroundColor: '#FFFFFF',
	},
	'#980000': {
		backgroundColor: '#980000',
	},
	'#FF0000': {
		backgroundColor: '#FF0000',
	},
	'#FF9900': {
		backgroundColor: '#FF9900',
	},
	'#FFFF00': {
		backgroundColor: '#FFFF00',
	},
	'#00FF00': {
		backgroundColor: '#00FF00',
	},
	'#00FFFF': {
		backgroundColor: '#00FFFF',
	},
	'#4A86E8': {
		backgroundColor: '#4A86E8',
	},
	'#0000FF': {
		backgroundColor: '#0000FF',
	},
	'#9900FF': {
		backgroundColor: '#9900FF',
	},
	'#FF00FF': {
		backgroundColor: '#FF00FF',
	},
	'#E6B8AF': {
		backgroundColor: '#E6B8AF',
	},
	'#F4CCCC': {
		backgroundColor: '#F4CCCC',
	},
	'#FCE5CD': {
		backgroundColor: '#FCE5CD',
	},
	'#FFF2CC': {
		backgroundColor: '#FFF2CC',
	},
	'#D9EAD3': {
		backgroundColor: '#D9EAD3',
	},
	'#D0E0E3': {
		backgroundColor: '#D0E0E3',
	},
	'#C9DAF8': {
		backgroundColor: '#C9DAF8',
	},
	'#CFE2F3': {
		backgroundColor: '#CFE2F3',
	},
	'#D9D2E9': {
		backgroundColor: '#D9D2E9',
	},
	'#EAD1DC': {
		backgroundColor: '#EAD1DC',
	},
	'#DD7E6B': {
		backgroundColor: '#DD7E6B',
	},
	'#EA9999': {
		backgroundColor: '#EA9999',
	},
	'#F9CB9C': {
		backgroundColor: '#F9CB9C',
	},
	'#FFE599': {
		backgroundColor: '#FFE599',
	},
	'#B6D7A8': {
		backgroundColor: '#B6D7A8',
	},
	'#A2C4C9': {
		backgroundColor: '#A2C4C9',
	},
	'#A4C2F4': {
		backgroundColor: '#A4C2F4',
	},
	'#9FC5E8': {
		backgroundColor: '#9FC5E8',
	},
	'#B4A7D6': {
		backgroundColor: '#B4A7D6',
	},
	'#D5A6BD': {
		backgroundColor: '#D5A6BD',
	},
	'#CC4125': {
		backgroundColor: '#CC4125',
	},
	'#E06666': {
		backgroundColor: '#E06666',
	},
	'#F6B26B': {
		backgroundColor: '#F6B26B',
	},
	'#FFD966': {
		backgroundColor: '#FFD966',
	},
	'#93C47D': {
		backgroundColor: '#93C47D',
	},
	'#76A5AF': {
		backgroundColor: '#76A5AF',
	},
	'#6D9EEB': {
		backgroundColor: '#6D9EEB',
	},
	'#6FA8DC': {
		backgroundColor: '#6FA8DC',
	},
	'#8E7CC3': {
		backgroundColor: '#8E7CC3',
	},
	'#C27BA0': {
		backgroundColor: '#C27BA0',
	},
	'#A61C00': {
		backgroundColor: '#A61C00',
	},
	'#CC0000': {
		backgroundColor: '#CC0000',
	},
	'#E69138': {
		backgroundColor: '#E69138',
	},
	'#F1C232': {
		backgroundColor: '#F1C232',
	},
	'#6AA84F': {
		backgroundColor: '#6AA84F',
	},
	'#45818E': {
		backgroundColor: '#45818E',
	},
	'#3C78D8': {
		backgroundColor: '#3C78D8',
	},
	'#3D85C6': {
		backgroundColor: '#3D85C6',
	},
	'#674EA7': {
		backgroundColor: '#674EA7',
	},
	'#A64D79': {
		backgroundColor: '#A64D79',
	},
	'#85200C': {
		backgroundColor: '#85200C',
	},
	'#990000': {
		backgroundColor: '#990000',
	},
	'#B45F06': {
		backgroundColor: '#B45F06',
	},
	'#BF9000': {
		backgroundColor: '#BF9000',
	},
	'#38761D': {
		backgroundColor: '#38761D',
	},
	'#134F5C': {
		backgroundColor: '#134F5C',
	},
	'#1155CC': {
		backgroundColor: '#1155CC',
	},
	'#0B5394': {
		backgroundColor: '#0B5394',
	},
	'#351C75': {
		backgroundColor: '#351C75',
	},
	'#741B47': {
		backgroundColor: '#741B47',
	},
	'#5B0F00': {
		backgroundColor: '#5B0F00',
	},
	'#660000': {
		backgroundColor: '#660000',
	},
	'#783F04': {
		backgroundColor: '#783F04',
	},
	'#7F6000': {
		backgroundColor: '#7F6000',
	},
	'#274E13': {
		backgroundColor: '#274E13',
	},
	'#0C343D': {
		backgroundColor: '#0C343D',
	},
	'#1C4587': {
		backgroundColor: '#1C4587',
	},
	'#073763': {
		backgroundColor: '#073763',
	},
	'#20124D': {
		backgroundColor: '#20124D',
	},
	'#4C1130': {
		backgroundColor: '#4C1130',
	},
};
