import React, { useMemo } from 'react';
import { Tooltip } from '@chakra-ui/react';
import { useField } from 'formik';

import { TextArea } from '../text-area';
import styles from './styles.module.scss';

interface Props {
	label?: string;
	name: string;
	required?: boolean;
	placeholder?: string;
	autocomplete?: string;
	rows?: number;
}

export const FormTextArea: React.FC<Props> = ({
	label,
	required = false,
	name,
	rows,
	placeholder,
	autocomplete,
}) => {
	const [field, meta, { setTouched }] = useField({ name });

	const isError: boolean = useMemo(
		() => !!(meta.touched && meta.error),
		[meta],
	);

	return (
		<div className={styles.container}>
			{label && (
				<div className={styles.labelContainer}>
					{required && <div className={styles.requiredMark}>*</div>}
					<label className={styles.label}>{label}</label>
				</div>
			)}
			<Tooltip
				label={meta.error}
				isDisabled={!isError}
				placement="bottom-start"
				bg="#ff218c"
				offset={[0, 2]}
			>
				<TextArea
					placeholder={`${required ? '*' : ''}${placeholder}`}
					isError={isError}
					{...field}
					rows={rows}
					onFocus={() => {
						setTouched(true);
					}}
					autoComplete={autocomplete}
				/>
			</Tooltip>
		</div>
	);
};
