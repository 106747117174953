import React from 'react';
import {
	Box,
	Flex,
	Heading,
	Menu,
	MenuItem,
	MenuList,
	useDisclosure,
} from '@chakra-ui/react';

import { ArrowDownIcon } from 'shared/icons';

import { changeToneOptions, PROMPTS_MAP } from '../types';

interface PromptsDropdownProps {
	isHeader?: boolean;
	handleAiModalOpen: (v: string, option?: string) => void;
}

export const PromptsDropdown: React.FC<PromptsDropdownProps> = ({
	isHeader,
	handleAiModalOpen,
}) => {
	const { isOpen, onOpen, onClose } = useDisclosure();

	return (
		<MenuList
			p="0 !important"
			zIndex={1000}
			maxH="none"
			maxW="270px"
			overflowY="hidden"
		>
			{isHeader && (
				<Heading
					px={6}
					py={3}
					fontSize="15px"
					bg="#fdfdfd"
					fontWeight={600}
					userSelect="none"
				>
					Ask AI
				</Heading>
			)}
			<Box px={2} py={1} lineHeight="18px">
				<MenuItem
					onClick={() => handleAiModalOpen(PROMPTS_MAP.IMPROVE_WRITING)}
				>
					{PROMPTS_MAP.IMPROVE_WRITING}
				</MenuItem>

				<MenuItem onClick={() => handleAiModalOpen(PROMPTS_MAP.FIX)}>
					{PROMPTS_MAP.FIX}
				</MenuItem>
				<MenuItem borderBottom="1px solid rgba(0, 0, 0, 0.08)">
					<Menu isOpen={isOpen}>
						<Flex
							w="100%"
							onMouseEnter={onOpen}
							onMouseLeave={onClose}
							justify="space-between"
						>
							{PROMPTS_MAP.CHANGE_TONE}
							<ArrowDownIcon w={5} h={5} transform="rotate(-90deg)" />
						</Flex>
						<MenuList
							position="absolute"
							zIndex={1200}
							top={isHeader ? '110px' : '70px'}
							left="240px"
							minWidth="160px !important"
							maxH="none"
							overflowY="hidden"
							onMouseEnter={onOpen}
							onMouseLeave={onClose}
						>
							{changeToneOptions.map((option) => (
								<MenuItem
									color="#000"
									onClick={() =>
										handleAiModalOpen(PROMPTS_MAP.CHANGE_TONE, option)
									}
								>
									{option}
								</MenuItem>
							))}
						</MenuList>
					</Menu>
				</MenuItem>
				<MenuItem onClick={() => handleAiModalOpen(PROMPTS_MAP.PARAPHRASING)}>
					{PROMPTS_MAP.PARAPHRASING}
				</MenuItem>
				<MenuItem
					onClick={() => handleAiModalOpen(PROMPTS_MAP.REPHRASING)}
					borderBottom="1px solid rgba(0, 0, 0, 0.08)"
				>
					{PROMPTS_MAP.REPHRASING}
				</MenuItem>
				<MenuItem onClick={() => handleAiModalOpen(PROMPTS_MAP.OUTLINE)}>
					{PROMPTS_MAP.OUTLINE}
				</MenuItem>
				<MenuItem
					onClick={() => handleAiModalOpen(PROMPTS_MAP.CHECKING)}
					borderBottom="1px solid rgba(0, 0, 0, 0.08)"
				>
					{PROMPTS_MAP.CHECKING}
				</MenuItem>

				<MenuItem onClick={() => handleAiModalOpen(PROMPTS_MAP.CUSTOM)}>
					{PROMPTS_MAP.CUSTOM}
				</MenuItem>
			</Box>
		</MenuList>
	);
};
