import * as Types from '../../../../../models.gen';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AssetCardBackgroundByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type AssetCardBackgroundByIdQuery = { background: { link?: string | null, name?: string | null } };

export type UnlinkAltBackgroundMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
  parentId?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type UnlinkAltBackgroundMutation = { unlinkAltBackground: string };


export const AssetCardBackgroundByIdDocument = gql`
    query AssetCardBackgroundById($id: String!) {
  background(id: $id) {
    link
    name
  }
}
    `;

/**
 * __useAssetCardBackgroundByIdQuery__
 *
 * To run a query within a React component, call `useAssetCardBackgroundByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssetCardBackgroundByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssetCardBackgroundByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAssetCardBackgroundByIdQuery(baseOptions: Apollo.QueryHookOptions<AssetCardBackgroundByIdQuery, AssetCardBackgroundByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AssetCardBackgroundByIdQuery, AssetCardBackgroundByIdQueryVariables>(AssetCardBackgroundByIdDocument, options);
      }
export function useAssetCardBackgroundByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AssetCardBackgroundByIdQuery, AssetCardBackgroundByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AssetCardBackgroundByIdQuery, AssetCardBackgroundByIdQueryVariables>(AssetCardBackgroundByIdDocument, options);
        }
export type AssetCardBackgroundByIdQueryHookResult = ReturnType<typeof useAssetCardBackgroundByIdQuery>;
export type AssetCardBackgroundByIdLazyQueryHookResult = ReturnType<typeof useAssetCardBackgroundByIdLazyQuery>;
export type AssetCardBackgroundByIdQueryResult = Apollo.QueryResult<AssetCardBackgroundByIdQuery, AssetCardBackgroundByIdQueryVariables>;
export const UnlinkAltBackgroundDocument = gql`
    mutation UnlinkAltBackground($id: String!, $parentId: String) {
  unlinkAltBackground(id: $id, parentId: $parentId)
}
    `;
export type UnlinkAltBackgroundMutationFn = Apollo.MutationFunction<UnlinkAltBackgroundMutation, UnlinkAltBackgroundMutationVariables>;

/**
 * __useUnlinkAltBackgroundMutation__
 *
 * To run a mutation, you first call `useUnlinkAltBackgroundMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnlinkAltBackgroundMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unlinkAltBackgroundMutation, { data, loading, error }] = useUnlinkAltBackgroundMutation({
 *   variables: {
 *      id: // value for 'id'
 *      parentId: // value for 'parentId'
 *   },
 * });
 */
export function useUnlinkAltBackgroundMutation(baseOptions?: Apollo.MutationHookOptions<UnlinkAltBackgroundMutation, UnlinkAltBackgroundMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnlinkAltBackgroundMutation, UnlinkAltBackgroundMutationVariables>(UnlinkAltBackgroundDocument, options);
      }
export type UnlinkAltBackgroundMutationHookResult = ReturnType<typeof useUnlinkAltBackgroundMutation>;
export type UnlinkAltBackgroundMutationResult = Apollo.MutationResult<UnlinkAltBackgroundMutation>;
export type UnlinkAltBackgroundMutationOptions = Apollo.BaseMutationOptions<UnlinkAltBackgroundMutation, UnlinkAltBackgroundMutationVariables>;