import React, { useMemo } from 'react';
import {
	Flex,
	Popover,
	PopoverBody,
	PopoverContent,
	PopoverTrigger,
	Text,
	useDisclosure,
} from '@chakra-ui/react';

import { ArrowRightSmallIcon } from 'shared/icons';

import { useBookChaptersQuery } from '../../../features/hero/chapters-select/queries.gen';
import { ChaptersPopover } from './chapters-popover';

type Chapter = {
	id: string;
	name?: string | null | undefined;
	chapterOrder?: number | null | undefined;
};

interface BookPopoverProps {
	bookId: string;
	name: string | null | undefined;
	selectedChapters: readonly Chapter[] | null | undefined;
	handleSubmit: (chapters: Chapter[]) => Promise<void>;
}

export const BookPopover: React.FC<BookPopoverProps> = ({
	bookId,
	name,
	selectedChapters,
	handleSubmit,
}) => {
	const { onOpen, onClose, isOpen } = useDisclosure();
	const { data } = useBookChaptersQuery({
		variables: { id: bookId },
	});

	const chaptersDisplay = useMemo(() => {
		if (
			data?.book.chapters?.length === selectedChapters?.length &&
			data?.book.chapters?.length !== 0
		)
			return 'All';

		return [...(selectedChapters || [])]
			.sort((a, b) => (a.chapterOrder || 0) - (b.chapterOrder || 0))
			.reduce((acc, chapter) => {
				if (!acc) return `${chapter.chapterOrder}`;
				return `${acc}, ${chapter.chapterOrder}`;
			}, '');
	}, [data?.book.chapters?.length, selectedChapters]);
	return (
		<Popover
			isLazy
			boundary="scrollParent"
			isOpen={isOpen}
			onOpen={onOpen}
			onClose={onClose}
			placement="right"
			offset={[150, -30]}
		>
			<PopoverTrigger>
				<Flex
					borderRadius="6px"
					p="9px 6px"
					key={bookId}
					align="center"
					cursor="pointer"
					gap="30px"
					bg={isOpen ? '#F4F4F8' : 'inherit'}
					_hover={{
						bg: '#F4F4F8',
					}}
				>
					<Text flex={1}>{name}</Text>
					<Text
						fontSize="13px"
						lineHeight="16px"
						color="#808192"
						overflow="hidden"
						whiteSpace="nowrap"
						textOverflow="ellipsis"
						maxW="75px"
					>
						{chaptersDisplay}
					</Text>
					<ArrowRightSmallIcon w="24px" h="24px" />
				</Flex>
			</PopoverTrigger>
			<PopoverContent maxW={290} maxH={337}>
				<PopoverBody>
					<Text
						p="5px 0 21px"
						fontWeight=" 500"
						fontSize="14px"
						lineHeight="20px"
					>
						{name}
					</Text>
					{isOpen && (
						<ChaptersPopover
							bookId={bookId}
							selectedChapters={selectedChapters}
							handleSubmit={handleSubmit}
							onClose={onClose}
						/>
					)}
				</PopoverBody>
			</PopoverContent>
		</Popover>
	);
};
