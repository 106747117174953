import React from 'react';
import { createIcon } from '@chakra-ui/react';

export const FilterIcon = createIcon({
	viewBox: '0 0 24 24',
	path: (
		<>
			<path
				d="m17.3743 6.6131.5496.4335-.5496-.4335ZM8.8809 19.4438l-.313-.6261.313.6261Zm-1.4236-7.0161v6.5689h1.4v-6.5689h-1.4Zm1.7366 7.6422 3.6316-1.8156-.6261-1.2523-3.6315 1.8157.626 1.2522Zm4.2949-2.889v-4.7532h-1.4v4.7532h1.4Zm-.1503-4.3198 4.5854-5.8145-1.0993-.867-4.5854 5.8146 1.0993.8669Zm3.6431-7.7576H4.3433v1.4h12.6383v-1.4ZM3.3711 7.007l4.219 5.831 1.1343-.8207-4.2191-5.831-1.1343.8207Zm9.4177 4.7207H8.1573v1.4h4.6315v-1.4ZM4.3432 5.1035c-.9794 0-1.5463 1.11-.9722 1.9035l1.1343-.8207c.0957.1322.0012.3172-.162.3172v-1.4Zm13.5807 1.943c.6207-.787.0601-1.943-.9423-1.943v1.4c-.167 0-.2604-.1927-.157-.3238l1.0993.8669Zm-5.0984 11.2078a1.2001 1.2001 0 0 0 .6633-1.0734h-1.4a.2.2 0 0 1 .1106-.1789l.6261 1.2523Zm-5.3682.7423c0 .8921.9388 1.4723 1.7366 1.0733l-.626-1.2522a.2.2 0 0 1 .2894.1789h-1.4ZM15.6697 14.1777a.7.7 0 1 0 0 1.4v-1.4Zm5.1162 1.4a.7.7 0 1 0 0-1.4v1.4Zm-5.1162 0h5.1162v-1.4h-5.1162v1.4Z"
				fill="currentColor"
			/>
			<path
				d="M18.9283 12.3211a.7.7 0 1 0-1.4 0h1.4Zm-1.4 5.1162a.7.7 0 1 0 1.4 0h-1.4Zm0-5.1162v5.1162h1.4v-5.1162h-1.4Z"
				fill="currentColor"
			/>
		</>
	),
});
