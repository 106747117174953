import React, { Dispatch, SetStateAction } from 'react';
import { Box, Checkbox, Flex, Image, Spinner, Text } from '@chakra-ui/react';

import { useAssetCardClothesByIdQuery } from 'features/hero/assets-tab/components/asset-card-clothes/queries.gen';
import { CheckmarkIcon } from 'shared/icons';
import { SelectedAssetsInfo } from 'widgets/create-card-modal/interfaces';

interface AssetCardClothesProps {
	defaultId?: string;
	assetId: string;
	setSelectedAssetsInfo?: Dispatch<SetStateAction<SelectedAssetsInfo>>;
	isActive?: boolean;
}

export const AssetCardClothes: React.FC<AssetCardClothesProps> = ({
	defaultId,
	assetId,
	isActive,
	setSelectedAssetsInfo,
}) => {
	const { data, loading } = useAssetCardClothesByIdQuery({
		variables: {
			id: assetId,
		},
	});

	const handleChange = () =>
		setSelectedAssetsInfo
			? setSelectedAssetsInfo((prev: SelectedAssetsInfo) => ({
					...prev,
					selectedClothes: isActive
						? prev.selectedClothes.filter((el) => el !== assetId)
						: [...prev.selectedClothes, assetId],
			  }))
			: null;

	return (
		<Flex flexDirection="column" gap={2} minW="240px" maxW="260px">
			<Box
				h="min-content"
				userSelect="none"
				border="4px solid transparent"
				borderRadius="10px"
				position="relative"
			>
				<Flex
					height="300px"
					alignItems="center"
					justifyContent="center"
					border="1px solid #EEEEF2"
					borderRadius="10px"
					overflow="hidden"
					borderColor={isActive ? '#644DED' : ''}
					_hover={{
						borderColor: '#644DED',
						cursor: 'pointer',
					}}
				>
					{loading ? (
						<Spinner size="md" />
					) : (
						<Box
							position="relative"
							top="-10%"
							transform="scale(0.9)"
							onClick={handleChange}
						>
							<Image
								alt={data?.findOneClothes.name || ''}
								src={data?.findOneClothes.link || ''}
							/>
						</Box>
					)}
				</Flex>

				{defaultId === assetId ? (
					<Flex
						position="absolute"
						top={1}
						left={1}
						w={10}
						h={10}
						justify="center"
						alignItems="center"
					>
						<CheckmarkIcon w={4} h={4} color="#A0AEC0" />
					</Flex>
				) : null}

				{!loading && (
					<Box position="absolute" top={1} right={1}>
						<Checkbox m="10px" isChecked={isActive} onChange={handleChange} />
					</Box>
				)}
			</Box>
			<Text p={1} fontSize="12px" fontWeight={600} lineHeight="16px">
				{data?.findOneClothes.name || ''}
			</Text>
		</Flex>
	);
};
