import React from 'react';
import { Portal } from '@chakra-ui/react';
import { Formik } from 'formik';

import { AssetType } from 'entities/assets/model';
import { ImageReview } from 'entities/assets/ui/image-review';
import { GetAssetsDocument } from 'pages/art-catalog/ui/ArtCatalogTable/queries.gen';
import { trackAssetCreated } from 'shared/analytics';
import { useGetAssetError } from 'shared/hooks/asset-error';
import { useAppToast } from 'shared/hooks/toast';
import { InputField, InputFieldWithLink } from 'shared/ui';
import { getFilenameFromURL } from 'shared/utils/get-filename-from-url';
import { FilterKeywords } from 'widgets/keywords-filter-modal/constant';

import { KeywordsPicker } from '../../../keywords-picker';
import { createHairSchema, CreateHairValues } from '../../lib/schemas/hair';
import { useCreateHairMutation } from '../../queries.gen';
import { ActionButtons } from '../action-buttons';
import { CreateAnyAssetFormProps } from '../types';

export const CreateHairForm: React.FC<CreateAnyAssetFormProps> = ({
	onSubmit,
	onCancel,
	buttonsContainerRef,
}) => {
	const toast = useAppToast();
	const handleError = useGetAssetError();

	const [createHair] = useCreateHairMutation({
		onCompleted: (data) => {
			const keywords = Object.values(
				data.createHair?.newKeywords as FilterKeywords,
			)
				.filter((v) => v)
				.flat();

			if (!keywords.length) {
				toast({
					title: `Hair was created without keywords`,
					status: 'warning',
				});
			} else {
				toast({ title: 'Hair created successfully', status: 'success' });
			}
			onSubmit();
			trackAssetCreated({
				type: AssetType.HAIR,
				filename: data.createHair?.filename as string,
				link: data.createHair?.frontLink as string,
			});
		},
		onError: (mutationError) => handleError(mutationError.message, 'Hair'),
		refetchQueries: [GetAssetsDocument],
	});

	return (
		<Formik<CreateHairValues>
			initialValues={{
				frontLink: '',
				backLink: '',
				filename: '',
				newHair: false,
				keywords: [],
				dropboxLink: '',
				newKeywords: {},
			}}
			validationSchema={createHairSchema}
			onSubmit={(data) => createHair({ variables: { hair: data } })}
		>
			{({ values, submitForm, setFieldValue, errors, isSubmitting }) => (
				<>
					<InputField
						label="Filename"
						name="filename"
						value={
							getFilenameFromURL(values.frontLink) ||
							values.filename?.trim() ||
							''
						}
						onApply={(v: string | number) => setFieldValue('filename', v, true)}
						error={errors.filename}
						placeholder=" hair_f_0.png"
						isDisabledFilename={!!values.frontLink}
					/>
					<ImageReview
						label="Front hair"
						type={AssetType.HAIR}
						imageSrc={values.frontLink?.trim() || ''}
						onImageChange={(link) =>
							setFieldValue('frontLink', link?.trim(), true)
						}
						error={errors.frontLink}
					/>
					<ImageReview
						label="Back hair"
						type={AssetType.HAIR}
						imageSrc={values.backLink?.trim() || ''}
						onImageChange={(link) =>
							setFieldValue('backLink', link?.trim(), true)
						}
						error={errors.backLink}
					/>
					<KeywordsPicker
						type={AssetType.HAIR}
						selectedKeywords={values.newKeywords}
						onApply={(v) => setFieldValue('newKeywords', v, true)}
					/>
					<InputFieldWithLink
						label="Link"
						name="dropboxLink"
						value={values.dropboxLink?.trim() || ''}
						onApply={(v: string | number) =>
							setFieldValue('dropboxLink', v, true)
						}
						error={errors.dropboxLink}
						placeholder="https://..."
					/>
					<Portal containerRef={buttonsContainerRef}>
						<ActionButtons
							onApply={submitForm}
							onCancel={onCancel}
							disabled={isSubmitting}
						/>
					</Portal>
				</>
			)}
		</Formik>
	);
};
