import React, { useState } from 'react';
import {
	Button,
	Flex,
	Popover,
	PopoverBody,
	PopoverContent,
	PopoverTrigger,
	Text,
	useDisclosure,
} from '@chakra-ui/react';

import { ButtonType } from 'shared/analytics';
import { PencilIcon } from 'shared/icons';
import { UpdateTagsForm } from 'shared/ui';

interface SelectHeroNameProps {
	options: string[];
	handleSetFieldValue: (
		field: string,
		value: any,
		shouldValidate?: any,
	) => void;
	heroName: string;
}

export const SelectHeroName: React.FC<SelectHeroNameProps> = ({
	options,
	handleSetFieldValue,
	heroName,
}) => {
	const [selectedHeroName, setSelectedHeroName] = useState('');
	const { onOpen, onClose, isOpen } = useDisclosure();

	const onSubmit = () => {
		handleSetFieldValue('heroName', selectedHeroName);
		onClose();
	};
	return (
		<Flex alignItems="center">
			<Text mr={2}>{heroName}</Text>
			<Popover
				isOpen={isOpen}
				onOpen={onOpen}
				onClose={onClose}
				placement="top-end"
				offset={[5, 10]}
			>
				<PopoverTrigger>
					<Button size="circle" variant="secondary">
						<PencilIcon w={6} h={6} />
					</Button>
				</PopoverTrigger>
				<PopoverContent>
					<PopoverBody>
						<UpdateTagsForm
							submitButtonName={ButtonType.SELECT_HERO_NAME}
							formTitle="Choose a hero name"
							onClose={onClose}
							options={options}
							selectedOptions={[selectedHeroName]}
							disableTags
							isMulti={false}
							onChange={(selected) => setSelectedHeroName(selected[0])}
							onSubmit={() => onSubmit()}
						/>
					</PopoverBody>
				</PopoverContent>
			</Popover>
		</Flex>
	);
};
