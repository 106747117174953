import React, { useRef } from 'react';
import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	Button,
} from '@chakra-ui/react';

import { ButtonType } from 'shared/analytics';

interface NoDocAlertProps {
	isOpen: boolean;
	onClose: () => void;
	onAccept: () => void;
}

export const NoDocAlert: React.FC<NoDocAlertProps> = ({
	isOpen,
	onClose,
	onAccept,
}) => {
	const cancelRef = useRef(null);

	return (
		<AlertDialog
			isOpen={isOpen}
			leastDestructiveRef={cancelRef}
			onClose={onClose}
		>
			<AlertDialogOverlay>
				<AlertDialogContent>
					<AlertDialogHeader fontSize="lg" fontWeight="bold">
						Warning!
					</AlertDialogHeader>

					<AlertDialogBody>
						The chapter isn&apos;t linked with GoogleDocs. Please add GoogleDocs
						link in order to save changes
					</AlertDialogBody>

					<AlertDialogFooter>
						<Button
							name={ButtonType.DISMISS_DOC_ADD_PUSH}
							variant="ghost"
							ref={cancelRef}
							onClick={onClose}
						>
							Add Later
						</Button>
						<Button
							name={ButtonType.ACCEPT_DOC_ADD_PUSH}
							onClick={onAccept}
							ml={3}
						>
							Add GoogleDoc
						</Button>
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialogOverlay>
		</AlertDialog>
	);
};
