import React from 'react';
import { createIcon } from '@chakra-ui/react';

export const FileClockIcon = createIcon({
	viewBox: '0 0 24 24',
	path: (
		<g fill="none">
			<path
				d="M9.875 18.875H4.875C4.54348 18.875 4.22554 18.7433 3.99112 18.5089C3.7567 18.2745 3.625 17.9565 3.625 17.625V3.875C3.625 3.54348 3.7567 3.22554 3.99112 2.99112C4.22554 2.7567 4.54348 2.625 4.875 2.625H13.732C14.0632 2.6252 14.3808 2.75684 14.615 2.991L17.009 5.385C17.2432 5.61922 17.3748 5.9368 17.375 6.268V8.875"
				stroke="currentColor"
				strokeWidth="1.25"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M7.37501 6.68652C7.43663 6.68672 7.49682 6.70516 7.54797 6.73952C7.59913 6.77388 7.63896 6.82262 7.66245 6.87959C7.68594 6.93656 7.69204 6.99921 7.67997 7.05964C7.6679 7.12006 7.6382 7.17557 7.59463 7.21914C7.55105 7.26271 7.49555 7.29241 7.43512 7.30448C7.3747 7.31655 7.31205 7.31045 7.25508 7.28696C7.19811 7.26347 7.14937 7.22364 7.11501 7.17248C7.08065 7.12133 7.06221 7.06115 7.06201 6.99952C7.06201 6.95855 7.07008 6.91798 7.08576 6.88013C7.10144 6.84227 7.12442 6.80788 7.15339 6.77891C7.18237 6.74993 7.21676 6.72695 7.25461 6.71127C7.29247 6.69559 7.33304 6.68752 7.37401 6.68752"
				stroke="currentColor"
				strokeWidth="1.25"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M13 16.25C13 17.14 13.2639 18.01 13.7584 18.7501C14.2529 19.4901 14.9557 20.0669 15.7779 20.4075C16.6002 20.7481 17.505 20.8372 18.3779 20.6635C19.2508 20.4899 20.0526 20.0613 20.682 19.432C21.3113 18.8026 21.7399 18.0008 21.9135 17.1279C22.0872 16.255 21.9981 15.3502 21.6575 14.5279C21.3169 13.7057 20.7401 13.0029 20.0001 12.5084C19.26 12.0139 18.39 11.75 17.5 11.75C16.3065 11.75 15.1619 12.2241 14.318 13.068C13.4741 13.9119 13 15.0565 13 16.25V16.25Z"
				stroke="currentColor"
				strokeWidth="1.25"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M19.21 16.75H17V14.541"
				stroke="currentColor"
				strokeWidth="1.25"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M13.5 10.5579L12.375 8.87489C12.3188 8.79007 12.2426 8.72029 12.1533 8.67164C12.0639 8.62298 11.9639 8.59693 11.8622 8.59575C11.7604 8.59456 11.6599 8.61829 11.5694 8.66485C11.4789 8.71141 11.4012 8.7794 11.343 8.86289L9.677 11.2389L8.65 10.4169C8.58236 10.3627 8.50417 10.3231 8.42041 10.3007C8.33665 10.2783 8.24915 10.2736 8.16347 10.2869C8.07779 10.3001 7.99581 10.3311 7.92273 10.3777C7.84965 10.4244 7.78709 10.4858 7.739 10.5579L6.125 13.8749H11.125"
				stroke="currentColor"
				strokeWidth="1.25"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</g>
	),
});
