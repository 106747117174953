import * as Types from '../../../models.gen';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateDocsNotesMutationVariables = Types.Exact<{
  docsNotes: Types.CreateDocsNotesRequestDto;
  docId: Types.Scalars['String'];
}>;


export type CreateDocsNotesMutation = { createDocsNotes: { id: string } };

export type GetDocsNotesByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type GetDocsNotesByIdQuery = { docNotes?: { id: string, name?: string | null, link?: string | null, description?: string | null, isActive?: boolean | null } | null };

export type UpdateDocsNotesMutationVariables = Types.Exact<{
  docsNotes: Types.UpdateDocsNotesRequestDto;
}>;


export type UpdateDocsNotesMutation = { updateDocsNotes: { id: string } };

export type DeleteDocsNotesMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type DeleteDocsNotesMutation = { deleteDocsNotes: string };


export const CreateDocsNotesDocument = gql`
    mutation CreateDocsNotes($docsNotes: CreateDocsNotesRequestDto!, $docId: String!) {
  createDocsNotes(docsNotes: $docsNotes, docId: $docId) {
    id
  }
}
    `;
export type CreateDocsNotesMutationFn = Apollo.MutationFunction<CreateDocsNotesMutation, CreateDocsNotesMutationVariables>;

/**
 * __useCreateDocsNotesMutation__
 *
 * To run a mutation, you first call `useCreateDocsNotesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDocsNotesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDocsNotesMutation, { data, loading, error }] = useCreateDocsNotesMutation({
 *   variables: {
 *      docsNotes: // value for 'docsNotes'
 *      docId: // value for 'docId'
 *   },
 * });
 */
export function useCreateDocsNotesMutation(baseOptions?: Apollo.MutationHookOptions<CreateDocsNotesMutation, CreateDocsNotesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDocsNotesMutation, CreateDocsNotesMutationVariables>(CreateDocsNotesDocument, options);
      }
export type CreateDocsNotesMutationHookResult = ReturnType<typeof useCreateDocsNotesMutation>;
export type CreateDocsNotesMutationResult = Apollo.MutationResult<CreateDocsNotesMutation>;
export type CreateDocsNotesMutationOptions = Apollo.BaseMutationOptions<CreateDocsNotesMutation, CreateDocsNotesMutationVariables>;
export const GetDocsNotesByIdDocument = gql`
    query GetDocsNotesById($id: String!) {
  docNotes(id: $id) {
    id
    name
    link
    description
    isActive
  }
}
    `;

/**
 * __useGetDocsNotesByIdQuery__
 *
 * To run a query within a React component, call `useGetDocsNotesByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocsNotesByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocsNotesByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDocsNotesByIdQuery(baseOptions: Apollo.QueryHookOptions<GetDocsNotesByIdQuery, GetDocsNotesByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDocsNotesByIdQuery, GetDocsNotesByIdQueryVariables>(GetDocsNotesByIdDocument, options);
      }
export function useGetDocsNotesByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDocsNotesByIdQuery, GetDocsNotesByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDocsNotesByIdQuery, GetDocsNotesByIdQueryVariables>(GetDocsNotesByIdDocument, options);
        }
export type GetDocsNotesByIdQueryHookResult = ReturnType<typeof useGetDocsNotesByIdQuery>;
export type GetDocsNotesByIdLazyQueryHookResult = ReturnType<typeof useGetDocsNotesByIdLazyQuery>;
export type GetDocsNotesByIdQueryResult = Apollo.QueryResult<GetDocsNotesByIdQuery, GetDocsNotesByIdQueryVariables>;
export const UpdateDocsNotesDocument = gql`
    mutation UpdateDocsNotes($docsNotes: UpdateDocsNotesRequestDto!) {
  updateDocsNotes(docsNotes: $docsNotes) {
    id
  }
}
    `;
export type UpdateDocsNotesMutationFn = Apollo.MutationFunction<UpdateDocsNotesMutation, UpdateDocsNotesMutationVariables>;

/**
 * __useUpdateDocsNotesMutation__
 *
 * To run a mutation, you first call `useUpdateDocsNotesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDocsNotesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDocsNotesMutation, { data, loading, error }] = useUpdateDocsNotesMutation({
 *   variables: {
 *      docsNotes: // value for 'docsNotes'
 *   },
 * });
 */
export function useUpdateDocsNotesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDocsNotesMutation, UpdateDocsNotesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDocsNotesMutation, UpdateDocsNotesMutationVariables>(UpdateDocsNotesDocument, options);
      }
export type UpdateDocsNotesMutationHookResult = ReturnType<typeof useUpdateDocsNotesMutation>;
export type UpdateDocsNotesMutationResult = Apollo.MutationResult<UpdateDocsNotesMutation>;
export type UpdateDocsNotesMutationOptions = Apollo.BaseMutationOptions<UpdateDocsNotesMutation, UpdateDocsNotesMutationVariables>;
export const DeleteDocsNotesDocument = gql`
    mutation DeleteDocsNotes($id: String!) {
  deleteDocsNotes(id: $id)
}
    `;
export type DeleteDocsNotesMutationFn = Apollo.MutationFunction<DeleteDocsNotesMutation, DeleteDocsNotesMutationVariables>;

/**
 * __useDeleteDocsNotesMutation__
 *
 * To run a mutation, you first call `useDeleteDocsNotesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDocsNotesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDocsNotesMutation, { data, loading, error }] = useDeleteDocsNotesMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDocsNotesMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDocsNotesMutation, DeleteDocsNotesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDocsNotesMutation, DeleteDocsNotesMutationVariables>(DeleteDocsNotesDocument, options);
      }
export type DeleteDocsNotesMutationHookResult = ReturnType<typeof useDeleteDocsNotesMutation>;
export type DeleteDocsNotesMutationResult = Apollo.MutationResult<DeleteDocsNotesMutation>;
export type DeleteDocsNotesMutationOptions = Apollo.BaseMutationOptions<DeleteDocsNotesMutation, DeleteDocsNotesMutationVariables>;