import * as yup from 'yup';

import { spaceValidation } from 'features/asset/update/lib/yup-validation';

export const createClothesSchema = yup.object({
	link: spaceValidation
		.url()
		.required('Clothes is a required field')
		.nullable(),
	filename: spaceValidation.min(5).max(255),
	dropboxLink: spaceValidation,
	price: yup.number().integer(),
	keywords: yup.array().of(yup.string().trim().required()).required(),
	newKeywords: yup
		.object()
		.shape({
			sex: yup.array().nullable(),
			app: yup.array().nullable(),
			art_style: yup.array().nullable(),
			body_type: yup.array().nullable(),
			age: yup.array().nullable(),
			style: yup.array().nullable(),
			color_range: yup.array().nullable(),
			material: yup.array().nullable(),
			nudity: yup.array().nullable(),
			top: yup.array().nullable(),
			bottom: yup.array().nullable(),
			adjective: yup.array().nullable(),
			extra: yup.array().nullable(),
		})
		.nullable(),
});

export type CreateClothesValues = yup.InferType<typeof createClothesSchema>;
