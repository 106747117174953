import React, {
	Dispatch,
	RefObject,
	SetStateAction,
	useCallback,
	useMemo,
	useRef,
} from 'react';
import {
	Button,
	Flex,
	Grid,
	GridItem,
	Heading,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	Spinner,
	Tab,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
	Text,
	VStack,
} from '@chakra-ui/react';
import { FormikProps, useFormikContext } from 'formik';

import { ButtonType } from 'shared/analytics';
import { TriangleDownIcon } from 'shared/icons';
import { SelectedAssetsInfo } from 'widgets/create-card-modal/interfaces';
import { AssetsTab } from 'widgets/create-card-modal/ui/assets-tab';
import { CardPreview } from 'widgets/create-card-modal/ui/general-tab/card-preview';
import { getCardsAmount } from 'widgets/create-card-modal/utils/getCardsAmount';
import { getIsDisabledSave } from 'widgets/create-card-modal/utils/getIsDisabledSave';
import { UpdateCardFormikParams } from 'widgets/update-card-modal/interfaces';

import { GeneralTab } from '../general-tab';

interface CardModalContentProps {
	bookId?: string | null;
	isLoading: boolean;
	isCardLoading: boolean;
	selectedAssetsInfo: SelectedAssetsInfo;
	setSelectedAssetsInfo: Dispatch<SetStateAction<SelectedAssetsInfo>>;
	onClose: () => void;
	handleModalClose: () => void;
	setIsCardGenerating: (v: boolean) => void;
	handleCardsDownload: () => void;
	handleSubmitForm: () => void;
}

export const CardModalContent: React.FC<CardModalContentProps> = ({
	bookId,
	isLoading,
	isCardLoading,
	selectedAssetsInfo,
	setSelectedAssetsInfo,
	handleModalClose,
	handleSubmitForm,
	handleCardsDownload,
}) => {
	const formik: FormikProps<UpdateCardFormikParams> = useFormikContext();
	const elGeneralTab = useRef<HTMLDivElement>(null);
	const elAssetsTab = useRef<HTMLDivElement>(null);

	const isDisabledSave = useMemo(
		() => getIsDisabledSave(selectedAssetsInfo),
		[selectedAssetsInfo],
	);

	const cardsAmount = useMemo(
		() => getCardsAmount(selectedAssetsInfo),
		[selectedAssetsInfo],
	);
	const handleSetFieldValue = useCallback(
		(field: string, value: any) => {
			formik.setFieldValue(field, value, true);
		},
		[formik],
	);

	const handleScrollTop = useCallback((el: RefObject<HTMLDivElement>) => {
		if (el.current) el.current.scrollIntoView({ behavior: 'smooth' });
	}, []);

	return (
		<ModalContent
			h="100%"
			w="100%"
			maxW="calc(100vw - 138px)"
			maxH="calc(100vh - 30px)"
			m={0}
			overflow="hidden"
		>
			<ModalCloseButton />
			<Grid
				flex="1"
				gridTemplateColumns="1fr 2fr"
				gridTemplateRows="76px 1fr"
				templateAreas={`'heading form' 'hero form'`}
				borderBottom="1px solid #eeeef2"
				overflow="auto"
			>
				<GridItem area="heading" borderBottom="1px solid #eeeef2">
					<Text as={Heading} fontSize="18px !important" p="25px 24px 26px">
						Card details
					</Text>
				</GridItem>
				<GridItem area="form" borderLeft="1px solid #eeeef2">
					<Tabs h="100%" d="flex" flexDir="column">
						<TabList px="38px" h="76px" gap="40px">
							<Tab onClick={() => handleScrollTop(elGeneralTab)}>General</Tab>
							{formik.values.hero && (
								<Tab onClick={() => handleScrollTop(elAssetsTab)}>Assets</Tab>
							)}
						</TabList>
						<VStack
							align="stretch"
							as={TabPanels}
							flex="1"
							overflow="auto"
							overflowX="hidden"
						>
							{isCardLoading ? (
								<Spinner size="md" />
							) : (
								<TabPanel p="16px 40px 16px 38px" h="100%" ref={elGeneralTab}>
									<GeneralTab
										bookId={bookId || ''}
										handleSetFieldValue={handleSetFieldValue}
										{...formik.values}
										errors={formik.errors}
									/>
								</TabPanel>
							)}
							{formik.values.hero && (
								<TabPanel p="16px 38px" ref={elAssetsTab}>
									<AssetsTab
										bodies={
											formik.values.hero?.bodies?.map((el) => el.id) || []
										}
										clothes={formik.values.hero?.clothes.map((el) => el.id)}
										hairs={formik.values.hero?.hairs.map((el) => el.id)}
										layers={formik.values.hero?.layers.map((el) => el.id)}
										selectedAssetsInfo={selectedAssetsInfo}
										setSelectedAssetsInfo={setSelectedAssetsInfo}
									/>
								</TabPanel>
							)}
						</VStack>
					</Tabs>
				</GridItem>
				<GridItem area="hero" overflow="hidden" minWidth="400px">
					<VStack align="center" justify="center" height="100%">
						<CardPreview
							{...formik.values}
							selectedAssetsInfo={selectedAssetsInfo}
						/>
					</VStack>
				</GridItem>
			</Grid>
			<ModalFooter display="flex" justifyContent="space-between">
				<Flex fontSize="14px">
					{formik.values.hero && (
						<>
							<Text fontWeight={600}>Will generate: </Text>
							<Text ml={2}>{cardsAmount} card(s)</Text>
						</>
					)}
				</Flex>
				<Flex>
					<Button variant="ghost" mr={3} onClick={handleModalClose}>
						Cancel
					</Button>
					<Menu>
						{({ isOpen: isOpenMenu }) => (
							<>
								<MenuButton
									size="lg"
									as={Button}
									disabled={!formik.isValid || isLoading || isDisabledSave}
									rightIcon={
										<TriangleDownIcon
											transform={isOpenMenu ? 'rotate(180deg)' : 'none'}
											transition="ease"
											transitionDuration="200ms"
										/>
									}
								>
									Save
								</MenuButton>
								<MenuList minW="max-content">
									<MenuItem
										name={ButtonType.CREATE_CARD}
										onClick={handleSubmitForm}
									>
										Save card(s)
									</MenuItem>
									<MenuItem onClick={handleCardsDownload}>
										Download card(s)
									</MenuItem>
								</MenuList>
							</>
						)}
					</Menu>
				</Flex>
			</ModalFooter>
		</ModalContent>
	);
};
