/* eslint-disable sonarjs/cognitive-complexity */
import React, { useCallback, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
	Flex,
	Grid,
	GridItem,
	IconButton,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Spinner,
	Text,
	Tooltip,
	useDisclosure,
} from '@chakra-ui/react';

import { uiModel } from 'entities/ui';
import { GetBookDocument } from 'features/book/queries.gen';
import {
	useGenerateChapterDocMutation,
	useUpdateChapterDocMutation,
} from 'features/chapter/update/queries.gen';
import { ChapterByIdDocument } from 'features/story-editor/header/queries.gen';
import { ButtonType, trackPropertyChanged } from 'shared/analytics';
import { api } from 'shared/api';
import { useAppToast } from 'shared/hooks/toast';
import { PlusIcon } from 'shared/icons';
import { FormWithLink } from 'shared/ui';
import { DocNotesData } from 'shared/ui/edit-doc-description';
import { CreateDocNotesModal } from 'widgets/doc-description-modal';
import { UpdateDocNotesModal } from 'widgets/update-doc-description-modal';

import { updateDocLinkSchema } from '../../schema';

interface Props {
	chapterId: string;
	label: string;
	initialDocLink?: string;
	docLinkToUpdate:
		| 'googleDoc'
		| 'googleDocFR'
		| 'googleDocDE'
		| 'googleDocES'
		| 'googleDocNL'
		| 'googleDocPT'
		| 'googleDocIT'
		| 'googleDocPL'
		| 'googleDocSV'
		| 'googleDocDA'
		| 'googleDocNO'
		| 'googleDocFI'
		| 'googleDocID'
		| 'googleDocCS'
		| 'googleDocRO'
		| 'googleDocHU'
		| 'googleDocTR'
		| 'googleDocJA'
		| 'googleDocKO'
		| 'googleDocPT_BR'
		| 'googleDocWriter'
		| 'googleDocNarrative'
		| 'googleDoc2Pov'
		| 'googleDoc2PovFR'
		| 'googleDoc2PovDE'
		| 'googleDoc2PovES'
		| 'googleDoc2PovNL'
		| 'googleDoc2PovPT'
		| 'googleDoc2PovIT'
		| 'googleDoc2PovPL'
		| 'googleDoc2PovSV'
		| 'googleDoc2PovDA'
		| 'googleDoc2PovNO'
		| 'googleDoc2PovFI'
		| 'googleDoc2PovID'
		| 'googleDoc2PovCS'
		| 'googleDoc2PovRO'
		| 'googleDoc2PovHU'
		| 'googleDoc2PovTR'
		| 'googleDoc2PovJA'
		| 'googleDoc2PovKO'
		| 'googleDoc2PovPT_BR'
		| 'googleDocABTest1'
		| 'googleDocABTest2'
		| 'googleDocABTest3';
	iconLink?: boolean;
	bookId: string;
	language:
		| 'en'
		| 'ru'
		| 'es'
		| 'pt'
		| 'fr'
		| 'de'
		| 'nl'
		| 'it'
		| 'pl'
		| 'sv'
		| 'da'
		| 'no'
		| 'fi'
		| 'id'
		| 'cs'
		| 'ro'
		| 'hu'
		| 'tr'
		| 'ja'
		| 'ko'
		| 'pt-BR';
	docId?: string;
	docType:
		| 'prod'
		| 'writer'
		| 'narrative'
		| 'prod-2pov'
		| 'googleDocABTest1'
		| 'googleDocABTest2'
		| 'googleDocABTest3';
	isDescription?: boolean;
	docNotesData?: DocNotesData | null;
	handleRedirect?: (v: string) => void;
}

export const UpdateDocLinkForm: React.FC<Props> = ({
	chapterId,
	label,
	initialDocLink = '',
	docLinkToUpdate,
	iconLink,
	bookId,
	language,
	docType,
	isDescription = false,
	docId,
	docNotesData,
	handleRedirect,
}) => {
	const { googleDocId } = useParams();
	const toast = useAppToast();
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const [isRedacting, setIsRedacting] = useState(false);
	const [isDocGenerating, setIsDocGenerating] = useState(false);
	const startRedacting = useCallback(() => setIsRedacting(true), []);
	const stopRedacting = useCallback(() => setIsRedacting(false), []);

	const { isOpen, onClose, onOpen } = useDisclosure();
	const {
		isOpen: isOpenUpdateModal,
		onClose: onCloseUpdateModal,
		onOpen: onOpenUpdateModal,
	} = useDisclosure();

	const [updateChapter, { loading: updateLoading }] =
		useUpdateChapterDocMutation({
			refetchQueries: [ChapterByIdDocument, GetBookDocument],
		});
	const [generateChapterDoc] = useGenerateChapterDocMutation({
		refetchQueries: [ChapterByIdDocument, GetBookDocument],
	});

	const updateDocLink = useCallback(
		async ({ formValue }) => {
			try {
				await api.docs.getValidationAccessFile({
					fileId: formValue.split('/')[5],
				});
				const isValidLink = formValue.endsWith('/edit');
				const correctLink = isValidLink ? formValue : `${formValue}/edit`;
				await updateChapter({
					variables: {
						chapter: {
							id: chapterId,
							[docLinkToUpdate]: iconLink
								? correctLink
								: {
										link: (correctLink || '').trim(),
										prevLink: initialDocLink,
								  },
						},
					},
				});
				trackPropertyChanged({
					entity: 'chapter',
					property: docLinkToUpdate,
					value: (correctLink || '').trim(),
					id: chapterId,
				});
				stopRedacting();
				toast({
					title: 'Link updated successfully',
					status: 'success',
				});

				if (
					pathname.split('/').length === 5 &&
					googleDocId === initialDocLink.split('/')[5]
				) {
					navigate(
						`/stories/${bookId}/${chapterId}/${formValue?.split('/')[5]}`,
					);
					uiModel.setIsUpdateChapterModalOpened(false);
				}
			} catch (e) {
				const error = e as Error;
				if (error.message) {
					toast({
						title: error.message,
						status: 'error',
					});
				} else {
					toast({
						title: 'Link was not updated',
						status: 'error',
					});
				}
			}
		},
		[
			bookId,
			chapterId,
			docLinkToUpdate,
			googleDocId,
			iconLink,
			initialDocLink,
			navigate,
			pathname,
			stopRedacting,
			toast,
			updateChapter,
		],
	);

	const generateDocLink = useCallback(async () => {
		setIsDocGenerating(true);
		try {
			await generateChapterDoc({
				variables: {
					bookId,
					chapterId,
					config: {
						language,
						type: docType,
					},
				},
			});

			stopRedacting();
			setIsDocGenerating(false);
			toast({
				title: 'Link generated successfully',
				status: 'success',
			});
		} catch {
			setIsDocGenerating(false);
			toast({
				title: 'Link was not updated',
				status: 'error',
			});
		}
	}, [
		bookId,
		chapterId,
		docType,
		generateChapterDoc,
		language,
		stopRedacting,
		toast,
	]);

	const deleteProcess = useCallback(async () => {
		try {
			await updateChapter({
				variables: {
					chapter: {
						id: chapterId,
						[docLinkToUpdate]: iconLink
							? ''
							: { link: '', prevLink: initialDocLink },
					},
				},
			});
			stopRedacting();
			toast({
				title: 'Link deleted successfully',
				status: 'success',
			});
			if (handleRedirect) {
				handleRedirect(initialDocLink.split('/')[5] || '');
			}
		} catch {
			toast({
				title: 'Link was not deleted',
				status: 'error',
			});
		}
	}, [
		chapterId,
		docLinkToUpdate,
		iconLink,
		initialDocLink,
		handleRedirect,
		stopRedacting,
		toast,
		updateChapter,
	]);

	return initialDocLink || isRedacting ? (
		<>
			<FormWithLink
				submitButtonName={ButtonType.EDIT_CHAPTER_DOC_LINK}
				label={label}
				inputValue={initialDocLink || ''}
				isRedacting={isRedacting}
				startRedacting={startRedacting}
				validationSchema={updateDocLinkSchema}
				onSubmit={updateDocLink}
				onDelete={deleteProcess}
				iconLink={iconLink}
				isDescription={isDescription}
				onOpen={onOpen}
				onOpenUpdateModal={onOpenUpdateModal}
				docNotesData={docNotesData}
				isDocUpdate={updateLoading}
			/>
			<CreateDocNotesModal
				documentId={docId || ''}
				isOpen={isOpen}
				onClose={onClose}
				docType={docType.includes('AB') ? 'A/B' : 'Additional Doc'}
				label={label}
			/>
			<UpdateDocNotesModal
				isOpen={isOpenUpdateModal}
				onClose={onCloseUpdateModal}
				docType={docType.includes('AB') ? 'A/B' : 'Additional Doc'}
				label={label}
				docNotesData={docNotesData}
			/>
		</>
	) : (
		<Grid
			borderBottom="1px solid #eeeef2"
			height="50px"
			gridTemplateColumns="1fr 83px"
			alignItems="center"
		>
			<GridItem
				fontWeight="500"
				fontSize="14px"
				lineHeight="114%"
				color="#242533"
			>
				<Text>{label}</Text>
			</GridItem>
			<GridItem>
				<Flex justify="flex-end">
					{isDocGenerating ? (
						<Spinner size="sm" mr="7px" />
					) : (
						<Menu placement="bottom-end">
							<Tooltip label="Add Doc">
								<MenuButton
									as={IconButton}
									variant="ghost"
									size="sm"
									padding="10px 0"
									icon={<PlusIcon />}
								/>
							</Tooltip>
							<MenuList minW="180px">
								<MenuItem onClick={generateDocLink}>
									Generate a Doc file
								</MenuItem>
								<MenuItem onClick={startRedacting}>
									Add Doc file manually
								</MenuItem>
							</MenuList>
						</Menu>
					)}
				</Flex>
			</GridItem>
		</Grid>
	);
};
