import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Offline } from 'react-detect-offline';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import useWebSocket from 'react-use-websocket';
import {
	Box,
	Flex,
	HStack,
	Spinner,
	Text,
	useDisclosure,
	VStack,
} from '@chakra-ui/react';
import {
	ASTBuilder,
	Lexer,
	Parser,
	Validator,
} from '@holywater-tech/lua-interpreter';
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import { useStore } from 'effector-react';

import { docsModel } from 'entities/docs';
import { generateDecorator, scriptModel } from 'entities/script';
import { uiModel } from 'entities/ui';
import { userModel } from 'entities/user';
import { useGetBookQuery } from 'features/book/queries.gen';
import CloseAlert from 'features/editor/close-alert';
import { CurrentComment } from 'features/editor/show-current-comment';
import { SnippetGuideline } from 'features/editor/snippet-guideline';
import { SyncAlert } from 'features/editor/sync-alert';
import { EditorHeader } from 'features/story-editor/header';
import {
	ChapterByIdQuery,
	useChapterByIdQuery,
} from 'features/story-editor/header/queries.gen';
import { useChapterLocalization } from 'features/story-editor/lang-selector';
import { trackLuaSaved, trackScriptValidated } from 'shared/analytics';
import { api } from 'shared/api';
import { useDebouncedEffect } from 'shared/hooks/debounced-effect';
import { usePrompt } from 'shared/hooks/prompt';
import { useAppToast } from 'shared/hooks/toast';
import { WarningIcon } from 'shared/icons';
import { deserialize } from 'shared/utils/deserialize';
import { findProdDoc } from 'shared/utils/find-prod-doc';
import {
	getAnalyticsDocType,
	getDocTypeForRems,
} from 'shared/utils/getDocType';
import { TextEditor } from 'widgets/editor';

import { useUpdateDocVersionMutation } from './queries.gen';

export type ErrorContent = {
	start_index: number;
	end_index: number;
	msgs: string[];
};

const SCRIPT_FILE_TYPE = 'text/plain';
const LANG_FIELD_MAP: Record<string, string> = {
	EN: 'luaLink',
	RU: 'luaLinkRU',
	ES: 'luaLinkES',
	FR: 'luaLinkFR',
	DE: 'luaLinkDE',
	PT: 'luaLinkPT',
	NL: 'luaLinkNL',
	IT: 'luaLinkIT',
	PL: 'luaLinkPL',
	SV: 'luaLinkSV',
	DA: 'luaLinkDA',
	NO: 'luaLinkNO',
	FI: 'luaLinkFI',
	ID: 'luaLinkID',
	CS: 'luaLinkCS',
	RO: 'luaLinkRO',
	HU: 'luaLinkHU',
	TR: 'luaLinkTR',
	JA: 'luaLinkJA',
	KO: 'luaLinkKO',
	'PT-BR': 'luaLinkPT_BR',
	googleDocABTest1: 'abTestLua1',
	googleDocABTest2: 'abTestLua2',
	googleDocABTest3: 'abTestLua3',
	googleDoc2Pov: 'secondPovLua',
};

export type FEATURES_TYPE = {
	isDecorator: boolean;
	isSnippets: boolean;
	isAutoBraces: boolean;
	isAutoHighlight: boolean;
	isCodeFolding: boolean;
	isDialogues: boolean;
};

export const initialFeatures = {
	isDecorator: true,
	isSnippets: true,
	isAutoBraces: true,
	isAutoHighlight: true,
	isCodeFolding: false,
	isDialogues: false,
};
export const useDocType = (
	docId: string,
	chapter?: ChapterByIdQuery['chapter'],
) => {
	switch (docId) {
		case chapter?.googleDocABTest1?.id: {
			return 'abTestLua1';
		}
		case chapter?.googleDocABTest2?.id: {
			return 'abTestLua2';
		}
		case chapter?.googleDocABTest3?.id: {
			return 'abTestLua3';
		}
		case chapter?.googleDoc2Pov?.id: {
			return 'secondPovLua';
		}
		case chapter?.googleDoc2PovFR?.id: {
			return 'secondPovLuaFR';
		}
		case chapter?.googleDoc2PovDE?.id: {
			return 'secondPovLuaDE';
		}
		case chapter?.googleDoc2PovES?.id: {
			return 'secondPovLuaES';
		}
		case chapter?.googleDoc2PovNL?.id: {
			return 'secondPovLuaNL';
		}
		case chapter?.googleDoc2PovPT?.id: {
			return 'secondPovLuaPT';
		}
		case chapter?.googleDoc2PovIT?.id: {
			return 'secondPovLuaIT';
		}
		case chapter?.googleDoc2PovPL?.id: {
			return 'secondPovLuaPL';
		}
		case chapter?.googleDoc2PovSV?.id: {
			return 'secondPovLuaSV';
		}
		case chapter?.googleDoc2PovDA?.id: {
			return 'secondPovLuaDA';
		}
		case chapter?.googleDoc2PovNO?.id: {
			return 'secondPovLuaNO';
		}
		case chapter?.googleDoc2PovFI?.id: {
			return 'secondPovLuaFI';
		}
		case chapter?.googleDoc2PovID?.id: {
			return 'secondPovLuaID';
		}
		case chapter?.googleDoc2PovCS?.id: {
			return 'secondPovLuaCS';
		}
		case chapter?.googleDoc2PovRO?.id: {
			return 'secondPovLuaRO';
		}
		case chapter?.googleDoc2PovHU?.id: {
			return 'secondPovLuaHU';
		}
		case chapter?.googleDoc2PovTR?.id: {
			return 'secondPovLuaTR';
		}
		case chapter?.googleDoc2PovJA?.id: {
			return 'secondPovLuaJA';
		}
		case chapter?.googleDoc2PovKO?.id: {
			return 'secondPovLuaKO';
		}
		case chapter?.googleDoc2PovPT_BR?.id: {
			return 'secondPovLuaPT_BR';
		}
		default: {
			return 'prod';
		}
	}
};

const ScriptEditorPage = () => {
	const toast = useAppToast();

	const { sendMessage } = useWebSocket('ws://localhost:4000');

	useEffect(() => {
		sendMessage('events');
	}, [sendMessage]);

	const {
		isOpen: isOpenGuideline,
		onClose: handleCloseGuideline,
		onOpen: handleOpenGuideline,
	} = useDisclosure();

	const { chapterId, bookId, googleDocId } = useParams<{
		chapterId: string;
		bookId: string;
		googleDocId: string;
	}>();
	const [updateDocVersion] = useUpdateDocVersionMutation();

	const [isForcedAutoSave, setIsForcedAutoSave] = useState(false);
	const [isErrorSync, setIsErrorSync] = useState(false);
	const [editorState, setEditorState] = useState<EditorState>(
		EditorState.createEmpty(),
	);
	const features = useStore(userModel.$features);
	useEffect(() => {
		if (features) {
			userModel.setFeatures(features);
		} else {
			userModel.setFeatures(initialFeatures);
		}
	}, [features]);

	const { data: bookData } = useGetBookQuery({
		variables: { id: bookId as string },
	});
	const { data: chapterData } = useChapterByIdQuery({
		variables: { id: chapterId as string },
	});

	const chapterLang = useChapterLocalization(
		googleDocId as string,
		chapterData?.chapter,
	);
	const docType = useDocType(googleDocId as string, chapterData?.chapter);

	const docTypeForRems = useMemo(
		() => getDocTypeForRems(googleDocId as string, chapterData?.chapter),
		[googleDocId, chapterData?.chapter],
	);
	const docTypeAnalytics = useMemo(
		() => getAnalyticsDocType(googleDocId as string, chapterData?.chapter),
		[googleDocId, chapterData?.chapter],
	);
	const luaContent = useStore(scriptModel.$luaValue);
	const initialDraftDocValue = useStore(scriptModel.$initialDraftDocValue);
	const currentCommentData = useStore(scriptModel.$currentCommentData);
	const isDocumentLoading = useStore(scriptModel.loadDraftDocContentFx.pending);
	const isDocumentSyncing = useStore(
		docsModel.syncDraftDocContentIntoDBFx.pending,
	);
	const isDraftDocumentUpdatedLoading = useStore(
		scriptModel.loadIsDraftDocContentUpdatedFX.pending,
	);
	const isDraftDocContentUpdated = useStore(
		scriptModel.$isDraftDocContentUpdated,
	);
	const initialDraftDocValueRequested = useStore(
		scriptModel.$initialDraftDocValueRequested,
	);

	const isLuaEdited = useStore(scriptModel.$isLuaEdited);

	const isScriptEdited = useStore(scriptModel.$isScriptEdited);
	const isDisabledSyncToDoc = useStore(scriptModel.$isDisabledSyncToDoc);
	const isDeniedAccess = useStore(scriptModel.$isDeniedAccess);

	const onDownloadLuaClick = useCallback(() => {
		if (luaContent) {
			const blobUrl = URL.createObjectURL(
				new Blob([luaContent], { type: SCRIPT_FILE_TYPE }),
			);

			const link = document.createElement('a');
			link.href = blobUrl;
			link.download = 'lua.txt';
			link.click();
		}
	}, [luaContent]);

	const validate = useCallback(async () => {
		const editorData = editorState.getCurrentContent();
		const content = convertToRaw(editorData);

		const { isDialogues } = features;

		const { blocks } = content;
		const blockWithLFSymbol = blocks.find((block) => block.text.includes('\n'));

		if (blockWithLFSymbol) {
			const blockToFocus = editorData.getBlockForKey(blockWithLFSymbol.key);

			const selection = editorState.getSelection().merge({
				anchorKey: blockToFocus.getKey(),
				focusKey: blockToFocus.getKey(),
				focusOffset: blockToFocus.getText().length,
				anchorOffset: blockToFocus.getText().length,
			});
			const editorFocus = EditorState.forceSelection(editorState, selection);
			setEditorState(editorFocus);
			setTimeout(() => {
				window.getSelection()?.focusNode?.parentElement?.scrollIntoView({
					block: 'center',
				});
				const errorBlock = document.querySelector(
					`div[data-block="true"][data-offset-key="${blockWithLFSymbol.key}-0-0"]`,
				);

				errorBlock?.classList.add('error-block');
			}, 50);

			toast({
				title: 'Incorrect Format',
				description:
					'There is an incorrect line break symbol in the text. Replace it with the regular line break',
				status: 'error',
				duration: null,
				isClosable: true,
			});
			return '';
		}
		if (isDialogues) {
			userModel.setFeatures({ ...features, isDialogues: false });
		}
		try {
			const data = await scriptModel.validateScriptFx({
				chapterId: chapterId || '',
				script: content,
				docType: docTypeForRems,
				lang: chapterLang,
			});
			userModel.setFeatures({ ...features, isDialogues });
			setEditorState(
				EditorState.set(editorState, {
					decorator: generateDecorator(''),
				}),
			);
			if (data?.errorsPositions?.length) {
				toast({
					title: 'Validation error',
					status: 'error',
				});
			} else {
				toast({
					title: 'Validation success',
					status: 'success',
				});
			}

			trackScriptValidated({
				customBookId: bookData?.book?.customId as string,
				chapterName: chapterData?.chapter?.name as string,
				chapterNumber: chapterData?.chapter?.chapterOrder as number,
				doc:
					docTypeAnalytics === 'prod' && chapterLang !== 'EN'
						? `${docTypeAnalytics}${chapterLang}`
						: docTypeAnalytics,
				validation: data?.errorsPositions?.length ? 'error' : 'success',
			});
			return data;
		} catch (error) {
			userModel.setFeatures({ ...features, isDialogues });
			toast({
				title: 'Server error',
				status: 'error',
			});
			return error;
		}
	}, [
		bookData?.book?.customId,
		chapterData?.chapter,
		chapterId,
		chapterLang,
		docTypeAnalytics,
		docTypeForRems,
		editorState,
		features,
		toast,
	]);

	const onSaveBtnClick = useCallback(async () => {
		// TODO add errors array check
		if (chapterId && luaContent && chapterData) {
			try {
				const blob = new Blob([luaContent], {
					type: SCRIPT_FILE_TYPE,
				});
				const file = new Blob([blob], { type: SCRIPT_FILE_TYPE });
				const { data: link } = await updateDocVersion({
					variables: {
						chapterId: chapterId as string,
						file,
						type: docType === 'prod' ? LANG_FIELD_MAP[chapterLang] : docType,
					},
				});
				//  TODO add prev link deleting ( can extract it from lua->url [ add it on backend ])

				const editorData = editorState.getCurrentContent();
				const rawState = convertToRaw(editorData);
				const lexer = new Lexer(
					deserialize(rawState)
						.text?.replaceAll('“', '"')
						?.replaceAll('”', '"'),
				);
				const lexemes = lexer.lexAnalysis();
				const validator = new Validator({
					items: [],
					backgrounds: [],
					characters: [],
					audios: {
						android: [],
						ios: [],
					},
					videos: [],
					prevChaptersRems: [],
				});
				validator.validateBrackets(lexemes);
				const astBuilder = new ASTBuilder(lexemes);
				const ast = astBuilder.parseNodes();
				const parser = new Parser(ast, validator);
				parser.parse();
				validator.validate();

				trackLuaSaved({
					customBookId: bookData?.book?.customId as string,
					chapterName: chapterData?.chapter?.name as string,
					chapterNumber: chapterData?.chapter?.chapterOrder as number,
					doc:
						docTypeAnalytics === 'prod' && chapterLang !== 'EN'
							? `${docTypeAnalytics}${chapterLang}`
							: docTypeAnalytics,
					link: link?.updateDocVersion as string,
				});
				scriptModel.setIsLuaEdited(false);
				toast({
					title: 'Chapter lua was updated',
					status: 'success',
				});
				// return;
			} catch (e) {
				scriptModel.setIsLuaEdited(true);
				toast({
					title: "Chapter lua wasn't updated",
					status: 'error',
				});
			}
		} else {
			toast({
				title: "Chapter lua wasn't updated",
				status: 'error',
			});
		}
	}, [
		chapterId,
		luaContent,
		chapterData,
		updateDocVersion,
		docType,
		chapterLang,
		editorState,
		bookData?.book?.customId,
		docTypeAnalytics,
		toast,
	]);

	const content = useMemo(() => editorState.getCurrentContent(), [editorState]);

	const handleGoogleDocSaveError = useCallback(
		() =>
			toast({
				title: "Changes weren't saved",
				status: 'error',
			}),
		[toast],
	);

	const isProdDocument = useMemo(
		() => findProdDoc(googleDocId, chapterData),
		[chapterData, googleDocId],
	);

	const handleAutoSave = useCallback(async () => {
		[...document.querySelectorAll(`div[data-dialogue]`)].forEach((block) =>
			block.removeAttribute('data-dialogue'),
		);
		const editorData = editorState.getCurrentContent();
		const rawState = convertToRaw(editorData);
		const additionalDocs = chapterData?.chapter?.additionalDocs?.filter(
			(doc) => doc.id === googleDocId,
		);
		const isAdditionalDoc = !!additionalDocs?.length;

		// try {
		// 	const lexer = new Lexer(deserialize(rawState).text);
		// 	const lexemes = lexer.lexAnalysis();
		// 	const validator = new Validator({
		// 		items: [],
		// 		backgrounds: [],
		// 		characters: [],
		// 		audios: [],
		// 		prevChaptersRems: [],
		// 	});
		// 	validator.validateBrackets(lexemes);
		// 	const astBuilder = new ASTBuilder(lexemes);
		// 	const ast = astBuilder.parseNodes();
		// 	const parser = new Parser(ast, validator);
		// 	parser.parse();
		// 	const errors = validator.validate();
		// 	const functionErrors = errors.filter(
		// 		({ type }) => type === 'FunctionError',
		// 	);
		// 	scriptModel.setErrors(functionErrors);
		// 	scriptModel.setWarnings(validator.warnings);
		// } catch (e) {
		// 	scriptModel.setErrors([e as any]);
		// }

		const entityMapToSave = Object.entries(rawState.entityMap).map(
			([key, entity]) => ({
				key,
				entity,
			}),
		);

		try {
			if (!isAdditionalDoc && googleDocId) {
				await api.docs.getValidationAccessFile({
					fileId: googleDocId,
					isValidateContentAccount: false,
				});
			}

			if (isDeniedAccess && !isAdditionalDoc) throw new Error();
			setIsErrorSync(false);
			const updateDoc = await docsModel.updateDraftDocContentFx({
				blocks: rawState.blocks,
				updateGoogleDoc: !isDisabledSyncToDoc && !isAdditionalDoc,
				entityMap: entityMapToSave,
				documentId: googleDocId as string,
				lang: chapterLang as string,
			});

			if (googleDocId) {
				docsModel.loadLastUpdateInfo(googleDocId);
				docsModel.setLastUpdateAdditionalDocDate(
					updateDoc.data.updateDraftDocContent,
				);
			}
			scriptModel.setIsScriptEdited(false);

			if (isProdDocument) {
				scriptModel.setIsLuaEdited(true);
			}
		} catch (e) {
			const error = e as Error;
			if (error?.message.includes('have access')) {
				toast({
					title: error.message,
					status: 'error',
				});
			} else {
				handleGoogleDocSaveError();
			}
			setIsErrorSync(true);
		}
	}, [
		// features,
		editorState,
		chapterData?.chapter?.additionalDocs,
		googleDocId,
		isDeniedAccess,
		isDisabledSyncToDoc,
		chapterLang,
		isProdDocument,
		toast,
		handleGoogleDocSaveError,
	]);

	useEffect(() => {
		scriptModel.resetValidationErrors();
		scriptModel.setCurrentScriptInfo({
			bookId,
			chapterOrder: chapterData?.chapter?.chapterOrder,
			docType: docTypeForRems,
			lang: chapterLang,
		});
	}, [bookId, chapterData?.chapter?.chapterOrder, chapterLang, docTypeForRems]);

	usePrompt((tx) => {
		uiModel.setIsCloseAlertShow(true);
		scriptModel.setRedirectAction(tx.retry);
	}, isScriptEdited);

	usePrompt((tx) => {
		uiModel.setIsCloseAlertShow(true);
		scriptModel.setRedirectAction(tx.retry);
	}, isLuaEdited);

	useEffect(() => {
		if (googleDocId) {
			scriptModel.loadIsDraftDocContentUpdatedFX(googleDocId);
			scriptModel.loadDraftDocContentFx(googleDocId);
			scriptModel.loadDraftDocContentUpdateDateFx(googleDocId);
			docsModel.loadLastUpdateInfo(googleDocId);
			docsModel.setLastUpdateAdditionalDocDate(null);
		}
	}, [googleDocId, chapterId]);

	// * used for effect to not trigger on bookData obj update
	const bookDbId = useMemo(() => bookData?.book.id, [bookData]);

	useEffect(() => {
		if (
			bookDbId &&
			!isDocumentLoading &&
			!isDocumentSyncing &&
			!isDraftDocumentUpdatedLoading
		) {
			setEditorState(
				EditorState.createWithContent(
					convertFromRaw(initialDraftDocValue),
					generateDecorator(''),
				),
			);
		}
	}, [
		bookDbId,
		googleDocId,
		initialDraftDocValue,
		isDocumentLoading,
		isDocumentSyncing,
		isDraftDocumentUpdatedLoading,
	]);

	useEffect(() => {
		const hasComments = !!Object.keys(initialDraftDocValue.entityMap).length;
		if (
			isDraftDocContentUpdated &&
			!isDraftDocumentUpdatedLoading &&
			!isDocumentLoading &&
			googleDocId !== 'no-doc' &&
			googleDocId &&
			!hasComments
		) {
			try {
				docsModel.syncDraftDocContentIntoDBFx({
					documentId: googleDocId,
				});
				scriptModel.setIsDraftDocContentUpdated(false);
			} catch {
				handleGoogleDocSaveError();
			}
		}
	}, [
		googleDocId,
		initialDraftDocValue.entityMap,
		isDocumentLoading,
		isDraftDocContentUpdated,
		isDraftDocumentUpdatedLoading,
		initialDraftDocValueRequested,
		handleGoogleDocSaveError,
	]);

	useDebouncedEffect(
		() => {
			if (googleDocId !== 'no-doc' && !isDocumentLoading && isScriptEdited) {
				handleAutoSave();
			}
		},
		3000,
		[content, googleDocId, isDocumentLoading, isScriptEdited, isForcedAutoSave],
	);

	if (
		!bookData?.book ||
		isDocumentLoading ||
		isDraftDocumentUpdatedLoading ||
		isDocumentSyncing
	)
		return (
			<Box w="full" h="full" display="flex">
				<Spinner size="xl" m="auto" />
			</Box>
		);

	return (
		<Box>
			<Helmet>
				<title>
					My Fantasy - {bookData?.book?.name || 'book'} -{' '}
					{chapterData?.chapter?.name || 'loading...'}
				</title>
			</Helmet>
			<EditorHeader
				onValidateClick={validate}
				onSaveBtnClick={onSaveBtnClick}
				onDownloadLuaClick={onDownloadLuaClick}
				luaContent={luaContent}
				book={bookData.book}
				editorState={editorState}
				setEditorState={setEditorState}
				isErrorSync={isErrorSync}
				isDisabledSyncToDoc={isDisabledSyncToDoc}
			/>
			<TextEditor
				withComments
				editorState={editorState}
				setEditorState={setEditorState}
				handleOpenGuideline={handleOpenGuideline}
				features={features}
			/>
			<SnippetGuideline
				isOpen={isOpenGuideline}
				onClose={handleCloseGuideline}
			/>
			<CloseAlert
				title={isScriptEdited ? '' : 'Changes were not saved to lua file'}
			/>
			<SyncAlert documentId={googleDocId || ''} />
			{currentCommentData && (
				<CurrentComment
					commentEntity={currentCommentData.entity}
					modalPosition={currentCommentData.position}
					editorState={editorState}
					setEditorState={setEditorState}
				/>
			)}
			<Offline onChange={() => setIsForcedAutoSave(!isForcedAutoSave)}>
				<HStack
					position="fixed"
					top="46px"
					left="50%"
					transform="translateX(-50%)"
					px={4}
					py={3}
					justifyContent="space-between"
					alignItems="flex-start"
					bg="#484964"
					maxW="356px"
					borderRadius="6px"
					zIndex={1100}
				>
					<Flex>
						<WarningIcon mr={2} color="#ffffff" w={5} h={5} />
					</Flex>
					<VStack alignItems="flex-start">
						<Text color="#ffffff">No internet connection</Text>
						<Text fontSize="14px" fontWeight="400" color="#ffffff">
							When the connection restores the changes automatically save
						</Text>
					</VStack>
				</HStack>
			</Offline>
		</Box>
	);
};

export default ScriptEditorPage;
