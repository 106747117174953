import React, { ReactNode } from 'react';
import { Button } from '@chakra-ui/react';
import { Form, Formik } from 'formik';

import { ButtonType } from 'shared/analytics';

import styles from './styles.module.scss';

export type FormWithChildrenValue = {
	formValue: string;
};

interface Props {
	initialValue?: string | null;
	isRedacting: boolean;
	isDisabledSubmit?: boolean;
	startRedacting: () => void;
	stopRedacting: () => void;
	validationSchema?: any;
	onSubmit: ({ formValue }: FormWithChildrenValue) => void;
	submitButtonName: ButtonType;
	children: ReactNode;
}

export const FormWithChildren: React.FC<Props> = ({
	onSubmit,
	initialValue,
	startRedacting,
	stopRedacting,
	isRedacting,
	validationSchema,
	children,
	submitButtonName,
	isDisabledSubmit = false,
}) => {
	return (
		<Formik
			initialValues={
				{
					formValue: initialValue || '',
				} as FormWithChildrenValue
			}
			validationSchema={validationSchema}
			enableReinitialize
			onSubmit={onSubmit}
			onReset={stopRedacting}
		>
			{({ isSubmitting }) => (
				<Form className={styles.container}>
					<div className={styles.form} onClick={startRedacting}>
						{children}
					</div>
					{isRedacting && (
						<div className={styles.btns}>
							<Button
								disabled={isSubmitting}
								type="reset"
								size="md"
								variant="secondary"
								color="#808192"
							>
								Cancel
							</Button>
							<Button
								name={submitButtonName}
								disabled={isSubmitting || isDisabledSubmit}
								type="submit"
								size="md"
								variant="secondary"
							>
								Apply
							</Button>
						</div>
					)}
				</Form>
			)}
		</Formik>
	);
};
