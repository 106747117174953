import React, { useMemo } from 'react';

import { AssetType } from 'entities/assets/model';

import {
	CreateBackgroundForm,
	CreateBodyForm,
	CreateClothesForm,
	CreateHairForm,
	CreateItemForm,
	CreateLayerForm,
} from './forms';
import { CreateAnyAssetFormProps } from './types';

const ASSET_TYPE_FORM_MAP: Record<
	AssetType,
	React.FC<CreateAnyAssetFormProps>
> = {
	[AssetType.BODY]: CreateBodyForm,
	[AssetType.HAIR]: CreateHairForm,
	[AssetType.CLOTHES]: CreateClothesForm,
	[AssetType.ITEM]: CreateItemForm,
	[AssetType.LAYER]: CreateLayerForm,
	[AssetType.BACKGROUND]: CreateBackgroundForm,
	// [AssetType.CHAT_PHOTO]: CreateChatPhotoForm,
	// [AssetType.AUDIO]: CreateAudioForm,
};

interface CreateAssetFormProps extends CreateAnyAssetFormProps {
	type: AssetType;
}

export const CreateAssetForm: React.FC<CreateAssetFormProps> = ({
	type,
	onSubmit,
	onCancel,
	buttonsContainerRef,
	specialType,
	altClothesIds,
	altBackgroundsIds,
}) => {
	const FormComponent = useMemo(() => ASSET_TYPE_FORM_MAP[type], [type]);

	return (
		<FormComponent
			onSubmit={onSubmit}
			onCancel={onCancel}
			buttonsContainerRef={buttonsContainerRef}
			specialType={specialType}
			altClothesIds={altClothesIds}
			altBackgroundsIds={altBackgroundsIds}
		/>
	);
};
