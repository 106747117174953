import React from 'react';
import { createIcon } from '@chakra-ui/react';

export const TrashBinIcon = createIcon({
	viewBox: '0 0 24 24',
	path: (
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M10.4047 5.90483C10.536 5.77355 10.7141 5.6998 10.8997 5.6998H13.6997C13.8854 5.6998 14.0634 5.77355 14.1947 5.90483C14.326 6.03611 14.3997 6.21415 14.3997 6.3998V7.09863H10.1997V6.3998C10.1997 6.21415 10.2735 6.03611 10.4047 5.90483ZM8.79971 7.09863V6.3998C8.79971 5.84285 9.02096 5.30871 9.41478 4.91488C9.80861 4.52105 10.3428 4.2998 10.8997 4.2998H13.6997C14.2567 4.2998 14.7908 4.52105 15.1846 4.91488C15.5785 5.30871 15.7997 5.84285 15.7997 6.3998V7.09863H18.6C18.9866 7.09863 19.3 7.41203 19.3 7.79863C19.3 8.18523 18.9866 8.49863 18.6 8.49863H17.832L16.8993 17.639C16.8892 18.1818 16.6692 18.7002 16.2846 19.0847C15.8908 19.4786 15.3567 19.6998 14.7997 19.6998H9.79971C9.24275 19.6998 8.70861 19.4786 8.31478 19.0847C7.93021 18.7002 7.7102 18.1818 7.70007 17.639L6.76738 8.49863H6.00005C5.61345 8.49863 5.30005 8.18523 5.30005 7.79863C5.30005 7.41203 5.61345 7.09863 6.00005 7.09863H7.40005H8.79971ZM9.49971 8.4998H15.0997H16.4246L15.5033 17.5287C15.5009 17.5524 15.4997 17.5761 15.4997 17.5998C15.4997 17.7855 15.426 17.9635 15.2947 18.0948C15.1634 18.2261 14.9854 18.2998 14.7997 18.2998H9.79971C9.61406 18.2998 9.43601 18.2261 9.30473 18.0948C9.17346 17.9635 9.09971 17.7855 9.09971 17.5998C9.09971 17.5761 9.0985 17.5524 9.09609 17.5287L8.17477 8.4998H9.49971Z"
			fill="currentColor"
		/>
	),
});
