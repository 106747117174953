import React, { memo, useEffect, useState } from 'react';
import { Box, Button, Flex, Text, useDisclosure } from '@chakra-ui/react';
import { FormikErrors } from 'formik';

import { useChapters } from 'features/asset/chapters-asset-modal/graphql/hooks';
import { PencilIcon, PlusIcon } from 'shared/icons';
import { BooleanInput, InputField } from 'shared/ui';
import { DisplayHeroCard } from 'widgets/display-hero-card';
import { SelectBackgroundModal } from 'widgets/select-background-modal';
import InfoPopover from 'widgets/select-background-modal/ui/info-popover';
import SelectChapterModal from 'widgets/select-chapter-modal';
import { SelectHeroName } from 'widgets/select-hero-name';
import { CardQuery } from 'widgets/update-card-modal/graphql/queries.gen';
import { UpdateCardFormikParams } from 'widgets/update-card-modal/interfaces';

interface GeneralTabProps {
	id: string;
	handleSetFieldValue: (
		field: string,
		value: any,
		shouldValidate?: any,
	) => void;
	bookId: string;
	golden: boolean;
	secondary: boolean;
	description: string;
	chapter: CardQuery['card']['chapter'] | null;
	background: CardQuery['card']['background'] | null;
	hero: CardQuery['card']['hero'] | null;
	heroName: string;
	errors: FormikErrors<UpdateCardFormikParams>;
}

export const GeneralTab: React.FC<GeneralTabProps> = memo(
	({
		id,
		handleSetFieldValue,
		bookId,
		golden,
		secondary,
		description,
		chapter,
		background,
		hero,
		heroName,
		errors,
	}) => {
		const [selectedChapter, setSelectedChapter] = useState<
			CardQuery['card']['chapter'] | null
		>(null);

		const { chapters: bookChapters, loadingChapters } = useChapters(bookId);

		const handleSubmitChapter = () => {
			handleSetFieldValue('chapter', selectedChapter);
			handleSetFieldValue('background', '');
			handleSetFieldValue('hero', null);
		};

		const {
			isOpen: isOpenChapterModal,
			onOpen: onOpenChapterModal,
			onClose: onCloseChapterModal,
		} = useDisclosure();
		const {
			isOpen: isOpenBackgroundModal,
			onOpen: onOpenBackgroundModal,
			onClose: onCloseBackgroundModal,
		} = useDisclosure();

		const {
			isOpen: isOpenInfoPopover,
			onOpen: onOpenInfoPopover,
			onClose: onCloseInfoPopover,
		} = useDisclosure();

		useEffect(() => {
			setSelectedChapter(chapter);
		}, [chapter]);

		const renderInfoPopover = () => (
			<InfoPopover
				isOpen={isOpenInfoPopover}
				onOpen={onOpenInfoPopover}
				onClose={onCloseInfoPopover}
			/>
		);

		return (
			<Box>
				<Flex
					p="19px 0 16px 0"
					align="center"
					justify="space-between"
					fontSize="14px"
					fontWeight={500}
					borderBottom="1px solid #eeeef2"
				>
					<Text>Id</Text>
					<Text>{id}</Text>
				</Flex>
				<BooleanInput
					isChecked={golden}
					label="Golden"
					htmlFor="golden"
					onChange={() => handleSetFieldValue('golden', !golden)}
					isBorderBottom
				/>
				<BooleanInput
					isChecked={secondary}
					label="Secondary"
					htmlFor="secondary"
					onChange={() => handleSetFieldValue('secondary', !secondary)}
					isBorderBottom
				/>
				<Box pos="relative">
					<InputField
						label="Card Description"
						name="description"
						value={description}
						onApply={(v: string | number) =>
							handleSetFieldValue('description', v, true)
						}
						error={errors.description}
						placeholder="Card Description"
						infoPopover={() => renderInfoPopover()}
					/>
					{description.length > 65 && (
						<Text
							pos="absolute"
							color="red"
							right="40px"
							top="40px"
							fontWeight={500}
							fontSize="12px"
							background="#fff"
							p="0 5px"
						>
							65 characters exceeded
						</Text>
					)}
				</Box>

				<Flex
					p="19px 0 16px 0"
					align="center"
					justify="space-between"
					fontSize="14px"
					fontWeight={500}
					borderBottom="1px solid #eeeef2"
				>
					<Text>Chapter</Text>
					<Flex alignItems="center">
						{chapter && (
							<Text mr={2}>
								{chapter?.chapterOrder}.{chapter?.name}
							</Text>
						)}
						<Button
							size="circle"
							variant="secondary"
							onClick={onOpenChapterModal}
						>
							{chapter ? (
								<Flex>
									<PencilIcon w={6} h={6} />
								</Flex>
							) : (
								<PlusIcon w={4} h={4} />
							)}
						</Button>
					</Flex>
				</Flex>
				{chapter && (
					<>
						<Flex
							p="19px 0 16px 0"
							align="center"
							justify="space-between"
							fontSize="14px"
							fontWeight={500}
							borderBottom="1px solid #eeeef2"
						>
							<Text>Background</Text>
							<Flex alignItems="center">
								{background && (
									<Text mr={2}>
										{background?.filename || background?.atName}
									</Text>
								)}
								<Button
									size="circle"
									variant="secondary"
									onClick={onOpenBackgroundModal}
								>
									{background ? (
										<Flex>
											<PencilIcon w={6} h={6} />
										</Flex>
									) : (
										<PlusIcon w={4} h={4} />
									)}
								</Button>
							</Flex>
						</Flex>
						<DisplayHeroCard
							hero={hero}
							chapterId={chapter.id || ''}
							handleSetFieldValue={handleSetFieldValue}
						/>
						{hero && (
							<Flex
								p="19px 0 16px 0"
								align="center"
								justify="space-between"
								fontSize="14px"
								fontWeight={500}
								borderBottom="1px solid #eeeef2"
								borderTop="1px solid #eeeef2"
							>
								<Text>Hero name</Text>
								<SelectHeroName
									heroName={heroName}
									options={hero.names}
									handleSetFieldValue={handleSetFieldValue}
								/>
							</Flex>
						)}
					</>
				)}

				<SelectChapterModal
					selectedChapter={selectedChapter}
					setSelectedChapter={setSelectedChapter}
					handleSubmit={handleSubmitChapter}
					bookChapters={bookChapters}
					isOpen={isOpenChapterModal}
					onClose={onCloseChapterModal}
					loadingChapters={loadingChapters}
				/>
				<SelectBackgroundModal
					handleSetFieldValue={handleSetFieldValue}
					chapterId={chapter?.id || ''}
					isOpen={isOpenBackgroundModal}
					onClose={onCloseBackgroundModal}
				/>
			</Box>
		);
	},
);
