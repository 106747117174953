import React, { lazy, useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { analytics } from 'shared';
import { BackgroundsList } from 'widgets/backgrounds-list';
import { CardsList } from 'widgets/cards-list';
import { ChaptersTable } from 'widgets/chapters-table';
import { HeroesList } from 'widgets/hero-list';
import { ItemsList } from 'widgets/items-list';

import { AuthSuccess } from './auth-success';
import { RequireAuth } from './require-auth';

const AuthPage = lazy(() => import('./auth'));
const ScriptEditor = lazy(() => import('./script-editor'));
const Resources = lazy(() => import('./resources'));
const StoriesPage = lazy(() => import('./stories'));
const StoryPage = lazy(() => import('./story'));
const ArtCatalogPage = lazy(() => import('./art-catalog'));
const HeroesPage = lazy(() => import('./heroes'));
const ContentBuilderPage = lazy(() => import('./content-builder'));
const OngoingBooksList = lazy(() => import('./ongoing-books'));

export const Routing: React.FC = () => {
	const location = useLocation();

	useEffect(() => {
		setTimeout(() => {
			analytics.trackPageView(location.pathname);
		}, 800);
	}, [location.pathname]);

	return (
		<Routes>
			<Route
				path="/stories"
				element={
					<RequireAuth>
						<StoriesPage />
					</RequireAuth>
				}
			/>
			<Route
				path="/stories/:bookId/:chapterId/:googleDocId"
				element={
					<RequireAuth>
						<ScriptEditor />
					</RequireAuth>
				}
			/>
			{/* <Route
				path="/editor/:bookId/chapter/:chapterId/:googleDocId"
				element={
					<RequireAuth>
						<ChapterEditor />
					</RequireAuth>
				}
			/>
			<Route
				path="/editor/:bookId/chat/:chatId/:googleDocId"
				element={
					<RequireAuth>
						<ChatEditor />
					</RequireAuth>
				}
			/> */}
			<Route
				path="/stories/:bookId"
				element={
					<RequireAuth>
						<StoryPage />
					</RequireAuth>
				}
			>
				<Route
					path="backgrounds"
					element={
						<RequireAuth>
							<BackgroundsList />
						</RequireAuth>
					}
				/>
				<Route
					path="items"
					element={
						<RequireAuth>
							<ItemsList />
						</RequireAuth>
					}
				/>
				<Route
					path="chapters"
					element={
						<RequireAuth>
							<ChaptersTable />
						</RequireAuth>
					}
				/>
				<Route
					path="cards"
					element={
						<RequireAuth>
							<CardsList />
						</RequireAuth>
					}
				/>
				<Route
					path="heroes"
					element={
						<RequireAuth>
							<HeroesList />
						</RequireAuth>
					}
				/>
				<Route
					path="*"
					element={
						<RequireAuth>
							<Navigate to="chapters" replace />
						</RequireAuth>
					}
				/>
			</Route>
			<Route
				path="/resources"
				element={
					<RequireAuth>
						<Resources />
					</RequireAuth>
				}
			/>
			<Route
				path="/art-catalog"
				element={
					<RequireAuth>
						<ArtCatalogPage />
					</RequireAuth>
				}
			/>
			<Route
				path="/hero-management"
				element={
					<RequireAuth>
						<HeroesPage />
					</RequireAuth>
				}
			/>
			<Route
				path="/content-builder"
				element={
					<RequireAuth>
						<ContentBuilderPage />
					</RequireAuth>
				}
			/>
			<Route
				path="/ongoing-books"
				element={
					<RequireAuth>
						<OngoingBooksList />
					</RequireAuth>
				}
			/>
			<Route path="/auth" element={<AuthPage />} />
			<Route path="/auth-success" element={<AuthSuccess />} />
			<Route path="*" element={<Navigate to="/resources" replace />} />
		</Routes>
	);
};
