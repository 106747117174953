import React from 'react';
import { Box } from '@chakra-ui/react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

interface DragAndDropWrapperProps {
	assetId: string;
	children: React.ReactNode;
}
export const DragAndDropWrapper: React.FC<DragAndDropWrapperProps> = ({
	assetId,
	children,
}) => {
	const {
		isDragging,
		attributes,
		listeners,
		setNodeRef,
		transform,
		transition,
	} = useSortable({
		id: assetId,
	});
	return (
		<Box
			ref={setNodeRef}
			{...listeners}
			{...attributes}
			opacity={isDragging ? 0.1 : 1}
			transform={CSS.Transform.toString(transform)}
			transition={transition || undefined}
		>
			{children}
		</Box>
	);
};
