import React from 'react';
import { createIcon } from '@chakra-ui/react';

export const CloudOffIcon = createIcon({
	viewBox: '0 0 16 12',
	path: (
		<>
			<path d="M0 0h24v24H0V0z" fill="none" />
			<path
				d="M 16 7.611 C 16 5.851 14.633 4.424 12.899 4.304 C 12.446 2.004 10.426 0.277 7.999 0.277 C 7.112 0.277 6.285 0.517 5.565 0.924 L 6.559 1.917 C 7.005 1.724 7.485 1.61 7.999 1.61 C 10.026 1.61 11.666 3.251 11.666 5.278 L 11.666 5.611 L 12.666 5.611 C 13.773 5.611 14.666 6.504 14.666 7.611 C 14.666 8.271 14.346 8.845 13.859 9.211 L 14.8 10.151 C 15.526 9.538 16 8.638 16 7.611 Z M 2.472 0.65 C 2.211 0.91 2.211 1.33 2.472 1.59 L 3.845 2.964 L 3.565 2.964 C 1.378 3.197 -0.275 5.191 0.038 7.491 C 0.305 9.505 2.125 10.945 4.145 10.945 L 11.819 10.945 L 12.679 11.805 C 12.939 12.065 13.359 12.065 13.619 11.805 C 13.879 11.545 13.879 11.125 13.619 10.865 L 3.412 0.65 C 3.152 0.39 2.732 0.39 2.472 0.65 Z M 3.998 9.611 C 2.525 9.611 1.331 8.418 1.331 6.944 C 1.331 5.471 2.525 4.277 3.998 4.277 L 5.152 4.277 L 10.486 9.611 L 3.998 9.611 Z"
				fill="currentColor"
			/>
		</>
	),
});
