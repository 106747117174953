import React, { useEffect, useRef, useState } from 'react';
import { Button, Input, Tooltip } from '@chakra-ui/react';

import {
	CheckmarkIcon,
	PencilIcon,
	PlusIcon,
	TrashBinIcon,
} from 'shared/icons';

import styles from './styles.module.scss';

interface Props {
	label?: string;
	name: string;
	value?: string | number;
	onApply: (v: string | number) => void;
	error?: string;
	type?: 'text' | 'number';
	defaultValue?: string | number;
	tooltipValue?: string;
	isPlusBtn?: boolean;
	placeholder?: string;
	isDisabledFilename?: boolean;
	border?: boolean;
	isLink?: boolean;
	infoPopover?: () => void;
}

export const InputField: React.FC<Props> = ({
	label,
	name,
	value,
	onApply,
	error,
	type = 'text',
	defaultValue = '',
	tooltipValue,
	isPlusBtn = true,
	placeholder,
	isDisabledFilename,
	border = true,
	isLink,
	infoPopover,
	// eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
	const containerRef = useRef<HTMLDivElement | null>(null);
	const [innerValue, setInnerValue] = useState(value);
	const [isRedacting, setIsRedacting] = useState(false);

	const stopRedacting = () => setIsRedacting(false);

	const hasValue = typeof value === 'number' ? true : !!value;

	useEffect(() => {
		setInnerValue(value);
	}, [value]);

	return (
		<div
			className={`${label && styles.root} ${!border ? styles.borderNone : ''}`}
			ref={containerRef}
		>
			{label && (
				<div className={styles.left}>
					<p className={styles.text}>
						{label} {infoPopover && infoPopover()}
					</p>
				</div>
			)}
			<Tooltip
				label={error}
				isDisabled={!error}
				placement="bottom-end"
				bg="#ff218c"
				offset={[0, 2]}
				isOpen
				portalProps={{
					containerRef,
				}}
			>
				<div className={styles.right}>
					{isRedacting ? (
						<>
							<div className={styles.input}>
								<Input
									width={placeholder ? '300px' : '165px'}
									value={innerValue}
									name={name}
									isInvalid={!!error}
									autoComplete="off"
									size="sm"
									placeholder={placeholder || ''}
									type={type}
									onChange={(e) => {
										setInnerValue(e.target.value);
									}}
									onKeyPress={(e) => {
										if (e.code === 'Enter') {
											e.preventDefault();
										}
									}}
								/>
							</div>
							<Tooltip label="Apply">
								<Button
									size="circle"
									variant="secondary"
									onClick={() => {
										onApply(innerValue as string);
										stopRedacting();
									}}
								>
									<CheckmarkIcon w={4} h={4} />
								</Button>
							</Tooltip>
							<Tooltip label="Delete">
								<Button
									size="circle"
									variant="secondary"
									onClick={() => {
										if (!isPlusBtn) {
											onApply(value || '');
											setInnerValue(value);
										} else {
											onApply(defaultValue);
										}
										stopRedacting();
									}}
								>
									<TrashBinIcon w={6} h={6} />
								</Button>
							</Tooltip>
						</>
					) : (
						<>
							{hasValue &&
								!error &&
								(isLink ? (
									<a
										href={value as string}
										target="_blank"
										rel="noreferrer"
										style={{
											color: '#644DED',
											fontSize: '13px',
											fontWeight: 500,
											textDecorationLine: 'underline',
											marginRight: '9px',
										}}
									>
										link
									</a>
								) : (
									<div className={styles.name}>{value}</div>
								))}
							{!isDisabledFilename ? (
								<Button
									size="circle"
									variant="secondary"
									onClick={() => setIsRedacting(true)}
								>
									{hasValue ? (
										<Tooltip label={tooltipValue || ''}>
											<PencilIcon w={6} h={6} />
										</Tooltip>
									) : (
										<PlusIcon />
									)}
								</Button>
							) : null}
						</>
					)}
				</div>
			</Tooltip>
		</div>
	);
};
