import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import cn from 'classnames';

import styles from './styles.module.scss';

interface Props {
	selectedCategory?: string;
	setSelectedCategory: (v?: string) => void;
}

// TODO: improve typings
const TYPE_CATEGORY_MAP: Record<string, string> = {
	body: 'Body',
	hair: 'Hairs',
	clothes: 'Clothes',
	item: 'Items',
	layer: 'Layer',
	background: 'Backgrounds',
	// chatPhoto: 'Chat photos',
	// audio: 'Audios',
};

const ArtCatalogCategories: React.FC<Props> = ({
	selectedCategory,
	setSelectedCategory,
}) => {
	return (
		<Box minW="218px">
			<Text fontSize="15px" lineHeight="22px" color="#abacbe" mb="33px">
				CATEGORIES
			</Text>
			<p
				className={cn(styles.category, {
					[styles.selectedCategory]: !selectedCategory,
				})}
				onClick={() => setSelectedCategory(undefined)}
			>
				All
			</p>
			{Object.keys(TYPE_CATEGORY_MAP).map((type) => (
				<p
					key={type}
					className={cn(styles.category, {
						[styles.selectedCategory]: type === selectedCategory,
					})}
					onClick={() => setSelectedCategory(type)}
				>
					{TYPE_CATEGORY_MAP[type]}
				</p>
			))}
		</Box>
	);
};

export default ArtCatalogCategories;
