import React from 'react';

import { userModel, useUserByEmailQuery } from 'entities/user';
import { analytics } from 'shared';
import { api } from 'shared/api';
import { Permission } from 'models.gen';

export const withAnalytics = (component: () => React.ReactNode) => () => {
	const version = process.env.REACT_APP_VERSION;

	useUserByEmailQuery({
		onCompleted: (res) => {
			analytics.setUserId(res.user.email);
			analytics.setEmail(res.user.email);
			analytics.setVersion(version || '');
			userModel.setPermissions(res.user.permissions as Permission[]);
		},
		client: api.apolloClient,
	});

	return <>{component()}</>;
};
