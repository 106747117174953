import * as Types from '../../../models.gen';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RemsQueryVariables = Types.Exact<{
  bookId: Types.Scalars['String'];
  chapterOrder: Types.Scalars['Float'];
  docType: Types.Scalars['String'];
  lang: Types.Scalars['String'];
}>;


export type RemsQuery = { rems: Array<{ id: string, chapterOrder: number, docType: string, rems: Array<Array<{ variable: string, position: number, line: number }>> }> };


export const RemsDocument = gql`
    query Rems($bookId: String!, $chapterOrder: Float!, $docType: String!, $lang: String!) {
  rems(
    bookId: $bookId
    chapterOrder: $chapterOrder
    docType: $docType
    lang: $lang
  ) {
    id
    chapterOrder
    rems {
      variable
      position
      line
    }
    docType
  }
}
    `;

/**
 * __useRemsQuery__
 *
 * To run a query within a React component, call `useRemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRemsQuery({
 *   variables: {
 *      bookId: // value for 'bookId'
 *      chapterOrder: // value for 'chapterOrder'
 *      docType: // value for 'docType'
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useRemsQuery(baseOptions: Apollo.QueryHookOptions<RemsQuery, RemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RemsQuery, RemsQueryVariables>(RemsDocument, options);
      }
export function useRemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RemsQuery, RemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RemsQuery, RemsQueryVariables>(RemsDocument, options);
        }
export type RemsQueryHookResult = ReturnType<typeof useRemsQuery>;
export type RemsLazyQueryHookResult = ReturnType<typeof useRemsLazyQuery>;
export type RemsQueryResult = Apollo.QueryResult<RemsQuery, RemsQueryVariables>;