import React, { SetStateAction, useCallback } from 'react';
import { Link } from 'react-router-dom';
import {
	Box,
	Flex,
	IconButton,
	Image,
	Link as ChakraLink,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Spinner,
	Text,
} from '@chakra-ui/react';

import { MoreIcon } from 'shared/icons';
import { getFilenameFromURL } from 'shared/utils/get-filename-from-url';

import { AssetInfo } from '../../../../../widgets/hero-update-modal';
import { DragAndDropWrapper } from '../drag-and-drop-wrapper';
import { useAssetCardBodyByIdQuery } from './queries.gen';

interface AssetCardBodyProps {
	assets: string[];
	assetId: string;
	handleSetFieldValue: (
		field: string,
		value: any,
		shouldValidate?: any,
	) => void;
	setCurrentAssetInfo?: React.Dispatch<SetStateAction<AssetInfo>>;
	isActive?: boolean;
	handlePhotoDownload: (imageLink: string, imageName: any) => void;
}

export const AssetCardBody: React.FC<AssetCardBodyProps> = ({
	assets,
	assetId,
	setCurrentAssetInfo,
	// openDetailsModal,
	handleSetFieldValue,
	isActive,
	handlePhotoDownload,
}) => {
	const { data, loading } = useAssetCardBodyByIdQuery({
		variables: {
			id: assetId,
		},
	});

	// const handleOpenAssetDetails = () => {
	// 	setCurrentAssetInfo(assetId);
	// 	openDetailsModal();
	// };
	const imageName = getFilenameFromURL(data?.body.link || '');
	const handleDownloadAsset = useCallback(() => {
		if (data) {
			handlePhotoDownload(data?.body.link || '', imageName);
		}
	}, [data, handlePhotoDownload, imageName]);

	const handleDeleteAsset = useCallback(() => {
		const newAssets = assets.filter((id) => id !== assetId);

		handleSetFieldValue(
			'bodies',
			assets.filter((id) => id !== assetId),
		);
		if (setCurrentAssetInfo) {
			setCurrentAssetInfo((prev) => ({
				...prev,
				bodyId: newAssets[0] || '',
			}));
		}
	}, [assetId, assets, handleSetFieldValue, setCurrentAssetInfo]);

	return (
		<DragAndDropWrapper assetId={assetId}>
			<Flex flexDirection="column" gap={2} w="240px" maxW="240px">
				<Box
					h="min-content"
					userSelect="none"
					border="4px solid transparent"
					borderRadius="10px"
					position="relative"
				>
					<Flex
						height="300px"
						alignItems="center"
						justifyContent="center"
						border="1px solid #EEEEF2"
						borderRadius="10px"
						overflow="hidden"
						borderColor={isActive ? '#644DED' : ''}
						_hover={{
							borderColor: '#644DED',
							cursor: 'pointer',
						}}
					>
						{loading ? (
							<Spinner size="md" />
						) : (
							<Box
								onClick={() =>
									setCurrentAssetInfo
										? setCurrentAssetInfo((prev) => ({
												...prev,
												bodyId: assetId,
										  }))
										: null
								}
							>
								<Image
									alt={data?.body.name || ''}
									src={data?.body.link || ''}
									height="300px"
								/>
							</Box>
						)}
					</Flex>

					{!loading && (
						<Box position="absolute" top={1} right={1}>
							<Menu placement="bottom-start">
								<MenuButton
									as={IconButton}
									aria-label="Options"
									icon={<MoreIcon w={5} h={5} />}
									variant="ghost"
								/>
								<MenuList rootProps={{ transform: 'scale(0)' }}>
									<ChakraLink
										as={Link}
										to={`/art-catalog/?id=${assetId}&name=${data?.body.name}&link=${data?.body.link}&type=body`}
										target="_blank"
										_hover={{ textDecoration: 'none' }}
										_focus={{ outline: 'none' }}
									>
										<MenuItem>Open in Art Catalog</MenuItem>
									</ChakraLink>
									<MenuItem onClick={handleDeleteAsset}>Unlink Asset</MenuItem>
									<MenuItem onClick={handleDownloadAsset}>
										Download Asset
									</MenuItem>
								</MenuList>
							</Menu>
						</Box>
					)}
				</Box>
				<Text p={1} fontSize="12px" fontWeight={600} lineHeight="16px">
					{data?.body.name || ''}
				</Text>
			</Flex>
		</DragAndDropWrapper>
	);
};
