import React, { useCallback, useMemo } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import {
	Badge,
	Box,
	Button,
	Flex,
	Heading,
	Link,
	Spinner,
	Stack,
	Table,
	TableContainer,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tooltip,
	Tr,
} from '@chakra-ui/react';

import { uiModel } from 'entities/ui';
import { useGetBookQuery } from 'features/book/queries.gen';
import { FORMAT_STATUS_MAP } from 'features/chapter/update/update-management-status/constants';
import {
	trackArtDocModalOpen,
	trackChapterPropertiesModalOpen,
} from 'shared/analytics/modals/open';
import { FileIcon, MoreIcon } from 'shared/icons';
import { ArtDocIcon } from 'shared/icons/art-doc';
import { formatDate } from 'shared/utils';

export const ChaptersTable: React.FC = () => {
	const { bookId } = useParams<{ bookId: string }>();
	const { data, loading } = useGetBookQuery({
		variables: { id: bookId as string },
	});

	const { isFullWidth } = useOutletContext<{ isFullWidth: boolean }>();

	const onSrcLinkClick = useCallback((src: string) => {
		window.open(src, '_blank');
	}, []);

	const isChapters = useMemo(
		() => data?.book?.chapters?.length,
		[data?.book?.chapters?.length],
	);

	const mapStatus = (status: string) =>
		status === 'rdy' ? (
			<Badge variant={status}>Ready</Badge>
		) : (
			<Badge variant={status}>Not Ready</Badge>
		);

	return (
		<Stack
			h={isFullWidth ? 'calc(100vh - 60px)' : 'calc(100vh - 195px)'}
			bg="#FBFBFCFF)"
			overflow="hidden"
		>
			<Flex
				alignItems="center"
				justifyContent="space-between"
				p="0 12px 0 20px"
				h="51px"
				min-h="51px"
				borderBottom="1px solid #eeeef2"
			>
				<Heading
					fontFamily="Poppins"
					fontWeight="600"
					fontSize="16px"
					lineHeight="150%"
					textAlign="center"
					color="#242533"
					whiteSpace="nowrap"
				>
					All Chapters
				</Heading>
				{isChapters ? (
					<Box>
						<Button
							variant="ghost"
							size="sm"
							onClick={() => {
								uiModel.setIsCreateArtDocOpened(true);
								trackArtDocModalOpen();
							}}
							mr="5px"
						>
							<ArtDocIcon w={6} h={6} marginRight="4px" /> Art Doc
						</Button>
						<Button
							variant="ghost"
							size="sm"
							onClick={() => {
								uiModel.setIsUpdateChapterModalOpened(true);
								trackChapterPropertiesModalOpen();
							}}
						>
							<MoreIcon w={6} h={6} marginRight="5px" /> Chapter Properties
						</Button>
					</Box>
				) : null}
			</Flex>
			{loading ? (
				<Spinner size="xl" m="auto" />
			) : (
				<Box d="flex" flex="1 1" overflow="auto">
					<TableContainer overflowY="scroll" width="100%">
						<Table size="md">
							<Thead position="sticky" left={0} top={0} zIndex={101}>
								<Tr>
									<Th display="flex" position="sticky" left={0} zIndex={100}>
										{/* <Checkbox
											minW="26px"
											mr="20px"
											onChange={toggleCheckbox}
											isChecked={isChecked}
										/> */}
										<Text
											w="15px"
											mx="9px"
											fontWeight={600}
											color="#808192"
											fontSize="14px"
										>
											№
										</Text>
										<Text
											ml="15px"
											fontWeight={600}
											color="#808192"
											fontSize="14px"
										>
											Chapter Name
										</Text>
									</Th>
									<Th width={180} color="#808192" fontSize="14px">
										Last Edited
									</Th>
									<Th width={150} color="#808192" fontSize="14px">
										Author
									</Th>
									<Th width={150} color="#808192" fontSize="14px">
										Lua
									</Th>
									<Th width={150} color="#808192" fontSize="14px">
										Prod Doc
									</Th>
									<Th flex={1} color="#808192" fontSize="14px">
										Status
									</Th>
									<Th flex={1} color="#808192" fontSize="14px">
										Management Status
									</Th>
								</Tr>
							</Thead>
							<Tbody>
								{data?.book?.chapters
									?.sort(
										(a, b) => (a.chapterOrder || 0) - (b.chapterOrder || 0),
									)
									.map((chapter) => (
										<Tr key={chapter.id}>
											<Td position="sticky" left={0}>
												<Flex alignItems="center">
													<Text minW="26px" mr="20px" textAlign="center">
														{chapter.chapterOrder}
													</Text>
													<Tooltip label="Open Chapter">
														<Link
															href={`/stories/${bookId}/${chapter.id}/${
																chapter?.googleDoc?.id ||
																chapter?.googleDocDE?.id ||
																chapter?.googleDocES?.id ||
																chapter?.googleDocFR?.id ||
																chapter?.googleDocNarrative?.id ||
																chapter?.googleDocWriter?.id ||
																chapter?.googleDoc2Pov?.id ||
																(chapter?.additionalDocs &&
																	chapter?.additionalDocs[0]?.id) ||
																'no-doc'
															}`}
															display="flex"
															alignItems="center"
															textDecoration="none"
															color="#242533"
															_focus={{ outline: 'none' }}
															_hover={{ textDecoration: 'none' }}
														>
															<Text
																overflow="hidden"
																whiteSpace="nowrap"
																textOverflow="ellipsis"
																maxW={260}
																fontWeight={600}
															>
																{chapter.name}
															</Text>
														</Link>
													</Tooltip>
												</Flex>
											</Td>

											<Td>{formatDate(chapter.updatedAt)}</Td>
											<Td>{chapter.author || '---'}</Td>
											<Td textAlign="center" p="9px 20px">
												{chapter.luaLink ? (
													<Tooltip label="Open Lua">
														<Button
															onClick={() =>
																onSrcLinkClick(chapter.luaLink || '')
															}
															size="square"
															variant="secondary"
															position="static"
															h="auto !important"
														>
															<FileIcon w={5} h={5} />
														</Button>
													</Tooltip>
												) : (
													<>---</>
												)}
											</Td>
											<Td textAlign="center">
												{chapter.googleDoc?.link ? (
													<Tooltip label="Open Google Doc">
														<Button
															onClick={() =>
																onSrcLinkClick(chapter.googleDoc?.link || '')
															}
															size="square"
															variant="secondary"
															position="static"
														>
															<FileIcon w={5} h={5} />
														</Button>
													</Tooltip>
												) : (
													<>&nbsp;&nbsp;---</>
												)}
											</Td>
											<Td>
												{chapter.status ? (
													mapStatus(chapter.status)
												) : (
													<>&nbsp;&nbsp;---</>
												)}
											</Td>
											<Td>
												<Flex>
													{chapter?.managementStatus?.map((status: string) => (
														<Badge
															key={status}
															variant={status?.toLowerCase()}
															marginRight="5px"
														>
															{FORMAT_STATUS_MAP[status]}
														</Badge>
													))}
												</Flex>
											</Td>
										</Tr>
									))}
							</Tbody>
						</Table>
					</TableContainer>
				</Box>
			)}
		</Stack>
	);
};
