import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
	Button,
	Flex,
	Grid,
	Modal,
	ModalContent,
	ModalOverlay,
} from '@chakra-ui/react';
import { debounce } from 'lodash';

import { ButtonType } from 'shared/analytics';
import { ArrayElement } from 'shared/model';
import {
	HeroesQuery,
	useHeroesQuery,
} from 'widgets/existing-heroes-modal/graphql/queries.gen';
import { AssetsSortArgDto } from 'models.gen';

import { sortOptions } from './constant/sortOptions';
import ChooseHeroesTab from './ui/choose-heroes-tab';
import Header from './ui/header';

interface SelectHeroModalProps {
	isOpen: boolean;
	onClose: () => void;
	chapterId: string;
	handleSetFieldValue: (
		field: string,
		value: any,
		shouldValidate?: any,
	) => void;
}

const HEROES_LIMIT = 10;

const SORT_MAP: Record<string, AssetsSortArgDto> = {
	'updatedAt-desc': {
		field: 'updatedAt',
		order: 'DESC',
	},
	'name-asc': {
		field: 'names',
		order: 'ASC',
	},
	'name-desc': {
		field: 'names',
		order: 'DESC',
	},
	'used-asc': {
		field: 'used',
		order: 'asc',
	},
	'used-desc': {
		field: 'used',
		order: 'desc',
	},
};

export const SelectHeroesModal: React.FC<SelectHeroModalProps> = ({
	isOpen,
	onClose,
	chapterId,
	handleSetFieldValue,
}) => {
	const [searchHero, setSearchHero] = useState('');
	const [searchHeroValue, setSearchHeroValue] = useState('');
	const [sortHeroes, setSortHeroes] = useState(sortOptions[2].value);
	const [selectedHero, setSelectedHero] = useState<ArrayElement<
		HeroesQuery['heroes']
	> | null>(null);

	const [page, setPage] = useState(1);
	const [loadMore, setLoadMore] = useState(false);

	const debouncedSetSearch = useMemo(
		() => debounce((v) => setSearchHero(v), 1000),
		[setSearchHero],
	);

	useEffect(() => {
		debouncedSetSearch(searchHeroValue);

		return () => debouncedSetSearch.cancel();
	}, [searchHeroValue, debouncedSetSearch]);

	useEffect(() => {
		setPage(1);
	}, [selectedHero, sortHeroes, searchHero]);

	const { data, loading, fetchMore } = useHeroesQuery({
		variables: {
			page: 0,
			limit: HEROES_LIMIT,
			filter: {
				chapterId,
				name: searchHero || undefined,
			},
			sort: SORT_MAP[sortHeroes] || undefined,
		},
		fetchPolicy: 'network-only',
		nextFetchPolicy: 'network-only',
		notifyOnNetworkStatusChange: true,
	});

	const defaultState = useCallback(() => {
		setSearchHero('');

		setSortHeroes(sortOptions[2].value);
		setSelectedHero(null);
	}, []);

	const onCloseModal = useCallback(() => {
		onClose();
		defaultState();
	}, [defaultState, onClose]);

	const handleSubmit = useCallback(async () => {
		onCloseModal();
		handleSetFieldValue('hero', selectedHero);
		handleSetFieldValue('heroName', selectedHero?.names[0]);
	}, [handleSetFieldValue, onCloseModal, selectedHero]);

	const loadMoreHeroes = useCallback(() => {
		fetchMore({
			variables: {
				page,
			},
			updateQuery: (prev, { fetchMoreResult }) => {
				if (!fetchMoreResult || fetchMoreResult.heroes.length === 0) {
					setLoadMore(false);
					return prev;
				}
				return { ...prev, heroes: [...prev.heroes, ...fetchMoreResult.heroes] };
			},
		});
	}, [fetchMore, page]);

	const handleLoadMoreHeroes = useCallback(() => {
		loadMoreHeroes();
		setPage((prevState) => prevState + 1);
	}, [loadMoreHeroes]);

	useEffect(() => {
		setLoadMore(false);

		if (data?.heroes?.length && data?.heroes?.length >= HEROES_LIMIT) {
			setLoadMore(true);
		}
	}, [data?.heroes?.length]);

	return (
		<Modal isOpen={isOpen} onClose={onCloseModal}>
			<ModalOverlay />
			<ModalContent minW={650} m={0} overflow="hidden">
				<Grid templateRows="auto 1fr auto" h={700}>
					<Header
						searchHero={searchHeroValue}
						setSearchHero={setSearchHeroValue}
						onCloseModal={onCloseModal}
					/>
					<Grid templateRows="auto 1fr" overflow="auto">
						<ChooseHeroesTab
							selectedHero={selectedHero}
							setSelectedHero={setSelectedHero}
							sortHeroes={sortHeroes}
							setSortHeroes={setSortHeroes}
							heroes={data?.heroes || null}
							loadingHeroes={loading}
							handleLoadMoreHeroes={handleLoadMoreHeroes}
							isLoading={loading}
							loadMore={loadMore}
						/>
					</Grid>
					<Flex
						justifyContent="flex-end"
						alignItems="center"
						gap={5}
						px={6}
						py={4}
						borderTop="1px solid #eeeef2"
					>
						<Button
							type="button"
							size="lg"
							variant="secondary"
							onClick={onCloseModal}
						>
							Cancel
						</Button>
						<Button
							name={ButtonType.ADD_HERO_TO_CARD}
							type="button"
							size="lg"
							variant="primary"
							onClick={handleSubmit}
							isDisabled={!selectedHero}
						>
							Apply
						</Button>
					</Flex>
				</Grid>
			</ModalContent>
		</Modal>
	);
};
