import React, { useCallback, useMemo } from 'react';
import { Checkbox, GridItem, Text } from '@chakra-ui/react';

import { FilterKeywords } from '../constant';

interface KeywordsListProps {
	keyword: string;
	keywordsKey: string;
	filterKeywords: any;
	setFilterKeywords: (v: FilterKeywords | null) => void;
}

export const KeywordItem: React.FC<KeywordsListProps> = ({
	keyword,
	keywordsKey,
	filterKeywords,
	setFilterKeywords,
}) => {
	const onChange = useCallback(
		(e) => {
			const { value, checked } = e.target;
			if (checked) {
				if (filterKeywords) {
					setFilterKeywords({
						...filterKeywords,
						[keywordsKey]: [
							...(filterKeywords[keywordsKey as any] || []),
							value,
						],
					});
				} else {
					setFilterKeywords({
						[keywordsKey]: [value],
					});
				}
			} else {
				setFilterKeywords({
					...filterKeywords,
					[keywordsKey]: [
						...filterKeywords[keywordsKey as any].filter(
							(w: any) => w !== value,
						),
					],
				});
			}
		},
		[filterKeywords, keywordsKey, setFilterKeywords],
	);

	const isChecked = useMemo(() => {
		if (filterKeywords) {
			return filterKeywords[keywordsKey as keyof FilterKeywords]?.includes(
				keyword,
			);
		}
		return false;
	}, [filterKeywords, keyword, keywordsKey]);
	return (
		<GridItem>
			<Checkbox
				w="fit-content"
				p="8px 0"
				fontSize="14px"
				value={keyword}
				onChange={onChange}
				isChecked={isChecked}
			>
				<Text fontSize="14px" lineHeight="16px">
					{keyword}
				</Text>
			</Checkbox>
		</GridItem>
	);
};
