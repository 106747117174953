import React, { useEffect, useMemo, useState } from 'react';
import { Box, Button, Flex, Spinner, useDisclosure } from '@chakra-ui/react';
import { debounce } from 'lodash';

import { PencilIcon, PlusIcon } from 'shared/icons';
import { SinglePicker } from 'shared/ui';

import { useFiltersQuery } from './queries.gen';

interface Props {
	label: string;
	selectedValue: string;
	onApply: (v: string | null) => void;
	isCreateForm?: boolean;
}

export const FilterPicker: React.FC<Props> = ({
	label,
	selectedValue,
	onApply,
	isCreateForm,
}) => {
	const { onOpen, onClose, isOpen } = useDisclosure();
	const [value, setValue] = useState(selectedValue);
	const [search, setSearch] = useState('');
	const [searchValue, setSearchValue] = useState('');

	const { data: options, loading } = useFiltersQuery();

	const debouncedSetSearch = useMemo(
		() => debounce(setSearch, 2000),
		[setSearch],
	);

	useEffect(() => {
		debouncedSetSearch(searchValue);

		return () => debouncedSetSearch.cancel();
	}, [searchValue, debouncedSetSearch]);

	const optionsValue = useMemo(
		() =>
			(
				options?.filters.map((filterValue) => ({
					title: filterValue,
					value: filterValue,
				})) || []
			).sort((a, b) => a.title.localeCompare(b.title)),
		[options],
	);

	const filteredSearch = useMemo(
		() =>
			search
				? optionsValue.filter(({ title }) =>
						title.toLowerCase().includes(search.toLowerCase().trim()),
				  )
				: optionsValue,
		[optionsValue, search],
	);

	if (loading) return <Spinner />;

	return (
		<Flex
			justifyContent="space-between"
			p="20px 0 17px"
			borderBottom={isCreateForm ? '' : '1px solid #eeeef2;'}
		>
			<Box fontSize="14px" fontWeight={500} lineHeight="16px" color="#242533">
				{label}
			</Box>
			<Flex alignItems="center">
				{selectedValue ? (
					<Box fontSize="13px" mr="10px" fontWeight="500" lineHeight="16px">
						{selectedValue}
					</Box>
				) : null}

				<SinglePicker
					options={filteredSearch}
					isOpen={isOpen}
					onOpen={onOpen}
					onClose={onClose}
					searchValue={searchValue}
					setSearchValue={setSearchValue}
					selectedValue={value}
					onChange={setValue}
					onApply={onApply}
					initialValue={selectedValue}
				>
					<Button type="button" size="circle" variant="secondary">
						{selectedValue ? <PencilIcon w={6} h={6} /> : <PlusIcon />}
					</Button>
				</SinglePicker>
			</Flex>
		</Flex>
	);
};
