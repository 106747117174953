import React from 'react';
import { createIcon } from '@chakra-ui/react';

export const InfoIcon = createIcon({
	viewBox: '0 0 24 24',
	path: (
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21ZM11.05 8.9998C11.05 8.47513 11.4754 8.0498 12 8.0498H12.0075C12.5322 8.0498 12.9575 8.47513 12.9575 8.9998C12.9575 9.52448 12.5322 9.9498 12.0075 9.9498H12C11.4754 9.9498 11.05 9.52448 11.05 8.9998ZM12 11.0498C12.5247 11.0498 12.95 11.4751 12.95 11.9998V15.9998C12.95 16.5245 12.5247 16.9498 12 16.9498C11.4754 16.9498 11.05 16.5245 11.05 15.9998V11.9998C11.05 11.4751 11.4754 11.0498 12 11.0498Z"
			fill="currentColor"
		/>
	),
});
