import React, { useCallback, useMemo, useRef } from 'react';
import {
	Box,
	Flex,
	Grid,
	Image,
	Spinner,
	Text,
	Tooltip,
} from '@chakra-ui/react';
import { useStore } from 'effector-react';

import { uiModel } from 'entities/ui';
import useInfiniteScroll from 'shared/hooks/infinite-scroll';
import { WarningIcon } from 'shared/icons';
import NotFound from 'shared/ui/not-found';
import { Asset } from 'models.gen';

import { ASSETS_LIMIT } from '../constant/sortOptions';

const noneBackground = {
	link: '/images/common/none-background.png',
	name: '',
	id: '',
};

interface AssetsCardsProps {
	loading: boolean;
	type?: string;
	heroId?: string;
	isHeroAddBackground?: boolean;
	isSelected: (id: string) => boolean;
	handleSelectAsset: (asset: { id: string; name: string }) => void;
	setSelectedAssets: (assets: { id: string; name: string }[]) => void;
	assetsData: Partial<Asset>[];
	handleFetchMore: () => void;
}

export const AssetsCards: React.FC<AssetsCardsProps> = ({
	loading,
	isSelected,
	isHeroAddBackground,
	setSelectedAssets,
	handleSelectAsset,
	type,
	heroId,
	assetsData,
	handleFetchMore,
}) => {
	const observeRef = useRef(null);
	const cardHeight = useMemo(
		() => (type === 'hair' || type === 'background' ? '180px' : '300px'),
		[type],
	);

	const handleLoadMore = (entries: { isIntersecting: boolean }[]) => {
		if (!assetsData) return;

		const target = entries[0];

		if (assetsData?.length < ASSETS_LIMIT) return;
		if (target.isIntersecting && !loading) {
			handleFetchMore();
		}
	};

	const usedAssetsBefore = useStore(uiModel.$usedAssetsBefore);

	const usedAssetIcon = useCallback(
		(id) => {
			const usedAsset = usedAssetsBefore.find(
				(assets) => assets.heroId !== heroId && assets.ids.includes(id),
			);
			return usedAsset ? (
				<Tooltip
					label={
						<Text color="#fff" lineHeight="15px">
							This {type} is already used <br /> in this story for{' '}
							{usedAsset.heroName} hero
						</Text>
					}
				>
					<WarningIcon
						w="24px"
						h="24px"
						position="absolute"
						top="10px"
						right="10px"
						color="#644DED"
					/>
				</Tooltip>
			) : null;
		},
		[heroId, type, usedAssetsBefore],
	);
	useInfiniteScroll(observeRef, handleLoadMore);

	const assets = useMemo(
		() => (isHeroAddBackground ? [noneBackground, ...assetsData] : assetsData),
		[isHeroAddBackground, assetsData],
	);

	return (
		<>
			{loading && !assetsData.length && <Spinner size="xl" m="auto" />}
			{!!assetsData.length && (
				<Grid templateRows="auto 1fr" overflow="auto">
					<Grid
						px={5}
						pb={4}
						gap={3}
						gridRowGap={6}
						gridColumnGap={3}
						templateColumns="repeat(3, 1fr)"
						alignContent="start"
					>
						{assets.map((asset) => (
							<Flex
								key={asset.id}
								flexDirection="column"
								gap={2}
								minW="240px"
								maxW="260px"
							>
								<Box
									h="min-content"
									userSelect="none"
									border="4px solid transparent"
									borderRadius="10px"
									cursor="pointer"
									borderColor={`${
										isSelected(asset.id as string)
											? 'rgba(100, 77, 237, 0.08)'
											: ''
									}`}
									onClick={() =>
										isHeroAddBackground
											? setSelectedAssets([
													{
														id: asset.id as string,
														name: asset.name || '',
													},
											  ])
											: handleSelectAsset({
													id: asset.id as string,
													name: asset.name || '',
											  })
									}
								>
									<Flex
										height={cardHeight}
										alignItems="center"
										justifyContent="center"
										border="1px solid #EEEEF2"
										borderRadius="10px"
										overflow="hidden"
										borderColor={`${
											isSelected(asset.id as string) ? '#644DED' : ''
										}`}
										_hover={{
											borderColor: '#644DED',
										}}
										position="relative"
									>
										<Box
											position="relative"
											top={type === 'hair' ? '50%' : '0'}
											transform={type === 'hair' ? 'scale(0.75)' : 'scale(1)'}
										>
											<Image
												alt={asset?.name || ''}
												src={asset?.link || ''}
												objectFit="scale-down"
												h={type === 'hair' ? 'auto' : '300px'}
											/>
										</Box>

										{usedAssetIcon(asset.id as string)}
									</Flex>
								</Box>
								<Text p={1} fontSize="12px" fontWeight={600} lineHeight="16px">
									{asset.name}
								</Text>
							</Flex>
						))}
						<Box minH={4} ref={observeRef} />
					</Grid>
				</Grid>
			)}
			{!loading && !assetsData.length ? (
				<Box m="auto">
					<NotFound />
				</Box>
			) : null}
		</>
	);
};
