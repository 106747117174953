import React, { useCallback, useEffect, useState } from 'react';
import { Box, Flex, Text, useDisclosure } from '@chakra-ui/react';

import { AssetType } from 'entities/assets/model';
import { useRenameImageMutation } from 'features/upload-file/queries.gen';
import { ButtonType } from 'shared/analytics';
import { api } from 'shared/api';
import { useAppToast } from 'shared/hooks/toast';
import { EditableField } from 'shared/ui';
import { ImageUpload } from 'shared/ui/image-upload';
import { ImageView } from 'shared/ui/image-view';
import { findSpaces } from 'shared/utils/find-space';
import { getFilenameFromURL } from 'shared/utils/get-filename-from-url';
import {
	fileSizeValidate,
	fileTypeValidate,
	imageResolutionValidate,
} from 'shared/utils/validation';
import { ImageUploadModal } from 'widgets/image-upload-modal';

export interface Props {
	label: string;
	type: AssetType;
	imageSrc: string;
	onImageChange: (link: string | null) => void;
	error?: string;
	atName?: string;
	isUploadError?: boolean;
	isObjectFitCover?: boolean;
}

const RESOLUTION_CONFIG: Record<
	AssetType,
	{ w: number | string; h: number | string; extensions: string[] }
> = {
	// todo to disable resolution validation set width and height to 'any'
	// [AssetType.AUDIO]: { w: 0, h: 0, extensions: [] }, // not an image
	[AssetType.BACKGROUND]: {
		w: 2330,
		h: 1920,
		extensions: ['image/jpeg', 'image/png'],
	},
	[AssetType.ITEM]: { w: 1024, h: 1024, extensions: ['image/png'] },
	[AssetType.BODY]: { w: 731, h: 1661, extensions: ['image/png'] },
	[AssetType.CLOTHES]: { w: 731, h: 1661, extensions: ['image/png'] },
	[AssetType.HAIR]: { w: 731, h: 1661, extensions: ['image/png'] },
	[AssetType.LAYER]: { w: 731, h: 1661, extensions: ['image/png'] },
	// [AssetType.BODY]: { w: 'any', h: 'any', extensions: ['image/png'] },
	// [AssetType.CLOTHES]: { w: 'any', h: 'any', extensions: ['image/png'] },
	// [AssetType.HAIR]: { w: 'any', h: 'any', extensions: ['image/png'] },
	// [AssetType.LAYER]: { w: 'any', h: 'any', extensions: ['image/png'] },
	// [AssetType.CHAT_PHOTO]: { w: 750, h: 1334, extensions: ['image/jpeg'] },
};

const validateImage = async (file: File, type: AssetType) => {
	const config = RESOLUTION_CONFIG[type];
	const filetypeValidationResult = fileTypeValidate(file, config.extensions);
	// for different file types (e.g pdf)
	if (!filetypeValidationResult) {
		return false;
	}
	const resolutionValidationResult =
		config.w === 'any' && config.h === 'any'
			? true
			: await imageResolutionValidate(file, {
					width: config.w,
					height: config.h,
			  });
	const fileSizeValidationResult = fileSizeValidate(file);
	return (
		filetypeValidationResult &&
		resolutionValidationResult &&
		fileSizeValidationResult
	);
};

export const ImageReview: React.FC<Props> = ({
	label,
	type,
	imageSrc,
	onImageChange,
	error,
	atName,
	isUploadError,
	isObjectFitCover = true,
}) => {
	const toast = useAppToast();
	const [link, setLink] = useState(imageSrc);
	// airtable bullshit
	const [filenameValue, setFilenameValue] = useState(
		atName || getFilenameFromURL(link) || '',
	);
	const { isOpen, onClose, onOpen } = useDisclosure();
	const [renameImage] = useRenameImageMutation();

	const onPhotoPreview = useCallback(
		async (files: File[]) => {
			if (files && files[0]) {
				const file = files[0];
				const isValid = await validateImage(file, type);
				const isSpaces = findSpaces(file.name);

				if (isSpaces) {
					toast({
						title: 'Image was not added',
						description: "The filename shouldn't contain spaces",
						status: 'error',
					});

					return;
				}
				if (!isValid) {
					toast({
						title: 'Image was not added',
						description: 'Image size, extension or resolution is wrong',
						status: 'error',
					});

					return;
				}

				setLink('');
				onOpen();
				await api.files
					.saveSingleFile(file, 'image', file.name.trim())
					.then((res) => {
						const resultLink = res.data?.uploadFileToFolder as string;
						setLink(resultLink);
					});
			}
		},
		[onOpen, toast, type],
	);

	const onPhotoUpload = async (resultLink: string) => {
		await onImageChange(resultLink);
		onClose();
	};

	const onPhotoCancel = useCallback(() => {
		setLink(imageSrc);
	}, [imageSrc]);

	const onNameChange = useCallback(
		async (filename: string) => {
			if (link) {
				const res = await renameImage({
					variables: { link, name: filename },
				});
				onImageChange(res.data?.renameFile || '');
			}
		},
		[link, onImageChange, renameImage],
	);

	useEffect(() => {
		setLink(imageSrc);
		setFilenameValue(atName || getFilenameFromURL(imageSrc) || '');
	}, [imageSrc, atName]);

	const resolutionConfig = RESOLUTION_CONFIG[type];
	return (
		<>
			<Text pt="19px" pb="15px" fontSize="14px">
				{label}
			</Text>

			<Box w="240px" color="#242533">
				{link && !isUploadError ? (
					<ImageView
						link={link}
						type={type}
						isObjectFitCover={isObjectFitCover}
						handlePhotoDelete={() => onImageChange(null)}
					/>
				) : (
					<ImageUpload
						w={resolutionConfig.w}
						h={resolutionConfig.h}
						handlePhotoUpload={onPhotoPreview}
						type={type}
					/>
				)}

				{link && !isUploadError ? (
					<Flex
						w="240px"
						mt="8px"
						alignItems="center"
						justifyContent="space-between"
					>
						<EditableField
							submitButtonName={ButtonType.EDIT_ASSET_FILE_NAME}
							onApply={onNameChange}
							initialValue={filenameValue}
						/>
					</Flex>
				) : null}
				{error && (
					<Text
						pt={2}
						fontWeight={600}
						fontSize="13px"
						lineHeight="17px"
						color="#ff218c"
					>
						{error}
					</Text>
				)}
			</Box>
			<ImageUploadModal
				name={getFilenameFromURL(imageSrc) || ''}
				link={link}
				onApply={onPhotoUpload}
				onCancel={onPhotoCancel}
				isFormOpened={isOpen}
				onClose={onClose}
			/>
		</>
	);
};
