/* eslint-disable sonarjs/cognitive-complexity */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
	Box,
	Grid,
	GridItem,
	Modal,
	ModalCloseButton,
	ModalContent,
	ModalOverlay,
} from '@chakra-ui/react';
import { useStore } from 'effector-react';

import { uiModel } from 'entities/ui';
import { GetBookQuery } from 'features/book/queries.gen';
import { trackChapterPropertiesModalClose } from 'shared/analytics/modals/close';
import { ChapterPropTabs } from 'widgets/update-chapter-modal/ui/lib/chapter-prop-tabs';
import { LocalizationTab } from 'widgets/update-chapter-modal/ui/localization-tab';
import { Doc } from 'models.gen';

import { GeneralTab } from './general-tab';
import Header from './header';
import SideNavbar from './side-navbar';

export interface UpdateChapterModalProps {
	book: GetBookQuery['book'];
	chapters: any[];
	chapterId?: string;
	openDocs?: boolean;
	googleDocLinks?: {
		prod?: string;
		prod2pov?: string;
		prodLinkVersionB?: Doc | null;
		prodLinkVersionC?: Doc | null;
		prodLinkVersionD?: Doc | null;
		writer?: string;
		narrative?: string;
	};
}

export const UpdateChapterModal: React.FC<UpdateChapterModalProps> = ({
	book,
	chapterId,
	openDocs,
	chapters,
	googleDocLinks,
}) => {
	const [currentChapterId, setCurrentChapterId] = useState('');
	const [activeTab, setActiveTab] = useState(ChapterPropTabs.GENERAL);

	const navigate = useNavigate();
	const { googleDocId } = useParams();

	const handleTabClick = useCallback((title: ChapterPropTabs) => {
		setActiveTab(title);
	}, []);

	const chaptersIds = useMemo(
		() =>
			chapters
				?.sort((a, b) => (a.chapterOrder || 0) - (b.chapterOrder || 0))
				.map(({ id }) => id) || [],
		[chapters],
	);
	const currentChapter = useMemo(
		() => chapters?.find(({ id }) => id === currentChapterId),
		[chapters, currentChapterId],
	);

	const isUpdateChapterModalOpened = useStore(
		uiModel.$isUpdateChapterModalOpened,
	);

	const onChapterChange = useCallback((id: string) => {
		setCurrentChapterId(id);
	}, []);

	const renderComponent = useCallback(() => {
		switch (activeTab) {
			case ChapterPropTabs.GENERAL: {
				return (
					<GeneralTab
						book={book}
						openDocs={openDocs}
						currentChapter={currentChapter}
						googleDocLinks={googleDocLinks}
					/>
				);
			}
			case ChapterPropTabs.LOCALIZATION: {
				return <LocalizationTab book={book} currentChapter={currentChapter} />;
			}
			default:
				return <p>Tab Not Found</p>;
		}
	}, [activeTab, book, currentChapter, googleDocLinks, openDocs]);

	useEffect(() => {
		if (
			!chapterId &&
			(!currentChapterId || !chaptersIds.includes(currentChapterId)) &&
			chaptersIds.length
		) {
			setCurrentChapterId(chaptersIds[0] || '');
		}
	}, [chapterId, chaptersIds, currentChapterId]);

	useEffect(() => {
		if (chapterId) {
			setCurrentChapterId(chapterId);
		}
	}, [chapterId]);

	if (!currentChapter) return null;

	return (
		<Modal
			isOpen={!!currentChapterId && isUpdateChapterModalOpened}
			onClose={() => {
				uiModel.setIsUpdateChapterModalOpened(false);
				trackChapterPropertiesModalClose();
				if (googleDocId === 'no-doc') {
					navigate(
						`/stories/${book.id}/${chapterId}/${
							currentChapter.googleDocWriter?.id ||
							currentChapter.googleDocNarrative?.id ||
							currentChapter.googleDoc?.id ||
							currentChapter.googleDocABTest1?.id ||
							currentChapter.googleDocABTest2?.id ||
							currentChapter.googleDocABTest3?.id ||
							currentChapter.additionalDocs?.[0]?.id ||
							'no-doc'
						}`,
					);
				}
			}}
		>
			<ModalOverlay />
			<ModalContent minW={910}>
				<ModalCloseButton margin="13px 5px" borderRadius={8} />
				<Grid templateColumns="auto 1fr" h={700} mb="10px">
					<GridItem>
						<SideNavbar
							onChapterChange={onChapterChange}
							currentChapterName={`${currentChapter?.chapterOrder || '-'}. ${
								currentChapter?.name || ''
							}`}
							activeTab={activeTab}
							handleTabClick={handleTabClick}
							chapters={chapters || []}
							chapterId={chapterId}
							setCurrentChapterId={setCurrentChapterId}
						/>
					</GridItem>
					<GridItem>
						<Header title={activeTab} />
						<Box h={700 - 78} overflow="auto">
							{renderComponent()}
						</Box>
					</GridItem>
				</Grid>
			</ModalContent>
		</Modal>
	);
};
