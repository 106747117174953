import { FetchResult } from '@apollo/client';
import gql from 'graphql-tag';

import { apiInstance } from './base';
import { convertScript } from './model/convertScript';

export const CONVERT_MUTATION = gql`
	mutation convertScript($dto: ConvertScriptDto!) {
		convertScript(dto: $dto) {
			text
			errors {
				message
				line
				position
				type
			}
			warnings {
				message
				line
				position
			}
		}
	}
`;

export const convert = async (
	content: string,
	chapterId: string,
	docType?: string,
	lang?: string,
): Promise<FetchResult<convertScript>> => {
	return apiInstance.mutate({
		mutation: CONVERT_MUTATION,
		variables: { dto: { content, chapterId, docType, lang } },
	});
};
