import { extendTheme, ThemeConfig } from '@chakra-ui/react';

import { components } from './components/index';

const config: ThemeConfig = {
	initialColorMode: 'light',
	useSystemColorMode: false,
};

const overrides = {
	config,
	components,
};

export default extendTheme({
	...overrides,
	fonts: {
		heading: `'Poppins', sans-serif`,
		body: `'Poppins', sans-serif`,
	},
});
