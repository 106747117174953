import React, { memo, MouseEventHandler, useCallback, useState } from 'react';
import {
	Badge,
	Box,
	HStack,
	Image,
	Spinner,
	Td,
	Text,
	Tooltip,
	Tr,
} from '@chakra-ui/react';

import { ButtonType } from 'shared/analytics';
import { AnalyticsIconButton } from 'shared/analytics/ui/icon-button';
import { TrashBinIcon } from 'shared/icons';
import { ArrayElement } from 'shared/model';
import { formatDate } from 'shared/utils';
import { CardsByStoryQuery } from 'widgets/cards-list/graphql/queries.gen';
import { UpdateCardModalContainer } from 'widgets/update-card-modal/ui/container';

interface CardListRowProps {
	card: ArrayElement<CardsByStoryQuery['cards']['cards']>;
	index?: number | null;
	currentBookId: string;
	handleCardDelete: (
		card: ArrayElement<CardsByStoryQuery['cards']['cards']>,
	) => MouseEventHandler<HTMLButtonElement>;
	refetch: () => void;
	loading: boolean;
}

export const CardListRow: React.FC<CardListRowProps> = memo(
	({ card, index, handleCardDelete, refetch, currentBookId, loading }) => {
		const [isModalOpen, setModalOpen] = useState(false);
		const [isCardGenerating, setIsCardGenerating] = useState(false);
		const [updatedCardId, setUpdatedCardId] = useState('');

		const handleModalClose = useCallback(() => {
			setModalOpen(false);
			refetch();
		}, [refetch]);

		const handleModalOpen = useCallback(() => {
			setModalOpen(true);
			setUpdatedCardId(card.id);
		}, [card.id]);

		return (
			<Tr cursor={isCardGenerating ? 'not-allowed' : 'pointer'}>
				<Tooltip label="Open Card">
					<Td
						py={0}
						position="sticky"
						left={0}
						zIndex={100}
						onClick={isCardGenerating ? () => null : () => handleModalOpen()}
					>
						<HStack alignItems="center" p="5px 0">
							<Text textAlign="center" minW="26px" mr="20px">
								{index}
							</Text>
							<Box
								mt="10px"
								pos="relative"
								borderRadius="15px"
								h="75px"
								w="75px"
								minW="75px"
								minH="75px"
								overflow="hidden"
							>
								{loading ? (
									<Spinner size="sm" mr="7px" />
								) : (
									<Image
										position="absolute"
										margin="auto"
										htmlHeight="100%"
										htmlWidth="100%"
										alt="card-image"
										src={card?.cardsLink[0] || ''}
									/>
								)}
							</Box>
							<Text
								pl={2}
								fontWeight={600}
								minW="150px"
								overflow="hidden"
								whiteSpace="nowrap"
								textOverflow="ellipsis"
							>
								{card.filename}
							</Text>
						</HStack>
					</Td>
				</Tooltip>
				<Td>{card.golden ? 'Gold' : 'Default'}</Td>
				<Td>{card.chapter?.chapterOrder}</Td>
				<Td>
					<Badge variant="logline">
						<Text>{card.heroName}</Text>
					</Badge>
				</Td>
				<Td>{formatDate(card.updatedAt)}</Td>
				<Td>
					<Tooltip label="Delete Cards">
						<AnalyticsIconButton
							name={ButtonType.DELETE_CARD}
							aria-label="remove-card"
							variant="ghost"
							size="square"
							onClick={handleCardDelete(card)}
						>
							<TrashBinIcon w={6} h={6} />
						</AnalyticsIconButton>
					</Tooltip>
				</Td>
				<UpdateCardModalContainer
					isOpen={isModalOpen}
					bookId={currentBookId}
					onClose={handleModalClose}
					setCardId={setUpdatedCardId}
					setIsCardGenerating={setIsCardGenerating}
					cardId={updatedCardId}
					refetch={refetch}
				/>
			</Tr>
		);
	},
);
