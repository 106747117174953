import React, { useMemo } from 'react';
import { Input, Tooltip } from '@chakra-ui/react';
import { useField } from 'formik';

import styles from './styles.module.scss';

interface IFormInputFieldProps {
	label?: string;
	name: string;
	required?: boolean;
	className?: string;
	placeholder?: string;
	autocomplete?: 'off' | 'on';
	inputSize?: 'sm' | 'md' | 'lg';
	canDisplayError?: boolean;
	inputPadding?: string;
	type?: string;
	preventEnter?: boolean;
	inputRef?: React.RefObject<HTMLInputElement> | null;
}

export const FormInputField = ({
	label,
	required = false,
	name,
	placeholder,
	className = '',
	autocomplete = 'on',
	canDisplayError = true,
	inputSize,
	inputPadding,
	type = 'text',
	preventEnter,
	inputRef,
}: IFormInputFieldProps) => {
	const [field, meta, { setTouched }] = useField({ name });

	const isError: boolean = useMemo(
		() => !!(meta.touched && meta.error),
		[meta],
	);

	return (
		<div className={`${styles.container} ${className}`}>
			{label && (
				<div className={styles.labelContainer}>
					{required && <div className={styles.requiredMark}>*</div>}
					<label className={styles.label}>{label}</label>
				</div>
			)}
			<Tooltip
				label={meta.error}
				isDisabled={!canDisplayError || !isError}
				placement="bottom-start"
				bg="#ff218c"
				offset={[0, 2]}
			>
				<Input
					{...field}
					ref={inputRef}
					isInvalid={isError}
					placeholder={placeholder}
					autoComplete={autocomplete}
					size={inputSize}
					pr={inputPadding}
					type={type}
					onFocus={() => {
						setTouched(true);
					}}
					onKeyPress={(e) => {
						if (preventEnter && e.code === 'Enter') {
							e.preventDefault();
						}
					}}
				/>
			</Tooltip>
		</div>
	);
};
