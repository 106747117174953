import React, { useState } from 'react';
import {
	Box,
	Button,
	Flex,
	Grid,
	IconButton,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Text,
	Tooltip,
} from '@chakra-ui/react';
import { Form, Formik } from 'formik';

import { DraftCommentEntity } from 'entities/script';
import { UserCommentCard } from 'entities/user';
import { ButtonType } from 'shared/analytics';
import { CheckmarkIcon, MoreIcon } from 'shared/icons';
import { DeletionAlert, FormTextArea } from 'shared/ui';
import { getRows } from 'shared/utils/get-rows';

import { UpdateCommentFormValues } from '../../schema';

interface EditCommentFormProps {
	commentEntity: DraftCommentEntity;
	handleCommentEdit: (comment: any) => void;
	handleCommentDelete: () => void;
	handleCommentResolve: () => void;
	handleCommentScroll: () => void;
}

// TODO remove arrow functions from JSX
export const EditCommentForm: React.FC<EditCommentFormProps> = ({
	commentEntity,
	handleCommentEdit,
	handleCommentDelete,
	handleCommentResolve,
	handleCommentScroll,
}) => {
	const [isEditing, setIsEditing] = useState(false);
	const [isDeleting, setIsDeleting] = useState(false);

	return (
		<>
			<Grid templateColumns="1fr 90px" alignItems="center">
				<Box onClick={handleCommentScroll} padding="11px 14px">
					<UserCommentCard
						author={commentEntity?.entityData?.author || '---'}
						picture={commentEntity?.entityData?.picture}
						createdAt={commentEntity?.entityData?.createdAt || ''}
					/>
				</Box>
				<Flex alignSelf="start" justify="flex-end" padding="11px 14px">
					{!commentEntity.entityData?.resolved ? (
						<Tooltip label="Resolve">
							<Button variant="ghost" size="sm" onClick={handleCommentResolve}>
								<CheckmarkIcon w={4} h={4} />
							</Button>
						</Tooltip>
					) : null}
					<Menu>
						<MenuButton
							as={IconButton}
							variant="ghost"
							size="sm"
							padding="10px 0"
							icon={<MoreIcon w={5} h={5} />}
						/>
						<MenuList minW="136px">
							<MenuItem onClick={() => setIsEditing(true)}>Edit</MenuItem>
							<MenuItem onClick={() => setIsDeleting(true)}>Delete</MenuItem>
						</MenuList>
					</Menu>
				</Flex>
			</Grid>
			{isEditing ? (
				<Box padding="6px 14px 16px" borderBottom="1px solid #EEEEF2">
					<Formik<UpdateCommentFormValues>
						enableReinitialize
						initialValues={{
							formValue: commentEntity?.entityData?.comment || '',
						}}
						onSubmit={async ({ formValue }) => {
							// * this needed to disable submit on comment value reset
							handleCommentEdit(formValue);
							setIsEditing(false);
						}}
						onReset={() => setIsEditing(false)}
					>
						{({ values }) => (
							<Form>
								<FormTextArea
									name="formValue"
									autocomplete="off"
									placeholder="Comment"
									rows={getRows(values.formValue as string)}
								/>
								<Flex justify="flex-end" mt="10px">
									<Button type="reset" size="md" variant="secondary">
										Cancel
									</Button>
									<Button
										name={ButtonType.EDIT_COMMENT}
										type="submit"
										size="md"
										variant="secondary"
										disabled={!values.formValue}
									>
										Apply
									</Button>
								</Flex>
							</Form>
						)}
					</Formik>
				</Box>
			) : (
				<Box
					onClick={handleCommentScroll}
					padding="6px 14px 16px"
					borderBottom="1px solid #EEEEF2"
				>
					{commentEntity?.entityData?.comment
						.split('\n')
						.map((line) => (line ? <Text>{line}</Text> : <br />))}
				</Box>
			)}
			{isDeleting && (
				<DeletionAlert
					top="38px"
					right="0"
					bottom="0"
					left="0"
					p="36px 10px"
					bg="rgba(36, 37, 51, 0.87)"
					message="Delete this comment thread?"
					onClose={() => setIsDeleting(false)}
					onSubmit={handleCommentDelete}
				/>
			)}
		</>
	);
};
