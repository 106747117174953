import React from 'react';
import { Portal } from '@chakra-ui/react';
import { Formik } from 'formik';

import { AssetType } from 'entities/assets/model';
import { ImageReview } from 'entities/assets/ui/image-review';
import { GetAssetsDocument } from 'pages/art-catalog/ui/ArtCatalogTable/queries.gen';
import { trackAssetCreated } from 'shared/analytics';
import { useGetAssetError } from 'shared/hooks/asset-error';
import { useAppToast } from 'shared/hooks/toast';
import { InputField, InputFieldWithLink } from 'shared/ui';
import { getFilenameFromURL } from 'shared/utils/get-filename-from-url';
import { FilterKeywords } from 'widgets/keywords-filter-modal/constant';

import { KeywordsPicker } from '../../../keywords-picker';
import { createItemSchema, CreateItemValues } from '../../lib/schemas/item';
import { useCreateItemMutation } from '../../queries.gen';
import { ActionButtons } from '../action-buttons';
import { CreateAnyAssetFormProps } from '../types';

export const CreateItemForm: React.FC<CreateAnyAssetFormProps> = ({
	onSubmit,
	onCancel,
	buttonsContainerRef,
}) => {
	const toast = useAppToast();
	const handleError = useGetAssetError();

	const [createItem] = useCreateItemMutation({
		onCompleted: (data) => {
			const keywords = Object.values(
				data.createItem?.newKeywords as FilterKeywords,
			)
				.filter((v) => v)
				.flat();

			if (!keywords.length) {
				toast({
					title: `Item was created without keywords`,
					status: 'warning',
				});
			} else {
				toast({ title: 'Item created successfully', status: 'success' });
			}
			onSubmit();
			trackAssetCreated({
				type: AssetType.ITEM,
				filename: data.createItem?.filename as string,
				link: data.createItem?.link as string,
			});
		},
		onError: (mutationError) => handleError(mutationError.message, 'Item'),
		refetchQueries: [GetAssetsDocument],
	});

	return (
		<Formik<CreateItemValues>
			initialValues={{
				link: '',
				price: 0,
				keywords: [],
				filename: '',
				dropboxLink: '',
				newKeywords: {},
			}}
			validationSchema={createItemSchema}
			onSubmit={(data) =>
				createItem({
					variables: {
						item: {
							...data,
							price: parseInt(data.price as any, 10),
						},
					},
				})
			}
		>
			{({ values, submitForm, setFieldValue, isSubmitting, errors }) => (
				<>
					<InputField
						label="Filename"
						name="filename"
						value={
							getFilenameFromURL(values.link)?.replace(/\.png/, '') ||
							values.filename?.trim() ||
							''
						}
						onApply={(v: string | number) => setFieldValue('filename', v, true)}
						error={errors.filename}
						placeholder="items_object_name"
						isDisabledFilename={!!values.link}
					/>
					<ImageReview
						label="Item"
						type={AssetType.ITEM}
						imageSrc={values.link?.trim()}
						onImageChange={(link) => setFieldValue('link', link?.trim(), true)}
						error={errors.link}
					/>
					<KeywordsPicker
						type={AssetType.ITEM}
						selectedKeywords={values.newKeywords}
						onApply={(v) => setFieldValue('newKeywords', v, true)}
					/>
					<InputFieldWithLink
						label="Link"
						name="dropboxLink"
						value={values.dropboxLink?.trim() || ''}
						onApply={(v: string | number) =>
							setFieldValue('dropboxLink', v, true)
						}
						error={errors.dropboxLink}
						placeholder="https://..."
					/>
					<Portal containerRef={buttonsContainerRef}>
						<ActionButtons
							onApply={submitForm}
							onCancel={onCancel}
							disabled={isSubmitting}
						/>
					</Portal>
				</>
			)}
		</Formik>
	);
};
