import * as Types from '../../../models.gen';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FiltersQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type FiltersQuery = { filters: Array<string> };


export const FiltersDocument = gql`
    query Filters {
  filters
}
    `;

/**
 * __useFiltersQuery__
 *
 * To run a query within a React component, call `useFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFiltersQuery({
 *   variables: {
 *   },
 * });
 */
export function useFiltersQuery(baseOptions?: Apollo.QueryHookOptions<FiltersQuery, FiltersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FiltersQuery, FiltersQueryVariables>(FiltersDocument, options);
      }
export function useFiltersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FiltersQuery, FiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FiltersQuery, FiltersQueryVariables>(FiltersDocument, options);
        }
export type FiltersQueryHookResult = ReturnType<typeof useFiltersQuery>;
export type FiltersLazyQueryHookResult = ReturnType<typeof useFiltersLazyQuery>;
export type FiltersQueryResult = Apollo.QueryResult<FiltersQuery, FiltersQueryVariables>;