import { cssVar } from '@chakra-ui/react';

const $arrowShadowColor = cssVar('popper-arrow-shadow-color');

export const popoverStyles = {
	baseStyle: () => ({
		popper: {
			inset: '0px auto auto -10px',
			border: 'none',
			zIndex: 999,
		},
		body: {
			display: 'flex',
			flexDirection: 'column',
			px: 4,
			py: 1,
			pt: 2.5,
		},
		header: {
			py: 5,
			px: 4,
			border: 'none',
		},
		footer: {
			pt: 1,
			px: 4,
			pb: 3,
			border: 'none',
			d: 'flex',
			alignItems: 'center',
			justifyContent: 'flex-end',
		},
		content: {
			w: '280px',
			border: 'none',
			inset: '0px auto auto -10px',
			[$arrowShadowColor.variable]: 'transparent',
			bg: '#fdfdfd !important',
			boxShadow:
				'0px 4px 4px rgba(0, 0, 0, 0.08), 0px 0px 1px rgba(67, 90, 111, 0.47)',
			borderRadius: '8px',
			outline: 'none',
			_focus: {
				outline: 'none',
				boxShadow:
					'0px 4px 4px rgba(0, 0, 0, 0.08), 0px 0px 1px rgba(67, 90, 111, 0.47)',
			},
		},
	}),
	variants: {
		singleList: {
			body: {
				padding: '6px 6px 10px',
			},
		},
		filterPicker: {
			body: {
				padding: '6px 6px 10px',
				zIndex: 1400,
			},
		},
	},
};
