import React, { useEffect } from 'react';
import { Box, Flex, Image, Text } from '@chakra-ui/react';

import { Audio } from 'shared/ui/audio';

import { AssetType } from '../model';

interface AssetProps {
	id: string;
	name?: string | null;
	link?: string | null;
	type: string;
}

interface Props {
	asset: AssetProps;
	onClick?: (asset: any) => void;
	setSelectedAsset: (asset: AssetProps) => void;
	selectedAsset: AssetProps;
}

export const STYLE_CONFIG: Record<
	AssetType,
	{
		w?: string;
		h?: string;
		objectPosition?: string;
		objectFit?: 'contain';
	}
> = {
	[AssetType.BODY]: {
		h: '302px',
		w: 'full',
		objectPosition: 'center 20%',
		objectFit: 'contain',
	},
	[AssetType.HAIR]: {
		h: '282px',
		w: 'full',
		objectPosition: 'center 10%',
	},
	[AssetType.CLOTHES]: {
		h: '302px',
		w: '70%',
		objectPosition: 'center 100%',
		objectFit: 'contain',
	},
	[AssetType.BACKGROUND]: {
		h: '182px',
		w: 'full',
	},
	[AssetType.ITEM]: {
		h: '182px',
		w: 'full',
		objectPosition: 'center 50%',
		objectFit: 'contain',
	},
	[AssetType.LAYER]: {
		w: 'full',
		h: '302px',
		// objectPosition: 'center 35%',
		objectFit: 'contain',
	},
	// [AssetType.CHAT_PHOTO]: {
	// 	h: '274px',
	// 	w: '65%',
	// },
	// [AssetType.AUDIO]: {},
};

export const AssetCard: React.FC<Props> = ({
	asset,
	onClick,
	setSelectedAsset,
	selectedAsset,
}) => {
	// TODO Maybe we can avoid this useEffect and refetch somewhere
	useEffect(() => {
		if (selectedAsset?.id === asset?.id) {
			setSelectedAsset(asset);
		}
	}, [asset, selectedAsset?.id, setSelectedAsset]);

	return (
		<Box
			cursor="pointer"
			onClick={() => {
				if (onClick) onClick(asset);
			}}
			w={asset.type === AssetType.BODY ? '240px' : 'full'}
			maxW="260px"
		>
			<Box
				border="1px solid #EEEEF2"
				borderRadius="10px"
				_hover={{
					boxShadow: '0 0 0 4px rgba(100, 77, 237, 0.08)',
					borderColor: '#644dED',
				}}
			>
				{asset.type === 'audio' ? (
					<Audio link={asset.link || undefined} />
				) : (
					<Flex justifyContent="center">
						<Image
							objectFit="cover"
							borderRadius="10px"
							src={asset.link || undefined}
							alt={asset.name || undefined}
							{...STYLE_CONFIG[asset.type as AssetType]}
						/>
					</Flex>
				)}
			</Box>
			<Text p="8px 8px 0" fontWeight="600" fontSize="12px" lineHeight="16px">
				{asset.name}
			</Text>
		</Box>
	);
};
