import React from 'react';
import { Box, Divider, Text } from '@chakra-ui/react';

export const WarningTooltip = () => {
	return (
		<Box
			w="220px"
			h="229px"
			boxShadow="0px 0px 1px 0px rgba(67, 90, 111, 0.47), 0px 4px 4px 0px rgba(0, 0, 0, 0.08)"
			borderRadius="8px"
			flexShrink={0}
		>
			<Text
				variant="labelText"
				color="#242533"
				p="14px 20px 14px 16px"
				lineHeight="22px"
			>
				Info
			</Text>
			<Divider />
			<Text variant="tooltipText" p="14px 35px 18px 15px">
				Counter values are incorrect because there is a syntax error in the
				script. Please, fix the error to see the correct number of words and
				choices.
			</Text>
		</Box>
	);
};
