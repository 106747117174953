import React from 'react';
import { Box } from '@chakra-ui/react';

interface Props {
	onClick: () => void;
	commentsCount: number;
	commentRef: React.RefObject<HTMLDivElement> | null;
	isVisible: boolean;
	isFirstLine: boolean;
}

export const CommentButton: React.FC<Props> = ({
	onClick,
	commentsCount,
	commentRef,
	isVisible,
	isFirstLine,
}) => (
	<Box
		position="relative"
		as="span"
		minW={isFirstLine ? '40px' : 'auto'}
		display={isVisible ? 'inline-block' : 'none'}
	>
		<Box
			as="span"
			position="absolute"
			left="0"
			top={isFirstLine ? '-20px' : '-40px'}
			ml={isFirstLine ? '10px' : '0'}
			h="24px"
			w="24px"
			borderRadius="50% 50% 50% 0"
			p="2px"
			minW="auto"
			aria-label="Comment"
			cursor="pointer"
			color="#ffffff"
			boxShadow="0px 4px 4px rgba(0, 0, 0, 0.08), 0px 0px 1px rgba(67, 90, 111, 0.47)"
			ref={commentRef}
			onClick={onClick}
			bg="#fefefb"
		>
			<Box
				as="span"
				display="flex"
				alignItems="center"
				justifyContent="center"
				h="20px"
				w="20px"
				borderRadius="full"
				bg="#644DED"
				contentEditable="false"
				suppressContentEditableWarning
			>
				{commentsCount}
			</Box>
		</Box>
	</Box>
);
