import * as Types from '../../models.gen';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UploadImageMutationVariables = Types.Exact<{
  file: Types.Scalars['Upload'];
  filename: Types.Scalars['String'];
}>;


export type UploadImageMutation = { uploadFileToFolder: string };

export type UploadImagesMutationVariables = Types.Exact<{
  files: Array<Types.Scalars['Upload']> | Types.Scalars['Upload'];
}>;


export type UploadImagesMutation = { uploadFilesToFolder: Array<string> };

export type UploadAudioMutationVariables = Types.Exact<{
  file: Types.Scalars['Upload'];
  filename: Types.Scalars['String'];
}>;


export type UploadAudioMutation = { uploadFileToFolder: string };

export type RenameImageMutationVariables = Types.Exact<{
  link: Types.Scalars['String'];
  name: Types.Scalars['String'];
}>;


export type RenameImageMutation = { renameFile: string };

export type RenameAudioMutationVariables = Types.Exact<{
  link: Types.Scalars['String'];
  name: Types.Scalars['String'];
}>;


export type RenameAudioMutation = { renameFile: string };


export const UploadImageDocument = gql`
    mutation UploadImage($file: Upload!, $filename: String!) {
  uploadFileToFolder(
    file: $file
    folder: "images"
    filename: $filename
    isRequireUuid: true
  )
}
    `;
export type UploadImageMutationFn = Apollo.MutationFunction<UploadImageMutation, UploadImageMutationVariables>;

/**
 * __useUploadImageMutation__
 *
 * To run a mutation, you first call `useUploadImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadImageMutation, { data, loading, error }] = useUploadImageMutation({
 *   variables: {
 *      file: // value for 'file'
 *      filename: // value for 'filename'
 *   },
 * });
 */
export function useUploadImageMutation(baseOptions?: Apollo.MutationHookOptions<UploadImageMutation, UploadImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadImageMutation, UploadImageMutationVariables>(UploadImageDocument, options);
      }
export type UploadImageMutationHookResult = ReturnType<typeof useUploadImageMutation>;
export type UploadImageMutationResult = Apollo.MutationResult<UploadImageMutation>;
export type UploadImageMutationOptions = Apollo.BaseMutationOptions<UploadImageMutation, UploadImageMutationVariables>;
export const UploadImagesDocument = gql`
    mutation UploadImages($files: [Upload!]!) {
  uploadFilesToFolder(files: $files, folder: "images")
}
    `;
export type UploadImagesMutationFn = Apollo.MutationFunction<UploadImagesMutation, UploadImagesMutationVariables>;

/**
 * __useUploadImagesMutation__
 *
 * To run a mutation, you first call `useUploadImagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadImagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadImagesMutation, { data, loading, error }] = useUploadImagesMutation({
 *   variables: {
 *      files: // value for 'files'
 *   },
 * });
 */
export function useUploadImagesMutation(baseOptions?: Apollo.MutationHookOptions<UploadImagesMutation, UploadImagesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadImagesMutation, UploadImagesMutationVariables>(UploadImagesDocument, options);
      }
export type UploadImagesMutationHookResult = ReturnType<typeof useUploadImagesMutation>;
export type UploadImagesMutationResult = Apollo.MutationResult<UploadImagesMutation>;
export type UploadImagesMutationOptions = Apollo.BaseMutationOptions<UploadImagesMutation, UploadImagesMutationVariables>;
export const UploadAudioDocument = gql`
    mutation UploadAudio($file: Upload!, $filename: String!) {
  uploadFileToFolder(file: $file, folder: "audio", filename: $filename)
}
    `;
export type UploadAudioMutationFn = Apollo.MutationFunction<UploadAudioMutation, UploadAudioMutationVariables>;

/**
 * __useUploadAudioMutation__
 *
 * To run a mutation, you first call `useUploadAudioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadAudioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadAudioMutation, { data, loading, error }] = useUploadAudioMutation({
 *   variables: {
 *      file: // value for 'file'
 *      filename: // value for 'filename'
 *   },
 * });
 */
export function useUploadAudioMutation(baseOptions?: Apollo.MutationHookOptions<UploadAudioMutation, UploadAudioMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadAudioMutation, UploadAudioMutationVariables>(UploadAudioDocument, options);
      }
export type UploadAudioMutationHookResult = ReturnType<typeof useUploadAudioMutation>;
export type UploadAudioMutationResult = Apollo.MutationResult<UploadAudioMutation>;
export type UploadAudioMutationOptions = Apollo.BaseMutationOptions<UploadAudioMutation, UploadAudioMutationVariables>;
export const RenameImageDocument = gql`
    mutation RenameImage($link: String!, $name: String!) {
  renameFile(link: $link, folder: "images", name: $name)
}
    `;
export type RenameImageMutationFn = Apollo.MutationFunction<RenameImageMutation, RenameImageMutationVariables>;

/**
 * __useRenameImageMutation__
 *
 * To run a mutation, you first call `useRenameImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRenameImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [renameImageMutation, { data, loading, error }] = useRenameImageMutation({
 *   variables: {
 *      link: // value for 'link'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useRenameImageMutation(baseOptions?: Apollo.MutationHookOptions<RenameImageMutation, RenameImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RenameImageMutation, RenameImageMutationVariables>(RenameImageDocument, options);
      }
export type RenameImageMutationHookResult = ReturnType<typeof useRenameImageMutation>;
export type RenameImageMutationResult = Apollo.MutationResult<RenameImageMutation>;
export type RenameImageMutationOptions = Apollo.BaseMutationOptions<RenameImageMutation, RenameImageMutationVariables>;
export const RenameAudioDocument = gql`
    mutation RenameAudio($link: String!, $name: String!) {
  renameFile(link: $link, folder: "audio", name: $name)
}
    `;
export type RenameAudioMutationFn = Apollo.MutationFunction<RenameAudioMutation, RenameAudioMutationVariables>;

/**
 * __useRenameAudioMutation__
 *
 * To run a mutation, you first call `useRenameAudioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRenameAudioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [renameAudioMutation, { data, loading, error }] = useRenameAudioMutation({
 *   variables: {
 *      link: // value for 'link'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useRenameAudioMutation(baseOptions?: Apollo.MutationHookOptions<RenameAudioMutation, RenameAudioMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RenameAudioMutation, RenameAudioMutationVariables>(RenameAudioDocument, options);
      }
export type RenameAudioMutationHookResult = ReturnType<typeof useRenameAudioMutation>;
export type RenameAudioMutationResult = Apollo.MutationResult<RenameAudioMutation>;
export type RenameAudioMutationOptions = Apollo.BaseMutationOptions<RenameAudioMutation, RenameAudioMutationVariables>;