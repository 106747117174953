import { FetchResult } from '@apollo/client';
import gql from 'graphql-tag';

import { apiInstance } from './base';
import { uploadFileToFolder } from './model/uploadFileToFolder';

const SAVE_FILE_MUTATION = gql`
	mutation uploadFileToFolder(
		$file: Upload!
		$folder: String!
		$filename: String
		$isRequireUuid: Boolean
	) {
		uploadFileToFolder(
			file: $file
			folder: $folder
			filename: $filename
			isRequireUuid: $isRequireUuid
		)
	}
`;

export const saveSingleFile = async (
	file: File | Blob | null,
	folder: string,
	filename?: string,
	isRequireUuid = true,
): Promise<FetchResult<uploadFileToFolder>> => {
	return apiInstance.mutate({
		mutation: SAVE_FILE_MUTATION,
		variables: { file, folder, filename, isRequireUuid },
	});
};
