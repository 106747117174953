import React, { useMemo, useRef } from 'react';
import { Box, Flex, Portal, useOutsideClick } from '@chakra-ui/react';

import { scriptModel } from 'entities/script';

interface ViewCommentModalProps {
	modalPosition?: DOMRect;
	children: React.ReactNode;
}

export const ViewCommentModal: React.FC<ViewCommentModalProps> = ({
	modalPosition,
	children,
}) => {
	const ref = useRef<HTMLDivElement>(null);

	useOutsideClick({
		ref,
		handler: () => scriptModel.setCurrentCommentData(null),
	});
	const isRightModalPosition =
		window.innerWidth - 330 < (modalPosition?.left || 0);

	const left = useMemo(
		() =>
			isRightModalPosition
				? 'none'
				: `${(modalPosition?.left || 0) + 30 || 0}px`,
		[isRightModalPosition, modalPosition?.left],
	);

	const top = useMemo(
		() => `${(modalPosition?.top || 0) + (isRightModalPosition ? 50 : 0)}px`,
		[isRightModalPosition, modalPosition?.top],
	);

	return (
		<Portal>
			<Flex
				position="absolute"
				background="#fdfdfd"
				zIndex="1000"
				top={top} // TODO add normal position handling
				left={left}
				right={isRightModalPosition ? '5' : 'none'}
				width="100%"
				maxWidth="312px"
				direction="column"
				boxShadow="0px 4px 4px rgba(0, 0, 0, 0.08), 0px 0px 1px rgba(67, 90, 111, 0.47)"
				borderRadius="8px"
				mb="16px"
				ref={ref}
			>
				<Box padding="0" maxHeight="400px" overflow="auto">
					{children}
				</Box>
			</Flex>
		</Portal>
	);
};
