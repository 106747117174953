import React, { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import {
	Box,
	Button,
	Flex,
	Menu,
	MenuButton,
	Text,
	Tooltip,
} from '@chakra-ui/react';
import { EditorState } from 'draft-js';
import { useStore } from 'effector-react';

import { scriptModel } from 'entities/script';
import { BackgroundColorPicker } from 'features/editor/background-color-picker';
import { CreateComment } from 'features/editor/create-comment';
import { EmojiPicker } from 'features/editor/emoji-picker';
import { ButtonType } from 'shared/analytics';
import { AnalyticsIconButton } from 'shared/analytics/ui/icon-button';
import {
	ArrowDownIcon,
	ArrowRedoIcon,
	ArrowUndoIcon,
	BoldTextIcon,
	HelpIcon,
	ItalicTextIcon,
	StarsIcon,
} from 'shared/icons';
import { MotionBox } from 'shared/ui';
import { useGetParamsInfo } from 'widgets/ask-ai-modal/hooks/getParamsInfo';
import { PromptsDropdown } from 'widgets/ask-ai-modal/ui/prompts-dropdown';
import { AmplitudeInfo, DOC_TYPES_MAP } from 'widgets/ask-ai-modal/ui/types';
import { aiIconClick } from 'widgets/editor/ui/toolbar/utils/ai-click';

import { insertCharacter } from '../../lib/insert-characters';
import { insertDecorationTag } from '../../lib/insert-decotration-tag';
import { toggleStyle } from '../../lib/toggle-style';
import { CustomizePicker } from './customize-picker';
import styles from './styles.module.scss';

interface ToolbarProps {
	counterRef: React.RefObject<HTMLDivElement>;
	editorState: EditorState;
	setEditorState: Dispatch<SetStateAction<EditorState>>;
	setSelectedText: (v: string) => void;
	withComments: boolean;
	isErrorBarOpened: boolean;
	toggleErrorBar: () => void;
	handleOpenGuideline: () => void;
	handleAiModalOpen: (v: string) => void;
	handleOpenCommentsList: () => void;
	isOpenAiModal?: boolean;
}

export const Toolbar: React.FC<ToolbarProps> = ({
	counterRef,
	editorState,
	setEditorState,
	withComments,
	isErrorBarOpened,
	toggleErrorBar,
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	handleOpenGuideline,
	handleOpenCommentsList,
	handleAiModalOpen,
	setSelectedText,
	isOpenAiModal,
}) => {
	const { chapterId, bookId, googleDocId } = useParams<{
		chapterId: string;
		bookId: string;
		googleDocId: string;
	}>();

	const { bookCustomId, chapterOrder, doc } = useGetParamsInfo(
		chapterId || '',
		bookId || '',
		googleDocId || '',
	);

	const amplitudeInfo = useMemo<AmplitudeInfo>(
		() => ({
			bookId: bookCustomId,
			chapterNumber: chapterOrder,
			doc: DOC_TYPES_MAP[doc] || 'additional',
		}),
		[bookCustomId, chapterOrder, doc],
	);

	const errors = useStore(scriptModel.$validationErrors);

	const handleBackgroundColorChange = useCallback(
		(backgroundColor?: string) => {
			scriptModel.setIsScriptEdited(true);
			setEditorState(toggleStyle(editorState, backgroundColor));
		},
		[editorState, setEditorState],
	);

	const handleAiIconClick = () => {
		aiIconClick({ editorState, setSelectedText, amplitudeInfo });
	};

	const handleUndoBtnClick = useCallback(() => {
		scriptModel.setIsDisabledControlButtons(true);
		scriptModel.setIsScriptEdited(true);
		setEditorState(EditorState.undo(editorState));
	}, [editorState, setEditorState]);

	const handleRedoBtnClick = useCallback(() => {
		scriptModel.setIsDisabledControlButtons(true);
		scriptModel.setIsScriptEdited(true);
		setEditorState(EditorState.redo(editorState));
	}, [editorState, setEditorState]);

	const handleItalicButtonClick = useCallback(() => {
		scriptModel.setIsDisabledControlButtons(true);
		scriptModel.setIsScriptEdited(true);
		setEditorState(insertDecorationTag(editorState, 'i'));
	}, [editorState, setEditorState]);

	const handleBoldButtonClick = useCallback(() => {
		scriptModel.setIsDisabledControlButtons(true);
		scriptModel.setIsScriptEdited(true);
		setEditorState(insertDecorationTag(editorState, 'b'));
	}, [editorState, setEditorState]);

	const handleEmojiAdd = useCallback(
		(data: any) => {
			scriptModel.setIsDisabledControlButtons(true);
			scriptModel.setIsScriptEdited(true);
			setEditorState(insertCharacter(editorState, data?.native || ''));
		},
		[editorState, setEditorState],
	);

	const handleClick = () => {
		window.open(
			'https://coda.io/d/_d3cwMKQBqfE/Snippets_suN8F',
			'_blank',
			'noreferrer',
		);
	};
	return (
		<Flex w="100%" h="50px" borderBottom="1px solid #eeeef2">
			<Box className={styles.actionSection}>
				<Tooltip label="Redo">
					<AnalyticsIconButton
						name={ButtonType.EDITOR_REDO}
						variant="ghost"
						size="square"
						aria-label="Redo"
						isDisabled={!editorState.getRedoStack().size}
						onClick={handleRedoBtnClick}
					>
						<ArrowRedoIcon w={5} h={5} />
					</AnalyticsIconButton>
				</Tooltip>
				<Tooltip label="Undo">
					<AnalyticsIconButton
						name={ButtonType.EDITOR_UNDO}
						variant="ghost"
						size="square"
						aria-label="Undo"
						isDisabled={!editorState.getUndoStack().size}
						onClick={handleUndoBtnClick}
					>
						<ArrowUndoIcon w={5} h={5} />
					</AnalyticsIconButton>
				</Tooltip>
			</Box>
			<Box className={styles.actionSection}>
				<Tooltip label="Make Bold">
					<AnalyticsIconButton
						name={ButtonType.EDITOR_MARK_BOLD}
						variant="ghost"
						size="square"
						aria-label="mark as bold"
						onMouseDown={(e) => e.preventDefault()}
						onClick={handleBoldButtonClick}
					>
						<BoldTextIcon w={5} h={5} />
					</AnalyticsIconButton>
				</Tooltip>
				<Tooltip label="Make Italic">
					<AnalyticsIconButton
						name={ButtonType.EDITOR_MARK_ITALIC}
						variant="ghost"
						size="square"
						aria-label="mark as italic"
						onMouseDown={(e) => e.preventDefault()}
						onClick={handleItalicButtonClick}
					>
						<ItalicTextIcon w={5} h={5} />
					</AnalyticsIconButton>
				</Tooltip>
			</Box>
			<Box className={styles.actionSection}>
				<BackgroundColorPicker onSelect={handleBackgroundColorChange} />
				<EmojiPicker onEmojiSelect={handleEmojiAdd} />
				{withComments && (
					<CreateComment
						editorState={editorState}
						setEditorState={setEditorState}
						handleOpenCommentsList={handleOpenCommentsList}
					/>
				)}
			</Box>
			<Box className={styles.actionSection}>
				<Menu>
					<Tooltip label="Ask AI">
						<MenuButton
							as={Button}
							variant="ghost"
							size="sm"
							disabled={isOpenAiModal}
							onClick={handleAiIconClick}
						>
							<StarsIcon w={5} h={5} />
						</MenuButton>
					</Tooltip>
					<PromptsDropdown isHeader handleAiModalOpen={handleAiModalOpen} />
				</Menu>
			</Box>
			<Box
				width="300px"
				flex="1"
				ref={counterRef}
				display="flex"
				alignItems="center"
				justifyContent="center"
			/>
			<Flex px="15px" align="center" justify="center">
				<CustomizePicker />
				<Tooltip label="Snippet guideline">
					{/* <AnalyticsIconButton
						name={ButtonType.EDITOR_OPEN_DOCS}
						aria-label="snippet-guideline"
						variant="ghost"
						size="square"
						onClick={handleOpenGuideline}
					>
						<HelpIcon w={5} h={5} />
					</AnalyticsIconButton> */}
					<AnalyticsIconButton
						name={ButtonType.APP_OPEN_DOCS}
						aria-label="snippet-guideline"
						variant="ghost"
						size="square"
						onClick={handleClick}
					>
						<HelpIcon w={5} h={5} />
					</AnalyticsIconButton>
					{/* <SessionManagement /> */}
				</Tooltip>
			</Flex>
			<Box
				w="64"
				h="full"
				display="flex"
				alignItems="center"
				px="5"
				borderLeftWidth="1px"
				borderColor="#EEEEF2"
				justifyContent="space-between"
			>
				<Box display="flex" alignItems="center" fontSize="14px">
					<Text color="#808192" mr="2.5">
						Errors:
					</Text>
					<Text
						color={errors.length ? '#ED0000' : '#242533'}
						fontWeight="semibold"
					>
						{errors.length}
					</Text>
				</Box>
				<MotionBox
					animate={isErrorBarOpened ? 'open' : 'closed'}
					transition={{ ease: 'easeOut', duration: 0.3 }}
					variants={{
						open: { rotate: [0, 180] },
						closed: { rotate: [180, 0] },
					}}
				>
					<AnalyticsIconButton
						name={ButtonType.EDITOR_TOGGLE_SHOW_ERRORS}
						variant="ghost"
						size="square"
						onMouseDown={(e) => e.preventDefault()}
						onClick={toggleErrorBar}
						aria-label="expand errors"
					>
						<ArrowDownIcon w={5} h={5} />
					</AnalyticsIconButton>
				</MotionBox>
			</Box>
		</Flex>
	);
};
