import React from 'react';
import { createIcon } from '@chakra-ui/react';

export const CheckmarkIcon = createIcon({
	viewBox: '0 0 10 7',
	path: (
		<path
			d="M1 4.0357 3.2564 6 9 1"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
	),
});
