import React from 'react';
import { Portal } from '@chakra-ui/react';
import { Formik } from 'formik';

import { AssetType } from 'entities/assets/model';
import { EmotionsReview } from 'entities/assets/ui/emotions-review';
import { ImageReview } from 'entities/assets/ui/image-review';
import { GetAssetsDocument } from 'pages/art-catalog/ui/ArtCatalogTable/queries.gen';
import { trackAssetCreated } from 'shared/analytics';
import { useGetAssetError } from 'shared/hooks/asset-error';
import { useAppToast } from 'shared/hooks/toast';
import { InputField, InputFieldWithLink } from 'shared/ui';
import { getFilenameFromURL } from 'shared/utils/get-filename-from-url';
import { FilterKeywords } from 'widgets/keywords-filter-modal/constant';

import { KeywordsPicker } from '../../../keywords-picker';
import { createBodySchema, CreateBodyValues } from '../../lib/schemas/body';
import { useCreateBodyMutation } from '../../queries.gen';
import { ActionButtons } from '../action-buttons';
import { CreateAnyAssetFormProps } from '../types';

export const CreateBodyForm: React.FC<CreateAnyAssetFormProps> = ({
	onSubmit,
	onCancel,
	buttonsContainerRef,
}) => {
	const toast = useAppToast();
	const handleError = useGetAssetError();

	const [createBody] = useCreateBodyMutation({
		onCompleted: (data) => {
			const keywords = Object.values(
				data.createBody?.newKeywords as FilterKeywords,
			)
				.filter((v) => v)
				.flat();

			if (!keywords.length) {
				toast({
					title: `Body was created without keywords`,
					status: 'warning',
				});
			} else {
				toast({ title: 'Body created successfully', status: 'success' });
			}
			onSubmit();
			trackAssetCreated({
				type: AssetType.BODY,
				filename: data.createBody?.filename as string,
				link: data.createBody?.link as string,
			});
		},
		onError: (mutationError) => handleError(mutationError.message, 'Body'),
		refetchQueries: [GetAssetsDocument],
	});

	return (
		<Formik<CreateBodyValues>
			initialValues={{
				link: '',
				filename: '',
				emotions: [],
				keywords: [],
				dropboxLink: '',
				newKeywords: {},
			}}
			validationSchema={createBodySchema}
			onSubmit={(data) => createBody({ variables: { body: data } })}
		>
			{({ values, submitForm, setFieldValue, errors, isSubmitting }) => (
				<>
					<InputField
						label="Filename"
						name="filename"
						value={
							getFilenameFromURL(values.link?.trim()) ||
							values.filename?.trim() ||
							''
						}
						onApply={(v: string | number) => setFieldValue('filename', v, true)}
						error={errors.filename}
						placeholder="face_f_0_body_f_regular_white_0.png"
						isDisabledFilename={!!values.link}
					/>
					<ImageReview
						label="Body"
						type={AssetType.BODY}
						imageSrc={values.link?.trim()}
						onImageChange={(link) => setFieldValue('link', link?.trim(), true)}
						error={errors.link}
						// isUploadError={isUploadError}
					/>
					<EmotionsReview
						label="Emotions"
						emotions={values.emotions}
						onChange={(emotions) => setFieldValue('emotions', emotions, true)}
						error={errors.emotions}
						multiple
					/>
					<KeywordsPicker
						type={AssetType.BODY}
						selectedKeywords={values.newKeywords}
						onApply={(v) => setFieldValue('newKeywords', v, true)}
					/>
					<InputFieldWithLink
						label="Link"
						name="dropboxLink"
						value={values.dropboxLink?.trim() || ''}
						onApply={(v: string | number) =>
							setFieldValue('dropboxLink', v, true)
						}
						error={errors.dropboxLink}
						placeholder="https://..."
					/>
					<Portal containerRef={buttonsContainerRef}>
						<ActionButtons
							onApply={submitForm}
							onCancel={onCancel}
							disabled={isSubmitting}
						/>
					</Portal>
				</>
			)}
		</Formik>
	);
};
