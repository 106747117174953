import * as Yup from 'yup';

export const docNotesSchema = Yup.object().shape({
	name: Yup.string().min(5).max(50).required(),
	link: Yup.string().matches(
		/https:\/\/app.asana.com\/[a-zA-Z0-9_-]/,
		'Must be valid Asana URL',
	),
	description: Yup.string(),
	isActive: Yup.boolean(),
});
