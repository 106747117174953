import React from 'react';
import { createIcon } from '@chakra-ui/react';

export const DownloadIcon = createIcon({
	viewBox: '0 0 24 24',
	path: (
		<g fill="none">
			<path
				d="M18.4655 12.4033V15.1408C18.4655 17.2184 17.3656 18.0495 15.5569 18.0495H8.90862C7.15157 18.0495 6 17.2184 6 15.1408V12.4033"
				stroke="currentColor"
				strokeWidth="1.4"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M8.90869 11.8175L12.2328 15.1416L15.557 11.8175"
				stroke="currentColor"
				strokeWidth="1.4"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M12.2328 14.7256V5.99974"
				stroke="currentColor"
				strokeWidth="1.4"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</g>
	),
});
