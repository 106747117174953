import * as Types from '../../../models.gen';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ItemsByStoryQueryVariables = Types.Exact<{
  page?: Types.InputMaybe<Types.Scalars['Int']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
  filter?: Types.InputMaybe<Types.ItemsFilterArgDto>;
}>;


export type ItemsByStoryQuery = { items: { items: Array<{ id: string, type?: string | null, filename?: string | null, link?: string | null, updatedAt: any, name?: string | null, chapters: Array<{ id: string, name?: string | null, chapterOrder?: number | null, book?: { id: string, name?: string | null } | null }> }> } };

export type DeleteItemFromStoryMutationVariables = Types.Exact<{
  chapter: Types.AddItemsToChapterDto;
}>;


export type DeleteItemFromStoryMutation = { removeItemsFromChapter: boolean };

export type UpdateItemChaptersMutationVariables = Types.Exact<{
  chapter: Types.AddItemsToChapterDto;
}>;


export type UpdateItemChaptersMutation = { addItemsToChapter: boolean };


export const ItemsByStoryDocument = gql`
    query ItemsByStory($page: Int, $limit: Int, $filter: ItemsFilterArgDto) {
  items(page: $page, limit: $limit, filter: $filter) {
    items {
      id
      type
      filename
      link
      updatedAt
      name
      chapters {
        id
        name
        chapterOrder
        book {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __useItemsByStoryQuery__
 *
 * To run a query within a React component, call `useItemsByStoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useItemsByStoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useItemsByStoryQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useItemsByStoryQuery(baseOptions?: Apollo.QueryHookOptions<ItemsByStoryQuery, ItemsByStoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ItemsByStoryQuery, ItemsByStoryQueryVariables>(ItemsByStoryDocument, options);
      }
export function useItemsByStoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ItemsByStoryQuery, ItemsByStoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ItemsByStoryQuery, ItemsByStoryQueryVariables>(ItemsByStoryDocument, options);
        }
export type ItemsByStoryQueryHookResult = ReturnType<typeof useItemsByStoryQuery>;
export type ItemsByStoryLazyQueryHookResult = ReturnType<typeof useItemsByStoryLazyQuery>;
export type ItemsByStoryQueryResult = Apollo.QueryResult<ItemsByStoryQuery, ItemsByStoryQueryVariables>;
export const DeleteItemFromStoryDocument = gql`
    mutation DeleteItemFromStory($chapter: AddItemsToChapterDto!) {
  removeItemsFromChapter(chapter: $chapter)
}
    `;
export type DeleteItemFromStoryMutationFn = Apollo.MutationFunction<DeleteItemFromStoryMutation, DeleteItemFromStoryMutationVariables>;

/**
 * __useDeleteItemFromStoryMutation__
 *
 * To run a mutation, you first call `useDeleteItemFromStoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteItemFromStoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteItemFromStoryMutation, { data, loading, error }] = useDeleteItemFromStoryMutation({
 *   variables: {
 *      chapter: // value for 'chapter'
 *   },
 * });
 */
export function useDeleteItemFromStoryMutation(baseOptions?: Apollo.MutationHookOptions<DeleteItemFromStoryMutation, DeleteItemFromStoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteItemFromStoryMutation, DeleteItemFromStoryMutationVariables>(DeleteItemFromStoryDocument, options);
      }
export type DeleteItemFromStoryMutationHookResult = ReturnType<typeof useDeleteItemFromStoryMutation>;
export type DeleteItemFromStoryMutationResult = Apollo.MutationResult<DeleteItemFromStoryMutation>;
export type DeleteItemFromStoryMutationOptions = Apollo.BaseMutationOptions<DeleteItemFromStoryMutation, DeleteItemFromStoryMutationVariables>;
export const UpdateItemChaptersDocument = gql`
    mutation UpdateItemChapters($chapter: AddItemsToChapterDto!) {
  addItemsToChapter(chapter: $chapter)
}
    `;
export type UpdateItemChaptersMutationFn = Apollo.MutationFunction<UpdateItemChaptersMutation, UpdateItemChaptersMutationVariables>;

/**
 * __useUpdateItemChaptersMutation__
 *
 * To run a mutation, you first call `useUpdateItemChaptersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateItemChaptersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateItemChaptersMutation, { data, loading, error }] = useUpdateItemChaptersMutation({
 *   variables: {
 *      chapter: // value for 'chapter'
 *   },
 * });
 */
export function useUpdateItemChaptersMutation(baseOptions?: Apollo.MutationHookOptions<UpdateItemChaptersMutation, UpdateItemChaptersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateItemChaptersMutation, UpdateItemChaptersMutationVariables>(UpdateItemChaptersDocument, options);
      }
export type UpdateItemChaptersMutationHookResult = ReturnType<typeof useUpdateItemChaptersMutation>;
export type UpdateItemChaptersMutationResult = Apollo.MutationResult<UpdateItemChaptersMutation>;
export type UpdateItemChaptersMutationOptions = Apollo.BaseMutationOptions<UpdateItemChaptersMutation, UpdateItemChaptersMutationVariables>;