import type { ComponentStyleConfig } from '@chakra-ui/theme';

export const tableStyles: ComponentStyleConfig = {
	baseStyle: {
		th: {
			bg: '#FBFBFC',
			fontStyle: 'normal',
			fontWeight: 600,
			fontSize: '14px',
			lineHeight: '140%',
			color: '#808192',
			textTransform: 'none',
			_notLast: { borderRight: '1px solid #EEEEF2' },
		},
		td: {
			backgroundColor: '#FFFFFF',
			fontStyle: 'normal',
			fontWeight: 'normal',
			fontSize: '14px',
			lineHeight: '16px',
			_notLast: { borderRight: '1px solid #EEEEF2' },
		},
		tr: {
			bg: '#FFFFFF',

			_hover: {
				td: {
					backgroundColor: '#F7F6FE',
				},
			},
		},
		table: {
			borderCollapse: 'separate',
			borderSpacing: 0,
		},
	},
	sizes: {
		default: {
			th: {
				px: '22px',
				py: '20px',
			},
			td: {
				px: '20px',
				py: '17px',
				minH: '50px',
			},
		},
		sm: {
			th: {
				px: '22px',
				py: '12px',
			},
			td: {
				px: '20px',
				py: '12px',
				minH: '40px',
			},
		},
		md: {
			th: {
				px: '22px',
				py: '17px',
				fontSize: '14px !important',
			},
			td: {
				px: '20px',
				py: '9px',
				minH: '50px',
			},
		},
	},
	defaultProps: {
		size: 'default',
	},
};
