import React from 'react';
import { Box } from '@chakra-ui/react';
import { useStore } from 'effector-react';

import { uiModel } from 'entities/ui';

import NavigationBody from './body';
import NavigationHeader from './header';

export const NavigationSidebar: React.FC = () => {
	const isNavigationOpened = useStore(uiModel.$isNavigationOpened);
	const toggleBtnClickHandler = uiModel.toggleNavigation;

	return (
		<Box
			as="nav"
			w={isNavigationOpened ? '220px' : '60px'}
			h="full"
			bg="#fbfbfc"
			overflow="hidden"
			transition="width 0.3s ease-in-out"
			zIndex={1201}
		>
			<NavigationHeader isNavigationOpened={isNavigationOpened} />
			<NavigationBody
				toggleBtnClickHandler={toggleBtnClickHandler}
				isNavigationOpened={isNavigationOpened}
			/>
		</Box>
	);
};
