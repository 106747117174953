import * as Types from '../../../models.gen';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateBookMutationVariables = Types.Exact<{
  book: Types.UpdateBookRequestDto;
}>;


export type UpdateBookMutation = { updateBook: { id: string } };

export type GenerateStoryProcessDocMutationVariables = Types.Exact<{
  bookId: Types.Scalars['String'];
  type: Types.Scalars['String'];
}>;


export type GenerateStoryProcessDocMutation = { createStoryDocByConfig: { id: string } };

export type GetAllUsersQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetAllUsersQuery = { allUsers: Array<{ id: string, picture?: string | null, fullName?: string | null }> };


export const UpdateBookDocument = gql`
    mutation UpdateBook($book: UpdateBookRequestDto!) {
  updateBook(book: $book) {
    id
  }
}
    `;
export type UpdateBookMutationFn = Apollo.MutationFunction<UpdateBookMutation, UpdateBookMutationVariables>;

/**
 * __useUpdateBookMutation__
 *
 * To run a mutation, you first call `useUpdateBookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBookMutation, { data, loading, error }] = useUpdateBookMutation({
 *   variables: {
 *      book: // value for 'book'
 *   },
 * });
 */
export function useUpdateBookMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBookMutation, UpdateBookMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBookMutation, UpdateBookMutationVariables>(UpdateBookDocument, options);
      }
export type UpdateBookMutationHookResult = ReturnType<typeof useUpdateBookMutation>;
export type UpdateBookMutationResult = Apollo.MutationResult<UpdateBookMutation>;
export type UpdateBookMutationOptions = Apollo.BaseMutationOptions<UpdateBookMutation, UpdateBookMutationVariables>;
export const GenerateStoryProcessDocDocument = gql`
    mutation GenerateStoryProcessDoc($bookId: String!, $type: String!) {
  createStoryDocByConfig(bookId: $bookId, type: $type) {
    id
  }
}
    `;
export type GenerateStoryProcessDocMutationFn = Apollo.MutationFunction<GenerateStoryProcessDocMutation, GenerateStoryProcessDocMutationVariables>;

/**
 * __useGenerateStoryProcessDocMutation__
 *
 * To run a mutation, you first call `useGenerateStoryProcessDocMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateStoryProcessDocMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateStoryProcessDocMutation, { data, loading, error }] = useGenerateStoryProcessDocMutation({
 *   variables: {
 *      bookId: // value for 'bookId'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useGenerateStoryProcessDocMutation(baseOptions?: Apollo.MutationHookOptions<GenerateStoryProcessDocMutation, GenerateStoryProcessDocMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateStoryProcessDocMutation, GenerateStoryProcessDocMutationVariables>(GenerateStoryProcessDocDocument, options);
      }
export type GenerateStoryProcessDocMutationHookResult = ReturnType<typeof useGenerateStoryProcessDocMutation>;
export type GenerateStoryProcessDocMutationResult = Apollo.MutationResult<GenerateStoryProcessDocMutation>;
export type GenerateStoryProcessDocMutationOptions = Apollo.BaseMutationOptions<GenerateStoryProcessDocMutation, GenerateStoryProcessDocMutationVariables>;
export const GetAllUsersDocument = gql`
    query GetAllUsers {
  allUsers {
    id
    picture
    fullName
  }
}
    `;

/**
 * __useGetAllUsersQuery__
 *
 * To run a query within a React component, call `useGetAllUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetAllUsersQuery, GetAllUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllUsersQuery, GetAllUsersQueryVariables>(GetAllUsersDocument, options);
      }
export function useGetAllUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllUsersQuery, GetAllUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllUsersQuery, GetAllUsersQueryVariables>(GetAllUsersDocument, options);
        }
export type GetAllUsersQueryHookResult = ReturnType<typeof useGetAllUsersQuery>;
export type GetAllUsersLazyQueryHookResult = ReturnType<typeof useGetAllUsersLazyQuery>;
export type GetAllUsersQueryResult = Apollo.QueryResult<GetAllUsersQuery, GetAllUsersQueryVariables>;