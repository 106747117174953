import type { ComponentStyleConfig } from '@chakra-ui/theme';

const ACTIVE_BG = 'rgba(93,66,255,0.08)';

export const menuStyles: ComponentStyleConfig = {
	baseStyle: {
		button: {
			transitionProperty: 'common',
			transitionDuration: 'normal',
		},
		list: {
			bg: '#fdfdfd',
			boxShadow:
				'0px 4px 4px rgba(0, 0, 0, 0.08), 0px 0px 1px rgba(67, 90, 111, 0.47)',
			color: 'inherit',
			minW: '238px',
			w: '100%',
			padding: '7px 6px 6px',
			zIndex: 1,
			borderRadius: 'lg',
			borderWidth: '1px',
			maxHeight: 235,
			overflowY: 'auto',
		},
		groupTitle: {
			color: '#242533',
			mx: 4,
			my: 2,
			fontSize: '15px',
		},
		command: {
			opacity: 0.6,
		},
		divider: {
			border: 0,
			borderBottom: '1px solid',
			borderColor: 'inherit',
			my: '0.5rem',
			opacity: 0.6,
		},
	},
	variants: {
		single: {
			item: {
				fontStyle: 'normal',
				fontWeight: 500,
				fontSize: '13px',
				lineHeight: '16px',
				marginBottom: '3px',
				borderRadius: '6px',
				transitionProperty: 'background',
				transitionDuration: 'ultra-fast',
				transitionTimingFunction: 'ease-in',
				// marginLeft: '8px',
				padding: '8px',
				_focus: {
					color: '#644ded',
					bg: ACTIVE_BG,
				},
				_active: {
					color: '#644ded',
					bg: ACTIVE_BG,
				},
				_expanded: {
					bg: '#000',
				},
				_disabled: {
					opacity: 0.4,
					cursor: 'not-allowed',
				},
			},
			button: {
				width: '100%',
				height: '48px',
			},
			list: {
				width: '186px',
				marginLeft: 'auto',
				overflowX: 'hidden',
			},
		},
		multi: {
			item: {
				fontStyle: 'normal',
				fontWeight: 500,
				fontSize: '13px',
				lineHeight: '16px',
				w: '93%',
				borderRadius: '6px',
				transitionProperty: 'background',
				transitionDuration: 'ultra-fast',
				transitionTimingFunction: 'ease-in',
				marginLeft: '8px',
				padding: '8px',
				_active: {
					color: '#644ded',
					bg: ACTIVE_BG,
				},
				_hover: {
					color: '#644ded',
					bg: 'transparent',
				},
				_expanded: {
					bg: '#000',
				},
				_disabled: {
					opacity: 0.4,
					cursor: 'not-allowed',
				},
			},
			button: {
				width: '100%',
				height: '48px',
			},
			list: {
				width: '270px',
				marginLeft: 'auto',
				maxHeight: '260px',
				padding: '9px',
			},
		},
		sort: {
			groupTitle: {
				color: '#242533',
				m: '0 0 12px 8px',
				fontSize: '15px',
				lineHeight: '20px',
			},
			item: {
				p: '8px',
				fontSize: '13px',
				lineHeight: '16px',
				fontWeight: 500,
				color: '#242533',
				borderRadius: '6px',
				transitionProperty: 'background',
				// transitionDuration: 'ultra-fast',
				transitionTimingFunction: 'ease-in',
				mb: '5px',

				_active: {
					color: '#644ded',
					bg: ACTIVE_BG,
				},
				_hover: {
					color: '#644ded',
					bg: ACTIVE_BG,
				},
			},
			divider: {
				border: 0,
				borderBottom: '1px solid',
				borderColor: 'inherit',
				my: '0.5rem',
				opacity: 0.6,
				h: '4px',
				mb: '15px',
			},
			list: {
				minH: '266px',
				minW: '270px',
				p: '16px 8px 10px',
			},
		},
	},
	defaultProps: {
		variant: 'single',
	},
};
