import React from 'react';
import cn from 'classnames';

import { ExpandIcon } from 'shared/icons';

import styles from './styles.module.scss';

interface Props {
	onClick?: () => void;
	isNavigationOpened: boolean;
}

const NavigationToggleBtn: React.FC<Props> = ({
	onClick,
	isNavigationOpened,
}) => {
	return (
		<div className={styles.wrapper} onClick={onClick}>
			<ExpandIcon
				w={6}
				h={6}
				className={cn({
					[styles.openedSidebar]: isNavigationOpened,
					[styles.closedSidebar]: !isNavigationOpened,
				})}
			/>
			<p
				className={cn(styles.text, {
					[styles.open]: isNavigationOpened,
				})}
			>
				Hide Menu
			</p>
		</div>
	);
};

export default NavigationToggleBtn;
