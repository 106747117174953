import * as yup from 'yup';

import { spaceValidation } from 'features/asset/update/lib/yup-validation';

export const createLayerSchema = yup.object({
	link: spaceValidation.url().required('Layer is a required field').nullable(),
	price: yup.number().integer(),
	position: yup.number().integer().required('Position is a required field'),
	dropboxLink: spaceValidation,
	description: yup.string().trim().min(5).max(255),
	keywords: yup.array().of(yup.string().trim().required()).required(),
	newKeywords: yup
		.object()
		.shape({
			sex: yup.array().nullable(),
			app: yup.array().nullable(),
			art_style: yup.array().nullable(),
			body_type: yup.array().nullable(),
			owner: yup.array().nullable(),
			defects: yup.array().nullable(),
			accessories: yup.array().nullable(),
			items: yup.array().nullable(),
			for_body: yup.array().nullable(),
		})
		.nullable(),
});

export type CreateLayerValues = yup.InferType<typeof createLayerSchema>;
export interface CreateLayerInitialValues
	extends Omit<CreateLayerValues, 'position'> {
	position?: number;
}
