import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import {
	Box,
	Flex,
	IconButton,
	Image,
	Link as ChakraLink,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Spinner,
	Text,
} from '@chakra-ui/react';

import { useUnlinkAssetMutation } from 'features/asset/update/forms/graphql/queries.gen';
import { GetAssetsDocument } from 'pages/art-catalog/ui/ArtCatalogTable/queries.gen';
import { CheckmarkIcon, MoreIcon } from 'shared/icons';
import { getFilenameFromURL } from 'shared/utils/get-filename-from-url';
import { FindOneClothesDocument } from 'widgets/update-asset-modal/ui/modal/queries.gen';

import { AssetInfo } from '../../../../../widgets/hero-update-modal';
import { DragAndDropWrapper } from '../drag-and-drop-wrapper';
import { useAssetCardClothesByIdQuery } from './queries.gen';

interface AssetCardClothesProps {
	defaultId?: string;
	assets: string[];
	assetId: string;
	setCurrentAssetInfo?: (asset: (value: AssetInfo) => AssetInfo) => void;
	handleSetFieldValue: (
		field: string,
		value: any,
		shouldValidate?: any,
	) => void;
	isAltAsset?: boolean;
	isActive?: boolean;
	parentId?: string;
	handlePhotoDownload: (imageLink: string, imageName: any) => void;
}

export const AssetCardClothes: React.FC<AssetCardClothesProps> = ({
	defaultId,
	assets,
	assetId,
	handleSetFieldValue,
	isAltAsset = false,
	isActive,
	setCurrentAssetInfo,
	parentId,
	handlePhotoDownload,
}) => {
	const { data, loading } = useAssetCardClothesByIdQuery({
		variables: {
			id: assetId,
		},
	});
	const [unlinkAsset] = useUnlinkAssetMutation({
		refetchQueries: [GetAssetsDocument, FindOneClothesDocument],
	});

	const handleDeleteAsset = useCallback(() => {
		const newAssets = assets.filter((id) => id !== assetId);

		handleSetFieldValue(
			isAltAsset ? 'altClothes' : 'clothes',
			assets.filter((id) => id !== assetId),
		);
		unlinkAsset({
			variables: {
				id: assetId,
				parentId,
			},
		});
		if (setCurrentAssetInfo) {
			setCurrentAssetInfo((prev) => ({
				...prev,
				clothesId: newAssets[0] || '',
			}));
		}
	}, [
		handleSetFieldValue,
		isAltAsset,
		assets,
		unlinkAsset,
		assetId,
		parentId,
		setCurrentAssetInfo,
	]);

	const imageName = getFilenameFromURL(data?.findOneClothes.link || '');
	const handleDownloadAsset = useCallback(() => {
		if (data) {
			handlePhotoDownload(data.findOneClothes.link || '', imageName);
		}
	}, [data, handlePhotoDownload, imageName]);

	// const handleSetDefault = useCallback(() => {
	// 	handleSetFieldValue('defaultClothesId', assetId);
	// }, [assetId, handleSetFieldValue]);

	return (
		<DragAndDropWrapper assetId={assetId}>
			<Flex flexDirection="column" gap={2} w="240px" maxW="240px">
				<Box
					h="min-content"
					userSelect="none"
					border="4px solid transparent"
					borderRadius="10px"
					position="relative"
				>
					<Flex
						height="300px"
						alignItems="center"
						justifyContent="center"
						border="1px solid #EEEEF2"
						borderRadius="10px"
						overflow="hidden"
						borderColor={isActive ? '#644DED' : ''}
						_hover={{
							borderColor: '#644DED',
							cursor: 'pointer',
						}}
					>
						{loading ? (
							<Spinner size="md" />
						) : (
							<Box
								onClick={() =>
									setCurrentAssetInfo
										? setCurrentAssetInfo((prev: AssetInfo) => ({
												...prev,
												clothesId: assetId,
										  }))
										: null
								}
							>
								<Image
									alt={data?.findOneClothes.name || ''}
									src={data?.findOneClothes.link || ''}
									h="300px"
								/>
							</Box>
						)}
					</Flex>

					{defaultId === assetId ? (
						<Flex
							position="absolute"
							top={1}
							left={1}
							w={10}
							h={10}
							justify="center"
							alignItems="center"
						>
							<CheckmarkIcon w={4} h={4} color="#A0AEC0" />
						</Flex>
					) : null}

					{!loading && (
						<Box position="absolute" top={1} right={1}>
							<Menu placement="bottom-start">
								<MenuButton
									as={IconButton}
									aria-label="Options"
									icon={<MoreIcon w={5} h={5} />}
									variant="ghost"
								/>
								<MenuList rootProps={{ transform: 'scale(0)' }}>
									<ChakraLink
										as={Link}
										to={`/art-catalog/?id=${assetId}&name=${data?.findOneClothes.name}&link=${data?.findOneClothes.link}&type=clothes`}
										target="_blank"
										_hover={{ textDecoration: 'none' }}
										_focus={{ outline: 'none' }}
									>
										<MenuItem>Open in Art Catalog</MenuItem>
									</ChakraLink>
									<MenuItem onClick={handleDeleteAsset}>Unlink Asset</MenuItem>
									<MenuItem onClick={handleDownloadAsset}>
										Download Asset
									</MenuItem>
								</MenuList>
							</Menu>
						</Box>
					)}
				</Box>
				<Text p={1} fontSize="12px" fontWeight={600} lineHeight="16px">
					{data?.findOneClothes.name || ''}
				</Text>
			</Flex>
		</DragAndDropWrapper>
	);
};
