import type { ComponentStyleConfig } from '@chakra-ui/theme';

const HOVER_BG = 'rgba(100, 77, 237, 0.08)';

export const accordionStyles: ComponentStyleConfig = {
	baseStyle: {
		icon: {
			borderRadius: 9999,
			_hover: {
				bg: 'blackAlpha.100',
			},
		},
		button: {
			padding: '15px 22px',
			_hover: {
				bg: 'initial',
			},
			_focus: {
				boxShadow: 'none',
			},
		},
		panel: {
			padding: '0 22px',
			px: 0,
			py: 2,
		},
	},
	variants: {
		artDocSidebar: {
			container: {
				border: 'none',
				width: '248px',
			},
			icon: {
				padding: 0,
				width: '28px',
				height: '28px',
				borderRadius: 'none',
				color: '#484964',

				_hover: {
					background: 'none',
				},
			},
			button: {
				color: '#484964',
				padding: '8px 8px 8px 14px',
				fontFamily: 'Poppins',
				fontSize: '15px',
				fontStyle: 'normal',
				fontWeight: 600,
				lineHeight: '16px' /* 106.667% */,
				border: 'none',
				height: '44px',
				borderRadius: '8px',
				_hover: {
					color: '#644ded',
					bg: HOVER_BG,
				},
			},
			panel: {
				padding: 0,
				marginLeft: '25px',
				marginTop: '5px',
				borderLeft: '1px solid #EEEEF2',
			},
			root: {
				height: '606px',
				overflowY: 'scroll',
				overflowX: 'hidden',
			},
		},
		artDocGeneral: {
			container: {
				border: 'none',
				width: '248px',
			},
			icon: {
				padding: 0,
				width: '28px',
				height: '28px',
				borderRadius: 'none',
				color: '#484964',

				transform: 'rotate(90deg)',
				_hover: {
					background: 'none',
				},
			},
			button: {
				color: '#484964',
				padding: '10px 8px 10px 14px',
				fontFamily: 'Poppins',
				fontSize: '15px',
				fontStyle: 'normal',
				fontWeight: 600,
				lineHeight: '16px' /* 106.667% */,
				border: 'none',
				borderRadius: '8px',
				_hover: {
					_hover: {
						color: '#644ded',
						bg: HOVER_BG,
					},
				},
			},
			panel: {
				padding: 0,
				marginLeft: '25px',
				marginTop: '5px',
				borderLeft: '1px solid #EEEEF2',
			},
			root: {
				padding: '10px 30px 20px',
				borderBottom: '1px solid #eeeef2',
			},
		},
		snippets: {
			container: {
				border: 'none',
				maxHeight: '211px',
				width: '173px',
			},
			icon: {
				padding: 0,
				width: '24px',
				height: '24px',
				borderRadius: 'none',
				color: '#484964',

				_hover: {
					background: 'none',
				},
			},
			button: {
				color: '#242533',
				padding: '5px 8px',
				fontFamily: 'Poppins',
				fontSize: '13px',
				fontStyle: 'normal',
				fontWeight: 500,
				lineHeight: '16px' /* 106.667% */,
				display: 'flex',
				justifyContent: 'space-between',
				alignItems: 'center',
			},
			panel: {
				padding: '5px 8px',
				color: '#242533',
				fontFamily: 'Poppins',
				fontSize: '13px',
				fontStyle: 'normal',
				fontWeight: 400,
				lineHeight: '16px',
			},
			root: {
				padding: '8px',
			},
		},
	},
};
