import * as yup from 'yup';

export const createAudioSchema = yup.object({
	link: yup
		.string()
		.trim()
		.url()
		.required('Audio is a required field')
		.nullable(),
	keywords: yup.array().of(yup.string().trim().required()).required(),
});

export type CreateAudioValues = yup.InferType<typeof createAudioSchema>;
