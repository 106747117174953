import React from 'react';
import { createIcon } from '@chakra-ui/react';

export const CloudBackupIcon = createIcon({
	viewBox: '0 0 16 12',
	path: (
		<>
			<path
				d="M 13.733 5.082 C 13.701 3.63 13.029 2.27 11.904 1.376 C 10.779 0.482 9.319 0.149 7.926 0.469 C 6.533 0.788 5.355 1.726 4.716 3.025 C 4.517 2.994 4.317 2.978 4.116 2.977 C 2.646 2.977 1.287 3.775 0.551 5.07 C -0.184 6.365 -0.184 7.961 0.551 9.257 C 1.287 10.552 2.646 11.35 4.116 11.35 L 12.857 11.35 C 13.875 11.35 14.829 10.849 15.419 10.005 C 16.009 9.161 16.16 8.079 15.825 7.101 C 15.49 6.123 14.711 5.371 13.733 5.082 L 13.733 5.082 Z M 12.857 10.221 L 4.116 10.221 C 3.249 10.223 2.424 9.844 1.852 9.182 C 1.28 8.519 1.017 7.638 1.131 6.764 C 1.245 5.889 1.724 5.108 2.446 4.62 C 3.168 4.132 4.062 3.985 4.899 4.216 C 5.173 4.29 5.458 4.141 5.559 3.872 C 5.967 2.781 6.855 1.949 7.958 1.625 C 9.06 1.3 10.248 1.522 11.165 2.223 C 12.083 2.924 12.623 4.023 12.623 5.19 C 12.623 5.291 12.623 5.393 12.607 5.494 C 12.582 5.797 12.798 6.066 13.095 6.101 C 13.783 6.191 14.378 6.631 14.675 7.268 C 14.971 7.906 14.928 8.653 14.56 9.251 C 14.192 9.849 13.55 10.214 12.857 10.221 L 12.857 10.221 Z"
				fill="currentColor"
			/>
			<path
				d="M 10.042 5.08 L 8.036 6.968 L 7.082 5.989 C 6.865 5.769 6.513 5.769 6.297 5.989 C 6.08 6.209 6.08 6.567 6.297 6.788 L 7.628 8.142 C 7.839 8.356 8.178 8.362 8.397 8.156 L 10.794 5.899 C 10.923 5.804 11.006 5.657 11.021 5.496 C 11.036 5.335 10.983 5.175 10.874 5.056 C 10.765 4.938 10.612 4.873 10.453 4.878 C 10.294 4.882 10.144 4.956 10.042 5.08 L 10.042 5.08 Z"
				fill="currentColor"
			/>
		</>
	),
});
