import * as yup from 'yup';

import { spaceValidation } from 'features/asset/update/lib/yup-validation';

export const createBackgroundSchema = yup.object({
	link: spaceValidation
		.url()
		.required('Background is a required field')
		.nullable(),
	filename: spaceValidation.min(5).max(255).nullable(),
	dropboxLink: spaceValidation,
	visionLabels: yup.string().trim().min(5).max(255),
	keywords: yup.array().of(yup.string().trim().required()).required(),
	newKeywords: yup
		.object()
		.shape({
			app: yup.array().nullable(),
			art_style: yup.array().nullable(),
			outside: yup.array().nullable(),
			inside: yup.array().nullable(),
			time: yup.array().nullable(),
			rooms: yup.array().nullable(),
			style: yup.array().nullable(),
			mood: yup.array().nullable(),
			extra: yup.array().nullable(),
		})
		.nullable(),
	filter: yup.string().trim(),
});

export type CreateBackgroundValues = yup.InferType<
	typeof createBackgroundSchema
>;
