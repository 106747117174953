import React from 'react';
import { Flex, Text } from '@chakra-ui/react';

import { SearchInput } from 'shared/ui';
import { CreateAssetButton } from 'widgets/create-asset-modal';

interface Props {
	search: string;
	setSearch: (v: string) => void;
}

const ArtCatalogHeader: React.FC<Props> = ({ search, setSearch }) => {
	return (
		<Flex
			h="44px"
			alignItems="center"
			justify="space-between"
			columnGap="64px"
			m="40px 45px"
		>
			<Text
				as="h3"
				minW="fit-content"
				fontWeight={800}
				fontSize="26px"
				lineHeight="39px"
				color="#242533"
			>
				Art Catalog
			</Text>
			<SearchInput search={search} setSearch={setSearch} />
			<CreateAssetButton />
		</Flex>
	);
};

export default ArtCatalogHeader;
