export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  JSON: any;
  Upload: any;
};

export type AddAssetToHeroDto = {
  assetDetails?: InputMaybe<Array<HairDetailsDocInput>>;
  assetType: Scalars['String'];
  bookID: Scalars['String'];
  chapterID: Scalars['String'];
  heroID: Scalars['String'];
};

export type AddBackgroundsToChapterDto = {
  backgrounds: Array<Scalars['String']>;
  chapterIds: Array<Scalars['String']>;
};

export type AddItemsToChapterDto = {
  chapterIds: Array<Scalars['String']>;
  items: Array<Scalars['String']>;
};

export type AdditionalDoc = {
  documentId: Scalars['String'];
  name: Scalars['String'];
};

export type ArtDoc = {
  backgrounds?: Maybe<Array<ArtDocBackgroundObject>>;
  bookID?: Maybe<Scalars['String']>;
  chapterID?: Maybe<Scalars['String']>;
  chapterOrder?: Maybe<Scalars['Float']>;
  cover?: Maybe<ArtDocCoverObject>;
  createdAt: Scalars['DateTime'];
  googleSheetGID?: Maybe<Scalars['String']>;
  googleSheetID?: Maybe<Scalars['String']>;
  heroes?: Maybe<Array<ArtDocHeroObject>>;
  id: Scalars['String'];
  isSyncGoogleSheet?: Maybe<Scalars['Boolean']>;
  items?: Maybe<Array<ArtDocItemObject>>;
  updatedAt: Scalars['DateTime'];
};

export type ArtDocBackgroundInput = {
  background_details?: InputMaybe<BackgroundDetailsDocInput>;
  copyAssetIds?: InputMaybe<Array<Scalars['String']>>;
  copyFromAssetId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  isCopied?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
};

export type ArtDocBackgroundObject = {
  background_details?: Maybe<BackgroundDetailsDocObject>;
  copyAssetIds?: Maybe<Array<Scalars['String']>>;
  copyFromAssetId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isCopied?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

export type ArtDocCoverInput = {
  description?: InputMaybe<Scalars['String']>;
};

export type ArtDocCoverObject = {
  description?: Maybe<Scalars['String']>;
};

export type ArtDocHeroInput = {
  copyAssetIds?: InputMaybe<Array<Scalars['String']>>;
  copyFromAssetId?: InputMaybe<Scalars['String']>;
  hair?: InputMaybe<Array<HairDetailsDocInput>>;
  hero_details?: InputMaybe<HeroDetailsDocInput>;
  id: Scalars['String'];
  isCopied?: InputMaybe<Scalars['Boolean']>;
  layer?: InputMaybe<Array<LayerDetailsDocInput>>;
  name?: InputMaybe<Scalars['String']>;
  outfit?: InputMaybe<Array<OutfitDetailsDocInput>>;
};

export type ArtDocHeroObject = {
  copyAssetIds?: Maybe<Array<Scalars['String']>>;
  copyFromAssetId?: Maybe<Scalars['String']>;
  hair?: Maybe<Array<HairDetailsDocObject>>;
  hero_details?: Maybe<HeroDetailsDocObject>;
  id: Scalars['String'];
  isCopied?: Maybe<Scalars['Boolean']>;
  layer?: Maybe<Array<LayerDetailsDocObject>>;
  name?: Maybe<Scalars['String']>;
  outfit?: Maybe<Array<OutfitDetailsDocObject>>;
};

export type ArtDocItemInput = {
  copyAssetIds?: InputMaybe<Array<Scalars['String']>>;
  copyFromAssetId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  isCopied?: InputMaybe<Scalars['Boolean']>;
  item_details?: InputMaybe<ItemDetailsDocInput>;
  name?: InputMaybe<Scalars['String']>;
};

export type ArtDocItemObject = {
  copyAssetIds?: Maybe<Array<Scalars['String']>>;
  copyFromAssetId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isCopied?: Maybe<Scalars['Boolean']>;
  item_details?: Maybe<ItemDetailsDocObject>;
  name?: Maybe<Scalars['String']>;
};

export type AskAiResponseDto = {
  result: Scalars['String'];
  totalTokens: Scalars['Float'];
};

export type Asset = {
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  keywords: Array<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type AssetResponseDto = {
  assets: Array<Asset>;
  count: Scalars['Int'];
};

export type AssetsFilterArgDto = {
  assetIds?: InputMaybe<Array<Scalars['String']>>;
  bookId?: InputMaybe<Scalars['String']>;
  filterKeywordsType?: InputMaybe<Scalars['String']>;
  keywords?: InputMaybe<KeywordsAssetInput>;
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type AssetsSortArgDto = {
  field?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['String']>;
};

export type Audio = {
  atName?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  keywords: Array<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  newKeywords?: Maybe<KeywordsAudioObject>;
  type: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type AudiosData = {
  android: Array<Scalars['String']>;
  ios: Array<Scalars['String']>;
};

export type AudiosFilterArgDto = {
  keywords?: InputMaybe<Array<Scalars['String']>>;
  name?: InputMaybe<Scalars['String']>;
};

export type Background = {
  alternativeBackgrounds: Array<Background>;
  atName?: Maybe<Scalars['String']>;
  cards: Array<Card>;
  chapters: Array<Chapter>;
  createdAt: Scalars['DateTime'];
  dropboxLink?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  filter?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  keywords: Array<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  newKeywords?: Maybe<KeywordsBackgroundObject>;
  type: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  visionLabels?: Maybe<Scalars['String']>;
};

export type BackgroundColor = {
  color: Color;
};

export type BackgroundDetailsDocInput = {
  copyAssetIds?: InputMaybe<Array<Scalars['String']>>;
  copyFromAssetId?: InputMaybe<Scalars['String']>;
  customID?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  isCopied?: InputMaybe<Scalars['Boolean']>;
  needTask?: InputMaybe<Scalars['Boolean']>;
  place?: InputMaybe<Scalars['String']>;
  script_name?: InputMaybe<Scalars['String']>;
  time_of_day?: InputMaybe<Array<Scalars['String']>>;
};

export type BackgroundDetailsDocObject = {
  copyAssetIds?: Maybe<Array<Scalars['String']>>;
  copyFromAssetId?: Maybe<Scalars['String']>;
  customID?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isCopied?: Maybe<Scalars['Boolean']>;
  needTask?: Maybe<Scalars['Boolean']>;
  place?: Maybe<Scalars['String']>;
  script_name?: Maybe<Scalars['String']>;
  time_of_day?: Maybe<Array<Scalars['String']>>;
};

export type BackgroundsFilterArgDto = {
  bookId?: InputMaybe<Scalars['String']>;
  chapterId?: InputMaybe<Scalars['String']>;
  keywords?: InputMaybe<Array<Scalars['String']>>;
  name?: InputMaybe<Scalars['String']>;
};

export type BackgroundsSortArgDto = {
  field?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['String']>;
};

export type BodiesData = {
  emotions: Array<Scalars['String']>;
  name: Scalars['String'];
};

export type BodiesFilterArgDto = {
  bookId?: InputMaybe<Scalars['String']>;
  keywords?: InputMaybe<Array<Scalars['String']>>;
  name?: InputMaybe<Scalars['String']>;
};

export type Body = {
  atName?: Maybe<Scalars['String']>;
  chatHeroes: Array<ChatHero>;
  createdAt: Scalars['DateTime'];
  dropboxLink?: Maybe<Scalars['String']>;
  emotions: Array<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  gender: Scalars['String'];
  heroes?: Maybe<Array<Hero>>;
  id: Scalars['String'];
  keywords: Array<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  newKeywords?: Maybe<KeywordsBodyObject>;
  type: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  uuidEmotionNameMap?: Maybe<Scalars['String']>;
};

export type Book = {
  app?: Maybe<Array<Scalars['String']>>;
  artDocGoogleSheetsId?: Maybe<Scalars['String']>;
  artDocument?: Maybe<Scalars['String']>;
  author?: Maybe<Scalars['String']>;
  banner: Scalars['Boolean'];
  bannerImage?: Maybe<Scalars['String']>;
  bookNotes?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  chapters?: Maybe<Array<Chapter>>;
  charactersBible?: Maybe<Scalars['String']>;
  chatHeroes?: Maybe<Array<ChatHero>>;
  chats?: Maybe<Array<Chat>>;
  comingSoon: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  customId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  directLink: Scalars['Boolean'];
  extra: Array<Scalars['String']>;
  fantasy: Scalars['Boolean'];
  fantasyCategories: Array<Scalars['String']>;
  finished: Scalars['Boolean'];
  folderId?: Maybe<Scalars['String']>;
  fresh: Scalars['Boolean'];
  freshPosition?: Maybe<Scalars['Int']>;
  genres: Array<Scalars['String']>;
  has_new_customization: Scalars['Boolean'];
  id: Scalars['String'];
  imageMenuBrazilianGP?: Maybe<Scalars['String']>;
  imageMenuBrazilianIOS?: Maybe<Scalars['String']>;
  imageMenuCzechGP?: Maybe<Scalars['String']>;
  imageMenuCzechIOS?: Maybe<Scalars['String']>;
  imageMenuDanishGP?: Maybe<Scalars['String']>;
  imageMenuDanishIOS?: Maybe<Scalars['String']>;
  imageMenuDutchGP?: Maybe<Scalars['String']>;
  imageMenuDutchIOS?: Maybe<Scalars['String']>;
  imageMenuFinnishGP?: Maybe<Scalars['String']>;
  imageMenuFinnishIOS?: Maybe<Scalars['String']>;
  imageMenuFrenchGP?: Maybe<Scalars['String']>;
  imageMenuFrenchIOS?: Maybe<Scalars['String']>;
  imageMenuGP?: Maybe<Scalars['String']>;
  imageMenuGermanGP?: Maybe<Scalars['String']>;
  imageMenuGermanIOS?: Maybe<Scalars['String']>;
  imageMenuHungarianGP?: Maybe<Scalars['String']>;
  imageMenuHungarianIOS?: Maybe<Scalars['String']>;
  imageMenuIOS?: Maybe<Scalars['String']>;
  imageMenuIndonesianGP?: Maybe<Scalars['String']>;
  imageMenuIndonesianIOS?: Maybe<Scalars['String']>;
  imageMenuItalianGP?: Maybe<Scalars['String']>;
  imageMenuItalianIOS?: Maybe<Scalars['String']>;
  imageMenuJapaneseGP?: Maybe<Scalars['String']>;
  imageMenuJapaneseIOS?: Maybe<Scalars['String']>;
  imageMenuKoreanGP?: Maybe<Scalars['String']>;
  imageMenuKoreanIOS?: Maybe<Scalars['String']>;
  imageMenuNorwegianGP?: Maybe<Scalars['String']>;
  imageMenuNorwegianIOS?: Maybe<Scalars['String']>;
  imageMenuPolishGP?: Maybe<Scalars['String']>;
  imageMenuPolishIOS?: Maybe<Scalars['String']>;
  imageMenuPortugueseGP?: Maybe<Scalars['String']>;
  imageMenuPortugueseIOS?: Maybe<Scalars['String']>;
  imageMenuRomanianGP?: Maybe<Scalars['String']>;
  imageMenuRomanianIOS?: Maybe<Scalars['String']>;
  imageMenuSpainGP?: Maybe<Scalars['String']>;
  imageMenuSpainIOS?: Maybe<Scalars['String']>;
  imageMenuSwedishGP?: Maybe<Scalars['String']>;
  imageMenuSwedishIOS?: Maybe<Scalars['String']>;
  imageMenuTurkishGP?: Maybe<Scalars['String']>;
  imageMenuTurkishIOS?: Maybe<Scalars['String']>;
  imagePreviewBrazilianGP?: Maybe<Scalars['String']>;
  imagePreviewBrazilianIOS?: Maybe<Scalars['String']>;
  imagePreviewCzechGP?: Maybe<Scalars['String']>;
  imagePreviewCzechIOS?: Maybe<Scalars['String']>;
  imagePreviewDanishGP?: Maybe<Scalars['String']>;
  imagePreviewDanishIOS?: Maybe<Scalars['String']>;
  imagePreviewDutchGP?: Maybe<Scalars['String']>;
  imagePreviewDutchIOS?: Maybe<Scalars['String']>;
  imagePreviewFinnishGP?: Maybe<Scalars['String']>;
  imagePreviewFinnishIOS?: Maybe<Scalars['String']>;
  imagePreviewFrenchGP?: Maybe<Scalars['String']>;
  imagePreviewFrenchIOS?: Maybe<Scalars['String']>;
  imagePreviewGP?: Maybe<Scalars['String']>;
  imagePreviewGermanGP?: Maybe<Scalars['String']>;
  imagePreviewGermanIOS?: Maybe<Scalars['String']>;
  imagePreviewHungarianGP?: Maybe<Scalars['String']>;
  imagePreviewHungarianIOS?: Maybe<Scalars['String']>;
  imagePreviewIOS?: Maybe<Scalars['String']>;
  imagePreviewIndonesianGP?: Maybe<Scalars['String']>;
  imagePreviewIndonesianIOS?: Maybe<Scalars['String']>;
  imagePreviewItalianGP?: Maybe<Scalars['String']>;
  imagePreviewItalianIOS?: Maybe<Scalars['String']>;
  imagePreviewJapaneseGP?: Maybe<Scalars['String']>;
  imagePreviewJapaneseIOS?: Maybe<Scalars['String']>;
  imagePreviewKoreanGP?: Maybe<Scalars['String']>;
  imagePreviewKoreanIOS?: Maybe<Scalars['String']>;
  imagePreviewNorwegianGP?: Maybe<Scalars['String']>;
  imagePreviewNorwegianIOS?: Maybe<Scalars['String']>;
  imagePreviewPolishGP?: Maybe<Scalars['String']>;
  imagePreviewPolishIOS?: Maybe<Scalars['String']>;
  imagePreviewPortugueseGP?: Maybe<Scalars['String']>;
  imagePreviewPortugueseIOS?: Maybe<Scalars['String']>;
  imagePreviewRomanianGP?: Maybe<Scalars['String']>;
  imagePreviewRomanianIOS?: Maybe<Scalars['String']>;
  imagePreviewSpainGP?: Maybe<Scalars['String']>;
  imagePreviewSpainIOS?: Maybe<Scalars['String']>;
  imagePreviewSwedishGP?: Maybe<Scalars['String']>;
  imagePreviewSwedishIOS?: Maybe<Scalars['String']>;
  imagePreviewTurkishGP?: Maybe<Scalars['String']>;
  imagePreviewTurkishIOS?: Maybe<Scalars['String']>;
  localized?: Maybe<Array<Scalars['String']>>;
  logline?: Maybe<Scalars['String']>;
  menuImage?: Maybe<Scalars['String']>;
  menuImagePreview?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nameCS?: Maybe<Scalars['String']>;
  nameDA?: Maybe<Scalars['String']>;
  nameDE?: Maybe<Scalars['String']>;
  nameES?: Maybe<Scalars['String']>;
  nameFI?: Maybe<Scalars['String']>;
  nameFR?: Maybe<Scalars['String']>;
  nameHU?: Maybe<Scalars['String']>;
  nameID?: Maybe<Scalars['String']>;
  nameIT?: Maybe<Scalars['String']>;
  nameJA?: Maybe<Scalars['String']>;
  nameKO?: Maybe<Scalars['String']>;
  nameNL?: Maybe<Scalars['String']>;
  nameNO?: Maybe<Scalars['String']>;
  namePL?: Maybe<Scalars['String']>;
  namePT?: Maybe<Scalars['String']>;
  namePT_BR?: Maybe<Scalars['String']>;
  nameRO?: Maybe<Scalars['String']>;
  nameRU?: Maybe<Scalars['String']>;
  nameSV?: Maybe<Scalars['String']>;
  nameTR?: Maybe<Scalars['String']>;
  narrativeLead?: Maybe<Scalars['String']>;
  organic: Scalars['Boolean'];
  outline?: Maybe<Scalars['String']>;
  prodStatusDontTouch?: Maybe<Scalars['String']>;
  production: Scalars['Boolean'];
  redesigned: Scalars['Boolean'];
  series?: Maybe<Array<Scalars['String']>>;
  stage: Scalars['String'];
  status: Scalars['String'];
  synopsis?: Maybe<Scalars['String']>;
  totalDiamonds?: Maybe<Scalars['Float']>;
  trending: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
};

export type BookInfo = {
  backgroundsCount: Scalars['Float'];
  cardsCount: Scalars['Float'];
  chaptersCount: Scalars['Float'];
  heroesCount: Scalars['Float'];
  itemsCount: Scalars['Float'];
};

export type BooksFilterArgDto = {
  app?: InputMaybe<Array<Scalars['String']>>;
  author?: InputMaybe<Scalars['String']>;
  extra?: InputMaybe<Array<Scalars['String']>>;
  fantasy?: InputMaybe<Scalars['Boolean']>;
  finished?: InputMaybe<Scalars['Boolean']>;
  fresh?: InputMaybe<Scalars['Boolean']>;
  genres?: InputMaybe<Array<Scalars['String']>>;
  has_new_customization?: InputMaybe<Scalars['Boolean']>;
  localized?: InputMaybe<Array<Scalars['String']>>;
  name?: InputMaybe<Scalars['String']>;
  narrativeLead?: InputMaybe<Scalars['String']>;
  redesigned?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Scalars['String']>;
  withoutBlushed?: InputMaybe<Scalars['Boolean']>;
  withoutFantasy?: InputMaybe<Scalars['Boolean']>;
};

export type BooksSortArgDto = {
  field?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['String']>;
};

export type BranchesQueryResponseDto = {
  name: Scalars['String'];
};

export type Bucket = {
  name: Scalars['String'];
};

export type BucketTransfer = {
  backup?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  from: Scalars['String'];
  id: Scalars['String'];
  to: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type Build = {
  config: Scalars['JSON'];
  createdAt: Scalars['DateTime'];
  createdBy: User;
  events: Array<BuildEvent>;
  id: Scalars['String'];
  status: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type BuildEvent = {
  createdAt: Scalars['DateTime'];
  error?: Maybe<Array<Scalars['String']>>;
  eventStatus?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  stage: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type BuildTemplate = {
  chapters: Array<Chapter>;
  createdAt: Scalars['DateTime'];
  createdBy: User;
  id: Scalars['String'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export enum Chapter_Status {
  ArtDoc = 'ART_DOC',
  ArtDocReady = 'ART_DOC_READY',
  DocReady = 'DOC_READY',
  Draft = 'DRAFT',
  EditsForArt = 'EDITS_FOR_ART',
  Illustration = 'ILLUSTRATION',
  IllustrationDone = 'ILLUSTRATION_DONE',
  IllustrationReady = 'ILLUSTRATION_READY',
  InProduction = 'IN_PRODUCTION',
  Lua = 'LUA',
  LuaDone = 'LUA_DONE',
  Music = 'MUSIC',
  Released = 'RELEASED',
  ReleaseDone = 'RELEASE_DONE',
  Script = 'SCRIPT',
  ScriptDone = 'SCRIPT_DONE'
}

export type Card = {
  background: Background;
  bookId: Scalars['String'];
  cardsLink: Array<Scalars['String']>;
  chapter: Chapter;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  filename: Scalars['String'];
  golden: Scalars['Boolean'];
  hero: Hero;
  heroName: Scalars['String'];
  id: Scalars['String'];
  secondary?: Maybe<Scalars['Boolean']>;
  selectedAssets: SelectedAssetsObject;
  updatedAt: Scalars['DateTime'];
};

export type CardsFilterArgDto = {
  bookId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type ChangeBackgroundsAssignsDto = {
  backgroundId: Scalars['String'];
  bookId: Scalars['String'];
  chaptersIds: Array<Scalars['String']>;
};

export type ChangeItemAssignsDto = {
  bookId: Scalars['String'];
  chaptersIds: Array<Scalars['String']>;
  itemId: Scalars['String'];
};

export type Chapter = {
  abTestLua1?: Maybe<Scalars['String']>;
  abTestLua2?: Maybe<Scalars['String']>;
  abTestLua3?: Maybe<Scalars['String']>;
  additionalDocs?: Maybe<Array<Doc>>;
  artDocID: Scalars['String'];
  author?: Maybe<Scalars['String']>;
  backgrounds: Array<Background>;
  book?: Maybe<Book>;
  cards: Array<Card>;
  chapterOrder?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  deleted: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
  descriptionCS?: Maybe<Scalars['String']>;
  descriptionDA?: Maybe<Scalars['String']>;
  descriptionDE?: Maybe<Scalars['String']>;
  descriptionES?: Maybe<Scalars['String']>;
  descriptionFI?: Maybe<Scalars['String']>;
  descriptionFR?: Maybe<Scalars['String']>;
  descriptionHU?: Maybe<Scalars['String']>;
  descriptionID?: Maybe<Scalars['String']>;
  descriptionIT?: Maybe<Scalars['String']>;
  descriptionJA?: Maybe<Scalars['String']>;
  descriptionKO?: Maybe<Scalars['String']>;
  descriptionNL?: Maybe<Scalars['String']>;
  descriptionNO?: Maybe<Scalars['String']>;
  descriptionPL?: Maybe<Scalars['String']>;
  descriptionPT?: Maybe<Scalars['String']>;
  descriptionPT_BR?: Maybe<Scalars['String']>;
  descriptionRO?: Maybe<Scalars['String']>;
  descriptionRU?: Maybe<Scalars['String']>;
  descriptionSV?: Maybe<Scalars['String']>;
  descriptionTR?: Maybe<Scalars['String']>;
  folderId?: Maybe<Scalars['String']>;
  googleDoc?: Maybe<Doc>;
  googleDoc2Pov?: Maybe<Doc>;
  googleDoc2PovCS?: Maybe<Doc>;
  googleDoc2PovDA?: Maybe<Doc>;
  googleDoc2PovDE?: Maybe<Doc>;
  googleDoc2PovES?: Maybe<Doc>;
  googleDoc2PovFI?: Maybe<Doc>;
  googleDoc2PovFR?: Maybe<Doc>;
  googleDoc2PovHU?: Maybe<Doc>;
  googleDoc2PovID?: Maybe<Doc>;
  googleDoc2PovIT?: Maybe<Doc>;
  googleDoc2PovJA?: Maybe<Doc>;
  googleDoc2PovKO?: Maybe<Doc>;
  googleDoc2PovNL?: Maybe<Doc>;
  googleDoc2PovNO?: Maybe<Doc>;
  googleDoc2PovPL?: Maybe<Doc>;
  googleDoc2PovPT?: Maybe<Doc>;
  googleDoc2PovPT_BR?: Maybe<Doc>;
  googleDoc2PovRO?: Maybe<Doc>;
  googleDoc2PovSV?: Maybe<Doc>;
  googleDoc2PovTR?: Maybe<Doc>;
  googleDocABTest1?: Maybe<Doc>;
  googleDocABTest2?: Maybe<Doc>;
  googleDocABTest3?: Maybe<Doc>;
  googleDocCS?: Maybe<Doc>;
  googleDocDA?: Maybe<Doc>;
  googleDocDE?: Maybe<Doc>;
  googleDocES?: Maybe<Doc>;
  googleDocFI?: Maybe<Doc>;
  googleDocFR?: Maybe<Doc>;
  googleDocHU?: Maybe<Doc>;
  googleDocID?: Maybe<Doc>;
  googleDocIT?: Maybe<Doc>;
  googleDocJA?: Maybe<Doc>;
  googleDocKO?: Maybe<Doc>;
  googleDocNL?: Maybe<Doc>;
  googleDocNO?: Maybe<Doc>;
  googleDocNarrative?: Maybe<Doc>;
  googleDocPL?: Maybe<Doc>;
  googleDocPT?: Maybe<Doc>;
  googleDocPT_BR?: Maybe<Doc>;
  googleDocRO?: Maybe<Doc>;
  googleDocRU?: Maybe<Doc>;
  googleDocSV?: Maybe<Doc>;
  googleDocTR?: Maybe<Doc>;
  googleDocWriter?: Maybe<Doc>;
  hasTwoPovs?: Maybe<Scalars['Boolean']>;
  heroes: Array<Hero>;
  id: Scalars['String'];
  isVideo?: Maybe<Scalars['Boolean']>;
  items: Array<Item>;
  lua2AbTest?: Maybe<Scalars['String']>;
  luaLink?: Maybe<Scalars['String']>;
  luaLinkCS?: Maybe<Scalars['String']>;
  luaLinkDA?: Maybe<Scalars['String']>;
  luaLinkDE?: Maybe<Scalars['String']>;
  luaLinkES?: Maybe<Scalars['String']>;
  luaLinkFI?: Maybe<Scalars['String']>;
  luaLinkFR?: Maybe<Scalars['String']>;
  luaLinkHU?: Maybe<Scalars['String']>;
  luaLinkID?: Maybe<Scalars['String']>;
  luaLinkIT?: Maybe<Scalars['String']>;
  luaLinkJA?: Maybe<Scalars['String']>;
  luaLinkKO?: Maybe<Scalars['String']>;
  luaLinkNL?: Maybe<Scalars['String']>;
  luaLinkNO?: Maybe<Scalars['String']>;
  luaLinkPL?: Maybe<Scalars['String']>;
  luaLinkPT?: Maybe<Scalars['String']>;
  luaLinkPT_BR?: Maybe<Scalars['String']>;
  luaLinkRO?: Maybe<Scalars['String']>;
  luaLinkRU?: Maybe<Scalars['String']>;
  luaLinkSV?: Maybe<Scalars['String']>;
  luaLinkTR?: Maybe<Scalars['String']>;
  managementStatus: Array<Chapter_Status>;
  name?: Maybe<Scalars['String']>;
  nameCS?: Maybe<Scalars['String']>;
  nameDA?: Maybe<Scalars['String']>;
  nameDE?: Maybe<Scalars['String']>;
  nameES?: Maybe<Scalars['String']>;
  nameFI?: Maybe<Scalars['String']>;
  nameFR?: Maybe<Scalars['String']>;
  nameHU?: Maybe<Scalars['String']>;
  nameID?: Maybe<Scalars['String']>;
  nameIT?: Maybe<Scalars['String']>;
  nameJA?: Maybe<Scalars['String']>;
  nameKO?: Maybe<Scalars['String']>;
  nameNL?: Maybe<Scalars['String']>;
  nameNO?: Maybe<Scalars['String']>;
  namePL?: Maybe<Scalars['String']>;
  namePT?: Maybe<Scalars['String']>;
  namePT_BR?: Maybe<Scalars['String']>;
  nameRO?: Maybe<Scalars['String']>;
  nameRU?: Maybe<Scalars['String']>;
  nameSV?: Maybe<Scalars['String']>;
  nameTR?: Maybe<Scalars['String']>;
  narrativeDocsFolderId?: Maybe<Scalars['String']>;
  prodDocsFolderId?: Maybe<Scalars['String']>;
  rems: Array<Scalars['String']>;
  secondHeroName?: Maybe<Scalars['String']>;
  secondPovLua?: Maybe<Scalars['String']>;
  secondPovLuaCS?: Maybe<Scalars['String']>;
  secondPovLuaDA?: Maybe<Scalars['String']>;
  secondPovLuaDE?: Maybe<Scalars['String']>;
  secondPovLuaES?: Maybe<Scalars['String']>;
  secondPovLuaFI?: Maybe<Scalars['String']>;
  secondPovLuaFR?: Maybe<Scalars['String']>;
  secondPovLuaHU?: Maybe<Scalars['String']>;
  secondPovLuaID?: Maybe<Scalars['String']>;
  secondPovLuaIT?: Maybe<Scalars['String']>;
  secondPovLuaJA?: Maybe<Scalars['String']>;
  secondPovLuaKO?: Maybe<Scalars['String']>;
  secondPovLuaNL?: Maybe<Scalars['String']>;
  secondPovLuaNO?: Maybe<Scalars['String']>;
  secondPovLuaPL?: Maybe<Scalars['String']>;
  secondPovLuaPT?: Maybe<Scalars['String']>;
  secondPovLuaPT_BR?: Maybe<Scalars['String']>;
  secondPovLuaRO?: Maybe<Scalars['String']>;
  secondPovLuaSV?: Maybe<Scalars['String']>;
  secondPovLuaTR?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  textLink?: Maybe<Scalars['String']>;
  totalDiamonds?: Maybe<Scalars['Float']>;
  updatedAt: Scalars['DateTime'];
  writerDocsFolderId?: Maybe<Scalars['String']>;
};

export type CharacterValidationData = {
  bodies: Array<BodiesData>;
  clothes: Array<Scalars['String']>;
  hairs: Array<Scalars['String']>;
  layers: Array<Scalars['String']>;
  name: Scalars['String'];
};

export type Chat = {
  analyticsName?: Maybe<Scalars['Float']>;
  books: Array<Book>;
  chatPhotos: ChatPhoto;
  createdAt: Scalars['DateTime'];
  googleDoc?: Maybe<Doc>;
  id: Scalars['String'];
  luaLink?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  readyForUpload: Scalars['Boolean'];
  status: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type ChatHero = {
  age?: Maybe<Scalars['Float']>;
  badge?: Maybe<Scalars['String']>;
  body?: Maybe<Body>;
  books: Array<Book>;
  cloth?: Maybe<Clothes>;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  hair?: Maybe<Hair>;
  hero?: Maybe<Hero>;
  id: Scalars['String'];
  isLoveInterest: Scalars['Boolean'];
  keywords: Array<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  secondHero: Scalars['Boolean'];
  status: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type ChatPhoto = {
  atName?: Maybe<Scalars['String']>;
  chats: Array<Chat>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  link?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type ChatPhotosFilterArgDto = {
  bookId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type Clothes = {
  alternativeClothes: Array<Clothes>;
  atName?: Maybe<Scalars['String']>;
  chatHeroes: Array<ChatHero>;
  createdAt: Scalars['DateTime'];
  dropboxLink?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  heroes: Array<Hero>;
  id: Scalars['String'];
  keywords?: Maybe<Array<Scalars['String']>>;
  link?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  newKeywords?: Maybe<KeywordsClothObject>;
  price?: Maybe<Scalars['Float']>;
  type: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type ClothesFilterArgDto = {
  bookId?: InputMaybe<Scalars['String']>;
  keywords?: InputMaybe<Array<Scalars['String']>>;
  name?: InputMaybe<Scalars['String']>;
};

export type Color = {
  rgbColor: RgbColor;
};

export type ConvertResult = {
  errors: Array<ErrorResult>;
  text?: Maybe<Scalars['String']>;
  warnings: Array<ErrorResult>;
};

export type ConvertScriptDto = {
  chapterId: Scalars['String'];
  content: Scalars['String'];
  docType?: InputMaybe<Scalars['String']>;
  lang?: InputMaybe<Scalars['String']>;
};

export type ConvertScriptOldDto = {
  script: Scalars['String'];
  validationData?: InputMaybe<Scalars['JSON']>;
};

export type CreateArtDocRequestDto = {
  backgrounds?: InputMaybe<Array<ArtDocBackgroundInput>>;
  bookID: Scalars['String'];
  chapterID: Scalars['String'];
  chapterOrder: Scalars['Float'];
  cover?: InputMaybe<ArtDocCoverInput>;
  heroes?: InputMaybe<Array<ArtDocHeroInput>>;
  items?: InputMaybe<Array<ArtDocItemInput>>;
};

export type CreateAudioRequestDto = {
  keywords: Array<Scalars['String']>;
  link: Scalars['String'];
  newKeywords?: InputMaybe<KeywordsAudioInput>;
};

export type CreateBackgroundRequestDto = {
  alternativeBackgroundsIds?: InputMaybe<Array<Scalars['String']>>;
  chaptersIds?: InputMaybe<Array<Scalars['String']>>;
  dropboxLink?: InputMaybe<Scalars['String']>;
  filename?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Scalars['String']>;
  keywords: Array<Scalars['String']>;
  link: Scalars['String'];
  newKeywords: KeywordsBackgroundInput;
  visionLabels?: InputMaybe<Scalars['String']>;
};

export type CreateBodyRequestDto = {
  dropboxLink?: InputMaybe<Scalars['String']>;
  emotions: Array<Scalars['String']>;
  filename?: InputMaybe<Scalars['String']>;
  keywords: Array<Scalars['String']>;
  link: Scalars['String'];
  newKeywords: KeywordsBodyInput;
};

export type CreateBookRequestDto = {
  app?: InputMaybe<Array<Scalars['String']>>;
  artDocument?: InputMaybe<Scalars['String']>;
  bannerImage?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<Scalars['String']>;
  chapters?: InputMaybe<Array<Scalars['String']>>;
  customId: Scalars['String'];
  extra?: InputMaybe<Array<Scalars['String']>>;
  fantasy?: InputMaybe<Scalars['Boolean']>;
  fantasyCategories?: InputMaybe<Array<Scalars['String']>>;
  finished?: InputMaybe<Scalars['Boolean']>;
  fresh?: InputMaybe<Scalars['Boolean']>;
  genres?: InputMaybe<Array<Scalars['String']>>;
  has_new_customization?: InputMaybe<Scalars['Boolean']>;
  imageMenuFrenchGP?: InputMaybe<Scalars['String']>;
  imageMenuFrenchIOS?: InputMaybe<Scalars['String']>;
  imageMenuGP?: InputMaybe<Scalars['String']>;
  imageMenuGermanGP?: InputMaybe<Scalars['String']>;
  imageMenuGermanIOS?: InputMaybe<Scalars['String']>;
  imageMenuIOS?: InputMaybe<Scalars['String']>;
  imageMenuSpainGP?: InputMaybe<Scalars['String']>;
  imageMenuSpainIOS?: InputMaybe<Scalars['String']>;
  imagePreviewFrenchGP?: InputMaybe<Scalars['String']>;
  imagePreviewFrenchIOS?: InputMaybe<Scalars['String']>;
  imagePreviewGP?: InputMaybe<Scalars['String']>;
  imagePreviewGermanGP?: InputMaybe<Scalars['String']>;
  imagePreviewGermanIOS?: InputMaybe<Scalars['String']>;
  imagePreviewIOS?: InputMaybe<Scalars['String']>;
  imagePreviewSpainGP?: InputMaybe<Scalars['String']>;
  imagePreviewSpainIOS?: InputMaybe<Scalars['String']>;
  localized?: InputMaybe<Array<Scalars['String']>>;
  logline?: InputMaybe<Scalars['String']>;
  menuImage?: InputMaybe<Scalars['String']>;
  menuImageDE?: InputMaybe<Scalars['String']>;
  menuImageES?: InputMaybe<Scalars['String']>;
  menuImageFR?: InputMaybe<Scalars['String']>;
  menuImagePreview?: InputMaybe<Scalars['String']>;
  menuImagePreviewDE?: InputMaybe<Scalars['String']>;
  menuImagePreviewES?: InputMaybe<Scalars['String']>;
  menuImagePreviewFR?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  nameDE?: InputMaybe<Scalars['String']>;
  nameES?: InputMaybe<Scalars['String']>;
  nameFR?: InputMaybe<Scalars['String']>;
  namePT?: InputMaybe<Scalars['String']>;
  nameRU?: InputMaybe<Scalars['String']>;
  outline?: InputMaybe<Scalars['String']>;
  production?: InputMaybe<Scalars['Boolean']>;
  redesigned?: InputMaybe<Scalars['Boolean']>;
  series?: InputMaybe<Array<Scalars['String']>>;
  synopsis?: InputMaybe<Scalars['String']>;
};

export type CreateCardRequestDto = {
  backgroundId: Scalars['String'];
  bookId?: InputMaybe<Scalars['String']>;
  cardsLink: Array<Scalars['String']>;
  chapterId: Scalars['String'];
  description: Scalars['String'];
  filename: Scalars['String'];
  golden: Scalars['Boolean'];
  heroId: Scalars['String'];
  heroName: Scalars['String'];
  secondary: Scalars['Boolean'];
  selectedAssets: SelectedAssetsInput;
};

export type CreateChapterDocByConfigRequestDto = {
  language: Scalars['String'];
  type: Scalars['String'];
};

export type CreateChapterRequestDto = {
  audios?: InputMaybe<Array<Scalars['String']>>;
  author?: InputMaybe<Scalars['String']>;
  backgrounds?: InputMaybe<Array<Scalars['String']>>;
  book?: InputMaybe<Scalars['String']>;
  chapterLink?: InputMaybe<Scalars['String']>;
  chapterOrder?: InputMaybe<Scalars['Int']>;
  chatPhotos?: InputMaybe<Array<Scalars['String']>>;
  description?: InputMaybe<Scalars['String']>;
  heroes?: InputMaybe<Array<Scalars['String']>>;
  items?: InputMaybe<Array<Scalars['String']>>;
  luaLink?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type CreateChatHeroRequestDto = {
  age: Scalars['Float'];
  badge?: InputMaybe<Scalars['String']>;
  body?: InputMaybe<Scalars['String']>;
  book?: InputMaybe<Scalars['String']>;
  cloth?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  hair?: InputMaybe<Scalars['String']>;
  hero?: InputMaybe<Scalars['String']>;
  isLoveInterest?: InputMaybe<Scalars['Boolean']>;
  keywords?: InputMaybe<Array<Scalars['String']>>;
  lastName: Scalars['String'];
  secondHero?: InputMaybe<Scalars['Boolean']>;
  sex: Scalars['Float'];
};

export type CreateChatPhotoRequestDto = {
  link: Scalars['String'];
};

export type CreateChatRequestDto = {
  analyticsName: Scalars['Float'];
  name: Scalars['String'];
};

export type CreateClothesRequestDto = {
  alternativeClothesIds?: InputMaybe<Array<Scalars['String']>>;
  dropboxLink?: InputMaybe<Scalars['String']>;
  filename?: InputMaybe<Scalars['String']>;
  keywords: Array<Scalars['String']>;
  link: Scalars['String'];
  newKeywords?: InputMaybe<KeywordsClothInput>;
  price: Scalars['Int'];
};

export type CreateDocsNotesRequestDto = {
  description?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  link?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type CreateHairsRequestDto = {
  backLink?: InputMaybe<Scalars['String']>;
  dropboxLink?: InputMaybe<Scalars['String']>;
  filename?: InputMaybe<Scalars['String']>;
  frontLink: Scalars['String'];
  keywords: Array<Scalars['String']>;
  newHair: Scalars['Boolean'];
  newKeywords?: InputMaybe<KeywordsHairInput>;
};

export type CreateHeroRequestDto = {
  bodies?: InputMaybe<Array<Scalars['String']>>;
  chapters?: InputMaybe<Array<Scalars['String']>>;
  clothes?: InputMaybe<Array<Scalars['String']>>;
  defaultClothesId?: InputMaybe<Scalars['String']>;
  hairs?: InputMaybe<Array<Scalars['String']>>;
  isFamiliar?: InputMaybe<Scalars['Boolean']>;
  keywords?: InputMaybe<Array<Scalars['String']>>;
  layers?: InputMaybe<Array<Scalars['String']>>;
  names: Array<Scalars['String']>;
  parentId?: InputMaybe<Scalars['String']>;
};

export type CreateItemRequestDto = {
  chaptersIds?: InputMaybe<Array<Scalars['String']>>;
  dropboxLink?: InputMaybe<Scalars['String']>;
  filename?: InputMaybe<Scalars['String']>;
  keywords: Array<Scalars['String']>;
  link: Scalars['String'];
  newKeywords?: InputMaybe<KeywordsItemInput>;
  price: Scalars['Int'];
};

export type CreateJobDto = {
  branch: Scalars['String'];
  bucket: Scalars['String'];
  chaptersIds: Array<Scalars['String']>;
  cleanCacheBooks: Array<Scalars['String']>;
  platform: Scalars['String'];
};

export type CreateLayerRequestDto = {
  description?: InputMaybe<Scalars['String']>;
  dropboxLink?: InputMaybe<Scalars['String']>;
  keywords: Array<Scalars['String']>;
  link: Scalars['String'];
  newKeywords?: InputMaybe<KeywordsLayerInput>;
  position: Scalars['Int'];
  price?: InputMaybe<Scalars['Float']>;
};

export type CreateTemplateDto = {
  chaptersIds: Array<Scalars['String']>;
  name: Scalars['String'];
};

export type CreateUserRequestDto = {
  email: Scalars['String'];
  fullName?: InputMaybe<Scalars['String']>;
  permissions: Array<Permission>;
  picture?: InputMaybe<Scalars['String']>;
  slackId?: InputMaybe<Scalars['String']>;
};

export type DeleteTemplateDto = {
  id: Scalars['String'];
};

export type Doc = {
  comments?: Maybe<Array<RawDraftEntity>>;
  content?: Maybe<Array<RawDraftContentBlock>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  docNotes?: Maybe<DocsNotes>;
  docOrder?: Maybe<Scalars['Int']>;
  id: Scalars['String'];
  link?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DocsNotes = {
  description?: Maybe<Scalars['String']>;
  doc?: Maybe<Doc>;
  id: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  link?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type DocumentElement = {
  text?: Maybe<Array<Text>>;
};

export type Element = {
  textRun?: Maybe<TextRun>;
};

export type EmotionsInfo = {
  deletedEmotions: Array<Scalars['String']>;
  newEmotions: Array<Scalars['String']>;
  updatedEmotions: Array<Scalars['String']>;
};

export type ErrorResult = {
  line: Scalars['Float'];
  message: Scalars['String'];
  position: Scalars['Float'];
  type: Scalars['String'];
};

export type FindAudiosResponseDto = {
  audios: Array<Audio>;
  count: Scalars['Float'];
};

export type FindBackgroundsResponseDto = {
  backgrounds: Array<Background>;
};

export type FindBodiesResponseDto = {
  bodies: Array<Body>;
  count: Scalars['Float'];
};

export type FindCardsResponseDto = {
  cards: Array<Card>;
  count: Scalars['Float'];
};

export type FindChatPhotosResponseDto = {
  chatPhotos: Array<ChatPhoto>;
  count: Scalars['Float'];
};

export type FindClothesResponseDto = {
  clothes: Array<Clothes>;
  count: Scalars['Float'];
};

export type FindHairsResponseDto = {
  count: Scalars['Float'];
  hairs: Array<Hair>;
};

export type FindItemsResponseDto = {
  count: Scalars['Float'];
  items: Array<Item>;
};

export type FindLayersResponseDto = {
  count: Scalars['Float'];
  layers: Array<Layer>;
};

export type GetDocResponseDto = {
  blocks: Array<RawDraftContentBlock>;
  createdAt?: Maybe<Scalars['DateTime']>;
  entityMap: Array<RawDraftEntity>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type Hair = {
  atName?: Maybe<Scalars['String']>;
  backLink?: Maybe<Scalars['String']>;
  backName?: Maybe<Scalars['String']>;
  chatHeroes: Array<ChatHero>;
  createdAt: Scalars['DateTime'];
  dropboxLink?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  frontLink?: Maybe<Scalars['String']>;
  gender: Scalars['String'];
  heroes: Array<Hero>;
  id: Scalars['String'];
  keywords?: Maybe<Array<Scalars['String']>>;
  name?: Maybe<Scalars['String']>;
  newHair: Scalars['Boolean'];
  newKeywords?: Maybe<KeywordsHairObject>;
  type: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type HairDetailsDocInput = {
  body_type?: InputMaybe<Scalars['String']>;
  color?: InputMaybe<Array<Scalars['String']>>;
  copyAssetIds?: InputMaybe<Array<Scalars['String']>>;
  copyFromAssetId?: InputMaybe<Scalars['String']>;
  cost?: InputMaybe<Scalars['String']>;
  customID?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  isCopied?: InputMaybe<Scalars['Boolean']>;
  length?: InputMaybe<Scalars['String']>;
  needTask?: InputMaybe<Scalars['Boolean']>;
  script_name?: InputMaybe<Scalars['String']>;
  sex?: InputMaybe<Scalars['String']>;
  structure?: InputMaybe<Scalars['String']>;
};

export type HairDetailsDocObject = {
  body_type?: Maybe<Scalars['String']>;
  color?: Maybe<Array<Scalars['String']>>;
  copyAssetIds?: Maybe<Array<Scalars['String']>>;
  copyFromAssetId?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['String']>;
  customID?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isCopied?: Maybe<Scalars['Boolean']>;
  length?: Maybe<Scalars['String']>;
  needTask?: Maybe<Scalars['Boolean']>;
  script_name?: Maybe<Scalars['String']>;
  sex?: Maybe<Scalars['String']>;
  structure?: Maybe<Scalars['String']>;
};

export type HairsFilterArgDto = {
  bookId?: InputMaybe<Scalars['String']>;
  keywords?: InputMaybe<Array<Scalars['String']>>;
  name?: InputMaybe<Scalars['String']>;
};

export type Hero = {
  bodies?: Maybe<Array<Body>>;
  cards: Array<Card>;
  chapters: Array<Chapter>;
  chatHeroes?: Maybe<ChatHero>;
  clothes: Array<Clothes>;
  createdAt: Scalars['DateTime'];
  customId?: Maybe<Scalars['Float']>;
  defaultClothes?: Maybe<Clothes>;
  freeClothes: Array<Clothes>;
  hairs: Array<Hair>;
  id: Scalars['String'];
  isFamiliar: Scalars['Boolean'];
  keywords: Array<Scalars['String']>;
  layers: Array<Layer>;
  names: Array<Scalars['String']>;
  parent?: Maybe<Hero>;
  updatedAt: Scalars['DateTime'];
};

export type HeroDetailsDocInput = {
  age?: InputMaybe<Scalars['String']>;
  body_id?: InputMaybe<Scalars['String']>;
  body_type?: InputMaybe<Scalars['String']>;
  copyAssetIds?: InputMaybe<Array<Scalars['String']>>;
  copyFromAssetId?: InputMaybe<Scalars['String']>;
  customID?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  isCopied?: InputMaybe<Scalars['Boolean']>;
  needTask?: InputMaybe<Scalars['Boolean']>;
  script_name?: InputMaybe<Scalars['String']>;
  sex?: InputMaybe<Scalars['String']>;
  skin_tone?: InputMaybe<Array<Scalars['String']>>;
};

export type HeroDetailsDocObject = {
  age?: Maybe<Scalars['String']>;
  body_id?: Maybe<Scalars['String']>;
  body_type?: Maybe<Scalars['String']>;
  copyAssetIds?: Maybe<Array<Scalars['String']>>;
  copyFromAssetId?: Maybe<Scalars['String']>;
  customID?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isCopied?: Maybe<Scalars['Boolean']>;
  needTask?: Maybe<Scalars['Boolean']>;
  script_name?: Maybe<Scalars['String']>;
  sex?: Maybe<Scalars['String']>;
  skin_tone?: Maybe<Array<Scalars['String']>>;
};

export type HeroesFilterArgDto = {
  bookId?: InputMaybe<Scalars['String']>;
  chapterId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  story?: InputMaybe<Scalars['String']>;
};

export type HeroesSortArgDto = {
  field?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['String']>;
};

export type InsertDraftDocDataDto = {
  blocks: Scalars['JSON'];
  entityMap: Scalars['JSON'];
};

export type InsertGoogleDocDataDto = {
  documentId: Scalars['String'];
  styles: Scalars['JSON'];
  text: Scalars['String'];
};

export type Item = {
  atName?: Maybe<Scalars['String']>;
  chapters: Array<Chapter>;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  dropboxLink?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  keywords: Array<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  newKeywords?: Maybe<KeywordsItemObject>;
  price?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type ItemDetailsDocInput = {
  copyAssetIds?: InputMaybe<Array<Scalars['String']>>;
  copyFromAssetId?: InputMaybe<Scalars['String']>;
  cost?: InputMaybe<Scalars['String']>;
  customID?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  isCopied?: InputMaybe<Scalars['Boolean']>;
  needTask?: InputMaybe<Scalars['Boolean']>;
  script_name?: InputMaybe<Scalars['String']>;
};

export type ItemDetailsDocObject = {
  copyAssetIds?: Maybe<Array<Scalars['String']>>;
  copyFromAssetId?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['String']>;
  customID?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isCopied?: Maybe<Scalars['Boolean']>;
  needTask?: Maybe<Scalars['Boolean']>;
  script_name?: Maybe<Scalars['String']>;
};

export type ItemsFilterArgDto = {
  bookId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type KeywordsAssetInput = {
  accessories?: InputMaybe<Array<Scalars['String']>>;
  adjective?: InputMaybe<Array<Scalars['String']>>;
  age?: InputMaybe<Array<Scalars['String']>>;
  app?: InputMaybe<Array<Scalars['String']>>;
  art_style?: InputMaybe<Array<Scalars['String']>>;
  body_type?: InputMaybe<Array<Scalars['String']>>;
  bottom?: InputMaybe<Array<Scalars['String']>>;
  color?: InputMaybe<Array<Scalars['String']>>;
  color_range?: InputMaybe<Array<Scalars['String']>>;
  defects?: InputMaybe<Array<Scalars['String']>>;
  extra?: InputMaybe<Array<Scalars['String']>>;
  for_body?: InputMaybe<Array<Scalars['String']>>;
  hairstyle?: InputMaybe<Array<Scalars['String']>>;
  inside?: InputMaybe<Array<Scalars['String']>>;
  item?: InputMaybe<Array<Scalars['String']>>;
  items?: InputMaybe<Array<Scalars['String']>>;
  length?: InputMaybe<Array<Scalars['String']>>;
  material?: InputMaybe<Array<Scalars['String']>>;
  mood?: InputMaybe<Array<Scalars['String']>>;
  not_human?: InputMaybe<Array<Scalars['String']>>;
  nudity?: InputMaybe<Array<Scalars['String']>>;
  outside?: InputMaybe<Array<Scalars['String']>>;
  owner?: InputMaybe<Array<Scalars['String']>>;
  rooms?: InputMaybe<Array<Scalars['String']>>;
  sex?: InputMaybe<Array<Scalars['String']>>;
  skintone?: InputMaybe<Array<Scalars['String']>>;
  structure?: InputMaybe<Array<Scalars['String']>>;
  style?: InputMaybe<Array<Scalars['String']>>;
  time?: InputMaybe<Array<Scalars['String']>>;
  top?: InputMaybe<Array<Scalars['String']>>;
};

export type KeywordsAudioInput = {
  accessories?: InputMaybe<Array<Scalars['String']>>;
  adjective?: InputMaybe<Array<Scalars['String']>>;
  age?: InputMaybe<Array<Scalars['String']>>;
  app?: InputMaybe<Array<Scalars['String']>>;
  art_style?: InputMaybe<Array<Scalars['String']>>;
  body_type?: InputMaybe<Array<Scalars['String']>>;
  bottom?: InputMaybe<Array<Scalars['String']>>;
  color?: InputMaybe<Array<Scalars['String']>>;
  color_range?: InputMaybe<Array<Scalars['String']>>;
  defects?: InputMaybe<Array<Scalars['String']>>;
  extra?: InputMaybe<Array<Scalars['String']>>;
  for_body?: InputMaybe<Array<Scalars['String']>>;
  hairstyle?: InputMaybe<Array<Scalars['String']>>;
  inside?: InputMaybe<Array<Scalars['String']>>;
  item?: InputMaybe<Array<Scalars['String']>>;
  items?: InputMaybe<Array<Scalars['String']>>;
  length?: InputMaybe<Array<Scalars['String']>>;
  material?: InputMaybe<Array<Scalars['String']>>;
  mood?: InputMaybe<Array<Scalars['String']>>;
  not_human?: InputMaybe<Array<Scalars['String']>>;
  nudity?: InputMaybe<Array<Scalars['String']>>;
  outside?: InputMaybe<Array<Scalars['String']>>;
  owner?: InputMaybe<Array<Scalars['String']>>;
  rooms?: InputMaybe<Array<Scalars['String']>>;
  sex?: InputMaybe<Array<Scalars['String']>>;
  skintone?: InputMaybe<Array<Scalars['String']>>;
  structure?: InputMaybe<Array<Scalars['String']>>;
  style?: InputMaybe<Array<Scalars['String']>>;
  time?: InputMaybe<Array<Scalars['String']>>;
  top?: InputMaybe<Array<Scalars['String']>>;
};

export type KeywordsAudioObject = {
  accessories?: Maybe<Array<Scalars['String']>>;
  adjective?: Maybe<Array<Scalars['String']>>;
  age?: Maybe<Array<Scalars['String']>>;
  app?: Maybe<Array<Scalars['String']>>;
  art_style?: Maybe<Array<Scalars['String']>>;
  body_type?: Maybe<Array<Scalars['String']>>;
  bottom?: Maybe<Array<Scalars['String']>>;
  color?: Maybe<Array<Scalars['String']>>;
  color_range?: Maybe<Array<Scalars['String']>>;
  defects?: Maybe<Array<Scalars['String']>>;
  extra?: Maybe<Array<Scalars['String']>>;
  for_body?: Maybe<Array<Scalars['String']>>;
  hairstyle?: Maybe<Array<Scalars['String']>>;
  inside?: Maybe<Array<Scalars['String']>>;
  item?: Maybe<Array<Scalars['String']>>;
  items?: Maybe<Array<Scalars['String']>>;
  length?: Maybe<Array<Scalars['String']>>;
  material?: Maybe<Array<Scalars['String']>>;
  mood?: Maybe<Array<Scalars['String']>>;
  not_human?: Maybe<Array<Scalars['String']>>;
  nudity?: Maybe<Array<Scalars['String']>>;
  outside?: Maybe<Array<Scalars['String']>>;
  owner?: Maybe<Array<Scalars['String']>>;
  rooms?: Maybe<Array<Scalars['String']>>;
  sex?: Maybe<Array<Scalars['String']>>;
  skintone?: Maybe<Array<Scalars['String']>>;
  structure?: Maybe<Array<Scalars['String']>>;
  style?: Maybe<Array<Scalars['String']>>;
  time?: Maybe<Array<Scalars['String']>>;
  top?: Maybe<Array<Scalars['String']>>;
};

export type KeywordsBackgroundInput = {
  app?: InputMaybe<Array<Scalars['String']>>;
  art_style?: InputMaybe<Array<Scalars['String']>>;
  extra?: InputMaybe<Array<Scalars['String']>>;
  inside?: InputMaybe<Array<Scalars['String']>>;
  mood?: InputMaybe<Array<Scalars['String']>>;
  outside?: InputMaybe<Array<Scalars['String']>>;
  rooms?: InputMaybe<Array<Scalars['String']>>;
  style?: InputMaybe<Array<Scalars['String']>>;
  time?: InputMaybe<Array<Scalars['String']>>;
};

export type KeywordsBackgroundObject = {
  app?: Maybe<Array<Scalars['String']>>;
  art_style?: Maybe<Array<Scalars['String']>>;
  extra?: Maybe<Array<Scalars['String']>>;
  inside?: Maybe<Array<Scalars['String']>>;
  mood?: Maybe<Array<Scalars['String']>>;
  outside?: Maybe<Array<Scalars['String']>>;
  rooms?: Maybe<Array<Scalars['String']>>;
  style?: Maybe<Array<Scalars['String']>>;
  time?: Maybe<Array<Scalars['String']>>;
};

export type KeywordsBodyInput = {
  accessories?: InputMaybe<Array<Scalars['String']>>;
  adjective?: InputMaybe<Array<Scalars['String']>>;
  age?: InputMaybe<Array<Scalars['String']>>;
  app?: InputMaybe<Array<Scalars['String']>>;
  art_style?: InputMaybe<Array<Scalars['String']>>;
  body_type?: InputMaybe<Array<Scalars['String']>>;
  bottom?: InputMaybe<Array<Scalars['String']>>;
  color?: InputMaybe<Array<Scalars['String']>>;
  color_range?: InputMaybe<Array<Scalars['String']>>;
  defects?: InputMaybe<Array<Scalars['String']>>;
  extra?: InputMaybe<Array<Scalars['String']>>;
  for_body?: InputMaybe<Array<Scalars['String']>>;
  hairstyle?: InputMaybe<Array<Scalars['String']>>;
  inside?: InputMaybe<Array<Scalars['String']>>;
  item?: InputMaybe<Array<Scalars['String']>>;
  items?: InputMaybe<Array<Scalars['String']>>;
  length?: InputMaybe<Array<Scalars['String']>>;
  material?: InputMaybe<Array<Scalars['String']>>;
  mood?: InputMaybe<Array<Scalars['String']>>;
  not_human?: InputMaybe<Array<Scalars['String']>>;
  nudity?: InputMaybe<Array<Scalars['String']>>;
  outside?: InputMaybe<Array<Scalars['String']>>;
  owner?: InputMaybe<Array<Scalars['String']>>;
  rooms?: InputMaybe<Array<Scalars['String']>>;
  sex?: InputMaybe<Array<Scalars['String']>>;
  skintone?: InputMaybe<Array<Scalars['String']>>;
  structure?: InputMaybe<Array<Scalars['String']>>;
  style?: InputMaybe<Array<Scalars['String']>>;
  time?: InputMaybe<Array<Scalars['String']>>;
  top?: InputMaybe<Array<Scalars['String']>>;
};

export type KeywordsBodyObject = {
  accessories?: Maybe<Array<Scalars['String']>>;
  adjective?: Maybe<Array<Scalars['String']>>;
  age?: Maybe<Array<Scalars['String']>>;
  app?: Maybe<Array<Scalars['String']>>;
  art_style?: Maybe<Array<Scalars['String']>>;
  body_type?: Maybe<Array<Scalars['String']>>;
  bottom?: Maybe<Array<Scalars['String']>>;
  color?: Maybe<Array<Scalars['String']>>;
  color_range?: Maybe<Array<Scalars['String']>>;
  defects?: Maybe<Array<Scalars['String']>>;
  extra?: Maybe<Array<Scalars['String']>>;
  for_body?: Maybe<Array<Scalars['String']>>;
  hairstyle?: Maybe<Array<Scalars['String']>>;
  inside?: Maybe<Array<Scalars['String']>>;
  item?: Maybe<Array<Scalars['String']>>;
  items?: Maybe<Array<Scalars['String']>>;
  length?: Maybe<Array<Scalars['String']>>;
  material?: Maybe<Array<Scalars['String']>>;
  mood?: Maybe<Array<Scalars['String']>>;
  not_human?: Maybe<Array<Scalars['String']>>;
  nudity?: Maybe<Array<Scalars['String']>>;
  outside?: Maybe<Array<Scalars['String']>>;
  owner?: Maybe<Array<Scalars['String']>>;
  rooms?: Maybe<Array<Scalars['String']>>;
  sex?: Maybe<Array<Scalars['String']>>;
  skintone?: Maybe<Array<Scalars['String']>>;
  structure?: Maybe<Array<Scalars['String']>>;
  style?: Maybe<Array<Scalars['String']>>;
  time?: Maybe<Array<Scalars['String']>>;
  top?: Maybe<Array<Scalars['String']>>;
};

export type KeywordsClothInput = {
  accessories?: InputMaybe<Array<Scalars['String']>>;
  adjective?: InputMaybe<Array<Scalars['String']>>;
  age?: InputMaybe<Array<Scalars['String']>>;
  app?: InputMaybe<Array<Scalars['String']>>;
  art_style?: InputMaybe<Array<Scalars['String']>>;
  body_type?: InputMaybe<Array<Scalars['String']>>;
  bottom?: InputMaybe<Array<Scalars['String']>>;
  color?: InputMaybe<Array<Scalars['String']>>;
  color_range?: InputMaybe<Array<Scalars['String']>>;
  defects?: InputMaybe<Array<Scalars['String']>>;
  extra?: InputMaybe<Array<Scalars['String']>>;
  for_body?: InputMaybe<Array<Scalars['String']>>;
  hairstyle?: InputMaybe<Array<Scalars['String']>>;
  inside?: InputMaybe<Array<Scalars['String']>>;
  item?: InputMaybe<Array<Scalars['String']>>;
  items?: InputMaybe<Array<Scalars['String']>>;
  length?: InputMaybe<Array<Scalars['String']>>;
  material?: InputMaybe<Array<Scalars['String']>>;
  mood?: InputMaybe<Array<Scalars['String']>>;
  not_human?: InputMaybe<Array<Scalars['String']>>;
  nudity?: InputMaybe<Array<Scalars['String']>>;
  outside?: InputMaybe<Array<Scalars['String']>>;
  owner?: InputMaybe<Array<Scalars['String']>>;
  rooms?: InputMaybe<Array<Scalars['String']>>;
  sex?: InputMaybe<Array<Scalars['String']>>;
  skintone?: InputMaybe<Array<Scalars['String']>>;
  structure?: InputMaybe<Array<Scalars['String']>>;
  style?: InputMaybe<Array<Scalars['String']>>;
  time?: InputMaybe<Array<Scalars['String']>>;
  top?: InputMaybe<Array<Scalars['String']>>;
};

export type KeywordsClothObject = {
  accessories?: Maybe<Array<Scalars['String']>>;
  adjective?: Maybe<Array<Scalars['String']>>;
  age?: Maybe<Array<Scalars['String']>>;
  app?: Maybe<Array<Scalars['String']>>;
  art_style?: Maybe<Array<Scalars['String']>>;
  body_type?: Maybe<Array<Scalars['String']>>;
  bottom?: Maybe<Array<Scalars['String']>>;
  color?: Maybe<Array<Scalars['String']>>;
  color_range?: Maybe<Array<Scalars['String']>>;
  defects?: Maybe<Array<Scalars['String']>>;
  extra?: Maybe<Array<Scalars['String']>>;
  for_body?: Maybe<Array<Scalars['String']>>;
  hairstyle?: Maybe<Array<Scalars['String']>>;
  inside?: Maybe<Array<Scalars['String']>>;
  item?: Maybe<Array<Scalars['String']>>;
  items?: Maybe<Array<Scalars['String']>>;
  length?: Maybe<Array<Scalars['String']>>;
  material?: Maybe<Array<Scalars['String']>>;
  mood?: Maybe<Array<Scalars['String']>>;
  not_human?: Maybe<Array<Scalars['String']>>;
  nudity?: Maybe<Array<Scalars['String']>>;
  outside?: Maybe<Array<Scalars['String']>>;
  owner?: Maybe<Array<Scalars['String']>>;
  rooms?: Maybe<Array<Scalars['String']>>;
  sex?: Maybe<Array<Scalars['String']>>;
  skintone?: Maybe<Array<Scalars['String']>>;
  structure?: Maybe<Array<Scalars['String']>>;
  style?: Maybe<Array<Scalars['String']>>;
  time?: Maybe<Array<Scalars['String']>>;
  top?: Maybe<Array<Scalars['String']>>;
};

export type KeywordsHairInput = {
  accessories?: InputMaybe<Array<Scalars['String']>>;
  adjective?: InputMaybe<Array<Scalars['String']>>;
  age?: InputMaybe<Array<Scalars['String']>>;
  app?: InputMaybe<Array<Scalars['String']>>;
  art_style?: InputMaybe<Array<Scalars['String']>>;
  body_type?: InputMaybe<Array<Scalars['String']>>;
  bottom?: InputMaybe<Array<Scalars['String']>>;
  color?: InputMaybe<Array<Scalars['String']>>;
  color_range?: InputMaybe<Array<Scalars['String']>>;
  defects?: InputMaybe<Array<Scalars['String']>>;
  extra?: InputMaybe<Array<Scalars['String']>>;
  for_body?: InputMaybe<Array<Scalars['String']>>;
  hairstyle?: InputMaybe<Array<Scalars['String']>>;
  inside?: InputMaybe<Array<Scalars['String']>>;
  item?: InputMaybe<Array<Scalars['String']>>;
  items?: InputMaybe<Array<Scalars['String']>>;
  length?: InputMaybe<Array<Scalars['String']>>;
  material?: InputMaybe<Array<Scalars['String']>>;
  mood?: InputMaybe<Array<Scalars['String']>>;
  not_human?: InputMaybe<Array<Scalars['String']>>;
  nudity?: InputMaybe<Array<Scalars['String']>>;
  outside?: InputMaybe<Array<Scalars['String']>>;
  owner?: InputMaybe<Array<Scalars['String']>>;
  rooms?: InputMaybe<Array<Scalars['String']>>;
  sex?: InputMaybe<Array<Scalars['String']>>;
  skintone?: InputMaybe<Array<Scalars['String']>>;
  structure?: InputMaybe<Array<Scalars['String']>>;
  style?: InputMaybe<Array<Scalars['String']>>;
  time?: InputMaybe<Array<Scalars['String']>>;
  top?: InputMaybe<Array<Scalars['String']>>;
};

export type KeywordsHairObject = {
  accessories?: Maybe<Array<Scalars['String']>>;
  adjective?: Maybe<Array<Scalars['String']>>;
  age?: Maybe<Array<Scalars['String']>>;
  app?: Maybe<Array<Scalars['String']>>;
  art_style?: Maybe<Array<Scalars['String']>>;
  body_type?: Maybe<Array<Scalars['String']>>;
  bottom?: Maybe<Array<Scalars['String']>>;
  color?: Maybe<Array<Scalars['String']>>;
  color_range?: Maybe<Array<Scalars['String']>>;
  defects?: Maybe<Array<Scalars['String']>>;
  extra?: Maybe<Array<Scalars['String']>>;
  for_body?: Maybe<Array<Scalars['String']>>;
  hairstyle?: Maybe<Array<Scalars['String']>>;
  inside?: Maybe<Array<Scalars['String']>>;
  item?: Maybe<Array<Scalars['String']>>;
  items?: Maybe<Array<Scalars['String']>>;
  length?: Maybe<Array<Scalars['String']>>;
  material?: Maybe<Array<Scalars['String']>>;
  mood?: Maybe<Array<Scalars['String']>>;
  not_human?: Maybe<Array<Scalars['String']>>;
  nudity?: Maybe<Array<Scalars['String']>>;
  outside?: Maybe<Array<Scalars['String']>>;
  owner?: Maybe<Array<Scalars['String']>>;
  rooms?: Maybe<Array<Scalars['String']>>;
  sex?: Maybe<Array<Scalars['String']>>;
  skintone?: Maybe<Array<Scalars['String']>>;
  structure?: Maybe<Array<Scalars['String']>>;
  style?: Maybe<Array<Scalars['String']>>;
  time?: Maybe<Array<Scalars['String']>>;
  top?: Maybe<Array<Scalars['String']>>;
};

export type KeywordsItemInput = {
  accessories?: InputMaybe<Array<Scalars['String']>>;
  adjective?: InputMaybe<Array<Scalars['String']>>;
  age?: InputMaybe<Array<Scalars['String']>>;
  app?: InputMaybe<Array<Scalars['String']>>;
  art_style?: InputMaybe<Array<Scalars['String']>>;
  body_type?: InputMaybe<Array<Scalars['String']>>;
  bottom?: InputMaybe<Array<Scalars['String']>>;
  color?: InputMaybe<Array<Scalars['String']>>;
  color_range?: InputMaybe<Array<Scalars['String']>>;
  defects?: InputMaybe<Array<Scalars['String']>>;
  extra?: InputMaybe<Array<Scalars['String']>>;
  for_body?: InputMaybe<Array<Scalars['String']>>;
  hairstyle?: InputMaybe<Array<Scalars['String']>>;
  inside?: InputMaybe<Array<Scalars['String']>>;
  item?: InputMaybe<Array<Scalars['String']>>;
  items?: InputMaybe<Array<Scalars['String']>>;
  length?: InputMaybe<Array<Scalars['String']>>;
  material?: InputMaybe<Array<Scalars['String']>>;
  mood?: InputMaybe<Array<Scalars['String']>>;
  not_human?: InputMaybe<Array<Scalars['String']>>;
  nudity?: InputMaybe<Array<Scalars['String']>>;
  outside?: InputMaybe<Array<Scalars['String']>>;
  owner?: InputMaybe<Array<Scalars['String']>>;
  rooms?: InputMaybe<Array<Scalars['String']>>;
  sex?: InputMaybe<Array<Scalars['String']>>;
  skintone?: InputMaybe<Array<Scalars['String']>>;
  structure?: InputMaybe<Array<Scalars['String']>>;
  style?: InputMaybe<Array<Scalars['String']>>;
  time?: InputMaybe<Array<Scalars['String']>>;
  top?: InputMaybe<Array<Scalars['String']>>;
};

export type KeywordsItemObject = {
  accessories?: Maybe<Array<Scalars['String']>>;
  adjective?: Maybe<Array<Scalars['String']>>;
  age?: Maybe<Array<Scalars['String']>>;
  app?: Maybe<Array<Scalars['String']>>;
  art_style?: Maybe<Array<Scalars['String']>>;
  body_type?: Maybe<Array<Scalars['String']>>;
  bottom?: Maybe<Array<Scalars['String']>>;
  color?: Maybe<Array<Scalars['String']>>;
  color_range?: Maybe<Array<Scalars['String']>>;
  defects?: Maybe<Array<Scalars['String']>>;
  extra?: Maybe<Array<Scalars['String']>>;
  for_body?: Maybe<Array<Scalars['String']>>;
  hairstyle?: Maybe<Array<Scalars['String']>>;
  inside?: Maybe<Array<Scalars['String']>>;
  item?: Maybe<Array<Scalars['String']>>;
  items?: Maybe<Array<Scalars['String']>>;
  length?: Maybe<Array<Scalars['String']>>;
  material?: Maybe<Array<Scalars['String']>>;
  mood?: Maybe<Array<Scalars['String']>>;
  not_human?: Maybe<Array<Scalars['String']>>;
  nudity?: Maybe<Array<Scalars['String']>>;
  outside?: Maybe<Array<Scalars['String']>>;
  owner?: Maybe<Array<Scalars['String']>>;
  rooms?: Maybe<Array<Scalars['String']>>;
  sex?: Maybe<Array<Scalars['String']>>;
  skintone?: Maybe<Array<Scalars['String']>>;
  structure?: Maybe<Array<Scalars['String']>>;
  style?: Maybe<Array<Scalars['String']>>;
  time?: Maybe<Array<Scalars['String']>>;
  top?: Maybe<Array<Scalars['String']>>;
};

export type KeywordsLayerInput = {
  accessories?: InputMaybe<Array<Scalars['String']>>;
  adjective?: InputMaybe<Array<Scalars['String']>>;
  age?: InputMaybe<Array<Scalars['String']>>;
  app?: InputMaybe<Array<Scalars['String']>>;
  art_style?: InputMaybe<Array<Scalars['String']>>;
  body_type?: InputMaybe<Array<Scalars['String']>>;
  bottom?: InputMaybe<Array<Scalars['String']>>;
  color?: InputMaybe<Array<Scalars['String']>>;
  color_range?: InputMaybe<Array<Scalars['String']>>;
  defects?: InputMaybe<Array<Scalars['String']>>;
  extra?: InputMaybe<Array<Scalars['String']>>;
  for_body?: InputMaybe<Array<Scalars['String']>>;
  hairstyle?: InputMaybe<Array<Scalars['String']>>;
  inside?: InputMaybe<Array<Scalars['String']>>;
  item?: InputMaybe<Array<Scalars['String']>>;
  items?: InputMaybe<Array<Scalars['String']>>;
  length?: InputMaybe<Array<Scalars['String']>>;
  material?: InputMaybe<Array<Scalars['String']>>;
  mood?: InputMaybe<Array<Scalars['String']>>;
  not_human?: InputMaybe<Array<Scalars['String']>>;
  nudity?: InputMaybe<Array<Scalars['String']>>;
  outside?: InputMaybe<Array<Scalars['String']>>;
  owner?: InputMaybe<Array<Scalars['String']>>;
  rooms?: InputMaybe<Array<Scalars['String']>>;
  sex?: InputMaybe<Array<Scalars['String']>>;
  skintone?: InputMaybe<Array<Scalars['String']>>;
  structure?: InputMaybe<Array<Scalars['String']>>;
  style?: InputMaybe<Array<Scalars['String']>>;
  time?: InputMaybe<Array<Scalars['String']>>;
  top?: InputMaybe<Array<Scalars['String']>>;
};

export type KeywordsLayerObject = {
  accessories?: Maybe<Array<Scalars['String']>>;
  adjective?: Maybe<Array<Scalars['String']>>;
  age?: Maybe<Array<Scalars['String']>>;
  app?: Maybe<Array<Scalars['String']>>;
  art_style?: Maybe<Array<Scalars['String']>>;
  body_type?: Maybe<Array<Scalars['String']>>;
  bottom?: Maybe<Array<Scalars['String']>>;
  color?: Maybe<Array<Scalars['String']>>;
  color_range?: Maybe<Array<Scalars['String']>>;
  defects?: Maybe<Array<Scalars['String']>>;
  extra?: Maybe<Array<Scalars['String']>>;
  for_body?: Maybe<Array<Scalars['String']>>;
  hairstyle?: Maybe<Array<Scalars['String']>>;
  inside?: Maybe<Array<Scalars['String']>>;
  item?: Maybe<Array<Scalars['String']>>;
  items?: Maybe<Array<Scalars['String']>>;
  length?: Maybe<Array<Scalars['String']>>;
  material?: Maybe<Array<Scalars['String']>>;
  mood?: Maybe<Array<Scalars['String']>>;
  not_human?: Maybe<Array<Scalars['String']>>;
  nudity?: Maybe<Array<Scalars['String']>>;
  outside?: Maybe<Array<Scalars['String']>>;
  owner?: Maybe<Array<Scalars['String']>>;
  rooms?: Maybe<Array<Scalars['String']>>;
  sex?: Maybe<Array<Scalars['String']>>;
  skintone?: Maybe<Array<Scalars['String']>>;
  structure?: Maybe<Array<Scalars['String']>>;
  style?: Maybe<Array<Scalars['String']>>;
  time?: Maybe<Array<Scalars['String']>>;
  top?: Maybe<Array<Scalars['String']>>;
};

export type LastUpdatedDocBy = {
  name?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
};

export type LastUpdatedDocResponse = {
  isUpdated: Scalars['Boolean'];
  modifiedBy: LastUpdatedDocBy;
  modifiedTime?: Maybe<Scalars['String']>;
};

export type Layer = {
  atName?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  dropboxLink?: Maybe<Scalars['String']>;
  heroes?: Maybe<Array<Hero>>;
  id: Scalars['String'];
  keywords: Array<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  newKeywords?: Maybe<KeywordsLayerObject>;
  position?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Float']>;
  type: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type LayerDetailsDocInput = {
  body_type?: InputMaybe<Scalars['String']>;
  copyAssetIds?: InputMaybe<Array<Scalars['String']>>;
  copyFromAssetId?: InputMaybe<Scalars['String']>;
  customID?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  isCopied?: InputMaybe<Scalars['Boolean']>;
  needTask?: InputMaybe<Scalars['Boolean']>;
  script_name?: InputMaybe<Scalars['String']>;
  sex?: InputMaybe<Scalars['String']>;
};

export type LayerDetailsDocObject = {
  body_type?: Maybe<Scalars['String']>;
  copyAssetIds?: Maybe<Array<Scalars['String']>>;
  copyFromAssetId?: Maybe<Scalars['String']>;
  customID?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isCopied?: Maybe<Scalars['Boolean']>;
  needTask?: Maybe<Scalars['Boolean']>;
  script_name?: Maybe<Scalars['String']>;
  sex?: Maybe<Scalars['String']>;
};

export type LayersFilterArgDto = {
  bookId?: InputMaybe<Scalars['String']>;
  keywords?: InputMaybe<Array<Scalars['String']>>;
  name?: InputMaybe<Scalars['String']>;
};

export type Mutation = {
  addAssetToArtDoc: ArtDoc;
  addAssetToHero: Array<Scalars['String']>;
  addBackgroundsToChapter: Scalars['Boolean'];
  addItemsToChapter: Scalars['Boolean'];
  backfillRems: Scalars['String'];
  changeBackgroundAssigns: Scalars['Boolean'];
  changeItemAssigns: Scalars['Boolean'];
  convertScript: ConvertResult;
  convertScriptOld: ConvertResult;
  copyDriveItemById: Scalars['String'];
  createArtDoc: ArtDoc;
  createAudio: Audio;
  createBackground: Background;
  createBody: Body;
  createBook: Book;
  createBuildTemplate: BuildTemplate;
  createCard: Card;
  createChapter: Chapter;
  createChapterDocByConfig: Chapter;
  createChat: Chat;
  createChatHero: ChatHero;
  createChatPhoto: ChatPhoto;
  createClothes: Clothes;
  createDocsNotes: DocsNotes;
  createGoogleSheets: ArtDoc;
  createHair: Hair;
  createHero: Hero;
  createItem: Item;
  createJob: Build;
  createLayer: Layer;
  createStoryDocByConfig: Book;
  createTask: Scalars['String'];
  createUser: User;
  delete: Scalars['String'];
  deleteAudio: Scalars['String'];
  deleteBackground: Background;
  deleteBody: Body;
  deleteBook: Scalars['String'];
  deleteBuildTemplate: Scalars['String'];
  deleteCard: Scalars['String'];
  deleteChapter: Scalars['String'];
  deleteChat: Scalars['String'];
  deleteChatPhoto: Scalars['String'];
  deleteClothes: Clothes;
  deleteDocsNotes: Scalars['String'];
  deleteHair: Hair;
  deleteHero: Scalars['String'];
  deleteItem: Item;
  deleteLayer: Layer;
  removeAdditionalDoc: Scalars['String'];
  removeAssetFromArtDoc: ArtDoc;
  removeAssetFromHero: ArtDoc;
  removeBackgroundsFromChapter: Scalars['Boolean'];
  removeItemsFromChapter: Scalars['Boolean'];
  renameFile: Scalars['String'];
  sendMessageByArtDoc: Scalars['Boolean'];
  startJob: Build;
  syncAllDraftDocsIntoDB: Scalars['Boolean'];
  syncDraftDocContentIntoDB: GetDocResponseDto;
  synchronizeGoogleSheets: ArtDoc;
  transferToBucket: Scalars['String'];
  unlinkAltBackground: Scalars['String'];
  unlinkAsset: Scalars['String'];
  updateAdditionalDoc: AdditionalDoc;
  updateArtDoc: ArtDoc;
  updateAudio: Audio;
  updateBackground: Background;
  updateBody: Body;
  updateBook: Book;
  updateBuildTemplate: BuildTemplate;
  updateCard: Card;
  updateChapter: Chapter;
  updateChapterDoc: Chapter;
  updateChat: Chat;
  updateChatHero: ChatHero;
  updateChatPhoto: ChatPhoto;
  updateClothes: Clothes;
  updateDocVersion: Scalars['String'];
  updateDocsNotes: DocsNotes;
  updateDraftDocContent?: Maybe<Scalars['DateTime']>;
  updateEmotions: EmotionsInfo;
  updateGoogleDocContent: Scalars['Boolean'];
  updateHair: Hair;
  updateHero: Hero;
  updateItem: Item;
  updateLayer: Layer;
  updateUser: User;
  uploadFileToFolder: Scalars['String'];
  uploadFilesToFolder: Array<Scalars['String']>;
};


export type MutationAddAssetToArtDocArgs = {
  artDoc: UpdateArtDocRequestDto;
};


export type MutationAddAssetToHeroArgs = {
  artDocAsset: AddAssetToHeroDto;
};


export type MutationAddBackgroundsToChapterArgs = {
  chapter: AddBackgroundsToChapterDto;
};


export type MutationAddItemsToChapterArgs = {
  chapter: AddItemsToChapterDto;
};


export type MutationChangeBackgroundAssignsArgs = {
  chapter: ChangeBackgroundsAssignsDto;
};


export type MutationChangeItemAssignsArgs = {
  chapter: ChangeItemAssignsDto;
};


export type MutationConvertScriptArgs = {
  dto: ConvertScriptDto;
};


export type MutationConvertScriptOldArgs = {
  dto: ConvertScriptOldDto;
};


export type MutationCopyDriveItemByIdArgs = {
  documentId: Scalars['String'];
  newFileName: Scalars['String'];
  parentId: Scalars['String'];
};


export type MutationCreateArtDocArgs = {
  artDoc: CreateArtDocRequestDto;
};


export type MutationCreateAudioArgs = {
  audio: CreateAudioRequestDto;
};


export type MutationCreateBackgroundArgs = {
  background: CreateBackgroundRequestDto;
};


export type MutationCreateBodyArgs = {
  body: CreateBodyRequestDto;
};


export type MutationCreateBookArgs = {
  book: CreateBookRequestDto;
};


export type MutationCreateBuildTemplateArgs = {
  dto: CreateTemplateDto;
};


export type MutationCreateCardArgs = {
  card: CreateCardRequestDto;
};


export type MutationCreateChapterArgs = {
  chapter: CreateChapterRequestDto;
};


export type MutationCreateChapterDocByConfigArgs = {
  bookId: Scalars['String'];
  chapterDocConfig: CreateChapterDocByConfigRequestDto;
  chapterId: Scalars['String'];
};


export type MutationCreateChatArgs = {
  chat: CreateChatRequestDto;
};


export type MutationCreateChatHeroArgs = {
  chatHero: CreateChatHeroRequestDto;
};


export type MutationCreateChatPhotoArgs = {
  chatPhoto: CreateChatPhotoRequestDto;
};


export type MutationCreateClothesArgs = {
  clothes: CreateClothesRequestDto;
};


export type MutationCreateDocsNotesArgs = {
  docId: Scalars['String'];
  docsNotes: CreateDocsNotesRequestDto;
};


export type MutationCreateGoogleSheetsArgs = {
  bookID: Scalars['String'];
  chapterID: Scalars['String'];
};


export type MutationCreateHairArgs = {
  hair: CreateHairsRequestDto;
};


export type MutationCreateHeroArgs = {
  hero: CreateHeroRequestDto;
};


export type MutationCreateItemArgs = {
  item: CreateItemRequestDto;
};


export type MutationCreateJobArgs = {
  job: CreateJobDto;
};


export type MutationCreateLayerArgs = {
  layer: CreateLayerRequestDto;
};


export type MutationCreateStoryDocByConfigArgs = {
  bookId: Scalars['String'];
  type: Scalars['String'];
};


export type MutationCreateTaskArgs = {
  bookID: Scalars['String'];
  chapterID: Scalars['String'];
};


export type MutationCreateUserArgs = {
  user: CreateUserRequestDto;
};


export type MutationDeleteArgs = {
  id: Scalars['String'];
};


export type MutationDeleteAudioArgs = {
  id: Scalars['String'];
};


export type MutationDeleteBackgroundArgs = {
  id: Scalars['String'];
};


export type MutationDeleteBodyArgs = {
  id: Scalars['String'];
};


export type MutationDeleteBookArgs = {
  id: Scalars['String'];
  isDeleteGoogleDrive: Scalars['Boolean'];
};


export type MutationDeleteBuildTemplateArgs = {
  dto: DeleteTemplateDto;
};


export type MutationDeleteCardArgs = {
  id: Scalars['String'];
};


export type MutationDeleteChapterArgs = {
  id: Scalars['String'];
  isDeleteGoogleDrive: Scalars['Boolean'];
};


export type MutationDeleteChatArgs = {
  id: Scalars['String'];
};


export type MutationDeleteChatPhotoArgs = {
  id: Scalars['String'];
};


export type MutationDeleteClothesArgs = {
  id: Scalars['String'];
};


export type MutationDeleteDocsNotesArgs = {
  id: Scalars['String'];
};


export type MutationDeleteHairArgs = {
  id: Scalars['String'];
};


export type MutationDeleteHeroArgs = {
  heroId: Scalars['String'];
};


export type MutationDeleteItemArgs = {
  id: Scalars['String'];
};


export type MutationDeleteLayerArgs = {
  id: Scalars['String'];
};


export type MutationRemoveAdditionalDocArgs = {
  documentId: Scalars['String'];
};


export type MutationRemoveAssetFromArtDocArgs = {
  assetID: Scalars['String'];
  assetType: Scalars['String'];
  chapterID: Scalars['String'];
};


export type MutationRemoveAssetFromHeroArgs = {
  artDocAsset: RemoveAssetFromHeroDto;
};


export type MutationRemoveBackgroundsFromChapterArgs = {
  chapter: AddBackgroundsToChapterDto;
};


export type MutationRemoveItemsFromChapterArgs = {
  chapter: AddItemsToChapterDto;
};


export type MutationRenameFileArgs = {
  folder: Scalars['String'];
  link: Scalars['String'];
  name: Scalars['String'];
};


export type MutationSendMessageByArtDocArgs = {
  bookName: Scalars['String'];
  chapterID: Scalars['String'];
  chapterOrder: Scalars['String'];
  linkToBook: Scalars['String'];
};


export type MutationStartJobArgs = {
  id: Scalars['String'];
};


export type MutationSyncDraftDocContentIntoDbArgs = {
  documentId: Scalars['String'];
};


export type MutationSynchronizeGoogleSheetsArgs = {
  bookID: Scalars['String'];
};


export type MutationTransferToBucketArgs = {
  dto: TransferToBucketDto;
};


export type MutationUnlinkAltBackgroundArgs = {
  id: Scalars['String'];
  parentId?: InputMaybe<Scalars['String']>;
};


export type MutationUnlinkAssetArgs = {
  id: Scalars['String'];
  parentId?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateAdditionalDocArgs = {
  documentId: Scalars['String'];
};


export type MutationUpdateArtDocArgs = {
  artDoc: UpdateArtDocRequestDto;
};


export type MutationUpdateAudioArgs = {
  audio: UpdateAudioRequestDto;
};


export type MutationUpdateBackgroundArgs = {
  background: UpdateBackgroundRequestDto;
};


export type MutationUpdateBodyArgs = {
  body: UpdateBodyRequestDto;
};


export type MutationUpdateBookArgs = {
  book: UpdateBookRequestDto;
};


export type MutationUpdateBuildTemplateArgs = {
  dto: UpdateTemplateDto;
};


export type MutationUpdateCardArgs = {
  card: UpdateCardRequestDto;
};


export type MutationUpdateChapterArgs = {
  chapter: UpdateChapterRequestDto;
};


export type MutationUpdateChapterDocArgs = {
  chapter: UpdateChapterRequestDto;
};


export type MutationUpdateChatArgs = {
  chat: UpdateChatRequestDto;
};


export type MutationUpdateChatHeroArgs = {
  chatHero: UpdateChatHeroRequestDto;
};


export type MutationUpdateChatPhotoArgs = {
  chatPhoto: UpdateChatPhotoRequestDto;
};


export type MutationUpdateClothesArgs = {
  clothes: UpdateClothesRequestDto;
};


export type MutationUpdateDocVersionArgs = {
  chapterId: Scalars['String'];
  file: Scalars['Upload'];
  type: Scalars['String'];
};


export type MutationUpdateDocsNotesArgs = {
  docsNotes: UpdateDocsNotesRequestDto;
};


export type MutationUpdateDraftDocContentArgs = {
  documentId: Scalars['String'];
  googleDoc: InsertDraftDocDataDto;
  lang: Scalars['String'];
  updateGoogleDoc: Scalars['Boolean'];
};


export type MutationUpdateEmotionsArgs = {
  emotions: UpdateEmotionsRequestDto;
  id: Scalars['String'];
};


export type MutationUpdateGoogleDocContentArgs = {
  documentId: Scalars['String'];
  googleDoc: InsertGoogleDocDataDto;
};


export type MutationUpdateHairArgs = {
  hair: UpdateHairsRequestDto;
};


export type MutationUpdateHeroArgs = {
  hero: UpdateHeroRequestDto;
};


export type MutationUpdateItemArgs = {
  item: UpdateItemRequestDto;
};


export type MutationUpdateLayerArgs = {
  layer: UpdateLayerRequestDto;
};


export type MutationUpdateUserArgs = {
  user: UpdateUserRequestDto;
};


export type MutationUploadFileToFolderArgs = {
  file: Scalars['Upload'];
  filename?: InputMaybe<Scalars['String']>;
  folder: Scalars['String'];
  isRequireUuid?: InputMaybe<Scalars['Boolean']>;
};


export type MutationUploadFilesToFolderArgs = {
  files: Array<Scalars['Upload']>;
  folder: Scalars['String'];
};

export type OutfitDetailsDocInput = {
  body_type?: InputMaybe<Scalars['String']>;
  copyAssetIds?: InputMaybe<Array<Scalars['String']>>;
  copyFromAssetId?: InputMaybe<Scalars['String']>;
  cost?: InputMaybe<Scalars['String']>;
  customID?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  isCopied?: InputMaybe<Scalars['Boolean']>;
  needTask?: InputMaybe<Scalars['Boolean']>;
  script_name?: InputMaybe<Scalars['String']>;
  sex?: InputMaybe<Scalars['String']>;
};

export type OutfitDetailsDocObject = {
  body_type?: Maybe<Scalars['String']>;
  copyAssetIds?: Maybe<Array<Scalars['String']>>;
  copyFromAssetId?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['String']>;
  customID?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isCopied?: Maybe<Scalars['Boolean']>;
  needTask?: Maybe<Scalars['Boolean']>;
  script_name?: Maybe<Scalars['String']>;
  sex?: Maybe<Scalars['String']>;
};

export type Paragraph = {
  elements: Array<Element>;
};

export enum Permission {
  BundlerAdmin = 'BundlerAdmin',
  BundlerUser = 'BundlerUser',
  DeleteAssets = 'DeleteAssets',
  DeleteBooks = 'DeleteBooks',
  DeleteChapters = 'DeleteChapters',
  DeleteChatHeroes = 'DeleteChatHeroes',
  DeleteChats = 'DeleteChats',
  DeleteHeroes = 'DeleteHeroes',
  EditDocs = 'EditDocs',
  EditLocDocs = 'EditLocDocs',
  ManageArtTasks = 'ManageArtTasks',
  ManageBooks = 'ManageBooks',
  ManageUsers = 'ManageUsers'
}

export type PlatformsQueryResponseDto = {
  name: Scalars['String'];
};

export type ProdBuildResponse = {
  isRunning: Scalars['Boolean'];
  remainingTime: Scalars['Int'];
};

export type Query = {
  allUsers: Array<User>;
  artDoc: ArtDoc;
  artDocByChapterID: ArtDoc;
  asset: Asset;
  assets: AssetResponseDto;
  audio: Audio;
  audios: FindAudiosResponseDto;
  background: Background;
  backgrounds: FindBackgroundsResponseDto;
  bodies: FindBodiesResponseDto;
  body: Body;
  book: Book;
  bookInfo: BookInfo;
  books: Array<Book>;
  booksByChapters: Array<Book>;
  branches: Array<BranchesQueryResponseDto>;
  buckets: Array<Bucket>;
  build: Build;
  buildTemplate: BuildTemplate;
  buildTemplates: Array<BuildTemplate>;
  builds: Array<Build>;
  card: Card;
  cards: FindCardsResponseDto;
  chapter: Chapter;
  chapters: Array<Chapter>;
  chat: Chat;
  chatHero: ChatHero;
  chatHeroes: Array<ChatHero>;
  chatPhoto: ChatPhoto;
  chatPhotos: FindChatPhotosResponseDto;
  chats: Array<Chat>;
  clothes: FindClothesResponseDto;
  createRequest: AskAiResponseDto;
  currentBuild?: Maybe<Build>;
  docNotes?: Maybe<DocsNotes>;
  filters: Array<Scalars['String']>;
  findAllByBookId: Array<ArtDoc>;
  findOneClothes: Clothes;
  getDraftDocContent: GetDocResponseDto;
  getGoogleDocContent: DocumentElement;
  getIsDraftDocContentUpdated: LastUpdatedDocResponse;
  getIsFileAlreadyExistInParent: Scalars['Boolean'];
  getUserId: UserId;
  getUserPermissions: UserPermissions;
  getValidationAccess?: Maybe<Scalars['Boolean']>;
  hair: Hair;
  hairs: FindHairsResponseDto;
  hero: Hero;
  heroByAssetId: Array<Hero>;
  heroes: Array<Hero>;
  heroesKeywords: Array<Scalars['String']>;
  item: Item;
  items: FindItemsResponseDto;
  keywords: Array<Scalars['String']>;
  layer: Layer;
  layers: FindLayersResponseDto;
  layersByIds: Array<Layer>;
  ongoingBooks: Array<Book>;
  platforms: Array<PlatformsQueryResponseDto>;
  prodBuild: ProdBuildResponse;
  refreshToken: RefreshTokenResponse;
  rems: Array<Rem>;
  revokeToken: Scalars['Boolean'];
  transfers: Array<BucketTransfer>;
  user: User;
};


export type QueryArtDocArgs = {
  id: Scalars['String'];
};


export type QueryArtDocByChapterIdArgs = {
  chapterID: Scalars['String'];
};


export type QueryAssetArgs = {
  id: Scalars['String'];
  type: Scalars['String'];
};


export type QueryAssetsArgs = {
  filter?: InputMaybe<AssetsFilterArgDto>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<AssetsSortArgDto>;
};


export type QueryAudioArgs = {
  id: Scalars['String'];
};


export type QueryAudiosArgs = {
  filter?: InputMaybe<AudiosFilterArgDto>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryBackgroundArgs = {
  id: Scalars['String'];
};


export type QueryBackgroundsArgs = {
  filter?: InputMaybe<BackgroundsFilterArgDto>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<BackgroundsSortArgDto>;
};


export type QueryBodiesArgs = {
  filter?: InputMaybe<BodiesFilterArgDto>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryBodyArgs = {
  id: Scalars['String'];
};


export type QueryBookArgs = {
  id: Scalars['String'];
};


export type QueryBookInfoArgs = {
  id: Scalars['String'];
};


export type QueryBooksArgs = {
  filter?: InputMaybe<BooksFilterArgDto>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  skipChaptersLoader?: InputMaybe<Scalars['Boolean']>;
  sort?: InputMaybe<BooksSortArgDto>;
};


export type QueryBooksByChaptersArgs = {
  chaptersIds: Array<Scalars['String']>;
  skipChaptersLoader?: InputMaybe<Scalars['Boolean']>;
};


export type QueryBuildArgs = {
  id: Scalars['String'];
};


export type QueryBuildTemplateArgs = {
  id: Scalars['String'];
};


export type QueryCardArgs = {
  id: Scalars['String'];
};


export type QueryCardsArgs = {
  filter?: InputMaybe<CardsFilterArgDto>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryChapterArgs = {
  id: Scalars['String'];
};


export type QueryChaptersArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryChatArgs = {
  id: Scalars['String'];
};


export type QueryChatHeroArgs = {
  id: Scalars['String'];
};


export type QueryChatHeroesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryChatPhotoArgs = {
  id: Scalars['String'];
};


export type QueryChatPhotosArgs = {
  filter?: InputMaybe<ChatPhotosFilterArgDto>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryChatsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryClothesArgs = {
  filter?: InputMaybe<ClothesFilterArgDto>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryCreateRequestArgs = {
  prompt: Scalars['String'];
};


export type QueryDocNotesArgs = {
  id: Scalars['String'];
};


export type QueryFindAllByBookIdArgs = {
  bookID: Scalars['String'];
};


export type QueryFindOneClothesArgs = {
  id: Scalars['String'];
};


export type QueryGetDraftDocContentArgs = {
  documentId: Scalars['String'];
};


export type QueryGetGoogleDocContentArgs = {
  documentId: Scalars['String'];
};


export type QueryGetIsDraftDocContentUpdatedArgs = {
  documentId: Scalars['String'];
};


export type QueryGetIsFileAlreadyExistInParentArgs = {
  documentId: Scalars['String'];
  parentId: Scalars['String'];
};


export type QueryGetValidationAccessArgs = {
  fileId: Scalars['String'];
  isValidateContentAccount?: InputMaybe<Scalars['Boolean']>;
};


export type QueryHairArgs = {
  id: Scalars['String'];
};


export type QueryHairsArgs = {
  filter?: InputMaybe<HairsFilterArgDto>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryHeroArgs = {
  id: Scalars['String'];
};


export type QueryHeroByAssetIdArgs = {
  id: Scalars['String'];
};


export type QueryHeroesArgs = {
  filter?: InputMaybe<HeroesFilterArgDto>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<HeroesSortArgDto>;
};


export type QueryItemArgs = {
  id: Scalars['String'];
};


export type QueryItemsArgs = {
  filter?: InputMaybe<ItemsFilterArgDto>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryKeywordsArgs = {
  type?: InputMaybe<Scalars['String']>;
};


export type QueryLayerArgs = {
  id: Scalars['String'];
};


export type QueryLayersArgs = {
  filter?: InputMaybe<LayersFilterArgDto>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryLayersByIdsArgs = {
  layersIds: Array<Scalars['String']>;
};


export type QueryRefreshTokenArgs = {
  refreshToken: Scalars['String'];
};


export type QueryRemsArgs = {
  bookId: Scalars['String'];
  chapterOrder: Scalars['Float'];
  docType: Scalars['String'];
  lang: Scalars['String'];
};


export type QueryRevokeTokenArgs = {
  access: Scalars['String'];
  refresh: Scalars['String'];
};

export type RgbColor = {
  blue?: Maybe<Scalars['Float']>;
  green?: Maybe<Scalars['Float']>;
  red?: Maybe<Scalars['Float']>;
};

export type RawDraftCommentData = {
  author: Scalars['String'];
  comment: Scalars['String'];
  createdAt: Scalars['Float'];
  picture?: Maybe<Scalars['String']>;
  replies: Array<RawDraftReplyData>;
  resolved: Scalars['Boolean'];
};

export type RawDraftContentBlock = {
  depth: Scalars['Int'];
  entityRanges: Array<RawDraftEntityRange>;
  inlineStyleRanges: Array<RawDraftInlineStyleRange>;
  key?: Maybe<Scalars['String']>;
  text: Scalars['String'];
  type: Scalars['String'];
};

export type RawDraftEntity = {
  entity: RawDraftEntityBlock;
  key: Scalars['String'];
};

export type RawDraftEntityBlock = {
  data?: Maybe<RawDraftCommentData>;
  mutability: Scalars['String'];
  type: Scalars['String'];
};

export type RawDraftEntityRange = {
  key: Scalars['Int'];
  length: Scalars['Int'];
  offset: Scalars['Int'];
};

export type RawDraftInlineStyleRange = {
  length: Scalars['Int'];
  offset: Scalars['Int'];
  style: Scalars['String'];
};

export type RawDraftReplyData = {
  author: Scalars['String'];
  createdAt: Scalars['Float'];
  id: Scalars['String'];
  picture?: Maybe<Scalars['String']>;
  reply: Scalars['String'];
  resolved: Scalars['Boolean'];
};

export type RefreshTokenResponse = {
  accessToken: Scalars['String'];
  refreshToken: Scalars['String'];
};

export type Rem = {
  bookId: Scalars['String'];
  chapterId: Scalars['String'];
  chapterOrder: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  docType: Scalars['String'];
  id: Scalars['String'];
  lang: Scalars['String'];
  rems: Array<Array<RemsObject>>;
  updatedAt: Scalars['DateTime'];
};

export type RemoveAssetFromHeroDto = {
  assetID: Scalars['String'];
  assetType: Scalars['String'];
  chapterID: Scalars['String'];
  heroID: Scalars['String'];
};

export type RemsInput = {
  line: Scalars['Float'];
  position: Scalars['Float'];
  variable: Scalars['String'];
};

export type RemsObject = {
  line: Scalars['Float'];
  position: Scalars['Float'];
  variable: Scalars['String'];
};

export type SelectedAssetsInput = {
  selectedBodies?: InputMaybe<Array<Scalars['String']>>;
  selectedClothes?: InputMaybe<Array<Scalars['String']>>;
  selectedHairs?: InputMaybe<Array<Scalars['String']>>;
  selectedLayers?: InputMaybe<Array<Scalars['String']>>;
};

export type SelectedAssetsObject = {
  selectedBodies?: Maybe<Array<Scalars['String']>>;
  selectedClothes?: Maybe<Array<Scalars['String']>>;
  selectedHairs?: Maybe<Array<Scalars['String']>>;
  selectedLayers?: Maybe<Array<Scalars['String']>>;
};

export type Text = {
  paragraph?: Maybe<Paragraph>;
};

export type TextRun = {
  content: Scalars['String'];
  textStyle: TextStyle;
};

export type TextStyle = {
  backgroundColor?: Maybe<BackgroundColor>;
  bold?: Maybe<Scalars['Boolean']>;
  italic?: Maybe<Scalars['Boolean']>;
};

export type TransferToBucketDto = {
  createBackup: Scalars['Boolean'];
  from: Scalars['String'];
  to: Scalars['String'];
};

export type UpdateArtDocRequestDto = {
  backgrounds?: InputMaybe<Array<ArtDocBackgroundInput>>;
  bookID?: InputMaybe<Scalars['String']>;
  chapterID: Scalars['String'];
  chapterOrder?: InputMaybe<Scalars['Float']>;
  cover?: InputMaybe<ArtDocCoverInput>;
  googleSheetGID?: InputMaybe<Scalars['String']>;
  googleSheetID?: InputMaybe<Scalars['String']>;
  heroes?: InputMaybe<Array<ArtDocHeroInput>>;
  id?: InputMaybe<Scalars['String']>;
  isSyncGoogleSheet?: InputMaybe<Scalars['Boolean']>;
  items?: InputMaybe<Array<ArtDocItemInput>>;
};

export type UpdateAudioRequestDto = {
  filename?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  keywords?: InputMaybe<Array<Scalars['String']>>;
  link?: InputMaybe<Scalars['String']>;
  newKeywords?: InputMaybe<KeywordsAudioInput>;
};

export type UpdateBackgroundRequestDto = {
  alternativeBackgroundsIds?: InputMaybe<Array<Scalars['String']>>;
  chaptersIds?: InputMaybe<Array<Scalars['String']>>;
  dropboxLink?: InputMaybe<Scalars['String']>;
  filename?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  keywords?: InputMaybe<Array<Scalars['String']>>;
  link?: InputMaybe<Scalars['String']>;
  newKeywords?: InputMaybe<KeywordsBackgroundInput>;
  visionLabels?: InputMaybe<Scalars['String']>;
};

export type UpdateBodyRequestDto = {
  dropboxLink?: InputMaybe<Scalars['String']>;
  filename?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  keywords?: InputMaybe<Array<Scalars['String']>>;
  link?: InputMaybe<Scalars['String']>;
  newKeywords?: InputMaybe<KeywordsBodyInput>;
};

export type UpdateBookRequestDto = {
  app?: InputMaybe<Array<Scalars['String']>>;
  artDocument?: InputMaybe<Scalars['String']>;
  author?: InputMaybe<Scalars['String']>;
  bannerImage?: InputMaybe<Scalars['String']>;
  bookNotes?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<Scalars['String']>;
  chapters?: InputMaybe<Array<Scalars['String']>>;
  charactersBible?: InputMaybe<Scalars['String']>;
  customId?: InputMaybe<Scalars['String']>;
  extra?: InputMaybe<Array<Scalars['String']>>;
  fantasy?: InputMaybe<Scalars['Boolean']>;
  fantasyCategories?: InputMaybe<Array<Scalars['String']>>;
  finished?: InputMaybe<Scalars['Boolean']>;
  folderId?: InputMaybe<Scalars['String']>;
  fresh?: InputMaybe<Scalars['Boolean']>;
  genres?: InputMaybe<Array<Scalars['String']>>;
  has_new_customization?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['String'];
  imageMenuBrazilianGP?: InputMaybe<Scalars['String']>;
  imageMenuBrazilianIOS?: InputMaybe<Scalars['String']>;
  imageMenuCzechGP?: InputMaybe<Scalars['String']>;
  imageMenuCzechIOS?: InputMaybe<Scalars['String']>;
  imageMenuDanishGP?: InputMaybe<Scalars['String']>;
  imageMenuDanishIOS?: InputMaybe<Scalars['String']>;
  imageMenuDutchGP?: InputMaybe<Scalars['String']>;
  imageMenuDutchIOS?: InputMaybe<Scalars['String']>;
  imageMenuFinnishGP?: InputMaybe<Scalars['String']>;
  imageMenuFinnishIOS?: InputMaybe<Scalars['String']>;
  imageMenuFrenchGP?: InputMaybe<Scalars['String']>;
  imageMenuFrenchIOS?: InputMaybe<Scalars['String']>;
  imageMenuGP?: InputMaybe<Scalars['String']>;
  imageMenuGermanGP?: InputMaybe<Scalars['String']>;
  imageMenuGermanIOS?: InputMaybe<Scalars['String']>;
  imageMenuHungarianGP?: InputMaybe<Scalars['String']>;
  imageMenuHungarianIOS?: InputMaybe<Scalars['String']>;
  imageMenuIOS?: InputMaybe<Scalars['String']>;
  imageMenuIndonesianGP?: InputMaybe<Scalars['String']>;
  imageMenuIndonesianIOS?: InputMaybe<Scalars['String']>;
  imageMenuItalianGP?: InputMaybe<Scalars['String']>;
  imageMenuItalianIOS?: InputMaybe<Scalars['String']>;
  imageMenuJapaneseGP?: InputMaybe<Scalars['String']>;
  imageMenuJapaneseIOS?: InputMaybe<Scalars['String']>;
  imageMenuKoreanGP?: InputMaybe<Scalars['String']>;
  imageMenuKoreanIOS?: InputMaybe<Scalars['String']>;
  imageMenuNorwegianGP?: InputMaybe<Scalars['String']>;
  imageMenuNorwegianIOS?: InputMaybe<Scalars['String']>;
  imageMenuPolishGP?: InputMaybe<Scalars['String']>;
  imageMenuPolishIOS?: InputMaybe<Scalars['String']>;
  imageMenuPortugueseGP?: InputMaybe<Scalars['String']>;
  imageMenuPortugueseIOS?: InputMaybe<Scalars['String']>;
  imageMenuRomanianGP?: InputMaybe<Scalars['String']>;
  imageMenuRomanianIOS?: InputMaybe<Scalars['String']>;
  imageMenuSpainGP?: InputMaybe<Scalars['String']>;
  imageMenuSpainIOS?: InputMaybe<Scalars['String']>;
  imageMenuSwedishGP?: InputMaybe<Scalars['String']>;
  imageMenuSwedishIOS?: InputMaybe<Scalars['String']>;
  imageMenuTurkishGP?: InputMaybe<Scalars['String']>;
  imageMenuTurkishIOS?: InputMaybe<Scalars['String']>;
  imagePreviewBrazilianGP?: InputMaybe<Scalars['String']>;
  imagePreviewBrazilianIOS?: InputMaybe<Scalars['String']>;
  imagePreviewCzechGP?: InputMaybe<Scalars['String']>;
  imagePreviewCzechIOS?: InputMaybe<Scalars['String']>;
  imagePreviewDanishGP?: InputMaybe<Scalars['String']>;
  imagePreviewDanishIOS?: InputMaybe<Scalars['String']>;
  imagePreviewDutchGP?: InputMaybe<Scalars['String']>;
  imagePreviewDutchIOS?: InputMaybe<Scalars['String']>;
  imagePreviewFinnishGP?: InputMaybe<Scalars['String']>;
  imagePreviewFinnishIOS?: InputMaybe<Scalars['String']>;
  imagePreviewFrenchGP?: InputMaybe<Scalars['String']>;
  imagePreviewFrenchIOS?: InputMaybe<Scalars['String']>;
  imagePreviewGP?: InputMaybe<Scalars['String']>;
  imagePreviewGermanGP?: InputMaybe<Scalars['String']>;
  imagePreviewGermanIOS?: InputMaybe<Scalars['String']>;
  imagePreviewHungarianGP?: InputMaybe<Scalars['String']>;
  imagePreviewHungarianIOS?: InputMaybe<Scalars['String']>;
  imagePreviewIOS?: InputMaybe<Scalars['String']>;
  imagePreviewIndonesianGP?: InputMaybe<Scalars['String']>;
  imagePreviewIndonesianIOS?: InputMaybe<Scalars['String']>;
  imagePreviewItalianGP?: InputMaybe<Scalars['String']>;
  imagePreviewItalianIOS?: InputMaybe<Scalars['String']>;
  imagePreviewJapaneseGP?: InputMaybe<Scalars['String']>;
  imagePreviewJapaneseIOS?: InputMaybe<Scalars['String']>;
  imagePreviewKoreanGP?: InputMaybe<Scalars['String']>;
  imagePreviewKoreanIOS?: InputMaybe<Scalars['String']>;
  imagePreviewNorwegianGP?: InputMaybe<Scalars['String']>;
  imagePreviewNorwegianIOS?: InputMaybe<Scalars['String']>;
  imagePreviewPolishGP?: InputMaybe<Scalars['String']>;
  imagePreviewPolishIOS?: InputMaybe<Scalars['String']>;
  imagePreviewPortugueseGP?: InputMaybe<Scalars['String']>;
  imagePreviewPortugueseIOS?: InputMaybe<Scalars['String']>;
  imagePreviewRomanianGP?: InputMaybe<Scalars['String']>;
  imagePreviewRomanianIOS?: InputMaybe<Scalars['String']>;
  imagePreviewSpainGP?: InputMaybe<Scalars['String']>;
  imagePreviewSpainIOS?: InputMaybe<Scalars['String']>;
  imagePreviewSwedishGP?: InputMaybe<Scalars['String']>;
  imagePreviewSwedishIOS?: InputMaybe<Scalars['String']>;
  imagePreviewTurkishGP?: InputMaybe<Scalars['String']>;
  imagePreviewTurkishIOS?: InputMaybe<Scalars['String']>;
  localized?: InputMaybe<Array<Scalars['String']>>;
  logline?: InputMaybe<Scalars['String']>;
  menuImage?: InputMaybe<Scalars['String']>;
  menuImagePreview?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  nameCS?: InputMaybe<Scalars['String']>;
  nameDA?: InputMaybe<Scalars['String']>;
  nameDE?: InputMaybe<Scalars['String']>;
  nameES?: InputMaybe<Scalars['String']>;
  nameFI?: InputMaybe<Scalars['String']>;
  nameFR?: InputMaybe<Scalars['String']>;
  nameHU?: InputMaybe<Scalars['String']>;
  nameID?: InputMaybe<Scalars['String']>;
  nameIT?: InputMaybe<Scalars['String']>;
  nameJA?: InputMaybe<Scalars['String']>;
  nameKO?: InputMaybe<Scalars['String']>;
  nameNL?: InputMaybe<Scalars['String']>;
  nameNO?: InputMaybe<Scalars['String']>;
  namePL?: InputMaybe<Scalars['String']>;
  namePT?: InputMaybe<Scalars['String']>;
  namePT_BR?: InputMaybe<Scalars['String']>;
  nameRO?: InputMaybe<Scalars['String']>;
  nameRU?: InputMaybe<Scalars['String']>;
  nameSV?: InputMaybe<Scalars['String']>;
  nameTR?: InputMaybe<Scalars['String']>;
  narrativeLead?: InputMaybe<Scalars['String']>;
  outline?: InputMaybe<Scalars['String']>;
  redesigned?: InputMaybe<Scalars['Boolean']>;
  series?: InputMaybe<Array<Scalars['String']>>;
  stage?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  synopsis?: InputMaybe<Scalars['String']>;
  trending?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateCardRequestDto = {
  backgroundId: Scalars['String'];
  bookId?: InputMaybe<Scalars['String']>;
  cardsLink: Array<Scalars['String']>;
  chapterId: Scalars['String'];
  description: Scalars['String'];
  filename: Scalars['String'];
  golden: Scalars['Boolean'];
  heroId: Scalars['String'];
  heroName: Scalars['String'];
  id: Scalars['String'];
  secondary: Scalars['Boolean'];
  selectedAssets: SelectedAssetsInput;
};

export type UpdateChapterRequestDto = {
  abTestLua1?: InputMaybe<Scalars['String']>;
  abTestLua2?: InputMaybe<Scalars['String']>;
  abTestLua3?: InputMaybe<Scalars['String']>;
  additionalDocs?: InputMaybe<Array<UpdateDocRequestDto>>;
  audios?: InputMaybe<Array<CreateAudioRequestDto>>;
  author?: InputMaybe<Scalars['String']>;
  backgrounds?: InputMaybe<Array<CreateBackgroundRequestDto>>;
  chapterLink?: InputMaybe<Scalars['String']>;
  chapterOrder?: InputMaybe<Scalars['Int']>;
  chatPhotos?: InputMaybe<Array<CreateChatPhotoRequestDto>>;
  description?: InputMaybe<Scalars['String']>;
  descriptionCS?: InputMaybe<Scalars['String']>;
  descriptionDA?: InputMaybe<Scalars['String']>;
  descriptionDE?: InputMaybe<Scalars['String']>;
  descriptionES?: InputMaybe<Scalars['String']>;
  descriptionFI?: InputMaybe<Scalars['String']>;
  descriptionFR?: InputMaybe<Scalars['String']>;
  descriptionHU?: InputMaybe<Scalars['String']>;
  descriptionID?: InputMaybe<Scalars['String']>;
  descriptionIT?: InputMaybe<Scalars['String']>;
  descriptionJA?: InputMaybe<Scalars['String']>;
  descriptionKO?: InputMaybe<Scalars['String']>;
  descriptionNL?: InputMaybe<Scalars['String']>;
  descriptionNO?: InputMaybe<Scalars['String']>;
  descriptionPL?: InputMaybe<Scalars['String']>;
  descriptionPT?: InputMaybe<Scalars['String']>;
  descriptionPT_BR?: InputMaybe<Scalars['String']>;
  descriptionRO?: InputMaybe<Scalars['String']>;
  descriptionSV?: InputMaybe<Scalars['String']>;
  descriptionTR?: InputMaybe<Scalars['String']>;
  googleDoc?: InputMaybe<UpdateDocRequestDto>;
  googleDoc2Pov?: InputMaybe<UpdateDocRequestDto>;
  googleDoc2PovCS?: InputMaybe<UpdateDocRequestDto>;
  googleDoc2PovDA?: InputMaybe<UpdateDocRequestDto>;
  googleDoc2PovDE?: InputMaybe<UpdateDocRequestDto>;
  googleDoc2PovES?: InputMaybe<UpdateDocRequestDto>;
  googleDoc2PovFI?: InputMaybe<UpdateDocRequestDto>;
  googleDoc2PovFR?: InputMaybe<UpdateDocRequestDto>;
  googleDoc2PovHU?: InputMaybe<UpdateDocRequestDto>;
  googleDoc2PovID?: InputMaybe<UpdateDocRequestDto>;
  googleDoc2PovIT?: InputMaybe<UpdateDocRequestDto>;
  googleDoc2PovJA?: InputMaybe<UpdateDocRequestDto>;
  googleDoc2PovKO?: InputMaybe<UpdateDocRequestDto>;
  googleDoc2PovNL?: InputMaybe<UpdateDocRequestDto>;
  googleDoc2PovNO?: InputMaybe<UpdateDocRequestDto>;
  googleDoc2PovPL?: InputMaybe<UpdateDocRequestDto>;
  googleDoc2PovPT?: InputMaybe<UpdateDocRequestDto>;
  googleDoc2PovPT_BR?: InputMaybe<UpdateDocRequestDto>;
  googleDoc2PovRO?: InputMaybe<UpdateDocRequestDto>;
  googleDoc2PovSV?: InputMaybe<UpdateDocRequestDto>;
  googleDoc2PovTR?: InputMaybe<UpdateDocRequestDto>;
  googleDocABTest1?: InputMaybe<UpdateDocRequestDto>;
  googleDocABTest2?: InputMaybe<UpdateDocRequestDto>;
  googleDocABTest3?: InputMaybe<UpdateDocRequestDto>;
  googleDocCS?: InputMaybe<UpdateDocRequestDto>;
  googleDocDA?: InputMaybe<UpdateDocRequestDto>;
  googleDocDE?: InputMaybe<UpdateDocRequestDto>;
  googleDocES?: InputMaybe<UpdateDocRequestDto>;
  googleDocFI?: InputMaybe<UpdateDocRequestDto>;
  googleDocFR?: InputMaybe<UpdateDocRequestDto>;
  googleDocHU?: InputMaybe<UpdateDocRequestDto>;
  googleDocID?: InputMaybe<UpdateDocRequestDto>;
  googleDocIT?: InputMaybe<UpdateDocRequestDto>;
  googleDocJA?: InputMaybe<UpdateDocRequestDto>;
  googleDocKO?: InputMaybe<UpdateDocRequestDto>;
  googleDocNL?: InputMaybe<UpdateDocRequestDto>;
  googleDocNO?: InputMaybe<UpdateDocRequestDto>;
  googleDocNarrative?: InputMaybe<UpdateDocRequestDto>;
  googleDocPL?: InputMaybe<UpdateDocRequestDto>;
  googleDocPT?: InputMaybe<UpdateDocRequestDto>;
  googleDocPT_BR?: InputMaybe<UpdateDocRequestDto>;
  googleDocRO?: InputMaybe<UpdateDocRequestDto>;
  googleDocRU?: InputMaybe<UpdateDocRequestDto>;
  googleDocSV?: InputMaybe<UpdateDocRequestDto>;
  googleDocTR?: InputMaybe<UpdateDocRequestDto>;
  googleDocWriter?: InputMaybe<UpdateDocRequestDto>;
  hasTwoPovs?: InputMaybe<Scalars['Boolean']>;
  heroes?: InputMaybe<Array<UpdateHeroRequestDto>>;
  id: Scalars['String'];
  isVideo?: InputMaybe<Scalars['Boolean']>;
  items?: InputMaybe<Array<CreateItemRequestDto>>;
  luaLink?: InputMaybe<Scalars['String']>;
  luaLinkCS?: InputMaybe<Scalars['String']>;
  luaLinkDA?: InputMaybe<Scalars['String']>;
  luaLinkDE?: InputMaybe<Scalars['String']>;
  luaLinkES?: InputMaybe<Scalars['String']>;
  luaLinkFI?: InputMaybe<Scalars['String']>;
  luaLinkFR?: InputMaybe<Scalars['String']>;
  luaLinkHU?: InputMaybe<Scalars['String']>;
  luaLinkID?: InputMaybe<Scalars['String']>;
  luaLinkIt?: InputMaybe<Scalars['String']>;
  luaLinkJA?: InputMaybe<Scalars['String']>;
  luaLinkKO?: InputMaybe<Scalars['String']>;
  luaLinkNL?: InputMaybe<Scalars['String']>;
  luaLinkNO?: InputMaybe<Scalars['String']>;
  luaLinkPL?: InputMaybe<Scalars['String']>;
  luaLinkPT?: InputMaybe<Scalars['String']>;
  luaLinkPT_BR?: InputMaybe<Scalars['String']>;
  luaLinkRO?: InputMaybe<Scalars['String']>;
  luaLinkRU?: InputMaybe<Scalars['String']>;
  luaLinkSV?: InputMaybe<Scalars['String']>;
  luaLinkTR?: InputMaybe<Scalars['String']>;
  managementStatus?: InputMaybe<Array<Chapter_Status>>;
  name?: InputMaybe<Scalars['String']>;
  nameCS?: InputMaybe<Scalars['String']>;
  nameDA?: InputMaybe<Scalars['String']>;
  nameDE?: InputMaybe<Scalars['String']>;
  nameES?: InputMaybe<Scalars['String']>;
  nameFI?: InputMaybe<Scalars['String']>;
  nameFR?: InputMaybe<Scalars['String']>;
  nameHU?: InputMaybe<Scalars['String']>;
  nameID?: InputMaybe<Scalars['String']>;
  nameIT?: InputMaybe<Scalars['String']>;
  nameJA?: InputMaybe<Scalars['String']>;
  nameKO?: InputMaybe<Scalars['String']>;
  nameNL?: InputMaybe<Scalars['String']>;
  nameNO?: InputMaybe<Scalars['String']>;
  namePL?: InputMaybe<Scalars['String']>;
  namePT?: InputMaybe<Scalars['String']>;
  namePT_BR?: InputMaybe<Scalars['String']>;
  nameRO?: InputMaybe<Scalars['String']>;
  nameSV?: InputMaybe<Scalars['String']>;
  nameTR?: InputMaybe<Scalars['String']>;
  rems?: InputMaybe<Array<Scalars['String']>>;
  secondHeroName?: InputMaybe<Scalars['String']>;
  secondPovLua?: InputMaybe<Scalars['String']>;
  secondPovLuaCS?: InputMaybe<Scalars['String']>;
  secondPovLuaDA?: InputMaybe<Scalars['String']>;
  secondPovLuaDE?: InputMaybe<Scalars['String']>;
  secondPovLuaES?: InputMaybe<Scalars['String']>;
  secondPovLuaFI?: InputMaybe<Scalars['String']>;
  secondPovLuaFR?: InputMaybe<Scalars['String']>;
  secondPovLuaHU?: InputMaybe<Scalars['String']>;
  secondPovLuaID?: InputMaybe<Scalars['String']>;
  secondPovLuaIT?: InputMaybe<Scalars['String']>;
  secondPovLuaJA?: InputMaybe<Scalars['String']>;
  secondPovLuaKO?: InputMaybe<Scalars['String']>;
  secondPovLuaNL?: InputMaybe<Scalars['String']>;
  secondPovLuaNO?: InputMaybe<Scalars['String']>;
  secondPovLuaPL?: InputMaybe<Scalars['String']>;
  secondPovLuaPT?: InputMaybe<Scalars['String']>;
  secondPovLuaPT_BR?: InputMaybe<Scalars['String']>;
  secondPovLuaRO?: InputMaybe<Scalars['String']>;
  secondPovLuaSV?: InputMaybe<Scalars['String']>;
  secondPovLuaTR?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  textLink?: InputMaybe<Scalars['String']>;
};

export type UpdateChatHeroRequestDto = {
  age?: InputMaybe<Scalars['Float']>;
  badge?: InputMaybe<Scalars['String']>;
  body?: InputMaybe<Scalars['String']>;
  book?: InputMaybe<Scalars['String']>;
  cloth?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  hair?: InputMaybe<Scalars['String']>;
  hero?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  isLoveInterest?: InputMaybe<Scalars['Boolean']>;
  keywords?: InputMaybe<Array<Scalars['String']>>;
  lastName?: InputMaybe<Scalars['String']>;
  secondHero?: InputMaybe<Scalars['Boolean']>;
  sex?: InputMaybe<Scalars['Float']>;
};

export type UpdateChatPhotoRequestDto = {
  filename?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  link?: InputMaybe<Scalars['String']>;
};

export type UpdateChatRequestDto = {
  analyticsName?: InputMaybe<Scalars['Float']>;
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

export type UpdateClothesRequestDto = {
  alternativeClothesIds?: InputMaybe<Array<Scalars['String']>>;
  dropboxLink?: InputMaybe<Scalars['String']>;
  filename?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  keywords?: InputMaybe<Array<Scalars['String']>>;
  link?: InputMaybe<Scalars['String']>;
  newKeywords?: InputMaybe<KeywordsClothInput>;
  price?: InputMaybe<Scalars['Int']>;
};

export type UpdateDocRequestDto = {
  json?: InputMaybe<Scalars['String']>;
  link?: InputMaybe<Scalars['String']>;
  prevLink?: InputMaybe<Scalars['String']>;
};

export type UpdateDocsNotesRequestDto = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  isActive?: InputMaybe<Scalars['Boolean']>;
  link?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateEmotionsRequestDto = {
  emotions?: InputMaybe<Array<Scalars['String']>>;
};

export type UpdateHairsRequestDto = {
  backLink?: InputMaybe<Scalars['String']>;
  dropboxLink?: InputMaybe<Scalars['String']>;
  filename?: InputMaybe<Scalars['String']>;
  frontLink?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  keywords?: InputMaybe<Array<Scalars['String']>>;
  newHair?: InputMaybe<Scalars['Boolean']>;
  newKeywords?: InputMaybe<KeywordsHairInput>;
};

export type UpdateHeroRequestDto = {
  bodies?: InputMaybe<Array<Scalars['String']>>;
  chapters?: InputMaybe<Array<Scalars['String']>>;
  clothes?: InputMaybe<Array<Scalars['String']>>;
  defaultClothesId?: InputMaybe<Scalars['String']>;
  hairs?: InputMaybe<Array<Scalars['String']>>;
  id?: InputMaybe<Scalars['String']>;
  isFamiliar?: InputMaybe<Scalars['Boolean']>;
  keywords?: InputMaybe<Array<Scalars['String']>>;
  layers?: InputMaybe<Array<Scalars['String']>>;
  link?: InputMaybe<Scalars['String']>;
  names?: InputMaybe<Array<Scalars['String']>>;
  parentId?: InputMaybe<Scalars['String']>;
};

export type UpdateItemRequestDto = {
  chaptersIds?: InputMaybe<Array<Scalars['String']>>;
  dropboxLink?: InputMaybe<Scalars['String']>;
  filename?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  keywords?: InputMaybe<Array<Scalars['String']>>;
  link?: InputMaybe<Scalars['String']>;
  newKeywords?: InputMaybe<KeywordsItemInput>;
  price?: InputMaybe<Scalars['Int']>;
};

export type UpdateLayerRequestDto = {
  dropboxLink?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  keywords?: InputMaybe<Array<Scalars['String']>>;
  link?: InputMaybe<Scalars['String']>;
  newKeywords?: InputMaybe<KeywordsLayerInput>;
  position?: InputMaybe<Scalars['Int']>;
  price?: InputMaybe<Scalars['Float']>;
};

export type UpdateTemplateDto = {
  chaptersIds: Array<Scalars['String']>;
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateUserRequestDto = {
  email?: InputMaybe<Scalars['String']>;
  fullName?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  permissions?: InputMaybe<Array<Permission>>;
  picture?: InputMaybe<Scalars['String']>;
  slackId?: InputMaybe<Scalars['String']>;
};

export type User = {
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  fullName?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isDeleted: Scalars['Boolean'];
  permissions: Array<Scalars['String']>;
  picture?: Maybe<Scalars['String']>;
  slackId?: Maybe<Scalars['String']>;
  tokens?: Maybe<UserTokens>;
  updatedAt: Scalars['DateTime'];
};

export type UserId = {
  id: Scalars['Float'];
};

export type UserPermissions = {
  permissions: Array<Permission>;
};

export type UserTokens = {
  id: Scalars['String'];
  tokensAmount?: Maybe<Scalars['Float']>;
  user?: Maybe<User>;
};
