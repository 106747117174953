import React from 'react';
import { Button } from '@chakra-ui/react';

import { ButtonType } from 'shared/analytics';
import { MultipleChoiceList } from 'shared/ui';

import { SingleChoiceList } from '../single-choice-list';
import styles from './styles.module.scss';

interface Props {
	options: string[];
	selectedOptions: string[];
	formTitle: string;
	onChange: (selected: string[]) => void;
	onClose: () => void;
	onSubmit: () => void;
	isMulti?: boolean;
	isIcon?: boolean;
	submitButtonName: ButtonType;
	disableTags?: boolean;
	isManagementStatus?: boolean;
	isFullHeight?: boolean;
}

// TODO add button submit type

export const UpdateTagsForm: React.FC<Props> = ({
	options,
	selectedOptions,
	onChange,
	onClose,
	onSubmit,
	formTitle,
	isMulti = true,
	isIcon = false,
	submitButtonName,
	disableTags,
	isManagementStatus,
	isFullHeight = false,
}) => {
	return (
		<div className={styles.wrapper}>
			<p className={styles.title}>{formTitle}</p>
			<div className={isFullHeight ? '' : styles.choices}>
				{isMulti ? (
					<MultipleChoiceList
						disableTags={disableTags}
						selectedOptions={selectedOptions}
						options={options}
						onChange={onChange}
						isIcon={isIcon}
						disableSearch
						isManagementStatus={isManagementStatus}
					/>
				) : (
					<SingleChoiceList
						selectedOption={selectedOptions[0]}
						options={options.map((v) => ({ title: v, value: v }))}
						onChange={(v: string) => onChange([v])}
						isIcon={isIcon}
					/>
				)}
			</div>
			<div className={styles.btns}>
				<Button size="md" variant="secondary" onClick={onClose}>
					Cancel
				</Button>
				<Button
					name={submitButtonName}
					size="md"
					variant="secondary"
					onClick={onSubmit}
				>
					Apply
				</Button>
			</div>
		</div>
	);
};
