/* eslint-disable react-hooks/exhaustive-deps */
import React, {
	Dispatch,
	SetStateAction,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import {
	Box,
	CloseButton,
	Flex,
	IconButton,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Popover,
	PopoverBody,
	PopoverContent,
	PopoverTrigger,
	Text,
	Tooltip,
	useDisclosure,
} from '@chakra-ui/react';
import { convertToRaw, EditorState } from 'draft-js';
import { useStore } from 'effector-react';
import { debounce } from 'lodash';

import { escape, generateDecorator, scriptModel } from 'entities/script';
import { uiModel } from 'entities/ui';
import { userModel } from 'entities/user';
import {
	trackFindByDialogModalClose,
	trackFindByTextModalClose,
} from 'shared/analytics/modals/close';
import {
	trackFindAndReplaceModalOpen,
	trackFindByDialogModalOpen,
	trackFindByTextModalOpen,
} from 'shared/analytics/modals/open';
import { ArrowDownIcon, KebabMenuIcon, SearchIcon } from 'shared/icons';
import { SearchInput } from 'shared/ui';

import { deserialize } from '../../../../../shared/utils/deserialize';
import { ReplaceByText } from '../../../replace';
import { checkIsCaseSensitive } from '../../../utils';

interface FindFormProps {
	editorState: EditorState;
	setEditorState: Dispatch<SetStateAction<EditorState>>;
}
interface EditorBlock {
	line: number;
	text: string;
	position: {
		from: number;
		to: number;
	};
}

const SEARCH_TYPE = {
	TEXT: 'text',
	DIALOGUE: 'dialogue',
};
export const FindForm: React.FC<FindFormProps> = ({
	editorState,
	setEditorState,
}) => {
	const inputRef = useRef<null | HTMLInputElement>(null);
	const inputFindRef = useRef<null | HTMLInputElement>(null);

	const luaContent = useStore(scriptModel.$luaValue);
	const { isDialogues } = useStore(userModel.$features);

	const [currentSearchResultIndex, setCurrentSearchResultIndex] = useState(0);
	const [inputValue, setInputValue] = useState('');
	const [isCaseSensitive, setIsCaseSensitive] = useState(false);
	const { onOpen, onClose, isOpen } = useDisclosure();
	const editorSearchValue = useStore(scriptModel.$editorSearchValue);
	const dialogueValue = useStore(scriptModel.$editorSearchDialogueValue);
	const searchType = useStore(scriptModel.$searchType);

	const debouncedSetSearch = useMemo(
		() => debounce(() => scriptModel.setEditorSearchValue(inputValue), 1000),
		[inputValue],
	);

	const debouncedSetInputValue = useMemo(
		() =>
			debounce(
				() => scriptModel.setEditorSearchDialogueValue(Number(inputValue)),
				1000,
			),
		[inputValue],
	);

	const findResult = useMemo(() => {
		if (searchType === SEARCH_TYPE.DIALOGUE) return [];
		const editorData = editorState.getCurrentContent();
		const rawState = convertToRaw(editorData);
		const regex = new RegExp(
			escape(editorSearchValue),
			checkIsCaseSensitive(isCaseSensitive),
		);
		const linesValue = deserialize(rawState).text.split('\n');

		if (!editorSearchValue) return [];

		return linesValue.reduce((acc: EditorBlock[], curr, index) => {
			if (curr.match(regex)) {
				let offset = 0;
				const matches = [];
				const splitStr = isCaseSensitive
					? curr.split(inputValue)
					: curr.toLowerCase().split(inputValue.toLowerCase());

				for (let i = 0; i < splitStr.length - 1; i += 1) {
					const current = splitStr[i];
					offset += current.length;
					matches.push({
						from: offset,
						to: offset + inputValue.length,
					});
					offset += inputValue.length;
				}

				matches.forEach((el) => {
					acc.push({
						text: curr,
						line: index,
						position: el,
					});
				});
			}
			return acc;
		}, []);
	}, [editorSearchValue, isCaseSensitive, searchType]);

	const handleFindShortcut = useCallback(
		(e: KeyboardEvent) => {
			if (e.keyCode === 114 || ((e.ctrlKey || e.metaKey) && e.keyCode === 70)) {
				e.preventDefault();

				onOpen();
				setTimeout(() => inputRef.current?.focus(), 50);
			}
		},
		[onOpen],
	);

	const onCloseModalTrack = useCallback(
		(type = '') => {
			if ((type || searchType) === SEARCH_TYPE.TEXT) {
				trackFindByTextModalClose();
			} else {
				trackFindByDialogModalClose();
			}
		},
		[searchType],
	);
	const onOpenModalTrack = useCallback(
		(type = '') => {
			if ((type || searchType) === SEARCH_TYPE.TEXT) {
				trackFindByTextModalOpen();
			} else {
				trackFindByDialogModalOpen();
			}
		},
		[searchType],
	);
	const handleNextSearchResult = () => {
		if (searchType === SEARCH_TYPE.DIALOGUE) {
			const newDialogueValue = dialogueValue + 1;
			setInputValue(newDialogueValue.toString());
			return;
		}
		if (currentSearchResultIndex === findResult.length - 1)
			setCurrentSearchResultIndex(0);

		if (currentSearchResultIndex < findResult.length - 1)
			setCurrentSearchResultIndex(currentSearchResultIndex + 1);
		inputRef.current?.select();
	};

	const handlePrevSearchResult = () => {
		if (searchType === SEARCH_TYPE.DIALOGUE) {
			const newDialogueValue = dialogueValue - 1;
			setInputValue(newDialogueValue.toString());
			return;
		}
		if (currentSearchResultIndex === 0)
			setCurrentSearchResultIndex(findResult.length - 1);

		if (currentSearchResultIndex > 0)
			setCurrentSearchResultIndex(currentSearchResultIndex - 1);
		inputRef.current?.select();
	};

	useEffect(() => {
		scriptModel.setSearchType(SEARCH_TYPE.TEXT);
	}, [isDialogues]);

	useEffect(() => {
		if (searchType === SEARCH_TYPE.TEXT) {
			debouncedSetSearch();
		}
		setCurrentSearchResultIndex(0);

		return () => debouncedSetSearch.cancel();
	}, [debouncedSetSearch]);

	useEffect(() => {
		if (
			searchType === SEARCH_TYPE.DIALOGUE &&
			!Number.isNaN(Number(inputValue))
		) {
			debouncedSetInputValue();
		}
		return () => debouncedSetInputValue.cancel();
	}, [debouncedSetInputValue]);

	useEffect(() => {
		if (findResult.length && searchType === SEARCH_TYPE.TEXT) {
			const editorData = editorState.getCurrentContent();
			const contentBlocks = editorData.getBlocksAsArray();

			if (contentBlocks.length >= findResult[currentSearchResultIndex].line) {
				const blockToFocus =
					contentBlocks[findResult[currentSearchResultIndex].line];
				scriptModel.setBlockToFindFocus(
					findResult[currentSearchResultIndex]?.position,
				);
				const newState = EditorState.set(editorState, {
					decorator: generateDecorator(
						editorSearchValue,
						{
							isCaseSensitive,
							position: findResult[currentSearchResultIndex]?.position,
						},
						blockToFocus.getKey(),
					),
				});
				const selection = newState.getSelection().merge({
					anchorKey: blockToFocus.getKey(),
					focusKey: blockToFocus.getKey(),
					focusOffset: blockToFocus.getText().length,
					anchorOffset: blockToFocus.getText().length,
				});
				const editorFocus = EditorState.forceSelection(newState, selection);
				setEditorState(editorFocus);
				setTimeout(() => {
					window.getSelection()?.focusNode?.parentElement?.scrollIntoView({
						block: 'center',
					});
				}, 50);
				setCurrentSearchResultIndex(currentSearchResultIndex);
			}
		} else {
			setEditorState(
				EditorState.set(editorState, {
					decorator: generateDecorator(editorSearchValue, { isCaseSensitive }),
				}),
			);
			setCurrentSearchResultIndex(0);
		}
		setTimeout(() => inputRef.current?.focus(), 50);
		setTimeout(() => {
			inputFindRef.current?.blur();
			inputFindRef.current?.focus();
		}, 50);
	}, [
		isCaseSensitive,
		findResult,
		currentSearchResultIndex,
		editorSearchValue,
		setEditorState,
	]);

	useEffect(() => {
		window.addEventListener('keydown', handleFindShortcut);

		return () => window.removeEventListener('keydown', handleFindShortcut);
	}, [handleFindShortcut]);

	useEffect(() => {
		if (luaContent && searchType === SEARCH_TYPE.DIALOGUE) {
			const currentBlock = document.querySelector(
				`div[data-dialogue="Dialogue ${dialogueValue}"]`,
			) as HTMLElement;

			if (!currentBlock && inputValue) {
				const newDialogueValue = dialogueValue + 1;
				setInputValue(newDialogueValue.toString());
			}

			currentBlock?.scrollIntoView({ block: 'center' }); // Прокрутка блоку в поле зору
			currentBlock?.focus();
			const editorData = editorState.getCurrentContent();
			const blockKey = currentBlock
				?.getAttribute('data-offset-key')
				?.split('-')[0];
			const contentBlocks = editorData.getBlockForKey(blockKey as string);

			const selection = editorState.getSelection().merge({
				anchorKey: blockKey,
				focusKey: blockKey,
				focusOffset: contentBlocks?.getLength() || 0,
				anchorOffset: contentBlocks?.getLength() || 0,
			});

			const editorFocus = EditorState.forceSelection(editorState, selection);
			setEditorState(editorFocus);

			setTimeout(() => {
				window.getSelection()?.focusNode?.parentElement?.scrollIntoView({
					block: 'center',
				});
			}, 50);
			setTimeout(() => {
				inputRef.current?.blur();
				inputRef.current?.focus();
			}, 50);
		}
	}, [luaContent, dialogueValue]);

	return (
		<Box position="relative">
			<Popover
				isOpen={isOpen}
				onOpen={() => {
					onOpen();
					onOpenModalTrack();
				}}
				onClose={() => {
					onClose();
					onCloseModalTrack();
				}}
				placement="bottom"
				closeOnBlur={false}
			>
				<PopoverTrigger>
					<Box>
						<Tooltip label="Search">
							<IconButton variant="ghost" size="square" aria-label="Emoji">
								<SearchIcon w={5} h={5} />
							</IconButton>
						</Tooltip>
					</Box>
				</PopoverTrigger>
				<PopoverContent w="400px" mt="60px">
					<PopoverBody p="9px 12px">
						<Text variant="inputLabel" mb="5px">
							Find by {searchType}
						</Text>
						<Flex align="center" justify="space-between">
							<SearchInput
								ref={inputRef}
								placeholder={`Find by ${searchType}`}
								search={inputValue}
								setSearch={setInputValue}
								matchedLinesLength={findResult.length}
								currentSearchResultIndex={
									findResult.length ? currentSearchResultIndex + 1 : 0
								}
								inputPadding={searchType === SEARCH_TYPE.TEXT ? '72px' : '50px'}
							/>
							<Flex align="center" ml="8px">
								<IconButton
									variant="ghost"
									size="square"
									aria-label="Document Version"
									onClick={handleNextSearchResult}
								>
									<ArrowDownIcon w={5} h={5} />
								</IconButton>
								<IconButton
									variant="ghost"
									size="square"
									aria-label="Document Version"
									onClick={handlePrevSearchResult}
								>
									<ArrowDownIcon w={5} h={5} transform="rotate(180deg)" />
								</IconButton>
								<Box
									margin="0 8px"
									width="1px"
									height="19px"
									backgroundColor="#eeeef2"
								/>
								<Tooltip label="More Options">
									{isDialogues ? (
										<Menu>
											<MenuButton
												as={IconButton}
												variant="ghost"
												size="square"
												aria-label="More Options"
												transform="rotate(90deg)"
											>
												<KebabMenuIcon w={5} h={5} />
											</MenuButton>
											<MenuList>
												<MenuItem
													onClick={() => {
														scriptModel.setEditorSearchValue('');
														setInputValue('');
														onCloseModalTrack(SEARCH_TYPE.DIALOGUE);
														scriptModel.setSearchType(SEARCH_TYPE.TEXT);
														onOpenModalTrack(SEARCH_TYPE.TEXT);
													}}
													color={
														searchType === SEARCH_TYPE.TEXT
															? '#644ded'
															: 'inherit'
													}
													backgroundColor={
														searchType === SEARCH_TYPE.TEXT
															? 'rgba(93,66,255,0.08)'
															: '#fdfdfd'
													}
												>
													Find by text
												</MenuItem>
												<MenuItem
													onClick={() => {
														scriptModel.setSearchType(SEARCH_TYPE.TEXT);
														uiModel.setIsEditorReplaceModalOpened(true);
														onClose();
														onCloseModalTrack();
														trackFindAndReplaceModalOpen();
													}}
												>
													Find and Replace
												</MenuItem>
												<MenuItem
													isDisabled={!luaContent}
													onClick={() => {
														scriptModel.setEditorSearchValue('');
														setInputValue('');
														onCloseModalTrack(SEARCH_TYPE.TEXT);
														scriptModel.setSearchType(SEARCH_TYPE.DIALOGUE);
														onOpenModalTrack(SEARCH_TYPE.DIALOGUE);
													}}
													color={
														searchType === SEARCH_TYPE.DIALOGUE
															? '#644ded'
															: 'inherit'
													}
													backgroundColor={
														searchType === SEARCH_TYPE.DIALOGUE
															? 'rgba(93,66,255,0.08)'
															: '#fdfdfd'
													}
												>
													Find by dialogues
												</MenuItem>
											</MenuList>
										</Menu>
									) : (
										<IconButton
											variant="ghost"
											size="square"
											aria-label="More Options"
											transform="rotate(90deg)"
											onClick={() => {
												uiModel.setIsEditorReplaceModalOpened(true);
												onClose();
											}}
										>
											<KebabMenuIcon w={5} h={5} />
										</IconButton>
									)}
								</Tooltip>
								<CloseButton
									onClick={() => {
										onClose();
										scriptModel.setEditorSearchValue('');
										setInputValue('');
										onCloseModalTrack();
									}}
								/>
							</Flex>
						</Flex>
						{searchType === SEARCH_TYPE.DIALOGUE &&
						inputValue &&
						Number.isNaN(Number(inputValue)) ? (
							<Text variant="errors">only numbers</Text>
						) : null}
					</PopoverBody>
				</PopoverContent>
			</Popover>
			<ReplaceByText
				editorState={editorState}
				setEditorState={setEditorState}
				isCaseSensitive={isCaseSensitive}
				setIsCaseSensitive={setIsCaseSensitive}
				matchedLinesLength={findResult.length}
				setInputValue={setInputValue}
				inputFindRef={inputFindRef}
			/>
		</Box>
	);
};
