import { createEvent, createStore } from 'effector';

import { scriptModel } from 'entities/script';

export const toggleNavigation = createEvent('toggle sidebar');
export const toggleStoryDetails = createEvent('toggle story details');
export const setIsCreateChapterModalOpened = createEvent<boolean>(
	'set create chapter modal opened',
);
export const setIsCreateArtDocOpened = createEvent<boolean>(
	'set create art doc modal opened',
);
export const setIsUpdateChapterModalOpened = createEvent<boolean>(
	'set update chapter modal opened',
);
export const setCreateBookModalOpened = createEvent<boolean>(
	'set create book modal opened',
);
export const setIsCreateAssetModalOpened = createEvent<boolean>(
	'set create asset modal opened',
);
export const setIsUpdateAssetModalOpened = createEvent<boolean>(
	'set update asset modal opened',
);
export const setIsImageViewModalOpened = createEvent<boolean>(
	'set image view modal opened',
);
export const setIsImageUploadModalOpened = createEvent<boolean>(
	'set image upload modal opened',
);
export const setIsDraftSyncAlertShow = createEvent<boolean>('Show sync Alert ');
export const setIsCloseAlertShow = createEvent<boolean>('show close alert ');
export const setIsAllCommentsResolved = createEvent<boolean>(
	'Set is all editor comments are resolved',
);
export const setIsEditorReplaceModalOpened = createEvent<boolean>(
	'Set is  editor replace modal is opened',
);
export const setUsedAssets = createEvent<
	{ heroId: string; heroName: string; ids: string[] }[]
>('Set used assets in other heroes');

export const $isNavigationOpened = createStore(false).on(
	toggleNavigation,
	(state) => !state,
);
export const $isStoryDetailsOpened = createStore(true).on(
	toggleStoryDetails,
	(state) => !state,
);
export const $isCreateChapterModalOpened = createStore(false).on(
	setIsCreateChapterModalOpened,
	(_, value) => value,
);
export const $isCreateArtDocOpened = createStore(false).on(
	setIsCreateArtDocOpened,
	(_, value) => value,
);
export const $isUpdateChapterModalOpened = createStore(false).on(
	setIsUpdateChapterModalOpened,
	(_, value) => value,
);
export const $isCreateBookModalOpened = createStore(false).on(
	setCreateBookModalOpened,
	(_, value) => value,
);
export const $isCreateAssetModalOpened = createStore(false).on(
	setIsCreateAssetModalOpened,
	(_, value) => value,
);
export const $isUpdateAssetModalOpened = createStore(false).on(
	setIsUpdateAssetModalOpened,
	(_, value) => value,
);
export const $isCloseAlertShow = createStore(false).on(
	setIsCloseAlertShow,
	(_, value) => value,
);
export const $isDraftSyncAlertShow = createStore(true)
	.on(
		scriptModel.loadIsDraftDocContentUpdatedFX.doneData,
		(_, result) => result.data.getIsDraftDocContentUpdated.isUpdated,
	)
	.on(setIsDraftSyncAlertShow, (_, value) => value);
export const $isAllCommentsResolved = createStore(false).on(
	setIsAllCommentsResolved,
	(_, value) => value,
);

export const $isEditorReplaceModalOpened = createStore(false).on(
	setIsEditorReplaceModalOpened,
	(_, value) => value,
);
export const $usedAssetsBefore = createStore<
	{ heroId: string; heroName: string; ids: string[] }[]
>([]).on(setUsedAssets, (_, value) => value);
