import React, { useCallback, useState } from 'react';

import { GetBookDocument, GetBookQuery } from 'features/book/queries.gen';
import { useUpdateChapterMutation } from 'features/chapter/update/queries.gen';
import { ChapterByIdDocument } from 'features/story-editor/header/queries.gen';
import {
	BookProperty,
	ButtonType,
	trackPropertyChanged,
} from 'shared/analytics';
import { useAppToast } from 'shared/hooks/toast';
import { ArrayElement } from 'shared/model';
import { FormInputField, FormWithChildren } from 'shared/ui';

import { updateNameSchema } from '../../schema';

export interface Props {
	chapter: ArrayElement<GetBookQuery['book']['chapters']>;
}

export const UpdateNameForm: React.FC<Props> = ({ chapter }) => {
	const toast = useAppToast();
	const [isRedacting, setIsRedacting] = useState(false);

	const onStartRedacting = useCallback(() => setIsRedacting(true), []);
	const onStopRedacting = useCallback(() => setIsRedacting(false), []);

	const [updateChapter] = useUpdateChapterMutation({
		onCompleted: () => {
			onStopRedacting();

			toast({
				title: 'Chapter name updated successfully',
				status: 'success',
			});
		},
		onError: () =>
			toast({
				title: 'Chapter name was not updated',
				status: 'error',
			}),
		refetchQueries: [ChapterByIdDocument, GetBookDocument],
	});

	const updateChapterName = useCallback(
		async (formValue: string) => {
			await updateChapter({
				variables: {
					chapter: {
						id: chapter?.id,
						name: formValue,
					},
				},
			});
			trackPropertyChanged({
				entity: 'chapter',
				property: BookProperty.NAME,
				value: formValue,
				id: chapter?.id,
			});
		},
		[chapter?.id, updateChapter],
	);

	return (
		<FormWithChildren
			submitButtonName={ButtonType.EDIT_CHAPTER_NAME}
			initialValue={chapter?.name}
			isRedacting={isRedacting}
			startRedacting={onStartRedacting}
			stopRedacting={onStopRedacting}
			validationSchema={updateNameSchema}
			onSubmit={({ formValue }) => updateChapterName(formValue)}
		>
			<FormInputField label="Name" name="formValue" placeholder="Name" />
		</FormWithChildren>
	);
};
