import React from 'react';
import { createIcon } from '@chakra-ui/react';

export const CollapseIcon = createIcon({
	viewBox: '0 0 24 24',
	path: (
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M5.4 5.7c0-.3866-.3134-.7-.7-.7-.3866 0-.7.3134-.7.7v13.1867c0 .3866.3134.7.7.7.3866 0 .7-.3134.7-.7V5.7Zm2.06616 6.063c-.2929.2929-.2929.7678 0 1.0607l3.77504 3.775c.2929.2929.7677.2929 1.0606 0 .2929-.2929.2929-.7678 0-1.0607l-2.49625-2.4962 9.90765-.0209c.4142-.0009.7493-.3374.7483-.7516-.0007-.4142-.3372-.7493-.7515-.7484l-9.90124.0208 2.50804-2.50802c.2929-.29289.2929-.76777 0-1.06066s-.7678-.29289-1.0606 0L7.46616 11.763Z"
			fill="currentColor"
		/>
	),
});
