import React from 'react';
import { createIcon } from '@chakra-ui/react';

export const ArrowDownIcon = createIcon({
	viewBox: '0 0 24 24',
	path: (
		<g fill="none">
			<path
				d="M 17.5 9.5 L 12.5 14.5 L 7.5 9.5"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</g>
	),
});
