import React from 'react';
import Dropzone from 'react-dropzone';
import { Button, Flex, Input, Text } from '@chakra-ui/react';

import { AssetType } from 'entities/assets/model';
import { STYLE_CONFIG } from 'entities/assets/ui/asset-card';
import { ButtonType } from 'shared/analytics';

export interface Props {
	type: AssetType;
	w: number | string;
	h: number | string;
	handlePhotoUpload: (acceptedFiles: File[]) => void;
}

export const ImageUpload: React.FC<Props> = ({
	type,
	w,
	h,
	handlePhotoUpload,
}) => {
	return (
		<Dropzone
			onDrop={(acceptedFiles: File[]) => handlePhotoUpload(acceptedFiles)}
		>
			{({ getRootProps, getInputProps, isDragActive }) => (
				<Flex
					w="full"
					flexDirection="column"
					justifyContent="center"
					alignItems="center"
					border={isDragActive ? '1px solid #644DED' : '1px solid #eeeef2'}
					borderRadius="10px"
					position="relative"
					overflow="hidden"
					cursor="pointer"
					{...STYLE_CONFIG[type]}
					{...getRootProps()}
				>
					<Button
						name={ButtonType.UPLOAD_ASSET}
						cursor="pointer"
						_hover={{ background: '#8960ff' }}
					>
						Upload Asset
					</Button>
					<Input
						type="file"
						id="file-input"
						accept="image/*"
						{...getInputProps()}
						// onChange={handlePhotoUpload}
						variant="file"
						value=""
						size="lg"
					/>
					{w && h && w !== 'any' && h !== 'any' ? (
						<Text pt="20px" fontSize="12px" lineHeight="16px" color="#abacbe">
							Resolution of “{w}x{h}”
						</Text>
					) : null}
				</Flex>
			)}
		</Dropzone>
	);
};
