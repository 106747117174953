import React from 'react';
import { createIcon } from '@chakra-ui/react';

export const FileIcon = createIcon({
	viewBox: '0 0 24 24',
	path: (
		<>
			<path
				d="M19.3654 18.7773V4.7323c0-.968-.7877-1.7557-1.7556-1.7557l-7.6113.0001a.3524.3524 0 0 0-.2488.1033L5.8594 6.9707a.3518.3518 0 0 0-.1033.2486v11.558c0 .968.7877 1.7557 1.7557 1.7557H17.61c.9678-.0002 1.7555-.7877 1.7555-1.7557h-.0002ZM9.6474 4.1754v1.6394c0 .581-.4724 1.0534-1.0533 1.0534H6.9547l2.6927-2.6928ZM6.4583 18.7773V7.5706H8.594c.968 0 1.7556-.7877 1.7556-1.7556V3.6792h7.2599c.5809 0 1.0534.4725 1.0534 1.0534v14.0446c0 .5809-.4725 1.0534-1.0534 1.0534l-10.0983.0001c-.581 0-1.0534-.4725-1.0534-1.0533l.0004-.0001Z"
				fill="currentColor"
				stroke="currentColor"
				strokeWidth=".4"
			/>
			<path
				d="M17.0408 13.1602h-8.96a.3508.3508 0 0 0-.351.351c0 .1942.157.3511.351.3511h8.96a.3508.3508 0 0 0 .351-.3511.3506.3506 0 0 0-.351-.351ZM17.0408 10.5312h-8.96a.3509.3509 0 0 0-.351.3511c0 .1941.157.3511.351.3511h8.96a.3508.3508 0 0 0 .351-.3511.3507.3507 0 0 0-.351-.3511Z"
				fill="currentColor"
				stroke="currentColor"
				strokeWidth=".4"
			/>
		</>
	),
});
