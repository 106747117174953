import React from 'react';
import { createIcon } from '@chakra-ui/react';

export const ItalicTextIcon = createIcon({
	viewBox: '0 0 24 24',
	path: (
		<path
			d="M 14.353 5.92 L 11.931 18.08 L 15.016 18.08 L 14.651 20 L 6.148 20 L 6.513 18.08 L 9.645 18.08 L 12.091 5.92 L 8.983 5.92 L 9.348 4 L 17.851 4 L 17.485 5.92 L 14.353 5.92 Z"
			fill="currentColor"
		/>
	),
});
