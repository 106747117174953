import React from 'react';
import { createIcon } from '@chakra-ui/react';

export const SidebarIcon = createIcon({
	viewBox: '0 0 24 24',
	path: (
		<path
			d="M17.3333 4H5.66667C4.74619 4 4 4.74619 4 5.66667V17.3333C4 18.2538 4.74619 19 5.66667 19H17.3333C18.2538 19 19 18.2538 19 17.3333V5.66667C19 4.74619 18.2538 4 17.3333 4ZM4 9h15M12 19V9"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
	),
});
