import { ContentBlock, EditorState } from 'draft-js';

import { EventTypes, sendAmplitudeData } from 'shared/analytics';
import { AmplitudeInfo } from 'widgets/ask-ai-modal/ui/types';

interface AiIconClickProps {
	editorState: EditorState;
	setSelectedText: (v: string) => void;
	amplitudeInfo: AmplitudeInfo;
}

export const aiIconClick = ({
	editorState,
	setSelectedText,
	amplitudeInfo,
}: AiIconClickProps) => {
	const selection = editorState.getSelection();
	const contentState = editorState.getCurrentContent();

	const startKey = selection.getStartKey();
	const endKey = selection.getEndKey();

	const endBlock = contentState.getBlockForKey(endKey);
	const startOffset = selection.getStartOffset();
	const endOffset = selection.getEndOffset();

	if (startOffset !== endOffset) {
		const blockArray: Array<ContentBlock> = contentState
			.getBlockMap()
			.toList()
			.skipUntil((block) => block?.getKey() === startKey)
			.takeUntil(
				(block) =>
					block?.getKey() === endKey &&
					block.getText().slice(0, endOffset).trim().length === endOffset,
			)
			.toArray();
		blockArray.push(endBlock);

		const selectedText = blockArray
			.map((block) => {
				const blockText = block.getText();
				const startIndex = block.getKey() === startKey ? startOffset : 0;
				const endIndex =
					block.getKey() === endKey ? endOffset : blockText.length;
				return blockText.slice(startIndex, endIndex);
			})
			.join('\n');

		setSelectedText(selectedText);

		sendAmplitudeData(EventTypes.ASKAI_CLICK, {
			...amplitudeInfo,
			customizable_part: selectedText || 'none',
		});
	} else {
		sendAmplitudeData(EventTypes.ASKAI_CLICK, {
			...amplitudeInfo,
			customizable_part: 'none',
		});
	}
};
