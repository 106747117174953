import * as yup from 'yup';

export const createChatPhotoSchema = yup.object({
	link: yup
		.string()
		.trim()
		.url()
		.required('Chat photo is a required field')
		.nullable(),
});

export type CreateChatPhotoValues = yup.InferType<typeof createChatPhotoSchema>;
