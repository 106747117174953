import React from 'react';
import { createIcon } from '@chakra-ui/react';

export const ArrowUndoIcon = createIcon({
	viewBox: '0 0 24 24',
	path: (
		<g fill="none">
			<path
				d="M8.22266 14.8892L4.22266 10.8892L8.22266 6.88916"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M20.2227 17.5553C20.2227 14.2416 17.5364 10.8887 14.2227 10.8887H4.88932"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</g>
	),
});
