import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
	Box,
	Button,
	Flex,
	Image,
	Tooltip,
	useDisclosure,
} from '@chakra-ui/react';

import { AddHeroAssetsModal } from 'features/asset/add-assets-modal';
import { HeroImage } from 'features/hero/hero-image';
import { ButtonType } from 'shared/analytics';
import { DownloadIcon } from 'shared/icons';
import { Background } from 'shared/icons/background';
import { downloadCanvas } from 'shared/utils/downloadCanvas';
import {
	DRAW_HERO_1X_CONFIG,
	DRAW_TYPES,
} from 'shared/utils/drawCanvas/configs';
import { drawCanvas } from 'shared/utils/drawCanvas/drawCanvas';

import { useAssetCardBackgroundByIdLazyQuery } from '../asset-card-background/queries.gen';
import { useAssetCardBodyByIdQuery } from '../asset-card-body/queries.gen';
import { useAssetCardClothesByIdQuery } from '../asset-card-clothes/queries.gen';
import { useAssetCardHairByIdQuery } from '../asset-card-hair/queries.gen';
import { useLayersByIdsQuery } from '../asset-card-layer/queries.gen';

interface RenderHeroProps {
	body: string;
	clothes: string;
	hairs: string;
	layers: string[];
}

export const RenderHero: React.FC<RenderHeroProps> = ({
	body,
	clothes,
	hairs,
	layers,
}) => {
	const [background, setBackground] = useState<string[]>([]);

	const [getBackground, { data: backgroundData }] =
		useAssetCardBackgroundByIdLazyQuery({
			variables: {
				id: background[0] || '',
			},
		});

	const { data: bodyData } = useAssetCardBodyByIdQuery({
		variables: {
			id: body,
		},
	});

	const { data: clothesData } = useAssetCardClothesByIdQuery({
		variables: {
			id: clothes,
		},
	});

	const { data: hairData } = useAssetCardHairByIdQuery({
		variables: {
			id: hairs,
		},
	});

	const { data: layersData } = useLayersByIdsQuery({
		variables: {
			layersIds: layers,
		},
	});

	const {
		isOpen: isOpenAssetModal,
		onOpen: onOpenAssetModal,
		onClose: onCloseAssetModal,
	} = useDisclosure();

	const emotionLink = useMemo(() => {
		const defaultEmotionLink = bodyData?.body.emotions?.find((emotion) =>
			emotion.includes('default'),
		);
		const firstEmotionLink = bodyData?.body.emotions[0];
		return defaultEmotionLink || firstEmotionLink || '';
	}, [bodyData?.body.emotions]);

	const handleHeroDownload = useCallback(
		async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
			e.preventDefault();
			e.stopPropagation();
			const filename = `hero.png`;
			const heroItemLinks = [
				{ link: backgroundData?.background.link || '', position: 0 },
				{ link: hairData?.hair.backLink || '', position: 0 },
				{ link: bodyData?.body.link || '', position: 1 },
				{ link: clothesData?.findOneClothes.link || '', position: 2 },
				{ link: emotionLink, position: 3 },
				{ link: hairData?.hair.frontLink || '', position: 4 },
				...(layersData?.layersByIds || []),
			]
				.sort((a, b) => (a.position || 0) - (b.position || 0))
				.map(({ link }) => link);

			const canvas = await drawCanvas(heroItemLinks as string[], {
				configs: DRAW_HERO_1X_CONFIG,
				type: DRAW_TYPES.DRAW_HERO,
			});

			downloadCanvas(canvas, filename, 'png');
		},
		[
			backgroundData?.background.link,
			bodyData?.body.link,
			clothesData?.findOneClothes.link,
			emotionLink,
			hairData?.hair.backLink,
			hairData?.hair.frontLink,
			layersData?.layersByIds,
		],
	);

	useEffect(() => {
		if (background[0]) {
			getBackground();
		}
	}, [background, getBackground]);

	return (
		<>
			<Flex
				gap="5px"
				position="absolute"
				top="10px"
				right="10px"
				zIndex="1000"
				flexDirection="column"
			>
				<Tooltip label="Download hero">
					<Button
						name={ButtonType.DOWNLOAD_ASSET_FILE}
						size="circle"
						variant="assetImage"
						onClick={handleHeroDownload}
					>
						<DownloadIcon w={6} h={6} />
					</Button>
				</Tooltip>
				<Tooltip label="Background">
					<Button
						name={ButtonType.DOWNLOAD_ASSET_FILE}
						size="circle"
						variant="assetImage"
						onClick={onOpenAssetModal}
					>
						<Background w={6} h={6} />
					</Button>
				</Tooltip>
			</Flex>
			{backgroundData?.background.link && (
				<Box height="100%" maxH="100%">
					<Image
						position="absolute"
						margin="auto"
						htmlHeight="none"
						left="50%"
						transform="translate(-50%, 0)"
						alt="hero-image-background"
						bottom="0"
						h="100%"
						maxH="100%"
						maxW="none"
						src={backgroundData?.background.link || ''}
					/>
				</Box>
			)}

			<HeroImage
				hairBackData={{ link: hairData?.hair.backLink || '', position: 0 }}
				bodyData={{ link: bodyData?.body.link || '', position: 1 }}
				clothesData={{
					link: clothesData?.findOneClothes.link || '',
					position: 2,
				}}
				emotionsData={{
					link:
						bodyData?.body.emotions?.find((emotion) =>
							emotion.includes('default'),
						) ||
						bodyData?.body.emotions[0] ||
						'' ||
						'',
					position: 3,
				}}
				hairFrontData={{ link: hairData?.hair.frontLink || '', position: 4 }}
				layersData={layersData?.layersByIds || []}
			/>
			{isOpenAssetModal && (
				<AddHeroAssetsModal
					backgrounds={[]}
					isOpen={isOpenAssetModal}
					onClose={onCloseAssetModal}
					type="background"
					handleSetFieldValue={(field, assets) => setBackground(assets)}
					altBackgrounds={[]}
					isAltAsset
					isHeroAddBackground
				/>
			)}
		</>
	);
};
