import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
	Box,
	Button,
	Flex,
	Grid,
	Modal,
	ModalCloseButton,
	ModalContent,
	ModalOverlay,
	Spacer,
	Text,
	useDisclosure,
} from '@chakra-ui/react';
import { debounce } from 'lodash';

import { filterKeywordsTypes, KEYWORDS_FILTER_TYPES } from 'pages/art-catalog';
import ArtCatalogSort from 'pages/art-catalog/ui/ArtCatalogSort';
import { ButtonType } from 'shared/analytics';
import { SearchInput } from 'shared/ui';
import { KeywordsFilter } from 'widgets/keywords-filter-modal';
import { FilterKeywords } from 'widgets/keywords-filter-modal/constant';
import { AssetsSortArgDto } from 'models.gen';

import { AssetsCards } from './assets-cards';
import { ASSETS_LIMIT, sortOptions } from './constant/sortOptions';
import { useAssetsQuery } from './graphql/queries.gen';

const SORT_MAP: Record<string, AssetsSortArgDto> = {
	'updatedAt-desc': {
		field: 'updatedAt',
		order: 'DESC',
	},
	'name-asc': {
		field: 'name',
		order: 'ASC',
	},
	'name-desc': {
		field: 'name',
		order: 'DESC',
	},
	'used-asc': {
		field: 'used',
		order: 'asc',
	},
	'used-desc': {
		field: 'used',
		order: 'desc',
	},
};

interface AddHeroAssetsModalProps {
	isOpen: boolean;
	onClose: () => void;
	type?: string;
	heroId?: string;
	bodies?: string[];
	backgrounds?: string[];
	altBackgrounds?: string[];
	clothes?: string[];
	hairs?: { id: string; name: string }[];
	layers?: string[];
	handleSetFieldValue: (
		field: string,
		value: any,
		shouldValidate?: any,
	) => void;
	altClothesIds?: string[];
	isAltAsset?: boolean;
	isHeroAddBackground?: boolean;
}

export const AddHeroAssetsModal: React.FC<AddHeroAssetsModalProps> = ({
	isOpen,
	onClose,
	type,
	heroId,
	bodies,
	backgrounds = [],
	clothes = [],
	hairs,
	layers,
	handleSetFieldValue,
	altClothesIds = [],
	altBackgrounds = [],
	isAltAsset,
	isHeroAddBackground,
}) => {
	const {
		isOpen: isOpenKeywordsModal,
		onClose: oncloseKeywordsModal,
		onOpen: onOpenKeywordsModal,
	} = useDisclosure();

	const [sort, setSort] = useState(sortOptions[0].value);
	const [selectedAssets, setSelectedAssets] = useState<
		{ id: string; name: string }[]
	>([]);
	const [search, setSearch] = useState('');
	const [searchValue, setSearchValue] = useState('');
	const [keywords, setKeywords] = useState<FilterKeywords | null>(null);
	const [page, setPage] = useState(0);
	const [filterKeywordsType, setFilterKeywordsType] =
		useState<filterKeywordsTypes>(KEYWORDS_FILTER_TYPES.HAS_ALL_OF);

	const debouncedSetSearch = useMemo(
		() => debounce((v) => setSearch(v), 1000),
		[setSearch],
	);

	useEffect(() => {
		debouncedSetSearch(searchValue);

		return () => debouncedSetSearch.cancel();
	}, [searchValue, debouncedSetSearch]);

	const filterAssetIds = useMemo(() => {
		switch (type) {
			case 'clothes':
				if (isAltAsset) return [...clothes, ...altClothesIds];
				return clothes;
				break;
			case 'background':
				if (isAltAsset) return [...backgrounds, ...altBackgrounds];
				return backgrounds;
				break;
			case 'hair':
				return hairs?.map(({ id }) => id);
				break;
			case 'body':
				return bodies;
				break;
			case 'layer':
				return layers;
				break;

			default:
				break;
		}
		return null;
	}, [
		altBackgrounds,
		altClothesIds,
		backgrounds,
		bodies,
		clothes,
		hairs,
		isAltAsset,
		layers,
		type,
	]);

	const { data, loading, fetchMore } = useAssetsQuery({
		variables: {
			page: 0,
			limit: ASSETS_LIMIT,
			filter: {
				type,
				name: search,
				keywords,
				filterKeywordsType,
				assetIds: filterAssetIds,
			},
			sort: SORT_MAP[sort],
		},
		fetchPolicy: 'network-only',
		nextFetchPolicy: 'network-only',
		notifyOnNetworkStatusChange: true,
	});

	const handleFetchMore = useCallback(() => {
		fetchMore({
			variables: {
				page: page + 1,
			},
			updateQuery: (previousQueryResult, { fetchMoreResult }) => {
				if (!fetchMoreResult || fetchMoreResult.assets.assets.length === 0) {
					return previousQueryResult;
				}

				return {
					...previousQueryResult,
					assets: {
						...previousQueryResult?.assets,
						assets: [
							...(previousQueryResult?.assets?.assets || []),
							...fetchMoreResult.assets.assets,
						],
					},
				};
			},
		});

		setPage(page + 1);
	}, [fetchMore, page]);
	const isDisabled = useMemo(
		() => selectedAssets.length === 0,
		[selectedAssets],
	);

	const defaultState = useCallback(() => {
		setSort(sortOptions[0].value);
		setKeywords(null);
		setSelectedAssets([]);
		setSearch('');
		setPage(0);
	}, []);

	const handleSelectAsset = useCallback(
		(ass: { id: string; name: string }) => {
			if (selectedAssets.map((asset) => asset.id).includes(ass.id)) {
				setSelectedAssets((prevState) =>
					prevState.filter((selectAsset) => selectAsset.id !== ass.id),
				);
			} else {
				setSelectedAssets((prevState) => [...prevState, ass]);
			}
		},
		[selectedAssets],
	);

	const isSelected = useCallback(
		(assetId: string) =>
			selectedAssets.map((asset) => asset.id).includes(assetId),
		[selectedAssets],
	);

	const onCloseModal = useCallback(() => {
		onClose();
		defaultState();
	}, [defaultState, onClose]);

	const handleSubmit = useCallback(() => {
		switch (type) {
			case 'clothes': {
				const clothesArr = !isAltAsset ? clothes : [];

				handleSetFieldValue('clothes', [
					...clothesArr,
					...selectedAssets.map((a) => a.id),
				]);
				break;
			}
			case 'hair': {
				handleSetFieldValue('hairs', [...(hairs || []), ...selectedAssets]);
				break;
			}
			case 'layer': {
				handleSetFieldValue('layers', [
					...(layers || []),
					...selectedAssets.map((a) => a.id),
				]);
				break;
			}
			case 'background': {
				handleSetFieldValue('background', [...selectedAssets.map((a) => a.id)]);
				break;
			}
			case 'body':
			default: {
				handleSetFieldValue('bodies', [
					...(bodies || []),
					...selectedAssets.map((a) => a.id),
				]);
				break;
			}
		}
		onCloseModal();
	}, [
		selectedAssets,
		bodies,
		clothes,
		hairs,
		handleSetFieldValue,
		isAltAsset,
		layers,
		onCloseModal,
		type,
	]);

	return (
		<Modal isOpen={isOpen} onClose={onCloseModal}>
			<ModalOverlay />
			<ModalContent minW={820} m={0} overflow="hidden">
				<Grid templateRows="auto 1fr auto" h={700}>
					<Flex
						alignItems="center"
						justifyContent="space-between"
						gap={5}
						px={6}
						py={4}
						height="78px"
						borderBottom="1px solid #eeeef2"
					>
						<SearchInput
							search={searchValue}
							setSearch={(v) => setSearchValue(v)}
							placeholder={`Search ${type}`}
						/>
						<Box mx={3}>
							<ModalCloseButton margin="13px 5px" borderRadius={8} />
						</Box>
					</Flex>
					<Grid templateRows="auto 1fr" overflow="auto">
						<Flex px={6} my={3}>
							<KeywordsFilter
								type={type || ''}
								filterKeywords={keywords}
								setFilterKeywords={setKeywords}
								setFilterKeywordsType={setFilterKeywordsType}
								filterKeywordsType={filterKeywordsType}
								isOpen={isOpenKeywordsModal}
								onClose={oncloseKeywordsModal}
								onOpen={onOpenKeywordsModal}
								isFilter
							/>
							<Spacer />
							<ArtCatalogSort sort={sort} setSort={setSort} />
						</Flex>
						<AssetsCards
							loading={loading}
							type={type}
							heroId={heroId}
							isHeroAddBackground={isHeroAddBackground}
							isSelected={isSelected}
							handleSelectAsset={handleSelectAsset}
							setSelectedAssets={setSelectedAssets}
							assetsData={data?.assets.assets || []}
							handleFetchMore={handleFetchMore}
						/>
					</Grid>
					<Flex
						justifyContent="space-between"
						alignItems="center"
						gap={5}
						px={6}
						py={4}
						borderTop="1px solid #eeeef2"
					>
						<Flex gap={1} fontSize="14px" fontWeight={600} lineHeight="16px">
							Selected: <Text>{selectedAssets.length} Items</Text>
						</Flex>
						<Flex gap={2}>
							<Button
								type="button"
								size="lg"
								variant="secondary"
								onClick={onCloseModal}
							>
								Cancel
							</Button>
							<Button
								name={ButtonType.CREATE_CHARACTER}
								type="button"
								size="lg"
								variant="primary"
								onClick={handleSubmit}
								isDisabled={isDisabled}
							>
								Apply
							</Button>
						</Flex>
					</Flex>
				</Grid>
			</ModalContent>
		</Modal>
	);
};
