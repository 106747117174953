import React, { useCallback } from 'react';
import {
	Box,
	Popover,
	PopoverBody,
	PopoverContent,
	PopoverTrigger,
	Tooltip,
	useDisclosure,
} from '@chakra-ui/react';
import { EmojiData, Picker } from 'emoji-mart';

import { ButtonType } from 'shared/analytics';
import { AnalyticsIconButton } from 'shared/analytics/ui/icon-button';
import { EmojiIcon } from 'shared/icons';

import './emoji-mart.normalize.scss';

interface EmojiPickerProps {
	onEmojiSelect: (emojiData: EmojiData) => void;
}

export const EmojiPicker: React.FC<EmojiPickerProps> = ({ onEmojiSelect }) => {
	const { onOpen, onClose, isOpen } = useDisclosure();

	const onSelect = useCallback(
		(emojiData: EmojiData) => {
			onClose();
			onEmojiSelect(emojiData);
		},
		[onClose, onEmojiSelect],
	);

	return (
		<Box position="relative">
			<Popover
				isOpen={isOpen}
				onOpen={onOpen}
				onClose={onClose}
				placement="top-end"
			>
				<PopoverTrigger>
					<Box>
						<Tooltip label="Emoji ()">
							<AnalyticsIconButton
								name={ButtonType.TOGGLE_EMOJI_SELECT}
								variant="ghost"
								size="square"
								aria-label="Emoji"
								icon={<EmojiIcon w={5} h={5} />}
							/>
						</Tooltip>
					</Box>
				</PopoverTrigger>
				<PopoverContent w="345px">
					<PopoverBody padding={0}>
						<Picker onSelect={onSelect} />
					</PopoverBody>
				</PopoverContent>
			</Popover>
		</Box>
	);
};
