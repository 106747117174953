import { FetchResult } from '@apollo/client';
import gql from 'graphql-tag';

import { apiInstance } from './base';
import { createHero_createHero } from './model/createHero';
import { CreateHeroRequestDto } from './model/globalTypes';

export const CREATE_HERO = gql`
	mutation createHero($hero: CreateHeroRequestDto!) {
		createHero(hero: $hero) {
			id
			names
			clothes {
				id
				name
				link
			}
			hairs {
				id
				name
				frontLink
				backLink
			}
			bodies {
				id
				name
				link
				gender
			}
			layers {
				price
				position
			}
		}
	}
`;

export const createHero = (
	hero: CreateHeroRequestDto,
	chapterId: string,
): Promise<FetchResult<createHero_createHero>> => {
	return apiInstance.mutate({
		mutation: CREATE_HERO,
		variables: {
			hero: { ...hero, chapters: [{ id: chapterId }] },
		},
	});
};
