import React from 'react';
import { Spinner } from '@chakra-ui/react';

import { Asset } from 'entities/assets/interfaces/asset';
import { AssetViewTabs } from 'widgets/update-asset-modal/lib/asset-view-tabs';

import { useFindOneClothesQuery } from '../modal/queries.gen';
import { ViewAsset } from '../view';

interface LoadClothesProps {
	assetId: string;
	activeTab: AssetViewTabs;
}

export const LoadClothes: React.FC<LoadClothesProps> = ({
	assetId,
	activeTab,
}) => {
	const { data, loading } = useFindOneClothesQuery({
		variables: {
			id: assetId,
		},
	});

	if (loading) return <Spinner />;

	return (
		<ViewAsset
			asset={data?.findOneClothes as Asset}
			chats={[]}
			activeTab={activeTab}
		/>
	);
};
