import React, { SetStateAction, useCallback, useMemo, useState } from 'react';
import { Box, Button, Flex, Grid, Text } from '@chakra-ui/react';
import {
	closestCenter,
	DndContext,
	DragEndEvent,
	DragOverlay,
	DragStartEvent,
	MouseSensor,
	TouchSensor,
	useSensor,
	useSensors,
} from '@dnd-kit/core';
import { restrictToFirstScrollableAncestor } from '@dnd-kit/modifiers';
import {
	arrayMove,
	rectSortingStrategy,
	SortableContext,
} from '@dnd-kit/sortable';

import { AssetType } from 'entities/assets/model';
import { useAppToast } from 'shared/hooks/toast';
import { PlusIcon } from 'shared/icons';

import { AssetInfo } from '../../../../../widgets/hero-update-modal';
import { AssetCardBackgrounds } from '../asset-card-background';
import { AssetCardBody } from '../asset-card-body';
import { AssetCardClothes } from '../asset-card-clothes';
import { AssetCardHair } from '../asset-card-hair';
import { AssetCardLayer } from '../asset-card-layer';

interface AssetsHeroProps {
	title: string;
	type: string;
	assets: string[];
	hairs: { id: string; name: string }[];
	defaultId?: string;
	openAddModal: () => void;
	setAssetType: (v: string) => void;
	currentAssetInfo?: AssetInfo;
	setCurrentAssetInfo?: React.Dispatch<SetStateAction<AssetInfo>>;
	handleSetFieldValue: (
		field: string,
		value: any,
		shouldValidate?: any,
	) => void;
	isAltAsset?: boolean;
	parentId?: string;
}

const ASSET_TYPE_TITLE_MAP: Record<string, string> = {
	[AssetType.BODY]: 'bodies',
	[AssetType.HAIR]: 'hairs',
	[AssetType.CLOTHES]: 'clothes',
	[AssetType.LAYER]: 'layers',
};

export const AssetsHero: React.FC<AssetsHeroProps> = ({
	title,
	type,
	assets,
	hairs,
	currentAssetInfo,
	setCurrentAssetInfo,
	openAddModal,
	setAssetType,
	handleSetFieldValue,
	defaultId,
	isAltAsset = false,
	parentId,
}) => {
	const handleClick = useCallback(() => {
		setAssetType(type);

		openAddModal();
	}, [type, setAssetType, openAddModal]);

	const toast = useAppToast();
	const handlePhotoDownload = useCallback(
		(imageLink: string, imageName: string) => {
			if (imageLink && imageName) {
				try {
					fetch(imageLink)
						.then((response) => response.blob())
						.then((blob) => {
							const url = window.URL.createObjectURL(blob);
							const link = document.createElement('a');
							link.href = url;
							link.setAttribute('download', imageName);
							document.body.appendChild(link);
							link.click();
							document.body.removeChild(link);
						});
				} catch (error) {
					toast({
						title: 'Image was not downloaded',
						status: 'error',
					});
				}
			}
		},
		[toast],
	);
	const renderCard = useCallback(
		(assetId: string) => {
			switch (type) {
				case 'clothes': {
					return (
						<AssetCardClothes
							parentId={parentId}
							key={assetId}
							defaultId={defaultId}
							assets={assets}
							assetId={assetId}
							setCurrentAssetInfo={setCurrentAssetInfo}
							handleSetFieldValue={handleSetFieldValue}
							isAltAsset={isAltAsset}
							isActive={assetId === currentAssetInfo?.clothesId}
							handlePhotoDownload={handlePhotoDownload}
						/>
					);
				}
				case 'hair': {
					return (
						<AssetCardHair
							key={assetId}
							assets={hairs}
							assetId={assetId}
							setCurrentAssetInfo={setCurrentAssetInfo}
							handleSetFieldValue={handleSetFieldValue}
							isActive={assetId === currentAssetInfo?.hairsId}
							handlePhotoDownload={handlePhotoDownload}
						/>
					);
				}
				case 'layer': {
					return (
						<AssetCardLayer
							key={assetId}
							assets={assets}
							assetId={assetId}
							setCurrentAssetInfo={setCurrentAssetInfo}
							handleSetFieldValue={handleSetFieldValue}
							isActive={
								!!currentAssetInfo?.layers.filter(
									(layer) => assetId === layer,
								)[0]
							}
							handlePhotoDownload={handlePhotoDownload}
						/>
					);
				}
				case 'body':
					return (
						<AssetCardBody
							key={assetId}
							assets={assets}
							assetId={assetId}
							setCurrentAssetInfo={setCurrentAssetInfo}
							handleSetFieldValue={handleSetFieldValue}
							isActive={assetId === currentAssetInfo?.bodyId}
							handlePhotoDownload={handlePhotoDownload}
						/>
					);
				case 'background':
					return (
						<AssetCardBackgrounds
							parentId={parentId}
							key={assetId}
							defaultId={defaultId}
							assets={assets}
							assetId={assetId}
							setCurrentAssetInfo={setCurrentAssetInfo}
							handleSetFieldValue={handleSetFieldValue}
							// isAltAsset={isAltAsset}
							isActive={assetId === currentAssetInfo?.clothesId}
							handlePhotoDownload={handlePhotoDownload}
						/>
					);
				default: {
					return null;
				}
			}
		},
		[
			type,
			assets,
			setCurrentAssetInfo,
			handleSetFieldValue,
			currentAssetInfo?.bodyId,
			currentAssetInfo?.clothesId,
			currentAssetInfo?.hairsId,
			currentAssetInfo?.layers,
			parentId,
			defaultId,
			isAltAsset,
			hairs,
			handlePhotoDownload,
		],
	);

	const [activeId, setActiveId] = useState<string>('');

	const mouseSensor = useSensor(MouseSensor, {
		activationConstraint: {
			distance: 5,
		},
	});
	const touchSensor = useSensor(TouchSensor);
	const sensors = useSensors(mouseSensor, touchSensor);

	const handleDragStart = useCallback((event: DragStartEvent) => {
		setActiveId(event.active.id.toString());
	}, []);

	const assetsArray = useMemo(
		() => (assets.length ? assets : hairs.map(({ id }) => id)),
		[assets, hairs],
	);

	const handleDragEnd = useCallback(
		(event: DragEndEvent) => {
			const { active, over } = event;
			if (active.id !== over?.id && over !== null) {
				const oldIndex = assetsArray.indexOf(active.id.toString());
				const newIndex = assetsArray.indexOf(over.id.toString());
				const assetsReturn = arrayMove(assetsArray, oldIndex, newIndex);
				const hairsReturn = arrayMove(assetsArray, oldIndex, newIndex).map(
					(hairId: string) => hairs.find(({ id }) => id === hairId),
				);
				handleSetFieldValue(
					ASSET_TYPE_TITLE_MAP[type],
					assets.length ? assetsReturn : hairsReturn,
				);
			}

			setActiveId('');
		},
		[assets.length, assetsArray, hairs, handleSetFieldValue, type],
	);

	const handleDragCancel = useCallback(() => {
		setActiveId('');
	}, []);

	return (
		<Box>
			<Text p={1} mb={3} fontSize="14px" lineHeight="16px">
				{title}
			</Text>
			<DndContext
				sensors={sensors}
				collisionDetection={closestCenter}
				onDragStart={handleDragStart}
				onDragEnd={handleDragEnd}
				onDragCancel={handleDragCancel}
				modifiers={[restrictToFirstScrollableAncestor]}
			>
				<SortableContext items={assetsArray} strategy={rectSortingStrategy}>
					<Grid
						templateColumns={`repeat(${isAltAsset ? 2 : 3}, 1fr)`}
						gap="20px"
						w={isAltAsset ? '600px' : '760px'}
						overflowX={isAltAsset ? 'hidden' : 'inherit'}
					>
						{assetsArray.map((id) => renderCard(id))}

						<Flex flexDirection="column" maxW="240px" w="240px">
							<Flex
								height={
									type === 'hair' || type === 'background' ? '180px' : '300px'
								}
								alignItems="center"
								justifyContent="center"
								border="1px solid #EEEEF2"
								borderRadius="10px"
								mt={type === 'background' ? '4px' : 0}
							>
								<Button leftIcon={<PlusIcon />} onClick={handleClick}>
									Add
								</Button>
							</Flex>
						</Flex>
					</Grid>
				</SortableContext>
				<DragOverlay style={{ transformOrigin: '0 0' }}>
					{activeId ? (
						<Box backgroundColor="#ffffff">{renderCard(activeId)}</Box>
					) : null}
				</DragOverlay>
			</DndContext>
		</Box>
	);
};
