import * as Types from '../../../../../models.gen';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AssetCardClothesByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type AssetCardClothesByIdQuery = { findOneClothes: { link?: string | null, name?: string | null } };


export const AssetCardClothesByIdDocument = gql`
    query AssetCardClothesById($id: String!) {
  findOneClothes(id: $id) {
    link
    name
  }
}
    `;

/**
 * __useAssetCardClothesByIdQuery__
 *
 * To run a query within a React component, call `useAssetCardClothesByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssetCardClothesByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssetCardClothesByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAssetCardClothesByIdQuery(baseOptions: Apollo.QueryHookOptions<AssetCardClothesByIdQuery, AssetCardClothesByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AssetCardClothesByIdQuery, AssetCardClothesByIdQueryVariables>(AssetCardClothesByIdDocument, options);
      }
export function useAssetCardClothesByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AssetCardClothesByIdQuery, AssetCardClothesByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AssetCardClothesByIdQuery, AssetCardClothesByIdQueryVariables>(AssetCardClothesByIdDocument, options);
        }
export type AssetCardClothesByIdQueryHookResult = ReturnType<typeof useAssetCardClothesByIdQuery>;
export type AssetCardClothesByIdLazyQueryHookResult = ReturnType<typeof useAssetCardClothesByIdLazyQuery>;
export type AssetCardClothesByIdQueryResult = Apollo.QueryResult<AssetCardClothesByIdQuery, AssetCardClothesByIdQueryVariables>;