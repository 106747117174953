export const downloadCanvas = (
	canvas: HTMLCanvasElement | null,
	filename: string,
	type: string,
) => {
	if (canvas) {
		const dataUrl = canvas.toDataURL(`image/${type}`, 1);
		const anchor = document.createElement('a');

		anchor.href = dataUrl;
		anchor.setAttribute('download', filename || '');

		document.body.appendChild(anchor);
		anchor.click();
		document.body.removeChild(anchor);
	}
};
