import { uniqBy } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { convertScript_convertScript_errors } from 'shared/api/graphql/model/convertScript';

import { ErrorWithId } from '../types';

export const handleErrors = (
	_: ErrorWithId[],
	data: { errorsPositions: convertScript_convertScript_errors[] },
) => {
	const sortedErrors = data.errorsPositions.sort(
		(a, b) => (a?.line || 0) - (b?.line || 0),
	);

	const uniqueErrors = uniqBy(sortedErrors, 'message');

	return uniqueErrors.map((error) => ({ ...error, id: uuidv4() }));
};

export const handleWarnings = (
	_: { id: string; message: string; line: number; position: number }[],
	data: {
		warningsPositions: { message: string; line: number; position: number }[];
	},
) => {
	const sortedWarnings = data.warningsPositions.sort(
		(a, b) => (a?.line || 0) - (b?.line || 0),
	);

	const uniqueWarnings = uniqBy(sortedWarnings, 'message');

	return uniqueWarnings.map((error) => ({ ...error, id: uuidv4() }));
};
