import React, { memo } from 'react';
import { Badge, Flex } from '@chakra-ui/react';

import styles from './styles.module.scss';

interface BookDetailsProps {
	bookName?: string | null;
	bookId?: string | null;
	bookAuthor?: string | null;
}
type Author = {
	id: string;
	fullName?: string;
	picture?: string;
};
const mapAuthor = (authors?: string | null) => {
	if (!authors) return null;
	try {
		return JSON.parse(authors || '').map((author: Author) => (
			<Badge>{author.fullName}</Badge>
		));
	} catch (e) {
		return <Badge>{authors}</Badge>;
	}
};

export const BookDetails: React.FC<BookDetailsProps> = memo(
	({ bookName, bookId, bookAuthor }) => {
		return (
			<div className={styles.root}>
				<div className={styles.detail}>
					<p className={styles.text}>Story Name</p>
					<p className={styles.text}>{bookName || '---'}</p>
				</div>
				<div className={styles.detail}>
					<p className={styles.text}>ID</p>
					<p className={styles.text}>{bookId || '---'}</p>
				</div>
				{/* <div className={styles.detail}>
					<p className={styles.text}>Story Status</p>
					<p className={styles.text}>
						{bookStatus ? (
							<Badge variant={bookStatus.toLowerCase()}>{bookStatus}</Badge>
						) : (
							<>&nbsp;&nbsp;---</>
						)}
					</p>
				</div> */}
				<div className={styles.detail}>
					<p className={styles.text}>Author Name</p>
					<Flex className={styles.text} gap="5px" maxW="470px" overflowX="auto">
						{mapAuthor(bookAuthor) || '---'}
					</Flex>
				</div>
			</div>
		);
	},
);
