import React from 'react';
import { createIcon } from '@chakra-ui/react';

export const FireIcon = createIcon({
	viewBox: '0 0 14 15',
	path: (
		<>
			<g clipPath="url(#clip0_614_20329)">
				<path
					d="M7.02511 14.058C10.2699 14.058 12.4101 12.5252 12.4101 9.51221C12.4101 5.42554 8.01428 4.96732 8.00428 1.3425C8.00428 1.33614 7.99882 1.33159 7.99246 1.33341C6.52507 1.7989 4.58129 4.5182 6.04686 6.78473C6.53598 7.69389 5.55591 8.60305 4.57766 8.60305C2.26839 8.60305 2.61933 5.87557 2.1302 6.78473C0.320968 10.1432 2.54841 14.058 7.02511 14.058Z"
					stroke="currentColor"
					fill="none"
					strokeWidth="1.4"
				/>
			</g>
			<defs>
				<clipPath id="clip0_614_20329">
					<rect
						width="13"
						height="15"
						fill="white"
						transform="translate(0.333496)"
					/>
				</clipPath>
			</defs>
		</>
	),
});
