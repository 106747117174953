import React, { useCallback, useState } from 'react';
import { Flex, Text } from '@chakra-ui/react';

import { ButtonType } from 'shared/analytics';
import { EditableField } from 'shared/ui';
import { ImageView } from 'shared/ui/image-view';

import { useRenameImageMutation } from '../queries.gen';

export interface Props {
	imageName: string;
	imageLink: string;
	onDelete: (v: number) => void;
	setValue: (preV: string, v: string) => void;
	index: number;
	customStyle?: { h: string };
	error?: string;
}

export const ImageReviewBlock: React.FC<Props> = ({
	imageName,
	imageLink,
	onDelete,
	setValue,
	index,
	customStyle = null,
	error,
}) => {
	const [link, setLink] = useState(imageLink);

	const [renameImage] = useRenameImageMutation();

	const handleImageDelete = useCallback(() => {
		onDelete(index);
	}, [onDelete, index]);

	const onNameChange = useCallback(
		async (filename: string) => {
			if (link) {
				const res = await renameImage({
					variables: { link, name: filename },
				});
				setValue(link, res.data?.renameFile || '');
				setLink(res.data?.renameFile || '');
			}
		},
		[link, renameImage, setValue],
	);

	return (
		<Flex flexDirection="column" w="240px">
			<ImageView
				link={link}
				type="emotion"
				handlePhotoDelete={handleImageDelete}
				customStyle={customStyle}
			/>

			<Flex
				w="240px"
				mt="8px"
				alignItems="center"
				justifyContent="space-between"
			>
				<EditableField
					submitButtonName={ButtonType.EDIT_ASSET_FILE_NAME}
					onApply={onNameChange}
					initialValue={imageName}
				/>
			</Flex>
			{error && (
				<Text
					pt={2}
					fontWeight={600}
					fontSize="13px"
					lineHeight="17px"
					color="#ff218c"
				>
					{error}
				</Text>
			)}
		</Flex>
	);
};
