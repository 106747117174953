import React from 'react';
import { Flex, Text } from '@chakra-ui/react';

interface VolumeEntity {
	id: string;
	name: string | null;
	bookCustomId?: string | null;
}
interface Props {
	volume: VolumeEntity[];
}

export const Volume: React.FC<Props> = ({ volume }) => {
	return (
		<>
			{volume.map((item, index) => (
				<Flex
					key={item.id}
					justifyContent="space-between"
					alignItems="center"
					p="16px 20px 16px 22px"
					borderBottom="1px solid #eeeef2"
				>
					<Flex alignItems="center">
						<Text mr="42px" fontWeight={400} fontSize="13px" lineHeight="16px">
							{index + 1}
						</Text>
						{item.bookCustomId ? (
							<Text
								mr="10px"
								fontWeight={600}
								fontSize="14px"
								lineHeight="16px"
								color="#644DED"
							>
								[{item.bookCustomId}]
							</Text>
						) : null}
						<Text fontWeight={600} fontSize="14px" lineHeight="16px">
							{item.name}
						</Text>
					</Flex>
				</Flex>
			))}
		</>
	);
};
