import { useEffect } from 'react';
import { useStore } from 'effector-react';

import { scriptModel } from 'entities/script';
import { userModel } from 'entities/user';

let lastNumber = -1;
export const useGetDialogNumber = (dialog: string, offsetKey: string) => {
	const luaContent = useStore(scriptModel.$luaValue);
	const { isDialogues } = useStore(userModel.$features);
	const editorSearchValue = useStore(scriptModel.$editorSearchValue);
	const blockToFindFocus = useStore(scriptModel.$blockToFindFocus);
	const dialogueValue = useStore(scriptModel.$editorSearchDialogueValue);
	const searchType = useStore(scriptModel.$searchType);

	useEffect(() => {
		lastNumber = -1;
	}, [
		luaContent,
		isDialogues,
		editorSearchValue,
		blockToFindFocus,
		dialogueValue,
		searchType,
	]);
	if (!dialog || !offsetKey) return;

	const dialogueTextRegex =
		/dialogs\.(?:ShowDialog|ShowMiddleDialog|ShowCallDialog|ShowVideocallDialog|ShowSexyVideocallDialog)[^)]*\([^)]*".*"[^)]*\)/g;
	const DialogueNumberRegex = /dialogs\.SaveProgress\("showDialog([^)]+)"\)/;

	const luaParseBlocks = luaContent.split('function');

	const dialogEl = luaParseBlocks.find((el) => {
		const isCurrentText =
			[...(el.match(dialogueTextRegex) || [])][0]
				?.split('"')[1]
				.replaceAll('#!#', '') === dialog;

		const dialogueNumber =
			el?.match(DialogueNumberRegex)?.map((v) => v)[1] || '';

		if (!isCurrentText || lastNumber > Number(dialogueNumber)) return false;

		lastNumber = Number(dialogueNumber);

		return true;
	});

	const dialogueNumber =
		dialogEl?.match(DialogueNumberRegex)?.map((v) => v)[1] || '';

	const currentBlock = document.querySelector(
		`div[data-offset-key="${offsetKey}"]`,
	);

	if (isDialogues) {
		currentBlock?.setAttribute(
			'data-dialogue',
			dialogueNumber ? `Dialogue ${dialogueNumber}` : '',
		);
	} else {
		currentBlock?.removeAttribute('data-dialogue');
	}
};
