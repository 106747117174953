export interface PromptProps {
	value: string;
	option?: string;
}

export const PROMPTS_MAP: Record<string, string> = {
	IMPROVE_WRITING: 'Improve writing',
	FIX: 'Fix spelling & grammar',
	CHANGE_TONE: 'Change tone',
	PARAPHRASING: 'Paraphrasing',
	REPHRASING: 'Rephrasing',
	OUTLINE: 'Creating an outline for a story',
	CHECKING: 'Checking whether something sounds authentic',
	CUSTOM: 'Custom',
};

export const PROMPTS_INFO_MAP: Record<string, string> = {
	[PROMPTS_MAP.IMPROVE_WRITING]:
		'Analyze the narrative and identify areas where improvements can be made.' +
		' Suggest ways to enhance the writing to make it more engaging and impactful. Text:',
	[PROMPTS_MAP.FIX]:
		'Utilize language processing technology to identify spelling and grammar errors in the text.' +
		' Provide recommended corrections and suggestions for improving the overall readability. Text:',
	[PROMPTS_MAP.CHANGE_TONE]:
		'Use language models to identify and analyze the tone of the text.' +
		' Suggest modifications to create the desired tone for the text. Text:',
	[PROMPTS_MAP.PARAPHRASING]:
		'Utilize natural language processing to reword sentences in the text while retaining the original meaning. ' +
		'Provide alternative phrasings for sentences or paragraphs. Text:',
	[PROMPTS_MAP.REPHRASING]:
		'Use language models to analyze the text and identify areas that could be rephrased for greater clarity and concision. ' +
		'Offer alternative sentence structures to improve the flow of the text Text:',
	[PROMPTS_MAP.OUTLINE]:
		'Leverage AI technology to generate a comprehensive outline for the story,' +
		' including plot twists, character development, and pacing recommendations. Text:',
	[PROMPTS_MAP.CHECKING]:
		'Use language models to analyze the text and provide feedback on whether the language used sounds authentic and natural.' +
		' Offer suggestions for more appropriate language usage and consistency in character dialogue and behavior Text:',
	[PROMPTS_MAP.CUSTOM]: '',
};

export const PROMPTS_INFO_EVENTS: Record<string, string> = {
	[PROMPTS_MAP.IMPROVE_WRITING]: 'improve-writing',
	[PROMPTS_MAP.FIX]: 'fix',
	[PROMPTS_MAP.PARAPHRASING]: 'paraphrasing',
	[PROMPTS_MAP.REPHRASING]: 'rephrasing',
	[PROMPTS_MAP.OUTLINE]: 'outline',
	[PROMPTS_MAP.CHECKING]: 'checking',
	[PROMPTS_MAP.CUSTOM]: 'custom',
};

export const CHANGE_TONE_EVENTS: Record<string, string> = {
	Professional: 'change-tone-professional',
	Casual: 'change-tone-casual',
	Friendly: 'change-tone-friendly',
	Confident: 'change-tone-confident',
	Straightforward: 'change-tone-straightforward',
	Flirty: 'change-tone-flirty',
	Romantic: 'change-tone-romantic',
};

export const changeToneOptions: string[] = [
	'Professional',
	'Casual',
	'Friendly',
	'Confident',
	'Straightforward',
	'Flirty',
	'Romantic',
];

export interface AmplitudeInfo {
	bookCustomId?: string | null;
	chapterOrder?: number | null;
	doc?: string | null;
}

export const DOC_TYPES_MAP: Record<string, string> = {
	googleDoc: 'prod',
	googleDoc2Pov: 'prod2POV',
	googleDocNarrative: 'narrator',
	googleDocWriter: 'author',
	googleDocES: 'prodES',
	googleDocFR: 'prodFR',
	googleDocDE: 'prodDE',
	googleDocABTest1: 'prodB',
	googleDocABTest2: 'prodC',
	googleDocABTest3: 'prodD',
};
