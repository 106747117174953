import React from 'react';
import { createIcon } from '@chakra-ui/react';

export const SizeDownIcon = createIcon({
	viewBox: '0 0 24 24',
	path: (
		<path
			d="M6 14h4v4M18 10h-4V6M14 10l7-7M3 21l7-7"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	),
});
