import React, { useState } from 'react';
import {
	Box,
	Button,
	Flex,
	Popover,
	PopoverBody,
	PopoverContent,
	PopoverTrigger,
	useDisclosure,
} from '@chakra-ui/react';

import { ButtonType } from 'shared/analytics';
import { PinIcon } from 'shared/icons';
import { MultipleChoiceList } from 'shared/ui';

import { useKeywordsAssetsQuery } from './queries.gen';

interface KeywordsAssetsProps {
	type: string;
	keywords: string[];
	setKeywords: (v: string[]) => void;
}

const KeywordsAssets: React.FC<KeywordsAssetsProps> = ({
	type,
	keywords,
	setKeywords,
}) => {
	const { onOpen, onClose, isOpen } = useDisclosure();
	const [selectedKeywords, setSelectedKeywords] = useState<string[]>([]);

	const { data } = useKeywordsAssetsQuery({ variables: { type } });

	return (
		<Popover
			isOpen={isOpen}
			onOpen={onOpen}
			onClose={onClose}
			placement="bottom-end"
		>
			<PopoverTrigger>
				<Button variant="secondary">
					<PinIcon marginRight="8px" />
					Keywords
					{keywords.length > 0 && (
						<Box
							fontSize="10px"
							fontWeight={500}
							lineHeight="16px"
							color="white"
							borderRadius="50%"
							background="#644ded"
							ml="5px"
							padding="2px 7px"
						>
							{keywords.length}
						</Box>
					)}
				</Button>
			</PopoverTrigger>
			<PopoverContent>
				<PopoverBody maxW={290} maxH={270}>
					<MultipleChoiceList
						selectedOptions={selectedKeywords}
						options={data?.keywords || []}
						onChange={setSelectedKeywords}
						isKeywordsChoice
					/>
					<Flex justifyContent="flexEnd">
						<Button
							name={ButtonType.CLEAR_CHARACTER_KEYWORDS}
							type="button"
							size="md"
							variant="secondary"
							onClick={() => {
								setSelectedKeywords([]);
								setKeywords([]);
								onClose();
							}}
						>
							Reset All
						</Button>
						<Button
							type="button"
							size="md"
							variant="secondary"
							onClick={() => {
								setSelectedKeywords([...keywords]);
								onClose();
							}}
						>
							Cancel
						</Button>
						<Button
							name={ButtonType.SET_CHARACTER_KEYWORDS}
							type="submit"
							size="md"
							variant="secondary"
							onClick={() => {
								setKeywords([...selectedKeywords]);
								onClose();
							}}
						>
							Apply
						</Button>
					</Flex>
				</PopoverBody>
			</PopoverContent>
		</Popover>
	);
};

export default KeywordsAssets;
