import * as yup from 'yup';

import { spaceValidation } from '../yup-validation';

export const updateHairSchema = yup.object({
	id: yup.string().trim().required(),
	frontLink: spaceValidation.url().nullable(),
	backLink: spaceValidation.url().nullable(),
	filename: spaceValidation.min(5).max(255).nullable(),
	dropboxLink: spaceValidation,
	keywords: yup.array().of(yup.string().trim().required()),
	newKeywords: yup
		.object()
		.shape({
			sex: yup.array().nullable(),
			app: yup.array().nullable(),
			art_style: yup.array().nullable(),
			body_type: yup.array().nullable(),
			color: yup.array().nullable(),
			structure: yup.array().nullable(),
			age: yup.array().nullable(),
			length: yup.array().nullable(),
			style: yup.array().nullable(),
			hairstyle: yup.array().nullable(),
			extra: yup.array().nullable(),
		})
		.nullable(),
	newHair: yup.boolean(),
});

export type UpdateHairValues = yup.InferType<typeof updateHairSchema>;
