import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Button, Flex, Spinner, VStack } from '@chakra-ui/react';

import { GetBookDocument } from 'features/book/queries.gen';
import { useGenerateChapterDocMutation } from 'features/chapter/update/queries.gen';
import { UpdateDoc } from 'features/chapter/update/update-doc';
import { ChapterByIdDocument } from 'features/story-editor/header/queries.gen';
import { trackPropertyChanged } from 'shared/analytics';
import { useAppToast } from 'shared/hooks/toast';
import { PlusIcon } from 'shared/icons';

export interface DocVersion {
	id: string;
	docOrder?: number | null;
	docNotes?: { id: string; name?: string | null } | null;
}

interface Props {
	bookId: string;
	chapterId: string;
	additionalDocs?: DocVersion[] | null;
	handleRedirect: (v: string) => void;
}

export const UpdateAdditionalDocsForm: React.FC<Props> = ({
	bookId,
	chapterId,
	additionalDocs,
	handleRedirect,
}) => {
	const toast = useAppToast();
	const [docVersions, setDocVersions] = useState(additionalDocs);

	const [generateChapterDoc, { loading: isLoading }] =
		useGenerateChapterDocMutation({
			refetchQueries: [ChapterByIdDocument, GetBookDocument],
		});
	const generateAdditionalDoc = useCallback(async () => {
		try {
			const link = await generateChapterDoc({
				variables: {
					bookId,
					chapterId,
					config: {
						language: 'en',
						type: 'additional',
					},
				},
			});
			trackPropertyChanged({
				entity: 'chapter',
				property: 'additional',
				value: link.data?.createChapterDocByConfig.id || '',
				id: chapterId,
			});
			toast({
				title: 'Doc generated successfully',
				status: 'success',
			});
		} catch {
			toast({
				title: 'Doc was not generated',
				status: 'error',
			});
		}
	}, [bookId, chapterId, generateChapterDoc, toast]);

	const isAllVersionsAdded = useMemo(
		() => docVersions && docVersions.length > 9,
		[docVersions],
	);

	useEffect(() => {
		setDocVersions(additionalDocs);
	}, [additionalDocs]);

	return (
		<Box>
			<Flex py="9px" align="center" justify="space-between">
				{isLoading ? (
					<Spinner size="md" m="flex-start" />
				) : (
					<Button
						leftIcon={<PlusIcon />}
						variant="ghost"
						disabled={isLoading || isAllVersionsAdded || false}
						onClick={generateAdditionalDoc}
					>
						New Additional Docs
					</Button>
				)}
			</Flex>
			<VStack align="stretch">
				{docVersions &&
					docVersions
						.sort((a, b) => (a.docOrder || 0) - (b.docOrder || 0))
						.map((docVersion: DocVersion) => (
							<UpdateDoc
								key={docVersion.docOrder}
								label={`Additional Doc ${docVersion.docOrder}`}
								documentId={docVersion.id}
								docNotes={docVersion?.docNotes}
								handleRedirect={handleRedirect}
							/>
						))}
			</VStack>
		</Box>
	);
};
