import React, { useCallback, useMemo } from 'react';
import {
	Button,
	ButtonGroup,
	Checkbox,
	Spinner,
	Text,
	VStack,
} from '@chakra-ui/react';
import { useFormik } from 'formik';

import { ButtonType } from 'shared/analytics';

import { useChapterNamesByStoryIdQuery } from './queries.gen';

interface Chapter {
	id: string;
	name?: string | null | undefined;
	chapterOrder?: number | null | undefined;
}

interface BookPopoverFormikParams {
	chapters: Chapter[];
}

interface ChaptersPopoverProps {
	bookId: string;
	selectedChapters: readonly Chapter[] | null | undefined;
	handleSubmit: (chapters: Chapter[]) => Promise<void>;
	onClose: () => void;
}

export const ChaptersPopover: React.FC<ChaptersPopoverProps> = ({
	bookId,
	selectedChapters,
	handleSubmit,
	onClose,
}) => {
	const { data, loading } = useChapterNamesByStoryIdQuery({
		variables: { id: bookId },
	});

	const formik = useFormik({
		initialValues: {
			chapters: selectedChapters || [],
		} as BookPopoverFormikParams,
		onSubmit: (values) => {
			handleSubmit(values.chapters);
			onClose();
		},
	});

	const isSelectedChapter = useCallback(
		(chapterId: string) =>
			formik.values.chapters.some((chapter) => chapter.id === chapterId),
		[formik.values.chapters],
	);

	const isSelectedAllChapters = useMemo(() => {
		if (!formik.values.chapters.length) {
			return false;
		}

		const chaptersIds = (data?.book?.chapters || []).map(
			(chapter) => chapter.id,
		);
		const selectedChaptersIds = formik.values.chapters.map(
			(chapter) => chapter.id,
		);

		return !chaptersIds.some(
			(chapter) => !selectedChaptersIds.includes(chapter),
		);
	}, [data?.book.chapters, formik.values.chapters]);

	const handleSelectAllChaptersToggle = useCallback(() => {
		if (isSelectedAllChapters) {
			formik.resetForm();
			formik.values.chapters = [];
			return;
		}
		formik.setFieldValue('chapters', data?.book?.chapters);
	}, [data?.book?.chapters, formik, isSelectedAllChapters]);

	const handleChapterSelectToggle = useCallback(
		(chapter: Chapter) => async () => {
			if (isSelectedChapter(chapter.id)) {
				await formik.setFieldValue(
					'chapters',
					formik.values.chapters.filter((c) => c.id !== chapter.id),
				);
				return;
			}
			await formik.setFieldValue('chapters', [
				...formik.values.chapters,
				chapter,
			]);
		},
		[formik, isSelectedChapter],
	);

	if (loading) return <Spinner size="md" m="auto" />;

	return (
		<>
			<Checkbox
				isChecked={isSelectedAllChapters}
				onChange={handleSelectAllChaptersToggle}
				mb="23px"
			>
				<Text fontSize="13px" lineHeight="16px">
					Select All
				</Text>
			</Checkbox>
			<VStack align="stretch" gap={23} h={181} overflow="auto">
				{[...(data?.book?.chapters || [])]
					.sort((a, b) => (a?.chapterOrder || 0) - (b?.chapterOrder || 0))
					.map((chapter) => (
						<Checkbox
							key={chapter.id}
							isChecked={isSelectedChapter(chapter.id)}
							onChange={handleChapterSelectToggle(chapter)}
						>
							<Text
								fontSize="13px"
								lineHeight="16px"
								overflow="hidden"
								whiteSpace="nowrap"
								textOverflow="ellipsis"
							>
								{chapter.chapterOrder}.{chapter.name}
							</Text>
						</Checkbox>
					))}
			</VStack>
			<ButtonGroup pt="5px" spacing="8px" justifyContent="flex-end">
				<Button variant="ghost" onClick={onClose}>
					Cancel
				</Button>
				<Button
					name={ButtonType.ADD_CHARACTERS_TO_CHAPTER}
					onClick={formik.submitForm}
				>
					Apply
				</Button>
			</ButtonGroup>
		</>
	);
};
