import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
	Box,
	Button,
	Center,
	IconButton,
	Input,
	InputGroup,
	InputLeftElement,
	InputRightElement,
	Popover,
	PopoverBody,
	PopoverContent,
	PopoverTrigger,
	Spinner,
	useDisclosure,
} from '@chakra-ui/react';

import { BookIcon, CrossIcon, SearchIcon } from 'shared/icons';
import { SingleChoiceList } from 'shared/ui';
import { StoriesQuery } from 'widgets/existing-heroes-modal/graphql/queries.gen';

interface SearchStoryProps {
	stories: StoriesQuery['books'];
	loadingStories: boolean;
	setSelectStory: (v: string) => void;
	searchStory: string;
	setSearchStory: (v: string) => void;
}

const SearchStory: React.FC<SearchStoryProps> = ({
	stories,
	loadingStories,
	setSelectStory,
	searchStory,
	setSearchStory,
}) => {
	const { onOpen, onClose, isOpen } = useDisclosure();

	const [selectedStoryId, setSelectedStoryId] = useState('');

	const storiesNames = useMemo(
		() =>
			stories
				.filter((story) => !!story.name)
				.map((story) => ({
					value: story.id,
					title: story.name || '',
				})) || [],
		[stories],
	);

	const setSelectedSort = (id: string) => {
		onClose();
		setSelectedStoryId(id);
	};

	const onSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = e.currentTarget;
		setSearchStory(value);
	};

	const onCrossClick = useCallback(() => {
		onClose();
		setSelectedStoryId('');
		setSearchStory('');
	}, [onClose, setSearchStory]);

	const selectedStory = useMemo(
		() => stories?.find(({ id }) => id === selectedStoryId),
		[stories, selectedStoryId],
	);

	useEffect(() => {
		setSelectStory(selectedStory?.id || '');
	}, [selectedStory, setSelectStory]);

	return (
		<Popover
			isOpen={isOpen}
			onOpen={onOpen}
			onClose={onClose}
			variant="singleList"
			placement="bottom-start"
		>
			<PopoverTrigger>
				<Button size="md" variant="secondary">
					<BookIcon marginRight="8px" />
					Story{selectedStory?.name ? `: ${selectedStory.name}` : ''}
				</Button>
			</PopoverTrigger>
			<PopoverContent maxW={270}>
				<PopoverBody maxW={270} maxH={270} p={0}>
					<Box
						position="sticky"
						pt={2}
						px={3}
						pb={2}
						bgColor="#fdfdfd"
						zIndex={10}
					>
						<InputGroup>
							<InputLeftElement
								pointerEvents="none"
								color="#BCBCC6"
								fontSize="1.2em"
							>
								<SearchIcon />
							</InputLeftElement>
							<Input
								height="44px"
								fontSize="13px"
								fontWeight={500}
								lineHeight="16px"
								pl="40px"
								placeholder="Search by name or id"
								value={searchStory || selectedStory?.name || ''}
								onChange={onSearchInputChange}
							/>
							{(selectedStoryId || searchStory) && (
								<InputRightElement
									cursor="pointer"
									color="#BCBCC6"
									fontSize="0.6em"
									onClick={onCrossClick}
								>
									<IconButton
										variant="ghost"
										w="28px"
										h="28px"
										minW="auto"
										flexShrink={0}
										aria-label="Clear search input"
										icon={<CrossIcon w={3} h={3} />}
									/>
								</InputRightElement>
							)}
						</InputGroup>
					</Box>
					<Box px={2} pb={2} overflow="auto">
						{loadingStories ? (
							<Center>
								<Spinner size="lg" m="auto" />
							</Center>
						) : (
							<SingleChoiceList
								options={storiesNames}
								selectedOption={selectedStoryId}
								onChange={setSelectedSort}
							/>
						)}
					</Box>
				</PopoverBody>
			</PopoverContent>
		</Popover>
	);
};

export default SearchStory;
