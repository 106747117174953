/* eslint-disable react-hooks/exhaustive-deps */
import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogCloseButton,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	Button,
	Checkbox,
	HStack,
	Text,
} from '@chakra-ui/react';

import { ButtonType } from 'shared/analytics';
import {
	trackDeleteAssetBackgroundModalClose,
	trackDeleteAssetBodyModalClose,
	trackDeleteAssetClothesModalClose,
	trackDeleteAssetEmotionModalClose,
	trackDeleteAssetHairModalClose,
	trackDeleteAssetItemModalClose,
	trackDeleteAssetLayerModalClose,
	trackDeleteBookModalClose,
	trackDeleteChapterModalClose,
	trackDeleteHeroModalClose,
} from 'shared/analytics/modals/close';
import {
	trackDeleteAssetBackgroundModalOpen,
	trackDeleteAssetBodyModalOpen,
	trackDeleteAssetClothesModalOpen,
	trackDeleteAssetEmotionModalOpen,
	trackDeleteAssetHairModalOpen,
	trackDeleteAssetItemModalOpen,
	trackDeleteAssetLayerModalOpen,
	trackDeleteBookModalOpen,
	trackDeleteChapterModalOpen,
	trackDeleteHeroModalOpen,
} from 'shared/analytics/modals/open';

interface DeletionAlertProps {
	title?: string;
	description?: string;
	onSubmit: () => void;
	isOpen: boolean;
	onClose: () => void;
	buttonName: ButtonType;
	checked?: boolean;
	isDeleteGoogleDrive?: boolean;
	onChecked?: () => void;
}

const modalTrack = {
	[ButtonType.DELETE_BOOK]: {
		open: trackDeleteBookModalOpen,
		close: trackDeleteBookModalClose,
	},
	[ButtonType.DELETE_CHAPTER]: {
		open: trackDeleteChapterModalOpen,
		close: trackDeleteChapterModalClose,
	},
	[ButtonType.DELETE_HERO]: {
		open: trackDeleteHeroModalOpen,
		close: trackDeleteHeroModalClose,
	},
	[ButtonType.DELETE_HAIR]: {
		open: trackDeleteAssetHairModalOpen,
		close: trackDeleteAssetHairModalClose,
	},
	[ButtonType.DELETE_CLOTHES]: {
		open: trackDeleteAssetClothesModalOpen,
		close: trackDeleteAssetClothesModalClose,
	},
	[ButtonType.DELETE_BODY]: {
		open: trackDeleteAssetBodyModalOpen,
		close: trackDeleteAssetBodyModalClose,
	},
	[ButtonType.DELETE_LAYER]: {
		open: trackDeleteAssetLayerModalOpen,
		close: trackDeleteAssetLayerModalClose,
	},
	[ButtonType.DELETE_ITEM]: {
		open: trackDeleteAssetItemModalOpen,
		close: trackDeleteAssetItemModalClose,
	},
	[ButtonType.DELETE_BACKGROUND]: {
		open: trackDeleteAssetBackgroundModalOpen,
		close: trackDeleteAssetBackgroundModalClose,
	},
	[ButtonType.DELETE_EMOTION]: {
		open: trackDeleteAssetEmotionModalOpen,
		close: trackDeleteAssetEmotionModalClose,
	},
};

export const EntityDeletionAlert: React.FC<DeletionAlertProps> = ({
	title = 'Deletion',
	description = "If you delete this entity, it can't be restored. Are you sure you want to continue?",
	isOpen,
	onClose,
	onSubmit,
	buttonName,
	isDeleteGoogleDrive,
	onChecked,
	checked,
}) => {
	const [isPending, setPending] = useState(false);
	const cancelRef = useRef(null);

	const handleAlertSubmit = useCallback(async () => {
		setPending(true);
		await onSubmit();
		onClose();
	}, [onClose, onSubmit]);

	const chooseModalTrack = useMemo(
		() => modalTrack[buttonName as keyof typeof modalTrack],
		[buttonName],
	);

	useEffect(() => {
		if (isOpen && chooseModalTrack) {
			chooseModalTrack.open();
		}
	}, []);

	return (
		<AlertDialog
			isOpen={isOpen}
			leastDestructiveRef={cancelRef}
			onClose={() => {
				onClose();
				if (chooseModalTrack) {
					chooseModalTrack.close();
				}
			}}
			closeOnOverlayClick={!isPending}
		>
			<AlertDialogOverlay>
				<AlertDialogContent>
					<AlertDialogHeader fontSize="lg" fontWeight="bold">
						{title}
					</AlertDialogHeader>
					<AlertDialogCloseButton />
					<AlertDialogBody>
						{description}
						{checked && (
							<HStack mt={4} alignItems="center">
								<Checkbox
									isChecked={isDeleteGoogleDrive}
									onChange={onChecked}
								/>
								<Text fontSize="13px" fontWeight="500">
									Delete the folder on Google Drive
								</Text>
							</HStack>
						)}
					</AlertDialogBody>
					<AlertDialogFooter>
						<Button
							disabled={isPending}
							ref={cancelRef}
							variant="ghost"
							onClick={() => {
								onClose();
								if (chooseModalTrack) {
									chooseModalTrack.close();
								}
							}}
						>
							Cancel
						</Button>
						<Button
							disabled={isPending}
							name={buttonName}
							onClick={handleAlertSubmit}
							ml={3}
						>
							Delete
						</Button>
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialogOverlay>
		</AlertDialog>
	);
};
