import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Spinner } from '@chakra-ui/react';

import {
	Asset,
	AssetBook,
	AssetChapter,
} from 'entities/assets/interfaces/asset';
import { AssetViewTabs } from 'widgets/update-asset-modal/lib/asset-view-tabs';
import { removeDuplicateIds } from 'widgets/update-asset-modal/utils/remove-duplicate-ids';

import { useGetItemQuery } from '../modal/queries.gen';
import { ViewAsset } from '../view';

interface LoadItemProps {
	assetId: string;
	activeTab: AssetViewTabs;
}

export const LoadItem: React.FC<LoadItemProps> = ({ assetId, activeTab }) => {
	const { data, loading, refetch } = useGetItemQuery({
		variables: {
			id: assetId,
		},
	});

	const [assetChapters, setAssetChapters] = useState<AssetChapter[]>([]);
	const [assetBooks, setAssetBooks] = useState<AssetBook[]>([]);

	const setBooks = useCallback((chapters: AssetChapter[]) => {
		chapters.forEach((chapter) => {
			if (chapter.book) {
				setAssetBooks((oldAssetBooks) => [
					...oldAssetBooks,
					chapter.book as AssetBook,
				]);
			}
		});
	}, []);

	useEffect(() => {
		setAssetChapters([]);
		setAssetBooks([]);

		if (data?.item.chapters?.length) {
			setAssetChapters([
				...(data?.item.chapters.map((chapter) => ({
					...chapter,
					bookCustomId: chapter.book?.customId,
				})) as AssetChapter[]),
			]);

			setBooks(data?.item.chapters as AssetChapter[]);
		}
	}, [data, setBooks]);

	const uniqueChapters = useMemo(
		() => removeDuplicateIds(assetChapters),
		[assetChapters],
	);

	const uniqueBooks = useMemo(
		() => removeDuplicateIds(assetBooks),
		[assetBooks],
	);

	if (loading) return <Spinner />;

	return (
		<ViewAsset
			asset={data?.item as Asset}
			chats={[]}
			chapters={uniqueChapters}
			books={uniqueBooks}
			activeTab={activeTab}
			refetch={refetch}
		/>
	);
};
