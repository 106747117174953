import React, { useEffect, useMemo, useState } from 'react';
import { Button, Spinner, useDisclosure } from '@chakra-ui/react';
import { debounce } from 'lodash';

import { BookIcon } from 'shared/icons';

import { SinglePicker } from '../../../../shared/ui';
import { useBooksFilterLazyQuery } from './queries.gen';

interface Props {
	selectedBook?: string;
	setFilterBook: (v: string) => void;
}

const ArtCatalogBookFilter: React.FC<Props> = ({
	selectedBook,
	setFilterBook,
}) => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [search, setSearch] = useState('');
	const [searchValue, setSearchValue] = useState('');

	const debouncedSetSearch = useMemo(() => debounce(setSearch, 2000), []);

	useEffect(() => {
		debouncedSetSearch(searchValue);
		return () => debouncedSetSearch.cancel();
	}, [searchValue, debouncedSetSearch]);

	const [getBook, { data, loading }] = useBooksFilterLazyQuery({
		variables: { filter: { name: search } },
	});

	const selectedBookName = useMemo(
		() => data?.books.find(({ id }) => id === selectedBook)?.name || '',
		[data, selectedBook],
	);

	useEffect(() => {
		if (isOpen) {
			getBook();
		}
	}, [isOpen, getBook]);

	if (loading) return <Spinner />;

	return (
		<SinglePicker
			options={
				data?.books.map(({ id, name }) => ({
					value: id,
					title: name || '',
				})) || []
			}
			onChange={setFilterBook}
			selectedValue={selectedBook}
			searchValue={searchValue}
			setSearchValue={setSearchValue}
			isOpen={isOpen}
			onOpen={onOpen}
			onClose={onClose}
		>
			<Button variant="secondary" color={selectedBook ? '#644DED' : ''}>
				<BookIcon marginRight="8px" />
				Story{selectedBookName && `: ${selectedBookName}`}
			</Button>
		</SinglePicker>
	);
};

export default ArtCatalogBookFilter;
