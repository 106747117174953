import { ContentState, SelectionState } from 'draft-js';

type BaseEntity = {
	blockKey: string;
	entityData?: any;
	entityKey?: string | undefined;
	row?: number;
};

const movePointerToEndOfSelection = (
	selection: SelectionState,
): SelectionState => {
	return selection.merge({
		focusKey: selection.getEndKey(),
		anchorKey: selection.getEndKey(),
		focusOffset: selection.getEndOffset(),
		anchorOffset: selection.getEndOffset(),
	});
};

const movePointerToStartOfSelection = (
	selection: SelectionState,
): SelectionState => {
	return selection.merge({
		focusKey: selection.getStartKey(),
		anchorKey: selection.getStartKey(),
		focusOffset: selection.getStartOffset(),
		anchorOffset: selection.getStartOffset(),
	});
};

const extractEntityKeyFromSelection = (
	contentState: ContentState,
	selection: SelectionState,
): string | undefined | null => {
	const startKey = selection.getStartKey();
	const startOffset = selection.getStartOffset();

	const blockWithEntity = contentState.getBlockForKey(startKey);
	return blockWithEntity.getEntityAt(startOffset);
};

const extractCommentedTextOffsetsFromBlock = (
	contentState: ContentState,
	blockKey: string | undefined | null,
	commentKey: string | undefined | null,
): { startOffset: number; endOffset: number } => {
	let startOffset: number | null = null;
	let endOffset = 0;

	if (!blockKey || !commentKey) {
		return { startOffset: 0, endOffset: 0 };
	}

	const charactersList = contentState
		.getBlockForKey(blockKey)
		.getCharacterList();

	charactersList.forEach((character, index) => {
		if (character?.getEntity() === commentKey && startOffset === null)
			startOffset = index || 0;

		if (character?.getEntity() === commentKey || startOffset === null)
			endOffset = (index || 0) + 1;
	});

	return { startOffset: startOffset || 0, endOffset };
};

const getEntitiesDataByEntityType = <T extends BaseEntity>(
	contentState: ContentState,
	entityType: string,
) => {
	const entities: T[] = [];

	contentState.getBlocksAsArray().forEach((block, index) => {
		let selectedEntity: T | null = null;

		block.findEntityRanges(
			(character) => {
				if (character.getEntity() !== null) {
					const entity = contentState.getEntity(character.getEntity());
					if (entity.getType() === entityType) {
						selectedEntity = {
							row: index + 1,
							blockKey: block.getKey(),
							entityKey: character.getEntity(),
							entityData: contentState
								.getEntity(character.getEntity())
								.getData(),
						} as T;
						return true;
					}
				}
				return false;
			},
			() => {
				if (selectedEntity) entities.push(selectedEntity);
			},
		);
	});

	return entities;
};

const getBlocksKeysWithEntityByEntityKey = (
	contentState: ContentState,
	entityKey: string | undefined,
) => {
	if (!entityKey) return [];
	const entities: string[] = [];

	contentState.getBlocksAsArray().forEach((block) => {
		let selectedEntity: string;

		block.findEntityRanges(
			(character) => {
				if (character.getEntity() !== null) {
					const currEntityKey = character.getEntity();
					if (currEntityKey === entityKey) {
						selectedEntity = block.getKey();
						return true;
					}
				}
				return false;
			},
			() => {
				if (selectedEntity) entities.push(selectedEntity);
			},
		);
	});

	return entities;
};

const getCommentTextFromSelection = (
	contentState: ContentState,
	selection: SelectionState,
) => {
	let commentText = null;

	if (!selection.isCollapsed()) {
		const commentKey = extractEntityKeyFromSelection(contentState, selection);

		if (commentKey) {
			const linkInstance = contentState.getEntity(commentKey);
			commentText = linkInstance.getData().comment;
		}
	}
	return commentText || '';
};

export {
	extractCommentedTextOffsetsFromBlock,
	extractEntityKeyFromSelection,
	getBlocksKeysWithEntityByEntityKey,
	getCommentTextFromSelection,
	getEntitiesDataByEntityType,
	movePointerToEndOfSelection,
	movePointerToStartOfSelection,
};
