import React, { useCallback, useMemo, useState } from 'react';
import { Button, Flex, Image, Tooltip, useDisclosure } from '@chakra-ui/react';

import { AssetType } from 'entities/assets/model';
import { STYLE_CONFIG } from 'entities/assets/ui/asset-card';
import { ButtonType } from 'shared/analytics';
import { DownloadIcon, TrashBinIcon } from 'shared/icons';
import { getFilenameFromURL } from 'shared/utils/get-filename-from-url';
import { FILENAME_REGEX } from 'shared/utils/regex/filename';
import { ImageViewModal } from 'widgets/image-view-modal';

import { EntityDeletionAlert } from '../entity-deletion-alert';

export interface Props {
	link: string;
	type: AssetType | 'emotion';
	isObjectFitCover?: boolean;
	handlePhotoDelete: () => void;
	customStyle?: { h: string } | null;
}

export const ImageView: React.FC<Props> = ({
	link,
	type,
	isObjectFitCover = true,
	handlePhotoDelete,
	customStyle = null,
}) => {
	const [isViewOpened, setIsViewOpened] = useState(false);
	const linkMatch = useMemo(() => link.match(FILENAME_REGEX) || [], [link]);
	const { isOpen, onClose, onOpen } = useDisclosure();

	const handlePhotoView = useCallback(
		() => setIsViewOpened(true),
		[setIsViewOpened],
	);

	const handleDownload = (e: {
		preventDefault: () => void;
		stopPropagation: () => void;
	}) => {
		e.preventDefault();
		e.stopPropagation();
		fetch(link)
			.then((response) => response.blob())
			.then((blob) => {
				const url = window.URL.createObjectURL(blob);
				const anchor = document.createElement('a');
				anchor.href = url;
				anchor.setAttribute('download', getFilenameFromURL(link) || '');
				document.body.appendChild(anchor);
				anchor.click();
				document.body.removeChild(anchor);
			});
	};
	return (
		<>
			<Flex
				justifyContent="center"
				border="1px solid #eeeef2"
				borderRadius="10px"
				position="relative"
				overflow="hidden"
				cursor="pointer"
				_hover={{
					border: '1px solid #644DED',
					boxShadow: '0px 0px 0px 4px rgba(0, 0, 0, 0.08)',
				}}
				onClick={handlePhotoView}
			>
				<Image
					src={link}
					alt={linkMatch[0] || ''}
					borderRadius="10px"
					objectFit={isObjectFitCover ? 'cover' : 'contain'}
					{...STYLE_CONFIG[type === 'emotion' ? 'hair' : type]}
					{...customStyle}
				/>
				<Flex gap="5px" position="absolute" top="10px" right="10px">
					<Tooltip label="Download file">
						<Button
							name={ButtonType.DOWNLOAD_ASSET_FILE}
							size="circle"
							variant="assetImage"
							onClick={handleDownload}
						>
							<DownloadIcon w={6} h={6} />
						</Button>
					</Tooltip>
					<Tooltip label="Delete file">
						<Button
							name={ButtonType.DELETE_ASSET_FILE}
							size="circle"
							variant="assetImage"
							onClick={(e) => {
								e.stopPropagation();
								onOpen();
							}}
						>
							<TrashBinIcon w={6} h={6} />
						</Button>
					</Tooltip>
				</Flex>
				<ImageViewModal
					name={linkMatch[0] || ''}
					link={link}
					isViewOpened={isViewOpened}
					setIsViewOpened={setIsViewOpened}
				/>
			</Flex>
			{isOpen && (
				<EntityDeletionAlert
					isOpen={isOpen}
					onClose={onClose}
					onSubmit={handlePhotoDelete}
					buttonName={
						type === 'emotion'
							? ButtonType.DELETE_EMOTION
							: ButtonType.DELETE_PHOTO
					}
					title={`${type.charAt(0).toUpperCase() + type.slice(1)} Deletion`}
					description={`If you delete this ${type} image, it can't be restored. Are you sure you want to continue?`}
				/>
			)}
		</>
	);
};
