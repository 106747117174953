/* eslint-disable sonarjs/cognitive-complexity */
import React, { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Box,
	Divider,
	Flex,
	HStack,
	Popover,
	PopoverBody,
	PopoverContent,
	PopoverTrigger,
	Text,
	useDisclosure,
} from '@chakra-ui/react';

import { GetBookQuery } from 'features/book/queries.gen';
import { DeleteChapterButton } from 'features/chapter/delete';
import { UpdateABDocLinks } from 'features/chapter/update/update-ab-doc-links';
import { UpdateAdditionalDocs } from 'features/chapter/update/update-additional-docs';
import { UpdateChapterAuthorForm } from 'features/chapter/update/update-author';
import { UpdateChapterDescription } from 'features/chapter/update/update-description';
import { UpdateChapterDocLink } from 'features/chapter/update/update-doc-link';
import { UpdateHasTwoPovs } from 'features/chapter/update/update-hastopovs';
import { UpdateChapterManagementStatus } from 'features/chapter/update/update-management-status';
import { UpdateChapterName } from 'features/chapter/update/update-name';
import { UpdateSecondHeroName } from 'features/chapter/update/update-second-hero';
import { UpdateChapterStatus } from 'features/chapter/update/update-status';
import { UpdateChapterVideo } from 'features/chapter/update/update-video';
import { InfoIcon } from 'shared/icons';
import { ArrayElement } from 'shared/model';
import { PermissionGuard } from 'shared/utils';
import { BookDetails } from 'widgets/update-chapter-modal/ui/book-details';
import { ChapterProperties } from 'widgets/update-chapter-modal/ui/chapter-properties';
import { Doc, Permission } from 'models.gen';

export interface GeneralTabProps {
	book: GetBookQuery['book'];
	openDocs?: boolean;
	currentChapter: ArrayElement<GetBookQuery['book']['chapters']>;
	googleDocLinks?: {
		prod?: string;
		prod2pov?: string;
		prodLinkVersionB?: Doc | null;
		prodLinkVersionC?: Doc | null;
		prodLinkVersionD?: Doc | null;
		writer?: string;
		narrative?: string;
	};
}

export const GeneralTab: React.FC<GeneralTabProps> = ({
	book,
	openDocs,
	currentChapter,
	googleDocLinks,
}) => {
	const {
		isOpen: isOpenDocPopover,
		onClose: onCloseDocPopover,
		onOpen: onOpenDocPopover,
	} = useDisclosure();

	const {
		isOpen: isOpenAdditionalDocPopover,
		onClose: onCloseAdditionalDocPopover,
		onOpen: onOpenAdditionalDocPopover,
	} = useDisclosure();
	const navigate = useNavigate();
	const { pathname } = useLocation();

	const currentLink = useMemo(
		() =>
			googleDocLinks &&
			Object.values(googleDocLinks || {})
				.concat(currentChapter?.additionalDocs || [])
				.map((value) => {
					if (typeof value === 'object' && value) {
						return Object.values(value)[0];
					}
					return value;
				})
				.filter((link) => link && link !== pathname.split('/')[4]),
		[currentChapter?.additionalDocs, googleDocLinks, pathname],
	);

	const handleRedirect = useCallback(
		(documentId) => {
			if (
				pathname.split('/').length === 5 &&
				pathname.split('/')[4] === documentId &&
				googleDocLinks &&
				currentLink
			) {
				navigate(
					`/stories/${book?.id}/${currentChapter?.id}/${currentLink[0] || ''}`,
				);
			}
		},
		[
			book?.id,
			currentChapter?.id,
			currentLink,
			googleDocLinks,
			navigate,
			pathname,
		],
	);

	const isHasTwoPov = useMemo(
		() => book.extra.includes('Story From 2 POVs'),
		[book.extra],
	);
	const isHasVideo = useMemo(
		() => book.extra.includes('Story with video content'),
		[book.extra],
	);
	return (
		<>
			<Accordion allowToggle defaultIndex={openDocs ? [3] : []}>
				<AccordionItem>
					<AccordionButton>
						<Box
							flex="1"
							textAlign="left"
							fontWeight={600}
							color="#808192"
							fontSize="14px"
							lineHeight="20px"
						>
							Story Details
						</Box>
						<AccordionIcon />
					</AccordionButton>
					<AccordionPanel>
						<BookDetails
							bookName={book?.name}
							bookId={book?.customId}
							bookAuthor={book?.author}
						/>
					</AccordionPanel>
				</AccordionItem>
				<AccordionItem>
					<AccordionButton>
						<Box
							flex="1"
							textAlign="left"
							fontWeight={600}
							color="#808192"
							fontSize="14px"
							lineHeight="20px"
						>
							Chapter Details
						</Box>
						<AccordionIcon />
					</AccordionButton>
					<AccordionPanel>
						<UpdateChapterName chapter={currentChapter} />
						<UpdateChapterDescription chapter={currentChapter} />
						<UpdateChapterAuthorForm chapter={currentChapter} />
					</AccordionPanel>
				</AccordionItem>
				<AccordionItem>
					<AccordionButton>
						<Box
							flex="1"
							textAlign="left"
							fontWeight={600}
							color="#808192"
							fontSize="14px"
							lineHeight="20px"
						>
							Chapter Properties
						</Box>
						<AccordionIcon />
					</AccordionButton>
					<AccordionPanel>
						<ChapterProperties chapter={currentChapter} />
						<UpdateChapterManagementStatus chapter={currentChapter} />
						{isHasTwoPov && <UpdateHasTwoPovs chapter={currentChapter} />}
						<UpdateChapterStatus chapter={currentChapter} />
						{isHasVideo && <UpdateChapterVideo chapter={currentChapter} />}
						{isHasTwoPov && (
							<UpdateSecondHeroName chapter={currentChapter} bookId={book.id} />
						)}
					</AccordionPanel>
				</AccordionItem>
				<AccordionItem>
					<AccordionButton>
						<HStack flex="1">
							<Text
								fontWeight={600}
								color="#808192"
								fontSize="14px"
								lineHeight="20px"
							>
								Docs
							</Text>
							<Popover
								isOpen={isOpenDocPopover}
								placement="right"
								offset={[206, 15]}
							>
								<PopoverTrigger>
									<InfoIcon
										onMouseMove={onOpenDocPopover}
										onMouseLeave={onCloseDocPopover}
										w={6}
										h={6}
										cursor="pointer"
										color="#808192"
										_hover={{
											color: '#8960FF',
										}}
									/>
								</PopoverTrigger>
								<PopoverContent>
									<PopoverBody>
										<Text
											textAlign="left"
											lineHeight="21px"
											fontWeight={600}
											pb="13px"
										>
											Info
										</Text>
										<Divider />
										<Flex
											py="17px"
											flexDir="column"
											align="flex-start"
											gap="10px"
											fontSize="14px"
										>
											<Text textAlign="left" lineHeight="16px">
												Author Google Docs
											</Text>
											<Text lineHeight="21px" textAlign="left">
												Author chapter version
											</Text>
										</Flex>
										<Divider />
										<Flex
											py="17px"
											flexDir="column"
											align="flex-start"
											gap="10px"
											fontSize="14px"
										>
											<Text textAlign="left" lineHeight="16px">
												Narrative Google Docs
											</Text>
											<Text lineHeight="21px" textAlign="left">
												Narrative chapter version
											</Text>
										</Flex>
										<Divider />
										<Flex
											py="17px"
											flexDir="column"
											align="flex-start"
											gap="10px"
											fontSize="14px"
										>
											<Text textAlign="left" lineHeight="16px">
												Production Google Docs
											</Text>
											<Text lineHeight="21px" textAlign="left">
												Production chapter version
											</Text>
										</Flex>
										<Divider />
										<Flex
											py="17px"
											flexDir="column"
											align="flex-start"
											gap="10px"
											fontSize="14px"
										>
											<Text textAlign="left" lineHeight="16px">
												A/B test Google Doc
											</Text>
											<Text lineHeight="21px" textAlign="left">
												Upload production chapter version to get access to test
												chapter version
											</Text>
										</Flex>
									</PopoverBody>
								</PopoverContent>
							</Popover>
						</HStack>
						<AccordionIcon />
					</AccordionButton>
					<AccordionPanel>
						<UpdateChapterDocLink
							language="en"
							docType="writer"
							chapterId={currentChapter?.id}
							label="Author Google Docs"
							initialDocLink={currentChapter?.googleDocWriter?.link || ''}
							docLinkToUpdate="googleDocWriter"
							bookId={book?.id || ''}
							handleRedirect={handleRedirect}
						/>
						<UpdateChapterDocLink
							language="en"
							docType="narrative"
							chapterId={currentChapter?.id}
							label="Narrative Google Docs"
							initialDocLink={currentChapter?.googleDocNarrative?.link || ''}
							docLinkToUpdate="googleDocNarrative"
							bookId={book?.id || ''}
							handleRedirect={handleRedirect}
						/>
						<UpdateChapterDocLink
							language="en"
							docType="prod"
							chapterId={currentChapter?.id}
							label="Production Google Docs"
							initialDocLink={currentChapter?.googleDoc?.link || ''}
							docLinkToUpdate="googleDoc"
							bookId={book?.id || ''}
							handleRedirect={handleRedirect}
						/>
						{currentChapter?.hasTwoPovs ? (
							<UpdateChapterDocLink
								language="en"
								docType="prod-2pov"
								chapterId={currentChapter?.id}
								label="Production 2Pov Google Docs"
								initialDocLink={currentChapter?.googleDoc2Pov?.link || ''}
								docLinkToUpdate="googleDoc2Pov"
								bookId={book?.id || ''}
								handleRedirect={handleRedirect}
							/>
						) : null}
						<UpdateABDocLinks
							chapterId={currentChapter?.id}
							googleDocABTest1={currentChapter?.googleDocABTest1 || null}
							googleDocABTest2={currentChapter?.googleDocABTest2 || null}
							googleDocABTest3={currentChapter?.googleDocABTest3 || null}
							bookId={book?.id || ''}
							handleRedirect={handleRedirect}
						/>
					</AccordionPanel>
				</AccordionItem>
				<AccordionItem>
					<AccordionButton>
						<HStack flex="1">
							<Text
								fontWeight={600}
								color="#808192"
								fontSize="14px"
								lineHeight="20px"
							>
								Additional Docs
							</Text>
							<Popover
								isOpen={isOpenAdditionalDocPopover}
								placement="right"
								offset={[80, 20]}
							>
								<PopoverTrigger>
									<InfoIcon
										onMouseMove={onOpenAdditionalDocPopover}
										onMouseLeave={onCloseAdditionalDocPopover}
										w={6}
										h={6}
										cursor="pointer"
										color="#808192"
										_hover={{
											color: '#8960FF',
										}}
									/>
								</PopoverTrigger>
								<PopoverContent w="350px">
									<PopoverBody>
										<Text
											textAlign="left"
											lineHeight="21px"
											fontWeight={600}
											pb="13px"
										>
											Info
										</Text>
										<Divider />
										<Flex
											p="17px 17px 17px 0"
											flexDir="column"
											textAlign="left"
											align="flex-start"
											fontSize="14px"
										>
											Here you can create any docs related to the chapter, but
											which aren&apos;t part of chapter production process. Pay
											attention, the additional docs exist only in the Editor,
											and don&apos;t have Google Docs link.
										</Flex>
									</PopoverBody>
								</PopoverContent>
							</Popover>
						</HStack>
						<AccordionIcon />
					</AccordionButton>
					<AccordionPanel>
						<UpdateAdditionalDocs
							chapterId={currentChapter?.id}
							bookId={book?.id}
							additionalDocs={currentChapter?.additionalDocs}
							handleRedirect={handleRedirect}
						/>
					</AccordionPanel>
				</AccordionItem>
			</Accordion>
			<PermissionGuard permissions={[Permission.DeleteChapters]}>
				<Flex w="full" p={4} justifyContent="flex-end">
					<DeleteChapterButton
						id={currentChapter?.id}
						chapterName={currentChapter.name as string}
						chapterNumber={currentChapter.chapterOrder as number}
						customBookId={book?.customId as string}
					/>
				</Flex>
			</PermissionGuard>
		</>
	);
};
