import { useEffect, useMemo } from 'react';

import { useHeroesNamesLazyQuery } from './queries.gen';

interface UseHeroesNamesParams {
	search: string;
	usedNames: string[];
	isOpen: boolean;
}

export const useHeroesNames = ({
	isOpen,
	search = '',
	usedNames = [],
}: UseHeroesNamesParams) => {
	const [getHeroNames, { data, loading, error }] = useHeroesNamesLazyQuery();

	const heroNames = useMemo(
		() =>
			[
				...new Set(
					data?.heroes.reduce(
						(acc, hero) => [...acc, ...hero.names],
						[] as string[],
					),
				),
			].filter(
				(name) =>
					name.toLocaleLowerCase().includes(search.toLocaleLowerCase()) &&
					!usedNames.includes(name),
			),
		[data?.heroes, search, usedNames],
	);

	useEffect(() => {
		if (isOpen) {
			getHeroNames();
		}
	}, [getHeroNames, isOpen]);
	return { heroNames, loading, error };
};
