import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
	Box,
	Flex,
	Grid,
	GridItem,
	Modal,
	ModalCloseButton,
	ModalContent,
	ModalOverlay,
	Text,
} from '@chakra-ui/react';

import { AssetType } from 'entities/assets/model';
import { DeleteBackgroundButton } from 'features/background/delete';
import { DeleteBodyButton } from 'features/body/delete';
import { DeleteClothesButton } from 'features/clothes/delete';
import { DeleteHairButton } from 'features/hair/delete';
import { DeleteItemButton } from 'features/item/delete';
import { DeleteLayerButton } from 'features/layer/delete';
import { useGetAssetByIdQuery } from 'pages/art-catalog/ui/ArtCatalogTable/queries.gen';
import { PermissionGuard } from 'shared/utils';
import { getFilenameFromURL } from 'shared/utils/get-filename-from-url';
import { AssetViewTabs } from 'widgets/update-asset-modal/lib/asset-view-tabs';
import { Permission } from 'models.gen';

import { LoadBackground } from '../assets/load-background';
import { LoadBody } from '../assets/load-body';
import { LoadClothes } from '../assets/load-clothes';
import { LoadHair } from '../assets/load-hair';
import { LoadItem } from '../assets/load-item';
import { LoadLayer } from '../assets/load-layer';
import { ViewAssetTabs } from '../tab';
import { ASSET_TYPES } from './constants';

interface AssetComponentsProps {
	assetId: string;
	activeTab: AssetViewTabs;
}

const ASSET_TYPE_COMPONENTS_MAP: Record<
	AssetType,
	React.FC<AssetComponentsProps>
> = {
	[AssetType.BODY]: LoadBody,
	[AssetType.HAIR]: LoadHair,
	[AssetType.CLOTHES]: LoadClothes,
	[AssetType.ITEM]: LoadItem,
	[AssetType.LAYER]: LoadLayer,
	[AssetType.BACKGROUND]: LoadBackground,
	// [AssetType.CHAT_PHOTO]: LoadChatPhoto,
	// [AssetType.AUDIO]: LoadAudio,
};

const ASSET_TYPE_DELETE_BUTTON_MAP: Record<
	AssetType,
	React.FC<{ id: string; onCompleted: () => void }>
> = {
	[AssetType.BODY]: DeleteBodyButton,
	[AssetType.HAIR]: DeleteHairButton,
	[AssetType.CLOTHES]: DeleteClothesButton,
	[AssetType.ITEM]: DeleteItemButton,
	[AssetType.LAYER]: DeleteLayerButton,
	[AssetType.BACKGROUND]: DeleteBackgroundButton,
	// [AssetType.CHAT_PHOTO]: DeleteChatPhotoButton,
	// [AssetType.AUDIO]: DeleteAudioButton,
};

interface UpdateAssetModalProps {
	isOpen: boolean;
	onClose: () => void;
	asset: {
		id: string;
		name?: string | null;
		link?: string | null;
		type?: string | null;
	};
	afterDelete: () => void;
}

const TABS_CONFIG: Record<AssetType, AssetViewTabs[]> = {
	[AssetType.BODY]: [
		AssetViewTabs.ASSET_DETAILS,
		AssetViewTabs.HEROES,
		AssetViewTabs.CHAPTERS,
		AssetViewTabs.STORIES,
	],
	[AssetType.HAIR]: [
		AssetViewTabs.ASSET_DETAILS,
		AssetViewTabs.HEROES,
		AssetViewTabs.CHAPTERS,
		AssetViewTabs.STORIES,
	],
	[AssetType.CLOTHES]: [
		AssetViewTabs.ASSET_DETAILS,
		AssetViewTabs.HEROES,
		AssetViewTabs.CHAPTERS,
		AssetViewTabs.STORIES,
		AssetViewTabs.ALTERNATIVE_CLOTHES,
	],
	[AssetType.ITEM]: [AssetViewTabs.ASSET_DETAILS, AssetViewTabs.STORIES],
	[AssetType.LAYER]: [
		AssetViewTabs.ASSET_DETAILS,
		AssetViewTabs.HEROES,
		AssetViewTabs.CHAPTERS,
		AssetViewTabs.STORIES,
	],
	[AssetType.BACKGROUND]: [
		AssetViewTabs.ASSET_DETAILS,
		AssetViewTabs.STORIES,
		AssetViewTabs.ALTERNATIVE_BACKGROUNDS,
	],
	// [AssetType.CHAT_PHOTO]: [
	// 	AssetViewTabs.ASSET_DETAILS,
	// 	AssetViewTabs.CHATS,
	// 	AssetViewTabs.STORIES,
	// ],
	// [AssetType.AUDIO]: [AssetViewTabs.ASSET_DETAILS],
};

export const UpdateAssetModal: React.FC<UpdateAssetModalProps> = ({
	isOpen,
	onClose,
	asset,
	afterDelete,
}) => {
	const [activeTab, setActiveTab] = useState(AssetViewTabs.ASSET_DETAILS);

	const handleAssetTabClick = useCallback((title: string) => {
		setActiveTab(title as AssetViewTabs);
	}, []);

	const DisplayAssetComponent = useMemo(
		() => ASSET_TYPE_COMPONENTS_MAP[asset.type as AssetType],
		[asset.type],
	);
	const DeleteAssetComponent = useMemo(
		() => ASSET_TYPE_DELETE_BUTTON_MAP[asset.type as AssetType],
		[asset.type],
	);

	useEffect(() => {
		setActiveTab(AssetViewTabs.ASSET_DETAILS);
	}, [isOpen]);

	const tabs = useMemo(
		() => TABS_CONFIG[asset.type as AssetType],
		[asset.type],
	);

	const { data: assetData } = useGetAssetByIdQuery({
		variables: {
			id: asset.id,
			type: ASSET_TYPES[asset.type || ''] as string,
		},
	});

	const assetName = useMemo(
		() =>
			assetData?.asset.name || getFilenameFromURL(assetData?.asset?.link || ''),
		[assetData],
	);

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent minW={910} m={0} overflow="hidden">
				<ModalCloseButton margin="13px 5px" borderRadius={8} />
				<Grid templateColumns="auto 1fr" h={700}>
					<GridItem>
						<Box
							borderStyle="solid"
							borderColor="#eeeef2"
							borderRightWidth="1px"
							borderBottomWidth="1px"
							w="260px"
							bg="#fbfbfc"
							h="full"
						>
							<Box p="26px 20px">
								<Text fontWeight={600} fontSize="14px" lineHeight="143%">
									{assetName}
								</Text>
							</Box>
							{tabs.map((tab) => (
								<ViewAssetTabs
									key={tab}
									title={tab}
									isActive={tab === activeTab}
									onClick={handleAssetTabClick}
								/>
							))}
						</Box>
					</GridItem>
					<GridItem display="flex" flexDirection="column" overflow="auto">
						<Flex
							justifyContent="space-between"
							alignItems="center"
							p="25px 24px 29px 32px"
							borderBottom="1px solid #eeeef2"
						>
							<Text
								variant="h3"
								fontWeight={700}
								fontSize="18px"
								lineHeight="135%"
							>
								{activeTab}
							</Text>
						</Flex>

						<Box w="full" overflow="auto">
							{DisplayAssetComponent ? (
								<DisplayAssetComponent
									assetId={asset.id}
									activeTab={activeTab}
								/>
							) : null}

							{activeTab === AssetViewTabs.ASSET_DETAILS ? (
								<PermissionGuard permissions={[Permission.DeleteAssets]}>
									<Flex px={6} pb={6} w="fill" justifyContent="flex-end">
										<DeleteAssetComponent
											onCompleted={() => {
												onClose();
												afterDelete();
											}}
											id={asset.id}
										/>
									</Flex>
								</PermissionGuard>
							) : null}
						</Box>
					</GridItem>
				</Grid>
			</ModalContent>
		</Modal>
	);
};
