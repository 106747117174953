import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import styles from './styles.module.scss';

interface Props {
	isNavigationOpened: boolean;
}

const NavigationHeader: React.FC<Props> = ({ isNavigationOpened }) => {
	return (
		<div className={styles.container}>
			<Link to="/stories" className={styles.link}>
				<img className={styles.logo} src="/images/common/logo.png" alt="logo" />
			</Link>
			<h2
				className={cn(styles.title, {
					[styles.open]: isNavigationOpened,
				})}
			>
				MF: Stories HUB
			</h2>
		</div>
	);
};

export default NavigationHeader;
