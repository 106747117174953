import React from 'react';

import styles from './styles.module.scss';

const NotFound: React.FC = () => (
	<div className={styles.root}>
		<p className={styles.title}>No results found</p>
		<p className={styles.description}>
			Try adjusting search to find what you&apos;re looking for
		</p>
	</div>
);

export default NotFound;
