import React, { useEffect } from 'react';
import {
	Badge,
	Box,
	Flex,
	IconButton,
	Td,
	Text,
	Tooltip,
	Tr,
} from '@chakra-ui/react';
import { useStore } from 'effector-react';

import { uiModel } from 'entities/ui';
import { ChaptersHeroSelect } from 'features/hero/chapters-select';
import { BookChaptersQuery } from 'features/hero/chapters-select/queries.gen';
import { HeroImage } from 'features/hero/hero-image';
import { TrashBinIcon } from 'shared/icons';
import { ArrayElement } from 'shared/model';
import { formatDate } from 'shared/utils';
import { ChapterHeroesQuery } from 'widgets/hero-list/graphql/queries.gen';

interface HeroListRowProps {
	hero: ArrayElement<ChapterHeroesQuery['heroes']>;
	index: number;
	handleHeroDelete: (
		hero: ArrayElement<ChapterHeroesQuery['heroes']>,
	) => () => Promise<void>;
	handleHeroUpdateModalOpen: () => void;
	currentBookId: string;
	handleUpdateHeroChapters: (
		hero: ArrayElement<ChapterHeroesQuery['heroes']>,
	) => (chapters: BookChaptersQuery['book']['chapters']) => Promise<void>;
}

export const HeroListRow: React.FC<HeroListRowProps> = ({
	hero,
	index,
	handleHeroDelete,
	handleHeroUpdateModalOpen,
	currentBookId,
	handleUpdateHeroChapters,
}) => {
	const [isEllipsis, setIsEllipsis] = React.useState(false);
	const nameRef = React.useRef<HTMLDivElement>(null);

	const isStoryDetailsOpened = useStore(uiModel.$isStoryDetailsOpened);
	const addShortNames = () => {
		const isShort =
			(nameRef.current?.scrollWidth || 0) > (nameRef.current?.clientWidth || 0);
		setIsEllipsis(isShort);
	};
	useEffect(() => {
		setTimeout(() => {
			addShortNames();
		}, 400);
	}, [nameRef, isStoryDetailsOpened]);

	useEffect(() => {
		addShortNames();
	}, []);

	return (
		<Tr cursor="pointer">
			<Td
				position="sticky"
				left={0}
				zIndex={100}
				onClick={handleHeroUpdateModalOpen}
				p={0}
				maxW="450px"
			>
				<Tooltip label="Open Hero" openDelay={500}>
					<Flex alignItems="center" mr="20px">
						<Text minW="26px" mx="20px" textAlign="center">
							{index + 1}
						</Text>
						<Box
							pos="relative"
							h="90px"
							w="90px"
							minW="90px"
							minH="90px"
							overflow="hidden"
						>
							<Box
								w="100%"
								h="100%"
								position="absolute"
								transform="scale(2.2)"
								top="40px"
							>
								<HeroImage
									emotionsData={{
										link:
											hero?.bodies?.[0]?.emotions?.find((emotion) =>
												emotion.includes('default'),
											) ||
											hero?.bodies?.[0]?.emotions?.[0] ||
											'',
										position: 3,
									}}
									bodyData={{
										link: hero?.bodies?.[0]?.link || '' || '',
										position: 1,
									}}
									hairFrontData={{
										link: hero?.hairs?.[0]?.frontLink || '',
										position: 4,
									}}
									hairBackData={{
										link: hero?.hairs?.[0]?.backLink || '',
										position: 0,
									}}
									clothesData={{
										link: hero?.clothes?.[0]?.link || '',
										position: 2,
									}}
								/>
							</Box>
						</Box>
						<Flex
							display="flex"
							maxH="90px"
							minH="30px"
							minW="250px"
							maxW={isEllipsis ? '90%' : '100%'}
							overflow="hidden"
							gap="5px"
							whiteSpace="nowrap"
							ref={nameRef}
						>
							{hero.names.map((name) => (
								<Badge variant="logline">
									<Text>{name}</Text>
								</Badge>
							))}
						</Flex>
						{isEllipsis && (
							<Badge variant="logline" h="30px" borderRadius={0}>
								...
							</Badge>
						)}
					</Flex>
				</Tooltip>
			</Td>
			<Td>{hero.customId}</Td>
			<Td>
				<ChaptersHeroSelect
					selectedChapters={hero.chapters.filter(
						(chapter) => chapter.book?.id === currentBookId,
					)}
					bookId={currentBookId}
					onSubmit={handleUpdateHeroChapters(hero)}
				/>
			</Td>
			<Td>{formatDate(hero.updatedAt)}</Td>

			<Td>
				<Tooltip label="Remove from Story">
					<IconButton
						aria-label="remove-hero"
						variant="ghost"
						size="square"
						onClick={handleHeroDelete(hero)}
					>
						<TrashBinIcon w={6} h={6} />
					</IconButton>
				</Tooltip>
			</Td>
		</Tr>
	);
};
