import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
	Button,
	Flex,
	Grid,
	Modal,
	ModalContent,
	ModalOverlay,
} from '@chakra-ui/react';
import { debounce } from 'lodash';

import { GetBookInfoDocument } from 'features/book/queries.gen';
import { ButtonType } from 'shared/analytics';
import { useAppToast } from 'shared/hooks/toast';
import { ArrayElement } from 'shared/model';
import { ChapterHeroesDocument } from 'widgets/hero-list/graphql/queries.gen';
import { AssetsSortArgDto } from 'models.gen';

import { ActivePage } from './constant/activePage';
import { sortOptions } from './constant/sortOptions';
import { useStories } from './graphql/hooks';
import {
	HeroesQuery,
	useHeroesQuery,
	useUpdateHeroMutation,
} from './graphql/queries.gen';
import ChooseChaptersTab from './ui/choose-chapters-tab';
import ChooseHeroesTab from './ui/choose-heroes-tab';
import Header from './ui/header';

interface ExistingHeroesModalProps {
	isOpen: boolean;
	onClose: () => void;
	startPage?: string;
	onChange?: (hero: ArrayElement<HeroesQuery['heroes']> | null) => void;
	filterHeroes?: string[];
	bookId: string;
}

const HEROES_LIMIT = 10;

const SORT_MAP: Record<string, AssetsSortArgDto> = {
	'updatedAt-desc': {
		field: 'updatedAt',
		order: 'DESC',
	},
	'name-asc': {
		field: 'names',
		order: 'ASC',
	},
	'name-desc': {
		field: 'names',
		order: 'DESC',
	},
	'used-asc': {
		field: 'used',
		order: 'asc',
	},
	'used-desc': {
		field: 'used',
		order: 'desc',
	},
};

export const ExistingHeroesModal: React.FC<ExistingHeroesModalProps> = ({
	isOpen,
	onClose,
	onChange,
	startPage = 'Choose Heroes',
	bookId,
	filterHeroes = [],
}) => {
	const toast = useAppToast();

	const [activePage, setActivePage] = useState(startPage);
	const [searchHero, setSearchHero] = useState('');
	const [searchHeroValue, setSearchHeroValue] = useState('');
	const [searchStory, setSearchStory] = useState('');
	const [sortHeroes, setSortHeroes] = useState(sortOptions[2].value);
	const [selectedHero, setSelectedHero] = useState<ArrayElement<
		HeroesQuery['heroes']
	> | null>(null);
	const [selectStory, setSelectStory] = useState('');
	const [selectChapters, setSelectChapters] = useState<string[]>([]);
	const [page, setPage] = useState(1);
	const [loadMore, setLoadMore] = useState(false);

	useEffect(() => {
		setPage(1);
	}, [selectStory, selectedHero, sortHeroes, searchHero, searchStory]);

	const [updateHero, { loading: loadingUpdate }] = useUpdateHeroMutation({
		refetchQueries: [ChapterHeroesDocument, GetBookInfoDocument],
	});

	const debouncedSetSearch = useMemo(
		() => debounce((v) => setSearchHero(v), 1000),
		[setSearchHero],
	);

	useEffect(() => {
		debouncedSetSearch(searchHeroValue);

		return () => debouncedSetSearch.cancel();
	}, [searchHeroValue, debouncedSetSearch]);
	const { data, loading, fetchMore } = useHeroesQuery({
		variables: {
			page: 0,
			limit: HEROES_LIMIT,
			filter: {
				bookId: selectStory || undefined,
				name: searchHero || undefined,
			},
			sort: SORT_MAP[sortHeroes] || undefined,
		},
		fetchPolicy: 'network-only',
		nextFetchPolicy: 'network-only',
		notifyOnNetworkStatusChange: true,
	});
	const { stories, loadingStories } = useStories(searchStory);

	const defaultState = useCallback(() => {
		setActivePage(ActivePage.CHOOSE_HEROES);
		setSearchHero('');
		setSearchStory('');
		setSortHeroes(sortOptions[2].value);
		setSelectedHero(null);
		setSelectStory('');
	}, []);

	const onCloseModal = useCallback(() => {
		onClose();
		defaultState();
	}, [defaultState, onClose]);

	const filteredHeroes = useMemo(
		() => (data?.heroes || []).filter(({ id }) => !filterHeroes.includes(id)),
		[data, filterHeroes],
	);

	const handleSubmit = useCallback(async () => {
		if (activePage === ActivePage.CHOOSE_HEROES) {
			const heroChapters = selectedHero?.chapters.map(({ id }) => id);
			setSelectChapters(heroChapters || []);
			setActivePage(ActivePage.CHOOSE_CHAPTERS);
			return;
		}

		if (activePage === ActivePage.KTO_OTEC) {
			if (onChange) onChange(selectedHero);
			onCloseModal();
			setActivePage(ActivePage.KTO_OTEC);
			return;
		}

		if (activePage === ActivePage.CHOOSE_CHAPTERS) {
			try {
				await updateHero({
					variables: {
						hero: {
							id: selectedHero?.id,
							chapters: [...selectChapters],
						},
					},
				});
				onCloseModal();
				toast({
					title: `${selectedHero?.names.join(', ')} was added`,
					status: 'success',
				});
			} catch (errors) {
				toast({
					title: `Server error: Can't add existing hero`,
					status: 'error',
				});
			}
		}
	}, [
		activePage,
		onChange,
		selectedHero,
		onCloseModal,
		updateHero,
		selectChapters,
		toast,
	]);

	const isDisabled = useMemo(() => {
		if (
			activePage === ActivePage.CHOOSE_HEROES ||
			activePage === ActivePage.KTO_OTEC
		) {
			return !selectedHero;
		}
		if (activePage === ActivePage.CHOOSE_CHAPTERS) {
			return selectChapters.length === 0;
		}
		return true;
	}, [activePage, selectedHero, selectChapters]);

	const loadMoreHeroes = useCallback(() => {
		fetchMore({
			variables: {
				page,
			},
			updateQuery: (prev, { fetchMoreResult }) => {
				if (!fetchMoreResult || fetchMoreResult.heroes.length === 0) {
					setLoadMore(false);
					return prev;
				}
				return { ...prev, heroes: [...prev.heroes, ...fetchMoreResult.heroes] };
			},
		});
	}, [fetchMore, page]);

	const handleLoadMoreHeroes = useCallback(() => {
		loadMoreHeroes();
		setPage((prevState) => prevState + 1);
	}, [loadMoreHeroes]);

	const renderPage = useCallback(() => {
		if (activePage === ActivePage.CHOOSE_CHAPTERS) {
			return (
				<ChooseChaptersTab
					bookId={bookId}
					selectChapters={selectChapters}
					setSelectChapters={setSelectChapters}
				/>
			);
		}

		return (
			<ChooseHeroesTab
				selectedHero={selectedHero}
				setSelectedHero={setSelectedHero}
				sortHeroes={sortHeroes}
				setSortHeroes={setSortHeroes}
				heroes={filteredHeroes}
				loadingHeroes={loading}
				stories={stories}
				loadingStories={loadingStories}
				setSelectStory={setSelectStory}
				searchStory={searchStory}
				setSearchStory={setSearchStory}
				handleLoadMoreHeroes={handleLoadMoreHeroes}
				isLoading={loading}
				loadMore={loadMore}
			/>
		);
	}, [
		activePage,
		selectedHero,
		sortHeroes,
		filteredHeroes,
		loading,
		stories,
		loadingStories,
		searchStory,
		handleLoadMoreHeroes,
		loadMore,
		bookId,
		selectChapters,
	]);

	useEffect(() => {
		setLoadMore(false);

		if (data?.heroes?.length && data?.heroes?.length >= HEROES_LIMIT) {
			setLoadMore(true);
		}
	}, [data?.heroes?.length]);

	return (
		<Modal isOpen={isOpen} onClose={onCloseModal}>
			<ModalOverlay />
			<ModalContent minW={650} m={0} overflow="hidden">
				<Grid templateRows="auto 1fr auto" h={700}>
					<Header
						activePage={activePage}
						setActivePage={setActivePage}
						searchHero={searchHeroValue}
						setSearchHero={setSearchHeroValue}
						onCloseModal={onCloseModal}
					/>
					<Grid templateRows="auto 1fr" overflow="auto">
						{renderPage()}
					</Grid>
					<Flex
						justifyContent="flex-end"
						alignItems="center"
						gap={5}
						px={6}
						py={4}
						borderTop="1px solid #eeeef2"
					>
						<Button
							type="button"
							size="lg"
							variant="secondary"
							onClick={onCloseModal}
						>
							Cancel
						</Button>
						<Button
							name={ButtonType.ADD_CHARACTERS_TO_CHAPTER}
							type="button"
							size="lg"
							variant="primary"
							onClick={handleSubmit}
							isDisabled={isDisabled || loadingUpdate}
						>
							Apply
						</Button>
					</Flex>
				</Grid>
			</ModalContent>
		</Modal>
	);
};
