import React from 'react';
import { Box } from '@chakra-ui/react';

import { Portal } from 'shared/ui';

interface SuggestionsListProps {
	containerRef: any;
	children: React.ReactNode;
}

export const SuggestionsList: React.FC<SuggestionsListProps> = ({
	containerRef,
	children,
}) => (
	<Portal>
		<Box
			ref={containerRef}
			position="absolute"
			top="-9999px"
			left="-9999px"
			zIndex={999999999}
			p="7px 3px 9px 6px"
			background="white"
			borderRadius="8px"
			boxShadow="0 1px 5px rgba(0, 0, 0, 0.2)"
			w="full"
			maxW="190px"
			maxHeight="200px"
			overflowY="auto"
		>
			{children}
		</Box>
	</Portal>
);
