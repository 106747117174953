/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum CHAPTER_STATUS {
  // ART_DOC_READY = "ART_DOC_READY",
  // DOC_READY = "DOC_READY",
  // DRAFT = "DRAFT",
  // EDITS_FOR_ART = "EDITS_FOR_ART",
  // ILLUSTRATION_DONE = "ILLUSTRATION_DONE",
  // ILLUSTRATION_READY = "ILLUSTRATION_READY",
  // IN_PRODUCTION = "IN_PRODUCTION",
  // LUA_DONE = "LUA_DONE",
  // RELEASE_DONE = "RELEASE_DONE",
  // SCRIPT_DONE = "SCRIPT_DONE",
  DRAFT = "DRAFT",
  SCRIPT = "SCRIPT",
  ART_DOC = "ART_DOC",
  ILLUSTRATION = "ILLUSTRATION",
  MUSIC = "MUSIC",
  LUA = "LUA",
  RELEASED = "RELEASED",
}

export interface AssetsFilterArgDto {
  type?: string | null;
  bookId?: string | null;
  name?: string | null;
  keywords?: string[] | null;
}

export interface AssetsSortArgDto {
  field?: string | null;
  order?: string | null;
}

export interface AudiosFilterArgDto {
  name?: string | null;
  keywords?: string[] | null;
}

export interface BackgroundsFilterArgDto {
  name?: string | null;
  bookId?: string | null;
  keywords?: string[] | null;
}

export interface BodiesFilterArgDto {
  name?: string | null;
  bookId?: string | null;
  keywords?: string[] | null;
}

export interface BooksFilterArgDto {
  name?: string | null;
}

export interface CreateAudioRequestDto {
  link: string;
  keywords: string[];
}

export interface CreateBackgroundRequestDto {
  link: string;
  filename?: string | null;
  visionLabels?: string | null;
  keywords: string[];
}

export interface CreateBodyRequestDto {
  link: string;
  filename?: string | null;
  emotions: string[];
  keywords: string[];
}

export interface CreateBookRequestDto {
  name: string;
  description: string;
  logline?: string | null;
  synopsis?: string | null;
  genres?: string[] | null;
  fantasyCategories?: string[] | null;
  tags?: string[] | null;
  coverLink?: string | null;
  chapters?: string[] | null;
  finished?: boolean | null;
  menuImage?: string | null;
  bannerImage?: string | null;
  category?: string | null;
  production?: boolean | null;
  imageMenuIOS?: string | null;
  imageMenuGP?: string | null;
  imagePreviewIos?: string | null;
  series?: string[] | null;
  fresh?: boolean | null;
  fantasy?: boolean | null;
  extra?: string[] | null;
  customId?: string | null;
  nameES?: string | null;
  nameRU?: string | null;
  namePT?: string | null;
  nameDE?: string | null;
  nameFR?: string | null;
}

export interface CreateChapterDocByConfigRequestDto {
  language: string;
  type: string;
}

export interface CreateChapterRequestDto {
  name?: string | null;
  chapterOrder?: number | null;
  description?: string | null;
  luaLink?: string | null;
  chapterLink?: string | null;
  heroes?: string[] | null;
  backgrounds?: string[] | null;
  book?: string | null;
  items?: string[] | null;
  chatPhotos?: string[] | null;
  audios?: string[] | null;
  author?: string | null;
}

export interface CreateChatPhotoRequestDto {
  link: string;
}

export interface CreateClothesRequestDto {
  link: string;
  keywords: string[];
  price: number;
  filename?: string | null;
}

export interface CreateHairsRequestDto {
  frontLink: string;
  backLink?: string | null;
  filename: string;
  keywords: string[];
  newHair: boolean;
}

export interface CreateHeroRequestDto {
  names: string[];
  parentId?: string | null;
  isFamiliar?: boolean | null;
  clothes?: string[] | null;
  chapters?: string[] | null;
  hairs?: string[] | null;
  bodies?: string[] | null;
  layers?: string[] | null;
  keywords?: string[] | null;
}

export interface CreateItemRequestDto {
  link: string;
  price: number;
  keywords: string[];
}

export interface CreateLayerRequestDto {
  link: string;
  position: number;
  description?: string | null;
  price: number;
  keywords: string[];
}

export interface HairsFilterArgDto {
  name?: string | null;
  bookId?: string | null;
  keywords?: string[] | null;
}

export interface HeroesFilterArgDto {
  name?: string | null;
  bookId?: string | null;
}

export interface HeroesSortArgDto {
  field?: string | null;
  order?: string | null;
}

export interface InsertDraftDocDataDto {
  blocks: any;
  entityMap: any;
}

export interface InsertGoogleDocDataDto {
  text: string;
  documentId: string;
  styles: any;
}

export interface ItemsFilterArgDto {
  name?: string | null;
  bookId?: string | null;
}

export interface LayersFilterArgDto {
  name?: string | null;
  bookId?: string | null;
  keywords?: string[] | null;
}

export interface UpdateAudioRequestDto {
  id: string;
  filename?: string | null;
  link?: string | null;
  keywords?: string[] | null;
}

export interface UpdateBackgroundRequestDto {
  id: string;
  link?: string | null;
  filename?: string | null;
  visionLabels?: string | null;
  keywords?: string[] | null;
}

export interface UpdateBodyRequestDto {
  id: string;
  link?: string | null;
  filename?: string | null;
  emotions?: string[] | null;
  keywords?: string[] | null;
}

export interface UpdateBookRequestDto {
  id: string;
  customId?: string | null;
  app?: string | null;
  nameES?: string | null;
  nameRU?: string | null;
  namePT?: string | null;
  nameDE?: string | null;
  nameFR?: string | null;
  description?: string | null;
  folderId?: string | null;
  logline?: string | null;
  genres?: string[] | null;
  fantasyCategories?: string[] | null;
  tags?: string[] | null;
  synopsis?: string | null;
  name?: string | null;
  coverLink?: string | null;
  chapters?: string[] | null;
  imageMenuIOS?: string | null;
  imageMenuGP?: string | null;
  imagePreviewIOS?: string | null;
  series?: string[] | null;
  fresh?: boolean | null;
  fantasy?: boolean | null;
  trending?: boolean | null;
  outline?: string | null;
  charactersBible?: string | null;
  artDocument?: string | null;
  extra?: string[] | null;
}

export interface UpdateChapterRequestDto {
  id: string;
  description?: string | null;
  name?: string | null;
  luaLink?: string | null;
  abTestLua1?: string | null;
  abTestLua2?: string | null;
  abTestLua3?: string | null;
  luaLinkRU?: string | null;
  luaLinkES?: string | null;
  luaLinkFR?: string | null;
  luaLinkDE?: string | null;
  luaLinkPT?: string | null;
  chapterLink?: string | null;
  googleDoc?: UpdateDocRequestDto | null;
  googleDocABTest1?: UpdateDocRequestDto | null;
  googleDocABTest2?: UpdateDocRequestDto | null;
  googleDocABTest3?: UpdateDocRequestDto | null;
  googleDocRU?: UpdateDocRequestDto | null;
  googleDocPT?: UpdateDocRequestDto | null;
  googleDocES?: UpdateDocRequestDto | null;
  googleDocDE?: UpdateDocRequestDto | null;
  googleDocFR?: UpdateDocRequestDto | null;
  googleDocWriter?: UpdateDocRequestDto | null;
  googleDocNarrative?: UpdateDocRequestDto | null;
  heroes?: UpdateHeroRequestDto[] | null;
  backgrounds?: CreateBackgroundRequestDto[] | null;
  items?: CreateItemRequestDto[] | null;
  chatPhotos?: CreateChatPhotoRequestDto[] | null;
  audios?: CreateAudioRequestDto[] | null;
  chapterOrder?: number | null;
  textLink?: string | null;
  managementStatus?: CHAPTER_STATUS[] | null;
  status?: string | null;
}

export interface UpdateChatPhotoRequestDto {
  id: string;
  link?: string | null;
  filename?: string | null;
}

export interface UpdateClothesRequestDto {
  id: string;
  link?: string | null;
  filename?: string | null;
  price?: number | null;
  keywords?: string[] | null;
}

export interface UpdateDocRequestDto {
  link?: string | null;
  prevLink?: string | null;
  name?: string | null;
  json?: string | null;
}

export interface UpdateHairsRequestDto {
  id: string;
  frontLink?: string | null;
  backLink?: string | null;
  filename?: string | null;
  keywords?: string[] | null;
  newHair?: boolean | null;
}

export interface UpdateHeroRequestDto {
  id?: string | null;
  link?: string | null;
  names?: string[] | null;
  parentId?: string | null;
  isFamiliar?: boolean | null;
  clothes?: string[] | null;
  hairs?: string[] | null;
  bodies?: string[] | null;
  chapters?: string[] | null;
  layers?: string[] | null;
  keywords?: string[] | null;
}

export interface UpdateItemRequestDto {
  id: string;
  link?: string | null;
  filename?: string | null;
  price?: number | null;
  keywords?: string[] | null;
}

export interface UpdateLayerRequestDto {
  id: string;
  link?: string | null;
  position?: number | null;
  keywords?: string[] | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
