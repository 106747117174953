import { trackModalClose } from './base';
import { Modal } from './types';

export const trackCreateBookModalClose = () =>
	trackModalClose(Modal.CREATE_BOOK);
export const trackDeleteBookModalClose = () =>
	trackModalClose(Modal.DELETE_BOOK);

export const trackCreateChapterModalClose = () =>
	trackModalClose(Modal.CREATE_CHAPTER);
export const trackDeleteChapterModalClose = () =>
	trackModalClose(Modal.DELETE_CHAPTER);
export const trackAddStoryModalClose = () => trackModalClose(Modal.ADD_STORY);

export const trackCreateHeroModalClose = () =>
	trackModalClose(Modal.CREATE_HERO);
export const trackDeleteHeroModalClose = () =>
	trackModalClose(Modal.DELETE_HERO);

export const trackCreateAssetModalClose = (modal: Modal) =>
	trackModalClose(modal);

export const trackDeleteAssetBodyModalClose = () =>
	trackModalClose(Modal.DELETE_BODY);
export const trackDeleteAssetEmotionModalClose = () =>
	trackModalClose(Modal.DELETE_EMOTION);
export const trackDeleteAssetHairModalClose = () =>
	trackModalClose(Modal.DELETE_HAIR);
export const trackDeleteAssetClothesModalClose = () =>
	trackModalClose(Modal.DELETE_CLOTHES);
export const trackDeleteAssetLayerModalClose = () =>
	trackModalClose(Modal.DELETE_LAYER);
export const trackDeleteAssetItemModalClose = () =>
	trackModalClose(Modal.DELETE_ITEM);
export const trackDeleteAssetBackgroundModalClose = () =>
	trackModalClose(Modal.DELETE_BACKGROUND);
export const trackArtDocModalClose = () => trackModalClose(Modal.ART_DOC);
export const trackChapterPropertiesModalClose = () =>
	trackModalClose(Modal.CHAPTER_PROPERTIES);
export const trackAnExistingHeroModalClose = () =>
	trackModalClose(Modal.AN_EXISTING_HERO);
export const trackAddBackgroundModalClose = () =>
	trackModalClose(Modal.ADD_BACKGROUND);
export const trackAddItemModalClose = () => trackModalClose(Modal.ADD_ITEM);
export const trackHeroDetailsModalClose = () =>
	trackModalClose(Modal.HERO_DETAILS);
export const trackAssetDetailsModalClose = (modal: Modal) =>
	trackModalClose(modal);
export const trackHeroRemoveModalClose = () =>
	trackModalClose(Modal.REMOVE_HERO);
export const trackBackgroundRemoveModalClose = () =>
	trackModalClose(Modal.REMOVE_BACKGROUND);
export const trackItemRemoveModalClose = () =>
	trackModalClose(Modal.REMOVE_ITEM);
export const trackAssetFilterModalClose = (modal: Modal) =>
	trackModalClose(modal);

export const trackAssetKeywordsModalClose = (modal: Modal) =>
	trackModalClose(modal);

export const trackBuilderAddStoryModalClose = () =>
	trackModalClose(Modal.BUILDER_ADD_STORY);
export const trackBuilderTransferModalClose = () =>
	trackModalClose(Modal.BUILDER_TRANSFER);
export const trackClothingBeforeTextSavingModalClose = () =>
	trackModalClose(Modal.CLOTHING_BEFORE_TEXT_SAVING);
export const trackClothingWithoutLuaSavingModalClose = () =>
	trackModalClose(Modal.CLOTHING_WITHOUT_LUA_SAVING);
export const trackFindAndReplaceModalClose = () =>
	trackModalClose(Modal.FIND_AND_REPLACE);
export const trackFindByTextModalClose = () =>
	trackModalClose(Modal.FIND_BY_TEXT);
export const trackFindByDialogModalClose = () =>
	trackModalClose(Modal.FIND_BY_DIALOG);

// export const editAsset = () => trackModalClose(Modal.EDIT_ASSET);

// export const createChapter = () => trackModalClose(Modal.CREATE_CHAPTER);
// export const editChapter = () => trackModalClose(Modal.EDIT_CHAPTER);

// export const createCard = () => trackModalClose(Modal.CREATE_CARD);
// export const updateCard = () => trackModalClose(Modal.UPDATE_CARD);

// export const closeDocDescription = () =>
// 	trackModalClose(Modal.EDIT_DOC_DESCRIPTION);

// export const addHeroToCard = () => trackModalOpen(Modal.ADD_HERO_TO_CARD);

// export const addBooksToCharacter = () =>
// 	trackModalClose(Modal.ADD_BOOKS_TO_CHARACTER);
// export const assetImagePreview = () =>
// 	trackModalClose(Modal.ASSET_IMAGE_PREVIEW);
// export const assetImageView = () => trackModalClose(Modal.ASSET_IMAGE_VIEW);
// export const selectChapters = () => trackModalClose(Modal.SELECT_CHAPTERS);

// export const addExistingCharacter = () =>
// 	trackModalClose(Modal.ADD_EXISTING_CHARACTER);

// export const createCharacter = () => trackModalClose(Modal.CREATE_CHARACTER);
// export const editCharacter = () => trackModalClose(Modal.EDIT_CHARACTER);
// export const uploadImage = () => trackModalClose(Modal.UPLOAD_IMAGE);
// export const legacyWriter = () =>
// 	trackModalClose(Modal.LEGACY_WRITER_CHAPTER_PICKER);

// export const transferBuckets = () =>
// 	trackModalClose(Modal.TRANSFER_BETWEEN_BUCKETS);

// export const createTemplate = () =>
// 	trackModalClose(Modal.CREATE_BUILD_TEMPLATE);
// export const editTemplate = () => trackModalClose(Modal.EDIT_BUILD_TEMPLATE);
