import React, { useCallback, useState } from 'react';

import { GetBookDocument, GetBooksDocument } from 'features/book/queries.gen';
import { useUpdateChapterMutation } from 'features/chapter/update/queries.gen';
import { updateLocalizationDescriptionSchema } from 'features/chapter/update/update-localization-description/schema';
import { ButtonType, trackPropertyChanged } from 'shared/analytics';
import { useAppToast } from 'shared/hooks/toast';
import { FormTextArea, FormWithChildren } from 'shared/ui';

export interface Props {
	id: string;
	chapterDescription: string;
	description?: string | null;
}

export const UpdateLocalizationDescriptionForm: React.FC<Props> = ({
	id,
	chapterDescription,
	description,
}) => {
	const toast = useAppToast();
	const [isRedacting, setIsRedacting] = useState(false);

	const [updateChapter] = useUpdateChapterMutation({
		onCompleted: () => {
			toast({
				title: `Chapter description updated`,
				status: 'success',
			});
		},
		onError: () =>
			toast({
				title: 'Chapter description was not updated',
				status: 'error',
			}),
		refetchQueries: [GetBookDocument, GetBooksDocument],
	});

	const onStartRedacting = useCallback(() => setIsRedacting(true), []);

	const updateLocalizationDescription = useCallback(
		async (formValue) => {
			updateChapter({
				variables: {
					chapter: {
						id,
						[chapterDescription]: formValue,
					},
				},
			});
			trackPropertyChanged({
				entity: 'chapter',
				property: chapterDescription as string,
				value: formValue,
				id,
			});
		},
		[updateChapter, id, chapterDescription],
	);

	return (
		<FormWithChildren
			submitButtonName={ButtonType.EDIT_LOCALIZED_CHAPTER_DESCRIPTION}
			initialValue={description}
			isRedacting={isRedacting}
			startRedacting={onStartRedacting}
			stopRedacting={() => setIsRedacting(false)}
			validationSchema={updateLocalizationDescriptionSchema}
			onSubmit={({ formValue }) => updateLocalizationDescription(formValue)}
		>
			<FormTextArea
				label="Description"
				name="formValue"
				placeholder="Description"
				autocomplete="off"
			/>
		</FormWithChildren>
	);
};
