import { ContentState, EditorState, Modifier } from 'draft-js';

export const insertSnippet = (
	editorState: EditorState,
	snippet: string,
	filteredWord: string,
) => {
	const currentSelection = editorState.getSelection();
	const currentContent = editorState.getCurrentContent();

	const endOffset = currentSelection.getEndOffset();

	const newSelection = editorState.getSelection().merge({
		focusOffset: endOffset,
		anchorOffset: endOffset - (filteredWord || '').length,
	});

	const pastedBlocks = ContentState.createFromText(snippet).getBlockMap();

	const newContent = Modifier.replaceWithFragment(
		currentContent,
		newSelection,
		pastedBlocks,
	);

	return EditorState.push(editorState, newContent, 'insert-fragment');
};
