import React from 'react';
import { createIcon } from '@chakra-ui/react';

export const CancelIcon = createIcon({
	viewBox: '0 0 14 14',
	path: (
		<>
			<circle cx="7" cy="7" r="7" fill="currentColor" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M9.48562 5.27742C9.67308 5.08997 9.67308 4.78605 9.48562 4.5986C9.29817 4.41114 8.99425 4.41114 8.8068 4.5986L7.08422 6.32117L5.36165 4.5986C5.1742 4.41114 4.87028 4.41114 4.68283 4.5986C4.49537 4.78605 4.49537 5.08997 4.68283 5.27742L6.4054 7L4.68283 8.72257C4.49537 8.91003 4.49537 9.21395 4.68283 9.4014C4.87028 9.58885 5.1742 9.58885 5.36165 9.4014L7.08422 7.67882L8.8068 9.4014C8.99425 9.58885 9.29817 9.58885 9.48562 9.4014C9.67308 9.21395 9.67308 8.91003 9.48562 8.72257L7.76305 7L9.48562 5.27742Z"
				fill="white"
			/>
		</>
	),
});
