import * as Types from '../../../models.gen';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CardsByStoryQueryVariables = Types.Exact<{
  page?: Types.InputMaybe<Types.Scalars['Int']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
  filter?: Types.InputMaybe<Types.CardsFilterArgDto>;
}>;


export type CardsByStoryQuery = { cards: { cards: Array<{ id: string, heroName: string, golden: boolean, secondary?: boolean | null, filename: string, updatedAt: any, cardsLink: Array<string>, selectedAssets: { selectedBodies?: Array<string> | null, selectedClothes?: Array<string> | null, selectedHairs?: Array<string> | null, selectedLayers?: Array<string> | null }, chapter: { id: string, chapterOrder?: number | null } }> } };

export type DeleteCardMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type DeleteCardMutation = { deleteCard: string };


export const CardsByStoryDocument = gql`
    query CardsByStory($page: Int, $limit: Int, $filter: CardsFilterArgDto) {
  cards(page: $page, limit: $limit, filter: $filter) {
    cards {
      id
      heroName
      golden
      secondary
      filename
      updatedAt
      cardsLink
      selectedAssets {
        selectedBodies
        selectedClothes
        selectedHairs
        selectedLayers
      }
      chapter {
        id
        chapterOrder
      }
    }
  }
}
    `;

/**
 * __useCardsByStoryQuery__
 *
 * To run a query within a React component, call `useCardsByStoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCardsByStoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCardsByStoryQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCardsByStoryQuery(baseOptions?: Apollo.QueryHookOptions<CardsByStoryQuery, CardsByStoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CardsByStoryQuery, CardsByStoryQueryVariables>(CardsByStoryDocument, options);
      }
export function useCardsByStoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CardsByStoryQuery, CardsByStoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CardsByStoryQuery, CardsByStoryQueryVariables>(CardsByStoryDocument, options);
        }
export type CardsByStoryQueryHookResult = ReturnType<typeof useCardsByStoryQuery>;
export type CardsByStoryLazyQueryHookResult = ReturnType<typeof useCardsByStoryLazyQuery>;
export type CardsByStoryQueryResult = Apollo.QueryResult<CardsByStoryQuery, CardsByStoryQueryVariables>;
export const DeleteCardDocument = gql`
    mutation DeleteCard($id: String!) {
  deleteCard(id: $id)
}
    `;
export type DeleteCardMutationFn = Apollo.MutationFunction<DeleteCardMutation, DeleteCardMutationVariables>;

/**
 * __useDeleteCardMutation__
 *
 * To run a mutation, you first call `useDeleteCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCardMutation, { data, loading, error }] = useDeleteCardMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCardMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCardMutation, DeleteCardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCardMutation, DeleteCardMutationVariables>(DeleteCardDocument, options);
      }
export type DeleteCardMutationHookResult = ReturnType<typeof useDeleteCardMutation>;
export type DeleteCardMutationResult = Apollo.MutationResult<DeleteCardMutation>;
export type DeleteCardMutationOptions = Apollo.BaseMutationOptions<DeleteCardMutation, DeleteCardMutationVariables>;