import React from 'react';
import {
	Box,
	Drawer,
	DrawerBody,
	DrawerCloseButton,
	DrawerContent,
	DrawerHeader,
	DrawerOverlay,
} from '@chakra-ui/react';

interface SnippetGuidelineProps {
	isOpen: boolean;
	onClose: () => void;
	url?: string;
}

export const SnippetGuideline: React.FC<SnippetGuidelineProps> = ({
	isOpen,
	onClose,
	url = 'https://coda.io/d/My-Fantasy-documentation_d3cwMKQBqfE/Snippets_suN8F#_luKyj',
}) => {
	return (
		<Drawer placement="bottom" onClose={onClose} isOpen={isOpen}>
			<DrawerOverlay />
			<DrawerContent height="80vh">
				<DrawerCloseButton />
				<DrawerHeader>Snippet Guideline</DrawerHeader>
				<DrawerBody borderTop="1px solid #E2E8F0" p="0">
					<Box
						as="iframe"
						w="100%"
						h="100%"
						title="naruto"
						src={url}
						allowFullScreen
						allow="clipboard-write https://help.myfantasy.studio"
					/>
				</DrawerBody>
			</DrawerContent>
		</Drawer>
	);
};
