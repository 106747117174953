import React from 'react';
import { createIcon } from '@chakra-ui/react';

export const TriangleDownIcon = createIcon({
	viewBox: '0 0 24 24',
	path: (
		<path
			d="M 20 8.446 C 20 7.942 19.563 7.554 19.003 7.554 L 5.003 7.554 C 4.443 7.554 4 7.942 4 8.446 C 4 8.674 4.099 8.868 4.259 9.028 L 11.262 16.143 C 11.44 16.32 11.699 16.446 12 16.446 C 12.301 16.446 12.56 16.32 12.738 16.143 L 19.742 9.028 C 19.902 8.868 20 8.674 20 8.446 Z"
			fill="currentColor"
		/>
	),
});
