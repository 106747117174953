import * as Types from '../../../models.gen';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BookChaptersForAssetSelectQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type BookChaptersForAssetSelectQuery = { book: { name?: string | null, chapters?: Array<{ id: string, name?: string | null, chapterOrder?: number | null }> | null } };


export const BookChaptersForAssetSelectDocument = gql`
    query BookChaptersForAssetSelect($id: String!) {
  book(id: $id) {
    name
    chapters {
      id
      name
      chapterOrder
    }
  }
}
    `;

/**
 * __useBookChaptersForAssetSelectQuery__
 *
 * To run a query within a React component, call `useBookChaptersForAssetSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookChaptersForAssetSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookChaptersForAssetSelectQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBookChaptersForAssetSelectQuery(baseOptions: Apollo.QueryHookOptions<BookChaptersForAssetSelectQuery, BookChaptersForAssetSelectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BookChaptersForAssetSelectQuery, BookChaptersForAssetSelectQueryVariables>(BookChaptersForAssetSelectDocument, options);
      }
export function useBookChaptersForAssetSelectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BookChaptersForAssetSelectQuery, BookChaptersForAssetSelectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BookChaptersForAssetSelectQuery, BookChaptersForAssetSelectQueryVariables>(BookChaptersForAssetSelectDocument, options);
        }
export type BookChaptersForAssetSelectQueryHookResult = ReturnType<typeof useBookChaptersForAssetSelectQuery>;
export type BookChaptersForAssetSelectLazyQueryHookResult = ReturnType<typeof useBookChaptersForAssetSelectLazyQuery>;
export type BookChaptersForAssetSelectQueryResult = Apollo.QueryResult<BookChaptersForAssetSelectQuery, BookChaptersForAssetSelectQueryVariables>;