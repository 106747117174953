import React, { Dispatch, SetStateAction } from 'react';
import { Flex } from '@chakra-ui/react';

import { SelectedAssetsInfo } from 'widgets/create-card-modal/interfaces';

import { AssetsHero } from './components/assets-hero';

interface AssetsTabProps {
	bodies: string[];
	clothes: string[];
	hairs: string[];
	layers: string[];
	defaultClothesId?: string;
	selectedAssetsInfo: SelectedAssetsInfo;
	setSelectedAssetsInfo: Dispatch<SetStateAction<SelectedAssetsInfo>>;
}

export const AssetsTab: React.FC<AssetsTabProps> = ({
	bodies,
	clothes,
	hairs,
	layers,
	defaultClothesId,
	selectedAssetsInfo,
	setSelectedAssetsInfo,
}) => {
	return (
		<Flex h="calc(100vh - 220px)" gap={6} flexDirection="column">
			<AssetsHero
				title="Bodies"
				type="body"
				assets={bodies}
				hairs={[]}
				selectedAssetsInfo={selectedAssetsInfo}
				setSelectedAssetsInfo={setSelectedAssetsInfo}
			/>
			<AssetsHero
				title="Hairs"
				type="hair"
				assets={[]}
				hairs={hairs}
				selectedAssetsInfo={selectedAssetsInfo}
				setSelectedAssetsInfo={setSelectedAssetsInfo}
			/>
			<AssetsHero
				defaultId={defaultClothesId}
				title="Clothes"
				type="clothes"
				assets={clothes}
				hairs={[]}
				selectedAssetsInfo={selectedAssetsInfo}
				setSelectedAssetsInfo={setSelectedAssetsInfo}
			/>
			<AssetsHero
				title="Layers"
				type="layer"
				assets={layers}
				hairs={[]}
				selectedAssetsInfo={selectedAssetsInfo}
				setSelectedAssetsInfo={setSelectedAssetsInfo}
			/>
		</Flex>
	);
};
