import React, {
	Dispatch,
	SetStateAction,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { Link, useParams } from 'react-router-dom';
import {
	Button,
	Flex,
	IconButton,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Spinner,
	Tooltip,
	useDisclosure,
} from '@chakra-ui/react';
import { EditorState } from 'draft-js';
import { useStore } from 'effector-react';

import { docsModel } from 'entities/docs';
import { scriptModel } from 'entities/script';
import { uiModel } from 'entities/ui';
import { GetBookQuery } from 'features/book/queries.gen';
import { NoDocAlert } from 'features/chapter/no-doc-alert';
import { FindByText } from 'features/editor/find';
import { DOC_TYPES, PROD_DOC_TYPES } from 'features/story-editor/header/types';
import { LangSelectorWrapper } from 'features/story-editor/lang-selector/wrapper';
import { ButtonType } from 'shared/analytics';
import { KebabMenuIcon, TriangleDownIcon } from 'shared/icons';
import { UpdateChapterModal } from 'widgets/update-chapter-modal';

import { ChapterPicker } from '../chapter-picker';
import { DocSelector } from '../doc-selector';
import { LastUpdated } from '../last-update';
import { StateIcon } from '../state-icon';
import { useChapterByIdQuery } from './queries.gen';
import styles from './styles.module.scss';

interface EditorHeaderProps {
	onValidateClick: () => void;
	onSaveBtnClick: () => void;
	onDownloadLuaClick: () => void;
	luaContent: string;
	book: GetBookQuery['book'];
	editorState: EditorState;
	setEditorState: Dispatch<SetStateAction<EditorState>>;
	isErrorSync: boolean;
	isDisabledSyncToDoc: boolean;
}

export const EditorHeader: React.FC<EditorHeaderProps> = ({
	onValidateClick,
	onSaveBtnClick,
	onDownloadLuaClick,
	luaContent,
	book,
	editorState,
	setEditorState,
	isErrorSync,
	isDisabledSyncToDoc,
}) => {
	const { bookId, chapterId, googleDocId } = useParams();
	const validationErrors = useStore(scriptModel.$validationErrors);
	const isValidationLoading = useStore(scriptModel.validateScriptFx.pending);
	const isDisabledControlButtons = useStore(
		scriptModel.$isDisabledControlButtons,
	);
	const isSavingGoogleDocs = useStore(docsModel.patchFileFx.pending);
	const isSavingDraftDocs = useStore(docsModel.updateDraftDocContentFx.pending);
	const {
		isOpen: isNoDocOpen,
		onClose: onNoDocClose,
		onOpen: onNoDocOpen,
	} = useDisclosure();
	const [shouldOpenDocs, setShouldOpenDocs] = useState(false);

	const handleAccept = useCallback(() => {
		uiModel.setIsUpdateChapterModalOpened(true);
		setShouldOpenDocs(true);
		onNoDocClose();
	}, [onNoDocClose]);

	const handleDiscard = useCallback(() => {
		setShouldOpenDocs(false);
		onNoDocClose();
	}, [onNoDocClose]);

	useEffect(() => {
		if (googleDocId === 'no-doc' && chapterId) {
			onNoDocOpen();
		}
	}, [chapterId, googleDocId, onNoDocOpen]);

	const { data } = useChapterByIdQuery({
		variables: { id: chapterId as string },
	});

	const googleDocLinks = useMemo(() => {
		if (!data?.chapter) return {};

		return {
			prod: data?.chapter.googleDoc?.id,
			prod2pov: data?.chapter.googleDoc2Pov?.id,
			prod2povDE: data?.chapter.googleDoc2PovDE?.id,
			prod2povES: data?.chapter.googleDoc2PovES?.id,
			prod2povFR: data?.chapter.googleDoc2PovFR?.id,
			prod2povNL: data?.chapter.googleDoc2PovNL?.id,
			prod2povPT: data?.chapter.googleDoc2PovPT?.id,
			prod2povIT: data?.chapter.googleDoc2PovIT?.id,
			prod2povPL: data?.chapter.googleDoc2PovPL?.id,
			prod2povSV: data?.chapter.googleDoc2PovSV?.id,
			prod2povDA: data?.chapter.googleDoc2PovDA?.id,
			prod2povNO: data?.chapter.googleDoc2PovNO?.id,
			prod2povFI: data?.chapter.googleDoc2PovFI?.id,
			prod2povID: data?.chapter.googleDoc2PovID?.id,
			prod2povCS: data?.chapter.googleDoc2PovCS?.id,
			prod2povRO: data?.chapter.googleDoc2PovRO?.id,
			prod2povHU: data?.chapter.googleDoc2PovHU?.id,
			prod2povTR: data?.chapter.googleDoc2PovTR?.id,
			prod2povJA: data?.chapter.googleDoc2PovJA?.id,
			prod2povKO: data?.chapter.googleDoc2PovKO?.id,
			prod2povPT_BR: data?.chapter.googleDoc2PovPT_BR?.id,
			prodLinkVersionB: data?.chapter.googleDocABTest1,
			prodLinkVersionC: data?.chapter.googleDocABTest2,
			prodLinkVersionD: data?.chapter.googleDocABTest3,
			writer: data?.chapter.googleDocWriter?.id,
			narrative: data?.chapter.googleDocNarrative?.id,
		};
	}, [data?.chapter]);

	const additionalDocs = useMemo(() => {
		return data?.chapter?.additionalDocs || [];
	}, [data?.chapter]);

	const additionalDoc = useMemo(
		() => additionalDocs?.filter((doc) => doc.id === googleDocId),
		[additionalDocs, googleDocId],
	);

	const pageName = useMemo(() => {
		if (
			googleDocId === googleDocLinks.prod ||
			googleDocId === data?.chapter?.googleDocDE?.id ||
			googleDocId === data?.chapter?.googleDocES?.id ||
			googleDocId === data?.chapter?.googleDocFR?.id ||
			googleDocId === data?.chapter?.googleDocNL?.id ||
			googleDocId === data?.chapter?.googleDocPT?.id ||
			googleDocId === data?.chapter?.googleDocIT?.id ||
			googleDocId === data?.chapter?.googleDocPL?.id ||
			googleDocId === data?.chapter?.googleDocSV?.id ||
			googleDocId === data?.chapter?.googleDocDA?.id ||
			googleDocId === data?.chapter?.googleDocNO?.id ||
			googleDocId === data?.chapter?.googleDocFI?.id ||
			googleDocId === data?.chapter?.googleDocID?.id ||
			googleDocId === data?.chapter?.googleDocCS?.id ||
			googleDocId === data?.chapter?.googleDocRO?.id ||
			googleDocId === data?.chapter?.googleDocHU?.id ||
			googleDocId === data?.chapter?.googleDocJA?.id ||
			googleDocId === data?.chapter?.googleDocKO?.id ||
			googleDocId === data?.chapter?.googleDocPT_BR?.id ||
			googleDocId === data?.chapter?.googleDocTR?.id
		)
			return DOC_TYPES.PROD;

		if (
			googleDocId === googleDocLinks.prod2pov ||
			googleDocId === googleDocLinks.prod2povDE ||
			googleDocId === googleDocLinks.prod2povES ||
			googleDocId === googleDocLinks.prod2povFR ||
			googleDocId === googleDocLinks.prod2povNL ||
			googleDocId === googleDocLinks.prod2povPT ||
			googleDocId === googleDocLinks.prod2povIT ||
			googleDocId === googleDocLinks.prod2povPL ||
			googleDocId === googleDocLinks.prod2povSV ||
			googleDocId === googleDocLinks.prod2povDA ||
			googleDocId === googleDocLinks.prod2povNO ||
			googleDocId === googleDocLinks.prod2povFI ||
			googleDocId === googleDocLinks.prod2povID ||
			googleDocId === googleDocLinks.prod2povCS ||
			googleDocId === googleDocLinks.prod2povRO ||
			googleDocId === googleDocLinks.prod2povHU ||
			googleDocId === googleDocLinks.prod2povJA ||
			googleDocId === googleDocLinks.prod2povKO ||
			googleDocId === googleDocLinks.prod2povPT_BR ||
			googleDocId === googleDocLinks.prod2povTR
		)
			return DOC_TYPES.PROD2POV;

		if (googleDocId === googleDocLinks.writer) return DOC_TYPES.AUTHOR;
		if (googleDocId === googleDocLinks.narrative) return DOC_TYPES.NARRATIVE;

		if (googleDocId === googleDocLinks?.prodLinkVersionB?.id)
			return DOC_TYPES.PROD_VERSION_B;
		if (googleDocId === googleDocLinks?.prodLinkVersionC?.id)
			return DOC_TYPES.PROD_VERSION_C;
		if (googleDocId === googleDocLinks?.prodLinkVersionD?.id)
			return DOC_TYPES.PROD_VERSION_D;

		const foundDoc = additionalDocs?.filter((doc) => doc.id === googleDocId);

		if (additionalDoc?.length) return `Additional Doc ${foundDoc[0].docOrder}`;

		return '-';
	}, [
		googleDocId,
		googleDocLinks.prod,
		googleDocLinks.prod2pov,
		googleDocLinks.prod2povDE,
		googleDocLinks.prod2povES,
		googleDocLinks.prod2povFR,
		googleDocLinks.prod2povNL,
		googleDocLinks.prod2povPT,
		googleDocLinks.prod2povIT,
		googleDocLinks.prod2povPL,
		googleDocLinks.prod2povSV,
		googleDocLinks.prod2povDA,
		googleDocLinks.prod2povNO,
		googleDocLinks.prod2povFI,
		googleDocLinks.prod2povID,
		googleDocLinks.prod2povCS,
		googleDocLinks.prod2povRO,
		googleDocLinks.prod2povHU,
		googleDocLinks.prod2povJA,
		googleDocLinks.prod2povKO,
		googleDocLinks.prod2povPT_BR,
		googleDocLinks.prod2povTR,
		googleDocLinks.writer,
		googleDocLinks.narrative,
		googleDocLinks?.prodLinkVersionB?.id,
		googleDocLinks?.prodLinkVersionC?.id,
		googleDocLinks?.prodLinkVersionD?.id,
		data?.chapter?.googleDocDE?.id,
		data?.chapter?.googleDocES?.id,
		data?.chapter?.googleDocFR?.id,
		data?.chapter?.googleDocNL?.id,
		data?.chapter?.googleDocPT?.id,
		data?.chapter?.googleDocIT?.id,
		data?.chapter?.googleDocPL?.id,
		data?.chapter?.googleDocSV?.id,
		data?.chapter?.googleDocDA?.id,
		data?.chapter?.googleDocNO?.id,
		data?.chapter?.googleDocFI?.id,
		data?.chapter?.googleDocID?.id,
		data?.chapter?.googleDocCS?.id,
		data?.chapter?.googleDocRO?.id,
		data?.chapter?.googleDocHU?.id,
		data?.chapter?.googleDocJA?.id,
		data?.chapter?.googleDocKO?.id,
		data?.chapter?.googleDocPT_BR?.id,
		data?.chapter?.googleDocTR?.id,
		additionalDocs,
		additionalDoc?.length,
	]);

	const prodPageName = useMemo(() => {
		return PROD_DOC_TYPES[pageName] || '';
	}, [pageName]);

	const localizedGoogleDocLinks = useMemo(() => {
		// eslint-disable-next-line default-case
		switch (prodPageName) {
			case DOC_TYPES.PROD: {
				return {
					EN: data?.chapter?.googleDoc?.id,
					ES: data?.chapter?.googleDocES?.id,
					FR: data?.chapter?.googleDocFR?.id,
					DE: data?.chapter?.googleDocDE?.id,
					NL: data?.chapter?.googleDocNL?.id,
					PT: data?.chapter?.googleDocPT?.id,
					IT: data?.chapter?.googleDocIT?.id,
					PL: data?.chapter?.googleDocPL?.id,
					SV: data?.chapter?.googleDocSV?.id,
					DA: data?.chapter?.googleDocDA?.id,
					NO: data?.chapter?.googleDocNO?.id,
					FI: data?.chapter?.googleDocFI?.id,
					ID: data?.chapter?.googleDocID?.id,
					CS: data?.chapter?.googleDocCS?.id,
					RO: data?.chapter?.googleDocRO?.id,
					HU: data?.chapter?.googleDocHU?.id,
					TR: data?.chapter?.googleDocTR?.id,
					JA: data?.chapter?.googleDocJA?.id,
					KO: data?.chapter?.googleDocKO?.id,
					'PT-BR': data?.chapter?.googleDocPT_BR?.id,
				};
			}
			case DOC_TYPES.PROD2POV: {
				return {
					EN: data?.chapter?.googleDoc2Pov?.id,
					ES: data?.chapter?.googleDoc2PovES?.id,
					FR: data?.chapter?.googleDoc2PovFR?.id,
					DE: data?.chapter?.googleDoc2PovDE?.id,
					NL: data?.chapter?.googleDoc2PovNL?.id,
					PT: data?.chapter?.googleDoc2PovPT?.id,
					IT: data?.chapter?.googleDoc2PovIT?.id,
					PL: data?.chapter?.googleDoc2PovPL?.id,
					SV: data?.chapter?.googleDoc2PovSV?.id,
					DA: data?.chapter?.googleDoc2PovDA?.id,
					NO: data?.chapter?.googleDoc2PovNO?.id,
					FI: data?.chapter?.googleDoc2PovFI?.id,
					ID: data?.chapter?.googleDoc2PovID?.id,
					CS: data?.chapter?.googleDoc2PovCS?.id,
					RO: data?.chapter?.googleDoc2PovRO?.id,
					HU: data?.chapter?.googleDoc2PovHU?.id,
					TR: data?.chapter?.googleDoc2PovTR?.id,
					JA: data?.chapter?.googleDoc2PovJA?.id,
					KO: data?.chapter?.googleDoc2PovKO?.id,
					'PT-BR': data?.chapter?.googleDoc2PovPT_BR?.id,
				};
			}
		}
		return {};
	}, [
		data?.chapter?.googleDoc?.id,
		data?.chapter?.googleDoc2Pov?.id,
		data?.chapter?.googleDoc2PovCS?.id,
		data?.chapter?.googleDoc2PovDA?.id,
		data?.chapter?.googleDoc2PovDE?.id,
		data?.chapter?.googleDoc2PovES?.id,
		data?.chapter?.googleDoc2PovFI?.id,
		data?.chapter?.googleDoc2PovFR?.id,
		data?.chapter?.googleDoc2PovHU?.id,
		data?.chapter?.googleDoc2PovID?.id,
		data?.chapter?.googleDoc2PovIT?.id,
		data?.chapter?.googleDoc2PovJA?.id,
		data?.chapter?.googleDoc2PovKO?.id,
		data?.chapter?.googleDoc2PovNL?.id,
		data?.chapter?.googleDoc2PovNO?.id,
		data?.chapter?.googleDoc2PovPL?.id,
		data?.chapter?.googleDoc2PovPT?.id,
		data?.chapter?.googleDoc2PovPT_BR?.id,
		data?.chapter?.googleDoc2PovRO?.id,
		data?.chapter?.googleDoc2PovSV?.id,
		data?.chapter?.googleDoc2PovTR?.id,
		data?.chapter?.googleDocCS?.id,
		data?.chapter?.googleDocDA?.id,
		data?.chapter?.googleDocDE?.id,
		data?.chapter?.googleDocES?.id,
		data?.chapter?.googleDocFI?.id,
		data?.chapter?.googleDocFR?.id,
		data?.chapter?.googleDocHU?.id,
		data?.chapter?.googleDocID?.id,
		data?.chapter?.googleDocIT?.id,
		data?.chapter?.googleDocJA?.id,
		data?.chapter?.googleDocKO?.id,
		data?.chapter?.googleDocNL?.id,
		data?.chapter?.googleDocNO?.id,
		data?.chapter?.googleDocPL?.id,
		data?.chapter?.googleDocPT?.id,
		data?.chapter?.googleDocPT_BR?.id,
		data?.chapter?.googleDocRO?.id,
		data?.chapter?.googleDocSV?.id,
		data?.chapter?.googleDocTR?.id,
		prodPageName,
	]);

	const stateSync = useMemo(() => {
		if (isDisabledSyncToDoc) return 'driveError';
		if (isSavingGoogleDocs || isSavingDraftDocs) return 'saving';
		if (pageName === '-' || isErrorSync) return 'error';
		if (additionalDoc?.length) return 'additionalDoc';

		return 'default';
	}, [
		additionalDoc?.length,
		isDisabledSyncToDoc,
		isErrorSync,
		isSavingDraftDocs,
		isSavingGoogleDocs,
		pageName,
	]);

	const isProdDocument = useMemo(
		() => prodPageName || pageName?.includes('A/B'),
		[pageName, prodPageName],
	);

	const renderValidationBtn = useCallback(() => {
		if (isDisabledControlButtons || !luaContent || !!validationErrors?.length) {
			return (
				<Button
					name={ButtonType.VALIDATE_SCRIPT}
					size="lg"
					onClick={onValidateClick}
					width={92}
				>
					{isValidationLoading ? <Spinner size="sm" /> : 'Validate'}
				</Button>
			);
		}

		if (!isProdDocument) {
			return (
				<Button
					name={ButtonType.DOWNLOAD_LUA}
					size="lg"
					onClick={onDownloadLuaClick}
				>
					Download lua
				</Button>
			);
		}

		return (
			<Menu>
				{({ isOpen }) => (
					<>
						<MenuButton
							size="lg"
							as={Button}
							isActive={isOpen}
							rightIcon={
								<TriangleDownIcon
									transform={isOpen ? 'rotate(180deg)' : 'none'}
									transition="ease"
									transitionDuration="200ms"
								/>
							}
						>
							Save Lua
						</MenuButton>
						<MenuList minW="max-content">
							<MenuItem onClick={onSaveBtnClick}>Save lua</MenuItem>
							<MenuItem onClick={onDownloadLuaClick}>Download lua</MenuItem>
						</MenuList>
					</>
				)}
			</Menu>
		);
	}, [
		isDisabledControlButtons,
		isProdDocument,
		isValidationLoading,
		luaContent,
		onSaveBtnClick,
		onDownloadLuaClick,
		onValidateClick,
		validationErrors?.length,
	]);

	if (!data?.chapter) return null;

	return (
		<div className={styles.root}>
			<div className={styles.logoContainer}>
				<Tooltip label="Back to Chapters">
					<Link to={`/stories/${bookId}/chapters`}>
						<img
							className={styles.logoImage}
							src="/images/common/logo.png"
							alt="logo"
						/>
					</Link>
				</Tooltip>
			</div>
			<div className={styles.controlsBar}>
				<div className={styles.storyInfo}>
					<div className={styles.infoText}>
						<div className={styles.bookInfo}>
							<ChapterPicker />
						</div>
						<Flex align="center">
							<LastUpdated pageName={pageName} />
							<div className={styles.storySync}>
								<StateIcon state={stateSync} />
							</div>
							{isDisabledSyncToDoc && (
								<Button
									size="sm"
									h="24px"
									p="2px 8px"
									variant="secondary"
									ml="10px"
									onClick={() => uiModel.setIsDraftSyncAlertShow(true)}
								>
									Update
								</Button>
							)}
						</Flex>
					</div>
				</div>
				<div className={styles.storySelect}>
					<DocSelector
						pageName={pageName || ''}
						prodLink={googleDocLinks.prod}
						hasTwoPovs={data?.chapter?.hasTwoPovs}
						prod2PovLink={googleDocLinks.prod2pov}
						narrativeLink={googleDocLinks.narrative}
						writerLink={googleDocLinks.writer}
						googleDocId={googleDocId || 'no-doc'}
						localizedGoogleDocLinks={localizedGoogleDocLinks}
						prodLinkVersionB={googleDocLinks.prodLinkVersionB}
						prodLinkVersionC={googleDocLinks.prodLinkVersionC}
						prodLinkVersionD={googleDocLinks.prodLinkVersionD}
						additionalDocs={additionalDocs || []}
					/>
				</div>
				<div className={styles.storyActions}>
					<LangSelectorWrapper
						localizedGoogleDocLinks={localizedGoogleDocLinks}
						prodPageName={prodPageName}
					/>
					<FindByText
						editorState={editorState}
						setEditorState={setEditorState}
					/>
					<div className={styles.storySave}>{renderValidationBtn()}</div>
				</div>
			</div>
			<div className={styles.editButtonContainer}>
				<Tooltip label="Properties">
					<IconButton
						variant="ghost"
						size="square"
						aria-label="Properties"
						onClick={() => uiModel.setIsUpdateChapterModalOpened(true)}
					>
						<KebabMenuIcon w={5} h={5} />
					</IconButton>
				</Tooltip>
				<UpdateChapterModal
					book={book}
					chapterId={chapterId}
					openDocs={shouldOpenDocs}
					chapters={book.chapters || []}
					googleDocLinks={googleDocLinks}
				/>

				<NoDocAlert
					isOpen={isNoDocOpen}
					onClose={handleDiscard}
					onAccept={handleAccept}
				/>
			</div>
		</div>
	);
};
