import React from 'react';
import { createIcon } from '@chakra-ui/react';

export const PencilIcon = createIcon({
	viewBox: '0 0 24 24',
	path: (
		<g fill="none">
			<path
				d="M14.0965 6.40936C14.6423 5.86355 15.5273 5.86355 16.0731 6.40936L17.5907 7.92692C18.1364 8.47275 18.1364 9.35769 17.5907 9.90351L10.193 17.3012L6 18L6.69883 13.807L14.0965 6.40936Z"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</g>
	),
});
