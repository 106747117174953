import { EditorState, Modifier, RichUtils } from 'draft-js';

import { backgroundsStyleMap } from 'features/editor/background-color-picker/constants/backgrounds';

export const toggleStyle = (editorState: EditorState, style?: string) => {
	const currentSelection = editorState.getSelection();

	const start = currentSelection.getStartOffset();
	const end = currentSelection.getEndOffset();
	const newSelection = editorState.getSelection().merge({
		focusOffset: end,
		anchorOffset: end,
	});

	if (!style) {
		return EditorState.push(
			editorState,
			Object.keys(backgroundsStyleMap).reduce(
				(newState, s) =>
					Modifier.removeInlineStyle(newState, currentSelection, s),
				editorState.getCurrentContent(),
			),
			'change-inline-style',
		);
	}

	if (start === end) {
		return RichUtils.toggleInlineStyle(editorState, style);
	}

	return EditorState.acceptSelection(
		RichUtils.toggleInlineStyle(editorState, style),
		newSelection,
	);
};
