import React from 'react';
import { createIcon } from '@chakra-ui/react';

export const WarningIcon = createIcon({
	viewBox: '0 0 24 24',
	path: (
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M18.3675 18.3675C19.9963 16.7386 21 14.4846 21 12C21 9.51537 19.9963 7.26153 18.3675 5.63255C16.7386 4.00369 14.4846 3 12 3C9.51537 3 7.26153 4.00372 5.63254 5.63255C4.00369 7.2614 3 9.51537 3 12C3 14.4846 4.00372 16.7385 5.63254 18.3675C7.2614 19.9963 9.51537 21 12 21C14.4846 21 16.7385 19.9963 18.3675 18.3675ZM13.2176 15.4717C13.2176 14.7971 12.6746 14.2542 12 14.2542C11.3254 14.2542 10.766 14.7971 10.766 15.4717C10.766 16.1463 11.3254 16.7057 12 16.7057C12.6747 16.7057 13.2176 16.1464 13.2176 15.4717ZM12 13.0202C11.5558 13.0202 11.2267 12.6582 11.1774 12.1975L10.766 8.52847C10.7003 7.88674 11.3749 7.29448 12 7.29448C12.6253 7.29448 13.2998 7.88687 13.2176 8.52847L12.8227 12.1975C12.7733 12.6582 12.4443 13.0202 12 13.0202Z"
			fill="currentColor"
		/>
	),
});
