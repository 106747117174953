import React, { Dispatch, SetStateAction, useCallback } from 'react';
import { Box, Grid, Text } from '@chakra-ui/react';

import { SelectedAssetsInfo } from 'widgets/create-card-modal/interfaces';

import { AssetCardBody } from '../asset-card-body';
import { AssetCardClothes } from '../asset-card-clothes';
import { AssetCardHair } from '../asset-card-hair';
import { AssetCardLayer } from '../asset-card-layer';

interface AssetsHeroProps {
	title: string;
	type: string;
	assets: string[];
	hairs: string[];
	defaultId?: string;
	selectedAssetsInfo?: SelectedAssetsInfo;
	setSelectedAssetsInfo?: Dispatch<SetStateAction<SelectedAssetsInfo>>;
	isAltAsset?: boolean;
}

export const AssetsHero: React.FC<AssetsHeroProps> = ({
	title,
	type,
	assets,
	hairs,
	selectedAssetsInfo,
	setSelectedAssetsInfo,
	defaultId,
	isAltAsset = false,
}) => {
	const renderCard = useCallback(
		(assetId: string) => {
			switch (type) {
				case 'clothes': {
					return (
						<AssetCardClothes
							key={assetId}
							defaultId={defaultId}
							assetId={assetId}
							setSelectedAssetsInfo={setSelectedAssetsInfo}
							isActive={selectedAssetsInfo?.selectedClothes.some(
								(el) => el === assetId,
							)}
						/>
					);
				}
				case 'hair': {
					return (
						<AssetCardHair
							key={assetId}
							assetId={assetId}
							setSelectedAssetsInfo={setSelectedAssetsInfo}
							isActive={selectedAssetsInfo?.selectedHairs.some(
								(el) => el === assetId,
							)}
						/>
					);
				}
				case 'layer': {
					return (
						<AssetCardLayer
							key={assetId}
							assetId={assetId}
							setSelectedAssetsInfo={setSelectedAssetsInfo}
							isActive={selectedAssetsInfo?.selectedLayers.some(
								(el) => el === assetId,
							)}
						/>
					);
				}
				case 'body':
					return (
						<AssetCardBody
							key={assetId}
							assetId={assetId}
							setSelectedAssetsInfo={setSelectedAssetsInfo}
							isActive={selectedAssetsInfo?.selectedBodies.some(
								(el) => el === assetId,
							)}
						/>
					);
				default: {
					return null;
				}
			}
		},
		[
			type,
			selectedAssetsInfo?.selectedBodies,
			selectedAssetsInfo?.selectedClothes,
			selectedAssetsInfo?.selectedHairs,
			selectedAssetsInfo?.selectedLayers,
			defaultId,
			setSelectedAssetsInfo,
		],
	);

	return (
		<Box mb={5}>
			<Text p={1} mb={3} fontSize="14px" lineHeight="16px">
				{title}
			</Text>
			<Grid
				templateColumns="repeat(2, 1fr)"
				gap={10}
				w="600px"
				overflowX={isAltAsset ? 'hidden' : 'inherit'}
			>
				{assets.length > 0
					? assets.map((assetId) => renderCard(assetId))
					: hairs.map((hairId) => renderCard(hairId))}
			</Grid>
		</Box>
	);
};
