import React from 'react';
import { createIcon } from '@chakra-ui/react';

export const MembersIcon = createIcon({
	viewBox: '0 0 22 22',
	path: (
		<path
			d="M8.5975 3.1266A3.569 3.569 0 0 0 6.9957 2.75c-1.9584 0-3.546 1.567-3.546 3.5s1.5876 3.5 3.546 3.5c.6018 0 1.1687-.148 1.6651-.4091M.9168 19.25c0-3.3137 2.7216-6 6.0789-6m7.0921-3.5c1.9584 0 3.5461-1.567 3.5461-3.5s-1.5877-3.5-3.5461-3.5c-1.9584 0-3.5461 1.567-3.5461 3.5s1.5877 3.5 3.5461 3.5Zm-6.079 9.5c0-3.3137 2.7217-6 6.079-6s6.0789 2.6863 6.0789 6H8.0089Z"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	),
});
