/* eslint-disable sonarjs/no-identical-functions */
import React, { useCallback, useRef, useState } from 'react';
import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	Badge,
	Box,
	Button,
	Flex,
	Grid,
	GridItem,
	IconButton,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Table,
	TableContainer,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tr,
	useDisclosure,
	VStack,
} from '@chakra-ui/react';

import { Asset, AssetBook } from 'entities/assets/interfaces/asset';
import { trackAddStoryModalClose } from 'shared/analytics/modals/close';
import { trackAddStoryModalOpen } from 'shared/analytics/modals/open';
import { useAppToast } from 'shared/hooks/toast';
import { BookIcon, FireIcon, MoreIcon, PlusIcon } from 'shared/icons';
import { AddStoriesModal } from 'shared/ui/add-stories-modal';
import {
	BackgroundsByStoryDocument,
	useDeleteBackgroundFromStoryMutation,
	useUpdateBackgroundChaptersMutation,
} from 'widgets/backgrounds-list/graphql/queries.gen';
import {
	ItemsByStoryDocument,
	useDeleteItemFromStoryMutation,
	useUpdateItemChaptersMutation,
} from 'widgets/items-list/graphql/queries.gen';

import { ChaptersAssetSelect } from '../chapters-asset-select';
import { BookChaptersForAssetSelectQuery } from '../chapters-asset-select/queries.gen';
import {
	useChangeBackgroundAssignsMutation,
	useChangeItemAssignsMutation,
} from './queries.gen';

interface StoriesAssetTabProps {
	asset: Asset;
	books: AssetBook[];
	refetch?: () => void;
	type?: string;
}

export const StoriesAssetTab: React.FC<StoriesAssetTabProps> = ({
	asset,
	books,
	refetch,
	type = 'hero',
}) => {
	const toast = useAppToast();
	const cancelRef = useRef(null);

	const {
		isOpen: isOpenAddStoriesModal,
		onOpen: onOpenAddStoriesModal,
		onClose: onCloseAddStoriesModal,
	} = useDisclosure();

	const {
		isOpen: isOpenDeleteStoryModal,
		onOpen: onOpenDeleteStoryModal,
		onClose: onCloseDeleteStoryModal,
	} = useDisclosure();

	const [updatedStory, setUpdatedStory] = useState('');
	const [deletedStory, setDeletedStory] = useState<AssetBook | null>(null);

	const [updateBackgroundChapters, { loading: updateBackgroundLoading }] =
		useUpdateBackgroundChaptersMutation({
			onCompleted: () =>
				toast({
					title: 'Background updated successfully',
					status: 'success',
				}),
			onError: (mutationError) =>
				toast({
					title: `Background was not updated. ${mutationError.message}`,
					status: 'error',
				}),
			refetchQueries: [BackgroundsByStoryDocument],
		});

	const [
		updateBackgroundChaptersAssigns,
		{ loading: updateBackgroundAssignsLoading },
	] = useChangeBackgroundAssignsMutation({
		onCompleted: () =>
			toast({
				title: 'Background updated successfully',
				status: 'success',
			}),
		onError: (mutationError) =>
			toast({
				title: `Background was not updated. ${mutationError.message}`,
				status: 'error',
			}),
		refetchQueries: [BackgroundsByStoryDocument],
	});

	const [
		deleteBackgroundFromStory,
		{ loading: deleteBackgroundFromStoryLoading },
	] = useDeleteBackgroundFromStoryMutation();

	const [updateItemChapters, { loading: updateItemLoading }] =
		useUpdateItemChaptersMutation({
			onCompleted: () =>
				toast({
					title: 'Item updated successfully',
					status: 'success',
				}),
			onError: (mutationError) =>
				toast({
					title: `Item was not updated. ${mutationError.message}`,
					status: 'error',
				}),
			refetchQueries: [ItemsByStoryDocument],
		});

	const [updateItemChaptersAssigns, { loading: updateItemAssignsLoading }] =
		useChangeItemAssignsMutation({
			onCompleted: () =>
				toast({
					title: 'Item updated successfully',
					status: 'success',
				}),
			onError: (mutationError) =>
				toast({
					title: `Item was not updated. ${mutationError.message}`,
					status: 'error',
				}),
			refetchQueries: [ItemsByStoryDocument],
		});

	const [deleteItemFromStory, { loading: deleteItemFromStoryLoading }] =
		useDeleteItemFromStoryMutation();

	const handleStoryUpdateChapters = useCallback(
		(bookId: string) =>
			async (chapters: BookChaptersForAssetSelectQuery['book']['chapters']) => {
				setUpdatedStory(bookId);

				try {
					if (asset.type === 'background') {
						await updateBackgroundChaptersAssigns({
							variables: {
								dto: {
									backgroundId: asset.id,
									chaptersIds: [
										...(asset.chapters || [])
											.filter((chapter) => chapter.book?.id !== bookId)
											.map((chapter) => chapter.id),
										...(chapters || []).map((chapter) => chapter.id),
									],
									bookId,
								},
							},
						});
					}
					if (asset.type === 'item') {
						await updateItemChaptersAssigns({
							variables: {
								dto: {
									itemId: asset.id,
									chaptersIds: [
										...(asset.chapters || [])
											.filter((chapter) => chapter.book?.id !== bookId)
											.map((chapter) => chapter.id),
										...(chapters || []).map((chapter) => chapter.id),
									],
									bookId,
								},
							},
						});
					}
				} finally {
					if (refetch) refetch();
					setUpdatedStory('');
				}
			},
		[
			asset.chapters,
			asset.id,
			asset.type,
			refetch,
			updateBackgroundChaptersAssigns,
			updateItemChaptersAssigns,
		],
	);

	const handleBookOpenInNewTab = useCallback(
		(bookId: string) => () =>
			window.open(
				`${window.location.origin}/stories/${bookId}/chapters`,
				'_blank',
			),
		[],
	);

	const handleDeleteStoryAttempt = useCallback(
		(book: AssetBook) => () => {
			onOpenDeleteStoryModal();
			setDeletedStory(book);
		},
		[onOpenDeleteStoryModal],
	);

	const handleDeleteModalClose = useCallback(() => {
		onCloseDeleteStoryModal();
		setDeletedStory(null);
	}, [onCloseDeleteStoryModal]);

	const handleDeleteStorySubmit = useCallback(async () => {
		try {
			if (asset.type === 'background') {
				await deleteBackgroundFromStory({
					variables: {
						chapter: {
							backgrounds: [asset?.id || ''],
							chapterIds: (asset?.chapters || []).reduce((acc, chapter) => {
								if (chapter.book?.id === deletedStory?.id)
									return [...acc, chapter.id];
								return acc;
							}, [] as string[]),
						},
					},
				});
			}
			if (asset.type === 'item') {
				await deleteItemFromStory({
					variables: {
						chapter: {
							items: [asset?.id || ''],
							// eslint-disable-next-line sonarjs/no-identical-functions
							chapterIds: (asset?.chapters || []).reduce((acc, chapter) => {
								if (chapter.book?.id === deletedStory?.id)
									return [...acc, chapter.id];
								return acc;
							}, [] as string[]),
						},
					},
				});
			}
			handleDeleteModalClose();
			toast({
				title: `${deletedStory?.name} was removed`,
				status: 'success',
			});
		} catch (error) {
			toast({
				title: `Can't remove story. ${error}`,
				status: 'error',
			});
		} finally {
			if (refetch) refetch();
		}
	}, [
		asset?.chapters,
		asset?.id,
		asset.type,
		deleteBackgroundFromStory,
		deleteItemFromStory,
		deletedStory?.id,
		deletedStory?.name,
		handleDeleteModalClose,
		refetch,
		toast,
	]);

	const booksChapters = Object.values(
		asset?.chapters?.reduce((acc, chapter) => {
			if (!chapter.book?.id) return acc;
			if (!acc[chapter.book.id])
				return {
					...acc,
					[chapter.book.id]: {
						...chapter.book,
						chapters: [
							{
								id: chapter.id,
								name: chapter.name,
								chapterOrder: chapter.chapterOrder,
							},
						],
					} as AssetBook,
				};

			return {
				...acc,
				[chapter.book.id]: {
					...acc[chapter.book.id],
					chapters: [...acc[chapter.book.id].chapters, chapter],
				} as AssetBook,
			};
		}, {} as Record<string, AssetBook>) || {},
	);

	const handleAddStoriesSubmit = useCallback(
		async (chapters: AssetBook[]) => {
			const chapterIds = chapters.reduce((acc, book) => {
				return [...acc, ...book.chapters.map((chapter) => chapter.id)];
			}, [] as string[]);

			try {
				if (asset.type === 'background') {
					await updateBackgroundChapters({
						variables: {
							chapter: {
								backgrounds: [asset.id],
								chapterIds,
							},
						},
					});
				}
				if (asset.type === 'item') {
					await updateItemChapters({
						variables: {
							chapter: {
								items: [asset.id],
								chapterIds,
							},
						},
					});
				}
			} finally {
				if (refetch) refetch();
			}
		},
		[
			asset.id,
			asset.type,
			refetch,
			updateBackgroundChapters,
			updateItemChapters,
		],
	);

	return (
		<>
			<Grid h="100%" templateRows="40px 1fr" gap="15px" p={5}>
				<GridItem as={Flex} align="center" justifyContent="flex-end">
					<Button
						leftIcon={<PlusIcon />}
						onClick={() => {
							onOpenAddStoriesModal();
							trackAddStoryModalOpen();
						}}
					>
						Add
					</Button>
				</GridItem>
				{books.length ? (
					<TableContainer width="100%" overflowY="auto">
						<Table w="full" height="full">
							<Thead position="sticky" top="0" zIndex={101}>
								<Tr>
									<Th color="#808192" background="none">
										Story Name
									</Th>
									<Th color="#808192" background="none">
										Id
									</Th>
									<Th color="#808192" background="none">
										Chapters
									</Th>
									<Th color="#808192" background="none">
										Type
									</Th>
									<Th />
								</Tr>
							</Thead>
							<Tbody>
								{books?.map((book) => (
									<Tr key={book.id}>
										<Td>
											<Box h="full" w="full">
												<Text
													fontWeight={600}
													lineHeight="24px"
													overflow="hidden"
													whiteSpace="nowrap"
													textOverflow="ellipsis"
													maxW="162px"
												>
													{book.name}
												</Text>
											</Box>
										</Td>
										<Td
											overflow="hidden"
											whiteSpace="nowrap"
											textOverflow="ellipsis"
										>
											<Text>{book.customId}</Text>
										</Td>
										<Td>
											<ChaptersAssetSelect
												bookId={book.id}
												selectedChapters={
													asset?.chapters?.filter(
														(chapter) => chapter.book?.id === book.id,
													) || []
												}
												onSubmit={handleStoryUpdateChapters(book.id)}
												isLoading={
													updateBackgroundLoading ||
													updateItemLoading ||
													updateItemAssignsLoading ||
													updateBackgroundAssignsLoading
												}
												updatedStory={updatedStory}
											/>
										</Td>
										<Td>
											<Badge
												as={Flex}
												align="center"
												gap="6px"
												p="5px 6px"
												bg="#EEECFC"
												color="#644DED"
											>
												{book.fantasy ? (
													<>
														<FireIcon />
														<Text>FR</Text>
													</>
												) : (
													<>
														<BookIcon />
														<Text>Story</Text>
													</>
												)}
											</Badge>
										</Td>
										<Td>
											<Flex h="100%" w="100%" justifyContent="center">
												<Menu>
													<MenuButton
														as={IconButton}
														variant="ghost"
														size="square"
														aria-label="more"
													>
														<MoreIcon w={6} h={6} />
													</MenuButton>
													<MenuList>
														<MenuItem onClick={handleBookOpenInNewTab(book.id)}>
															Open in New Tab
														</MenuItem>

														<MenuItem onClick={handleDeleteStoryAttempt(book)}>
															Remove from Stories
														</MenuItem>
													</MenuList>
												</Menu>
											</Flex>
										</Td>
									</Tr>
								))}
							</Tbody>
						</Table>
					</TableContainer>
				) : (
					<GridItem as={VStack} justify="center" gap="14px">
						<Text fontSize={18} lineHeight="27px" fontWeight={600}>
							There is no {type} in any story
						</Text>
						{/* <Text maxW={301} color="#ABACBE" align="center">
							to save a {type}, you need to add him to at least one chapter
						</Text> */}
					</GridItem>
				)}
			</Grid>
			{isOpenAddStoriesModal && (
				<AddStoriesModal
					books={booksChapters.reduce(
						(acc, book) => ({ ...acc, [book.id]: book }),
						{},
					)}
					isOpen={isOpenAddStoriesModal}
					onClose={() => {
						onCloseAddStoriesModal();
						trackAddStoryModalClose();
					}}
					onSubmit={handleAddStoriesSubmit}
				/>
			)}
			{isOpenDeleteStoryModal && (
				<AlertDialog
					isOpen={isOpenDeleteStoryModal}
					leastDestructiveRef={cancelRef}
					onClose={handleDeleteModalClose}
				>
					<AlertDialogOverlay>
						<AlertDialogContent>
							<AlertDialogHeader fontSize="lg" fontWeight="bold">
								Asset
							</AlertDialogHeader>

							<AlertDialogBody>
								If you delete asset from the story, it can always be returned.
								Are you sure you want to continue?
							</AlertDialogBody>

							<AlertDialogFooter>
								{!deleteBackgroundFromStoryLoading &&
									!deleteItemFromStoryLoading && (
										<Button
											ref={cancelRef}
											variant="ghost"
											onClick={handleDeleteModalClose}
										>
											Cancel
										</Button>
									)}
								<Button onClick={handleDeleteStorySubmit} ml={3}>
									Delete
								</Button>
							</AlertDialogFooter>
						</AlertDialogContent>
					</AlertDialogOverlay>
				</AlertDialog>
			)}
		</>
	);
};
