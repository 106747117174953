import { useEffect, useRef } from 'react';

export const useDebouncedEffect = (
	callback: () => void,
	timeout: number,
	deps = <any[]>[],
) => {
	const data = useRef<{
		firstTime: boolean;
		clearFunc?: (() => void | (() => void)) | void;
	}>({
		firstTime: true,
		clearFunc: () => {},
	});

	useEffect(() => {
		const { firstTime, clearFunc } = data.current;

		if (firstTime) {
			data.current.firstTime = false;
			return;
		}

		const handler = setTimeout(() => {
			if (clearFunc && typeof clearFunc === 'function') {
				clearFunc();
			}
			data.current.clearFunc = callback();
		}, timeout);

		// eslint-disable-next-line consistent-return
		return () => clearTimeout(handler);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [timeout, ...deps]);
};
