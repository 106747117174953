import React from 'react';
import {
	Box,
	Flex,
	Grid,
	Image,
	Spacer,
	Spinner,
	Text,
} from '@chakra-ui/react';

import KeywordsAssets from 'features/asset/add-assets-modal/keyword-assets';
import ArtCatalogSort from 'pages/art-catalog/ui/ArtCatalogSort';
import NotFound from 'shared/ui/not-found';
import { useBackgroundsByStoryQuery } from 'widgets/backgrounds-list/graphql/queries.gen';
import { AssetsSortArgDto, Background } from 'models.gen';

const ASSETS_LIMIT = 12;

const SORT_MAP: Record<string, AssetsSortArgDto> = {
	'updatedAt-desc': {
		field: 'updatedAt',
		order: 'DESC',
	},
	'name-asc': {
		field: 'filename',
		order: 'ASC',
	},
	'name-desc': {
		field: 'filename',
		order: 'DESC',
	},
	'used-asc': {
		field: 'used',
		order: 'asc',
	},
	'used-desc': {
		field: 'used',
		order: 'desc',
	},
};

interface AssetsTabProps {
	selectedAsset: Background | null;
	setSelectedAsset: (v: Background | null) => void;
	search: string;
	chapterId: string;
	page: number;
	sort: string;
	setSort: (v: string) => void;
	keywords: string[];
	setKeywords: (v: string[]) => void;
}

const AssetsTab: React.FC<AssetsTabProps> = ({
	chapterId,
	selectedAsset,
	setSelectedAsset,
	search,
	page,
	// setPage,
	sort,
	setSort,
	keywords,
	setKeywords,
}) => {
	const { data, loading } = useBackgroundsByStoryQuery({
		variables: {
			page: page || undefined,
			limit: ASSETS_LIMIT,
			filter: {
				chapterId,
				name: search,
				keywords,
			},
			sort: SORT_MAP[sort],
		},
	});

	return (
		<>
			<Flex px={6} my={3} h="full">
				<KeywordsAssets
					type="background"
					keywords={keywords}
					setKeywords={setKeywords}
				/>
				<Spacer />
				<ArtCatalogSort sort={sort} setSort={setSort} />
			</Flex>
			{loading ? (
				<Spinner size="xl" m="auto" />
			) : (
				<Grid templateRows="auto 1fr" overflow="auto">
					<Grid
						px={5}
						pb={4}
						gap={3}
						gridRowGap={6}
						gridColumnGap={3}
						templateColumns="repeat(3, 1fr)"
						alignContent="start"
					>
						{data?.backgrounds.backgrounds.map((asset) => (
							<Flex
								key={asset.id}
								flexDirection="column"
								gap={2}
								minW="240px"
								maxW="260px"
							>
								<Box
									h="min-content"
									userSelect="none"
									border="4px solid transparent"
									borderRadius="10px"
									cursor="pointer"
									borderColor={`${
										selectedAsset?.id === asset.id
											? 'rgba(100, 77, 237, 0.08)'
											: ''
									}`}
									onClick={() => setSelectedAsset(asset as Background)}
								>
									<Flex
										height="180px"
										alignItems="center"
										justifyContent="center"
										border="1px solid #EEEEF2"
										borderRadius="10px"
										overflow="hidden"
										borderColor={`${
											selectedAsset?.id === asset.id ? '#644DED' : ''
										}`}
										_hover={{
											borderColor: '#644DED',
										}}
									>
										<Image
											width="100%"
											height="100%"
											objectFit="cover"
											alt={asset?.name || ''}
											src={asset?.link || ''}
										/>
									</Flex>
								</Box>
								<Text
									p={1}
									fontFamily="Poppins"
									fontSize="12px"
									fontWeight={600}
									lineHeight="16px"
								>
									{asset.name}
								</Text>
							</Flex>
						))}
					</Grid>
					{!loading && !data?.backgrounds.backgrounds.length ? (
						<Box m="auto">
							<NotFound />
						</Box>
					) : null}
				</Grid>
			)}
		</>
	);
};

export default AssetsTab;
