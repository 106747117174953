import React, { useEffect, useMemo, useState } from 'react';
import {
	Box,
	Button,
	Flex,
	Grid,
	GridItem,
	Text,
	Tooltip,
	useClipboard,
} from '@chakra-ui/react';

import { EventTypes, sendAmplitudeData } from 'shared/analytics';
import { CopyIcon, StarsIcon } from 'shared/icons';
import { TextArea } from 'shared/ui';
import { ErrorText } from 'shared/ui/error';
import { getPromptByTone } from 'widgets/ask-ai-modal/ui/utils/getPromptByTone';

import { useCreateAiRequestLazyQuery } from '../../graphql/graphql.gen';
import {
	AmplitudeInfo,
	CHANGE_TONE_EVENTS,
	PromptProps,
	PROMPTS_INFO_EVENTS,
	PROMPTS_INFO_MAP,
	PROMPTS_MAP,
} from '../types';

interface AiModalContentProps {
	selectedPrompt: PromptProps;
	selectedText: string;
	amplitudeInfo: AmplitudeInfo;
	customValue: string;
	setCustomValue: (v: string) => void;
}

export const AiModalContent: React.FC<AiModalContentProps> = ({
	selectedPrompt,
	selectedText,
	amplitudeInfo,
	customValue,
	setCustomValue,
}) => {
	const [promptValue, setPromptValue] = useState<string>('');
	const [resultValue, setResultValue] = useState<string>('');
	const [prevRequest, setPrevRequest] = useState<string>('');

	const { onCopy, hasCopied } = useClipboard(resultValue);
	const [createRequest, { loading, error }] = useCreateAiRequestLazyQuery();

	const requestValue = useMemo(
		() => `${promptValue}\n"${customValue}"`,
		[customValue, promptValue],
	);

	const isCustomPrompt = useMemo(
		() => selectedPrompt.value === PROMPTS_MAP.CUSTOM,
		[selectedPrompt],
	);

	const handleGenerateAnswer = async () => {
		const estimatedTokensAmount = Math.round(requestValue.length / 4);
		const eventType =
			prevRequest === requestValue
				? EventTypes.ASKAI_REGENERATE_CLICK
				: EventTypes.ASKAI_GENERATE_CLICK;

		setResultValue('');

		sendAmplitudeData(eventType, {
			...amplitudeInfo,
			prompt: promptValue,
			estimated_tokens: estimatedTokensAmount,
			customizable_part: customValue,
			option: selectedPrompt.option
				? CHANGE_TONE_EVENTS[selectedPrompt.option]
				: PROMPTS_INFO_EVENTS[selectedPrompt.value],
		});

		const answer = await createRequest({
			variables: {
				prompt: requestValue,
			},
		});
		const result = answer?.data?.createRequest.result;
		const tokens = answer?.data?.createRequest.totalTokens;

		setResultValue(result || '');
		setPrevRequest(requestValue);

		sendAmplitudeData(EventTypes.ASKAI_RESULT_SHOWN, {
			...amplitudeInfo,
			result,
			prompt: promptValue,
			customizable_part: customValue,
			option: selectedPrompt.option
				? CHANGE_TONE_EVENTS[selectedPrompt.option]
				: PROMPTS_INFO_EVENTS[selectedPrompt.value],
			tokens,
		});
	};

	const handleCopy = () => {
		onCopy();
		sendAmplitudeData(EventTypes.ASKAI_COPY_CLICK, {
			...amplitudeInfo,
			result: resultValue,
		});
	};

	useEffect(() => {
		setCustomValue(selectedText);
	}, [selectedText, setCustomValue]);

	useEffect(() => {
		if (selectedPrompt.option) {
			setPromptValue(getPromptByTone(selectedPrompt.option));
		} else {
			setPromptValue(PROMPTS_INFO_MAP[selectedPrompt.value]);
		}
		setResultValue('');
	}, [selectedPrompt, selectedText, setCustomValue, setResultValue]);

	useEffect(() => {
		if (error) {
			sendAmplitudeData(EventTypes.ASKAI_ERROR_SHOWN, {
				...amplitudeInfo,
				error: error?.message,
				error_type: error?.extraInfo,
			});
		}
	}, [amplitudeInfo, error]);

	return (
		<>
			<Grid flexDir="column" w="100%" h="100%">
				<GridItem borderBottom="1px solid #eeeef2">
					<Flex justify="space-between" align="center" m="15px 15px 0px 10px">
						<Text fontSize="14px" color="#484964">
							Results
						</Text>
						<Tooltip
							isOpen={hasCopied}
							label="Copied!"
							placement="bottom"
							offset={[0, -15]}
						>
							<Tooltip label="Copy">
								<Button
									variant="ghost"
									disabled={!resultValue.length}
									size="sm"
									onClick={handleCopy}
								>
									<CopyIcon w="24px" h="24px" opacity=".7" />
								</Button>
							</Tooltip>
						</Tooltip>
					</Flex>
					<Box
						fontSize="13px"
						color="#242533"
						overflowY="auto"
						p="0px 15px"
						h={isCustomPrompt ? '350px' : '230px'}
						maxH={isCustomPrompt ? '350px' : '230px'}
					>
						{error && requestValue === prevRequest ? (
							<ErrorText message={error?.message} />
						) : (
							resultValue
						)}
					</Box>
				</GridItem>
				<GridItem m="10px 15px">
					{isCustomPrompt ? (
						<>
							<Text fontSize="14px" color="#484964" mb="5px">
								Ask AI
							</Text>
							<TextArea
								value={customValue}
								placeholder="Enter your promt/ Ask AI something/ etc"
								isFullWidth
								onChange={(e) => setCustomValue(e.target.value)}
							/>
						</>
					) : (
						<>
							<Text fontSize="14px" color="#484964" mb="5px">
								Prompts
							</Text>
							<TextArea
								value={promptValue}
								onChange={(e) => setPromptValue(e.target.value)}
								isFullWidth
							/>
							<Text fontSize="14px" color="#484964" mb="5px">
								Customizable part
							</Text>
							<TextArea
								value={customValue}
								isFullWidth
								onChange={(e) => setCustomValue(e.target.value)}
							/>
						</>
					)}
				</GridItem>
			</Grid>
			<Flex
				borderTop="1px solid #eeeef2"
				pos="absolute"
				w="100%"
				p="10px 15px"
				bottom="0"
				align="center"
				justify="flex-end"
			>
				{loading ? (
					<Flex left={5} pos="absolute" align="center">
						<StarsIcon color="#644DED" w={7} h={7} />
						<Text ml={2} mt={1.5} fontSize="13px" color="#644DED">
							AI is writing...
						</Text>
					</Flex>
				) : null}
				<Button
					size="lg"
					fontWeight={400}
					width="82px"
					h="40px"
					onClick={handleGenerateAnswer}
				>
					Generate
				</Button>
			</Flex>
		</>
	);
};
