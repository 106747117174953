import { SelectedAssetsInfo } from 'widgets/create-card-modal/interfaces';

export const getCardsAmount = (
	selectedAssetsInfo: SelectedAssetsInfo,
): number => {
	const bodies = selectedAssetsInfo.selectedBodies.length || 1;
	const clothes = selectedAssetsInfo.selectedClothes.length || 1;
	const hairs = selectedAssetsInfo.selectedHairs.length || 1;
	const layers = selectedAssetsInfo.selectedLayers.length || 1;

	return layers * hairs * bodies * clothes;
};
