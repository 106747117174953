import { useCallback } from 'react';
import type { Transition } from 'history';

import { useBlocker } from '../blocker';

type UsePromptFunc = (cb: (tx: Transition) => void, condition: boolean) => void;

/**
 * @source https://github.com/remix-run/react-router/issues/8139#issuecomment-1021457943
 */
export const usePrompt: UsePromptFunc = (cb, condition = true) => {
	const blocker = useCallback(
		(tx: Transition) => {
			cb(tx);
		},
		[cb],
	);
	return useBlocker(blocker, condition);
};
