import React, { Dispatch, SetStateAction, useCallback, useState } from 'react';
import {
	Box,
	Button,
	Flex,
	Grid,
	IconButton,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Popover,
	PopoverBody,
	PopoverContent,
	PopoverTrigger,
	Spinner,
	Tooltip,
	useDisclosure,
} from '@chakra-ui/react';
import { EditorState, RichUtils } from 'draft-js';
import { useStore } from 'effector-react';
import { Form, Formik } from 'formik';

import { scriptModel } from 'entities/script';
import { uiModel } from 'entities/ui';
import { UserCommentCard, useUserByEmailQuery } from 'entities/user';
import { ButtonType } from 'shared/analytics';
import { CommentIcon, MoreIcon } from 'shared/icons';
import { DeletionAlert, FormTextArea } from 'shared/ui';
import {
	extractCommentedTextOffsetsFromBlock,
	extractEntityKeyFromSelection,
	getBlocksKeysWithEntityByEntityKey,
	getCommentTextFromSelection,
	movePointerToEndOfSelection,
} from 'shared/utils';
import { getRows } from 'shared/utils/get-rows';

import { addOrEditComment } from '../lib';
import { CreateCommentFormValues } from '../schema';

interface CreateCommentProps {
	editorState: EditorState;
	setEditorState: Dispatch<SetStateAction<EditorState>>;
	handleOpenCommentsList: () => void;
}

export const CreateComment: React.FC<CreateCommentProps> = ({
	editorState,
	setEditorState,
	handleOpenCommentsList,
}) => {
	const { onOpen, onClose, isOpen } = useDisclosure();
	const [isDeleting, setIsDeleting] = useState(false);

	const [comment, setComment] = useState('');
	const isAllCommentsResolved = useStore(uiModel.$isAllCommentsResolved);

	const { data: userData, loading: isUserLoading } = useUserByEmailQuery();

	const handleOpenCreateComment = useCallback(() => {
		onOpen();
		setComment(
			getCommentTextFromSelection(
				editorState.getCurrentContent(),
				editorState.getSelection(),
			),
		);
		handleOpenCommentsList();
	}, [editorState, handleOpenCommentsList, onOpen]);

	const handleCommentDelete = useCallback(() => {
		const contentState = editorState.getCurrentContent();
		let newSelection = editorState.getSelection();
		let newEditorState = editorState;

		if (newSelection.isCollapsed()) {
			setEditorState(EditorState.forceSelection(newEditorState, newSelection));
			scriptModel.setIsScriptEdited(true);
			setIsDeleting(false);
			onClose();
			return;
		}

		const commentKey = extractEntityKeyFromSelection(
			contentState,
			newSelection,
		);

		const blocksKeys = getBlocksKeysWithEntityByEntityKey(
			contentState,
			commentKey || '',
		);

		blocksKeys.forEach((key) => {
			const { startOffset, endOffset } = extractCommentedTextOffsetsFromBlock(
				contentState,
				key,
				commentKey,
			);

			const selectionForDelete = EditorState.forceSelection(
				newEditorState,
				newEditorState.getSelection().merge({
					anchorKey: key,
					focusKey: key,
					anchorOffset: startOffset,
					focusOffset: endOffset,
					// * this needed for proper deletion
					isBackward: false,
				}),
			).getSelection();

			newEditorState = RichUtils.toggleLink(
				newEditorState,
				selectionForDelete,
				null,
			);
			newSelection = movePointerToEndOfSelection(newEditorState.getSelection());
		});

		setEditorState(EditorState.forceSelection(newEditorState, newSelection));
		scriptModel.setIsScriptEdited(true);
		setIsDeleting(false);
		onClose();
	}, [editorState, onClose, setEditorState]);

	const handleAddOrEditComment = useCallback(
		async ({ formValue }: any, { setSubmitting, resetForm }: any) => {
			setSubmitting(true);
			if (formValue)
				setEditorState(
					addOrEditComment(
						editorState,
						formValue,
						userData?.user.picture || '',
						userData?.user.fullName || userData?.user.email,
					),
				);
			onClose();
			resetForm();
			setSubmitting(false);
			scriptModel.setIsScriptEdited(true);
		},
		[
			editorState,
			onClose,
			setEditorState,
			userData?.user.email,
			userData?.user.fullName,
			userData?.user.picture,
		],
	);

	return (
		<Box position="relative">
			<Popover
				isOpen={isOpen}
				onOpen={handleOpenCreateComment}
				onClose={onClose}
				placement="bottom-start"
				closeOnBlur={false}
			>
				<PopoverTrigger>
					<IconButton
						variant="ghost"
						borderRadius={8}
						size="sm"
						aria-label="Add Comment"
					>
						<Tooltip label="Add Comment" w="100%" h="100%">
							<Flex
								w="100%"
								h="100%"
								align="center"
								justify="center"
								position="relative"
							>
								<CommentIcon w={5} h={5} />
								{isAllCommentsResolved && (
									<Box
										bg="#644DED"
										w="12px"
										h="12px"
										borderRadius="full"
										position="absolute"
										border="3px solid #FFFFFF"
										top="6px"
										right="4px"
									/>
								)}
							</Flex>
						</Tooltip>
					</IconButton>
				</PopoverTrigger>
				<PopoverContent w="312px">
					<PopoverBody padding="11px 16px">
						{isUserLoading ? (
							<Flex justify="center" height="180px">
								<Spinner size="md" />
							</Flex>
						) : (
							<>
								<Grid templateColumns="1fr 32px" gap="10px" alignItems="center">
									<UserCommentCard
										author={
											userData?.user.fullName || userData?.user.email || '---'
										}
										picture={userData?.user.picture}
										createdAt={Date.now() || ''}
									/>
									<Flex alignSelf="start">
										<Menu>
											{() => (
												<>
													<MenuButton
														as={IconButton}
														variant="ghost"
														size="sm"
														padding="10px 0"
														icon={<MoreIcon w={5} h={5} />}
													/>
													<MenuList minW="136px">
														{/* <MenuItem onClick={() => alert('edit')}>Edit</MenuItem> */}
														<MenuItem onClick={() => setIsDeleting(true)}>
															Delete
														</MenuItem>
													</MenuList>
												</>
											)}
										</Menu>
									</Flex>
								</Grid>

								<Box mt="20px">
									<Formik<CreateCommentFormValues>
										enableReinitialize
										initialValues={{ formValue: comment }}
										onSubmit={handleAddOrEditComment}
										onReset={onClose}
									>
										{({ values }) => (
											<Form>
												<FormTextArea
													name="formValue"
													autocomplete="off"
													placeholder="Comment"
													rows={getRows(values.formValue)}
												/>
												<Flex justify="flex-end" mt="10px">
													<Button type="reset" size="md" variant="secondary">
														Cancel
													</Button>
													<Button
														name={ButtonType.ADD_COMMENT}
														type="submit"
														size="md"
														variant="secondary"
														disabled={!values.formValue}
													>
														Comment
													</Button>
												</Flex>
											</Form>
										)}
									</Formik>
								</Box>
							</>
						)}
						{isDeleting && (
							<DeletionAlert
								top="0"
								right="0"
								bottom="0"
								left="0"
								borderRadius="8px"
								p="36px 10px"
								bg="rgba(36, 37, 51, 0.87)"
								message="Delete this comment thread?"
								onClose={() => setIsDeleting(false)}
								onSubmit={handleCommentDelete}
							/>
						)}
					</PopoverBody>
				</PopoverContent>
			</Popover>
		</Box>
	);
};
