import * as Types from '../../../../../models.gen';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AssetCardBodyByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type AssetCardBodyByIdQuery = { body: { link?: string | null, name?: string | null, emotions: Array<string> } };


export const AssetCardBodyByIdDocument = gql`
    query AssetCardBodyById($id: String!) {
  body(id: $id) {
    link
    name
    emotions
  }
}
    `;

/**
 * __useAssetCardBodyByIdQuery__
 *
 * To run a query within a React component, call `useAssetCardBodyByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssetCardBodyByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssetCardBodyByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAssetCardBodyByIdQuery(baseOptions: Apollo.QueryHookOptions<AssetCardBodyByIdQuery, AssetCardBodyByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AssetCardBodyByIdQuery, AssetCardBodyByIdQueryVariables>(AssetCardBodyByIdDocument, options);
      }
export function useAssetCardBodyByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AssetCardBodyByIdQuery, AssetCardBodyByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AssetCardBodyByIdQuery, AssetCardBodyByIdQueryVariables>(AssetCardBodyByIdDocument, options);
        }
export type AssetCardBodyByIdQueryHookResult = ReturnType<typeof useAssetCardBodyByIdQuery>;
export type AssetCardBodyByIdLazyQueryHookResult = ReturnType<typeof useAssetCardBodyByIdLazyQuery>;
export type AssetCardBodyByIdQueryResult = Apollo.QueryResult<AssetCardBodyByIdQuery, AssetCardBodyByIdQueryVariables>;