import React from 'react';
import { createIcon } from '@chakra-ui/react';

export const AirTableIcon = createIcon({
	viewBox: '0 0 21 18',
	path: (
		<>
			<path
				d="M9.37691 0.221672L1.54759 3.46135C1.1122 3.64153 1.11671 4.25996 1.55483 4.43362L9.41692 7.55138C10.1077 7.82534 10.877 7.82534 11.5678 7.55138L19.43 4.43362C19.868 4.25996 19.8726 3.64153 19.4371 3.46135L11.6079 0.221672C10.8935 -0.0738906 10.0911 -0.0738906 9.37691 0.221672Z"
				fill="currentColor"
			/>
			<path
				d="M11.1899 9.25369V17.0423C11.1899 17.4128 11.5635 17.6664 11.9078 17.5299L20.6686 14.1294C20.8686 14.0501 20.9998 13.8569 20.9998 13.6418V5.85318C20.9998 5.48272 20.6262 5.22906 20.2819 5.36556L11.5211 8.76607C11.3212 8.84536 11.1899 9.03861 11.1899 9.25369Z"
				fill="currentColor"
			/>
			<path
				d="M9.1444 9.65574L6.54439 10.9111L6.28041 11.0387L0.791965 13.6685C0.444056 13.8363 0 13.5828 0 13.1963V5.88607C0 5.74625 0.0716947 5.62553 0.167842 5.5346C0.207962 5.49436 0.253424 5.46125 0.300667 5.43501C0.43183 5.35632 0.618901 5.33531 0.777959 5.39822L9.10071 8.69582C9.52376 8.86366 9.557 9.45645 9.1444 9.65574Z"
				fill="currentColor"
			/>
		</>
	),
});
