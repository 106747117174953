import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Button, Flex, Text, VStack } from '@chakra-ui/react';

import { UpdateChapterDocLink } from 'features/chapter/update/update-doc-link';
import { getChapter_chapter_googleDocABTest1 } from 'shared/api/graphql/model';
import { PlusIcon } from 'shared/icons';

interface Props {
	googleDocABTest1: getChapter_chapter_googleDocABTest1 | null;
	googleDocABTest2: getChapter_chapter_googleDocABTest1 | null;
	googleDocABTest3: getChapter_chapter_googleDocABTest1 | null;
	bookId: string;
	chapterId: string;
	handleRedirect: (v: string) => void;
}

export interface DocVersion {
	version: 'B' | 'C' | 'D';
	isShow: boolean;
	doc: getChapter_chapter_googleDocABTest1 | null;
	docName: 'googleDocABTest1' | 'googleDocABTest2' | 'googleDocABTest3';
}

export const UpdateABDocLinksForm: React.FC<Props> = ({
	googleDocABTest1,
	googleDocABTest2,
	googleDocABTest3,
	bookId,
	chapterId,
	handleRedirect,
}) => {
	const chapterDocVersions: DocVersion[] = useMemo(
		() => [
			{
				version: 'B',
				isShow: !!googleDocABTest1,
				doc: googleDocABTest1,
				docName: 'googleDocABTest1',
			},
			{
				version: 'C',
				isShow: !!googleDocABTest2,
				doc: googleDocABTest2,
				docName: 'googleDocABTest2',
			},
			{
				version: 'D',
				isShow: !!googleDocABTest3,
				doc: googleDocABTest3,
				docName: 'googleDocABTest3',
			},
		],
		[googleDocABTest1, googleDocABTest2, googleDocABTest3],
	);

	const [docVersions, setDocVersions] = useState(chapterDocVersions);

	const isAllVersionsAdded = useMemo(
		() => !docVersions.some((docVersion) => !docVersion.isShow),
		[docVersions],
	);

	const handleVersionAdd = useCallback(() => {
		if (isAllVersionsAdded) return;

		let isAddedNewVersion = false;
		setDocVersions(
			docVersions.map((docVersion) => {
				if (!docVersion.isShow && !isAddedNewVersion) {
					isAddedNewVersion = true;
					return { ...docVersion, isShow: true };
				}
				return docVersion;
			}),
		);
	}, [isAllVersionsAdded, docVersions]);

	useEffect(() => {
		setDocVersions(chapterDocVersions);
	}, [chapterDocVersions]);

	return (
		<Box>
			<Flex py="9px" align="center" justify="space-between">
				<Text
					fontWeight={600}
					fontSize="14px"
					lineHeight="140%"
					color="#808192"
				>
					A/B test Google Doc
				</Text>
				<Button
					leftIcon={<PlusIcon />}
					variant="ghost"
					disabled={isAllVersionsAdded}
					onClick={handleVersionAdd}
				>
					New chapter version
				</Button>
			</Flex>
			<VStack align="stretch">
				{docVersions.map(
					(docVersion) =>
						docVersion.isShow && (
							<UpdateChapterDocLink
								docType={docVersion.docName}
								language="en"
								key={docVersion?.version}
								chapterId={chapterId}
								label={`Version [${docVersion.version}]`}
								initialDocLink={docVersion.doc?.link || ''}
								docId={docVersion.doc?.id}
								docLinkToUpdate={docVersion.docName}
								docNotesData={docVersion?.doc?.docNotes}
								bookId={bookId}
								isDescription
								handleRedirect={handleRedirect}
							/>
						),
				)}
			</VStack>
		</Box>
	);
};
