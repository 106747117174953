import React from 'react';
import { createIcon } from '@chakra-ui/react';

export const UploadIcon = createIcon({
	viewBox: '0 0 24 24',
	path: (
		<>
			<path
				d="M20.6346 12.5084V16.3811C20.6346 19.3202 19.0786 20.4958 16.5198 20.4958H7.11473C4.6291 20.4958 3 19.3202 3 16.3811V12.5084"
				stroke="currentColor"
				strokeLinejoin="round"
				strokeWidth="1.50898"
				fill="none"
				strokeLinecap="round"
			/>
			<path
				d="M16.52 8.15115L11.8175 3.44861L7.11493 8.15115"
				stroke="currentColor"
				strokeWidth="1.50898"
				strokeLinecap="round"
				fill="none"
				strokeLinejoin="round"
			/>
			<path
				d="M11.8174 4.03619L11.8174 16.3804"
				stroke="currentColor"
				strokeWidth="1.50898"
				strokeLinecap="round"
				fill="none"
				strokeLinejoin="round"
			/>
		</>
	),
});
