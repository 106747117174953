import React from 'react';
import { ApolloProvider } from '@apollo/client';

import { api } from 'shared/api';

export const withApollo = (component: () => React.ReactNode) => () => {
	return (
		<ApolloProvider client={api.apolloClient}>{component()}</ApolloProvider>
	);
};
