import React, { useCallback, useEffect, useState } from 'react';
import { Box, useDisclosure } from '@chakra-ui/react';

import {
	Asset,
	AssetBook,
	AssetChapter,
	AssetChats,
	AssetHero,
} from 'entities/assets/interfaces/asset';
import { StoriesAssetTab } from 'features/asset/stories-asset-tab';
import { AssetViewTabs } from 'widgets/update-asset-modal/lib/asset-view-tabs';
import { removeDuplicateIds } from 'widgets/update-asset-modal/utils/remove-duplicate-ids';

import { AddHeroAssetsModal } from '../../../../features/asset/add-assets-modal';
import {
	useUpdateBackgroundMutation,
	useUpdateClothesMutation,
} from '../../../../features/asset/update/forms/graphql/queries.gen';
import { AssetsHero } from '../../../../features/hero/assets-tab/components/assets-hero';
import { GetAssetsDocument } from '../../../../pages/art-catalog/ui/ArtCatalogTable/queries.gen';
import { useAppToast } from '../../../../shared/hooks/toast';
import { DetailedViewAsset } from '../detailed-view';
import { Heroes } from '../heroes';
import {
	FindOneClothesDocument,
	GetBackgroundDocument,
	useGetChaptersByAssetIdLazyQuery,
	useGetHeroByAssetIdLazyQuery,
} from '../modal/queries.gen';
import { Volume } from '../volume';

interface ViewAssetProps {
	asset: Asset;
	chats: AssetChats[];
	chapters?: AssetChapter[];
	books?: AssetBook[];
	activeTab: AssetViewTabs;
	refetch?: () => void;
}

export const ViewAsset: React.FC<ViewAssetProps> = ({
	asset,
	chats,
	chapters,
	books,
	activeTab,
	refetch,
}) => {
	const [altClothesIds, setAltClothesIds] = useState<string[]>([]);
	const [altBackgroundsIds, setAltBackgroundsIds] = useState<string[]>([]);
	const [assetHeroes, setAssetHeroes] = useState<AssetHero[]>([]);
	const [assetChapters, setAssetChapters] = useState<AssetChapter[]>([]);
	const [assetBooks, setAssetBooks] = useState<AssetBook[]>([]);
	const toast = useAppToast();

	const {
		isOpen: isOpenAssetModal,
		onOpen: onOpenAssetModal,
		onClose: onCloseAssetModal,
	} = useDisclosure();

	const [updateAltClothes] = useUpdateClothesMutation({
		refetchQueries: [GetAssetsDocument, FindOneClothesDocument],
	});
	const [updateAltBackgrounds] = useUpdateBackgroundMutation({
		refetchQueries: [GetAssetsDocument, GetBackgroundDocument],
	});

	const [getHeroes] = useGetHeroByAssetIdLazyQuery({
		variables: {
			id: asset.id,
		},
	});

	const [getBodyChapters] = useGetChaptersByAssetIdLazyQuery({
		variables: {
			id: asset.id,
		},
	});

	useEffect(() => {
		switch (activeTab) {
			case AssetViewTabs.HEROES:
				if (assetHeroes.length || chapters?.length || books?.length) return;
				getHeroes().then((res) => {
					setAssetHeroes((res.data?.heroByAssetId as any) || []);
				});
				break;
			case AssetViewTabs.CHAPTERS:
				if (assetChapters.length || chapters?.length || books?.length) return;
				getBodyChapters().then((res) => {
					const allChapters = res.data?.heroByAssetId
						?.map((hero) => hero.chapters)
						.flat();

					const chaptersWithBookCustomId = allChapters?.map((chaptersItem) => ({
						...chaptersItem,
						bookCustomId: chaptersItem?.book?.customId,
					}));
					setAssetChapters(
						removeDuplicateIds(chaptersWithBookCustomId as AssetChapter[]),
					);
				});
				break;

			case AssetViewTabs.STORIES:
				if (assetBooks.length || books?.length) return;
				getBodyChapters().then((res) => {
					const allChapters = res.data?.heroByAssetId
						?.map((hero) => hero.chapters)
						.flat();
					const allBooks = allChapters
						?.map((chapterItem) => chapterItem.book)
						.filter(Boolean)
						.flat();
					setAssetBooks(removeDuplicateIds(allBooks as AssetBook[]));
				});
				break;

			default:
				break;
		}
	}, [
		activeTab,
		getBodyChapters,
		getHeroes,
		assetChapters.length,
		assetHeroes.length,
		chapters?.length,
		books?.length,
		assetBooks.length,
	]);
	// for conversion [{id: ''}] -> ['', '', '']
	useEffect(() => {
		if (asset.alternativeClothes) {
			const altClothesArr: string[] = [];
			asset.alternativeClothes.forEach((e) => altClothesArr.push(e.id));
			setAltClothesIds(altClothesArr);
		}
	}, [asset.alternativeClothes]);
	useEffect(() => {
		if (asset.alternativeBackgrounds) {
			const altBackgroundsArr: string[] = [];
			asset.alternativeBackgrounds.forEach((e) => altBackgroundsArr.push(e.id));
			setAltBackgroundsIds(altBackgroundsArr);
		}
	}, [asset, asset.alternativeBackgrounds]);

	const handleSetFieldValue = useCallback(
		(field: string, value: string[]) => {
			if (field === 'altClothes') {
				setAltClothesIds([...value]);
			} else {
				setAltClothesIds([...altClothesIds, ...value]);
			}

			// to update altClothes onChange
			updateAltClothes({
				variables: {
					clothes: {
						id: asset.id,
						alternativeClothesIds:
							field === 'altClothes'
								? [...value]
								: [...altClothesIds, ...value],
					},
				},
			});
			toast({
				title: 'Alternative Clothes were updated',
				status: 'success',
			});
		},
		[altClothesIds, asset.id, toast, updateAltClothes],
	);

	const handleSetAltBackgroundsValue = useCallback(
		(field: string, value: string[]) => {
			if (field === 'altBackgrounds') {
				setAltBackgroundsIds([...value]);
			} else {
				setAltBackgroundsIds([...altBackgroundsIds, ...value]);
			}

			// to update altBackgrounds onChange
			updateAltBackgrounds({
				variables: {
					background: {
						id: asset.id,
						alternativeBackgroundsIds:
							field === 'altBackgrounds'
								? [...value]
								: [...altBackgroundsIds, ...value],
					},
				},
			});
			toast({
				title: 'Alternative Backgrounds were updated',
				status: 'success',
			});
		},
		[altBackgroundsIds, asset.id, toast, updateAltBackgrounds],
	);

	const renderComponent = useCallback(() => {
		switch (activeTab) {
			case AssetViewTabs.ASSET_DETAILS: {
				return <DetailedViewAsset asset={asset} />;
			}
			case AssetViewTabs.HEROES: {
				return (assetHeroes || []).map((hero) => (
					<Heroes hero={hero as AssetHero} key={hero.id} />
				));
			}
			case AssetViewTabs.CHATS: {
				return <Volume volume={chats} />;
			}
			case AssetViewTabs.CHAPTERS: {
				return <Volume volume={chapters || assetChapters} />;
			}
			case AssetViewTabs.STORIES: {
				if (asset.type === 'background' || asset.type === 'item') {
					return (
						<StoriesAssetTab
							asset={asset}
							books={books || assetBooks}
							refetch={refetch}
							type={asset.type}
						/>
					);
				}
				return <Volume volume={assetBooks} />;
			}
			case AssetViewTabs.ALTERNATIVE_CLOTHES: {
				return (
					<Box m={9}>
						<AssetsHero
							parentId={asset.id}
							defaultId=""
							title=""
							type="clothes"
							assets={altClothesIds}
							hairs={[]}
							openAddModal={onOpenAssetModal}
							setAssetType={() => {}} // here
							handleSetFieldValue={handleSetFieldValue}
							isAltAsset
						/>
						{isOpenAssetModal && (
							<AddHeroAssetsModal
								clothes={[asset?.id]}
								isOpen={isOpenAssetModal}
								onClose={onCloseAssetModal}
								type={asset.type}
								handleSetFieldValue={handleSetFieldValue}
								altClothesIds={altClothesIds}
								isAltAsset
							/>
						)}
					</Box>
				);
			}
			case AssetViewTabs.ALTERNATIVE_BACKGROUNDS: {
				return (
					<Box m={9}>
						<AssetsHero
							parentId={asset.id}
							defaultId=""
							title=""
							type="background"
							assets={altBackgroundsIds}
							hairs={[]}
							openAddModal={onOpenAssetModal}
							setAssetType={() => {}} // here
							handleSetFieldValue={handleSetAltBackgroundsValue}
							isAltAsset
						/>
						{isOpenAssetModal && (
							<AddHeroAssetsModal
								backgrounds={[asset?.id]}
								isOpen={isOpenAssetModal}
								onClose={onCloseAssetModal}
								type={asset?.type}
								handleSetFieldValue={handleSetAltBackgroundsValue}
								altBackgrounds={altBackgroundsIds}
								isAltAsset
							/>
						)}
					</Box>
				);
			}
			default:
				return <p>Page Not Found</p>;
		}
	}, [
		activeTab,
		asset,
		assetHeroes,
		chats,
		chapters,
		assetChapters,
		assetBooks,
		books,
		refetch,
		altClothesIds,
		onOpenAssetModal,
		handleSetFieldValue,
		isOpenAssetModal,
		onCloseAssetModal,
		altBackgroundsIds,
		handleSetAltBackgroundsValue,
	]);

	return <>{renderComponent()}</>;
};
