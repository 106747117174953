import { ReactNode } from 'react';
import ReactDOM from 'react-dom';

interface PortalProps {
	parent?: any;
	children: ReactNode;
}

export const Portal: React.FC<PortalProps> = ({ parent, children }) => {
	return typeof document === 'object'
		? ReactDOM.createPortal(children, parent || document.body)
		: null;
};
