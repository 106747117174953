const getEnvVar = (key: string) => {
	if (process.env[key] === undefined) {
		throw new Error(`Env variable ${key} is required`);
	}

	return process.env[key] || '';
};

export const API_URL = getEnvVar('REACT_APP_SERVER_URL');
export const STAGE = getEnvVar('REACT_APP_STAGE');

export const isDevEnv = STAGE !== 'production';
export const isProdEnv = STAGE === 'production';
