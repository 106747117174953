import {
	ApolloClient,
	ApolloLink,
	DefaultOptions,
	fromPromise,
	gql,
	InMemoryCache,
	NormalizedCacheObject,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';

import { userModel } from 'entities/user';
import { API_URL } from 'shared/config';

// eslint-disable-next-line import/no-mutable-exports
let apiInstance: ApolloClient<NormalizedCacheObject>;

const GET_TOKEN_QUERY = gql`
	query refreshToken($refreshToken: String!) {
		refreshToken(refreshToken: $refreshToken) {
			accessToken
			refreshToken
		}
	}
`;

const defaultOptions: DefaultOptions = {
	watchQuery: {
		fetchPolicy: 'no-cache',
	},
	query: {
		fetchPolicy: 'no-cache',
	},
};

const getNewToken = (refreshToken: string) => {
	return apiInstance
		.query({ query: GET_TOKEN_QUERY, variables: { refreshToken } })
		.then((response) => {
			return response.data.refreshToken;
		});
};

const errorLink = onError(({ graphQLErrors, operation, forward }) => {
	if (graphQLErrors) {
		const refreshToken = localStorage.getItem('refreshToken');

		for (let i = 0; i < graphQLErrors.length; i += 1) {
			const err = graphQLErrors[i];
			if ((err.extensions.exception as any)?.status === 401 && refreshToken) {
				return fromPromise(
					getNewToken(refreshToken)
						.then((response) => {
							userModel.setAccessToken(response.accessToken);
							userModel.setRefreshToken(response.refreshToken);

							return response;
						})
						.catch(() => {
							userModel.setAccessToken(null);
							userModel.setRefreshToken(null);
						}),
				)
					.filter((value) => Boolean(value))
					.flatMap((data) => {
						const oldHeaders = operation.getContext().headers;
						// modify the operation context with a new token
						operation.setContext({
							headers: {
								...oldHeaders,
								authorization: `Bearer ${data.accessToken}`,
							},
						});

						// retry the request, returning the new observable
						return forward(operation);
					});
			}
		}

		return undefined;
	}

	return undefined;
});

const linkTokenHeader = setContext(async (_, { headers }) => {
	const accessToken = localStorage.getItem('accessToken');
	return {
		headers: {
			...headers,
			Authorization: accessToken ? `Bearer ${accessToken}` : '',
		},
	};
});

apiInstance = new ApolloClient({
	link: ApolloLink.from([
		errorLink,
		linkTokenHeader,
		// logoutLink.concat(httpLink),
		createUploadLink({
			uri: `${API_URL}/graphql`,
		}),
	]),
	cache: new InMemoryCache({
		addTypename: false,
	}),
	defaultOptions,
});

export { apiInstance };
