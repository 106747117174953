import React, { useCallback } from 'react';
import {
	Box,
	ButtonGroup,
	Flex,
	IconButton,
	Text,
	Tooltip,
	useDisclosure,
} from '@chakra-ui/react';

import { ButtonType } from 'shared/analytics';
import { AnalyticsIconButton } from 'shared/analytics/ui/icon-button';
import { PencilIcon, PlusIcon, TrashBinIcon } from 'shared/icons';
import { ArrayElement } from 'shared/model';
import { ExistingHeroesModal } from 'widgets/existing-heroes-modal';
import { ActivePage } from 'widgets/existing-heroes-modal/constant/activePage';
import { HeroesQuery } from 'widgets/existing-heroes-modal/graphql/queries.gen';
import { useHeroQuery } from 'widgets/hero-update-modal/graphql/queries.gen';

import { HeroCard } from '../hero-card';

interface ParentHeroInputProps {
	onChange: (hero: ArrayElement<HeroesQuery['heroes']> | null) => void;
	hero: ArrayElement<HeroesQuery['heroes']> | null;
	updatedHeroId?: string;
	bookId: string;
}

export const ParentHeroInput: React.FC<ParentHeroInputProps> = ({
	hero,
	onChange,
	updatedHeroId,
	bookId,
}) => {
	const {
		isOpen: isOpenModal,
		onOpen: onOpenModal,
		onClose: onCloseModal,
	} = useDisclosure();
	const { data: heroData } = useHeroQuery({
		variables: { id: hero?.id as string },
	});
	const handleParentRemove = useCallback(() => {
		onChange(null);
	}, [onChange]);

	return (
		<>
			<Flex flexDir="column">
				<Flex p="13px 0 12px 0" align="center" justify="space-between">
					<Text fontSize="14px">Blood related</Text>

					{!hero ? (
						<IconButton
							aria-label="add-hero-names"
							size="circle"
							variant="secondary"
							onClick={onOpenModal}
						>
							<PlusIcon />
						</IconButton>
					) : (
						<ButtonGroup spacing="4px" alignItems="center">
							<IconButton
								aria-label="edit-parent"
								size="circle"
								variant="secondary"
								onClick={onOpenModal}
							>
								<PencilIcon w={6} h={6} />
							</IconButton>
							<Tooltip label="Remove">
								<AnalyticsIconButton
									name={ButtonType.REMOVE_CHARACTER_PARENT}
									aria-label="remove-parent"
									size="circle"
									variant="secondary"
									onClick={handleParentRemove}
								>
									<TrashBinIcon w={6} h={6} />
								</AnalyticsIconButton>
							</Tooltip>
						</ButtonGroup>
					)}
				</Flex>
				<Box maxW={600}>
					{heroData?.hero && (
						<HeroCard heroId={hero?.id as string} hero={heroData?.hero} />
					)}
				</Box>
			</Flex>
			{isOpenModal && (
				<ExistingHeroesModal
					bookId={bookId}
					isOpen={isOpenModal}
					onClose={onCloseModal}
					startPage={ActivePage.KTO_OTEC}
					onChange={onChange}
					filterHeroes={updatedHeroId ? [updatedHeroId] : []}
				/>
			)}
		</>
	);
};
