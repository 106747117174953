import * as Types from '../../../models.gen';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HeroesQueryVariables = Types.Exact<{
  page?: Types.InputMaybe<Types.Scalars['Int']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
  filter?: Types.InputMaybe<Types.HeroesFilterArgDto>;
  sort?: Types.InputMaybe<Types.HeroesSortArgDto>;
}>;


export type HeroesQuery = { heroes: Array<{ id: string, names: Array<string>, customId?: number | null, updatedAt: any, clothes: Array<{ id: string, link?: string | null }>, parent?: { id: string } | null, hairs: Array<{ id: string, frontLink?: string | null, backLink?: string | null }>, bodies?: Array<{ id: string, link?: string | null, emotions: Array<string> }> | null, chapters: Array<{ id: string, book?: { name?: string | null } | null }> }> };

export type StoriesQueryVariables = Types.Exact<{
  page?: Types.InputMaybe<Types.Scalars['Int']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
  filter?: Types.InputMaybe<Types.BooksFilterArgDto>;
}>;


export type StoriesQuery = { books: Array<{ id: string, name?: string | null }> };

export type ChaptersQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type ChaptersQuery = { book: { id: string, name?: string | null, chapters?: Array<{ id: string, name?: string | null, chapterOrder?: number | null }> | null } };

export type UpdateHeroMutationVariables = Types.Exact<{
  hero: Types.UpdateHeroRequestDto;
}>;


export type UpdateHeroMutation = { updateHero: { id: string } };

export type ChapterHeroesIdsQueryVariables = Types.Exact<{
  page?: Types.InputMaybe<Types.Scalars['Int']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
  filter?: Types.InputMaybe<Types.HeroesFilterArgDto>;
}>;


export type ChapterHeroesIdsQuery = { heroes: Array<{ id: string }> };


export const HeroesDocument = gql`
    query Heroes($page: Int, $limit: Int, $filter: HeroesFilterArgDto, $sort: HeroesSortArgDto) {
  heroes(page: $page, limit: $limit, filter: $filter, sort: $sort) {
    id
    names
    customId
    updatedAt
    clothes {
      id
      link
    }
    parent {
      id
    }
    hairs {
      id
      frontLink
      backLink
    }
    bodies {
      id
      link
      emotions
    }
    chapters {
      id
      book {
        name
      }
    }
  }
}
    `;

/**
 * __useHeroesQuery__
 *
 * To run a query within a React component, call `useHeroesQuery` and pass it any options that fit your needs.
 * When your component renders, `useHeroesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHeroesQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useHeroesQuery(baseOptions?: Apollo.QueryHookOptions<HeroesQuery, HeroesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HeroesQuery, HeroesQueryVariables>(HeroesDocument, options);
      }
export function useHeroesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HeroesQuery, HeroesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HeroesQuery, HeroesQueryVariables>(HeroesDocument, options);
        }
export type HeroesQueryHookResult = ReturnType<typeof useHeroesQuery>;
export type HeroesLazyQueryHookResult = ReturnType<typeof useHeroesLazyQuery>;
export type HeroesQueryResult = Apollo.QueryResult<HeroesQuery, HeroesQueryVariables>;
export const StoriesDocument = gql`
    query Stories($page: Int, $limit: Int, $filter: BooksFilterArgDto) {
  books(page: $page, limit: $limit, filter: $filter) {
    id
    name
  }
}
    `;

/**
 * __useStoriesQuery__
 *
 * To run a query within a React component, call `useStoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoriesQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useStoriesQuery(baseOptions?: Apollo.QueryHookOptions<StoriesQuery, StoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StoriesQuery, StoriesQueryVariables>(StoriesDocument, options);
      }
export function useStoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StoriesQuery, StoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StoriesQuery, StoriesQueryVariables>(StoriesDocument, options);
        }
export type StoriesQueryHookResult = ReturnType<typeof useStoriesQuery>;
export type StoriesLazyQueryHookResult = ReturnType<typeof useStoriesLazyQuery>;
export type StoriesQueryResult = Apollo.QueryResult<StoriesQuery, StoriesQueryVariables>;
export const ChaptersDocument = gql`
    query Chapters($id: String!) {
  book(id: $id) {
    id
    name
    chapters {
      id
      name
      chapterOrder
    }
  }
}
    `;

/**
 * __useChaptersQuery__
 *
 * To run a query within a React component, call `useChaptersQuery` and pass it any options that fit your needs.
 * When your component renders, `useChaptersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChaptersQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useChaptersQuery(baseOptions: Apollo.QueryHookOptions<ChaptersQuery, ChaptersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChaptersQuery, ChaptersQueryVariables>(ChaptersDocument, options);
      }
export function useChaptersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChaptersQuery, ChaptersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChaptersQuery, ChaptersQueryVariables>(ChaptersDocument, options);
        }
export type ChaptersQueryHookResult = ReturnType<typeof useChaptersQuery>;
export type ChaptersLazyQueryHookResult = ReturnType<typeof useChaptersLazyQuery>;
export type ChaptersQueryResult = Apollo.QueryResult<ChaptersQuery, ChaptersQueryVariables>;
export const UpdateHeroDocument = gql`
    mutation UpdateHero($hero: UpdateHeroRequestDto!) {
  updateHero(hero: $hero) {
    id
  }
}
    `;
export type UpdateHeroMutationFn = Apollo.MutationFunction<UpdateHeroMutation, UpdateHeroMutationVariables>;

/**
 * __useUpdateHeroMutation__
 *
 * To run a mutation, you first call `useUpdateHeroMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateHeroMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateHeroMutation, { data, loading, error }] = useUpdateHeroMutation({
 *   variables: {
 *      hero: // value for 'hero'
 *   },
 * });
 */
export function useUpdateHeroMutation(baseOptions?: Apollo.MutationHookOptions<UpdateHeroMutation, UpdateHeroMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateHeroMutation, UpdateHeroMutationVariables>(UpdateHeroDocument, options);
      }
export type UpdateHeroMutationHookResult = ReturnType<typeof useUpdateHeroMutation>;
export type UpdateHeroMutationResult = Apollo.MutationResult<UpdateHeroMutation>;
export type UpdateHeroMutationOptions = Apollo.BaseMutationOptions<UpdateHeroMutation, UpdateHeroMutationVariables>;
export const ChapterHeroesIdsDocument = gql`
    query ChapterHeroesIds($page: Int, $limit: Int, $filter: HeroesFilterArgDto) {
  heroes(page: $page, limit: $limit, filter: $filter) {
    id
  }
}
    `;

/**
 * __useChapterHeroesIdsQuery__
 *
 * To run a query within a React component, call `useChapterHeroesIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useChapterHeroesIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChapterHeroesIdsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useChapterHeroesIdsQuery(baseOptions?: Apollo.QueryHookOptions<ChapterHeroesIdsQuery, ChapterHeroesIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChapterHeroesIdsQuery, ChapterHeroesIdsQueryVariables>(ChapterHeroesIdsDocument, options);
      }
export function useChapterHeroesIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChapterHeroesIdsQuery, ChapterHeroesIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChapterHeroesIdsQuery, ChapterHeroesIdsQueryVariables>(ChapterHeroesIdsDocument, options);
        }
export type ChapterHeroesIdsQueryHookResult = ReturnType<typeof useChapterHeroesIdsQuery>;
export type ChapterHeroesIdsLazyQueryHookResult = ReturnType<typeof useChapterHeroesIdsLazyQuery>;
export type ChapterHeroesIdsQueryResult = Apollo.QueryResult<ChapterHeroesIdsQuery, ChapterHeroesIdsQueryVariables>;