import React, { memo } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';

import { MultipleNamesInput, ParentHeroInput } from 'features/hero';
import { ArrayElement } from 'shared/model';
import { BooleanInput } from 'shared/ui';
import { formatDate } from 'shared/utils';
import { HeroesQuery } from 'widgets/existing-heroes-modal/graphql/queries.gen';

interface GeneralTabProps {
	names: string[];
	customId: number | string;
	heroId: string;
	updatedAt: any;
	keywords: string[];
	isFamiliar: boolean;
	handleSetFieldValue: (
		field: string,
		value: any,
		shouldValidate?: any,
	) => void;
	parent: ArrayElement<HeroesQuery['heroes']> | null;
	bookId: string;
}

export const GeneralTab: React.FC<GeneralTabProps> = memo(
	({
		names,
		isFamiliar,
		parent,
		updatedAt,
		heroId,
		handleSetFieldValue,
		customId,
		bookId,
	}) => {
		return (
			<Box>
				<Flex
					p="19px 0 16px 0"
					align="center"
					justify="space-between"
					fontSize="14px"
					borderBottom="1px solid #eeeef2"
				>
					<Text>ID</Text>
					<Text>{customId}</Text>
				</Flex>
				<MultipleNamesInput
					label="Name"
					values={names}
					onChange={(value: string[]) => handleSetFieldValue('names', value)}
				/>
				<BooleanInput
					isChecked={isFamiliar}
					label="Is familiar"
					htmlFor="isFamiliar"
					tooltip="available only for animal"
					onChange={() => handleSetFieldValue('isFamiliar', !isFamiliar)}
				/>
				<Flex
					p="19px 0 16px 0"
					align="center"
					justify="space-between"
					fontSize="14px"
					borderTop="1px solid #eeeef2"
					borderBottom="1px solid #eeeef2"
				>
					<Text>Date modified</Text>
					<Text> {formatDate(updatedAt)}</Text>
				</Flex>
				<ParentHeroInput
					bookId={bookId}
					updatedHeroId={heroId}
					hero={parent}
					onChange={(hero: ArrayElement<HeroesQuery['heroes']> | null) =>
						handleSetFieldValue('parent', hero)
					}
				/>
			</Box>
		);
	},
);
