import { useCallback } from 'react';

import { api } from 'shared/api';
import { useAppToast } from 'shared/hooks/toast';
import { DRAW_TYPES, DRAW1_5xCONFIG } from 'shared/utils/drawCanvas/configs';
import { drawCanvas } from 'shared/utils/drawCanvas/drawCanvas';
import { runInSequence } from 'shared/utils/run-in-sequence';
import { useGetCardLinks } from 'widgets/create-card-modal/graphql/hooks';
import { SelectedAssetsInfo } from 'widgets/create-card-modal/interfaces';
import { getAllCombinations } from 'widgets/create-card-modal/utils/getAllCombinations';
import { getRace } from 'widgets/create-card-modal/utils/getRace';

export interface UseGenerateCards {
	selectedAssetsInfo: SelectedAssetsInfo;
	bookId: string;
	chapterId: number;
	heroId: number;
	backgroundLink: string;
	onClose: () => void;
	isGolden: boolean;
	isSecondary: boolean;
}

export const useGenerateCards = () => {
	const getCardLinks = useGetCardLinks();
	const toast = useAppToast();

	return useCallback(
		async ({
			selectedAssetsInfo,
			bookId,
			chapterId,
			heroId,
			backgroundLink,
			onClose,
			isGolden,
			isSecondary,
		}: UseGenerateCards): Promise<string[]> => {
			const combinations = getAllCombinations(selectedAssetsInfo);
			const linksArr: string[] = [];

			onClose();
			toast({
				title: `Cards are generating`,
				status: 'success',
			});

			await runInSequence(
				combinations.map((card) => async () => {
					const cardLinks = await getCardLinks(card);
					const splitHairName = cardLinks.hairFilename?.split('_');
					const hairId = splitHairName?.[splitHairName.length - 1].replace(
						'.png',
						'',
					);

					const canvas = await drawCanvas(
						[
							backgroundLink || '',
							cardLinks.hairBack || '',
							cardLinks.body || '',
							cardLinks.clothes || '',
							cardLinks.emotion || '',
							cardLinks.hairFront || '',
							cardLinks.layer || '',
						],
						{
							configs: DRAW1_5xCONFIG,
							type: DRAW_TYPES.DRAW_1_5X,
						},
					);
					const race = getRace(cardLinks.body || '');
					const cardType = isGolden ? 'golden' : 'regular';
					const filenameEnding = isSecondary ? '' : `_${race}_${hairId}`;

					const filename = `${cardType}_${bookId}_${chapterId}_${heroId}${filenameEnding}.jpg`;

					const blob = await new Promise((resolve) => {
						canvas.toBlob((b) => {
							resolve(b);
						});
					});

					const res = await api.files.saveSingleFile(
						blob as Blob,
						'image',
						filename,
						false,
					);
					const resultLink = res.data?.uploadFileToFolder as string;
					linksArr.push(resultLink);
				}),
			);
			return linksArr;
		},
		[getCardLinks, toast],
	);
};
