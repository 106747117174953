/* eslint-disable no-return-assign */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Box,
	ExpandedIndex,
	Portal,
	Text,
} from '@chakra-ui/react';
import { useStore } from 'effector-react';

import { scriptModel } from 'entities/script';

import { useRemsQuery } from '../../queries.gen';

interface Props {
	topPosition: string;
	bottomPosition: string;
	additionalSnippetRef: React.RefObject<HTMLDivElement>;
	snippetRef: React.RefObject<HTMLDivElement>;
	onClick: (variants?: string[]) => void;
}

export const AdditionalSuggestions: React.FC<Props> = ({
	onClick,
	snippetRef,
	additionalSnippetRef,
	topPosition,
	bottomPosition,
}) => {
	const [accordionIndex, setAccordionIndex] = useState<ExpandedIndex>(-1);
	const accordionRefs = useRef<HTMLDivElement | null>(null);
	const { bookId, chapterOrder, docType, lang } = useStore(
		scriptModel.$currentScriptInfo,
	);
	const { data } = useRemsQuery({
		variables: { bookId, chapterOrder, docType, lang },
	});

	const remsSortByChapterOrder = useMemo(
		() => data?.rems.sort((a, b) => a.chapterOrder - b.chapterOrder),
		[data?.rems],
	);

	useEffect(() => {
		setTimeout(() => {
			if (accordionRefs.current) {
				accordionRefs.current?.scrollIntoView({
					behavior: 'smooth',
					block: 'start',
				});
			}
		}, 150);
	}, [accordionRefs, accordionIndex]);

	if (!data?.rems.length) return null;

	return (
		<Portal>
			<Box
				ref={additionalSnippetRef}
				position="absolute"
				top={topPosition}
				bottom={topPosition === 'none' ? bottomPosition : 'none'}
				left={
					(snippetRef.current?.parentElement?.getBoundingClientRect().left ||
						0) + 180
				}
				zIndex={999999999}
				background="white"
				borderRadius="8px"
				boxShadow="0 1px 5px rgba(0, 0, 0, 0.2)"
				minW="190px"
				maxHeight="215px"
				h="auto"
				overflowY="auto"
			>
				<Accordion
					index={accordionIndex as ExpandedIndex}
					variant="snippets"
					allowToggle
					id="id"
					onChange={(index) => setAccordionIndex(index)}
				>
					{remsSortByChapterOrder?.map((rem, idx) =>
						rem.rems.length ? (
							<AccordionItem
								w="auto"
								cursor="pointer"
								maxHeight="none"
								ref={accordionIndex === idx ? accordionRefs : null}
								_expanded={{ color: 'red' }}
							>
								<AccordionButton key={rem.id}>
									Chapter {rem.chapterOrder}
									<AccordionIcon
										boxSize={5}
										sx={{ '& path': { strokeWidth: 1 } }}
									/>
								</AccordionButton>
								<AccordionPanel>
									{rem.rems.map((r) => (
										<Text
											width="auto"
											onClick={() =>
												onClick(r.map((remValue) => remValue.variable))
											}
											p="5px 8px"
											fontWeight={400}
											_hover={{ background: '#F4F4F8', borderRadius: '6px' }}
										>
											{r.map(({ variable }) => variable).join(', ')}
										</Text>
									))}
								</AccordionPanel>
							</AccordionItem>
						) : null,
					)}
				</Accordion>
			</Box>
		</Portal>
	);
};
