import React from 'react';
import { createIcon } from '@chakra-ui/react';

export const ArrowRedoIcon = createIcon({
	viewBox: '0 0 24 24',
	path: (
		<g fill="none">
			<path
				d="M16.2227 14.8892L20.2227 10.8892L16.2227 6.88916"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M4.22266 17.5553C4.22266 14.2416 6.90895 10.8887 10.2227 10.8887H19.556"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</g>
	),
});
