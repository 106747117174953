import React from 'react';
import { Flex, Text } from '@chakra-ui/react';

const EmptyStories: React.FC = () => {
	return (
		<Flex flex="1" alignItems="center" justify="center">
			<Flex gap="11px" flexDir="column" alignItems="center">
				<Text
					as="p"
					fontWeight="600"
					fontSize="20px"
					lineHeight="30px"
					color="var(--color-dark-tone-100)"
				>
					No results found
				</Text>
				<Text as="p" color="var(--color-dark-tone-80)">
					Try adjusting search to find what you&apos;re looking for
				</Text>
			</Flex>
		</Flex>
	);
};

export default EmptyStories;
