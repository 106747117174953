interface LocalizedInfo {
	title: string;
	chapterName:
		| 'nameES'
		| 'nameFR'
		| 'nameDE'
		| 'namePT'
		| 'nameNL'
		| 'nameIT'
		| 'namePL'
		| 'nameSV'
		| 'nameDA'
		| 'nameNO'
		| 'nameFI'
		| 'nameID'
		| 'nameCS'
		| 'nameRO'
		| 'nameHU'
		| 'nameTR'
		| 'nameJA'
		| 'nameKO'
		| 'namePT_BR';
	chapterDescription:
		| 'descriptionES'
		| 'descriptionFR'
		| 'descriptionDE'
		| 'descriptionPT'
		| 'descriptionNL'
		| 'descriptionIT'
		| 'descriptionPL'
		| 'descriptionSV'
		| 'descriptionDA'
		| 'descriptionNO'
		| 'descriptionFI'
		| 'descriptionID'
		| 'descriptionCS'
		| 'descriptionRO'
		| 'descriptionHU'
		| 'descriptionTR'
		| 'descriptionJA'
		| 'descriptionKO'
		| 'descriptionPT_BR';
	docName:
		| 'googleDoc'
		| 'googleDocFR'
		| 'googleDocDE'
		| 'googleDocES'
		| 'googleDocNL'
		| 'googleDocPT'
		| 'googleDocIT'
		| 'googleDocPL'
		| 'googleDocSV'
		| 'googleDocDA'
		| 'googleDocNO'
		| 'googleDocFI'
		| 'googleDocID'
		| 'googleDocCS'
		| 'googleDocRO'
		| 'googleDocHU'
		| 'googleDocTR'
		| 'googleDocJA'
		| 'googleDocKO'
		| 'googleDocPT_BR';
	doc2PovName:
		| 'googleDoc2PovFR'
		| 'googleDoc2PovDE'
		| 'googleDoc2PovES'
		| 'googleDoc2PovNL'
		| 'googleDoc2PovPT'
		| 'googleDoc2PovIT'
		| 'googleDoc2PovPL'
		| 'googleDoc2PovSV'
		| 'googleDoc2PovDA'
		| 'googleDoc2PovNO'
		| 'googleDoc2PovFI'
		| 'googleDoc2PovID'
		| 'googleDoc2PovCS'
		| 'googleDoc2PovRO'
		| 'googleDoc2PovHU'
		| 'googleDoc2PovTR'
		| 'googleDoc2PovJA'
		| 'googleDoc2PovKO'
		| 'googleDoc2PovPT_BR';
	lang:
		| 'en'
		| 'ru'
		| 'es'
		| 'pt'
		| 'fr'
		| 'de'
		| 'nl'
		| 'it'
		| 'pl'
		| 'sv'
		| 'da'
		| 'no'
		| 'fi'
		| 'id'
		| 'cs'
		| 'ro'
		| 'hu'
		| 'tr'
		| 'ja'
		| 'ko'
		| 'pt-BR';
}

export const LOCALIZED_LANG_MAP: Record<string, LocalizedInfo> = {
	'localized-ES': {
		title: 'Spanish',
		chapterName: 'nameES',
		chapterDescription: 'descriptionES',
		docName: 'googleDocES',
		doc2PovName: 'googleDoc2PovES',
		lang: 'es',
	},
	'localized-FR': {
		title: 'French',
		chapterName: 'nameFR',
		chapterDescription: 'descriptionFR',
		docName: 'googleDocFR',
		doc2PovName: 'googleDoc2PovFR',
		lang: 'fr',
	},
	'localized-DE': {
		title: 'German',
		chapterName: 'nameDE',
		chapterDescription: 'descriptionDE',
		docName: 'googleDocDE',
		doc2PovName: 'googleDoc2PovDE',
		lang: 'de',
	},
	'localized-NL': {
		title: 'Dutch',
		chapterName: 'nameNL',
		chapterDescription: 'descriptionNL',
		docName: 'googleDocNL',
		doc2PovName: 'googleDoc2PovNL',
		lang: 'nl',
	},
	'localized-PT': {
		title: 'Portuguese',
		chapterName: 'namePT',
		chapterDescription: 'descriptionPT',
		docName: 'googleDocPT',
		doc2PovName: 'googleDoc2PovPT',
		lang: 'pt',
	},
	'localized-IT': {
		title: 'Italian',
		chapterName: 'nameIT',
		chapterDescription: 'descriptionIT',
		docName: 'googleDocIT',
		doc2PovName: 'googleDoc2PovIT',
		lang: 'it',
	},
	'localized-PL': {
		title: 'Polish',
		chapterName: 'namePL',
		chapterDescription: 'descriptionPL',
		docName: 'googleDocPL',
		doc2PovName: 'googleDoc2PovPL',
		lang: 'pl',
	},
	'localized-SV': {
		title: 'Swedish',
		chapterName: 'nameSV',
		chapterDescription: 'descriptionSV',
		docName: 'googleDocSV',
		doc2PovName: 'googleDoc2PovSV',
		lang: 'sv',
	},
	'localized-DA': {
		title: 'Danish',
		chapterName: 'nameDA',
		chapterDescription: 'descriptionDA',
		docName: 'googleDocDA',
		doc2PovName: 'googleDoc2PovDA',
		lang: 'da',
	},
	'localized-NO': {
		title: 'Norwegian',
		chapterName: 'nameNO',
		chapterDescription: 'descriptionNO',
		docName: 'googleDocNO',
		doc2PovName: 'googleDoc2PovNO',
		lang: 'no',
	},
	'localized-FI': {
		title: 'Finnish',
		chapterName: 'nameFI',
		chapterDescription: 'descriptionFI',
		docName: 'googleDocFI',
		doc2PovName: 'googleDoc2PovFI',
		lang: 'fi',
	},
	'localized-ID': {
		title: 'Indonesian',
		chapterName: 'nameID',
		chapterDescription: 'descriptionID',
		docName: 'googleDocID',
		doc2PovName: 'googleDoc2PovID',
		lang: 'id',
	},
	'localized-CS': {
		title: 'Czech',
		chapterName: 'nameCS',
		chapterDescription: 'descriptionCS',
		docName: 'googleDocCS',
		doc2PovName: 'googleDoc2PovCS',
		lang: 'cs',
	},
	'localized-RO': {
		title: 'Romanian',
		chapterName: 'nameRO',
		chapterDescription: 'descriptionRO',
		docName: 'googleDocRO',
		doc2PovName: 'googleDoc2PovRO',
		lang: 'ro',
	},
	'localized-HU': {
		title: 'Hungarian',
		chapterName: 'nameHU',
		chapterDescription: 'descriptionHU',
		docName: 'googleDocHU',
		doc2PovName: 'googleDoc2PovHU',
		lang: 'hu',
	},
	'localized-TR': {
		title: 'Turkish',
		chapterName: 'nameTR',
		chapterDescription: 'descriptionTR',
		docName: 'googleDocTR',
		doc2PovName: 'googleDoc2PovTR',
		lang: 'tr',
	},
	'localized-JA': {
		title: 'Japanese',
		chapterName: 'nameJA',
		chapterDescription: 'descriptionJA',
		docName: 'googleDocJA',
		doc2PovName: 'googleDoc2PovJA',
		lang: 'ja',
	},
	'localized-KO': {
		title: 'Korean',
		chapterName: 'nameKO',
		chapterDescription: 'descriptionKO',
		docName: 'googleDocKO',
		doc2PovName: 'googleDoc2PovKO',
		lang: 'ko',
	},
	'localized-PT_BR': {
		// eslint-disable-next-line sonarjs/no-duplicate-string
		title: 'Brazilian Portuguese',
		chapterName: 'namePT_BR',
		chapterDescription: 'descriptionPT_BR',
		docName: 'googleDocPT_BR',
		doc2PovName: 'googleDoc2PovPT_BR',
		lang: 'pt-BR',
	},
};

export const LOCALIZED_LANG: Record<string, string> = {
	'localized-ES': 'Spanish',
	'localized-FR': 'French',
	'localized-DE': 'German',
	'localized-NL': 'Dutch',
	'localized-PT': 'Portuguese',
	'localized-IT': 'Italian',
	'localized-PL': 'Polish',
	'localized-SV': 'Swedish',
	'localized-DA': 'Danish',
	'localized-NO': 'Norwegian',
	'localized-FI': 'Finnish',
	'localized-ID': 'Indonesian',
	'localized-CS': 'Czech',
	'localized-RO': 'Romanian',
	'localized-HU': 'Hungarian',
	'localized-TR': 'Turkish',
	'localized-JA': 'Japanese',
	'localized-KO': 'Korean',
	'localized-PT_BR': 'Brazilian Portuguese',
};

export const LANGS_MAP: Record<string, string> = {
	es: 'Spanish',
	fr: 'French',
	de: 'German',
	nl: 'Dutch',
	pt: 'Portuguese',
	it: 'Italian',
	pl: 'Polish',
	sv: 'Swedish',
	da: 'Danish',
	no: 'Norwegian',
	fi: 'Finnish',
	id: 'Indonesian',
	cs: 'Czech',
	ro: 'Romanian',
	hu: 'Hungarian',
	tr: 'Turkish',
	ja: 'Japanese',
	ko: 'Korean',
	'pt-BR': 'Brazilian Portuguese',
};
