import React, { useMemo } from 'react';
import { useStore } from 'effector-react';
import _ from 'lodash';

import { userModel } from 'entities/user';
import { Permission } from 'models.gen';

interface PermissionGuardProps {
	permissions: Permission[];
	children: React.ReactElement;
}

export const PermissionGuard: React.FC<PermissionGuardProps> = ({
	permissions,
	children,
}) => {
	const userPermissions = useStore(userModel.$permissions);

	const hasAccess = useMemo(
		() =>
			_.intersection(userPermissions, permissions).length ===
			permissions.length,
		[userPermissions, permissions],
	);

	if (!hasAccess) return null;

	return children;
};
