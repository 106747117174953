import React from 'react';
import { createIcon } from '@chakra-ui/react';

export const ArrowFillRightIcon = createIcon({
	viewBox: '0 0 4 8',
	path: (
		<path
			xmlns="http://www.w3.org/2000/svg"
			d="M0.9 0.5C0.676 0.5 0.5 0.6925 0.5 0.9375L0.5 7.0625C0.5 7.3075 0.676 7.5 0.9 7.5C1.004 7.5 1.092 7.45625 1.164 7.38625L4.364 4.32375C4.444 4.245 4.5 4.13125 4.5 4C4.5 3.86875 4.444 3.755 4.364 3.67625L1.164 0.61375C1.092 0.54375 1.004 0.5 0.9 0.5Z"
			fill="#484964"
		/>
	),
});
