import React from 'react';
import { Box, Flex, Grid, Spinner, Text } from '@chakra-ui/react';

import { HeroPreviewCell } from 'pages/heroes/ui/HeroesBody/hero-preview-cell';
import { ArrayElement } from 'shared/model';
import { HeroesQuery } from 'widgets/existing-heroes-modal/graphql/queries.gen';

interface HeroCardProps {
	hero: any;
	heroId: string;
	selectedHero?: ArrayElement<HeroesQuery['heroes']> | null;
	setSelectedHero?: (hero: ArrayElement<HeroesQuery['heroes']> | null) => void;
}

export const HeroCard: React.FC<HeroCardProps> = ({
	selectedHero,
	heroId,
	hero,
	setSelectedHero,
}) => {
	return (
		<Box
			h="min-content"
			userSelect="none"
			border="4px solid transparent"
			borderRadius="10px"
			cursor="pointer"
			borderColor={`${
				selectedHero?.id === heroId ? 'rgba(100, 77, 237, 0.08)' : ''
			}`}
			onClick={() => heroId && setSelectedHero && setSelectedHero(hero || null)}
		>
			<Grid
				height="140px"
				templateColumns={!hero ? 'auto' : '165px auto'}
				border="1px solid #eeeef2"
				borderRadius="10px"
				overflow="hidden"
				borderColor={`${heroId === selectedHero?.id ? '#644DED' : ''}`}
				_hover={{
					borderColor: '#644DED',
				}}
			>
				{!hero ? (
					<Spinner size="md" m="auto" />
				) : (
					<>
						<Box position="relative" transform="scale(2.3)" top="50px">
							<HeroPreviewCell hero={hero} />
						</Box>
						<Flex py={1} pr={3} flexDirection="column" gap={1}>
							<Flex justifyContent="space-between" alignItems="center">
								<Text
									fontSize="12px"
									fontWeight={600}
									lineHeight="16px"
									color="#ABACBE"
								>
									{hero?.customId}
								</Text>
							</Flex>

							<Text
								fontSize="16px"
								fontWeight={600}
								lineHeight="22px"
								whiteSpace="nowrap"
								overflow="hidden"
								textOverflow="ellipsis"
								width="380px"
							>
								{hero?.names?.join(', ') || ''}
							</Text>

							<Text fontSize="13px" fontWeight={400} lineHeight="16px">
								{hero?.chapters?.[0]?.book?.name}
							</Text>
						</Flex>
					</>
				)}
			</Grid>
		</Box>
	);
};
