import React, { useState } from 'react';
import { Box, Button, Grid, Text } from '@chakra-ui/react';

import {
	FilterKeywords,
	KEYWORDS_KEY_MAP,
	KEYWORDS_MAP,
	KeywordsType,
} from '../constant';
import { KeywordItem } from '../keyword';

interface KeywordsListProps {
	keywordsKey: string;
	type: string;
	filterKeywords: FilterKeywords | null;
	setFilterKeywords: (v: FilterKeywords | null) => void;
}

export const KeywordsList: React.FC<KeywordsListProps> = ({
	keywordsKey,
	type,
	filterKeywords,
	setFilterKeywords,
}) => {
	const [isShowMore, setIsShowMore] = useState(false);

	const keywordsOfType = KEYWORDS_MAP[type as keyof KeywordsType];

	const keywordsList: string[] =
		keywordsOfType[keywordsKey as keyof FilterKeywords];

	const showElementCount = isShowMore ? keywordsList?.length : 15;

	return (
		<Box p="20px 0 16px" _notLast={{ borderBottom: '1px solid #EEEEF2' }}>
			<Text mb="16px">
				{KEYWORDS_KEY_MAP[keywordsKey as keyof FilterKeywords]}
			</Text>
			<Grid
				templateColumns="repeat(3, 1fr)"
				columnGap="30px"
				w="fit-content"
				h="50%"
				transition="height 2s ease-out"
			>
				{keywordsList?.slice(0, showElementCount).map((keyword: any) => (
					<KeywordItem
						keywordsKey={keywordsKey}
						keyword={keyword}
						filterKeywords={filterKeywords}
						setFilterKeywords={setFilterKeywords}
					/>
				))}
			</Grid>
			{keywordsList && keywordsList?.length > 15 && (
				<Button
					type="button"
					variant="accent"
					mt="10px"
					onClick={() => setIsShowMore(!isShowMore)}
				>
					{isShowMore ? 'Hide' : 'Show More'}
				</Button>
			)}
		</Box>
	);
};
