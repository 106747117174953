import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';

import { HeroImage } from 'features/hero/hero-image';

interface Props {
	hero: any;
}

export const Heroes: React.FC<Props> = ({ hero }) => {
	return (
		<Flex
			m="30px 24px"
			h="140px"
			overflow="hidden"
			border="1px solid #eeeef2"
			borderRadius="10px"
		>
			<Flex
				position="relative"
				ml="10px"
				w="140px"
				maxH={138}
				justifyContent="center"
			>
				<Box
					w="full"
					h="full"
					transform="scale(1.9)"
					position="relative"
					top="40px"
				>
					<HeroImage
						bodyData={{
							link: hero?.bodies?.[0]?.link || '' || '',
							position: 1,
						}}
						hairFrontData={{
							link: hero?.hairs?.[0]?.frontLink || '',
							position: 4,
						}}
						hairBackData={{
							link: hero?.hairs?.[0]?.backLink || '',
							position: 0,
						}}
						clothesData={{
							link: hero?.clothes?.[0]?.link || '',
							position: 2,
						}}
					/>
				</Box>
			</Flex>
			<Flex w="full" p="14px 25px" flexDir="column">
				<Text
					pb="17px"
					fontWeight={600}
					fontSize="12px"
					lineHeight="16px"
					color="#abacbe"
				>
					{hero.customId}
				</Text>
				<Text
					pb="10px"
					fontWeight={600}
					fontSize="16px"
					lineHeight="22px"
					whiteSpace="nowrap"
					overflow="hidden"
					textOverflow="ellipsis"
					width="380px"
				>
					{hero.names.join(', ')}
				</Text>
				<Text pb="10px" fontWeight={400} fontSize="13px" lineHeight="16px">
					{[...(hero.chapters || [])].reverse()[0]?.book?.name || ''}
				</Text>
			</Flex>
		</Flex>
	);
};
