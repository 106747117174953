import React from 'react';
import { Flex } from '@chakra-ui/react';

import { filterKeywordsTypes } from 'pages/art-catalog';
import { FilterKeywords } from 'widgets/keywords-filter-modal/constant';

import ArtCatalogCategories from '../ArtCatalogCategories';
import { ArtCatalogTable } from '../ArtCatalogTable';

interface Props {
	selectedCategory?: string;
	setSelectedCategory: (v?: string) => void;
	search: string;
	page: number;
	setPage: (v: number) => void;
	filterKeywords: FilterKeywords | null;
	setFilterKeywords: (v: FilterKeywords | null) => void;
	selectedBook?: string;
	setSelectedBook: (v: string) => void;
	type?: string;
	filterKeywordsType: filterKeywordsTypes;
	setFilterKeywordsType: (v: filterKeywordsTypes) => void;
}

const ArtCatalogBody: React.FC<Props> = ({
	selectedCategory,
	setSelectedCategory,
	search,
	page,
	setPage,
	filterKeywords,
	setFilterKeywords,
	selectedBook,
	setSelectedBook,
	type,
	filterKeywordsType,
	setFilterKeywordsType,
}) => {
	return (
		<Flex margin="0 45px">
			<ArtCatalogCategories
				selectedCategory={selectedCategory}
				setSelectedCategory={setSelectedCategory}
			/>
			<ArtCatalogTable
				selectedCategory={selectedCategory}
				search={search}
				page={page}
				setPage={setPage}
				filterKeywords={filterKeywords}
				setFilterKeywords={setFilterKeywords}
				selectedBook={selectedBook}
				setSelectedBook={setSelectedBook}
				type={type}
				filterKeywordsType={filterKeywordsType}
				setFilterKeywordsType={setFilterKeywordsType}
			/>
		</Flex>
	);
};

export default ArtCatalogBody;
