import { createEffect, createEvent, createStore } from 'effector';

import { api } from 'shared/api';
import { UpdateDraftDocContentParams } from 'shared/api/graphql/docs';
import { syncDraftDocContentIntoDBVariables } from 'shared/api/graphql/model/syncDraftDocContentIntoDB';

type PatchFileParams = {
	documentId: string;
	text: string;
	styles: any[];
};
type LastUpdateDocDate = {
	isUpdated: boolean;
	modifiedBy: {
		name: string;
		photo: string;
	};
	modifiedTime: string;
};

export const patchFileFx = createEffect(async (data: PatchFileParams) => {
	return api.docs.patchFile(data);
});

export const updateDraftDocContentFx = createEffect(
	async (data: UpdateDraftDocContentParams) => {
		return api.docs.updateDraftDocContent(data);
	},
);

export const syncDraftDocContentIntoDBFx = createEffect(
	async (data: syncDraftDocContentIntoDBVariables) => {
		return api.docs.syncDraftDocContentIntoDB(data);
	},
);

export const loadLastUpdateInfo = createEffect(async (documentId: string) =>
	api.docs.getIsDraftDocContentUpdated({ documentId }),
);

export const setLastUpdateAdditionalDocDate = createEvent<string | null>(
	'set last update additional doc',
);

export const $lastUpdateDocDate = createStore<LastUpdateDocDate | null>(
	null,
).on(
	loadLastUpdateInfo.doneData,
	(_, response) => response.data.getIsDraftDocContentUpdated,
);
export const $lastUpdateAdditionalDocDate = createStore<string | null>(null).on(
	setLastUpdateAdditionalDocDate,
	(_, date) => date,
);
