import React, { Dispatch, SetStateAction, useCallback } from 'react';
import { Box, Button, Flex, Spinner } from '@chakra-ui/react';
import { EditorState, RichUtils } from 'draft-js';
import { Form, Formik } from 'formik';
import { v4 as uuidv4 } from 'uuid';

import {
	DraftCommentEntity,
	DraftReplyData,
	generateDecorator,
	scriptModel,
} from 'entities/script';
import { useUserByEmailQuery } from 'entities/user';
import { ButtonType } from 'shared/analytics';
import { FormTextArea } from 'shared/ui';
import {
	executeConsistentAction,
	extractCommentedTextOffsetsFromBlock,
	getBlocksKeysWithEntityByEntityKey,
	movePointerToEndOfSelection,
} from 'shared/utils';
import { getRows } from 'shared/utils/get-rows';

import { EditCommentForm } from './edit-comment-form';
import { EditReplyForm } from './edit-reply-form';
import { ViewCommentModal } from './view-comment-modal';

interface CreateCommentProps {
	editorState: EditorState;
	setEditorState: Dispatch<SetStateAction<EditorState>>;
	commentEntity: DraftCommentEntity;
	modalPosition: DOMRect | undefined;
}
// TODO REFACTOR
// * delete duplicated code
// * move reply, edit forms into DOC entities and reuse them in features
// * move delete handler into DOC entities/lib
// TODO REFACTOR

export const CurrentComment: React.FC<CreateCommentProps> = ({
	editorState,
	setEditorState,
	commentEntity,
	modalPosition,
}) => {
	const { data: userData, loading: isUserLoading } = useUserByEmailQuery();

	const handleCommentDataMerge = useCallback(
		(mergedData: any) => {
			const contentState = editorState.getCurrentContent();
			const newContentState = contentState.mergeEntityData(
				commentEntity?.entityKey || '',
				mergedData,
			);
			const newEditorState = EditorState.set(editorState, {
				currentContent: newContentState,
				decorator: generateDecorator(''),
			});

			const blocksKeys = getBlocksKeysWithEntityByEntityKey(
				contentState,
				commentEntity?.entityKey,
			);

			blocksKeys.forEach((key) => {
				const { endOffset } = extractCommentedTextOffsetsFromBlock(
					newContentState,
					key,
					commentEntity?.entityKey,
				);

				const newSelection = EditorState.forceSelection(
					newEditorState,
					newEditorState.getSelection().merge({
						anchorKey: key,
						focusKey: key,
						anchorOffset: endOffset,
						focusOffset: endOffset,
					}),
				).getSelection();

				executeConsistentAction(() =>
					setEditorState(
						EditorState.forceSelection(newEditorState, newSelection),
					),
				);
			});
			scriptModel.setIsScriptEdited(true);
		},
		[commentEntity?.entityKey, editorState, setEditorState],
	);

	const handleCommentResolve = useCallback(() => {
		handleCommentDataMerge({
			resolved: !commentEntity?.entityData?.resolved,
		});
		scriptModel.setCurrentCommentData(null);
	}, [commentEntity?.entityData?.resolved, handleCommentDataMerge]);

	const handleCommentDelete = useCallback(() => {
		const contentState = editorState.getCurrentContent();

		const blocksKeys = getBlocksKeysWithEntityByEntityKey(
			contentState,
			commentEntity?.entityKey,
		);

		let newEditorState = editorState;
		let newSelection = editorState.getSelection();

		blocksKeys.forEach((key) => {
			const { startOffset, endOffset } = extractCommentedTextOffsetsFromBlock(
				contentState,
				key,
				commentEntity?.entityKey,
			);

			const selectionForDelete = EditorState.forceSelection(
				newEditorState,
				newEditorState.getSelection().merge({
					anchorKey: key,
					focusKey: key,
					anchorOffset: startOffset,
					focusOffset: endOffset,
					// * this needed for proper deletion
					isBackward: false,
				}),
			).getSelection();

			newEditorState = RichUtils.toggleLink(
				newEditorState,
				selectionForDelete,
				null,
			);
			newSelection = movePointerToEndOfSelection(newEditorState.getSelection());
		});

		setEditorState(EditorState.forceSelection(newEditorState, newSelection));
		scriptModel.setIsScriptEdited(true);
		scriptModel.setCurrentCommentData(null);
	}, [commentEntity?.entityKey, editorState, setEditorState]);

	const handleCommentEdit = useCallback(
		(comment: any) => {
			if (!comment) return;

			handleCommentDataMerge({ comment });

			scriptModel.setCurrentCommentData({
				position: modalPosition,
				entity: {
					...commentEntity,
					entityData: { ...commentEntity.entityData, comment },
				} as DraftCommentEntity,
			});
		},
		[commentEntity, handleCommentDataMerge, modalPosition],
	);

	const handleReplyCreate = useCallback(
		(formValue: any) => {
			if (!formValue) return;

			const replies = [
				...(commentEntity?.entityData?.replies || []),
				{
					id: uuidv4(),
					author:
						userData?.user.fullName || userData?.user.email || 'Unknown author',
					reply: formValue,
					picture: userData?.user.picture,
					resolved: false,
					createdAt: Date.now(),
				},
			];

			handleCommentDataMerge({
				replies,
			});

			scriptModel.setCurrentCommentData({
				position: modalPosition,
				entity: {
					...commentEntity,
					entityData: { ...commentEntity.entityData, replies },
				} as DraftCommentEntity,
			});
		},
		[
			commentEntity,
			handleCommentDataMerge,
			modalPosition,
			userData?.user.email,
			userData?.user.fullName,
			userData?.user.picture,
		],
	);

	const handleReplyDelete = useCallback(
		(id: string) => {
			const replies = [
				...(commentEntity?.entityData?.replies || []).filter(
					(reply) => reply.id !== id,
				),
			];

			handleCommentDataMerge({
				replies,
			});

			scriptModel.setCurrentCommentData({
				position: modalPosition,
				entity: {
					...commentEntity,
					entityData: { ...commentEntity.entityData, replies },
				} as DraftCommentEntity,
			});
		},
		[commentEntity, handleCommentDataMerge, modalPosition],
	);

	const handleReplyEdit = useCallback(
		(formValue: string | undefined, replyData: DraftReplyData) => {
			if (!formValue) return;

			const replies = [
				...(commentEntity?.entityData?.replies || []).map((reply) => {
					if (reply.id === replyData.id) {
						return { ...replyData, reply: formValue };
					}
					return reply;
				}),
			];
			handleCommentDataMerge({
				replies,
			});

			scriptModel.setCurrentCommentData({
				position: modalPosition,
				entity: {
					...commentEntity,
					entityData: { ...commentEntity.entityData, replies },
				} as DraftCommentEntity,
			});
		},
		[commentEntity, handleCommentDataMerge, modalPosition],
	);

	return (
		<ViewCommentModal modalPosition={modalPosition}>
			<Box position="relative">
				<EditCommentForm
					commentEntity={commentEntity}
					handleCommentEdit={handleCommentEdit}
					handleCommentDelete={handleCommentDelete}
					handleCommentResolve={handleCommentResolve}
				/>
				{commentEntity?.entityData?.replies?.map((reply) => (
					<EditReplyForm
						key={reply.id}
						replyData={reply}
						handleReplyEdit={handleReplyEdit}
						handleReplyDelete={handleReplyDelete}
					/>
				))}
				<Box padding="16px 14px">
					{isUserLoading ? (
						<Flex justify="center" align="center" height="48px">
							<Spinner size="md" />
						</Flex>
					) : (
						<Formik
							enableReinitialize
							initialValues={{
								formValue: '',
							}}
							onSubmit={async ({ formValue }, { resetForm }) => {
								handleReplyCreate(formValue);
								resetForm();
							}}
						>
							{({ values }) => (
								<Form>
									<FormTextArea
										name="formValue"
										autocomplete="off"
										placeholder="Comment"
										rows={getRows(values.formValue as string)}
									/>
									<Flex justify="flex-end" mt="10px">
										<Button
											type="reset"
											size="md"
											variant="secondary"
											onClick={() => scriptModel.setCurrentCommentData(null)}
										>
											Cancel
										</Button>
										<Button
											name={ButtonType.EDIT_COMMENT}
											type="submit"
											size="md"
											variant="secondary"
											disabled={!values.formValue}
										>
											Apply
										</Button>
									</Flex>
								</Form>
							)}
						</Formik>
					)}
				</Box>
			</Box>
		</ViewCommentModal>
	);
};
