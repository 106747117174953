import * as Yup from 'yup';

export const updateDocLinkSchema = Yup.object({
	formValue: Yup.string()
		.trim()
		.url('Must be valid URL')
		.matches(
			/https:\/\/docs.google.com\/document\/d\/[a-zA-Z0-9_-]{44}/,
			'Must be valid Drive URL',
		),
});
