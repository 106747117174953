import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Box, Button, Flex, Text, useDisclosure } from '@chakra-ui/react';
import { debounce } from 'lodash';

import { GetBookDocument, GetBookQuery } from 'features/book/queries.gen';
import { useUpdateChapterMutation } from 'features/chapter/update/queries.gen';
import { ChapterByIdDocument } from 'features/story-editor/header/queries.gen';
import { BookProperty, trackPropertyChanged } from 'shared/analytics';
import { useAppToast } from 'shared/hooks/toast';
import { PencilIcon } from 'shared/icons';
import { ArrayElement } from 'shared/model';
import { SinglePicker } from 'shared/ui';
import { useChapterHeroesQuery } from 'widgets/hero-list/graphql/queries.gen';

export interface Props {
	chapter: ArrayElement<GetBookQuery['book']['chapters']>;
	bookId?: string | null;
}

export const UpdateSecondHeroNameForm: React.FC<Props> = ({
	chapter,
	bookId,
}) => {
	const toast = useAppToast();
	const { onOpen, onClose, isOpen } = useDisclosure();
	const [searchValue, setSearchValue] = useState('');
	const [search, setSearch] = useState('');
	const [secondHeroName, setSecondHeroName] = useState<
		string | undefined | null
	>();

	const debouncedSetSearch = useMemo(
		() => debounce(setSearch, 500),
		[setSearch],
	);

	useEffect(() => {
		debouncedSetSearch(searchValue);

		return () => debouncedSetSearch.cancel();
	}, [searchValue, debouncedSetSearch]);

	const [updateChapter] = useUpdateChapterMutation({
		onCompleted: () => {
			toast({
				title: 'Second Hero Name updated successfully',
				status: 'success',
			});
		},
		onError: () =>
			toast({
				title: 'Second Hero Name was not updated',
				status: 'error',
			}),
		refetchQueries: [ChapterByIdDocument, GetBookDocument],
	});

	const { data } = useChapterHeroesQuery({
		variables: { filter: { bookId }, limit: 100 },
		fetchPolicy: 'network-only',
		nextFetchPolicy: 'network-only',
		notifyOnNetworkStatusChange: true,
	});

	const options = useMemo(() => {
		const heroNames =
			data?.heroes.reduce<string[]>(
				(acc, { chapters, names }) =>
					chapters.some(({ id }) => id === chapter.id)
						? [...acc, ...names]
						: acc,
				[],
			) || [];
		return heroNames.map((name) => ({ title: name, value: name })) || [];
	}, [data?.heroes, chapter.id]);

	const filteredSearch = useMemo(
		() =>
			search
				? options.filter(({ title }) =>
						title.toLowerCase().includes(search.toLowerCase().trim()),
				  )
				: options,
		[options, search],
	);

	const updateSecondHeroName = async (name: string | null) => {
		await updateChapter({
			variables: {
				chapter: {
					id: chapter?.id,
					secondHeroName: name,
				},
			},
		});
		trackPropertyChanged({
			entity: 'chapter',
			property: BookProperty.SECOND_HERO_NAME,
			value: name || '',
			id: chapter?.id,
		});
	};

	const containerRef = useRef<HTMLDivElement | null>(null);
	return (
		<Box
			borderTop="1px solid #eeeef2"
			borderBottom="1px solid #eeeef2"
			ref={containerRef}
		>
			<Flex
				position="relative"
				p="13px 0"
				justifyContent="space-between"
				alignItems="center"
			>
				<Text fontWeight={500} fontSize="14px" lineHeight="114%">
					Second Hero Name
				</Text>
				<Flex>
					{chapter?.secondHeroName && (
						<Text fontSize="14px" mr="10px">
							{chapter?.secondHeroName}
						</Text>
					)}
					<SinglePicker
						options={filteredSearch}
						onChange={setSecondHeroName}
						selectedValue={secondHeroName}
						searchValue={searchValue}
						setSearchValue={setSearchValue}
						isOpen={isOpen}
						onOpen={onOpen}
						onClose={onClose}
						isNeedPermissionToSearch
						onApply={updateSecondHeroName}
						optionText={
							options.length
								? 'No such hero in this chapter'
								: 'No heroes in this chapter'
						}
						customStyle={{ width: '270px', maxHeight: '328px' }}
					>
						<Button size="circle" variant="secondary">
							<PencilIcon w={6} h={6} />
						</Button>
					</SinglePicker>
				</Flex>
			</Flex>
		</Box>
	);
};
