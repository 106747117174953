import React, { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import {
	Button,
	Flex,
	HStack,
	Tooltip,
	useClipboard,
	// useDisclosure,
} from '@chakra-ui/react';

// import { SnippetGuideline } from 'features/editor/snippet-guideline';
import { SessionManagement } from 'features/user/manage-session';
import { ButtonType } from 'shared/analytics';
import { AnalyticsIconButton } from 'shared/analytics/ui/icon-button';
import { CopyIcon, HelpIcon } from 'shared/icons';
import { Breadcrumb, Breadcrumbs } from 'shared/ui';

interface Props {
	breadcrumbs: Breadcrumb[];
	linkDocs?: string;
}

export const HeaderWithBreadcrumbs: React.FC<Props> = ({
	breadcrumbs,
	linkDocs = '',
}) => {
	const { pathname } = useLocation();
	const { onCopy, hasCopied } = useClipboard(`${window.origin}${pathname}`);

	const handleClick = useCallback(() => {
		window.open(linkDocs, '_blank', 'noreferrer');
	}, [linkDocs]);

	return (
		<>
			<HStack
				p="17px 45px 10px"
				h={10}
				w="full"
				zIndex={1000}
				backgroundColor="#fbfbfc"
				alignItems="center"
				justify="space-between"
			>
				<HStack alignItems="center">
					<Breadcrumbs breadcrumbs={breadcrumbs} />
					<Flex m="0 28px">
						<Tooltip isOpen={hasCopied} label="Copied!">
							<Button
								name={ButtonType.COPY_URL_TO_CLIPBOARD}
								size="sm"
								variant="secondary"
								onClick={onCopy}
							>
								<CopyIcon w="18px" h="18px" />
								Link
							</Button>
						</Tooltip>
					</Flex>
				</HStack>

				<HStack spacing={2} ml="auto" alignItems="center">
					<Tooltip label="Documentation">
						<AnalyticsIconButton
							name={ButtonType.APP_OPEN_DOCS}
							aria-label="snippet-guideline"
							variant="ghost"
							size="square"
							onClick={handleClick}
						>
							<HelpIcon w={5} h={5} />
						</AnalyticsIconButton>
					</Tooltip>
					<SessionManagement />
				</HStack>
			</HStack>
			{/* <SnippetGuideline
				isOpen={isOpen}
				onClose={onClose}
				url="https://help.myfantasy.studio/mf-studio-documentation"
			/> */}
		</>
	);
};
