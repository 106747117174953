import React from 'react';
import { Portal } from '@chakra-ui/react';
import { Formik } from 'formik';

import { AssetType } from 'entities/assets/model';
import { ImageReview } from 'entities/assets/ui/image-review';
import { GetAssetsDocument } from 'pages/art-catalog/ui/ArtCatalogTable/queries.gen';
import { trackAssetCreated } from 'shared/analytics';
import { useGetAssetError } from 'shared/hooks/asset-error';
import { useAppToast } from 'shared/hooks/toast';
import { InputField, InputFieldWithLink } from 'shared/ui';
import { getFilenameFromURL } from 'shared/utils/get-filename-from-url';
import { FilterKeywords } from 'widgets/keywords-filter-modal/constant';

import { KeywordsPicker } from '../../../keywords-picker';
import {
	createClothesSchema,
	CreateClothesValues,
} from '../../lib/schemas/clothes';
import { useCreateClothesMutation } from '../../queries.gen';
import { ActionButtons } from '../action-buttons';
import { CreateAnyAssetFormProps } from '../types';

const ALT_CLOTHES = 'Alternative Clothes';

export const CreateClothesForm: React.FC<CreateAnyAssetFormProps> = ({
	onSubmit,
	onCancel,
	buttonsContainerRef,
	specialType,
	altClothesIds,
}) => {
	const toast = useAppToast();
	const handleError = useGetAssetError();

	const [createClothes] = useCreateClothesMutation({
		onCompleted: (data) => {
			const keywords = Object.values(
				data.createClothes?.newKeywords as FilterKeywords,
			)
				.filter((v) => v)
				.flat();

			if (!keywords.length) {
				toast({
					title: `Clothes was created without keywords`,
					status: 'warning',
				});
			} else {
				toast({ title: 'Clothes created successfully', status: 'success' });
			}
			onSubmit();
			trackAssetCreated({
				type: AssetType.CLOTHES,
				filename: data.createClothes?.filename as string,
				link: data.createClothes?.link as string,
			});
		},
		onError: (mutationError) => handleError(mutationError.message, 'Clothes'),
		refetchQueries: [GetAssetsDocument],
	});

	return (
		<Formik<CreateClothesValues>
			initialValues={{
				link: '',
				filename: '',
				price: 0,
				keywords: [],
				dropboxLink: '',
				newKeywords: {},
			}}
			validationSchema={createClothesSchema}
			onSubmit={(data) =>
				createClothes({
					variables: {
						clothes: {
							...data,
							price: parseInt(data.price as any, 10),
							alternativeClothesIds: altClothesIds,
						},
					},
				})
			}
		>
			{({ values, submitForm, setFieldValue, errors, isSubmitting }) => (
				<>
					{specialType !== ALT_CLOTHES ? (
						<>
							<InputField
								label="Filename"
								name="filename"
								value={
									getFilenameFromURL(values.link) ||
									values.filename?.trim() ||
									''
								}
								onApply={(v: string | number) =>
									setFieldValue('filename', v, true)
								}
								error={errors.filename}
								placeholder="cloths_f_regular_0.png"
								isDisabledFilename={!!values.link}
							/>
							<ImageReview
								label="Clothes"
								type={AssetType.CLOTHES}
								imageSrc={values.link?.trim()}
								onImageChange={(link) =>
									setFieldValue('link', link?.trim(), true)
								}
								error={errors.link}
							/>
							<KeywordsPicker
								type={AssetType.CLOTHES}
								selectedKeywords={values.newKeywords}
								onApply={(v) => setFieldValue('newKeywords', v, true)}
							/>
							<InputFieldWithLink
								label="Link"
								name="dropboxLink"
								value={values.dropboxLink?.trim() || ''}
								onApply={(v: string | number) =>
									setFieldValue('dropboxLink', v, true)
								}
								error={errors.dropboxLink}
								placeholder="https://..."
							/>
						</>
					) : null}

					<Portal containerRef={buttonsContainerRef}>
						<ActionButtons
							onApply={submitForm}
							onCancel={onCancel}
							disabled={isSubmitting}
						/>
					</Portal>
				</>
			)}
		</Formik>
	);
};
