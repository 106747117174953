import { EditorState, Modifier } from 'draft-js';

export const insertDoubledCharacters = (
	editorState: EditorState,
	firstCharacter: '(' | '{' | '[' | '"' | '$',
	secondCharacter: ')' | '}' | ']' | '"' | '$',
) => {
	const currentContent = editorState.getCurrentContent();
	const currentSelection = editorState.getSelection();

	const start = currentSelection.getStartOffset();
	const end = currentSelection.getEndOffset();

	const selectionBefore = editorState.getSelection().merge({
		focusOffset: start,
		anchorOffset: start,
	});

	const selectionAfter = editorState.getSelection().merge({
		focusOffset: end + 1,
		anchorOffset: end + 1,
	});

	let newContent = Modifier.insertText(
		currentContent,
		selectionBefore,
		`${firstCharacter}`,
	);

	newContent = Modifier.insertText(
		newContent,
		selectionAfter,
		`${secondCharacter}`,
	);

	const newEditorState = EditorState.push(
		editorState,
		newContent,
		'insert-characters',
	);

	const endOffSet = editorState.getSelection().getEndOffset();

	const newSelection = editorState.getSelection().merge({
		focusOffset: endOffSet + 1,
		anchorOffset: endOffSet + 1,
	});

	return EditorState.forceSelection(newEditorState, newSelection);
};
