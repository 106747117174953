import { useEffect } from 'react';

const useInfiniteScroll = (
	element,
	callback,
	{ root = null, rootMargin = '20px', threshold = 0.8 } = {},
) => {
	useEffect(() => {
		const options = {
			root,
			rootMargin,
			threshold,
		};

		const observer = new IntersectionObserver(callback, options);

		if (element.current) {
			observer.observe(element.current);
		}

		return () => {
			if (element.current) {
				// eslint-disable-next-line react-hooks/exhaustive-deps
				observer.unobserve(element?.current);
			}
		};
	}, [callback, element, root, rootMargin, threshold]);
};

export default useInfiniteScroll;
