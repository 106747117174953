import React, { useCallback, useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
	Box,
	Flex,
	IconButton,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Text,
	Tooltip,
} from '@chakra-ui/react';

import { scriptModel } from 'entities/script';
import { ButtonType } from 'shared/analytics';
import { AnalyticsIconButton } from 'shared/analytics/ui/icon-button';
import { ArrowDownIcon, LinkIcon } from 'shared/icons';
import DocNotesTooltip from 'widgets/doc-notes-tooltip';

interface Doc {
	id?: string;
	link?: string | null;
	name?: string | null;
	docNotes?: { id?: string | null; name?: string | null } | null;
}

interface AdditionalDoc {
	id?: string;
	link?: string | null;
	name?: string | null;
	docOrder?: number | null;
	docNotes?: { id?: string | null; name?: string | null } | null;
}

export interface DocSelectorProps {
	prodLink?: string;
	prod2PovLink?: string;
	prodLinkVersionB?: Doc | null;
	prodLinkVersionC?: Doc | null;
	prodLinkVersionD?: Doc | null;
	writerLink?: string;
	narrativeLink?: string;
	pageName: string;
	googleDocId: string;
	localizedGoogleDocLinks: any;
	additionalDocs?: AdditionalDoc[];
	hasTwoPovs?: boolean | null;
}

export const DocSelector: React.FC<DocSelectorProps> = ({
	prodLink,
	prod2PovLink,
	prodLinkVersionB,
	prodLinkVersionC,
	prodLinkVersionD,
	writerLink,
	narrativeLink,
	pageName,
	googleDocId,
	localizedGoogleDocLinks,
	additionalDocs = [],
	hasTwoPovs,
}) => {
	const { bookId, chapterId } = useParams();

	const localizedDocs = useMemo(() => {
		return Object.entries(localizedGoogleDocLinks).filter(
			([key, val]) => val && key !== 'EN',
		);
	}, [localizedGoogleDocLinks]);

	const isDropdownDisabled = useMemo(
		() =>
			![
				prodLink,
				prod2PovLink,
				writerLink,
				narrativeLink,
				localizedDocs.length,
				prodLinkVersionB,
				prodLinkVersionC,
				prodLinkVersionD,
				...additionalDocs,
			].filter(Boolean).length,
		[
			prodLink,
			prod2PovLink,
			writerLink,
			narrativeLink,
			localizedDocs.length,
			prodLinkVersionB,
			prodLinkVersionC,
			prodLinkVersionD,
			additionalDocs,
		],
	);

	const handleOpenGoogleDocs = useCallback(() => {
		if (googleDocId !== 'no-doc') {
			window.open(`https://docs.google.com/document/d/${googleDocId}/edit`);
		}
	}, [googleDocId]);

	const additionalDoc = additionalDocs?.filter((doc) => doc.id === googleDocId);

	const isNoGoogleDocLink = !!additionalDoc?.length;

	return (
		<Flex alignItems="center" height="32px" position="relative">
			<Text mr={2}>{pageName}</Text>
			<Menu>
				{/* eslint-disable-next-line sonarjs/cognitive-complexity */}
				{({ isOpen }) => (
					<>
						<Tooltip label="Document Version">
							<IconButton
								as={MenuButton}
								variant="ghost"
								size="square"
								aria-label="Document Version"
								disabled={isDropdownDisabled}
							>
								<ArrowDownIcon
									w={5}
									h={5}
									transform={isOpen ? 'rotate(180deg)' : 'none'}
									transition="ease"
									transitionDuration="200ms"
								/>
							</IconButton>
						</Tooltip>
						<MenuList ml="-100px">
							{writerLink && (
								<MenuItem
									as={Link}
									onClick={() => scriptModel.setIsDisabledControlButtons(true)}
									to={`/stories/${bookId}/${chapterId}/${writerLink || ''}`}
								>
									Author
								</MenuItem>
							)}
							{narrativeLink && (
								<MenuItem
									as={Link}
									onClick={() => scriptModel.setIsDisabledControlButtons(true)}
									to={`/stories/${bookId}/${chapterId}/${narrativeLink || ''}`}
								>
									Narrative Designer
								</MenuItem>
							)}
							{prodLink || localizedDocs.length ? (
								<MenuItem
									as={Link}
									onClick={() => scriptModel.setIsDisabledControlButtons(true)}
									to={`/stories/${bookId}/${chapterId}/${prodLink || ''}`}
								>
									Production
								</MenuItem>
							) : null}
							{prod2PovLink && hasTwoPovs && (
								<MenuItem
									as={Link}
									onClick={() => scriptModel.setIsDisabledControlButtons(true)}
									to={`/stories/${bookId}/${chapterId}/${prod2PovLink || ''}`}
								>
									Production 2Pov
								</MenuItem>
							)}
							{prodLinkVersionB && (
								<MenuItem
									data-group
									as={Link}
									onClick={() => scriptModel.setIsDisabledControlButtons(true)}
									to={`/stories/${bookId}/${chapterId}/${
										prodLinkVersionB.id || ''
									}`}
								>
									<DocNotesTooltip
										docNotesName={prodLinkVersionB?.docNotes?.name}
										label="A/B Production [B]"
									/>
								</MenuItem>
							)}
							{prodLinkVersionC && (
								<MenuItem
									data-group
									as={Link}
									onClick={() => scriptModel.setIsDisabledControlButtons(true)}
									to={`/stories/${bookId}/${chapterId}/${
										prodLinkVersionC.id || ''
									}`}
								>
									<DocNotesTooltip
										docNotesName={prodLinkVersionC?.docNotes?.name}
										label="A/B Production [C]"
									/>
								</MenuItem>
							)}
							{prodLinkVersionD && (
								<MenuItem
									data-group
									as={Link}
									onClick={() => scriptModel.setIsDisabledControlButtons(true)}
									to={`/stories/${bookId}/${chapterId}/${
										prodLinkVersionD.id || ''
									}`}
								>
									<DocNotesTooltip
										docNotesName={prodLinkVersionD?.docNotes?.name}
										label="A/B Production [D]"
									/>
								</MenuItem>
							)}
							{additionalDocs &&
								additionalDocs
									.sort((a, b) => (a.docOrder || 0) - (b.docOrder || 0))
									.map((doc) => (
										<MenuItem
											data-group
											key={doc.docOrder}
											as={Link}
											onClick={() =>
												scriptModel.setIsDisabledControlButtons(true)
											}
											to={`/stories/${bookId}/${chapterId}/${doc.id}`}
										>
											<DocNotesTooltip
												docNotesName={doc?.docNotes?.name}
												label={`Additional Doc ${doc.docOrder}`}
											/>
										</MenuItem>
									))}
						</MenuList>
					</>
				)}
			</Menu>
			{!isNoGoogleDocLink && (
				<>
					<Box mx="5px" width="1px" height="23px" background="#eeeef2" />
					<Tooltip label="Google Docs">
						<AnalyticsIconButton
							name={ButtonType.OPEN_GOOGLE_DOC}
							variant="ghost"
							size="square"
							aria-label="Google Docs"
							onClick={handleOpenGoogleDocs}
						>
							<LinkIcon w={5} h={5} />
						</AnalyticsIconButton>
					</Tooltip>
				</>
			)}
		</Flex>
	);
};
