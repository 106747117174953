import React, { Dispatch, SetStateAction, useCallback } from 'react';
import { Box, Button, Flex, Spinner } from '@chakra-ui/react';
import { EditorState, RichUtils } from 'draft-js';
import { useStore } from 'effector-react';
import { Form, Formik } from 'formik';
import { v4 as uuidv4 } from 'uuid';

import {
	DraftCommentEntityWithRowsAndKeys,
	DraftReplyData,
	generateDecorator,
	scriptModel,
} from 'entities/script';
import { useUserByEmailQuery } from 'entities/user';
import { ButtonType } from 'shared/analytics';
import { FormTextArea } from 'shared/ui';
import {
	executeConsistentAction,
	extractCommentedTextOffsetsFromBlock,
	movePointerToEndOfSelection,
} from 'shared/utils';
import { getRows } from 'shared/utils/get-rows';

import { UpdateCommentFormValues } from '../schema'; // TODO add all validation schemas
import { EditCommentForm } from './edit-comment-form';
import { EditReplyForm } from './edit-reply-form';

interface ListCommentProps {
	editorState: EditorState;
	setEditorState: Dispatch<SetStateAction<EditorState>>;
	commentEntity: DraftCommentEntityWithRowsAndKeys;
}

// TODO REFACTOR
// * delete duplicated code
// * move reply, edit forms into DOC entities and reuse them in features
// * move delete handler into DOC entities/lib
// TODO REFACTOR

export const ListComment: React.FC<ListCommentProps> = ({
	editorState,
	setEditorState,
	commentEntity,
}) => {
	const hideBlockKeys = useStore(scriptModel.$hideBlockKeys);
	const { data: userData, loading: isUserLoading } = useUserByEmailQuery();

	const handleCommentDataMerge = useCallback(
		(mergedData: any) => {
			const contentState = editorState.getCurrentContent();
			const newContentState = contentState.mergeEntityData(
				commentEntity?.entityKey || '',
				mergedData,
			);
			const newEditorState = EditorState.set(editorState, {
				currentContent: newContentState,
				decorator: generateDecorator(''),
			});
			commentEntity.commentedBlocksKeys.forEach((key) => {
				const { endOffset } = extractCommentedTextOffsetsFromBlock(
					newContentState,
					key,
					commentEntity?.entityKey,
				);

				const newSelection = EditorState.forceSelection(
					newEditorState,
					newEditorState.getSelection().merge({
						anchorKey: key,
						focusKey: key,
						anchorOffset: endOffset,
						focusOffset: endOffset,
					}),
				).getSelection();

				executeConsistentAction(() =>
					setEditorState(
						EditorState.forceSelection(newEditorState, newSelection),
					),
				);
			});
			scriptModel.setIsScriptEdited(true);
		},
		[
			commentEntity.commentedBlocksKeys,
			commentEntity?.entityKey,
			editorState,
			setEditorState,
		],
	);

	const handleCommentResolve = useCallback(() => {
		handleCommentDataMerge({
			resolved: !commentEntity?.entityData?.resolved,
		});
	}, [commentEntity?.entityData?.resolved, handleCommentDataMerge]);

	const handleCommentDelete = useCallback(() => {
		const contentState = editorState.getCurrentContent();
		let newEditorState = editorState;
		let newSelection = editorState.getSelection();

		commentEntity.commentedBlocksKeys.forEach((blockKey) => {
			const { startOffset, endOffset } = extractCommentedTextOffsetsFromBlock(
				contentState,
				blockKey,
				commentEntity?.entityKey,
			);

			const selectionForDelete = EditorState.forceSelection(
				newEditorState,
				newEditorState.getSelection().merge({
					anchorKey: blockKey,
					focusKey: blockKey,
					anchorOffset: startOffset,
					focusOffset: endOffset,
					// * this needed for proper deletion
					isBackward: false,
				}),
			).getSelection();

			newEditorState = RichUtils.toggleLink(
				newEditorState,
				selectionForDelete,
				null,
			);
			newSelection = movePointerToEndOfSelection(newEditorState.getSelection());
		});

		setEditorState(EditorState.forceSelection(newEditorState, newSelection));
		scriptModel.setIsScriptEdited(true);
	}, [
		commentEntity.commentedBlocksKeys,
		commentEntity?.entityKey,
		editorState,
		setEditorState,
	]);

	const handleCommentEdit = useCallback(
		(comment?: string) => {
			if (!comment) return;
			handleCommentDataMerge({ comment });
		},
		[handleCommentDataMerge],
	);

	const handleReplyCreate = useCallback(
		(formValue?: string) => {
			if (!formValue) return;

			handleCommentDataMerge({
				replies: [
					...(commentEntity?.entityData?.replies || []),
					{
						id: uuidv4(),
						author:
							userData?.user.fullName ||
							userData?.user.email ||
							'Unknown author',
						reply: formValue,
						picture: userData?.user.picture,
						resolved: false,
						createdAt: Date.now(),
					},
				],
			});
		},
		[
			handleCommentDataMerge,
			commentEntity?.entityData?.replies,
			userData?.user.email,
			userData?.user.fullName,
			userData?.user.picture,
		],
	);

	const handleReplyDelete = useCallback(
		(id: string) => {
			handleCommentDataMerge({
				replies: (commentEntity?.entityData?.replies || []).filter(
					(reply) => reply.id !== id,
				),
			});
		},
		[commentEntity?.entityData?.replies, handleCommentDataMerge],
	);

	const handleReplyEdit = useCallback(
		(formValue: string | undefined, replyData: DraftReplyData) => {
			if (!formValue) return;

			handleCommentDataMerge({
				replies: (commentEntity?.entityData?.replies || []).map((reply) => {
					if (reply.id === replyData.id) {
						return { ...replyData, reply: formValue };
					}
					return reply;
				}),
			});
		},
		[commentEntity?.entityData?.replies, handleCommentDataMerge],
	);

	const handleCommentScroll = useCallback(() => {
		const contentBlock = editorState
			.getCurrentContent()
			.getBlockForKey(commentEntity.blockKey);

		const selection = editorState.getSelection().merge({
			anchorKey: contentBlock.getKey(),
			focusKey: contentBlock.getKey(),
			focusOffset: contentBlock.getText().length,
			anchorOffset: contentBlock.getText().length,
		});
		setEditorState(EditorState.forceSelection(editorState, selection));

		setTimeout(() => {
			window.getSelection()?.focusNode?.parentElement?.scrollIntoView({
				inline: 'nearest',
				block: 'center',
			});
		}, 50);

		const blockKey = contentBlock.getKey();

		hideBlockKeys.forEach((foldBlock) => {
			if (foldBlock.blockKeysIn.includes(blockKey) && !foldBlock.isOpen) {
				const newArr = foldBlock;
				newArr.isOpen = true;

				scriptModel.setHideBlockKeys(newArr);
			}
		});
	}, [commentEntity.blockKey, editorState, hideBlockKeys, setEditorState]);

	return (
		<Flex
			direction="column"
			boxShadow="0px 4px 4px rgba(0, 0, 0, 0.08), 0px 0px 1px rgba(67, 90, 111, 0.47)"
			borderRadius="8px"
			mb="16px"
			position="relative"
		>
			<Flex
				padding="7px 14px"
				background="#F3F3F8"
				borderRadius="8px 8px 0 0"
				onClick={handleCommentScroll}
			>
				Selected rows -&gt;{' '}
				{commentEntity.commentedBlocksRows.map((row, index, array) =>
					index !== array.length - 1 ? `${row} · ` : row,
				) || '-'}
			</Flex>
			<EditCommentForm
				commentEntity={commentEntity}
				handleCommentEdit={handleCommentEdit}
				handleCommentDelete={handleCommentDelete}
				handleCommentResolve={handleCommentResolve}
				handleCommentScroll={handleCommentScroll}
			/>
			{commentEntity?.entityData?.replies?.map((reply, index) => (
				<EditReplyForm
					// eslint-disable-next-line react/no-array-index-key
					key={reply.id + index}
					replyData={reply}
					handleReplyEdit={handleReplyEdit}
					handleReplyDelete={handleReplyDelete}
				/>
			))}
			<Box padding="16px 14px ">
				{isUserLoading ? (
					<Flex justify="center">
						<Spinner size="md" />
					</Flex>
				) : (
					<Formik<UpdateCommentFormValues> // TODO add schema for validation
						enableReinitialize
						initialValues={{
							formValue: '',
						}}
						onSubmit={async ({ formValue }, { resetForm }) => {
							handleReplyCreate(formValue);
							resetForm();
						}}
					>
						{({ values }) => (
							<Form>
								<FormTextArea
									name="formValue"
									autocomplete="off"
									placeholder="Reply"
									rows={getRows(values.formValue as string)}
								/>
								<Flex justify="flex-end" mt="10px">
									<Button type="reset" size="md" variant="secondary">
										Cancel
									</Button>
									<Button
										name={ButtonType.REPLY_COMMENT}
										type="submit"
										size="md"
										variant="secondary"
										disabled={!values.formValue}
									>
										Apply
									</Button>
								</Flex>
							</Form>
						)}
					</Formik>
				)}
			</Box>
		</Flex>
	);
};
