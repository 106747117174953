import {
	ASTBuilder,
	ChoiceNode,
	Lexer,
	Node,
	OptionNode,
	Parser,
	TokenTypeName,
	Validator,
} from '@holywater-tech/lua-interpreter';

import { scriptModel } from 'entities/script';

export const countChoices = (
	node: Node | OptionNode | ChoiceNode,
): { free: number; paid: number } => {
	try {
		if (
			node instanceof ChoiceNode &&
			[TokenTypeName.CHOICE, TokenTypeName.TIMED_CHOICE].includes(
				node.token.type.name,
			)
		) {
			return {
				free: node.isPaid ? 0 : 1,
				paid: node.isPaid ? 1 : 0,
			};
		}
		if (node instanceof OptionNode) {
			return node.childNodes.reduce(
				(acc, subnode) => {
					const inRes = countChoices(subnode);
					return {
						free: acc.free + inRes.free,
						paid: acc.paid + inRes.paid,
					};
				},
				{ free: 0, paid: 0 },
			);
		}

		return { free: 0, paid: 0 };
	} catch {
		return { free: 0, paid: 0 };
	}
};

export const countWords = (str: string): number => {
	try {
		const lexer = new Lexer(str?.replaceAll('“', '"')?.replaceAll('”', '"'));
		const lexemes = lexer.lexAnalysis();
		const validator = new Validator({
			items: [],
			backgrounds: [],
			characters: [],
			audios: {
				android: [],
				ios: [],
			},
			videos: [],
			prevChaptersRems: [],
		});
		validator.validateBrackets(lexemes);
		const astBuilder = new ASTBuilder(lexemes);
		const ast = astBuilder.parseNodes();
		const parser = new Parser(ast, validator);
		parser.parse();
		scriptModel.setSyntaxErrors(false);
		return validator.wordsCount;
	} catch {
		scriptModel.setSyntaxErrors(true);
		return 0;
	}
};

export const choicesCounter = (str: string) => {
	try {
		const lexer = new Lexer(str?.replaceAll('“', '"')?.replaceAll('”', '"'));
		const lexemes = lexer.lexAnalysis();
		const validator = new Validator({
			items: [],
			backgrounds: [],
			characters: [],
			audios: {
				android: [],
				ios: [],
			},
			videos: [],
			prevChaptersRems: [],
		});
		validator.validateBrackets(lexemes);
		const astBuilder = new ASTBuilder(lexemes);
		const ast = astBuilder.parseNodes();

		return ast.reduce(
			(acc, node) => {
				const res = countChoices(node);
				return {
					free: acc.free + res.free,
					paid: acc.paid + res.paid,
				};
			},
			{ free: 0, paid: 0 },
		);
	} catch {
		return { free: 0, paid: 0 };
	}
};
