import * as Types from '../../../../models.gen';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type KeywordsAssetsQueryVariables = Types.Exact<{
  type?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type KeywordsAssetsQuery = { keywords: Array<string> };


export const KeywordsAssetsDocument = gql`
    query KeywordsAssets($type: String) {
  keywords(type: $type)
}
    `;

/**
 * __useKeywordsAssetsQuery__
 *
 * To run a query within a React component, call `useKeywordsAssetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useKeywordsAssetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKeywordsAssetsQuery({
 *   variables: {
 *      type: // value for 'type'
 *   },
 * });
 */
export function useKeywordsAssetsQuery(baseOptions?: Apollo.QueryHookOptions<KeywordsAssetsQuery, KeywordsAssetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<KeywordsAssetsQuery, KeywordsAssetsQueryVariables>(KeywordsAssetsDocument, options);
      }
export function useKeywordsAssetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KeywordsAssetsQuery, KeywordsAssetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<KeywordsAssetsQuery, KeywordsAssetsQueryVariables>(KeywordsAssetsDocument, options);
        }
export type KeywordsAssetsQueryHookResult = ReturnType<typeof useKeywordsAssetsQuery>;
export type KeywordsAssetsLazyQueryHookResult = ReturnType<typeof useKeywordsAssetsLazyQuery>;
export type KeywordsAssetsQueryResult = Apollo.QueryResult<KeywordsAssetsQuery, KeywordsAssetsQueryVariables>;