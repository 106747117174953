import React, { useCallback } from 'react';
import {
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
} from '@chakra-ui/react';

import { GetBookDocument } from 'features/book/queries.gen';
import { ChapterByIdDocument } from 'features/story-editor/header/queries.gen';
import { useAppToast } from 'shared/hooks/toast';
import {
	DocNotesData,
	EditDocDescription,
	EditDocNotes,
} from 'shared/ui/edit-doc-description';
import {
	GetDocsNotesByIdDocument,
	useUpdateDocsNotesMutation,
} from 'shared/ui/edit-doc-description/queries.gen';

interface UpdateDocNotesModalProps {
	isOpen: boolean;
	onClose: () => void;
	docType: 'A/B' | 'Additional Doc';
	label?: string;
	docNotesData?: DocNotesData | null;
}

export const UpdateDocNotesModal: React.FC<UpdateDocNotesModalProps> = ({
	isOpen,
	onClose,
	docType,
	label,
	docNotesData,
}) => {
	const toast = useAppToast();

	const [updateDocNotes, { loading: isUpdatingDocNotes }] =
		useUpdateDocsNotesMutation({
			refetchQueries: [
				ChapterByIdDocument,
				GetBookDocument,
				GetDocsNotesByIdDocument,
			],
		});

	const handleDocsNotesUpdate = useCallback(
		async (docsNotes: EditDocNotes) => {
			if (docNotesData) {
				try {
					await updateDocNotes({
						variables: {
							docsNotes: {
								id: docNotesData.id,
								...docsNotes,
							},
						},
					});
					onClose();

					toast({
						title: `${docType} Notes updated successfully`,
						status: 'success',
					});
				} catch (error) {
					toast({
						title: `${docType} Notes were not updated`,
					});
				}
			}
		},
		[docNotesData, updateDocNotes, onClose, toast, docType],
	);

	return (
		<Modal isOpen={isOpen} onClose={onClose} autoFocus={false}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>{label} Notes</ModalHeader>
				<ModalCloseButton margin="10px 15px" />
				<ModalBody p={0}>
					<EditDocDescription
						onClose={onClose}
						docType={docType}
						docNotesData={docNotesData}
						onSubmit={handleDocsNotesUpdate}
						isSubmittingForm={isUpdatingDocNotes}
					/>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};
