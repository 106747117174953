import * as Types from '../../../models.gen';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteBodyMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type DeleteBodyMutation = { deleteBody: { filename?: string | null, link?: string | null } };


export const DeleteBodyDocument = gql`
    mutation DeleteBody($id: String!) {
  deleteBody(id: $id) {
    filename
    link
  }
}
    `;
export type DeleteBodyMutationFn = Apollo.MutationFunction<DeleteBodyMutation, DeleteBodyMutationVariables>;

/**
 * __useDeleteBodyMutation__
 *
 * To run a mutation, you first call `useDeleteBodyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBodyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBodyMutation, { data, loading, error }] = useDeleteBodyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteBodyMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBodyMutation, DeleteBodyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteBodyMutation, DeleteBodyMutationVariables>(DeleteBodyDocument, options);
      }
export type DeleteBodyMutationHookResult = ReturnType<typeof useDeleteBodyMutation>;
export type DeleteBodyMutationResult = Apollo.MutationResult<DeleteBodyMutation>;
export type DeleteBodyMutationOptions = Apollo.BaseMutationOptions<DeleteBodyMutation, DeleteBodyMutationVariables>;