import { useCallback } from 'react';

import { Card } from 'widgets/create-card-modal/utils/getAllCombinations';
import {
	useFindOneClothesLazyQuery,
	useGetBodyLazyQuery,
	useGetHairLazyQuery,
	useGetLayerLazyQuery,
} from 'widgets/update-asset-modal/ui/modal/queries.gen';

export const useGetCardLinks = () => {
	const [getBody] = useGetBodyLazyQuery();
	const [getClothes] = useFindOneClothesLazyQuery();
	const [getHair] = useGetHairLazyQuery();
	const [getLayer] = useGetLayerLazyQuery();

	return useCallback(
		async (card: Card) => {
			const { data: bodyData } = await getBody({
				variables: { id: card.bodyId },
			});
			const { data: clothesData } = await getClothes({
				variables: { id: card.clothesId },
			});
			const { data: hairData } = await getHair({
				variables: { id: card.hairId },
			});
			const { data: layerData } = await getLayer({
				variables: { id: card.layerId || '' },
			});

			const emotions = bodyData?.body.emotions;

			return {
				body: bodyData?.body.link,
				emotion:
					emotions?.filter((em) => em.includes('_default'))[0] || emotions?.[0],
				clothes: clothesData?.findOneClothes.link,
				hairFront: hairData?.hair.frontLink,
				hairFilename: hairData?.hair.filename || hairData?.hair.atName,
				hairBack: hairData?.hair.backLink,
				layer: layerData?.layer.link,
			};
		},
		[getBody, getClothes, getHair, getLayer],
	);
};
