import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';

import { Asset } from 'entities/assets/interfaces/asset';
import { AssetType } from 'entities/assets/model';
import { ImageReview } from 'entities/assets/ui/image-review';
import {
	GetAssetByIdDocument,
	GetAssetsDocument,
} from 'pages/art-catalog/ui/ArtCatalogTable/queries.gen';
import { useGetAssetError } from 'shared/hooks/asset-error';
import { useAppToast } from 'shared/hooks/toast';
import { InputField, InputFieldWithLink } from 'shared/ui';
import { GetHairDocument } from 'widgets/update-asset-modal/ui/modal/queries.gen';

import { KeywordsPicker } from '../../keywords-picker';
import { updateHairSchema, UpdateHairValues } from '../lib/schemas/hair';
import { useUpdateHairMutation } from './graphql/queries.gen';

export interface Props {
	asset: Asset;
}

export const UpdateHairForm: React.FC<Props> = ({ asset }) => {
	const toast = useAppToast();
	const handleError = useGetAssetError();
	const [isDisabledFilename, setIsDisabledFilename] = useState(
		!!asset.filename,
	);
	const [isUploadError, setIsUploadError] = useState(false);

	useEffect(() => {
		setIsDisabledFilename(!!asset.filename);
	}, [asset.filename]);

	const [updateHair] = useUpdateHairMutation({
		onCompleted: () => {
			toast({
				title: 'Hair updated successfully',
				status: 'success',
			});
			setIsUploadError(false);
		},

		onError: (mutationError) => {
			handleError(mutationError.message, 'Hair');
			setIsUploadError(!asset?.frontLink);
		},
		refetchQueries: [GetAssetsDocument, GetHairDocument, GetAssetByIdDocument],
	});

	return (
		<Formik<UpdateHairValues>
			initialValues={{
				id: asset.id,
				filename: asset.filename,
				frontLink: asset.frontLink,
				backLink: asset.backLink,
				keywords: asset.keywords,
				newKeywords: asset.newKeywords || {},
				dropboxLink: asset.dropboxLink || '',
				newHair: true, // TODO: add field in form
			}}
			validationSchema={updateHairSchema}
			onSubmit={(value) => updateHair({ variables: { hair: value } })}
			enableReinitialize
		>
			{({ values, submitForm, setFieldValue, errors, validateForm }) => (
				<Form>
					<InputField
						label="Filename"
						name="filename"
						value={values.filename?.trim() || ''}
						onApply={async (v: string | number) => {
							setFieldValue('filename', v, true);
							await validateForm();
							if (!errors.filename) submitForm();
						}}
						error={errors.filename}
						placeholder="hair_f_0.png"
						isDisabledFilename={isDisabledFilename}
					/>
					<ImageReview
						label="Front hair"
						type={asset.type}
						atName={isUploadError ? '' : asset?.atName?.trim()}
						imageSrc={isUploadError ? '' : values.frontLink || ''}
						onImageChange={async (link) => {
							setFieldValue('frontLink', link, true);
							await validateForm();
							return submitForm();
						}}
						error={errors.frontLink}
						isUploadError={isUploadError}
					/>
					<ImageReview
						label="Back hair"
						type={asset.type}
						imageSrc={values.backLink || ''}
						onImageChange={async (backLink) => {
							setFieldValue('backLink', backLink, true);
							await validateForm();
							return submitForm();
						}}
						error={errors.backLink}
						isUploadError={isUploadError}
					/>
					<KeywordsPicker
						type={AssetType.HAIR}
						selectedKeywords={values.newKeywords}
						onApply={async (v) => {
							setFieldValue('newKeywords', v, true);
							await validateForm();
							submitForm();
						}}
					/>
					<InputFieldWithLink
						label="Link"
						name="dropboxLink"
						value={values.dropboxLink?.trim() || ''}
						onApply={async (v: string | number) => {
							setFieldValue('dropboxLink', v, true);
							await validateForm();
							if (!errors.dropboxLink) submitForm();
						}}
						error={errors.dropboxLink}
						placeholder="https://..."
					/>
				</Form>
			)}
		</Formik>
	);
};
