import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
	Button,
	Flex,
	Grid,
	Modal,
	ModalContent,
	ModalOverlay,
} from '@chakra-ui/react';
import { debounce } from 'lodash';

import { sortOptions } from 'features/asset/add-assets-modal/constant/sortOptions';
import Header from 'widgets/select-background-modal/ui/header';
import { Background } from 'models.gen';

import AssetsTab from './ui/assets-tab';

interface ChaptersAssetModalProps {
	isOpen: boolean;
	onClose: () => void;
	chapterId: string;
	handleSetFieldValue: (
		field: string,
		value: any,
		shouldValidate?: any,
	) => void;
}

export const SelectBackgroundModal: React.FC<ChaptersAssetModalProps> = ({
	isOpen,
	onClose,
	chapterId,
	handleSetFieldValue,
}) => {
	const [searchAsset, setSearchAsset] = useState('');
	const [searchAssetValue, setSearchAssetValue] = useState('');
	const [selectedAsset, setSelectedAsset] = useState<Background | null>(null);
	const [sortAssets, setSortAssets] = useState(sortOptions[0].value);
	const [keywordsAssets, setKeywordsAssets] = useState<string[]>([]);
	const [page, setPage] = useState(0);

	useEffect(() => {
		setPage(0);
	}, [searchAsset, keywordsAssets]);

	const debouncedSetSearch = useMemo(
		() => debounce((v) => setSearchAsset(v), 1000),
		[setSearchAsset],
	);

	useEffect(() => {
		debouncedSetSearch(searchAssetValue);

		return () => debouncedSetSearch.cancel();
	}, [searchAssetValue, debouncedSetSearch]);

	const defaultState = useCallback(() => {
		setSearchAsset('');
		setSelectedAsset(null);
		setSortAssets(sortOptions[0].value);
		setKeywordsAssets([]);
		setPage(0);
	}, []);

	const onCloseModal = useCallback(() => {
		onClose();
		defaultState();
	}, [defaultState, onClose]);

	const handleSubmit = useCallback(async () => {
		handleSetFieldValue('background', selectedAsset);
		onCloseModal();
	}, [handleSetFieldValue, onCloseModal, selectedAsset]);

	return (
		<Modal isOpen={isOpen} onClose={onCloseModal}>
			<ModalOverlay />
			<ModalContent minW={820} m={0} overflow="hidden">
				<Header
					searchAsset={searchAssetValue}
					setSearchAsset={setSearchAssetValue}
				/>
				<Grid templateRows="auto 1fr auto" maxH={700} minH={700} h="full">
					<Grid templateRows="auto 1fr" overflow="auto" minH={610}>
						<AssetsTab
							chapterId={chapterId}
							selectedAsset={selectedAsset}
							setSelectedAsset={setSelectedAsset}
							search={searchAsset}
							page={page}
							sort={sortAssets}
							setSort={setSortAssets}
							keywords={keywordsAssets}
							setKeywords={setKeywordsAssets}
						/>
					</Grid>
					<Flex
						justifyContent="flex-end"
						alignItems="center"
						gap={5}
						px={6}
						py={4}
						borderTop="1px solid #eeeef2"
					>
						<Button
							type="button"
							size="lg"
							variant="secondary"
							onClick={onCloseModal}
						>
							Cancel
						</Button>
						<Button
							type="button"
							size="lg"
							variant="primary"
							onClick={handleSubmit}
							isDisabled={!selectedAsset}
						>
							Apply
						</Button>
					</Flex>
				</Grid>
			</ModalContent>
		</Modal>
	);
};
