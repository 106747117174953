export const getRows = (value: string) => {
	const textareaElements = document.getElementsByTagName('textarea');
	const currentElement: HTMLTextAreaElement | undefined = Array.from(
		textareaElements,
	).find((el: HTMLTextAreaElement) => el.value === value);

	if (!value) {
		Array.from(textareaElements).forEach((el: HTMLTextAreaElement) => {
			const element = el;
			element.style.height = 'auto';
		});
	}

	if (currentElement) {
		currentElement.style.height = 'auto';
		const elementHeight =
			currentElement.scrollHeight < 106 ? currentElement.scrollHeight + 2 : 108;
		currentElement.style.height = `${elementHeight.toString()}px`;
	}

	return 1;
};
