import React from 'react';
import { createIcon } from '@chakra-ui/react';

export const CommentsIcon = createIcon({
	viewBox: '0 0 130 122',
	path: (
		<g fill="none">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M88.809 44.038C88.809 45.1232 88.7713 46.1954 88.6819 47.2552C88.4727 50.1037 87.9903 52.8643 87.2543 55.5227C82.2351 73.7083 65.5597 87.0714 45.7688 87.0714H42.4566C42.0377 85.1491 41.7521 83.1823 41.6059 81.1711C41.5176 80.1112 41.4789 79.0323 41.4789 77.9533C41.4789 54.1902 60.7501 34.9132 84.5195 34.9132C85.6424 34.9132 86.7595 34.9576 87.8638 35.0403C88.4851 37.9398 88.809 40.9541 88.809 44.038Z"
				fill="#EEEEF2"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M88.8064 44.0355C88.8064 45.1201 88.7687 46.1965 88.685 47.2578C88.4728 50.1011 87.9878 52.8612 87.2523 55.5222C82.2325 73.7114 65.5597 87.0709 45.771 87.0709H2L10.113 68.1385C6.11694 62.2319 3.53914 55.28 2.90231 47.7816C2.78868 46.5451 2.73548 45.2947 2.73548 44.0355C2.73548 31.4347 8.15656 20.092 16.8 12.2213C24.443 5.24606 34.6101 1 45.771 1C69.5403 1 88.8064 20.2733 88.8064 44.0355Z"
				stroke="currentColor"
				strokeWidth="1.5"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M41.4821 77.9502C41.4821 79.0349 41.5198 80.1117 41.6034 81.1726C41.8157 84.0159 42.3012 86.776 43.0367 89.4375C48.0559 107.627 64.7287 120.986 84.5175 120.986H128.288L120.175 102.054C124.172 96.1472 126.75 89.1947 127.386 81.6963C127.5 80.4599 127.553 79.2094 127.553 77.9502C127.553 65.3494 122.132 54.0068 113.488 46.136C105.846 39.1608 95.6783 34.9153 84.5175 34.9153C60.7482 34.9153 41.4821 54.1886 41.4821 77.9502Z"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeDasharray="3 2"
			/>
		</g>
	),
});
