import React from 'react';
import { Portal } from '@chakra-ui/react';
import { Formik } from 'formik';

import { AssetType } from 'entities/assets/model';
import { ImageReview } from 'entities/assets/ui/image-review';
import { ARRAY_OF_LAYERS_POSITION } from 'features/asset/update/forms/layer';
import { GetAssetsDocument } from 'pages/art-catalog/ui/ArtCatalogTable/queries.gen';
import { trackAssetCreated } from 'shared/analytics';
import { useGetAssetError } from 'shared/hooks/asset-error';
import { useAppToast } from 'shared/hooks/toast';
import { InputFieldWithLink, SingleSelectForm } from 'shared/ui';
import { FilterKeywords } from 'widgets/keywords-filter-modal/constant';

import { KeywordsPicker } from '../../../keywords-picker';
import {
	CreateLayerInitialValues,
	createLayerSchema,
	CreateLayerValues,
} from '../../lib/schemas/layer';
import { useCreateLayerMutation } from '../../queries.gen';
import { ActionButtons } from '../action-buttons';
import { CreateAnyAssetFormProps } from '../types';

export const CreateLayerForm: React.FC<CreateAnyAssetFormProps> = ({
	onSubmit,
	onCancel,
	buttonsContainerRef,
}) => {
	const toast = useAppToast();
	const handleError = useGetAssetError();

	const [createLayer] = useCreateLayerMutation({
		onCompleted: (data) => {
			const keywords = Object.values(
				data.createLayer?.newKeywords as FilterKeywords,
			)
				.filter((v) => v)
				.flat();

			if (!keywords.length) {
				toast({
					title: `Layer was created without keywords`,
					status: 'warning',
				});
			} else {
				toast({ title: 'Layer created successfully', status: 'success' });
			}
			onSubmit();
			trackAssetCreated({
				type: AssetType.LAYER,
				filename: data.createLayer?.name as string,
				link: data.createLayer?.link as string,
			});
		},
		onError: (mutationError) => handleError(mutationError.message, 'Layer'),
		refetchQueries: [GetAssetsDocument],
	});

	return (
		<Formik<CreateLayerInitialValues>
			initialValues={{
				link: '',
				price: 0,
				description: '',
				keywords: [],
				dropboxLink: '',
				newKeywords: {},
			}}
			validationSchema={createLayerSchema}
			onSubmit={(data) => {
				const correctData = data as CreateLayerValues;
				createLayer({
					variables: {
						layer: {
							...correctData,
							price: parseInt(correctData.price as any, 10),
							position: parseInt(correctData.position as any, 10),
						},
					},
				});
			}}
		>
			{({
				values,
				submitForm,
				setFieldValue,
				isSubmitting,
				setSubmitting,
				errors,
				touched,
			}) => (
				<>
					<ImageReview
						label="Layer"
						type={AssetType.BODY}
						imageSrc={values.link?.trim()}
						onImageChange={(link) => {
							setFieldValue('link', link?.trim(), true);
							setSubmitting(false);
						}}
						error={errors.link}
						isObjectFitCover={false}
					/>
					<KeywordsPicker
						type={AssetType.LAYER}
						selectedKeywords={values.newKeywords}
						onApply={(v) => setFieldValue('newKeywords', v, true)}
					/>
					<SingleSelectForm
						label="Position"
						selectedOption={String(values.position)}
						options={ARRAY_OF_LAYERS_POSITION}
						onClick={(v) => setFieldValue('position', v, true)}
						error={errors.position}
						touched={touched.position}
						type="number"
						showErrorIfNotTouched
					/>
					<InputFieldWithLink
						label="Link"
						name="dropboxLink"
						value={values.dropboxLink?.trim() || ''}
						onApply={(v: string | number) =>
							setFieldValue('dropboxLink', v, true)
						}
						error={errors.dropboxLink}
						placeholder="https://..."
					/>
					<Portal containerRef={buttonsContainerRef}>
						<ActionButtons
							onApply={submitForm}
							onCancel={onCancel}
							disabled={isSubmitting}
						/>
					</Portal>
				</>
			)}
		</Formik>
	);
};
