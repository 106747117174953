import * as Types from '../../../../models.gen';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BooksFilterQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.BooksFilterArgDto>;
}>;


export type BooksFilterQuery = { books: Array<{ id: string, name?: string | null }> };


export const BooksFilterDocument = gql`
    query BooksFilter($filter: BooksFilterArgDto) {
  books(page: 0, limit: 300, filter: $filter) {
    id
    name
  }
}
    `;

/**
 * __useBooksFilterQuery__
 *
 * To run a query within a React component, call `useBooksFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useBooksFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBooksFilterQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useBooksFilterQuery(baseOptions?: Apollo.QueryHookOptions<BooksFilterQuery, BooksFilterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BooksFilterQuery, BooksFilterQueryVariables>(BooksFilterDocument, options);
      }
export function useBooksFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BooksFilterQuery, BooksFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BooksFilterQuery, BooksFilterQueryVariables>(BooksFilterDocument, options);
        }
export type BooksFilterQueryHookResult = ReturnType<typeof useBooksFilterQuery>;
export type BooksFilterLazyQueryHookResult = ReturnType<typeof useBooksFilterLazyQuery>;
export type BooksFilterQueryResult = Apollo.QueryResult<BooksFilterQuery, BooksFilterQueryVariables>;