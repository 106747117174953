import React, { useMemo } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';

import { ChaptersByStoryQuery } from 'features/asset/chapters-asset-modal/graphql/queries.gen';
import { ArrayElement } from 'shared/model';
import { Chapter } from 'widgets/existing-heroes-modal/graphql/hooks';
import { CardQuery } from 'widgets/update-card-modal/graphql/queries.gen';

interface ChapterCardProps {
	chapter: Chapter;
	selectedChapter:
		| ArrayElement<ChaptersByStoryQuery['book']['chapters']>
		| CardQuery['card']['chapter']
		| null;
	handleSelectChapter: (v: Chapter) => void;
}

export const ChapterCard: React.FC<ChapterCardProps> = ({
	chapter,
	selectedChapter,
	handleSelectChapter,
}) => {
	const isChecked = useMemo(
		() => selectedChapter?.id === chapter.id,
		[chapter.id, selectedChapter?.id],
	);

	return (
		<Box
			h="min-content"
			userSelect="none"
			border="4px solid transparent"
			borderRadius="10px"
			cursor="pointer"
			borderColor={`${isChecked ? 'rgba(100, 77, 237, 0.08)' : ''}`}
			onClick={() => handleSelectChapter(chapter)}
		>
			<Flex
				p={3}
				height="85px"
				justifyContent="space-between"
				border="1px solid #eeeef2"
				borderRadius="10px"
				overflow="hidden"
				borderColor={`${isChecked ? '#644DED' : ''}`}
				_hover={{
					borderColor: '#644DED',
				}}
			>
				<Flex py={1} pl={4} flexDirection="column" gap={1}>
					<Text fontSize="15px" fontWeight={600} lineHeight="24px">
						{`${chapter.chapterOrder}. ${chapter.name}`}
					</Text>
					<Text fontSize="13px" fontWeight={400} lineHeight="16px">
						{chapter.bookName}
					</Text>
				</Flex>
			</Flex>
		</Box>
	);
};
