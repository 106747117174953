import React, { memo } from 'react';
import { HStack, Image, Td, Text, Tooltip, Tr } from '@chakra-ui/react';

import { ChaptersAssetSelect } from 'features/asset/chapters-asset-select';
import { BookChaptersForAssetSelectQuery } from 'features/asset/chapters-asset-select/queries.gen';
import { ButtonType } from 'shared/analytics';
import { AnalyticsIconButton } from 'shared/analytics/ui/icon-button';
import { TrashBinIcon } from 'shared/icons';
import { ArrayElement } from 'shared/model';
import { formatDate } from 'shared/utils';
import { ItemsByStoryQuery } from 'widgets/items-list/graphql/queries.gen';

interface ItemListRowProps {
	item: ArrayElement<ItemsByStoryQuery['items']['items']>;
	index: number;
	currentBookId: string;
	handleUpdateItemChapters: (
		item: ArrayElement<ItemsByStoryQuery['items']['items']>,
	) => (
		chapters: BookChaptersForAssetSelectQuery['book']['chapters'],
	) => Promise<void>;
	handleItemDelete: (
		item: ArrayElement<ItemsByStoryQuery['items']['items']>,
	) => () => Promise<void>;
	isLoading: boolean;
	updatedItem: string;
	refetch: () => void;
	handleUpdateModalOpen: () => void;
}

export const ItemListRow: React.FC<ItemListRowProps> = memo(
	({
		item,
		index,
		currentBookId,
		handleUpdateItemChapters,
		handleItemDelete,
		isLoading,
		updatedItem,
		handleUpdateModalOpen,
	}) => {
		return (
			<Tr cursor="pointer">
				<Tooltip label="Open Asset">
					<Td
						py={0}
						position="sticky"
						left={0}
						zIndex={100}
						onClick={handleUpdateModalOpen}
					>
						<HStack alignItems="center">
							<Text textAlign="center" minW="26px" mr="20px">
								{index + 1}
							</Text>
							<Image
								boxSize="75px"
								borderRadius="10px"
								objectFit="contain"
								src={item.link || ''}
								alt={item.name || ''}
							/>
							<Text
								fontWeight={400}
								minW="150px"
								overflow="hidden"
								whiteSpace="nowrap"
								textOverflow="ellipsis"
							>
								{item.filename}
							</Text>
						</HStack>
					</Td>
				</Tooltip>

				<Td>
					<ChaptersAssetSelect
						assetId={item.id}
						bookId={currentBookId}
						selectedChapters={item.chapters.filter(
							(chapter) => chapter.book?.id === currentBookId,
						)}
						onSubmit={handleUpdateItemChapters(item)}
						isLoading={isLoading}
						updatedAsset={updatedItem}
					/>
				</Td>
				<Td>{formatDate(item.updatedAt)}</Td>
				<Td>
					<Tooltip label="Remove from Story">
						<AnalyticsIconButton
							name={ButtonType.DELETE_ITEM_ASSET}
							aria-label="remove-hero"
							variant="ghost"
							size="square"
							onClick={handleItemDelete(item)}
						>
							<TrashBinIcon w={6} h={6} />
						</AnalyticsIconButton>
					</Tooltip>
				</Td>
			</Tr>
		);
	},
);
