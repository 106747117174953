export const SERIES_OPTIONS = ['Make Me', 'Melting Ice'];

export enum BookCategory {
	SWEET = 'Sweet',
	ROMANCE = 'Romance',
	FANTASY = 'Fantasy',
	TRENDING = 'Trending',
	MYSTERY = 'Mystery',
	HOT = 'Hot',
	DRAMA = 'Drama',
	PARANORMAL = 'Paranormal',
}
export enum AppOptions {
	MY_FANTASY = 'My Fantasy',
	BLUSHED_MARKETING = 'Blushed Marketing',
	BLUSHED_ORGANIC = 'Blushed Organic',
	SPARKS_MARKETING = 'Sparks Marketing',
	SPARKS_ORGANIC = 'Sparks Organic',
}

export const CATEGORY_OPTIONS = Object.values(BookCategory);

export enum BookStatus {
	READY = 'rdy',
	NOT_READY = 'not_rdy',
}

export const STATUS_OPTIONS = ['rdy', 'not rdy'];

export const APP_OPTIONS = [
	'My Fantasy',
	'Blushed Marketing',
	'Blushed Organic',
	'Sparks Marketing',
	'Sparks Organic',
];
