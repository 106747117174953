import React, { useCallback, useRef } from 'react';
import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogCloseButton,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	Button,
} from '@chakra-ui/react';

import { ButtonType } from 'shared/analytics';

interface DeleteHeroAlertProps {
	onSubmit: () => void;
	isOpen: boolean;
	onClose: () => void;
}

export const DeleteHeroAlert: React.FC<DeleteHeroAlertProps> = ({
	isOpen,
	onClose,
	onSubmit,
}) => {
	const cancelRef = useRef(null);

	const handleHeroAlertSubmit = useCallback(() => {
		onSubmit();
		onClose();
	}, [onClose, onSubmit]);

	return (
		<AlertDialog
			isOpen={isOpen}
			leastDestructiveRef={cancelRef}
			onClose={onClose}
		>
			<AlertDialogOverlay>
				<AlertDialogContent>
					<AlertDialogHeader fontSize="lg" fontWeight="bold">
						Hero Deletion
					</AlertDialogHeader>
					<AlertDialogCloseButton />
					<AlertDialogBody>
						If you delete this character, it can&apos;t be restored. Are you
						sure you want to continue?
					</AlertDialogBody>
					<AlertDialogFooter>
						<Button ref={cancelRef} variant="ghost" onClick={onClose}>
							Cancel
						</Button>
						<Button
							name={ButtonType.DELETE_CHARACTER}
							onClick={handleHeroAlertSubmit}
							ml={3}
						>
							Delete
						</Button>
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialogOverlay>
		</AlertDialog>
	);
};
