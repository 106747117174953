import * as yup from 'yup';

export const updateCardSchema = yup.object({
	golden: yup.boolean().required(),
	description: yup.string().trim(),
	heroName: yup.string().trim().required(),
	background: yup
		.object({
			id: yup.string().required(),
		})
		.nullable(),
	hero: yup
		.object({
			id: yup.string().required(),
		})
		.nullable(),
});
