import * as Types from '../../../../models.gen';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetBodyQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type GetBodyQuery = { body: { id: string, filename?: string | null, name?: string | null, atName?: string | null, link?: string | null, dropboxLink?: string | null, keywords: Array<string>, createdAt: any, gender: string, emotions: Array<string>, type: string, newKeywords?: { sex?: Array<string> | null, app?: Array<string> | null, art_style?: Array<string> | null, body_type?: Array<string> | null, skintone?: Array<string> | null, age?: Array<string> | null, extra?: Array<string> | null, not_human?: Array<string> | null } | null } };

export type GetChaptersByAssetIdQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type GetChaptersByAssetIdQuery = { heroByAssetId: Array<{ id: string, chapters: Array<{ id: string, chapterOrder?: number | null, name?: string | null, book?: { id: string, name?: string | null, customId?: string | null } | null }> }> };

export type GetHeroByAssetIdQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type GetHeroByAssetIdQuery = { heroByAssetId: Array<{ id: string, names: Array<string>, customId?: number | null, bodies?: Array<{ id: string, link?: string | null }> | null, hairs: Array<{ id: string, frontLink?: string | null, backLink?: string | null }>, clothes: Array<{ id: string, link?: string | null }> }> };

export type GetHairQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type GetHairQuery = { hair: { id: string, filename?: string | null, name?: string | null, atName?: string | null, frontLink?: string | null, backLink?: string | null, dropboxLink?: string | null, backName?: string | null, keywords?: Array<string> | null, createdAt: any, gender: string, type: string, newKeywords?: { sex?: Array<string> | null, app?: Array<string> | null, art_style?: Array<string> | null, age?: Array<string> | null, body_type?: Array<string> | null, color?: Array<string> | null, structure?: Array<string> | null, length?: Array<string> | null, style?: Array<string> | null, hairstyle?: Array<string> | null, extra?: Array<string> | null } | null } };

export type FindOneClothesQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type FindOneClothesQuery = { findOneClothes: { id: string, filename?: string | null, name?: string | null, atName?: string | null, link?: string | null, dropboxLink?: string | null, keywords?: Array<string> | null, createdAt: any, price?: number | null, type: string, alternativeClothes: Array<{ id: string }>, newKeywords?: { sex?: Array<string> | null, app?: Array<string> | null, art_style?: Array<string> | null, body_type?: Array<string> | null, age?: Array<string> | null, style?: Array<string> | null, color_range?: Array<string> | null, material?: Array<string> | null, nudity?: Array<string> | null, top?: Array<string> | null, bottom?: Array<string> | null, adjective?: Array<string> | null, extra?: Array<string> | null } | null } };

export type GetBackgroundQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type GetBackgroundQuery = { background: { id: string, filename?: string | null, name?: string | null, atName?: string | null, filter?: string | null, link?: string | null, dropboxLink?: string | null, keywords: Array<string>, createdAt: any, type: string, alternativeBackgrounds: Array<{ id: string }>, newKeywords?: { app?: Array<string> | null, art_style?: Array<string> | null, outside?: Array<string> | null, inside?: Array<string> | null, time?: Array<string> | null, rooms?: Array<string> | null, style?: Array<string> | null, mood?: Array<string> | null, extra?: Array<string> | null } | null, chapters: Array<{ id: string, chapterOrder?: number | null, name?: string | null, book?: { id: string, name?: string | null, customId?: string | null } | null }> } };

export type GetLayerQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type GetLayerQuery = { layer: { id: string, link?: string | null, atName?: string | null, keywords: Array<string>, createdAt: any, position?: number | null, price?: number | null, dropboxLink?: string | null, type: string, newKeywords?: { sex?: Array<string> | null, app?: Array<string> | null, art_style?: Array<string> | null, body_type?: Array<string> | null, owner?: Array<string> | null, defects?: Array<string> | null, accessories?: Array<string> | null, items?: Array<string> | null, for_body?: Array<string> | null } | null } };

export type GetItemQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type GetItemQuery = { item: { id: string, filename?: string | null, name?: string | null, atName?: string | null, link?: string | null, createdAt: any, price?: number | null, dropboxLink?: string | null, type?: string | null, keywords: Array<string>, newKeywords?: { app?: Array<string> | null, sex?: Array<string> | null, item?: Array<string> | null } | null, chapters: Array<{ id: string, chapterOrder?: number | null, name?: string | null, book?: { id: string, name?: string | null, customId?: string | null } | null }> } };

export type GetChatPhotoQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type GetChatPhotoQuery = { chatPhoto: { id: string, name?: string | null, link?: string | null, createdAt: any, type: string, chats: Array<{ id: string, name?: string | null, books: Array<{ id: string, name?: string | null, customId?: string | null }> }> } };

export type GetAudioQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type GetAudioQuery = { audio: { id: string, name?: string | null, link?: string | null, keywords: Array<string>, createdAt: any, type: string } };


export const GetBodyDocument = gql`
    query GetBody($id: String!) {
  body(id: $id) {
    id
    filename
    name
    atName
    link
    dropboxLink
    keywords
    newKeywords {
      sex
      app
      art_style
      body_type
      skintone
      age
      extra
      not_human
    }
    createdAt
    gender
    emotions
    type
  }
}
    `;

/**
 * __useGetBodyQuery__
 *
 * To run a query within a React component, call `useGetBodyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBodyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBodyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBodyQuery(baseOptions: Apollo.QueryHookOptions<GetBodyQuery, GetBodyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBodyQuery, GetBodyQueryVariables>(GetBodyDocument, options);
      }
export function useGetBodyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBodyQuery, GetBodyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBodyQuery, GetBodyQueryVariables>(GetBodyDocument, options);
        }
export type GetBodyQueryHookResult = ReturnType<typeof useGetBodyQuery>;
export type GetBodyLazyQueryHookResult = ReturnType<typeof useGetBodyLazyQuery>;
export type GetBodyQueryResult = Apollo.QueryResult<GetBodyQuery, GetBodyQueryVariables>;
export const GetChaptersByAssetIdDocument = gql`
    query GetChaptersByAssetId($id: String!) {
  heroByAssetId(id: $id) {
    id
    chapters {
      id
      chapterOrder
      name
      book {
        id
        name
        customId
      }
    }
  }
}
    `;

/**
 * __useGetChaptersByAssetIdQuery__
 *
 * To run a query within a React component, call `useGetChaptersByAssetIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChaptersByAssetIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChaptersByAssetIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetChaptersByAssetIdQuery(baseOptions: Apollo.QueryHookOptions<GetChaptersByAssetIdQuery, GetChaptersByAssetIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetChaptersByAssetIdQuery, GetChaptersByAssetIdQueryVariables>(GetChaptersByAssetIdDocument, options);
      }
export function useGetChaptersByAssetIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetChaptersByAssetIdQuery, GetChaptersByAssetIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetChaptersByAssetIdQuery, GetChaptersByAssetIdQueryVariables>(GetChaptersByAssetIdDocument, options);
        }
export type GetChaptersByAssetIdQueryHookResult = ReturnType<typeof useGetChaptersByAssetIdQuery>;
export type GetChaptersByAssetIdLazyQueryHookResult = ReturnType<typeof useGetChaptersByAssetIdLazyQuery>;
export type GetChaptersByAssetIdQueryResult = Apollo.QueryResult<GetChaptersByAssetIdQuery, GetChaptersByAssetIdQueryVariables>;
export const GetHeroByAssetIdDocument = gql`
    query GetHeroByAssetId($id: String!) {
  heroByAssetId(id: $id) {
    id
    names
    customId
    bodies {
      id
      link
    }
    hairs {
      id
      frontLink
      backLink
    }
    clothes {
      id
      link
    }
  }
}
    `;

/**
 * __useGetHeroByAssetIdQuery__
 *
 * To run a query within a React component, call `useGetHeroByAssetIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHeroByAssetIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHeroByAssetIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetHeroByAssetIdQuery(baseOptions: Apollo.QueryHookOptions<GetHeroByAssetIdQuery, GetHeroByAssetIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetHeroByAssetIdQuery, GetHeroByAssetIdQueryVariables>(GetHeroByAssetIdDocument, options);
      }
export function useGetHeroByAssetIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHeroByAssetIdQuery, GetHeroByAssetIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetHeroByAssetIdQuery, GetHeroByAssetIdQueryVariables>(GetHeroByAssetIdDocument, options);
        }
export type GetHeroByAssetIdQueryHookResult = ReturnType<typeof useGetHeroByAssetIdQuery>;
export type GetHeroByAssetIdLazyQueryHookResult = ReturnType<typeof useGetHeroByAssetIdLazyQuery>;
export type GetHeroByAssetIdQueryResult = Apollo.QueryResult<GetHeroByAssetIdQuery, GetHeroByAssetIdQueryVariables>;
export const GetHairDocument = gql`
    query GetHair($id: String!) {
  hair(id: $id) {
    id
    filename
    name
    atName
    frontLink
    backLink
    dropboxLink
    backName
    keywords
    newKeywords {
      sex
      app
      art_style
      age
      body_type
      color
      structure
      length
      style
      hairstyle
      extra
    }
    createdAt
    gender
    type
  }
}
    `;

/**
 * __useGetHairQuery__
 *
 * To run a query within a React component, call `useGetHairQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHairQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHairQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetHairQuery(baseOptions: Apollo.QueryHookOptions<GetHairQuery, GetHairQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetHairQuery, GetHairQueryVariables>(GetHairDocument, options);
      }
export function useGetHairLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHairQuery, GetHairQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetHairQuery, GetHairQueryVariables>(GetHairDocument, options);
        }
export type GetHairQueryHookResult = ReturnType<typeof useGetHairQuery>;
export type GetHairLazyQueryHookResult = ReturnType<typeof useGetHairLazyQuery>;
export type GetHairQueryResult = Apollo.QueryResult<GetHairQuery, GetHairQueryVariables>;
export const FindOneClothesDocument = gql`
    query FindOneClothes($id: String!) {
  findOneClothes(id: $id) {
    id
    filename
    name
    atName
    link
    dropboxLink
    alternativeClothes {
      id
    }
    keywords
    newKeywords {
      sex
      app
      art_style
      body_type
      age
      style
      color_range
      material
      nudity
      top
      bottom
      adjective
      extra
    }
    createdAt
    price
    type
  }
}
    `;

/**
 * __useFindOneClothesQuery__
 *
 * To run a query within a React component, call `useFindOneClothesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindOneClothesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindOneClothesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFindOneClothesQuery(baseOptions: Apollo.QueryHookOptions<FindOneClothesQuery, FindOneClothesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindOneClothesQuery, FindOneClothesQueryVariables>(FindOneClothesDocument, options);
      }
export function useFindOneClothesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindOneClothesQuery, FindOneClothesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindOneClothesQuery, FindOneClothesQueryVariables>(FindOneClothesDocument, options);
        }
export type FindOneClothesQueryHookResult = ReturnType<typeof useFindOneClothesQuery>;
export type FindOneClothesLazyQueryHookResult = ReturnType<typeof useFindOneClothesLazyQuery>;
export type FindOneClothesQueryResult = Apollo.QueryResult<FindOneClothesQuery, FindOneClothesQueryVariables>;
export const GetBackgroundDocument = gql`
    query GetBackground($id: String!) {
  background(id: $id) {
    id
    filename
    name
    atName
    filter
    link
    dropboxLink
    alternativeBackgrounds {
      id
    }
    keywords
    newKeywords {
      app
      art_style
      outside
      inside
      time
      rooms
      style
      mood
      extra
    }
    createdAt
    type
    chapters {
      id
      chapterOrder
      name
      book {
        id
        name
        customId
      }
    }
  }
}
    `;

/**
 * __useGetBackgroundQuery__
 *
 * To run a query within a React component, call `useGetBackgroundQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBackgroundQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBackgroundQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBackgroundQuery(baseOptions: Apollo.QueryHookOptions<GetBackgroundQuery, GetBackgroundQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBackgroundQuery, GetBackgroundQueryVariables>(GetBackgroundDocument, options);
      }
export function useGetBackgroundLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBackgroundQuery, GetBackgroundQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBackgroundQuery, GetBackgroundQueryVariables>(GetBackgroundDocument, options);
        }
export type GetBackgroundQueryHookResult = ReturnType<typeof useGetBackgroundQuery>;
export type GetBackgroundLazyQueryHookResult = ReturnType<typeof useGetBackgroundLazyQuery>;
export type GetBackgroundQueryResult = Apollo.QueryResult<GetBackgroundQuery, GetBackgroundQueryVariables>;
export const GetLayerDocument = gql`
    query GetLayer($id: String!) {
  layer(id: $id) {
    id
    link
    atName
    keywords
    newKeywords {
      sex
      app
      art_style
      body_type
      owner
      defects
      accessories
      items
      for_body
    }
    createdAt
    position
    price
    dropboxLink
    type
  }
}
    `;

/**
 * __useGetLayerQuery__
 *
 * To run a query within a React component, call `useGetLayerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLayerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLayerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetLayerQuery(baseOptions: Apollo.QueryHookOptions<GetLayerQuery, GetLayerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLayerQuery, GetLayerQueryVariables>(GetLayerDocument, options);
      }
export function useGetLayerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLayerQuery, GetLayerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLayerQuery, GetLayerQueryVariables>(GetLayerDocument, options);
        }
export type GetLayerQueryHookResult = ReturnType<typeof useGetLayerQuery>;
export type GetLayerLazyQueryHookResult = ReturnType<typeof useGetLayerLazyQuery>;
export type GetLayerQueryResult = Apollo.QueryResult<GetLayerQuery, GetLayerQueryVariables>;
export const GetItemDocument = gql`
    query GetItem($id: String!) {
  item(id: $id) {
    id
    filename
    name
    atName
    link
    createdAt
    price
    dropboxLink
    type
    keywords
    newKeywords {
      app
      sex
      item
    }
    chapters {
      id
      chapterOrder
      name
      book {
        id
        name
        customId
      }
    }
  }
}
    `;

/**
 * __useGetItemQuery__
 *
 * To run a query within a React component, call `useGetItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetItemQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetItemQuery(baseOptions: Apollo.QueryHookOptions<GetItemQuery, GetItemQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetItemQuery, GetItemQueryVariables>(GetItemDocument, options);
      }
export function useGetItemLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetItemQuery, GetItemQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetItemQuery, GetItemQueryVariables>(GetItemDocument, options);
        }
export type GetItemQueryHookResult = ReturnType<typeof useGetItemQuery>;
export type GetItemLazyQueryHookResult = ReturnType<typeof useGetItemLazyQuery>;
export type GetItemQueryResult = Apollo.QueryResult<GetItemQuery, GetItemQueryVariables>;
export const GetChatPhotoDocument = gql`
    query GetChatPhoto($id: String!) {
  chatPhoto(id: $id) {
    id
    name
    link
    createdAt
    type
    chats {
      id
      name
      books {
        id
        name
        customId
      }
    }
  }
}
    `;

/**
 * __useGetChatPhotoQuery__
 *
 * To run a query within a React component, call `useGetChatPhotoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChatPhotoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChatPhotoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetChatPhotoQuery(baseOptions: Apollo.QueryHookOptions<GetChatPhotoQuery, GetChatPhotoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetChatPhotoQuery, GetChatPhotoQueryVariables>(GetChatPhotoDocument, options);
      }
export function useGetChatPhotoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetChatPhotoQuery, GetChatPhotoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetChatPhotoQuery, GetChatPhotoQueryVariables>(GetChatPhotoDocument, options);
        }
export type GetChatPhotoQueryHookResult = ReturnType<typeof useGetChatPhotoQuery>;
export type GetChatPhotoLazyQueryHookResult = ReturnType<typeof useGetChatPhotoLazyQuery>;
export type GetChatPhotoQueryResult = Apollo.QueryResult<GetChatPhotoQuery, GetChatPhotoQueryVariables>;
export const GetAudioDocument = gql`
    query GetAudio($id: String!) {
  audio(id: $id) {
    id
    name
    link
    keywords
    createdAt
    type
  }
}
    `;

/**
 * __useGetAudioQuery__
 *
 * To run a query within a React component, call `useGetAudioQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAudioQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAudioQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAudioQuery(baseOptions: Apollo.QueryHookOptions<GetAudioQuery, GetAudioQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAudioQuery, GetAudioQueryVariables>(GetAudioDocument, options);
      }
export function useGetAudioLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAudioQuery, GetAudioQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAudioQuery, GetAudioQueryVariables>(GetAudioDocument, options);
        }
export type GetAudioQueryHookResult = ReturnType<typeof useGetAudioQuery>;
export type GetAudioLazyQueryHookResult = ReturnType<typeof useGetAudioLazyQuery>;
export type GetAudioQueryResult = Apollo.QueryResult<GetAudioQuery, GetAudioQueryVariables>;