import React, { useState } from 'react';
import {
	Box,
	Button,
	Flex,
	Grid,
	IconButton,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Text,
} from '@chakra-ui/react';
import { Form, Formik } from 'formik';

import { DraftReplyData } from 'entities/script';
import { UserCommentCard } from 'entities/user';
import { ButtonType } from 'shared/analytics';
import { MoreIcon } from 'shared/icons';
import { DeletionAlert, FormTextArea } from 'shared/ui';
import { getRows } from 'shared/utils/get-rows';

import { UpdateCommentFormValues } from '../../schema';

interface EditReplyFormProps {
	replyData: DraftReplyData;
	handleReplyEdit: (
		formValue: string | undefined,
		replyData: DraftReplyData,
	) => void;
	handleReplyDelete: (id: string) => void;
}

export const EditReplyForm: React.FC<EditReplyFormProps> = ({
	replyData,
	handleReplyEdit,
	handleReplyDelete,
}) => {
	const [isEditing, setIsEditing] = useState(false);
	const [isDeleting, setIsDeleting] = useState(false);
	// TODO remove handlers from JSX

	return (
		<Box position="relative">
			<Grid
				templateColumns="1fr 64px"
				gap="10px"
				alignItems="center"
				padding="11px 14px"
			>
				<UserCommentCard
					author={replyData?.author || '---'}
					picture={replyData?.picture}
					createdAt={replyData?.createdAt || ''}
				/>
				<Flex alignSelf="start" justify="flex-end">
					<Menu>
						<MenuButton
							as={IconButton}
							variant="ghost"
							size="sm"
							padding="10px 0"
							icon={<MoreIcon w={5} h={5} />}
						/>
						<MenuList minW="136px">
							<MenuItem onClick={() => setIsEditing(true)}>Edit</MenuItem>
							<MenuItem onClick={() => setIsDeleting(true)}>Delete</MenuItem>
						</MenuList>
					</Menu>
				</Flex>
			</Grid>
			{isEditing ? (
				<Box padding="6px 14px 16px">
					<Formik<UpdateCommentFormValues> // TODO add schema for validation
						enableReinitialize
						initialValues={{
							formValue: replyData?.reply || '',
						}}
						onSubmit={async ({ formValue }) => {
							// * this needed to disable submit on reply value reset

							handleReplyEdit(formValue, replyData);
							setIsEditing(false);
						}}
						onReset={() => setIsEditing(false)}
					>
						{({ values }) => (
							<Form>
								<FormTextArea
									name="formValue"
									autocomplete="off"
									placeholder="Comment"
									rows={getRows(values.formValue as string)}
								/>
								<Flex justify="flex-end" mt="10px">
									<Button type="reset" size="md" variant="secondary">
										Cancel
									</Button>
									<Button
										name={ButtonType.EDIT_COMMENT}
										type="submit"
										size="md"
										variant="secondary"
										disabled={!values.formValue}
									>
										Apply
									</Button>
								</Flex>
							</Form>
						)}
					</Formik>
				</Box>
			) : (
				<Box padding="6px 14px 16px" borderBottom="1px solid #EEEEF2">
					{replyData?.reply
						.split('\n')
						.map((line) => (line ? <Text>{line}</Text> : <br />))}
				</Box>
			)}
			{isDeleting && (
				<DeletionAlert
					top="0"
					right="0"
					bottom="0"
					left="0"
					borderRadius="0"
					justifyContent="center"
					bg="rgba(36, 37, 51, 0.87)"
					message="Delete this comment?"
					onClose={() => setIsDeleting(false)}
					onSubmit={() => handleReplyDelete(replyData.id)}
				/>
			)}
		</Box>
	);
};
