import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { NetworkStatus } from '@apollo/client';
import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	Box,
	Button,
	HStack,
	Spinner,
	Table,
	TableContainer,
	Tbody,
	Text,
	Th,
	Thead,
	Tr,
	useDisclosure,
} from '@chakra-ui/react';

import { BookChaptersForAssetSelectQuery } from 'features/asset/chapters-asset-select/queries.gen';
import { useChangeBackgroundAssignsMutation } from 'features/asset/stories-asset-tab/queries.gen';
import { GetBookInfoDocument } from 'features/book/queries.gen';
import {
	trackAssetDetailsModalClose,
	trackBackgroundRemoveModalClose,
} from 'shared/analytics/modals/close';
import {
	trackAssetDetailsModalOpen,
	trackBackgroundRemoveModalOpen,
} from 'shared/analytics/modals/open';
import { Modal } from 'shared/analytics/modals/types';
import { useAppToast } from 'shared/hooks/toast';
import { ArrayElement } from 'shared/model';
import { UpdateAssetModal } from 'widgets/update-asset-modal';

import {
	BackgroundsByStoryDocument,
	BackgroundsByStoryQuery,
	useBackgroundsByStoryQuery,
	useDeleteBackgroundFromStoryMutation,
} from '../graphql/queries.gen';
import { BackgroundListRow } from './list-row';

type BackgroundsListRouteParams = {
	readonly bookId: string;
};

export const BackgroundsList: React.FC = () => {
	const [updateBackground, setUpdateBackground] = useState<ArrayElement<
		BackgroundsByStoryQuery['backgrounds']['backgrounds']
	> | null>(null);

	const { bookId } = useParams<BackgroundsListRouteParams>();
	if (!bookId) throw Error();

	const toast = useAppToast();
	const cancelRef = useRef(null);

	const {
		isOpen: isOpenDeleteModal,
		onClose: onCloseDeleteModal,
		onOpen: onOpeDeleteModal,
	} = useDisclosure();
	const {
		isOpen: isOpenUpdateModal,
		onClose: onCloseUpdateModal,
		onOpen: onOpeUpdateModal,
	} = useDisclosure();

	const [deletedBackground, setDeletedBackground] = useState<ArrayElement<
		BackgroundsByStoryQuery['backgrounds']['backgrounds']
	> | null>(null);

	const { data, error, networkStatus, refetch } = useBackgroundsByStoryQuery({
		variables: { filter: { bookId }, limit: 100 },
		fetchPolicy: 'network-only',
		nextFetchPolicy: 'network-only',
		notifyOnNetworkStatusChange: true,
	});

	const handleUpdateModalClose = useCallback(() => {
		onCloseUpdateModal();
		refetch();
	}, [onCloseUpdateModal, refetch]);

	const handleUpdateModalOpen = useCallback(
		(background) => {
			setUpdateBackground(background);
			onOpeUpdateModal();
			trackAssetDetailsModalOpen(Modal.BACKGROUND_DETAILS);
		},
		[onOpeUpdateModal],
	);

	const [
		updateBackgroundChaptersAssigns,
		{ loading: updateBackgroundChaptersLoading },
	] = useChangeBackgroundAssignsMutation({
		refetchQueries: [BackgroundsByStoryDocument, GetBookInfoDocument],
	});

	const [deleteBackgroundFromStory, { loading: deleteBackgroundLoading }] =
		useDeleteBackgroundFromStoryMutation({
			refetchQueries: [
				{
					variables: { filter: { bookId }, limit: 100 },
					query: BackgroundsByStoryDocument,
				},
				GetBookInfoDocument,
			],
		});

	const handleUpdateBackgroundChapters = useCallback(
		(
				background: ArrayElement<
					BackgroundsByStoryQuery['backgrounds']['backgrounds']
				>,
			) =>
			async (chapters: BookChaptersForAssetSelectQuery['book']['chapters']) => {
				try {
					await updateBackgroundChaptersAssigns({
						variables: {
							dto: {
								backgroundId: background.id,
								chaptersIds: [
									...background.chapters
										.filter((chapter) => chapter.book?.id !== bookId)
										.map((chapter) => chapter.id),
									...(chapters || []).map((chapter) => chapter.id),
								],
								bookId,
							},
						},
					});
					if (chapters?.length === 0) {
						toast({
							title: `${background?.name} was removed`,
							status: 'success',
						});
					}
				} catch (errors) {
					toast({
						title: `Error: Can't update chapters`,
						status: 'error',
					});
				}
			},
		[bookId, toast, updateBackgroundChaptersAssigns],
	);

	const handleDeleteBackgroundModalClose = useCallback(() => {
		onCloseDeleteModal();
		setDeletedBackground(null);
		trackBackgroundRemoveModalClose();
	}, [onCloseDeleteModal]);

	const handleBackgroundDeleteAttempt = useCallback(
		(
				background: ArrayElement<
					BackgroundsByStoryQuery['backgrounds']['backgrounds']
				>,
			) =>
			async () => {
				setDeletedBackground(background);
				onOpeDeleteModal();
				trackBackgroundRemoveModalOpen();
			},
		[onOpeDeleteModal],
	);

	const handleBackgroundDeleteSubmit = useCallback(async () => {
		try {
			await deleteBackgroundFromStory({
				variables: {
					chapter: {
						backgrounds: [deletedBackground?.id || ''],
						chapterIds:
							deletedBackground?.chapters.reduce((acc, chapter) => {
								if (chapter.book?.id === bookId) return [...acc, chapter.id];
								return acc;
							}, [] as string[]) || [],
					},
				},
			});
			handleDeleteBackgroundModalClose();
			toast({
				title: 'Background was removed',
				status: 'success',
			});
		} catch (errors) {
			toast({
				title: `Can't remove background`,
				status: 'error',
			});
		}
	}, [
		bookId,
		deleteBackgroundFromStory,
		deletedBackground,
		handleDeleteBackgroundModalClose,
		toast,
	]);

	useEffect(() => {
		if (error?.message)
			toast({
				title: error?.message,
				status: 'error',
			});
	}, [error, toast]);

	useEffect(() => {
		if (updateBackgroundChaptersLoading) {
			setTimeout(() => refetch(), 0);
		}
	}, [updateBackgroundChaptersLoading, refetch]);

	if (networkStatus === NetworkStatus.loading) {
		return <Spinner size="xl" m="auto" />;
	}

	return (
		<>
			<Box
				d="flex"
				flex-direction="column"
				flex="1 1"
				overflow="hidden"
				h="calc(100vh - 195px)"
			>
				<Box d="flex" flex="1 1" overflow="auto">
					<TableContainer width="100%" overflowY="auto">
						<Table>
							<Thead position="sticky" left={0} top={0} zIndex={101}>
								<Tr>
									<Th position="sticky" left={0} zIndex={100}>
										<HStack>
											<Text
												textAlign="center"
												minW={7}
												mr={5}
												fontFamily="sans-serif"
											>
												№
											</Text>

											<Text minW="75px" mr={1}>
												Pic
											</Text>
											<Text minW="150px" noOfLines={1}>
												Background Name
											</Text>
										</HStack>
									</Th>

									<Th>Chapters</Th>
									<Th>Last Edited</Th>
									<Th />
								</Tr>
							</Thead>
							<Tbody>
								{data?.backgrounds.backgrounds.map((background, index) => (
									<BackgroundListRow
										key={background.id}
										index={index}
										background={background}
										currentBookId={bookId}
										handleUpdateBackgroundChapters={
											handleUpdateBackgroundChapters
										}
										handleBackgroundDelete={handleBackgroundDeleteAttempt}
										isLoading={updateBackgroundChaptersLoading}
										updatedBackground={updateBackground?.id as string}
										handleUpdateModalOpen={() =>
											handleUpdateModalOpen(background)
										}
									/>
								))}
							</Tbody>
						</Table>
					</TableContainer>
				</Box>
			</Box>
			{isOpenDeleteModal && (
				<AlertDialog
					isOpen={isOpenDeleteModal}
					leastDestructiveRef={cancelRef}
					onClose={handleDeleteBackgroundModalClose}
				>
					<AlertDialogOverlay>
						<AlertDialogContent>
							<AlertDialogHeader fontSize="lg" fontWeight="bold">
								Background
							</AlertDialogHeader>

							<AlertDialogBody>
								If you delete background from the story, it can always be
								returned. Are you sure you want to continue?
							</AlertDialogBody>

							<AlertDialogFooter>
								{!deleteBackgroundLoading && (
									<Button
										ref={cancelRef}
										variant="ghost"
										onClick={handleDeleteBackgroundModalClose}
									>
										Cancel
									</Button>
								)}
								<Button
									onClick={handleBackgroundDeleteSubmit}
									ml={3}
									isLoading={deleteBackgroundLoading}
								>
									Delete
								</Button>
							</AlertDialogFooter>
						</AlertDialogContent>
					</AlertDialogOverlay>
				</AlertDialog>
			)}
			{isOpenUpdateModal && updateBackground && (
				<UpdateAssetModal
					isOpen={isOpenUpdateModal}
					onClose={() => {
						handleUpdateModalClose();
						trackAssetDetailsModalClose(Modal.BACKGROUND_DETAILS);
					}}
					asset={updateBackground}
					afterDelete={refetch}
				/>
			)}
		</>
	);
};
