import type { ComponentStyleConfig } from '@chakra-ui/theme';

export const tabsStyles: ComponentStyleConfig = {
	baseStyle: {
		tab: {
			fontWeight: 600,
			fontSize: '16px',
			lineHeight: '24px',
			_selected: {
				outline: 'none',
				borderBottom: 'none',
				boxShadow: 'inset 0px -2px 0px #242533',
			},
		},
	},
	variants: {
		line: {
			tab: {
				color: '#808192',
				_selected: {
					color: '#242533',
				},
			},
		},
		rounded: {
			tab: {
				fontSize: '13px',
				fontWeight: 500,
				lineHeight: '140%',
				boxShadow: 'none',
				border: 'none',
				borderRadius: '8px',
				color: '#242533',
				padding: '8px 13px',
				_selected: {
					padding: '8px 13px',
					boxShadow: 'none',
					backgroundColor: '#644DED',
					color: '#ffffff',
					_hover: {
						backgroundColor: '#644DED',
					},
				},
				_hover: {
					backgroundColor: 'rgba(100, 77, 237, 0.08)',
				},
			},
		},
	},
};
